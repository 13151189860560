import parentApi from "store/parentApi"
const aiSalesGeneratorApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getAiSalesPitch: build.query({
      query: clientId => `/clients/${clientId}/clientAnalysis?requestType=client_sales_pitch`,
    }),
    getLanguagePreference: build.query({
      query: key => `/services/languages/platformInferenceLanguages?platformKey=${key}`,
      transformResponse: data => data?.data?.languages?.map(item => ({ ...item, value: item?.key, label: item?.name })),
    }),
    generateSalesPitch: build.mutation({
      query: data => {
        let { clientId, leadId, language } = data
        let body = {
          requestType: "client_sales_pitch",
          language: language,
          leadId: +leadId,
        }
        return { url: `/clients/${clientId}/clientAnalysis`, method: "POST", body: body }
      },
    }),
  }),
})

export const {
  useGenerateSalesPitchMutation,
  useGetAiSalesPitchQuery,
  useLazyGetAiSalesPitchQuery,
  useGetLanguagePreferenceQuery,
  useLazyGetLanguagePreferenceQuery,
} = aiSalesGeneratorApi
