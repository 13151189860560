const getTaskTypeCategoriesTransformHelper = response => {
  const groupedData = {}
  response.forEach(task => {
    const { parent } = task
    if (parent) {
      groupedData[parent] = groupedData[parent] ?? []
      groupedData[parent].push(task)
    }
  })
  return groupedData
}

export default getTaskTypeCategoriesTransformHelper
