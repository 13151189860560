import { DATE_FORMAT } from "constants"
import Icon from "../icon/icon"
import Tooltip from "../tooltip/tooltip"
import { dateTimeFunction } from "utils/date"
import { TIME_FORMAT } from "constants"
const DateFormat = ({
  dateClassName = "gray-800",
  date: d,
  showTime,
  color = "red",
  icon = "BiCalendar",
  customTooltip,
}) => {
  let date = dateTimeFunction({ date: d, format: DATE_FORMAT })
  let time = dateTimeFunction({ date: d, format: TIME_FORMAT })

  const tooltipTitle = customTooltip ? `${customTooltip} ${date}, ${time}` : `${date}, ${time}`
  return (
    d && (
      <div className={dateClassName} style={{ cursor: !!showTime ? "default" : "pointer" }}>
        <Tooltip title={tooltipTitle}>
          {icon && <Icon icon={icon} size="14px" />}
          &nbsp; {date}
          {showTime && <span style={{ color: color }}>&nbsp; {time}</span>}
        </Tooltip>
      </div>
    )
  )
}

export default DateFormat
