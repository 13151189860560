import { Button, Dropdown, notification } from "components/common"
import { useAddClientContactViewTrackingMutation, useCallViaPropGoMutation } from "features/clients/detail/api"

import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import Lottie from "react-lottie"
import animationData from "animation/propGoAnimation"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { useErrorNotification } from "hooks"
import { useSelector } from "react-redux"
import { useLazyGetClientCellsQuery } from "features/shared/api"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
const CallButton = props => {
  const { t } = useTranslation()
  const { client, affiliate, disabled, leadId, from, className, ...buttonProps } = props
  const userInfo = useSelector(state => state.app.user?.info)

  const defaultOptions = { loop: true, autoplay: true, animationData: animationData }
  const [getClientCellInfo, { data: clientCells }] = useLazyGetClientCellsQuery()
  const [callViaPropGo, options] = useCallViaPropGoMutation()
  const [addClientContactView] = useAddClientContactViewTrackingMutation()

  const { isFetching: isFetchingCall, isError, error } = options
  useErrorNotification({ isError, error })

  const callAndReset = (cellNumber, id) => {
    callViaPropGo({ cellNumber: cellNumber, clientId: id, ...(affiliate && { affiliate: true }) })
      .unwrap()
      .then(e => {
        return
        // addClientContactView({ clientId: client?.id, action: "contact_via_prop_go" })
      })

    fireEvent({
      ...ACTION_TYPES.call_contact_client,
      user_input: cellNumber,
      client_id: client?.id,
      ...(leadId && { lead_id: leadId }),
      ...from,
    })
  }

  const onClick = e => {
    e.domEvent.stopPropagation()
    callAndReset(items[e.key].label, client?.id || affiliate?.id)
  }

  const onCopy = e => {
    e.preventDefault()
  }
  const onContextMenu = e => {
    e.preventDefault()
  }

  const items = useMemo(() => {
    if (affiliate) {
      return affiliate?.affiliateCells?.map((e, index) => {
        return { label: e?.number || e?.cell, key: index }
      })
    } else {
      return clientCells?.map((e, index) => {
        return { label: e, key: index }
      })
    }
  }, [clientCells, affiliate])

  return (
    <Dropdown
      overlayClassName="ddPhone"
      menu={{ items, onClick, onCopy, onContextMenu }}
      dropdownRender={menu => (items?.length ? <div className="dropdown-content">{menu}</div> : <></>)}
      placement="bottomLeft"
      suffixIcon=""
      disabled={disabled}
    >
      <Button
        className={className}
        icon={"IconGo"}
        iconProps={{ size: "1.6em" }}
        onClick={e => {
          e.stopPropagation()
          affiliate && callAndReset(items?.[0]?.label, affiliate?.id)
          client && getClientCellInfo({ clientId: client?.id, actionType: "tooltip" })
        }}
        content={t("Call via PropGo")}
        color="#0186fe"
        shape="round"
        type="light"
        disabled={!userInfo?.AgencyConfiguration?.Platform?.platformKey === "zameen" || disabled}
        {...buttonProps}
      />
    </Dropdown>
  )
}

export default CallButton
