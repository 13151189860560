import { checkExpiryInHours } from "utils/date"

const existingDiscountTransformHelper = response => {
  if (!response.DealDiscount) {
    return {
      doesDiscountExist: false,
    }
  }
  return {
    id: response.DealDiscount.id,
    inquiryId: response.DealDiscount.inquiryId,
    projectId: response.DealDiscount.projectId,
    discountType: response.DealDiscount.discountType,
    discountInfo: response.DealDiscount.discountInfo,
    discountId: response.DealDiscount.discountId,
    duePayment: response.DealDiscount.duePayment,
    isApproved: response.DealDiscount.isApproved,
    requestedFrom: response.DealDiscount.requestedFrom,
    requestedBy: response.DealDiscount.requestedBy,
    expiryDuration: !!response.DealDiscount.linkExpiry
      ? checkExpiryInHours(response.DealDiscount.linkExpiry)
      : response.DealDiscount.linkExpiry,
    agencyId: response.DealDiscount.agencyId,
    comment: response.DealDiscount.comment,
    createdAt: response.DealDiscount.createdAt,
    RequestedFrom: response.DealDiscount.RequestedFrom,
    InternalInquiry: response.DealDiscount.InternalInquiry,
    doesDiscountExist: true,
  }
}

export default existingDiscountTransformHelper
