import { Card, DateFormat, Number, Space, Text } from "components/common"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"

import cx from "clsx"
import { AIBadgeScore } from "components/common/aiBdgeScore/aiBadgeScore"
import CardTag from "components/common/cardTag/cardTag"
import DataTable from "components/common/dataTable/dataTable"
import Recording from "components/common/recording/recording"
import CallDrawer from "features/callRecording/components/callDrawer/callDrawer"
import { t } from "i18next"
import { convertToReadableString, localisedValue } from "utils"
import styles from "./callsTab.module.less"
import { ActionButtons, StatusTag, TitleDescription } from "components/custom"
import TimeFormat from "components/common/timeFormat/timeFormat"
import UserProfileSummary from "features/shared/components/userProfileSummary"
import ClientInfoTooltip from "features/shared/components/clientInfoToolTip/clientInfoToolTip"
import theme from "utils/themeVars"
import { useLazyGetCallRecordsQuery } from "features/callRecording/api"
import getPrivilege from "utils/privileges/privileges"
import { PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"
import ClientNumberButton from "../contactedNumber"

const getcolumns = () => [
  { title: t("Date & Time"), dataIndex: "dateAndTime", key: "dateAndTime", className: "tableStartSpace" },
  { title: t("Staff"), dataIndex: "staff", key: "staff" },
  { title: t("Client"), dataIndex: "client", key: "client" },
  { title: t("Status"), dataIndex: "status", key: "status" },
  { title: t("Duration"), dataIndex: "duration", key: "duration" },
  { title: t("Tag"), dataIndex: "tag", key: "tag" },
  { title: t("Recording"), dataIndex: "recording", key: "recording" },
  { title: t("Call"), dataIndex: "action", key: "call", width: 50 },
  { title: t("Odessy AI"), dataIndex: "odessy", key: "call" },
]
const staff = element => {
  return (
    <TitleDescription
      title={
        <Space size={3}>
          {localisedValue({ key: "userName", object: element })}&nbsp;
          <UserProfileSummary staffId={element?.userId} />
        </Space>
      }
      description={localisedValue({ key: "callBy", object: element })}
    />
  )
}
const clientTag = element => {
  return (
    <>
      {localisedValue({ key: "clientName", object: element })}&nbsp;
      <ClientInfoTooltip client={element.clientId} />
    </>
  )
}
const getActionButtons = (data, handleDrawer) => {
  return [
    {
      key: "call",
      Component: () => (
        <ClientNumberButton
          client={data}
          shape="circle"
          from={{ interacted_from: PAGE_SUBSECTIONS.client_listing_page }}
          disabled={!getPrivilege("can_view_client_contact", data?.privileges)}
        />
      ),
    },
  ]
}

const CallsTab = ({ toggleParentDrawer = () => {}, getCount = () => {}, params, ...props }) => {
  const [selectedCall, setSelectedCall] = useState(null)
  const [queryObj, setQueryObj] = useState({
    page: 0,
    pageLimit: 10,
    callType: "all",
    ...params,
  })

  useEffect(() => {
    setQueryObj({
      page: 0,
      pageLimit: 10,
      callType: "all",
      ...params,
    })
  }, [params])

  const callDrawerRef = useRef()
  const [getCallRecords, { data, isFetching, error, refetch }] = useLazyGetCallRecordsQuery()

  useEffect(() => {
    getCallRecords(queryObj, true)
  }, [queryObj])

  const callStatuses = useMemo(() => {
    return {
      unanswered: { title: t("Unanswered"), color: theme["error-color-light"], textColor: theme["error-color"] },
      answered: { title: t("Answered"), color: theme["primary-light"], textColor: theme["primary-color"] },
    }
  }, [])
  const onChangePage = (page, pageSize) => {
    setQueryObj(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }

  const handleCallTrasnscription = call => {
    setSelectedCall(call)
    callDrawerRef.current?.toggleDrawer()
  }
  useEffect(() => {
    data?.count && getCount(data?.count)
  }, [data?.count])

  const callTabs = useMemo(
    () =>
      data &&
      Object.keys(data?.phoneCallRecordsStatistics)?.map(e => {
        return {
          tab: (
            <Fragment key={e}>
              {convertToReadableString(data?.callTabNames[e], true)}{" "}
              <Text type="secondaryLight">
                <Number
                  wrapValue={val => `(${val})`}
                  value={data.phoneCallRecordsStatistics?.[e]}
                  defaultValue="0"
                  digitAfterFraction={1}
                  isStringOnly
                  tooltip
                />
              </Text>
            </Fragment>
          ),
          key: e,
        }
      }),
    [data]
  )
  const getCallsData = (data, handleDrawer) => {
    let dataSource =
      data &&
      data?.phoneCallRecords?.map(element => {
        return {
          dateAndTime: (
            <TitleDescription
              title={<DateFormat date={element.date} dateClassName="" />}
              gapSpace={4}
              description={<TimeFormat date={element.time} isTimeOnlyStr />}
            />
          ),
          staff: staff(element),
          client: clientTag(element),
          status: <StatusTag data={callStatuses[element?.status]} />,

          duration: element?.duration,
          tag: (
            <CardTag
              title={element?.tagShort}
              color={element.tagStyle?.bgColor}
              tooltipTitle={element?.tag?.tooltipTitle}
            />
          ),
          recording: (
            <div style={{ height: 60, display: "flex", alignItems: "center" }}>
              {element?.url ? (
                <Recording id={element.date} recordingSrc={element?.url} />
              ) : (
                <Text>{t("Recording not available")}</Text>
              )}
            </div>
          ),

          action: (
            <ActionButtons
              list={getActionButtons(element)}
              animate={false}
              style={{ "--hover-displacement": "-24px" }}
            />
          ),
          odessy: element?.analysisStatus === "completed" && (
            <>
              <AIBadgeScore
                totalScore={element?.assessment?.scoring?.total_score}
                handleDrawer={() => handleDrawer(element)}
                className={cx(styles.analyzerBox, "pointer")}
              />
            </>
          ),
        }
      })
    return dataSource
  }

  return (
    <>
      <Card
        tabList={callTabs}
        activeTabKey={queryObj?.callType || "all"}
        onTabChange={key => setQueryObj({ ...queryObj, callType: key, page: 0 })}
        bodyStyle={{ padding: "25px 0 0" }}
        style={{ flexGrow: 1 }}
      >
        <DataTable
          tableLayout="auto"
          pagination={{ totalCount: data?.count, pageOptions: [10, 25, 50] }}
          paginationOnBottom={true}
          paginationOnTop={false}
          onChangePage={onChangePage}
          columns={getcolumns()}
          data={getCallsData(data, handleCallTrasnscription)}
          loading={isFetching}
          error={error}
          onErrorRetry={refetch}
          noUrlPush={true}
          {...props}
        />

        <CallDrawer
          selectedCall={selectedCall}
          onBack={toggleCallDrawer => {
            toggleCallDrawer()
            setSelectedCall(null)
          }}
          ref={callDrawerRef}
        />
      </Card>
    </>
  )
}

export default CallsTab
