import { arrayBufferToBase64, convertQueryObjToString } from "utils"

import { DATE_TIME_FORMAT } from "constants"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import designationTransformer from "./designationTransformer"
import parentApi from "store/parentApi"
import { dateTimeFunction } from "utils/date"

const staffApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getStaffUser: build.query({
      query: params => {
        const updatedParams = { ...params }
        delete updatedParams["staffList_withoutChildren"]
        return `/users?${convertQueryObjToString({
          ...updatedParams,
          ...(updatedParams?.blockReasonKeys
            ? { blockReasonKeys: updatedParams?.blockReasonKeys.replaceAll("-", ",") }
            : {}),
          includeAllChildren: !params.staffList_withoutChildren,
        })}`
      },
      transformResponse: response => {
        response?.data.forEach(e => {
          e["privileges"] = createPrivilegeObject(e?.privileges)
          return e
        })
        return response
      },
      providesTags: ["staff"],
    }),

    getStaffDetail: build.query({
      query: id => {
        return `/users/${id}`
      },
      providesTags: ["staff-detail"],
    }),

    getUserInterventionDefinitions: build.query({
      query: () => `/userInterventionDefinitions`,
    }),

    getDesignations: build.query({
      query: params => `/userDesignations/getDesignationsWithCount/${params.activeTab}?allowBlocked=false`,
      transformResponse: (data, meta, arg) => {
        return designationTransformer(data, arg)
      },
    }),

    createNewStaff: build.mutation({
      query: ({ values }) => {
        let body

        body = {
          cell: values.officialNumber,
          cityId: values.city.toString(),
          cnicFrontImage: values.governmentIdPicture?.url?.split(",")[1],
          cnicPictureFileName: values.governmentIdPicture?.name,
          countryId: values.country,
          regionId: values.region,
          ...(values?.pbxMachine && { pbxMachineId: values.pbxMachine }),
          ...(values?.pbxUserIp && { pbxUserIP: values.pbxUserIp }),
          ...(values?.pbxExtension && { extension: values.pbxExtension }),
          dateOfBirth: dateTimeFunction({ date: values.dateOfBirth, timeZone: true, apiRequest: true }),
          designationId: values.designation,
          ...(values?.specialRole && { privilegeRoleId: values?.specialRole }),
          email: values.email,
          employeeId: values.employeeId,
          languageId: values.language,
          lineManagerId: values?.lineManager?.id,
          name: values.name,
          nationalId: values.nationalId,
          personalContact: values.personalPhoneNumber,
          phone: null,
          picture: values.profilePicture.url?.split(",")[1],
          profilePictureFileName: values.profilePicture?.name,
          signatureAcknowledgement: values?.signature,
          signatureImage: values.signaturePicture?.url?.split(",")[1],
          signaturePictureFilename: values.signaturePicture?.name,
          teamTypeId: values.department?.id,
          timeFormat: [values.timeFormat],
          timezoneId: [values.timeZone.toString()],
          joiningDate: dateTimeFunction({ date: values.joiningDate, timeZone: true, apiRequest: true }),
          address: values.address,
          gender: values.gender,
          selfSeller: values.selfSeller,
          ...(values?.recruitmentDrive && { labelId: values?.recruitmentDrive }),
        }
        return { url: `/users`, method: "POST", body: body }
      },
    }),

    updateStaff: build.mutation({
      query: ({ values, staffData, self }) => {
        let body
        body = {
          cityId: values.city.toString(),
          countryId: values.country,
          regionId: values.region,
          dateOfBirth: dateTimeFunction({ date: values.dateOfBirth, timeZone: true, apiRequest: true }),
          designationId: values.designation,
          privilegeRoleId: values?.specialRole ? values?.specialRole : null,
          email: values.email,
          employeeId: values.employeeId,
          languageId: values.language,
          lineManagerId:
            self && staffData?.lineManagerId
              ? staffData?.lineManagerId
              : values?.lineManager
              ? values?.lineManager?.id
              : null,
          name: values.name,
          nationalId: values.nationalId,
          ...(values.profilePicture?.url && { picture: values.profilePicture.url.split(",")[1] }),
          ...(values.profilePicture?.name && { profilePictureFileName: values.profilePicture.name }),
          signatureAcknowledgement: values.signature,
          teamTypeId: values.department?.id,
          timeFormat: values.timeFormat,
          timezoneId: values.timeZone,
          address: values.address,
          joiningDate: dateTimeFunction({ date: values.joiningDate, timeZone: true, apiRequest: true }),
          personalContact: values.personalPhoneNumber,
          cell: values.officialNumber,
          ...(values?.pbxMachine && { pbxMachineId: values.pbxMachine }),
          ...(values?.pbxUserIp && { pbxUserIP: values.pbxUserIp }),
          ...(values?.pbxExtension && { extension: values.pbxExtension }),
          signatureImage: values.signaturePicture?.url?.split(",")[1],
          signaturePictureFilename: values?.signaturePicture?.name,
          id: staffData.id,
          gender: values.gender,
          selfSeller: values.selfSeller,
          ...(values?.recruitmentDrive && { labelId: values?.recruitmentDrive }),
        }
        return { url: `/users/${staffData.id}`, method: "PUT", body: body }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff-detail"] : []),
    }),

    blockUsers: build.mutation({
      query: ({ userIds, blockReasonId }) => {
        const payload = { userIds, blockReasonId }
        return { url: `/blockedUsers`, method: "POST", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    markSelfSeller: build.mutation({
      query: ({ userIds, selfSeller }) => {
        const payload = { userIds, selfSeller }
        return { url: `/users/markSelfSeller`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    getUserSuspensionReasons: build.query({
      query: () => `/users/getUserSuspensionReason`,
    }),

    getUserCounts: build.query({
      query: params => `/users/${params.userId}/userCounts`,
    }),

    getLineManagers: build.query({
      query: params => `/users/lineManagers?${convertQueryObjToString({ ...params })}`,
    }),

    changeLineManager: build.mutation({
      query: params => {
        const payload = { lineManagerId: params?.lineManagerId }
        return { url: `/users/${params?.userId}/changeLineManager`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    addIntervention: build.mutation({
      query: ({ encodedFile, staff, ...values }) => {
        const payload = {
          ...(encodedFile &&
            encodedFile.length && {
              attachments: encodedFile.map(e => ({ file: e.file.split(",")[1], name: e.name })),
            }),
          comment: values?.commentsNotes,
          dateTime: dateTimeFunction({ date: values?.dateAndTime, timeZone: true, apiRequest: true }),
          strengthId: values?.strength,
          title: values?.title,
          typeId: values?.type,
          weaknessId: values?.weakness,
          userId: staff?.id,
        }
        return { url: `/userInterventions`, method: "POST", body: payload }
      },
    }),

    getUserInterventions: build.query({
      query: params => `/userInterventions/${params?.staffId}`,
    }),

    getDispositions: build.query({
      query: params => `/dispositions?${convertQueryObjToString({ ...params })}`,
    }),

    getStaffUserDesignations: build.query({
      query: params =>
        `/userDesignations${
          params?.teamTypeId ? `/${params?.teamTypeId}/teamTypeWise?teamTypeId=${params?.teamTypeId}` : ""
        }`,
    }),

    getSpecialRoles: build.query({
      query: () => `/roles?isActive=1&isSpecial=true`,
    }),

    addRecruitmentDrive: build.mutation({
      query: ({ name }) => {
        const payload = { name: name }
        return { url: `/userLabels`, method: "POST", body: payload }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["recruitment-drive"] : []
      },
    }),

    getLeadAcknowledgeSlip: build.query({
      query: ({ id, useBuffer, ...params }) => ({
        url: `/user-signoff/${id}?${convertQueryObjToString({ ...params })}`,
        responseHandler: response => (response.ok ? response.arrayBuffer() : response.json()), //convert file stream to array buffer
        headers: { accept: "application/pdf" },
      }),
      transformResponse: (data, _meta, arg) => (arg.useBuffer ? data : arrayBufferToBase64(data)), //convert file from array buffer to base64
    }),

    suspendUser: build.mutation({
      query: params => {
        const payload = {
          leadSuspension: params?.leadSuspension,
          qcSuspension: params?.qcSuspension,
          shareLeadSuspension: params?.shareLeadSuspension,
          allocatedShareLead: params?.allocatedShareLead,
          allocatedLead: params?.allocatedLead,
          allocatedQcTasks: params?.allocatedQcTasks,
          userSuspensionReason: {
            userSuspensionParentReason: params?.reason,
            userSuspensionChildReason: params?.childReason,
          },
        }
        return { url: `/users/${params?.userId}/suspend`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    unblockUsers: build.mutation({
      query: ({ userIds, unblockReason }) => {
        const payload = { userIds, unblockReason }
        return { url: `/blockedUsers/unblock`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    extendDeadline: build.mutation({
      query: params => {
        const payload = {
          userIds: params?.userIds,
          extendDeadlineData: {
            ...params?.extendDeadlineData,
            deadline: dateTimeFunction({
              date: params?.extendDeadlineData?.deadline,
              timeZone: true,
              apiRequest: true,
            }),
          },
        }
        return { url: `/blockedUsers/extendBlockDate/bulk`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    extendBlockDate: build.mutation({
      query: params => {
        const payload = {
          blockedUser: {
            blockDate: dateTimeFunction({ date: params?.blockDate, timeZone: true, apiRequest: true }),
            userId: params?.userId,
            unblockReason: params?.unblockReason,
          },
        }
        return { url: `/blockedUsers/${params?.blockReasonId}/extendBlockDate`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    forgotPassword: build.mutation({
      query: ({ email }) => {
        const payload = { email }
        return { url: `/authentication/forgotPassword`, method: "POST", body: payload }
      },
    }),

    checkEmailOrCellDuplication: build.query({
      query: params => `/users/checkForEmailOrCellDuplication?${convertQueryObjToString({ ...params })}`,
    }),

    activateUser: build.mutation({
      query: ({ userId }) => {
        const payload = {}
        return { url: `/users/${userId}/activate`, method: "PUT", body: payload }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),

    getBlockedUsers: build.query({
      query: params => `/blockedUsers?${convertQueryObjToString({ ...params })}`,
    }),

    getStaffValidation: build.query({
      query: params => `users/checkDuplicates?${convertQueryObjToString(params)}`,
    }),
    changePassword: build.mutation({
      query: params => {
        let body = {
          currentPassword: params.currentPassword,
          newPassword: params.newPassword,
          userId: params.userId,
        }
        return { url: `/users/${params.userId}/changePassword`, method: "PUT", body: body }
      },
    }),
    getActiveProjectsListForAllocation: build.query({
      query: params => `/projects?${convertQueryObjToString({ ...params })}`,
    }),
    getAllocatedProjectsForUser: build.query({
      query: params => `users/${params}/getProjects`,
    }),
    updateProjectsForUser: build.mutation({
      query: params => {
        let body = {
          projectIds: params.projectIds.join(","),
          userId: params.userId,
        }
        return { url: `/projects/leadAssignee`, method: "POST", body: body }
      },
      invalidatesTags: (resp, error) => (resp ? ["staff"] : []),
    }),
  }),

  overrideExisting: false,
})

export const {
  useChangePasswordMutation,
  useGetBlockedUsersQuery,
  useLazyGetStaffDetailQuery,
  useGetStaffUserQuery,
  useLazyGetStaffUserQuery,
  useGetDesignationsQuery,
  useLazyGetLeadAcknowledgeSlipQuery,
  useUpdateStaffMutation,
  useLazyGetStaffUserDesignationsQuery,
  useLazyGetDesignationsQuery,
  useLazyGetStaffValidationQuery,
  useGetUserInterventionDefinitionsQuery,
  useLazyGetUserInterventionDefinitionsQuery,
  useCreateNewStaffMutation,
  useBlockUsersMutation,
  useMarkSelfSellerMutation,
  useLazyGetUserSuspensionReasonsQuery,
  useGetUserSuspensionReasonsQuery,
  useLazyGetUserCountsQuery,
  useLazyGetLineManagersQuery,
  useChangeLineManagerMutation,
  useAddInterventionMutation,
  useGetUserInterventionsQuery,
  useLazyGetUserInterventionsQuery,
  useLazyGetDispositionsQuery,
  useSuspendUserMutation,
  useUnblockUsersMutation,
  useExtendDeadlineMutation,
  useForgotPasswordMutation,
  useLazyCheckEmailOrCellDuplicationQuery,
  useActivateUserMutation,
  useLazyGetBlockedUsersQuery,
  useExtendBlockDateMutation,
  useGetSpecialRolesQuery,
  useAddRecruitmentDriveMutation,
  useGetActiveProjectsListForAllocationQuery,
  useLazyGetAllocatedProjectsForUserQuery,
  useUpdateProjectsForUserMutation,
} = staffApi
