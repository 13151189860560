import { Card, Col, Row } from "antd"
import { Icon, Skeleton, Group } from "components/common"

import chroma from "chroma-js"
import cx from "clsx"
import styles from "./index.module.less"

const { Meta } = Card

const IconCard = props => {
  const {
    className,
    extra,
    hoverable,
    isrequired,
    ref,
    accentColor,
    onClick,
    title,
    icon,
    description,
    skeletonLoading,
    ...rest
  } = props
  return skeletonLoading ? (
    <IconCardSkeleton accentColor={accentColor} />
  ) : (
    <Card
      className={cx(className, styles.iconCard)}
      style={{
        "--accent-color": accentColor,
        "--icon-bg-color": "#fff",
        "--icon-card-bg": accentColor ? chroma(accentColor).alpha(0.08).saturate(0.24) : "initial",
      }}
      bodyStyle={{ padding: "12px 8px" }}
      onClick={onClick}
      hoverable={hoverable}
      {...rest}
    >
      <Meta
        className={styles.meta}
        avatar={<Icon hasBackground icon={icon} size={rest.iconProps?.size || "1.43em"} />}
        title={title}
        description={description}
      />
    </Card>
  )
}

const IconCardSkeleton = props => {
  const { accentColor } = props

  return (
    <Card
      className={cx(styles.iconCard)}
      style={{
        "--accent-color": accentColor,
        "--icon-bg-color": "#fff",
        "--icon-card-bg": accentColor ? chroma(accentColor).alpha(0.08).saturate(0.24) : "initial",
      }}
      bodyStyle={{ padding: "8px 8px", fontSize: 4 }}
    >
      <Group template={"auto 1fr"}>
        <Skeleton type="avatar" size={36} />

        <Group template={"initial"} gap="5px">
          <Skeleton height={16} />
          <Skeleton height={20} />
        </Group>
      </Group>
    </Card>
  )
}

export default IconCard
