import { Col, Form } from "antd"
import { useEffect, useState } from "react"

import { SelectInput } from "components/common"
import { useTranslation } from "react-i18next"

export default function Region({ value, level = 0, onChange, valueList, className, colSpan = 6, data }) {
  const { t } = useTranslation()

  const [region, setRegion] = useState(null)
  const [country, setCountry] = useState(null)
  const [city, setCity] = useState(null)

  useEffect(() => {
    if (valueList && data) {
      setRegion(prev => {
        return { ...prev, value: valueList[0], option: data.filter(item => item.id === +valueList[0]) }
      })
      setCountry(prev => {
        return {
          ...prev,
          value: valueList[1],
        }
      })
      valueList[2] &&
        valueList[2] !== city &&
        region?.option?.key != "international" &&
        setCity(prev => {
          return { value: valueList[2] }
        })
    }
  }, [valueList, data])

  return (
    <>
      <Col span={colSpan} key={"reg-1"}>
        <Form.Item key={"ct"}>
          <SelectInput
            className={className}
            label={t("Region")}
            placeholder={t("Please select a Region")}
            value={valueList ? valueList[0] : value ? value : region.value}
            options={data}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id.toString()}
            onChange={(value, option) => {
              setRegion({ value: value, option: option })
              setCountry(null)
              setCity(null)
              onChange(0, option)
            }}
          />
        </Form.Item>
      </Col>

      {region?.value && level > 0 && level <= 2 && (
        <Col span={colSpan} key={"reg-2"}>
          <Form.Item key={"ci"}>
            <SelectInput
              className={className}
              label={t("Country")}
              placeholder={t("Please select a Country")}
              value={valueList ? valueList[1] : country.value}
              options={region?.option[0]?.countries || []}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id.toString()}
              onChange={(value, option) => {
                setCity({ value: value, option: option })
                onChange(1, option)
              }}
            />
          </Form.Item>
        </Col>
      )}
      {city && region?.value && level === 2 && (
        <Col span={colSpan} key={"reg-3"}>
          <Form.Item key={"lo"}>
            <SelectInput
              className={className}
              label={t("City")}
              placeholder={t("Please select a City")}
              value={valueList ? valueList[2] : city.value}
              options={region?.option[0]?.cities || []}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id.toString()}
              onChange={(value, option) => {
                onChange(2, option)
              }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  )
}
