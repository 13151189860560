import { capitalizeFirstLetter, convertToReadableString } from "utils"

export const trasncriptionTransformHelper = response => {
  const getCallTags = (callTags, summary) => {
    let tags = []
    callTags?.tags?.split(",")?.map(tag => {
      tags.push(convertToReadableString(tag))
    })
    if (summary?.meeting?.status) {
      summary?.meeting?.status == "approved" ? tags.push("Meeting Approved") : tags.push("Meeting Rejected")
    }
    return tags
  }
  return {
    id: response.phoneCallId,
    transcription: response.transcription.replace("\r", "")?.split("\n"),
    summary: {
      description: response?.summary?.description,
      nextProposedTask: response?.summary?.nextProposedTask || "None",
      projectDiscussed: response?.summary?.projectDiscussed || "None",
      meeting: Object.keys(response?.summary?.meeting)?.length ? response?.summary?.meeting : null,
    },
    callTags: getCallTags(response?.callTags, response?.summary),
    clientInformation: Object.keys(response?.clientInformation?.client_information || {})?.length
      ? response?.clientInformation?.client_information
      : null,
    assessment: Object.keys(response?.assessment || {})?.length ? response?.assessment : null,
  }
}
