import { Button, Text } from "components/common"

import { useState } from "react"

const CollapsibleText = props => {
  const { text, collapsibleLength = 500, className } = props
  const [seeMore, setSeeMore] = useState(false)
  const finalText = text?.props?.children || text

  return (
    <>
      <p style={{ whiteSpace: "break-spaces" }} className={className}>
        {!seeMore && finalText?.length > collapsibleLength
          ? `${finalText?.substring(0, collapsibleLength)}...`
          : finalText}
        {finalText?.length > collapsibleLength && (
          <Button
            type="link"
            size="small"
            className="pointer text-underline pi-12 pb-0"
            style={{ height: "auto" }}
            onClick={() => setSeeMore(!seeMore)}
            text={seeMore ? "See Less" : "See More"}
          />
        )}
      </p>
    </>
  )
}
export default CollapsibleText
