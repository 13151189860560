import { Col, Collapse, Row } from "antd"
import { Group, Icon, Steps, Text } from "components/common"

import { Fragment } from "react"
import { InfoBlock } from "components/custom"
import cx from "clsx"
import styles from "./index.module.less"
import theme from "utils/themeVars"

export default function DetailBar(props) {
  const {
    className,
    detailData,
    listStyle,
    listData,
    collapsible,
    collapseHeader,
    stepsProps,
    detailHeader,
    evenly = false,
    separator = true,
    onlyInfoBlock,
    customComponent = () => {},
    customComponentObj,
    detailContainerClass,
    noDetailHeader,
  } = props

  const renderGroup = () => {
    return (
      <>
        {!noDetailHeader && detailHeader}
        <div>
          <Row
            className={cx(
              styles.outlookInfo,
              !separator && styles.hideSep,
              evenly && styles.equalSpace,
              detailContainerClass
            )}
            justify="space-between"
          >
            {detailData?.map((item, i) => {
              if (item.component) return item.component
              return (
                <Fragment key={i}>
                  <Col className={styles.sep} />
                  <Col key={`${item.value}-${i}`}>
                    <Text color={theme["gray700"]} block>
                      {item.label}
                    </Text>
                    <Text block>{item.value}</Text>
                    {item?.subText && (
                      <Text color={theme["gray700"]} block>
                        {item.subText}
                      </Text>
                    )}
                  </Col>
                </Fragment>
              )
            })}
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      {collapsible ? (
        <Collapse
          className={cx(className, styles.outlook)}
          // style={{ ...style }}
          expandIcon={({ isActive }) => (
            <Icon icon="MdKeyboardArrowDown" size="1.8em" style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />
          )}
          expandIconPosition="end"
          collapsible="header"
        >
          <Collapse.Panel
            header={
              stepsProps ? (
                <Group gap="16px">
                  <div>
                    <Steps size="small" labelPlacement="vertical" {...stepsProps} />
                  </div>
                  {renderGroup()}
                </Group>
              ) : (
                <Group gap="16px">
                  {collapseHeader && <div>{collapseHeader}</div>}
                  {renderGroup()}
                </Group>
              )
            }
          >
            {listData && (
              <InfoBlock className={cx(listStyle === "grid" && styles.infoBlockGrid)} size="small" data={listData} />
            )}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <Group className={cx("p-16", styles.outlook, className)} gap="16px">
          {onlyInfoBlock ? (
            <InfoBlock
              className={cx(listStyle === "grid" && styles.infoBlockGrid)}
              size="small"
              data={listData}
              customComponent={data => customComponent(data)}
              customComponentObj={customComponentObj}
            />
          ) : (
            renderGroup()
          )}
        </Group>
      )}
    </>
  )
}
