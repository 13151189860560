import { createSlice } from "@reduxjs/toolkit"
import { reportCategories } from "constants/list"

const generateReportsInitialState = () => {
  const initialState = {
    reports: {},
  }

  Object.keys(reportCategories).forEach(reportKey => {
    const { name, key } = reportCategories[reportKey]
    initialState.reports[key] = {
      name: name,
    }
  })

  return initialState
}

const reportsSlice = createSlice({
  name: "reports",
  initialState: generateReportsInitialState(),
  reducers: {
    setSelectedColumns(state, { payload }) {
      state.selectedColumns = payload
    },
    setExpandedColumnsState(state, { payload }) {
      const { name, expandedColumns } = payload
      state.reports[name].expandedColumns = expandedColumns
    },
  },
})

console.log("initialState", generateReportsInitialState())

export const { setSelectedColumns, setExpandedColumnsState } = reportsSlice.actions
export default reportsSlice
