import { Modal, RadioButtons, SelectInput, Text, TextInput, notification } from "components/common"

import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import { Form } from "antd"
import { IconText } from "components/custom"
import theme from "utils/themeVars"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getChangeAllocationValidationSchema from "./changeAllocationValidationSchema"
import { schemaRules } from "features/shared/utils"
import { useChangeAssigneeMutation } from "features/clients/detail/api"
import { useLazyGetUsersQuery } from "features/shared/api"
import { useMemo } from "react"
import { useWatch } from "antd/lib/form/Form"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function ChangeAllocationForm({
  formRef,
  clientIds = [],
  leadIds = [],
  leads,
  currentSelected,
  allocationParams = {},
  type,
  filterRequired,
  setFilterRequired = () => {},
  onSuccess = () => {},
}) {
  const { t } = useTranslation()
  const [getUsers, { data, isFetching }] = useLazyGetUsersQuery()
  const [changeAssignee, { error, isError, isLoading, reset }] = useChangeAssigneeMutation()
  const [form] = Form.useForm()
  const rules = schemaRules(getChangeAllocationValidationSchema())
  const allocatedTo = useWatch("allocatedTo", form)
  const [searchParams] = useSearchParams()
  const queryObj = Object.fromEntries([...searchParams])

  const onModalVisible = () => {
    getUsers(allocationParams)
  }

  const reallocatedType = useWatch("reallocatedType", form)

  const reallocatedTypes = useMemo(() => [
    { label: t("Manual - Select Staff"), value: "manual" },
    { label: t("Round Robin - Select Staff"), value: "round_robin" },
    { label: t("Auto - Lead Allocation Policy"), value: "auto_allocation" },
  ])

  const onSubmit = async values => {
    const response = await changeAssignee({ clientIds, leadIds, type, filterRequired, queryObj, ...values })
      .unwrap()
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.allocated_to_edit_submit,
          user_input: values?.allocatedTo?.label,
          response: e.status,
          client_id: clientIds[0],
        })
      })
    if (response) {
      fireEvent({
        ...ACTION_TYPES.allocated_to_edit_submit,
        user_input: values?.allocatedTo?.label,
        response: "200",
        client_id: clientIds[0],
      })
      onSuccess()
      formRef?.current?.setVisible(false)
      setFilterRequired(false)
      notification.success({ message: t("Allocation changed successfully") })
    }
  }

  const getInitialValues = useMemo(
    () => ({
      allocatedTo: null, //currentSelected ? currentSelected.name :
    }),
    [currentSelected]
  )

  return (
    <Modal
      title={t("Change Allocation")}
      ref={formRef}
      okText={t("Re-Allocate")}
      cancelText={t("Cancel")}
      onCancel={() => {
        setFilterRequired(false)
        formRef?.current?.setVisible(false)
        reset()
      }}
      onOk={form.submit}
      loading={isLoading}
      onModalVisible={onModalVisible}
      footerAlert={error}
      width={700}
      destroyOnClose
      bodyStyle={{ overflow: "visible" }}
    >
      <Form
        form={form}
        // layout="vertical"
        name="change-allocation-form"
        initialValues={getInitialValues}
        onFinish={onSubmit}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        labelAlign={"left"}
        // width={1000}
      >
        {filterRequired && <Text strong>This action will be performed on all 500 selected</Text>}
        <Form.Item name={"reallocatedType"} label={t("Re-Allocated By")} initialValue={"manual"}>
          <RadioButtons
            options={reallocatedTypes}
            onChange={(value, option) => {
              form.setFieldsValue({ allocatedTo: undefined })
            }}
          />
        </Form.Item>
        {reallocatedType !== "auto_allocation" && (
          <Form.Item
            name="allocatedTo"
            label={t("Select Staff")}
            rules={rules}
            required
            extra={
              clientIds.length === 1 ? (
                //detail page case currentSelected is the allcoatedTo User object
                <>
                  {(!!currentSelected?.userId ? currentSelected?.userId : currentSelected?.[0]?.id) ===
                    allocatedTo?.id && <Text type="danger">{t("Lead is already allocated to this User")}</Text>}
                </>
              ) : (
                //listing page case currentSelected holds allocatedToUsers array
                <>
                  {currentSelected.length &&
                    currentSelected?.every(e => e?.id === currentSelected[0]?.id) &&
                    currentSelected?.[0]?.id === allocatedTo?.id && (
                      <Text type="danger">{t("Selected client(s) is already allocated to this User")}</Text>
                    )}
                </>
              )
            }
          >
            <SelectInput
              placeholder={t("Please select staff")}
              options={data}
              mode={reallocatedType === "round_robin" ? "multiple" : null}
              loading={isFetching}
              getPopupContainer={true}
              onChange={(_value, option) => {
                form.setFieldsValue({ allocatedTo: option })
              }}
            />
          </Form.Item>
        )}
        {reallocatedType === "manual" && (
          <Form.Item
            name="manualAllocationReason"
            label={t("Reason")}
            rules={rules}
            required
            extra={
              <IconText
                icon="MdInfoOutline"
                iconProps={{ color: theme["gray800"], size: "1em" }}
                textType="secondary"
                title={t("Maximum 150 characters")}
              />
            }
          >
            <TextInput placeholder={t("Please enter reason")} maxLength={150} showCount style={{ resize: "none" }} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
