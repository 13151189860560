import { Tabs, Radio } from "antd"
import { Icon, Modal } from "components/common"
import { useLazyGetProfileSummaryQuery } from "features/shared/api"
import { useEffect, useMemo, useRef, useState } from "react"
import ActivityLogs from "./activityLogs/activityLogs"
import GeneralInformation from "./generalInformation/generalInformation"
import styles from "./index.module.less"
import Kpi from "./kpi/kpi"
import UserSummary from "./summary/userSummary"
import TargetVsAchieved from "./targetVsAchieved/targetVsAchieved"
import UserProfileHeader from "./userProfileHeader/userProfileHeader"
import { t } from "i18next"
import { convertQueryObjToString, convertToReadableString } from "utils"

const UserProfileSummary = props => {
  const { staffId } = props
  const ref = useRef()
  const [tab, setTab] = useState("generalInformation")
  const [getProfileSummary, { data, isFetching, error }] = useLazyGetProfileSummaryQuery()
  const [kpiType, setKpiType] = useState("sales")

  const categories = useMemo(
    () => [
      {
        key: "summary",
        label: t("Summary"),
        children: <UserSummary staffId={staffId} />,
      },
      {
        key: "kpi",
        label: t("KPI"),
        children: <Kpi staffId={staffId} kpiType={kpiType} />,
      },
      {
        key: "targetVsAchieved",
        label: t("Target Vs Achieved"),
        children: <TargetVsAchieved staffId={staffId} />,
      },
      {
        key: "activityLogs",
        label: t("Activity Logs"),
        children: <ActivityLogs staffId={staffId} />,
      },
      {
        key: "generalInformation",
        label: t("General Information"),
        children: <GeneralInformation general={data?.generalInformation} />,
      },
    ],
    [isFetching, data, kpiType]
  )

  const onModalVisible = () => {
    getProfileSummary({ staffId, load: "generalInformation,basicInformation" }, true)
    setTab("generalInformation")
  }

  //add filter here or in tab tag
  const items = useMemo(
    () => categories?.filter(category => data?.allowedComponents?.includes(category.key)),
    [data, tab, staffId, isFetching, kpiType]
  )

  return (
    <>
      <Icon
        hasBackground
        icon={"BsInfo"}
        color="#707070"
        size="16px"
        style={{ backgroundColor: "#E6E6E6", borderRadius: "1rem", "--icon-color": "#707070", margin: -8 }}
        className="pointer"
        onClick={e => {
          ref.current?.setVisible(true)
          e?.stopPropagation()
        }}
      />
      <div onClick={event => event?.stopPropagation()}>
        <Modal
          width={850}
          ref={ref}
          onCancel={e => {
            ref?.current?.setVisible(false)
          }}
          loading={isFetching}
          hideCancelButton={true}
          className={styles.modalBg}
          bodyStyle={{ padding: 24 }}
          footer={null}
          onModalVisible={onModalVisible}
          destroyOnClose
        >
          <UserProfileHeader basicInformation={data?.basicInformation} isFetching={isFetching} />
          <div style={{ display: "flex" }}>
            <Tabs
              className={styles.tabsMain}
              activeKey={tab}
              items={items}
              onChange={activeKey => setTab(activeKey)}
              tabBarExtraContent={
                tab === "kpi" &&
                data?.kpiBifurcation?.enableSwitch && (
                  <Radio.Group
                    buttonStyle="solid"
                    value={kpiType}
                    size="small"
                    onChange={e => {
                      setKpiType(e?.target?.value)
                    }}
                  >
                    {data?.kpiBifurcation?.possibleValues?.map((item, index) => (
                      <Radio.Button key={index} value={item}>
                        {t(`${convertToReadableString(item)}`)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )
              }
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default UserProfileSummary
