import { affiliateOptions } from "constants/list"
import moment from "moment"
import { localisedValue } from "utils"
import { dateTimeFunction } from "utils/date"
import { t } from "i18next"

export const getClientInitialValues = client => {
  if (client) {
    return {
      mobileNumber: ["+92"],
      clientClass: localisedValue({ object: client, key: "referralType" }),
      searchAffiliate: { ...affiliateOptions()?.[0], affiliateId: client?.Affiliate?.id },
      searchId: client?.Affiliate?.AffiliateMOU?.id,
      name: localisedValue({ object: client, key: "name" }),
      email: client.email,
      gender: localisedValue({ object: client, key: "gender" }),
      cnic: !!client.nationalId ? client.nationalId.replace(/-/g, "") : "",
      country: !!client.Country
        ? {
            key: client.Country.id,
            label: localisedValue({ object: client.Country, key: "name" }),
            value: localisedValue({ object: client.Country, key: "name" }),
          }
        : null,
      city: !!client.City
        ? {
            key: client.City.id,
            label: localisedValue({ object: client.City, key: "name" }),
            value: localisedValue({ object: client.City, key: "name" }),
          }
        : null,
      locality: !!client.Locality
        ? {
            ...client.Locality,
            key: client.Locality.id,
            label: localisedValue({ object: client.Locality, key: "name" }),
            value: client.Locality.name,
          }
        : null,
      address: localisedValue({ object: client, key: "address" }),
      fatherName: localisedValue({ object: client, key: "relationship" }),
      passportNumber: client.passport,
      dateOfBirth: moment(client.dateOfBirth).isValid()
        ? dateTimeFunction({ date: client.dateOfBirth, Default: true })
        : null,
      clientType: localisedValue({ object: client.ClientType, key: "id" })?.toString(),
      clientOccupation: localisedValue({ object: client, key: "occupation" }),
      clientRating: client.ClientRating?.id?.toString(),
      clientBuyingIntent: localisedValue({ object: client.ClientBuyingIntent, key: "id" })?.toString(),
    }
  }

  return {
    mobileNumber: ["+92"],
    clientClass: "direct",
    searchAffiliate: affiliateOptions()?.[0],
    name: "",
    email: "",
    gender: "",
    country: null,
    city: null,
    locality: null,
    allocatedTo: null,
    clientType: "",
    clientOccupation: null,
    clientRating: "",
    clientBuyingIntent: "",
  }
}
