import * as yup from "yup"

import { isValidPhoneNumber } from "react-phone-number-input"
import { capitalizeFirstLetter } from "utils"
import { regex } from "../constants/regex"

import { t } from "i18next"
//TODO: need to remove translation import here

export const phoneValidationYup = (isRequired = true, isMulti, label = "phone") => {
  if (!!isMulti) {
    return yup.array().of(
      yup
        .string()
        .required(`${capitalizeFirstLetter(label)} ${t("number is required")}`)
        .test("format", `Invalid ${capitalizeFirstLetter(label)} number format`, value => {
          return isValidPhoneNumber(value ? value : "-")
        })
    )
  } else {
    return isRequired
      ? yup
          .string()
          .required(`${capitalizeFirstLetter(label)} ${t("number is required")}`)
          .test("format", t("Invalid phone format"), value => isValidPhoneNumber(value ? value : "-"))
      : yup
          .string()
          .test("format", t("Invalid phone format"), value => {
            return value ? isValidPhoneNumber(value ? value : "-") : true
          })
          .nullable()
  }
}

export const websiteValidation = (websiteRegex = regex.websiteRegex) => {
  return yup
    .string()
    .test("", "Invalid url format", function (value) {
      if (!value) return true
      const isValidWebsite = websiteRegex.test(value)
      if (!isValidWebsite) {
        return false
      }
      return true
    })
    .nullable()
}

export const emailValidationYup = (isRequired, emailRegex = regex.emailRegex) => {
  return isRequired
    ? yup
        .string()
        .required(t("Email is required"))
        .test("", t("Invalid email format"), function (value) {
          const isValidEmail = emailRegex.test(value)
          if (!isValidEmail) {
            return false
          }
          return true
        })
    : yup.string().test("", t("Invalid email format"), function (value) {
        if (!value) return true
        const isValidEmail = emailRegex.test(value)
        if (!isValidEmail) {
          return false
        }
        return true
      })
}

export const stringValidationYup = (requiredMsg, regex = "") => {
  if (requiredMsg) {
    if (regex && requiredMsg) return yup.string().required(requiredMsg).matches(regex, t("Invalid format"))
    return yup.string().required(requiredMsg)
  } else
    return yup.string().test("", t("Invalid format"), value => {
      if (!value) return true
      return regex ? regex.test(value) : true
    })
}

export const numValidation = (requiredMsg, limitTo = 15, regexKey = "numbersAndDecimal") => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test("regex", t("Invalid format, Please add numbers only"), value => regex[regexKey].test(value?.trim()))
      .test("limitTest", `Max Number limit exceeded (${limitTo})`, value => value?.trim().length <= limitTo)
  }
  return yup
    .string()
    .test("regex", t("Invalid format, Please add numbers only"), value =>
      value ? regex[regexKey].test(value?.trim()) : true
    )
    .test("limitTest", `${t("Max Number limit exceeded")} (${limitTo})`, value =>
      value ? value?.trim().length <= limitTo : true
    )
    .nullable()
}
export const numValidationGreater = (requiredMsg, limitTo = 13, exact, regexKey = "naturalNumbers") => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test("regex", t("Invalid format"), value => regex[regexKey].test(value?.trim()))
      .test("limitTest", `${t("Character limit must be greater than or equal")} (${limitTo})`, value =>
        exact ? value?.trim().length == limitTo : value?.trim().length >= limitTo
      )
  }
  return yup
    .string()
    .test("regex", t("Invalid format"), value => (value ? regex[regexKey].test(value?.trim()) : true))
    .test("limitTest", `${t("Character limit must be greater than or equal")} (${limitTo})`, value =>
      value ? (exact ? value?.trim().length == limitTo : value?.trim().length >= limitTo) : true
    )
    .nullable()
}

export const numValidationByAmount = (requiredMsg, amount) => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test("regex", t("Invalid format"), value => (value ? regex.naturalNumbers.test(value?.trim()) : true))
      .test("limitTest", `${t("Amount exceeded")} ${amount}`, value => (value ? value <= amount : true))
  }
  return yup
    .string()
    .test("regex", t("Invalid format"), value => (value ? regex.naturalNumbers.test(value?.trim()) : true))
    .test("limitTest", `${t("Amount exceeded")} ${amount}`, value => (value ? value <= amount : true))
}

export const cnicValidation = (requiredMsg, amount) => {
  if (requiredMsg) {
    return yup.string().required(requiredMsg).matches(regex.cnicRegex, t("Invalid format"))
  }
  return yup.string().matches(regex.cnicRegex, t("Invalid format"))
}

export const numValidationBetween = (requiredMsg, startLimit, endLimit) => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test("regex", t("Invalid format"), value => (value ? regex.naturalNumbers.test(value?.trim()) : true))
      .test("limitTest", `${t("Amount should be between")} ${startLimit} and ${endLimit}`, value =>
        value ? value <= endLimit && value >= startLimit : true
      )
  }
  return yup
    .string()
    .test("regex", t("Invalid format"), value => (value ? regex.naturalNumbers.test(value?.trim()) : true))
    .test("limitTest", `${t("Amount should be between")} ${startLimit} and ${endLimit}`, value =>
      value ? value <= endLimit && value >= startLimit : true
    )
}
