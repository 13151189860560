import { useCallback, useEffect, useMemo, useState } from "react"
import locale from "antd/es/date-picker/locale/ar_EG"
import { DatePicker } from "antd"
import { DATE_FORMAT } from "constants"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { RangePicker } = DatePicker

const DateRangePicker = props => {
  const { value, onChange, noDefault, noOfDaysBefore = 0, limitRange = false, restrictRange, ...rest } = props
  const { t } = useTranslation()
  const { locale: appLanguage } = useSelector(state => state.app.configs)

  const [date, setDate] = useState(
    !noDefault ? [moment().subtract(7 + noOfDaysBefore, "days"), moment().subtract(noOfDaysBefore, "days")] : undefined
  )

  useEffect(() => {
    setDate(value?.[0] && value?.[1] ? [moment(value[0]), moment(value[1])] : undefined)
  }, [value])

  const onChangeDate = (dates, dateStrings) => {
    setDate(dates)
  }

  const handleChangeDate = useCallback(() => {
    if (!!restrictRange && !!date) {
      let dates = [...date]
      if (dates?.[0] && dates?.[1]) {
        if (dates[1].diff(dates[0], "days") > restrictRange) {
          dates[1] = moment(dates[0]).add(restrictRange, "days")
        }
      }
      onChange(dates)
    } else {
      onChange(date)
    }
  }, [date])

  const ranges = useMemo(() => {
    return !!restrictRange
      ? {}
      : {
          [t("Yesterday")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          [t("Last 7 Days")]: [moment().subtract(7 + noOfDaysBefore, "days"), moment()],
          [t("Last 30 Days")]: [moment().subtract(30 + noOfDaysBefore, "days"), moment()],
          [t("Last 90 Days")]: [moment().subtract(90 + noOfDaysBefore, "days"), moment()],
          [t("Last 6 Months")]: [moment().subtract(6, "months"), moment()],
          [t("Last Year")]: [moment().subtract(12, "months"), moment()],
        }
  }, [restrictRange])
  return (
    <div>
      <RangePicker
        allowClear={false}
        ranges={!limitRange && ranges}
        value={date}
        onChange={onChangeDate}
        format={DATE_FORMAT}
        onOpenChange={isOpened => {
          !isOpened && handleChangeDate()
        }}
        {...rest}
        locale={appLanguage === "en" ? [] : locale}
      />
    </div>
  )
}
export default DateRangePicker
