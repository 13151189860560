import { t } from "i18next"
import { DetailBar, Group, Modal, PriceSlider, RadioButtons, SelectInput, Title } from "components/common"
import { useAddAnswersMutation, useLazyGetClientAnswerQuery, useLazyGetClientQuestionsQuery } from "features/tasks/api"
import { useEffect, useMemo } from "react"
import { useLazyGetCitiesQuery, useLazyGetCountriesQuery, useLazyGetLocalityQuery } from "features/shared/api"

import { Form } from "antd"
import getClientProfileInitialValues from "./clientProfileInitialValues"
import getClientProfileValidationSchema from "./clientProfileValidationSchema"
import { useWatch } from "antd/lib/form/Form"
import { getCountryList } from "utils"
import { localisedValue, convertToSpecificAreaUnit } from "utils"
import { useSelector } from "react-redux"

const schemaRules = schema => [
  {
    validator: async ({ field }, value) => {
      await schema.validateSyncAt(field, { [field]: value })
    },
  },
]

export default function ClientProfileForm({ formRef, client, allocatedTo, onSuccess }) {
  const [form] = Form.useForm()
  const { setFieldsValue } = form
  let rules = schemaRules(getClientProfileValidationSchema())

  const [getQuestions, { data: questionData, isFetching }] = useLazyGetClientQuestionsQuery()
  const [getClientAnswers, { data: answerData, refetch }] = useLazyGetClientAnswerQuery()
  const [getCities, { data: cities }] = useLazyGetCitiesQuery()
  const [getLocalities, { data: localities, isFetching: isFetchingLocality }] = useLazyGetLocalityQuery()
  const [getCountries, { data: countries }] = useLazyGetCountriesQuery()

  const [addAnswers, { isError, isLoading, reset, error }] = useAddAnswersMutation()

  const countryWatch = useWatch("country", form)
  const cityWatch = useWatch("city", form)
  const existingCountryWatch = useWatch("existingCountry", form)
  const existingCityWatch = useWatch("existingCity", form)
  const occupation = useWatch("clientOccupation", form)
  const clientPropertyType = useWatch("clientPropertyType", form)

  const userCountryId = useSelector(state => state?.app?.user?.info?.AgencyConfiguration?.countryId)
  const userPlatformKey = useSelector(state => state.app.user?.info?.AgencyConfiguration?.Platform?.platformKey)
  const getClientDetail = useMemo(
    () => [
      { label: t("Client"), value: client?.name },
      // { label:{t("Lead ID")}, value: client?.id },
      { label: t("Allocated To"), value: client?.allocationActivity?.name || client?.User?.name || allocatedTo },
    ],
    [client]
  )
  const getNationalities = useMemo(() => {
    return countries?.map(country => ({
      label: localisedValue({ object: country, key: "nationalityTitle" }),
      value: country.id,
    }))
  }, [countries])

  const getOptions = data => {
    return data?.map(option => ({
      label: option?.name,
      value: option?.key,
    }))
  }
  const getAreaOptions = () => {
    return questionData?.clientAreaSize?.options?.map((areaOption, _) => {
      const areaValue = convertToSpecificAreaUnit(
        areaOption.value,
        userPlatformKey === "bayut_ksa" ? "sq_m" : "sq_f"
      ).toFixed(0)
      const areaUnit = userPlatformKey === "bayut_ksa" ? t("Square Meter") : t("Square Feet")
      return {
        value: areaOption.value.toFixed(0),
        unit: areaUnit,
        name: `${areaValue} ${areaUnit}`,
      }
    })
  }

  const onModalVisible = () => {
    getClientAnswers(client?.id)
    getQuestions()
    getCountries()
    !countryWatch && getCities(userCountryId)
  }

  const onSubmit = async values => {
    reset()
    addAnswers({ values, client })
      .unwrap()
      .then(() => {
        if (!isError) {
          onSuccess()
          formRef?.current?.setVisible(false)
        }
      })
  }

  useEffect(() => {
    answerData && setFieldsValue(getClientProfileInitialValues(answerData))

    getCities(userCountryId)
    if (!!answerData?.preferredCityId) {
      getLocalities(answerData?.preferredCityId)
    }
  }, [answerData])

  return (
    <Modal
      title={t("Fill Client Profile")}
      ref={formRef}
      okText={t("Save")}
      width={900}
      cancelText={t("Cancel")}
      onModalVisible={onModalVisible}
      onCancel={() => {
        form.resetFields()
        formRef?.current?.setVisible(false)
        reset()
      }}
      onOk={form.submit}
      loading={isLoading}
      destroyOnClose
      footerAlert={error}
    >
      <>
        <div className="mbe-24">
          <Title level={3}>{t("Client Profile")}</Title>
          <DetailBar detailData={getClientDetail} />
        </div>

        <Form
          form={form}
          layout="vertical"
          name="client-Profile"
          initialValues={getClientProfileInitialValues(answerData)}
          onFinish={onSubmit}
          preserve={false}
          disabled={isFetching}
          scrollToFirstError
        >
          <Group template="repeat(2, 1fr)" gap="16px 64px" className="mbe-24">
            <Form.Item
              name="gender"
              label={localisedValue({ object: questionData?.gender, key: "title" })}
              required
              rules={rules}
            >
              <RadioButtons
                options={localisedValue({ object: questionData?.gender, key: "options" })}
                getValue={e => localisedValue({ object: e, key: "key" })}
                getLabel={e => localisedValue({ object: e, key: "name" })}
              />
            </Form.Item>
            <Form.Item
              name="maritalStatus"
              label={localisedValue({ object: questionData?.maritalStatus, key: "title" })}
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.maritalStatus, key: "title" })}
                options={getOptions(questionData?.maritalStatus?.options)}
                onChange={(value, option) => {
                  setFieldsValue({ maritalStatus: value })
                }}
              />
            </Form.Item>
            <Form.Item
              name="children"
              label={localisedValue({ object: questionData?.children, key: "title" })}
              rules={rules}
            >
              <RadioButtons
                options={[
                  { label: t("Yes"), value: "true" },
                  { label: t("No"), value: "false" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="nationality"
              label={localisedValue({ object: questionData?.nationality, key: "title" })}
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.nationality, key: "title" })}
                options={getNationalities}
                onChange={(value, option) => {
                  setFieldsValue({ nationality: value })
                }}
              />
            </Form.Item>
            <Form.Item
              name="clientOccupation"
              label={localisedValue({ object: questionData?.clientOccupation, key: "title" })}
              required
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.clientOccupation, key: "placeholder" })}
                options={localisedValue({ object: questionData?.clientOccupation, key: "options" })}
                onChange={(value, option) => {
                  setFieldsValue({ clientOccupation: option.name })
                }}
              />
            </Form.Item>
          </Group>
          <div className="mbe-24">
            <Title level={3}>{t("Purchase Criteria")}</Title>
          </div>
          <Group template="repeat(2, 1fr)" gap="16px 64px" className="mbe-24">
            <Form.Item
              name="clientExist"
              label={localisedValue({ object: questionData?.existingProperty, key: "title" })}
              required
              rules={rules}
            >
              <RadioButtons
                options={localisedValue({ object: questionData?.existingProperty, key: "options" })}
                getValue={e => localisedValue({ object: e, key: "key" })}
                getLabel={e => localisedValue({ object: e, key: "name" })}
              />
            </Form.Item>
            <Form.Item
              name="intent"
              label={localisedValue({ object: questionData?.intent, key: "title" })}
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.intent, key: "title" })}
                options={getOptions(questionData?.intent?.options)}
                onChange={(value, option) => {
                  setFieldsValue({ intent: value })
                }}
              />
            </Form.Item>
            <Form.Item
              name="clientPropertyInterest"
              label={localisedValue({ object: questionData?.clientInterest, key: "title" })}
              required
              rules={rules}
            >
              <RadioButtons options={localisedValue({ object: questionData?.clientInterest, key: "options" })} />
            </Form.Item>
            <Form.Item
              name="clientAreaInterest"
              label={localisedValue({ object: questionData?.clientAreaSize, key: "title" })}
              required
              rules={rules}
            >
              <RadioButtons options={getAreaOptions()} getValue={e => localisedValue({ object: e, key: "value" })} />
            </Form.Item>
            <Form.Item
              name="preferredFinancing"
              label={localisedValue({ object: questionData?.preferredFinancing, key: "title" })}
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.preferredFinancing, key: "title" })}
                options={getOptions(questionData?.preferredFinancing?.options)}
                onChange={(value, option) => {
                  setFieldsValue({ preferredFinancing: value })
                }}
              />
            </Form.Item>
            <Form.Item
              name="preferredCity"
              label={localisedValue({ object: questionData?.preferredCity, key: "title" })}
              rules={rules}
            >
              <SelectInput
                showSearch
                placeholder={localisedValue({ object: questionData?.preferredCity, key: "title" })}
                options={cities}
                onChange={(value, option) => {
                  setFieldsValue({ preferredCity: value, preferredRegion: null, preferredLocality: null })
                  getLocalities(option.id)
                }}
              />
            </Form.Item>
            {!!form.getFieldValue("preferredCity") && (
              <Form.Item
                name="preferredRegion"
                label={localisedValue({ object: questionData?.preferredRegion, key: "title" })}
                rules={rules}
              >
                <SelectInput
                  showSearch
                  placeholder={localisedValue({ object: questionData?.preferredRegion, key: "title" })}
                  options={getOptions(questionData?.preferredRegion?.options)}
                  onChange={(value, option) => {
                    setFieldsValue({ preferredRegion: value })
                  }}
                />
              </Form.Item>
            )}
            {!!form.getFieldValue("preferredCity") && localities?.length !== 0 && (
              <Form.Item
                name="preferredLocality"
                label={localisedValue({ object: questionData?.preferredLocality, key: "title" })}
                rules={rules}
              >
                <SelectInput
                  showSearch
                  placeholder={localisedValue({ object: questionData?.preferredLocality, key: "title" })}
                  options={localities}
                  onChange={(value, option) => {
                    setFieldsValue({ preferredLocality: value })
                  }}
                />
              </Form.Item>
            )}
          </Group>
          <Form.Item
            name="clientBudgetRange"
            label={localisedValue({ object: questionData?.clientBudget, key: "title" })}
            required
            rules={rules}
          >
            <PriceSlider
              range={[
                +questionData?.clientBudget.options?.[0].minValue,
                +questionData?.clientBudget.options?.[0].maxValue,
              ]}
              steps={100000}
              setField={setFieldsValue}
              disable
            />
          </Form.Item>
          <Form.Item
            name="clientBuyingIntent"
            label={localisedValue({ object: questionData?.clientInvestmentPlan, key: "title" })}
            required
            rules={rules}
          >
            <RadioButtons options={localisedValue({ object: questionData?.clientInvestmentPlan, key: "options" })} />
          </Form.Item>
        </Form>
      </>
    </Modal>
  )
}
