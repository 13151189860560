import { localisedValue } from "utils"
import { t } from "i18next"
const getTaskTypeTransformHelper = (response, args) => {
  let teleSalesdata = 0
  const filteredData = response?.leadStatuses.filter(arr => {
    return arr.key !== "new" && arr.key !== "sales"
  })
  const data = filteredData.map(e => {
    return {
      ...e,
      id: e.id,
      name: localisedValue({ object: e, key: "name" }),
      subTask: e.children,
      nextTask: e.nextStatus,
    }
  })
  const nextStatus = response?.nextStatuses.map(e => {
    return {
      ...e,
      name: localisedValue({ object: e, key: "statusTitleTodo" }),
    }
  })
  return {
    leadStatuses: data,
    nextStatuses:
      args?.params?.teamType == "telesales"
        ? [...nextStatus]
        : args?.extra?.type =="sales"
        ? [
            { id: 0, key: "do_nothing", name: t("Do Nothing"), statusTitleTodo: t("Do Nothing") },
            ...response?.nextStatuses.filter(e => [33,45,9].includes(e.id)), // ids for cdp,pdp,token
          ]
        : 
        [
            { id: 0, key: "do_nothing", name: t("Do Nothing"), statusTitleTodo: t("Do Nothing") },
            ...response?.nextStatuses,
        ],
  }
}
export default getTaskTypeTransformHelper
