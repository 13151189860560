import { DATE_TIME_FORMAT } from "constants"
import parentApi from "store/parentApi"
import { convertQueryObjToString } from "utils"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import clientAnswerTransformer from "./clientAnswerTransformer"
import clientQuestionTransformer from "./clientQuestionTransformer"
import getTaskTypeCategoriesTransformHelper from "./getTaskTypeCategoriesTransformHelper"
import { dateTimeFunction } from "utils/date"
import taskStatusTransformer from "features/leads/api/taskStatusTransformer"
import moment from "moment"

const taskFormApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getTaskTypeCategories: build.query({
      query: params => `taskTypes/hierarchy?${convertQueryObjToString(params)}`,
      transformResponse: response => {
        const transformedData = getTaskTypeCategoriesTransformHelper(response)
        return transformedData
      },
    }),
    getCellNumber: build.query({
      query: clientId => `otps/cells?clientId=${clientId}`,
    }),

    addTask: build.mutation({
      query: ({ lead, encodedFile, verifyMeeting, ...values }) => {
        const payload = {
          ...(!!values.voiceComment && {
            commentVoiceRecording: {
              data: values.voiceComment.split(",")[1],
              name: "voice-recording.webm",
              type: "audio/webm",
            },
          }),
          statusId: values?.subofSubTask ? values?.subofSubTask : values?.subTask,
          ...(values?.nextTask !== 0 && { nextStatusId: values?.nextTask }),
          status: "successful",
          projectId: values?.project?.projectId ? +values?.project?.projectId : +lead.Project.projectId,
          classificationId: values.leadsClassification ? +values.leadsClassification : +values.tsrClassification,
          tsrClassificationId: values.tsrClassification ? +values.tsrClassification : +values.leadsClassification,
          verified: verifyMeeting === "fullfiled",
          ...(encodedFile && {
            attachments: encodedFile
              ? encodedFile.map(e => {
                  return { file: e.file.split(",")[1], name: e.name }
                })
              : null,
          }),
          ...(values?.deadLine && {
            nextStatusDeadline: dateTimeFunction({
              date: values?.timeSlot
                ? moment(values?.dateSlot).format(DATE_TIME_FORMAT)
                : moment(values?.deadLine).format(DATE_TIME_FORMAT),
              timeZone: true,
              apiRequest: true,
            }),
          }),
          completionDate: dateTimeFunction({
            date: values.completionDate.format(DATE_TIME_FORMAT),
            timeZone: true,
            apiRequest: true,
          }),
          comments: values.comment,
          ...(values.timeSlot && {
            calenderEvent: {
              date: dateTimeFunction({ date: values?.dateSlot, timeZone: true, apiRequest: true })?.toString(),
              start: dateTimeFunction({ date: values.timeSlot[0], timeZone: true, apiRequest: true })?.toString(),
              end: dateTimeFunction({ date: values.timeSlot[1], timeZone: true, apiRequest: true })?.toString(),
            },
          }),

          source: "web_v2",
        }
        return { url: `/internalInquiries/${lead?.id}/task/v2`, method: "POST", body: payload }
      },
      invalidatesTags: (result, error, args) => {
        return result ? [{ type: "leads", id: args.lead.Client.id }, "taskLogs", "client-detail", "leads", "todos"] : []
      },
    }),
    createOtp: build.mutation({
      query: ({ selectedNumber, lead }) => {
        const payload = { cell: selectedNumber, clientId: lead?.clientId, inquiryId: lead?.id, userId: lead?.User?.id }
        return { url: "otps/generate", method: "POST", body: payload }
      },
    }),
    verifyOtp: build.mutation({
      query: ({ otpNumber, lead }) => {
        const payload = { otp: otpNumber, clientId: lead?.clientId, inquiryId: lead?.id, userId: lead?.User?.id }
        return { url: "otps/verify", method: "PUT", body: payload }
      },
    }),

    getClientQuestions: build.query({
      query: () => `clientProfileQuestions`,
      transformResponse: response => {
        const transformedData = clientQuestionTransformer(response)
        return transformedData
      },
    }),
    getClientAnswer: build.query({
      query: id => `clients/attemptedAnswers/${id}`,
      transformResponse: response => {
        const transformedData = clientAnswerTransformer(response)
        return transformedData
      },
    }),

    getTaskListing: build.query({
      query: data => {
        let params = { ...data }
        const DateTime = (time) => {
          const dateTime = moment().startOf('day').set({
            hour: moment(time, 'hh:mm:ss A').hour(),
            minute: moment(time, 'hh:mm:ss A').minute(),
            second: moment(time, 'hh:mm:ss A').second()
          })
        return dateTimeFunction({
            date: dateTime.format(DATE_TIME_FORMAT),
            apiRequest: true,
            timeZone: true,
          })
          
        }
        const checkDate = key => {
          if (!!params[key]) {
            if(key=='taskAdded'){
              const dateR = params[key].split(",")
              params[key] = encodeURIComponent(`{"from":"${dateR[0]}","to":"${dateR[1]}"}`)
            }
            else{
              const dateR = params[key].split(",")
              params[key] = encodeURIComponent(`{"from":"${DateTime(dateR[0].trim())}","to":"${DateTime(dateR[1].trim())}"}`)
            }
          }
        }
        
        if(params.hasOwnProperty('taskAddedTime'))
        {
          checkDate("taskAddedTime")
        }
        if(params.hasOwnProperty('taskAdded')){
          checkDate("taskAdded")
        }
        return `/tasks/search?${convertQueryObjToString(params)}`
      },
      transformResponse: data => ({
        ...data,
        rows: data?.rows?.map(item => ({ ...item, privileges: createPrivilegeObject(item.privileges) })),
      }),
    }),
    getTaskTypes: build.query({
      query: () => `/taskTypes?taskTypeFor=lead&hierarchy=true`,
      transformResponse: data => taskStatusTransformer(data),
    }),

    addAnswers: build.mutation({
      query: ({ values, client }) => {
        const area = {
          unit: "Square Feet",
          max: values.clientAreaInterest,
        }
        const payload = {
          ...(!!values?.city && { cityId: values.city }),
          ...(!!values?.country && { countryId: values.country }),
          ...(!!values?.locality && { localityId: values.locality }),
          ...(!!values?.existingLocality && { existingLocalityId: values.existingLocality }),
          ...(!!values?.existingCity && { existingCityId: values.existingCity }),
          ...(!!values?.existingCountry && { existingCountryId: values.existingCountry }),
          ...(!!values?.clientPropertyType && { existingTypeId: values.clientPropertyType?.id }),
          ...(!!values?.clientPropertySubType && { existingSubTypeId: values.clientPropertySubType?.id }),
          ...(!!values?.gender && { gender: values.gender }),
          ...(!!values?.clientOccupation && { occupation: values.clientOccupation }),
          ...(!!values?.clientIndustry && { industryId: values.clientIndustry?.id }),
          ...(!!values?.clientType && { clientUserType: values?.clientType }),
          ...(!!values?.clientExist && { hasExistingProperty: values?.clientExist === "yes" ? true : false }),
          ...(!!values?.clientBudgetRange &&
            values.clientBudgetRange?.[1] && { budget: values.clientBudgetRange?.[1] }),
          ...(!!values?.clientBuyingIntent && { buyingIntentId: values.clientBuyingIntent }),
          ...(!!values?.clientPropertyInterest && { interestedTypeId: values.clientPropertyInterest }),
          ...(!!values?.clientAreaInterest && {
            area: area,
          }),
          ...(!!values?.maritalStatus && { maritalStatus: values.maritalStatus }),
          ...(!!values?.children && { children: values.children }),
          ...(!!values?.intent && { intent: values.intent }),
          ...(!!values?.nationality && { nationalityId: values.nationality }),
          ...(!!values?.preferredFinancing && { preferredFinancing: values.preferredFinancing }),
          ...(!!values?.preferredCity && { preferredCityId: values.preferredCity }),
          ...(!!values?.preferredRegion && { preferredCityRegion: values.preferredRegion }),
          ...(!!values?.preferredLocality && { preferredLocalityId: values.preferredLocality }),
        }
        return { url: `/clients/${client?.id}/answers`, method: "PUT", body: payload }
      },
    }),
  }),
})

export const {
  useGetCellNumberQuery,
  useLazyGetCellNumberQuery,
  useAddTaskMutation,
  useCreateOtpMutation,
  useVerifyOtpMutation,
  useGetClientQuestionsQuery,
  useLazyGetClientQuestionsQuery,
  useLazyGetClientAnswerQuery,
  useAddAnswersMutation,
  useGetTaskTypeCategoriesQuery,
  useGetTaskListingQuery,
  useGetTaskTypesQuery,
} = taskFormApi
