import Cookies from "js-cookie"

const getAgency = () => {
  if (typeof window !== "undefined" && process.env.REACT_APP_NODE_ENV === "production") {
    const agency = window && window.location.host.split(".")[0]
    if (agency === "bayut" || agency === "sandbox") return agency
    return "zameen"
  } else {
    return Cookies.get("tenant") === "KSA" ? "bayut" : "zameen"
  }
}

export const AGENCY = getAgency()
