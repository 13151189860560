import { Icon, Text } from "components/common"

import DateFormat from "../dateFormat/dateFormat"
import theme from "utils/themeVars"

const SenderReceiver = props => {
  return (
    <div style={props.style}>
      <div>
        {props.sender ? <>{props.sender}&nbsp; </> : null}
        {props.icon && (
          <>
            <Icon
              icon={props.icon}
              color={theme["primary-color"]}
              size="1.6em"
              style={{ translate: "0 4px", ...props.iconProps?.style }}
              {...props.iconProps}
            />{" "}
            &nbsp;
          </>
        )}
        <Text type="primary">{props.receiver}</Text>
      </div>

      <DateFormat className="gray-800" date={props.date} />
    </div>
  )
}

export default SenderReceiver
