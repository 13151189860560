import { Checkbox, Form, Radio, Row } from "antd"
import {
  Button,
  DatePicker,
  Group,
  ImageUpload,
  InputNumber,
  Legend,
  Modal,
  SelectInput,
  Text,
  TextInput,
  Title,
  notification,
} from "components/common"
import { useCallback, useMemo, useRef, useState } from "react"
import {
  useGetProjectsQuery,
  useLazyGetPossessionAndInstalmentInfoQuery,
  useGenerateProposalMutation,
  useLazyGetDesignationDiscountsQuery,
} from "features/shared/api"
import { useWatch } from "antd/lib/form/Form"
import { IconText } from "components/custom"
import { theme } from "configs"
import { useLazyGetLeadsQuery } from "features/clients/detail/api"
import { schemaRules } from "features/shared/utils"
import { useSelector } from "react-redux"
import { numberConvertor, roundValues } from "utils"
import styles from "./index.module.less"
import getProposalValidationSchema from "./proposalGenerationValidationSchema"
import { useLazyGetUnitsQuery } from "features/leads/api"
import { useQueryUpdate } from "hooks"
import PreviewProposalModal from "./previewProposalModal"
import cx from "clsx"
import { useTranslation } from "react-i18next"

export default function ProposalGenerationForm({ formRef, from, inventory }) {
  const { t } = useTranslation()
  const { data: projectData, isFetching: isFetchingProjects } = useGetProjectsQuery({
    skipPpaExpired: true,
    financials: true,
    paymentInfo: true,
    withSaleableListings: true,
    status: "on",
  })
  const [getUnits, { data: units, isFetching: isFetchingUnits }] = useLazyGetUnitsQuery()
  const [getLeads, { data: leads, isFetching: isFetchingLeads, isErrorSearchLeads, errorSearchLead }] =
    useLazyGetLeadsQuery()
  const [getDesignationDiscounts, { data: designationDiscounts }] = useLazyGetDesignationDiscountsQuery()
  const [getPossessionAndInstalment, { data: possessAndIntalData, isFetching: isFetchingPossesionAndInstalments }] =
    useLazyGetPossessionAndInstalmentInfoQuery()
  const [generateProposal, { isLoading, error, reset: resetGenerateProposal, data }] = useGenerateProposalMutation()

  const [form] = Form.useForm()
  const pdfViewRef = useRef()
  const rules = schemaRules(getProposalValidationSchema())
  const { setFieldsValue } = form
  const projects = Form.useWatch("projects", form)
  const unit = Form.useWatch("unitNo", form)
  const paymentPlan = Form.useWatch("paymentPlanCheckbox", form)
  const unitFloorPlan = Form.useWatch("unitFloorPlanCheckbox", form)
  const uploadAttachment = Form.useWatch("uploadAttachments", form)
  const leadId = Form.useWatch("leadId", form)
  const clientsLeadId = useWatch("clientId", form)
  const months = useWatch("months", form)
  const discountPercentage = Form.useWatch("discountPercentage", form)
  const cdp = useWatch("cdp", form)
  const possessionAmount = useWatch("possessionAmount", form)
  const fixedFeaturesPrice = useWatch("featurePrice", form)
  const [updateQuery] = useQueryUpdate()
  const [formState, setFormState] = useState()

  const currency = useSelector(
    state =>
      state?.app?.user.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode
  )

  let totalDealPrice = unit?.unitPrice + unit?.totalFixedFeatureCharges

  const onModalVisible = useCallback(() => {
    if (from?.name === "inventory" || from?.name === "project") {
      let projectObj = projectData?.find(item => item?.projectId === from?.projectId)
      setFieldsValue({ projects: projectObj })
      getUnits(from?.projectId, true)
        .unwrap()
        .then(data => {
          if (from?.name === "inventory") {
            let unitsObj = data?.find(item => item?.value === from?.unitNumber)
            setFieldsValue({ unitNo: unitsObj })
            getDesignationDiscounts({ projectId: from?.projectId, listingId: unitsObj?.listingId }, true)
            getPossessionAndInstalment(
              { projectId: from?.projectId, listingId: unitsObj?.listingId, dealPrice: unitsObj?.unitPrice },
              true
            )
              .unwrap()
              .then(data => {
                form.setFieldsValue({
                  unitType: unitsObj?.Type?.name,
                  discountPercentage: 0,
                  bookingPrice: unitsObj?.unitPrice + unitsObj?.totalFixedFeatureCharges,
                  publishedPrice: unitsObj?.unitPrice,
                  featurePrice: unitsObj?.totalFixedFeatureCharges,
                  cdp:
                    roundValues(
                      (unitsObj?.unitPrice + unitsObj?.totalFixedFeatureCharges) *
                        (+projectObj?.ProjectFinancial?.completeDownPayment / 100)
                    ) || 0,
                  possessionAmount: roundValues(
                    (unitsObj?.unitPrice + unitsObj?.totalFixedFeatureCharges) *
                      ((data?.possessionPercentage || 0) / 100)
                  ),
                })
              })
          }
        })
    }
  }, [from, projectData])

  const onSubmit = async values => {
    const response = await generateProposal({
      listingId: unit?.listingId,
      inquiryId: leadId || from?.listingId,
      payload: values,
      floorPlan: uploadAttachment,
      preview: true,
    })
      .unwrap()
      .then(url => {
        setFormState({
          ...values,
          floorPlan: uploadAttachment,
          listingId: unit?.listingId,
          inquiryId: leadId || from?.listingId,
          imageData: url,
        })
        pdfViewRef?.current?.setVisible(true)
        formRef?.current?.setVisible(false)
      })
      .catch(error => {
        notification.error({ message: error?.message })
      })

    if (!response?.error) {
      resetGenerateProposal()
    }
  }

  const onSearch = useCallback(() => {
    getLeads({ id: leadId })
    return leads
  }, [leadId])

  const monthOptions = useMemo(() => {
    const monthData = []

    for (let i = 1; i <= possessAndIntalData?.instalment; i++) {
      monthData.push({ label: i, value: i })
    }

    return monthData
  }, [possessAndIntalData])

  const installmentTypeOptions = useMemo(() => {
    let data = [
      { label: t("Monthly"), value: "monthly" },
      +months % 3 === 0 ? { label: t("Quarterly"), value: "quarterly" } : null,
      +months % 6 === 0 ? { label: t("BiAnnually"), value: "bi_annually" } : null,
    ].filter(option => option !== null)

    return data
  }, [months])

  const onHandleProjectChange = (value, option) => {
    setFieldsValue({
      projects: option,
      unitNo: null,
      discountPercentage: 0,
      cdp: 0,
      bookingPrice: null,
      publishedPrice: null,
      featurePrice: null,
      possessionAmount: null,
    })
    getUnits(option?.projectId)
  }

  const onHandleUnitNoChange = (value, option) => {
    setFieldsValue({
      unitNo: option,
      discountPercentage: 0,
      bookingPrice: roundValues(option?.unitPrice + option?.totalFixedFeatureCharges),
      publishedPrice: roundValues(option?.unitPrice),
      featurePrice: roundValues(option?.totalFixedFeatureCharges),
      cdp:
        roundValues(
          (+option?.unitPrice + option?.totalFixedFeatureCharges) *
            (+projects?.ProjectFinancial?.completeDownPayment / 100)
        ) || 0,
      unitType: option?.Type?.name,
    })
    getDesignationDiscounts({ projectId: projects?.projectId, listingId: option?.listingId }, true)
    getPossessionAndInstalment(
      { projectId: projects?.projectId, listingId: option?.listingId, dealPrice: option?.unitPrice },
      true
    )
      .unwrap()
      .then(data => {
        setFieldsValue({
          possessionAmount: roundValues(
            (option?.unitPrice + option?.totalFixedFeatureCharges) * ((data?.possessionPercentage || 0) / 100)
          ),
        })
      })
  }

  const onHandleDiscPercChange = value => {
    let bookingPrice = totalDealPrice - (+value / 100) * (unit?.unitPrice + unit?.totalFixedFeatureCharges || 0)
    form.setFieldsValue({
      discountPercentage: value || 0,
      bookingPrice: roundValues(bookingPrice),
      cdp: roundValues(bookingPrice * (+projects?.ProjectFinancial?.completeDownPayment / 100)) || 0,
      possessionAmount: roundValues(bookingPrice * ((possessAndIntalData?.possessionPercentage || 0) / 100)),
    })
  }

  return (
    <div>
      <Modal
        maskClosable={false}
        title={t("Project Proposal")}
        ref={formRef}
        okText={t("Generate")}
        disableOkButton={!clientsLeadId && from?.name !== "lead"}
        width={880}
        cancelText={t("Cancel")}
        loading={isLoading}
        onOk={form.submit}
        footerAlert={error}
        destroyOnClose={true}
        scrollToFirstError
        onModalVisible={onModalVisible}
        onCancel={() => {
          resetGenerateProposal()
          formRef?.current?.setVisible(false)
          if (from?.name !== "lead") {
            updateQuery("getLeads", draft => null)
          }
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="proposalForm"
          scrollToFirstError
          preserve={true}
          onFinish={onSubmit}
          disabled={isFetchingProjects}
        >
          <Legend title={t("Project & Lead Details")} />

          {from?.name !== "lead" && (
            <>
              <Group template="315px 1fr" gap="6px 24px" style={{ alignItems: "flex-start" }}>
                <Form.Item
                  name="leadId"
                  label={t("Lead ID")}
                  required
                  validateStatus={isErrorSearchLeads ? "error" : undefined}
                  rules={rules}
                >
                  <TextInput
                    value={leadId}
                    placeholder={t("Search By Lead ID")}
                    onChange={e => setFieldsValue({ leadId: e.target.value })}
                  />
                  <div>
                    {(isErrorSearchLeads || leads?.data?.length === 0) && (
                      <IconText
                        icon="MdInfoOutline"
                        iconProps={{ color: "red", size: "1em" }}
                        textType={"danger"}
                        title={
                          isErrorSearchLeads
                            ? errorSearchLead?.message
                            : leads?.data?.length === 0
                            ? t("Leads Not Found")
                            : ""
                        }
                      />
                    )}
                  </div>
                </Form.Item>
                <div style={{ top: "30px", position: "relative" }}>
                  <Button
                    loading={isFetchingLeads}
                    type="primary"
                    size="large"
                    onClick={onSearch}
                    icon="FiSearch"
                    text={t("Search")}
                    style={{ height: 48 }}
                    disabled={leadId ? false : true}
                  />
                </div>
              </Group>
              <Form.Item name="clientId" hidden={!leads?.data?.length || !!inventory?.leadId}>
                {!!inventory?.leadId ? (
                  setFieldsValue({ clientId: inventory?.leadId })
                ) : (
                  <Radio.Group
                    onChange={e => {
                      form.setFieldsValue({
                        clientId: e.target.value,
                      })
                    }}
                    className="w-100"
                  >
                    <Group template="repeat(3,1fr)" gap="16px">
                      {!!leads?.data?.length &&
                        leads?.data?.slice(0, 3).map(e => (
                          <Radio className={styles.cardRadio} value={e?.id} key={e?.id}>
                            <Text type="secondaryLight" block>
                              ID: {e?.id}
                            </Text>
                            <Text>{e?.clientName}</Text>
                          </Radio>
                        ))}
                    </Group>
                  </Radio.Group>
                )}
              </Form.Item>
            </>
          )}

          <Group template="repeat(3, 1fr)" gap="6px 32px">
            <Form.Item name="projects" label={t("Select Project")} required rules={rules}>
              <SelectInput
                placeholder={t("Select Project")}
                options={projectData || []}
                disabled={from?.name !== "lead"}
                onChange={onHandleProjectChange}
              />
            </Form.Item>
            <Form.Item name="unitNo" label={t("Unit No")} required rules={rules}>
              <SelectInput
                inputLoading={isFetchingUnits}
                placeholder={t("Select Unit No")}
                options={units || []}
                onChange={onHandleUnitNoChange}
                disabled={(!clientsLeadId || !leadId || from?.name === "inventory") && from?.name !== "lead"}
              />
            </Form.Item>

            <Form.Item name="unitType" label={t("Unit Type")}>
              <TextInput placeholder={t("Unit Type")} disabled />
            </Form.Item>
          </Group>

          {projects && unit && (from?.name !== "lead" ? !!clientsLeadId : true) && (
            <>
              <Legend title={t("Payment Details")} />
              <Group template="repeat(2, 1fr)" gap="6px 32px">
                <Form.Item name="publishedPrice" label={t("Published Price")}>
                  <TextInput
                    loading={isFetchingPossesionAndInstalments}
                    placeholder={t("Published price")}
                    suffixText={currency}
                    disabled
                  />
                </Form.Item>

                <Form.Item name="featurePrice" label={t("Features Price")}>
                  <TextInput
                    loading={isFetchingPossesionAndInstalments}
                    placeholder={t("Feature price")}
                    suffixText={currency}
                    value={fixedFeaturesPrice}
                    disabled
                  />
                  <div>
                    <IconText
                      icon="MdInfoOutline"
                      iconProps={{ color: theme["gray800"], size: "1em" }}
                      textType="secondary"
                      title={t("Cost of Fixed Features")}
                    />
                  </div>
                </Form.Item>

                <Form.Item name="discountPercentage" label={t("Discount Applied")} rules={rules} required>
                  <InputNumber
                    precision={2}
                    value={discountPercentage}
                    style={{ width: "100%" }}
                    placeholder={t("Please add percentage discount %")}
                    max={+designationDiscounts?.[0]?.discount || 0}
                    min={0}
                    controls={false}
                    addonAfter={`${roundValues(
                      ((+discountPercentage || 0) / 100) * (totalDealPrice || 0)
                    )} ${currency}`}
                    onChange={onHandleDiscPercChange}
                    disabled={!designationDiscounts?.[0]?.discount && true}
                  />
                  <div>
                    <IconText
                      icon="MdInfoOutline"
                      iconProps={{ color: theme["gray800"], size: "1em" }}
                      textType="secondary"
                      title={`${t("Maximum Discount")}: ${designationDiscounts?.[0]?.discount || 0}%`}
                    />
                  </div>
                </Form.Item>

                <Form.Item name="bookingPrice" label={t("Booking Price")} required rules={rules}>
                  <TextInput
                    loading={isFetchingPossesionAndInstalments}
                    placeholder={t("Booking price")}
                    suffixText={currency}
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  name="cdp"
                  label={t("Complete Down Payment")}
                  rules={rules}
                  required
                  style={{ marginBottom: "32px" }}
                >
                  <TextInput value={cdp} placeholder={t("Completed down payment")} suffixText={currency} disabled />
                  <div>
                    <IconText
                      icon="MdInfoOutline"
                      iconProps={{ color: theme["gray800"], size: "1em" }}
                      textType="secondary"
                      title={`${numberConvertor(projects?.ProjectFinancial?.completeDownPayment || 0, "local", 2)}% ${t(
                        "of Deal Price"
                      )}`}
                    />
                  </div>
                </Form.Item>

                <Form.Item name="possessionAmount" label={t("Possession Amount")} rules={rules} required>
                  <TextInput
                    value={possessionAmount}
                    loading={isFetchingPossesionAndInstalments}
                    placeholder={t("Possession amount")}
                    suffixText={currency}
                    disabled
                  />
                  <IconText
                    icon="MdInfoOutline"
                    iconProps={{ color: theme["gray800"], size: "1em" }}
                    textType="secondary"
                    title={`${numberConvertor(possessAndIntalData?.possessionPercentage || 0, "local", 2)}% ${t(
                      "of Deal Price"
                    )}`}
                  />
                </Form.Item>

                <Form.Item
                  className={cx("span-all", styles.leadDetailForm)}
                  name="paymentPlanCheckbox"
                  label=""
                  valuePropName="checked"
                >
                  <Checkbox onChange={e => form.setFieldValue("paymentPlanCheckbox", e.target.checked)}>
                    <Title className="mbe-0" level={4}>
                      {t("Payment Plan")}
                    </Title>
                  </Checkbox>
                </Form.Item>

                {paymentPlan && (
                  <>
                    <Form.Item name="startDate" label={t("Start Date")} required rules={rules}>
                      <DatePicker disablePast showTime={false} placeholder={t("Select Date")} />
                    </Form.Item>

                    <Form.Item
                      name="months"
                      label={t("Instalment Duration")}
                      required
                      rules={rules}
                      help={`${t("Maximum Instalments Duration ")}: ${possessAndIntalData?.instalment || 0} ${t(
                        "Months"
                      )}`}
                    >
                      <SelectInput
                        placeholder={t("Select months")}
                        options={monthOptions}
                        allowClear
                        onChange={value => {
                          setFieldsValue({ months: value })
                          form.resetFields(["installmentFrequency"])
                        }}
                      />
                    </Form.Item>

                    <Form.Item name="installmentFrequency" label={t("Instalment Frequency")} required rules={rules}>
                      <SelectInput
                        options={installmentTypeOptions}
                        placeholder={t("Select Instalment Frequency")}
                        allowClear
                      />
                    </Form.Item>
                  </>
                )}
              </Group>

              <Form.Item
                className={cx("span-all", styles.leadDetailForm)}
                name="unitFloorPlanCheckbox"
                valuePropName="checked"
              >
                <Checkbox onChange={e => form.setFieldValue("unitFloorPlanCheckbox", e.target.checked)}>
                  <Title className="mbe-0" level={4}>
                    {t("Unit Floor Plan")}
                  </Title>
                </Checkbox>
              </Form.Item>
              {unitFloorPlan && (
                <Form.Item
                  name="uploadAttachments"
                  label={t("Attachments")}
                  required={unitFloorPlan}
                  dependencies={[unitFloorPlan]}
                  rules={rules.concat([
                    () => ({
                      validator(_, value) {
                        if (value?.fileList?.length == 0 && unitFloorPlan) {
                          return Promise.reject(new Error(t("Attachment is required")))
                        }
                        return Promise.resolve()
                      },
                    }),
                  ])}
                >
                  <ImageUpload
                    withDimensionLimit={false}
                    picture={true}
                    noCard={true}
                    setFiles={data => form.setFieldsValue({ uploadAttachment: data })}
                    files={uploadAttachment?.fileList}
                    className={styles.uploadID}
                    uploadDescription={t("JPG, or PNG file format up till 1 MB")}
                  />
                </Form.Item>
              )}

              <div>
                <Legend title={t("Proposal Settings")} />
                <Text type="secondary">{t("Please tick the checkbox below to customize your proposal.")}</Text>
                <Form.Item
                  name={"proposalSettings"}
                  initialValue={[
                    "showClientName",
                    "showUnitNetArea",
                    "showUnitGrossArea",
                    "showPossessionAmount",
                    "includeBusinessCard",
                  ]}
                  required
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={list => {
                      form.setFieldsValue({ proposalSettings: list })
                    }}
                  >
                    <Row>
                      <Checkbox value="showUnitNetArea">
                        <Text type="secondary">{t("Show Unit Net Area")}</Text>
                      </Checkbox>
                    </Row>
                    <Row>
                      <Checkbox value="showUnitGrossArea">
                        <Text type="secondary">{t("Show Unit Gross Area")}</Text>
                      </Checkbox>
                    </Row>
                    <Row>
                      <Checkbox value="showPossessionAmount">
                        <Text type="secondary">{t("Show Possession Amount")}</Text>
                      </Checkbox>
                    </Row>
                    <Row>
                      <Checkbox value="generateBookingForm">
                        <Text type="secondary">{t("Generate Booking Form PDF")}</Text>
                      </Checkbox>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <IconText
                  icon="MdInfoOutline"
                  iconProps={{ color: theme["gray800"], size: "1em" }}
                  textType="secondary"
                  title={t("A task of proposal will be added on the selected lead")}
                />
              </div>
            </>
          )}
        </Form>
      </Modal>
      <PreviewProposalModal
        pdfViewRef={pdfViewRef}
        formRef={formRef}
        formState={formState}
        from={from}
        form={form}
        isLoading={isLoading}
        error={error}
        generateProposal={generateProposal}
        resetGenerateProposal={resetGenerateProposal}
      />
    </div>
  )
}
