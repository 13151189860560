import { Group, Text, Title, notify } from "components/common"
import { Modal, Row, Upload } from "antd"

import { IconText } from "components/custom"
import Lottie from "react-lottie"
import animationData from "animation/uploadFileAnimation"
import cx from "clsx"
import { base64ToBlob, fileToBase64 } from "utils"
import styles from "./index.module.less"
import theme from "utils/themeVars"
import { useState } from "react"
import { t } from "i18next"

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

const handleBlobPreview = (file) => {
  const base64String = file?.url?.split(",")[1]
  const blob = base64ToBlob(base64String, file?.type)
  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl)
}

const onPreview = async file => {
  let src = file.url
  if (isValidUrl(src)) {
    if (!src.includes("http")) {
      handleBlobPreview(file)
    }
    else{
      window.open(src)
    }
  } else {
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }
}

export default function UploadDraggerS3({
  files,
  setFiles,
  setIsImageUploading = () => {},
  title,
  allowedFiles = "image/png, image/jpeg, application/pdf",
  setFilesList,
  path,
  uploadTitle = t("Click or drag file to this area to upload"),
  uploadDescription = t("Must be in JPG, PNG or PDF file format up till 5 MB Upload all the evidence screenshots, chat conversation or emails that help in your claim."),
  maxCount = 1,
  hideUpload,
  onFileUploadError = () => {},
  onFileUploadSuccess = () => {},
  setLoading = () => {},
  withLocalPath,
  popupPreviewProp,
  extraInformation,
  uploadClass,
  justLottie,
  allowedFileSize = 10,
  isduplicateFileAllowed = false,
  multiple,
  filesList,
  attachments,
  additions,
  uploadOuterWidth,
  hideDelete,
  onAttachmentsEdit = () => {},
  ...rest
}) {
  const defaultOptions = { loop: true, autoplay: true, animationData: animationData }
  const [file, setFile] = useState({ previewOpen: false })

  const handleCancel = () => setFile(prev => ({ ...prev, previewOpen: false }))

  const popupPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await fileToBase64(file.originFileObj)
    }
    setFile(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewTitle: file.name || file.url?.substring(file.url.lastIndexOf("/") + 1),
        previewOpen: true,
      }
    })
  }

  const beforeUpload = e => {
    if (e?.size / 1024 / 1024 > allowedFileSize) {
      notify.error(t("File size must be within") + ` ${allowedFileSize} MB!`)
      return Upload.LIST_IGNORE
    } else if (!isduplicateFileAllowed && files?.find(efile => efile.lastModified === e.lastModified)) {
      notify.error(t("Cannot add duplicate file"))
      return Upload.LIST_IGNORE
    } else if (!allowedFiles.split(", ").includes(e.type)) {
      notify.error(t("File Must be in") + ` ${allowedFiles} ` + t("format"))
      return Upload.LIST_IGNORE
    } else {
      fileToBase64(e).then(base64File => {
        setFiles(prev => {
          const newFiles = [
            ...prev,
            { ...e, lastModified: e.lastModified, name: e.name, url: base64File, type: e.type },
          ]
          onAttachmentsEdit(true)
          return newFiles
        })
      })
      return false
    }
  }
  const onRemove = e => {
    setFiles(file => {
      const newFiles = file.filter(file => file.uid !== e.uid)
      onAttachmentsEdit(true)
      return newFiles
    })
  }

  return (
    <div>
      {!!title && <Title level={3}>{title}</Title>}
      <div className={cx(styles.uploadOuter, justLottie && styles.rounded, uploadOuterWidth)}>
        {extraInformation && extraInformation}
        <Upload.Dragger
          multiple={multiple}
          beforeUpload={beforeUpload}
          accept={allowedFiles ? allowedFiles : "image/png, image/jpeg,.pdf,.xls"}
          onPreview={popupPreviewProp ? popupPreview : onPreview}
          listType="picture-card"
          maxCount={maxCount}
          className={
            !!additions
              ? cx(styles.upload, rest.disabled, additions?.length == maxCount && styles.hideUploadArea, uploadClass)
              : cx(
                  styles.upload,
                  rest.disabled,
                  (attachments?.fileList?.length == maxCount || files?.length == maxCount) && styles.hideUploadArea,
                  uploadClass
                )
          }
          onRemove={onRemove}
          fileList={!!additions ? additions : attachments?.fileList}
          showUploadList={{
            showRemoveIcon: hideDelete ? false : true,
          }}
          {...rest}
        >
          {!hideUpload && (additions?.length || attachments?.fileList?.length || 0) < maxCount && (
            <Group
              template={!uploadTitle ? "1fr" : "auto 1fr"}
              gap="24px"
              className="p-16"
              style={{ "align-items": "center" }}
            >
              <Lottie options={defaultOptions} height={56} width={56} />
              {uploadTitle && (
                <Row className="text-left" style={{ gap: 8 }}>
                  <Text color={theme["primary-color"]} block>
                    <span className="semiBold">{uploadTitle}</span>
                  </Text>
                  {uploadDescription && (
                    <IconText
                      icon="BsInfo"
                      title={uploadDescription}
                      iconProps={{
                        style: { color: "#707070" },
                        hasBackground: true,
                        size: "1.5em",
                        iconContainerStyle: { marginTop: 4, padding: 0, "--icon-bg-color": "#E6E6E6" },
                      }}
                      style={{ alignItems: "flex-start", "--typography-color": "#9D9D9D", fontSize: 12, gap: 8 }}
                    />
                  )}
                </Row>
              )}
            </Group>
          )}
        </Upload.Dragger>
        {popupPreviewProp && (
          <Modal open={file.previewOpen} title={file.previewTitle} footer={null} onCancel={handleCancel}>
            <img alt={rest.modalImgAlt} style={{ width: "100%" }} src={file.previewImage} />
          </Modal>
        )}
      </div>
    </div>
  )
}
