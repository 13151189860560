import { t } from "i18next"
import { DataTable, Number } from "components/common"

import { IconText } from "components/custom"
import { useMemo } from "react"
import { localisedValue } from "utils"

// const paymentColumns = [
//   { title: t("Payment (%)"), dataIndex: "payment", key: "payment" },
//   { title: t("Unit Type"), dataIndex: "unit", key: "unit" },
//   { title: t("Discount"), dataIndex: "discount", key: "discount" },
// ]

const DiscountPlans = props => {
  const { paymentDiscounts, designationDiscounts } = props
  const paymentTable = useMemo(
    () =>
      paymentDiscounts
        ? paymentDiscounts.map((e, index) => ({
            key: index,
            payment: e.payment,
            unit: localisedValue({ object: e?.Type, key: "name" }) || t("All"),
            discount:
              e?.discountType === "percentage" ? (
                `${e?.discount}%`
              ) : (
                <Number isCurrency isStringOnly value={e?.discount} />
              ),
          }))
        : [],
    [paymentDiscounts]
  )

  const designationColumns = useMemo(
    () => [
      { title: t("Designation"), dataIndex: "designation", key: "designation" },
      { title: t("Unit Type"), dataIndex: "unit", key: "unit" },
      { title: t("Discount"), dataIndex: "discount", key: "discount" },
    ],
    [paymentDiscounts]
  )
  const paymentColumns = useMemo(
    () => [
      { title: t("Payments"), dataIndex: "payment", key: "payment" },
      { title: t("Unit Type"), dataIndex: "unit", key: "unit" },
      { title: t("Discount"), dataIndex: "discount", key: "discount" },
    ],
    [paymentDiscounts]
  )

  const designationTable = useMemo(
    () =>
      designationDiscounts
        ? designationDiscounts.map((e, index) => ({
            key: index,
            designation: localisedValue({ object: e.UserDesignation, key: "name" }),
            unit: e?.Type?.name || t("All"),
            discount:
              e?.discountType === "percentage" ? (
                `${e?.discount}%`
              ) : (
                <Number isCurrency isStringOnly value={e?.discount} />
              ),
          }))
        : [],
    [designationDiscounts]
  )
  return (
    <div style={{ paddingTop: 12 }}>
      {!!paymentDiscounts?.length && (
        <>
          <IconText
            title={t("By Payment")}
            icon={"RiCoinLine"}
            iconProps={{ style: { color: "#9D9D9D" }, hasBackground: true, size: "1em" }}
            gap={8}
            className="mbe-12"
            titleProps={{ strong: true }}
          />
          <DataTable
            tableLayout="auto"
            columns={paymentColumns}
            data={paymentTable}
            noUrlPush={true}
            stickyOffset={-16}
            className="mbe-12"
          />
        </>
      )}
      {!!designationDiscounts?.length && (
        <>
          <IconText
            title={t("By Designation")}
            icon={"SlHandbag"}
            iconProps={{ style: { color: "#9D9D9D" }, hasBackground: true, size: "1em" }}
            gap={8}
            className="mbe-12"
            titleProps={{ strong: true }}
          />
          <DataTable
            tableLayout="auto"
            columns={designationColumns}
            data={designationTable}
            noUrlPush={true}
            stickyOffset={-16}
          />
        </>
      )}
    </div>
  )
}

export default DiscountPlans
