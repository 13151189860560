import { Space } from "antd"
import { Dropdown, Text } from "components/common"
import { IconText } from "components/custom"
import { useTranslation } from "react-i18next"
import theme from "utils/themeVars"

const Sort = props => {
  const { sort, options, onClickItem } = props
  const { t } = useTranslation()  
  return (
    <Space>
      <IconText
        title={t("Sort By")}
        icon={sort?.sortOrder === "desc" ? "IconSortDesc" : "IconSortAsc"}
        iconProps={{
          style: { transform: "translateY(2px)", marginInlineStart: 8 },
          placement: "end",
          color: theme["primary-color"],
        }}
        gap={4}
        textType="secondaryLight"
      />
      <Dropdown options={options} placement="bottomRight" onClickItem={onClickItem} style={{ cursor: "pointer" }}>
        <Text type="primary">{sort?.label}</Text>
      </Dropdown>
    </Space>
  )
}

export default Sort
