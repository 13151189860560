import { localisedValue } from "utils"

const recruitmentDriveTransformer = response => {
  return response?.map(item => {
    return {
      ...item,
      label: localisedValue({ object: item, key: "name" }),
      value: item.id.toString(),
    }
  })
}

export default recruitmentDriveTransformer
