import React from "react"
import { Tag } from "antd"
import { Tooltip } from "antd"

const CardTag = props => {
  return (
    <>
      <Tooltip title={props.tooltipTitle} color="white" overlayInnerStyle={{ color: "black" }}>
        <Tag color={props.color}>{props.title}</Tag>
      </Tooltip>
    </>
  )
}

export default CardTag
