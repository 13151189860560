import { t } from "i18next"
import {
  CALL_RECORDING_PATH,
  CLIENT_LISTING_PATH,
  LEADS_LISTING_PATH,
  LEADS_SHARED_LISTING_PATH,
  QUALITY_CONTROL_PATH,
  TASKS_LISTING_PATH,
} from "./app-paths"

export const genderList = () => [
  { id: 1, label: t("Male"), slug: "male" },
  { id: 2, label: t("Female"), slug: "female" },
  { id: 3, label: t("Others"), slug: "other" },
]

export const selectionType = () => [
  { key: 1, label: t("On"), value: "on" },
  { key: 2, label: t("Off"), value: "off" },
]

export const genericSelectionType = () => [
  { label: t("No"), value: "false" },
  { label: t("Yes"), value: "true" },
]

export const clientClassOptions = () => [
  { label: t("Direct"), value: "direct" },
  { label: t("Affiliate"), value: "affiliate" },
]

export const affiliateOptions = () => [
  { label: t("MOU ID"), value: "mouId" },
  { label: t("Affiliate ID"), value: "id" },
  { label: t("CRM ID"), value: "crmId" },
]

// Array with values {id}-{leadSide}
export const secondaryLeadPurpose = () => [
  { label: t("Buy"), value: "1-demand" },
  { label: t("Sell"), value: "1-supply" },
  { label: t("Rent In"), value: "2-demand" },
  { label: t("Rent Out"), value: "2-supply" },
]

export const propertyTypes = () => [
  { id: 1, label: t("Home"), slug: "home" },
  { id: 2, label: t("Plots"), slug: "plots" },
  { id: 3, label: t("Commercial"), slug: "commercial" },
  { id: 4, label: t("Apartments"), slug: "apartments" },
]

export const leadTypes = () => [
  { id: 1, label: t("Primary"), value: "primary" },
  { id: 2, label: t("Secondary"), value: "secondary" },
]

export const teamTypes = () => [
  { id: 1, label: t("Sales-Primary"), slug: t("Sales-Primary"), value: "sales" },
  { id: 2, label: t("Sales-Secondary"), slug: t("Sales-Secondary"), value: "secondarySales" },
  { id: 3, label: t("Support"), slug: "support", value: "support" },
]

export const taskTypes = () => [
  { id: 1, label: t("Meeting"), slug: "meeting", value: "meeting" },
  { id: 2, label: t("Call"), slug: "call", value: "call" },
  { id: 3, label: t("Sales"), slug: "sales", value: "sales" },
]

export const timeFormats = () => [
  { label: t("12 hours"), value: "12" },
  { label: t("24 hours"), value: "24" },
]

export const callByList = () => [
  { value: "prop_go", label: t("Propgo") },
  { value: "whatsapp", label: t("Whatsapp") },
  { value: "dubai_pbx", label: t("DubaiPBX") },
  { value: "stealth", label: t("Stealth") },
  { value: "intellicon", label: t("Intellicon") },
]

export const roleTypes = () => [
  { label: t("Default Role"), value: "default" },
  { label: t("Special Role"), value: "special" },
]

export const projectTypes = () => [
  { label: t("Active"), value: "on" },
  { label: t("Inactive"), value: "off" },
]

export const meetingVenue = () => [
  { label: t("Company Office"), value: "company_office" },
  { label: t("Site Office"), value: "site_office" },
  { label: t("Out Door"), value: "out_door" },
  { label: t("Sale Event"), value: "sale_event" },
]

export const meetingProbability = () => [
  { label: t("High"), value: "high" },
  { label: t("Medium"), value: "medium" },
  { label: t("Low"), value: "low" },
]

export const calenderFiltersList = () => [
  { label: t("My Meetings"), value: "myMeeting" },
  { label: t("TSR Meetings"), value: "tsrMeeting" },
  { label: t("Sales"), value: "sale" },
  { label: t("Busy"), value: "busy" },
]

export const inActivityDays = () => [
  { label: t("5 days"), value: "5" },
  { label: t("15 days"), value: "15" },
  { label: t("25 days"), value: "25" },
  { label: t("30 days"), value: "30" },
  { label: t("60 days"), value: "60" },
  { label: t("90 days"), value: "90" },
  { label: t("120 days"), value: "120" },
  { label: t("240 days"), value: "240" },
]

export const RoutesMappings = {
  leads: LEADS_LISTING_PATH,
  calls: CALL_RECORDING_PATH,
  tasks: TASKS_LISTING_PATH,
  clients: CLIENT_LISTING_PATH,
  leadsShared: LEADS_SHARED_LISTING_PATH,
  qualityControl: QUALITY_CONTROL_PATH,
}
export const units = () => [
  { label: t("% Age"), value: "percentage" },
  { label: t("Amount"), value: "amount" },
]
export const ratingArray = () => [
  { id: 4, label: t("Excellent") },
  { id: 3, label: t("Good") },
  { id: 2, label: t("Average") },
  { id: 1, label: t("Need Improvement") },
]

export const reportCategories = {
  saleskpi: {
    name: "KPI Sales",
    key: "kpi-sales",
  },
  affiliateskpi: {
    name: "KPI Affiliates",
    key: "kpi-affiliates",
  },
  telesaleskpi: {
    name: "KPI Telesales",
    key: "kpi-telesales",
  },
  productivity: {
    name: "Productivity",
    key: "productivity-report",
  },
  managementkpi: {
    name: "KPI Management",
    key: "kpi-management",
  },
  revenues: {
    name: "Revenue Report",
    key: "revenue-report",
  },
  aiCallAnalysis: {
    name: "AI Call Analysis",
    key: "ai-call-analysis",
  },
}
export const projectsCategories = () => [
  { key: 1, label: t("Medallion"), value: "medallion" },
  { key: 2, label: t("Non Medallion"), value: "nonMedallion" },
]

export const revenueTypes = () => [
  { key: 1, label: t("All"), value: "All" },
  { key: 2, label: t("Invoiced"), value: "invoiced" },
  { key: 3, label: t("Un-Invoiced"), value: "un-invoiced" },
]

export const adjustmentVarients = (t = () => {}) => [
  { label: t("Add"), value: 1 },
  { label: t("Subtract"), value: -1 },
]

export const regions = () => [
  { label: t("Central"), value: "central" },
  { label: t("North"), value: "north" },
  { label: t("East"), value: "east" },
  { label: t("West"), value: "west" },
  { label: t("South"), value: "south" },
]

export const policyStatuses = () => [
  { label: t("Active"), value: "active" },
  { label: t("Inactive"), value: "in_active" },
]

export const enableOrDisableOptions = () => [
  { label: t("Yes"), value: true },
  { label: t("No"), value: false },
]

export const numberTypes = () => [
  { key: 1, label: t("Percentage"), value: "percentage" },
  {
    key: 2,
    label: t("Amount"),
    value: "fixed",
  },
]
export const relationTypes = () => [
  { key: 1, label: t("Is Greater Than"), value: ">" },
  {
    key: 2,
    label: t("Is Less Than"),
    value: "<",
  },
]

export const poolStatuses = () => [
  { label: t("Active"), value: "1" },
  { label: t("Inactive"), value: "0" },
]

export const intentType = () => [
  { key: 1, label: t("Investment"), value: "investment" },
  { key: 2, label: t("Self Occupation (Residence)"), value: "self_occupation" },
]

export const financingType = () => [
  { key: 1, label: t("Mortgage"), value: "mortgage" },
  { key: 2, label: t("Self Finance (Cash)"), value: "self_finance" },
]