export const sharingTransformHelper = response => {
  const { data: sharingData, count: count } = response
  const sharingRows = sharingData.map(e => {
    return {
      ...e,
      key: e.id,
      leadId: e.inquiryId,
      sharedFrom: {
        senderId: e?.unSharedFromUser?.id,
        sender: e?.unSharedFromUser?.name,
      },
      sharedTo: {
        receiverId: e?.shareWithUser?.id,
        receiver: e?.shareWithUser?.name,
      },
      sharedBy: e?.logAddedByUser?.name,
      teamType: e?.shareWithTeam?.Team?.type,
      teamName: e?.shareWithTeam?.Team?.name,
      commission: e?.commission ? `${e?.commission}%` : "-",
      statusType: e?.type
        ? e.type === "update"
          ? "Updated"
          : e.type === "stopshare"
          ? "Unshared"
          : e.type === "share"
          ? "Shared"
          : "-"
        : "-",
      statustype: e?.type,
    }
  })

  return { data: sharingRows, count: count }
}
