import { ComponentState, Spinner } from "components/common"
import { Document, Page, pdfjs } from "react-pdf"
import { useMemo, useState } from "react"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const PDFDocumentView = props => {
  const { error, isLoading, onRefetch, data, file, pageWidth } = props
  const pdfFile = useMemo(() => (data ? { data } : file), [data, file])
  const [numPages, setNumPages] = useState(null)

  const loadingComponent = () => (
    <div className="text-center" style={{ minHeight: 400 }}>
      <Spinner type="plain" size="medium" fullPageSpinner />
    </div>
  )

  return (
    <>
      {error ? (
        <ComponentState title="Error" subTitle={error?.message} btnLoading={isLoading} onClick={onRefetch} />
      ) : isLoading ? (
        loadingComponent()
      ) : (
          <Document
            file={pdfFile}
            loading={loadingComponent()}
            error={<ComponentState btnText="" description="Failed to load PDF file." />}
            noData={<ComponentState type="empty" description="No PDF file specified." />}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => (
                <Page pageNumber={page} key={page} width={pageWidth} />
              ))}
          </Document>
      )}
    </>
  )
}

export default PDFDocumentView
