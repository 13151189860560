import { Form, Row } from "antd"
import {
  Alert,
  DataTable,
  DatePicker,
  DetailBar,
  Group,
  Modal,
  Number,
  RadioButtons,
  SelectInput,
  Steps,
  Text,
  TextInput,
} from "components/common"
import {
  useAddSalesMutation,
  useLazyGetBankNameQuery,
  useLazyGetContractExpiryDateQuery,
  useLazyGetLeadDetailsQuery,
  useLazyGetPaymentListQuery,
  useLazyGetPaymentMethodQuery,
  useLazyGetPaymentModeQuery,
  useLazyGetSaleEventQuery,
  useLazyGetUnitsQuery,
  useLazyGetValidateReferenceNumberQuery,
} from "features/leads/api"
import {
  useLazyGetCheckNationalIdQuery,
  useLazyGetCitiesQuery,
  useLazyGetCountriesQuery,
  useLazyGetProjectsQuery,
  useLazyGetTaskTypeQuery,
} from "features/shared/api"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { formatPriceValue, getCountryList, joinBytItems, localisedValue } from "utils"
import {
  chequeFormChildren,
  chequeFormProps,
  depositSlipFormChildren,
  depositSlipFormProps,
  onlinePaymentFormChildren,
  onlinePaymentFormProps,
  payorderFormChildren,
  payorderFormProps,
} from "./formItemConstants"

import { useWatch } from "antd/lib/form/Form"
import cx from "clsx"
import Legend from "components/common/legend/legend"
import { Separator } from "components/custom"
import ThemeConfig from "configs/theme"
import { DATE_FORMAT } from "constants"
import { NAME_SPACE } from "constants/app-paths"
import { schemaRules } from "features/shared/utils"
import { useLazyGetClientAnswerQuery } from "features/tasks/api"
import ClientProfileForm from "features/tasks/components/clientProfileForm/clientProfileForm"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { dateTimeFunction } from "utils/date"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import getSalesValidationSchema from "./addSalesValidationSchema"
import DepositSlipSelect from "./components/depositSlipSelect"
import FormItemCreator from "./components/formItemCreator"
import InvoiceDropDown from "./components/invoiceDropDown"
import PaymentProofImageUpload from "./components/paymentProofImageUpload"
import styles from "./index.module.less"
import { getSalesInitialValues } from "./salesInitialValues"
import { DATE_TIME_FORMAT } from "constants"
import moment from "moment"

const { variables: theme } = ThemeConfig

export default function AddSalesForm({ lead, formRef, client, from = {} }) {
  const { t } = useTranslation()
  const [getCountries, { data: countries, isFetching: isFetchingCountries }] = useLazyGetCountriesQuery()
  const [getProjects, { data: projects, isFetching: isFetchingProjects }] = useLazyGetProjectsQuery()
  const [getCities, { data: cities, isFetching: isFetchingCities }] = useLazyGetCitiesQuery()
  const [getUnits, { data: units, isFetching: isFetchingUnits }] = useLazyGetUnitsQuery()
  const [getClientAnswers, { data: answerData, isFetching: fetchClientAnswers }] = useLazyGetClientAnswerQuery()
  const [getPaymentMethod, { data: modeOfPayments, isFetching: isFetchingPaymentMethods }] =
    useLazyGetPaymentMethodQuery()
  const [validateReference, { data: referenceValidity, isError: referenceError }] =
    useLazyGetValidateReferenceNumberQuery()
  const [getLeadDetails, { data: leadSalesInfo, isFetching }] = useLazyGetLeadDetailsQuery()
  const [getContractExpiry, { data: contractExpiry, isFetching: isFetchingContractExpiry }] = useLazyGetContractExpiryDateQuery()
  const [getPaymentMode, { data: paymentMode, isFetching: isFetchingPaymentModes }] = useLazyGetPaymentModeQuery()
  const [getBankName, { data: banks, isFetching: isFetchingBanks }] = useLazyGetBankNameQuery()
  const [checkCnic, { data: cnicData, isError: cnicError }] = useLazyGetCheckNationalIdQuery()
  const [addSales, { isError, isLoading, error, data, reset }] = useAddSalesMutation()
  const [getPaymentList, { data: paymentList, isFetching: fetchPaymentList }] = useLazyGetPaymentListQuery()
  const [getSaleEvent, { data: saleEvent, isLoading: saleEventLoading }] = useLazyGetSaleEventQuery()
  const currency = useSelector(
    state =>
      state?.app?.user?.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode
  )
  const userPlateformKey = useSelector(state => state.app.user?.info?.AgencyConfiguration?.Platform?.platformKey)
  const userInfo = useSelector(state => state?.app?.user.info)
  const clientProfileRef = useRef()
  const [form] = Form.useForm()
  const { setFieldsValue, validateFields, getFieldValue } = form
  const rules = schemaRules(getSalesValidationSchema())
  const country = useWatch("country", form)
  const project = useWatch("project", form)
  const discount = useWatch("unitDiscount", form)
  const unit = useWatch("unit", form)
  const unitPrice = useWatch("unitPrice", form)
  const dealPrice = useWatch("dealPrice", form)
  const discountType = useWatch("discountType", form)
  const amount = useWatch("amount", form)
  const cdp = useWatch("cdpCommitment", form)
  const depositSlip = useWatch("bank_deposit_slip", form)
  const onlinePayment = useWatch("online_payment_screenshot", form)
  const cheque = useWatch("cheque", form)
  const featureTable = useWatch("featureTable", form)
  const subTask = Form.useWatch("subTask", form)

  const [getTaskTypes, { data: taskTypes, isFetching: isFetchingTaskTypes }] = useLazyGetTaskTypeQuery()

  const fieldMapper = (imageData, paymentObject) => {
    if (imageData) {
      setFieldsValue(
        Object?.keys(paymentObject)?.reduce((obj, item) => {
          return {
            ...obj,
            [item]:
              typeof imageData[paymentObject[item]] === "object"
                ? imageData[paymentObject[item]]?.key
                : paymentObject[item] === "date"
                ? dateTimeFunction({ date: imageData[paymentObject[item]], Default: true })
                : imageData[paymentObject[item]],
          }
        }, {})
      )
    }
  }

  const columns = useMemo(
    () => [
      { title: t("Feature"), dataIndex: "Feature" },
      { title: t("Amount"), dataIndex: "Amount" },
    ],
    [t]
  )

  const getTableData = useMemo(() => {
    return unit?.FeatureCharges?.map(item => {
      return {
        ...item,
        key: item?.featureId,
        Feature: item?.Feature?.name,
        Amount: (
          <>
            <Number
              value={item?.amount}
              isCurrency={!item?.amountType === "percentile"}
              wrapValue={val => (item?.amountType === "percentile" ? `${val} %` : val)}
            />
          </>
        ),
      }
    })
  }, [unit])

  const optionList = useMemo(() => {
    return {
      bank: banks,
      paymentMode: paymentMode,
    }
  }, [banks, paymentMode])

  const onModalVisible = () => {
    getLeadDetails(lead?.id)
    getCountries()
    getProjects({ skipPpaExpired: true, financials: true, paymentInfo: true, withSaleableListings: true, status: "on" })
    getTaskTypes({params:{
      withNextStatuses: 1,
      leadType: lead?.interestedIn,
      ...((userInfo.AgencyConfiguration.Platform.platformKey !== "mubawab" ||
        userInfo.AgencyConfiguration.Platform.platformKey !== "bproperty") &&
        (userInfo.teamType === "sales" ||
          userInfo.teamType === "secondarySales" ||
          userInfo.teamType === "support") && { teamType: userInfo.teamType }),
    },extra:{type:"sales"}})
  }

  const debouncedReferenceValidator = useCallback(
    _.debounce(value => {
      const response = validateReference(value).unwrap()
      response.then(e => {
        validateFields(["referenceNumber"])
      })
    }, 500),
    []
  )

  const debounceCnicValidator = useCallback(
    _.debounce(value => {
      const response = checkCnic(value).unwrap()
      response.then(e => {
        form.validateFields(["nationalId"])
      })
    }, 500),
    []
  )

  const checkAbovePDP = () => {
    if (leadSalesInfo?.current >= 0 && +amount > 0) {
      return +amount >= leadSalesInfo?.remainParDowPayment ? true : false
    } else if (amount > 0) {
      return +amount >= +dealPrice * (project?.partialDp / 100) ? true : false
    }

    return false
  }

  const updateNextTask=(value)=>{
    if (+dealPrice === 0) {
      return
    }
    const id = leadSalesInfo?.current >= 0
      ? value >= leadSalesInfo?.remainComDowPayment
        ? 0
        : value >= leadSalesInfo?.remainParDowPayment && value < leadSalesInfo?.remainComDowPayment
        ? 45
        : value < leadSalesInfo?.remainParDowPayment && value >= leadSalesInfo?.token
        ? 33
        : null
      : value >= +dealPrice * (project?.ProjectFinancial?.completeDownPayment / 100)
      ? 0
      : value >= +dealPrice * (project?.partialDp / 100) &&
        value < +dealPrice * (project?.ProjectFinancial?.completeDownPayment / 100)
      ? 45
      : value < +dealPrice * (project?.partialDp / 100) && value >= +project?.minToken
      ? 33
      : null
      form.setFieldValue("nextTask",id)
  }

  const onSubmit = async eventPayload => {
    const val = form.getFieldsValue()
    let bookingForm =
      (leadSalesInfo?.current >= 0 ? leadSalesInfo?.projectBookingForm : project?.needsBookingForm) && checkAbovePDP()

    await addSales({ lead, leadSalesInfo, bookingForm, banks, unit, ...val })
      .unwrap()
      .then(() => {
        formRef?.current?.setVisible(false)
        fireEvent({
          ...ACTION_TYPES.lead_tab_submit_sale,
          ...from,
          response: "200",
          client_id: lead?.Client?.id || client?.id,
          ...eventPayload,
          unit_id: eventPayload?.unit_id || leadSalesInfo?.unitId || val?.unit?.id,
          unit_discount: eventPayload?.unit_discount || leadSalesInfo?.unitDiscount || val?.unitDiscount,
          project_name: val?.project?.name || leadSalesInfo?.projectName,
        })
        form.resetFields()
      })
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.lead_tab_submit_sale,
          ...from,
          response: e.status,
          client_id: lead?.Client?.id || client?.id,
          ...eventPayload,
          unit_id: eventPayload?.unit_id || leadSalesInfo?.unitId || val?.unit?.id,
          unit_discount: eventPayload?.unit_discount || leadSalesInfo?.unitDiscount || val?.unitDiscount,
          project_name: val?.project?.name || leadSalesInfo?.projectName,
        })
      })
  }
  const debounceOnSubmit = useCallback(
    _.debounce((callBack = () => {}) => {
      callBack()
    }, 500),
    []
  )

  useEffect(() => {
    if (unitPrice) {
      let selectedFeaturePrice = featureTable?.selectedPrices || 0
      let discountAmount = (discountType === "%" ? unitPrice * (+discount / 100) : unitPrice - +discount).toFixed(2)
      form.setFieldsValue({ dealPrice: unitPrice - discountAmount + selectedFeaturePrice })
    }
  }, [discount, unitPrice, featureTable])

  useEffect(() => {
    if (cheque) {
      const chequeField = modeOfPayments?.find(item => item.key === "cheque")?.cheque
      fieldMapper(cheque?.imageData, chequeField)
      if (cheque?.imageData?.chequeNumber) {
        validateReference({
          referenceNumber: cheque?.imageData?.chequeNumber,
          bankKey: getFieldValue("chequeBank"),
          paymentModeKey: getFieldValue("modeOfPayment")?.key,
        })
          .unwrap()
          .then(e => {
            validateFields(["referenceNumber"])
          })
      }
    }
    if (onlinePayment) {
      const onlinePaymentField = modeOfPayments?.find(item => item.key === "online_payment")?.onlinePayment
      fieldMapper(onlinePayment?.imageData, onlinePaymentField)
    }
  }, [cheque, onlinePayment, modeOfPayments])

  useEffect(() => {
    if (depositSlip) {
      const depositSlipField = modeOfPayments?.find(item =>
        ["cheque", "cash", "bank_order"].includes(item.key)
      )?.depositSlip
      fieldMapper(depositSlip?.imageData, depositSlipField)
    }
  }, [depositSlip])

  useEffect(() => {
    if (!leadSalesInfo?.ReservationQueuedAgainstLead?.isReserved) {
      if (leadSalesInfo?.projectId) getPaymentMethod(leadSalesInfo?.projectId)
      if (leadSalesInfo?.contractId) {
        getPaymentList({
          searchKey: "contractId",
          searchIds: [leadSalesInfo?.contractId],
          pageOffset: 0,
          pageSize: 50,
          includeStatistics: true,
        })
      }

      if (projects) {
        const option = projects?.find(item => item?.projectId === lead?.Project?.projectId)
        if (option) {
          setFieldsValue({ project: option })
          getSaleEvent(option?.id, true)
          getUnits(option.id, true)
          getPaymentMethod(option.id, true)
        }
      }
    }
  }, [leadSalesInfo, isFetchingProjects, lead])

  useEffect(()=>{
    const listingId = form.getFieldValue("listingId") ?? leadSalesInfo?.listingId
    if (listingId && amount && (dealPrice || leadSalesInfo?.dealPrice)){
        getContractExpiry({
          listingId: listingId,
          params: {
            tokenAmount: amount,
            dealPrice: leadSalesInfo?.dealPrice ? leadSalesInfo?.dealPrice : dealPrice,
            contractListingId: leadSalesInfo?.contracts?.[0]?.contractListingId,
          }
        })
    }
  },[leadSalesInfo,amount,dealPrice,form.getFieldValue("listingId")])

  useEffect(() => {
    if (
      leadSalesInfo?.ReservationQueuedAgainstLead.isReserved &&
      leadSalesInfo?.ReservationQueuedAgainstLead.status === "reserved"
    ) {
      let unit
      getUnits(leadSalesInfo?.ReservationQueuedAgainstLead?.projectId, true)
        .unwrap()
        .then(response => {
          unit = response?.find(e => {
            return e.listingId == leadSalesInfo?.ReservationQueuedAgainstLead?.listingId
          })
          setFieldsValue({
            project: projects?.find(e => {
              return e.projectId == leadSalesInfo?.ReservationQueuedAgainstLead?.projectId
            }),
            unit: unit,
            unitPrice: Math.round(+unit?.unitPrice).toString(),
            unitDiscount: unit?.discount || 0,
            listingId: unit?.listingId,
            discountType: "%",
          })
        })
      getPaymentMethod(leadSalesInfo?.ReservationQueuedAgainstLead?.projectId, true)
    }
  }, [leadSalesInfo, units, unit])

  const setProgressionBar = (amount, project, dealPrice) => {
    const dealPricenum = +dealPrice

    if (!!dealPricenum) {
      if (
        +amount < (project?.minTokenUnit === "amount" ? project?.tokenWithUnit : dealPricenum * project?.tokenWithUnit)
      ) {
        return 0
      }
      if (
        +amount >=
          (project?.minTokenUnit === "amount" ? project?.tokenWithUnit : dealPricenum * project?.tokenWithUnit) &&
        +amount < dealPricenum * (project?.partialDp / 100)
      ) {
        return 1
      }
      if (
        +amount >= dealPricenum * (project?.partialDp / 100) &&
        +amount < dealPricenum * (project?.ProjectFinancial?.completeDownPayment / 100)
      ) {
        return 2
      }
      if (+amount >= dealPricenum * (project?.ProjectFinancial?.completeDownPayment / 100)) {
        return 3
      }
    }
    return 0
  }

  const suffixHandler = useCallback(() => {
    if (+dealPrice === 0) {
      return ""
    }
    return leadSalesInfo?.current >= 0
      ? +amount >= leadSalesInfo?.remainComDowPayment
        ? t("Complete Payment Done")
        : +amount >= leadSalesInfo?.remainParDowPayment && +amount < leadSalesInfo?.remainComDowPayment
        ? t("Partial Payment Done")
        : ""
      : +amount >= +dealPrice * (project?.ProjectFinancial?.completeDownPayment / 100)
      ? t("Complete Payment Done")
      : +amount >= +dealPrice * (project?.partialDp / 100) &&
        +amount < +dealPrice * (project?.ProjectFinancial?.completeDownPayment / 100)
      ? t("Partial Payment Done")
      : ""
  }, [amount, project, dealPrice, leadSalesInfo])

  const getCurrent = useMemo(() => {
    return setProgressionBar(amount, project, dealPrice)
  }, [amount, project, dealPrice])

  const stepData = useMemo(
    () => [
      {
        title: t("Token"),
        description:
          leadSalesInfo?.current >= 0 ? (
            leadSalesInfo?.tokenUnit === "percentage" ? (
              <>
                <>{`${leadSalesInfo.token}% `}</>
                {unit ? (
                  <Number isStringOnly={true} isCurrency={true} value={(leadSalesInfo.token / 100) * dealPrice} />
                ) : (
                  ""
                )}
              </>
            ) : (
              formatPriceValue(leadSalesInfo.token)
            )
          ) : !!project ? (
            project?.tokenWithUnit > 1 ? (
              `${t("Token")}: ${formatPriceValue(project?.minToken)}`
            ) : (
              <>
                <>{`${t("Token")}: ${project?.minToken}% `}</>
                {unit ? (
                  <Number isStringOnly={true} isCurrency={true} value={(project?.minToken / 100) * dealPrice} />
                ) : (
                  ""
                )}
              </>
            )
          ) : (
            ""
          ),
      },
      {
        title: t("PDP"),
        description:
          leadSalesInfo?.current >= 0 ? (
            <>
              <>{`${t("PDP")}: ${leadSalesInfo?.pdp}% `} </>
              {unit ? <Number isCurrency={true} value={(leadSalesInfo?.pdp / 100) * dealPrice} /> : ""}
            </>
          ) : !!project ? (
            <>
              <>{`${t("PDP")}: ${project?.partialDp}% `}</>
              {unit ? (
                <Number isStringOnly={true} isCurrency={true} value={(project?.partialDp / 100) * dealPrice} />
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          ),
      },
      {
        title: t("Booking Form"),
        description: !!leadSalesInfo?.documents[0]?.Disposition
          ? `${leadSalesInfo.documents[0]?.Disposition.name}`
          : "",
        status:
          leadSalesInfo?.documents[0]?.Disposition?.key === "signed"
            ? "finish"
            : leadSalesInfo?.documents[0]?.Disposition?.key === "draft"
            ? "process"
            : getCurrent >= 2 || leadSalesInfo?.current >= 2
            ? "wait pending"
            : "wait",
      },
      {
        title: t("Closed(Won)"),
        description:
          leadSalesInfo?.current >= 0 ? (
            <>
              <>{`${t("CDP")}: ${leadSalesInfo?.cdp}% `}</>
              {unit ? (
                <Number isStringOnly={true} isCurrency={true} value={(leadSalesInfo?.cdp / 100) * dealPrice} />
              ) : (
                ""
              )}
            </>
          ) : !!project ? (
            <>
              <>{`${t("CDP")}: ${project?.ProjectFinancial?.completeDownPayment}% `}</>
              {unit ? (
                <Number
                  isStringOnly={true}
                  isCurrency={true}
                  value={(project?.ProjectFinancial?.completeDownPayment / 100) * dealPrice}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          ),
      },
      {
        title: t("V.Closed(Won)"),
        description: !!leadSalesInfo?.contracts[0]?.contractStatusKey
          ? `${t("Contract")}: ${leadSalesInfo?.contracts[0].contractStatusKey}`
          : "",
      },
    ],
    [leadSalesInfo, project, getCurrent, unit]
  )

  const projectInfo = useMemo(
    () =>
      leadSalesInfo
        ? joinBytItems(
            [
              leadSalesInfo?.contracts[0]?.project.name ? (
                <a
                  href={leadSalesInfo?.contracts[0]?.project?.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "currentColor" }}
                >
                  {localisedValue({ object: leadSalesInfo?.contracts[0]?.project, key: "name" })}
                </a>
              ) : (
                localisedValue({ object: leadSalesInfo?.contracts[0]?.project, key: "name" })
              ),
              localisedValue({ object: leadSalesInfo?.contracts[0]?.Type, key: "name" }),
            ],
            <Separator />
          )
        : [],
    [leadSalesInfo]
  )

  const saleDetail = useMemo(
    () => [
      { label: t("Client"), value: localisedValue({ object: leadSalesInfo?.inquiry?.Client, key: "name" }) },
      { label: t("Unit ID"), value: leadSalesInfo?.unitId },
      {
        label: t("Contract ID"),
        value: (
          <div>
            <Text>
              {leadSalesInfo?.contractId ? (
                <a
                  href={`${window.location.origin}/${NAME_SPACE[0]}/contracts/?contractId=${leadSalesInfo?.contractId}`}
                >
                  {leadSalesInfo?.contractId}
                </a>
              ) : (
                leadSalesInfo?.contractId
              )}
            </Text>
            {leadSalesInfo?.expiryDate && (
              <Text className="small" type="danger">
                ({t("Expiry")} {dateTimeFunction({ date: leadSalesInfo?.expiryDate, format: "Do MMM" })})
              </Text>
            )}
          </div>
        ),
      },
    ],
    [leadSalesInfo]
  )
  const salesSteps = useMemo(
    () => [
      {
        label: t("Unit Price"),
        data: <Number isStringOnly={true} isCurrency={true} value={leadSalesInfo?.unitPrice} />,
      },
      {
        label: t("Unit Discount"),
        data: <Number isStringOnly={true} isCurrency={true} value={leadSalesInfo?.unitDiscount} />,
      },
      {
        label: t("Paid Amount"),
        data: (
          <>
            <Number isStringOnly={true} isCurrency={true} value={leadSalesInfo?.paidAmount} />
            <Number
              isStringOnly={true}
              digitAfterFraction={0}
              value={(leadSalesInfo?.paidAmount / leadSalesInfo?.dealPrice) * 100}
              wrapValue={value => `(${value})%`}
            />
          </>
        ),
      },
      {
        label: t("Deal Price"),
        data: <Number isStringOnly={true} isCurrency={true} value={leadSalesInfo?.dealPrice} />,
      },
      {
        label: t("Due Amount"),
        data: (
          <>
            <Number isStringOnly={true} isCurrency={true} value={leadSalesInfo?.remainComDowPayment} />
            <Number
              isStringOnly={true}
              digitAfterFraction={0}
              value={(leadSalesInfo?.remainComDowPayment / leadSalesInfo?.dealPrice) * 100}
              wrapValue={value => `(${value})%`}
            />
          </>
        ),
      },
    ],
    [leadSalesInfo, paymentList]
  )

  const customValidatorForDiscount = value => {
    if (getFieldValue("discountType") === "%") {
      return !value || (value <= getFieldValue("unit").discount && value >= 0)
        ? Promise.resolve()
        : Promise.reject(new Error(`${t("The discount cannot be greater than")} ${getFieldValue("unit").discount}`))
    } else if (getFieldValue("discountType") === currency) {
      return !value || (value <= (+getFieldValue("unit").discount / 100) * +getFieldValue("unit").price && value >= 0)
        ? Promise.resolve()
        : Promise.reject(
            new Error(
              `${t("The discount cannot be greater than")} ${
                (+getFieldValue("unit").discount / 100) * +getFieldValue("unit").price
              }`
            )
          )
    }
  }
  const customValidatorForCDP = value => {
    if ((+value > 100 || +value < project?.ProjectFinancial?.completeDownPayment) && value !== "") {
      return Promise.reject(
        `${t("CDP committment must be between")}  ${project?.ProjectFinancial?.completeDownPayment}% ${t("and")} 100%`
      )
    }
    return Promise.resolve()
  }
  const customValidatorForAddPayment = value => {
    let tokenAmount = saleEvent
      ? +saleEvent?.minAmount
      : project?.minTokenUnit === "amount"
      ? project?.minToken
      : (project?.minToken / 100) * dealPrice

    if (value === "" || value === null) {
      return Promise.resolve()
    }
    if (
      (leadSalesInfo?.ReservationQueuedAgainstLead?.isReserved &&
        leadSalesInfo?.ReservationQueuedAgainstLead?.status === "reserved") ||
      (leadSalesInfo?.contracts[0]?.ReservationQueueAgainstListing.isReserved &&
        leadSalesInfo?.contracts[0]?.ReservationQueueAgainstListing.status === "pending")
    ) {
      if (
        +value <
        ((leadSalesInfo?.cdp || project?.ProjectFinancial?.completeDownPayment) / 100) *
          (leadSalesInfo?.dealPrice || dealPrice)
      ) {
        return Promise.reject(t("The amount can not be lower than the CDP "))
      }
    }
    if (+value < tokenAmount) {
      return Promise.reject(t("Amount can not be lower than token"))
    }

    if (+value > leadSalesInfo?.dealPrice) {
      return Promise.reject(t("Amount exceeds deal price"))
    }
    if (+value > +dealPrice) {
      return Promise.reject(t("Amount exceeds deal price"))
    }
    return Promise.resolve()
  }

  const onChangeForProjectName = option => {
    setFieldsValue({
      project: option,
      unit: null,
      modeOfPayment: null,
      unitPrice: 0,
      dealPrice: 0,
      unitDiscount: null,
      amount: null,
      cdpCommitment: option?.ProjectFinancial?.completeDownPayment,
      listingId: null,
      deadLine: null,
    })
    getUnits(option.id)
    getPaymentMethod(option.id)
    getSaleEvent(option?.id)
  }
  return (
    <Modal
      title={t("Add Sales")}
      ref={formRef}
      width={820}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      loading={isLoading || fetchClientAnswers || isFetching}
      onOk={form.submit}
      getContainer={false}
      bodyStyle={{ padding: "24px" }}
      onModalVisible={onModalVisible}
      forceRender
      footerAlert={error}
      onCancel={() => {
        form.resetFields()
        formRef?.current?.setVisible(false)
        reset()
      }}
      destroyOnClose
    >
      <>
        <Steps
          className={cx(styles.steps, "mbe-24")}
          current={leadSalesInfo?.current >= 0 ? leadSalesInfo?.current : getCurrent}
          data={stepData}
          progressDot
        />
        {leadSalesInfo?.ReservationQueuedAgainstLead.isReserved &&
          leadSalesInfo.ReservationQueuedAgainstLead.status === "pending" && (
            <Alert
              type="warning"
              message={`${t(
                "Submitting sales task will remove the queued request of inventory reserve for this lead against"
              )} ${leadSalesInfo?.ReservationQueuedAgainstLead?.projectName}'s ${t("Unit #")} ${
                leadSalesInfo?.ReservationQueuedAgainstLead?.unitNumber
              }.`}
            />
          )}

        <Form
          form={form}
          layout="vertical"
          name="add-sales-form"
          initialValues={getSalesInitialValues(client)}
          onFinish={e => {
            const eventPayload = {
              client_name: client?.name,
              project_name: e.project?.name,
              comments: e.comment,
              unit_id: e.unit?.id,
              amount: e.amount,
              payment_mode: e.modeOfPayment?.name,
              receipt_number: e.receiptNumber,
              unit_discount: e.unitDiscount,
              lead_id: lead.id,
            }
            let ApiCall = () => {
              getClientAnswers(client?.id)
                .unwrap()
                .then(e => {
                  if (!e?.profileFilled) {
                    clientProfileRef?.current.setVisible(true)
                  } else {
                    reset()
                    onSubmit(eventPayload)
                  }
                })
            }
            debounceOnSubmit(ApiCall)
          }}
          scrollToFirstError
          disabled={fetchClientAnswers || isFetching || isLoading}
          preserve={false}
        >
          <Group template="initial" gap="24px">
            <div>
              <Legend title={t("Project Details")} />
              {leadSalesInfo?.current >= 0 || (leadSalesInfo?.contracts[0] && !leadSalesInfo?.current) ? (
                <DetailBar
                  className={styles.detailBar}
                  collapsible
                  detailData={saleDetail}
                  listData={salesSteps}
                  detailHeader={
                    <Row justify="space-between">
                      {projectInfo.length && (
                        <div>
                          {projectInfo.map((e, i) => (
                            <Text key={i} type={i === 0 ? "info" : "secondaryLight"} style={{ gap: "initial" }}>
                              {e}
                            </Text>
                          ))}
                        </div>
                      )}
                      {paymentList?.payments?.length > 0 && <InvoiceDropDown paymentList={paymentList} />}
                    </Row>
                  }
                />
              ) : (
                <div>
                  <Group className="mbe-16" template="repeat(2, 1fr)" gap="16px 32px">
                    <Form.Item className="mbe-0" required name="project" label={t("Project")} rules={rules}>
                      <SelectInput
                        placeholder={t("Select project")}
                        options={projects}
                        disabled={
                          leadSalesInfo?.ReservationQueuedAgainstLead.isReserved &&
                          leadSalesInfo.ReservationQueuedAgainstLead.status === "reserved"
                        }
                        loading={isFetchingProjects}
                        onChange={(value, option) => {
                          onChangeForProjectName(option)
                        }}
                      />
                    </Form.Item>
                    <Form.Item className="mbe-0" required name="unit" label={t("Unit")} rules={rules}>
                      <SelectInput
                        placeholder={t("Select unit")}
                        disabled={
                          !project ||
                          (leadSalesInfo?.ReservationQueuedAgainstLead.isReserved &&
                            leadSalesInfo.ReservationQueuedAgainstLead.status === "reserved")
                        }
                        options={
                          leadSalesInfo?.ReservationQueuedAgainstLead.isReserved &&
                          leadSalesInfo?.ReservationQueuedAgainstLead?.status === "reserved"
                            ? units
                            : units?.filter(e => e.status === "Available")
                        }
                        inputLoading={isFetchingUnits}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        onChange={(value, option) => {
                          let selectedFeatures = []
                          let selectedFeaturePrices = 0
                          option?.FeatureCharges?.forEach(item => {
                            if (item?.fixed) {
                              selectedFeatures.push(item?.featureId)
                              selectedFeaturePrices +=
                                item?.amountType === "fixed" ? +item?.amount : (+item?.amount / 100) * option?.price
                            }
                          })
                          setFieldsValue({
                            unit: option,
                            unitPrice: +option.price.toString(),
                            unitDiscount: 0,
                            discountType: "%",
                            featureTable: {
                              selectedRowKeys: selectedFeatures,
                              selectedPrices: selectedFeaturePrices,
                            },
                            dealPrice: option?.price,
                            listingId: option?.listingId,
                            deadLine: null,
                          })
                        }}
                      />
                    </Form.Item>

                    <div
                      className={cx(styles.highlight, styles.highlightMain, "span-all")}
                      style={{ padding: "30px 24px 12px" }}
                    >
                      <Form.Item
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 17 }}
                        required
                        name="unitPrice"
                        label={t("Unit Price")}
                        rules={rules}
                      >
                        <TextInput
                          placeholder={0}
                          disabled
                          suffixText={<Number isStringOnly tooltip isCurrency value={unitPrice} />}
                        />
                      </Form.Item>

                      {unit && unit?.FeatureCharges?.length !== 0 && (
                        <Form.Item
                          name={"featureTable"}
                          label="Features"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 17 }}
                        >
                          <DataTable
                            tableLayout="auto"
                            stickyOffset={-100}
                            data={getTableData || []}
                            columns={columns || []}
                            rowSelection={{
                              type: "checkbox",
                              columnWidth: 20,
                              onChange: (selectedRowKeys, selectedRows) => {
                                let selectedFeaturePrices = 0
                                selectedRows?.forEach(item => {
                                  selectedFeaturePrices +=
                                    item?.amountType === "fixed" ? +item?.amount : (+item?.amount / 100) * unitPrice
                                })
                                form.setFieldsValue({
                                  featureTable: {
                                    selectedRowKeys,
                                    selectedPrices: selectedFeaturePrices,
                                    features: selectedRows,
                                  },
                                })
                              },
                              selectedRowKeys: featureTable?.selectedRowKeys || [],
                              getCheckboxProps: record => {
                                return {
                                  disabled: record?.fixed,
                                }
                              },
                            }}
                            scroll={{
                              y: 240,
                            }}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        className={styles.unitDiscount}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 17 }}
                        required
                        name="unitDiscount"
                        label={t("Unit Discount")}
                        rules={[
                          () => ({
                            required: true,
                            type: "number",
                            validator(_, value) {
                              return customValidatorForDiscount(value)
                            },
                          }),
                        ]}
                      >
                        <TextInput
                          disabled={!unit?.discount || unit?.discount === 0 ? true : false}
                          placeholder={t("Enter discount")}
                          suffixText={
                            <Form.Item required name="discountType" rules={rules} noStyle>
                              <RadioButtons
                                options={[
                                  { key: 1, label: t("%"), value: "%" },
                                  { key: 2, label: currency, value: currency },
                                ]}
                                disabled={!unit?.discount || unit?.discount === 0 ? true : false}
                                onChange={value => {
                                  const option = value.target.value
                                  setFieldsValue({ discountType: option })
                                  option === "%"
                                    ? setFieldsValue({ unitDiscount: Math.round((discount / unitPrice) * 100) })
                                    : setFieldsValue({ unitDiscount: Math.round(unitPrice * (discount / 100)) })
                                }}
                              />
                            </Form.Item>
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        name="dealPrice"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 17 }}
                        label={t("Deal Price")}
                        rules={rules}
                      >
                        <TextInput
                          placeholder="0"
                          disabled
                          suffixText={<Number isStringOnly tooltip isCurrency value={dealPrice} />}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 17 }}
                        name="cdpCommitment"
                        label={t("CDP Commitment")}
                        rules={rules.concat({
                          validator: async (_, value) => {
                            return customValidatorForCDP(value)
                          },
                        })}
                      >
                        <TextInput
                          placeholder={t("Enter CDP commitment")}
                          disabled={!project}
                          suffixText={
                            !!dealPrice && !!cdp ? (
                              <>
                                <Text>{t("Amount Due")}:</Text>
                                <Number
                                  isStringOnly
                                  tooltip
                                  isCurrency
                                  value={dealPrice - Math.round((+cdp / 100) * dealPrice)}
                                />
                              </>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </Group>
                </div>
              )}
            </div>
            <div>
              {!!!leadSalesInfo?.verifiedWon && (
                <>
                  <Legend title={t("Add Payment")} />
                  <Group template="repeat(2, 1fr)" gap="16px 32px">
                    <Form.Item
                      name="amount"
                      label={t("Amount")}
                      required
                      onChange={event=>{
                        form.setFieldValue("deadLine",null)
                        updateNextTask(event.target.value)
                      }}
                      rules={rules.concat({
                        validator: async (_, value) => {
                          return customValidatorForAddPayment(value)
                        },
                      })}
                    >
                      <TextInput
                        renderDescription={value => (
                          <Number value={value} defaultValue="" isCurrency color={theme["gray800"]} />
                        )}
                        placeholder={t("Enter amount")}
                        suffixText={suffixHandler()}
                      />
                    </Form.Item>

                    <Form.Item name="receiptNumber" label={t("Receipt Number")} rules={rules}>
                      <TextInput placeholder={t("Enter receipt number")} />
                    </Form.Item>

                    <Group className="span-all" template="initial" gap="8px">
                      {(!!project || !!leadSalesInfo?.projectName) && (
                        <Form.Item
                          className="mbe-0"
                          required
                          name={"modeOfPayment"}
                          label={t("Mode Of Payment")}
                          rules={rules}
                        >
                          <RadioButtons
                            getValue={item => item}
                            options={modeOfPayments}
                            onChange={value => {
                              const option = value.target.value
                              getPaymentMode(option.id, true)
                              if (
                                option.key === "cheque" ||
                                option.key === "online_payment" ||
                                option.key === "bank_order" ||
                                option.key === "cash"
                              ) {
                                getBankName()
                              }
                            }}
                          />
                        </Form.Item>
                      )}

                      <Form.Item noStyle shouldUpdate={true}>
                        {({ getFieldValue }) => {
                          if (!!getFieldValue("modeOfPayment")) {
                            return getFieldValue("modeOfPayment")?.PaymentModeAttachmentTypeMappings?.map(e => {
                              return (
                                <Form.Item
                                  className={cx(styles.highlight, styles.highlightSecondary, "mbe-0 span-all")}
                                >
                                  <Group template="auto 1fr">
                                    <Form.Item
                                      name={e.AttachmentType.key}
                                      label={e.AttachmentType.title}
                                      required={e.mandatory}
                                      rules={[
                                        {
                                          validator: async (_, value) => {
                                            if (!!!value && e.mandatory) {
                                              return Promise.reject(t("This Attachment is Required"))
                                            }
                                            return Promise.resolve()
                                          },
                                        },
                                      ]}
                                    >
                                      <div>
                                        {e.attachmentTypeId !== 2 ? (
                                          <PaymentProofImageUpload
                                            setFiles={data => {
                                              setFieldsValue({ [e.AttachmentType.key]: data })
                                            }}
                                            paymentProofProps={{
                                              attachmentTypeId: e.attachmentTypeId,
                                              api: e?.visionRequired,
                                            }}
                                            platformKey={userPlateformKey}
                                          />
                                        ) : (
                                          <DepositSlipSelect
                                            setFiles={data => {
                                              setFieldsValue({ [e.AttachmentType.key]: data })
                                            }}
                                            paymentProofProps={{
                                              attachmentTypeId: e.attachmentTypeId,
                                              api: e?.visionRequired,
                                            }}
                                            platformKey={userPlateformKey}
                                          />
                                        )}
                                      </div>
                                    </Form.Item>
                                    {e.attachmentTypeId === 5 && (
                                      <>
                                        <FormItemCreator
                                          formItemPropList={payorderFormProps({
                                            referenceError,
                                            referenceValidity,
                                          })}
                                          formItemChildrenList={payorderFormChildren({
                                            setFieldsValue,
                                            optionList,
                                            getFieldValue,
                                            debouncedReferenceValidator,
                                          })}
                                          rules={rules}
                                        />
                                      </>
                                    )}
                                    {!!depositSlip && typeof depositSlip === "object" && e.attachmentTypeId === 2 && (
                                      <>
                                        <FormItemCreator
                                          formItemPropList={depositSlipFormProps()}
                                          formItemChildrenList={depositSlipFormChildren({
                                            setFieldsValue,
                                            optionList,
                                          })}
                                          rules={rules}
                                        />
                                      </>
                                    )}
                                    {!!onlinePayment?.imageData && e.attachmentTypeId === 6 && (
                                      <>
                                        <FormItemCreator
                                          formItemPropList={onlinePaymentFormProps()}
                                          formItemChildrenList={onlinePaymentFormChildren({
                                            setFieldsValue,
                                            optionList,
                                          })}
                                          rules={rules}
                                        />
                                      </>
                                    )}
                                    {!!cheque?.imageData && e.attachmentTypeId === 3 && (
                                      <>
                                        <FormItemCreator
                                          formItemPropList={chequeFormProps({
                                            referenceError,
                                            referenceValidity,
                                          })}
                                          formItemChildrenList={chequeFormChildren({
                                            setFieldsValue,
                                            optionList,
                                            getFieldValue,
                                            debouncedReferenceValidator,
                                          })}
                                          rules={rules}
                                        />
                                      </>
                                    )}
                                  </Group>
                                </Form.Item>
                              )
                            })
                          }
                        }}
                      </Form.Item>
                    </Group>
                  </Group>
                </>
              )}
              <div>
                {((!!!client?.nationalId && (!!getFieldValue("project")?.needsDepositSlip || checkAbovePDP())) ||
                  !leadSalesInfo?.doesCountryExist ||
                  !leadSalesInfo?.doesCityExist) && <Legend title={t("Client Details")} />}
                <Group className="mbe-16" template="repeat(2, 1fr)" gap="16px 32px">
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      if (!!!client?.nationalId) {
                        if (!!getFieldValue("project")?.needsDepositSlip || checkAbovePDP()) {
                          return (
                            <>
                              <Form.Item
                                hasFeedback
                                required
                                rules={rules.concat({
                                  validator: async (_, value) => {
                                    if (cnicData?.exists) {
                                      return Promise.reject(t("National id already exists"))
                                    } else {
                                      return Promise.resolve()
                                    }
                                  },
                                })}
                                name="nationalId"
                                label={t("National ID")}
                              >
                                <TextInput
                                  placeholder={t("Enter national id")}
                                  onChange={e => {
                                    debounceCnicValidator({
                                      id: client?.id,
                                      nationalId: e.target.value,
                                    })
                                  }}
                                  maxLength={13}
                                />
                              </Form.Item>
                              <Form.Item name="dateOfBirth" label={t("Date of birth")} rules={rules}>
                                <DatePicker allowClear={false} showTime={false} format={DATE_FORMAT} />
                              </Form.Item>
                            </>
                          )
                        }
                      }
                    }}
                  </Form.Item>
                  {!leadSalesInfo?.doesCountryExist && (
                    <Form.Item required name="country" label={t("Country")} rules={rules}>
                      <SelectInput
                        placeholder={t("Select country")}
                        options={getCountryList(countries)}
                        loading={isFetchingCountries}
                        onChange={(value, option) => {
                          getCities(option.id, true)
                          setFieldsValue({ country: option, city: null })
                        }}
                      />
                    </Form.Item>
                  )}

                  {!leadSalesInfo?.doesCityExist && (
                    <Form.Item required name="city" label={t("City")} rules={rules}>
                      <SelectInput
                        placeholder={t("Select city")}
                        loading={isFetchingCities}
                        disabled={!(!!country || !!leadSalesInfo?.countryId)}
                        options={cities}
                        onFocus={() => {
                          if (!!leadSalesInfo?.countryId) {
                            getCities(leadSalesInfo?.countryId, true)
                          }
                        }}
                        onChange={(value, option) => {
                          setFieldsValue({ city: option })
                        }}
                      />
                    </Form.Item>
                  )}
                </Group>
              </div>
              <div>
                <Legend title={t("Next Task")} hidden={subTask === 39 ? true : false} />
                <Group template="repeat(2, 1fr)" gap="6px 32px">
                  <Form.Item
                    required
                    hidden={subTask === 39 ? true : false}
                    name="nextTask"
                    label={t("Next Task")}
                    rules={rules.concat({
                      validator: async (_, value) => {
                        if (subTask === 39 || value === 0) {
                          return Promise.resolve()
                        } else if (!value) return Promise.reject(t("Next task is required"))
                      },
                    })}
                  >
                    <SelectInput
                      placeholder={t("Select next task")}
                      options={taskTypes?.nextStatuses}
                      getOptionLabel={option => option?.statusTitleTodo}
                      onChange={(value, _option) => {
                        setFieldsValue({ nextTask: value })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="deadLine"
                    label={t("Deadline")}
                    required={true}
                    rules={rules}
                  >
                    <DatePicker
                      range={{ startDate: moment(), endDate: amount && contractExpiry?.expiryDate ? moment(contractExpiry?.expiryDate):null }}
                      showTime
                      disableTimeRange={true}
                      format={DATE_TIME_FORMAT}
                    />
                  </Form.Item>
                </Group>
              </div>
            </div>
          </Group>
        </Form>
        <>
          <ClientProfileForm formRef={clientProfileRef} FormRef={formRef} onSuccess={onSubmit} client={client} />
        </>
      </>
    </Modal>
  )
}
