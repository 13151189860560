import { Skeleton as AntdSkeleton } from "antd"
import PropTypes from "prop-types"
import React from "react"

function SkeletonBody({ type, size, active, enableAvatar, avatarShape, width, height, ...rest }) {
  switch (type) {
    case "title":
      return <AntdSkeleton.Input active={active} size={size} {...rest} />
    case "paragraph":
      return <AntdSkeleton.Input active={active} size={size} {...rest} />
    case "input":
      return (
        <AntdSkeleton.Input
          active={active}
          size={size}
          style={{ maxWidth: width, width, height: height, lineHeight: height }}
          width={width}
          height={height}
          {...rest}
        />
      )
    case "button":
      return (
        <AntdSkeleton.Button
          active={active}
          size={size}
          style={{ maxWidth: width, width, height: height, lineHeight: height }}
          width={width}
          height={height}
          {...rest}
        />
      )
    case "avatar":
      return (
        <AntdSkeleton.Avatar
          active={active}
          size={size}
          avatarShape={avatarShape}
          width={width}
          height={height}
          {...rest}
        />
      )
    case "image":
      return <AntdSkeleton.Image active={active} size={size} avatarShape={avatarShape} {...rest} />
    default:
      return <AntdSkeleton active={active} {...rest} />
  }
}

SkeletonBody.propTypes = {
  type: PropTypes.oneOf(["title", "paragraph", "input", "button", "avatar", "image", undefined]),
  size: PropTypes.string,
  active: PropTypes.bool,
  enableAvatar: PropTypes.bool,
  avatarShape: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number
}

SkeletonBody.defaultProps = {
  type: "button",
  size: "default",
  // active: true,
  enableAvatar: true,
  avatarShape: "circle",
  width: 50,
  height: 32,
}

export default SkeletonBody
