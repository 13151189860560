import { Button, Modal, PDFDocumentView, notification } from "components/common"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

import parentApi from "store/parentApi"
import { useLazyGetPolicyQuery } from "features/policies/api"
import { useSelector } from "react-redux"
import { t } from "i18next"

const AnnouncementModal = forwardRef((_props, ref) => {
  const user = useSelector(state => state?.app?.user?.info)
  const [acknowledge, { isLoading: isLoadingAck }] = parentApi.useUpdatePendingAnnouncementsMutation()
  const [getPolicy, { data: policy, isLoading, error }] = useLazyGetPolicyQuery()
  const [announcements, setAnnouncements] = useState()
  const [announcement, setAnnouncement] = useState()

  const modalRef = useRef()
  const confirmModalRef = useRef()
  const policyModalRef = useRef()

  useImperativeHandle(
    ref,
    () => ({
      showFor(data) {
        setAnnouncements(data)
        setAnnouncement(data[0])
        modalRef.current.setVisible(true)
      },
    }),
    []
  )

  const onConfirm = () => {
    setAnnouncement(prev => {
      const pendingAnnouncements = announcements?.filter(e => e?.announcementId !== prev?.announcementId)
      setAnnouncements([...pendingAnnouncements])
      if (!pendingAnnouncements?.length) modalRef?.current?.setVisible(false)
      return pendingAnnouncements?.[0]
    })
    confirmModalRef?.current?.setVisible(false)
    policyModalRef.current.setVisible(false)
  }

  const onViewPolicy = () => {
    getPolicy(announcement.againstId).then(e => {
      if (e?.error) {
        notification.error({ message: e?.error?.message?.message })
      } else {
        policyModalRef.current.setVisible(true)
      }
    })
  }

  const onAcknowledge = () => {
    if (announcement?.announcementId) {
      acknowledge({ userId: user?.id, announcementId: announcement?.announcementId }).then(e => {
        if (!e.error) {
          onConfirm()
        } else {
          notification.error({ message: e.error?.message?.message || e.error?.message })
        }
      })
    }
  }

  const renderViewPolicyButton = () => (
    <div className="text-right">
      <Button size="small" type="primary" text="View Policy" onClick={onViewPolicy} loading={isLoading} />
    </div>
  )

  return (
    <>
      <Modal
        width={640}
        ref={modalRef}
        onCancel={() => confirmModalRef?.current?.setVisible(true)}
        hideCancelButton
        bodyStyle={{ padding: 24 }}
        footer={null}
        destroyOnClose
        title={announcement?.title}
        subTitle={announcement?.from ? `By ${announcement.from}` : ""}
      >
        {/** TODO: need to fix this */}
        {announcement?.message && (
          <div className="content" dangerouslySetInnerHTML={{ __html: announcement?.message }}></div>
        )}
        {announcement?.againstTypeKey == "repository_policy" && renderViewPolicyButton()}
      </Modal>

      <Modal
        ref={policyModalRef}
        onCancel={() => confirmModalRef?.current?.setVisible(true)}
        hideCancelButton
        bodyStyle={{ padding: 24 }}
        destroyOnClose
        title="Policy"
        footer={null}
      >
        {policy?.Attachment?.path && (
          <PDFDocumentView isLoading={isLoading} error={error} file={policy?.Attachment?.path} />
        )}
      </Modal>

      <Modal
        width={400}
        ref={confirmModalRef}
        onCancel={() => confirmModalRef?.current?.setVisible(false)}
        bodyStyle={{ padding: 24 }}
        onOk={onAcknowledge}
        okText="Yes"
        destroyOnClose
        title="Confirmation"
        loading={isLoadingAck}
      >
        {t("Are you sure that you have read the message?")}
      </Modal>
    </>
  )
})

export default AnnouncementModal
