import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import React, { useState } from "react"
import { addDays, endOfDay, startOfDay, subDays } from "date-fns"
import { Button, Icon } from "components/common"
import { DATE_TIME_FORMAT } from "../../../constants"
import { DatePicker } from "antd"
import { DateRangePicker } from "react-date-range"
import { createStaticRanges } from "react-date-range"
import { getStartEndValue } from "utils/date"
import { useTranslation } from "react-i18next"
import { t } from "i18next"

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
}

const staticRanges = () => [
  {
    label: t("Today"),
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: t("Yesterday"),
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: t("Last 7 Days"),
    range: () => ({
      startDate: addDays(new Date(), -6),
      endDate: startOfDay(new Date()),
    }),
  },
  {
    label: t("Last 15 Days"),
    range: () => ({
      startDate: addDays(new Date(), -14),
      endDate: startOfDay(new Date()),
    }),
  },
  {
    label: t("Last 30 Days"),
    range: () => ({
      startDate: addDays(new Date(), -29),
      endDate: startOfDay(new Date()),
    }),
  },
]

const DateRangePickerOne = props => {
  const { t, i18n } = useTranslation()

  const { setIsOpen, setDateRange, dateRange, onSelect, type = "past", accentColor } = props
  const [state, setState] = useState({
    datePickerInternational: null,
    dateRangePicker: {
      selection: {
        startDate: props.queryStartDate ? new Date(props.queryStartDate) : subDays(new Date(), 6),
        endDate: props.queryEndDate ? new Date(props.queryEndDate) : new Date(),
        key: "selection",
      },
    },
  })

  const handleRangeChange = which => {
    setState({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    })
    setDateRange({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    })
  }

  const { dateRangePicker } = state
  const start =
    dateRange && dateRange?.length != 0
      ? dateRange?.dateRangePicker?.selection.startDate.toString().split(" ")
      : dateRangePicker.selection.startDate.toString().split(" ")

  const end =
    dateRange && dateRange?.length != 0
      ? dateRange?.dateRangePicker?.selection.endDate.toString().split(" ")
      : dateRangePicker.selection.endDate.toString().split(" ")

  return (
    <>
      <DateRangePicker
        onChange={handleRangeChange}
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        className="PreviewArea"
        months={2}
        {...props}
        {...(type === "past" && { maxDate: new Date() })}
        {...(type === "future" && { minDate: new Date() })}
        ranges={
          dateRange && dateRange?.length != 0 ? [dateRange?.dateRangePicker?.selection] : [dateRangePicker?.selection]
        }
        direction="horizontal"
        inputRanges={[]}
        staticRanges={createStaticRanges(staticRanges())}
        rangeColors={[`${accentColor}`]}
      />

      <p>{getStartEndValue(start, end)}</p>
      <Button
        size="small"
        type="primary"
        onClick={() => {
          setIsOpen(false)
          setDateRange(state)
          onSelect(start, end)
        }}
        style={{ backgroundColor: accentColor, border: 0 }}
        text={"Apply"}
      />
      <Button size="small" type="white" outlined onClick={() => setIsOpen(false)} text={"Cancel"} />
    </>
  )
}

class CustomDateRange extends React.Component {
  // eslint-disable-next-line react/state-in-constructor

  state = {
    startValue: null,
    endValue: null,
    endOpen: false,
  }

  disabledStartDate = startValue => {
    const { endValue } = this.state
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    })
  }

  onStartChange = value => {
    this.onChange("startValue", value)
  }

  onEndChange = value => {
    this.onChange("endValue", value)
  }

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true })
    }
  }

  handleEndOpenChange = open => {
    this.setState({ endOpen: open })
  }

  render() {
    const { startValue, endValue, endOpen } = this.state
    if (this.props.isRanged) {
      return (
        <DatePicker
          disabledDate={this.disabledStartDate}
          showTime
          format="YYYY-MM-DD"
          value={startValue}
          placeholder="Start"
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange}
          suffixIcon={<Icon icon={"BiCalendar"} />}
        />
      )
    } else {
      return (
        <>
          <DatePicker
            disabledDate={this.disabledStartDate}
            showTime
            format={DATE_TIME_FORMAT}
            value={startValue}
            placeholder="Start"
            onChange={this.onStartChange}
            onOpenChange={this.handleStartOpenChange}
            suffixIcon={<Icon icon={"BiCalendar"} />}
          />

          <DatePicker
            disabledDate={this.disabledEndDate}
            showTime
            format={DATE_TIME_FORMAT}
            value={endValue}
            placeholder="End"
            onChange={this.onEndChange}
            open={endOpen}
            onOpenChange={this.handleEndOpenChange}
            suffixIcon={<Icon icon={"BiCalendar"} />}
          />
        </>
      )
    }
  }
}

export { DateRangePickerOne, CustomDateRange }
