import { useCallback, useEffect, useState } from "react"

import { TimePicker as AntdTimePicker } from "antd"
import moment from "moment"
import { useSelector } from "react-redux"
import locale from "antd/es/date-picker/locale/ar_EG"

const TimePicker = props => {
  const { preSelectedDate, value, onChange, minuteStep = 5, ...rest } = props
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const { locale: appLanguage } = useSelector(state => state.app.configs)

  useEffect(() => {
    if (value) {
      setStartTime(value[0])
      setEndTime(value[1])
    }
  }, [value])

  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledTime = useCallback(
    (date, type) => {
      if (date && preSelectedDate && preSelectedDate?.isSame(date, "day")) {
        return {
          disabledHours: () => {
            return range(0, type === "end" && startTime ? startTime?.hour() : date.hour())
          },
          disabledMinutes: selectedHour => {
            if (type === "end") {
              if (selectedHour < (startTime.hour() || date.hour())) {
                return range(0, 59)
              }
              if (selectedHour === (startTime.hour() || date.hour())) {
                return range(0, startTime ? startTime?.minutes() : date.minutes())
              }
            } else {
              if (selectedHour < date.hour()) {
                return range(0, 59)
              }
              if (selectedHour === date.hour()) {
                return range(0, date.minutes())
              }
            }
          },
        }
      } else if (type === "end" && startTime) {
        return {
          disabledHours: () => {
            return range(0, startTime && startTime?.hour())
          },
          disabledMinutes: () => {
            return range(0, startTime && startTime?.minutes())
          },
        }
      }
      return {}
    },
    [preSelectedDate, startTime]
  )

  const handleChangeTime = dates => {
    if (dates) {
      let edTime = dates[1]
      let stTime = moment(dates[0])
      if (stTime?.isAfter(dates[1]) && moment(stTime)?.add(1, "hours")?.isSame(stTime, "day")) {
        edTime = stTime?.add(1, "hours")
      } else if (stTime?.isAfter(dates[1]) && !moment(stTime)?.add(1, "hours")?.isSame(stTime, "day")) {
        edTime = stTime.endOf("day")
      }
      setEndTime(edTime)
      onChange([dates[0], edTime])
    }
  }

  return (
    <AntdTimePicker.RangePicker
      order={false}
      use12Hours
      format="h:mm a"
      disabledTime={disabledTime}
      value={[startTime, endTime]}
      onChange={dates => {
        dates && handleChangeTime(dates)
      }}
      onOk={dates => {
        setStartTime(dates[0])
        setEndTime(dates[1])
      }}
      hideDisabledOptions
      minuteStep={minuteStep}
      locale={appLanguage === "en" ? [] : locale}
      {...rest}
    />
  )
}
export default TimePicker
