import { useEffect, useState } from "react"

import { DATE_FORMAT } from "constants"
import { DatePicker } from "antd"
import moment from "moment"
import { DATE_TIME_FORMAT } from "constants"
import { useSelector } from "react-redux"
import locale from "antd/es/date-picker/locale/ar_EG"

export default function SimpleDateFilter({
  onSelect,
  value: dateValue,
  valueFormat = DATE_TIME_FORMAT,
  label,
  disabledDate,
  picker,
  dd,
  ...rest
}) {
  const [value, setValue] = useState()
  const { locale: appLanguage } = useSelector(state => state.app.configs)

  useEffect(() => {
    setValue(!!dateValue ? moment(dateValue) : null)
  }, [dateValue])
  return (
    <>
      <label className="d-block">{label}</label>
      <DatePicker
        format={picker !== "month" ? DATE_FORMAT : "MMMM-YYYY"}
        picker={picker}
        value={value}
        disabledDate={disabledDate}
        onChange={date => {
          setValue(date)
          onSelect(date, date ? date.format(valueFormat) : "")
        }}
        locale={appLanguage === "en" ? [] : locale}
        {...rest}
      />
    </>
  )
}
