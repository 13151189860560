import { getAppCredentials, removeAppCredentials } from "configs/utils"

import { createSlice } from "@reduxjs/toolkit"

const appSlice = createSlice({
  name: "app",
  initialState: { ...getAppCredentials() },
  reducers: {
    setAppToken(state, { payload }) {
      state.auth.token = payload?.token
    },
    setIsLoggedIn(state, { payload }) {
      state.auth.isLoggedIn = payload
    },
    setAppUser(state, { payload }) {
      state.user = payload
    },
    setAppUserState(state, { payload }) {
      state.user.status = payload
    },
    setPrivileges(state, { payload }) {
      state.privileges = payload
    },
    setAppLocale(state, { payload }) {
      state.configs.locale = payload
    },
    setAppDirection(state, { payload }) {
      state.configs.direction = payload
    },
    logout(state, { payload }) {
      removeAppCredentials(payload)
      state.auth.isLoggedIn = false
      state.auth.token = null
      state.user = null
      state.privileges = null
    },
    setSelectedMenuItem(state, { payload })
    {
      state.selectedMenuItem = payload
    },
    setSelectedSideMenuItem(state, { payload })
    {
      state.selectedSideMenuItem = payload
    },
  },
})

export const {
  setAppToken,
  setIsLoggedIn,
  setAppUser,
  setPrivileges,
  logout,
  setAppUserState,
  setSelectedMenuItem,
  setSelectedSideMenuItem,
  setAppLocale,
  setAppDirection,
} = appSlice.actions

export default appSlice
