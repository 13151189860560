import { Card, Col, Divider, Row, Space } from "antd"
import { DataTable, Icon, Number, Text, Title } from "components/common"
import { useLazyGetProfileSummaryQuery } from "features/shared/api"
import { useEffect, useMemo } from "react"
import { t } from "i18next"
import { localisedValue } from "utils"

const TargetVsAchieved = ({ staffId }) => {
  const [getProfileSummary, { data, isFetching, error }] = useLazyGetProfileSummaryQuery()

  const targetColumns = [
    { title: t("Project Name"), dataIndex: "projectName" },
    { title: t("Achieved"), dataIndex: "achieved" },
    { title: t("Target"), dataIndex: "target" },
  ]

  useEffect(() => {
    getProfileSummary({ staffId, load: "targetVsAchieved" }, true)
  }, [])

  const tableData = useMemo(() => {
    if (data) {
      return (data?.targetVsAchieved?.puTargets).map((e, index) => ({
        key: index,
        projectName: localisedValue({ object: e, key: "projectName" }),
        achieved: e.achieved,
        target: e.target,
      }))
    }
    return []
  }, [data, isFetching])

  const revenue = useMemo(() => {
    if (data) {
      return [
        [t("Achieved"), data?.targetVsAchieved?.achievedRevenue || "-"],
        [t("Regional Avg./User"), data?.targetVsAchieved?.regionalAverage || "-"],
        [t("Target"), data?.targetVsAchieved?.targetRevenue || "-"],
      ]
    }
    return []
  }, [data, isFetching])

  return (
    <>
      <>
        <Space>
          <Icon color={"#9D9D9D"} icon="AiOutlineRise" hasBackground size="1.5em" />
          <Title level={4} className="mbe-0">
            {t("Revenue")}
          </Title>
        </Space>
        <Card loading={isFetching} style={{ marginTop: "20px", marginBottom: "0px" }}>
          <Row justify="space-between">
            {revenue?.map((e, index) => (
              <>
                <Col span={6} key={index} className="text-center" style={{ gap: "40px" }}>
                  <Text type="secondaryLight" style={{ fontSize: "16px" }} block>
                    {e[0]}
                  </Text>

                  <Text className="bold" style={{ fontSize: "16px" }}>
                    <Number isStringOnly={true} value={e[1]} digitAfterFraction={0} />
                  </Text>
                </Col>
                {index < revenue.length - 1 && (
                  <Col>
                    <Divider type="vertical" style={{ borderColor: "#F5F5F5", marginInline: "30px", height: "50px" }} />
                  </Col>
                )}
              </>
            ))}
          </Row>
        </Card>
      </>

      <>
        <Space style={{ marginTop: "40px", marginBottom: "20px" }}>
          <Icon color={"#9D9D9D"} icon="TbTargetArrow" hasBackground size="1.5em" />
          <Title level={4} className="mbe-0">
            {t("Project Unit Target")}
          </Title>
        </Space>
        <DataTable
          stickyOffset={0}
          error={error}
          data={tableData || []}
          noUrlPush={true}
          loading={isFetching}
          columns={targetColumns || []}
        />
      </>
    </>
  )
}

export default TargetVsAchieved
