import { t } from "i18next"
import { DATE_FORMAT } from "constants"

export const payorderFormProps = ({ referenceError, referenceValidity }) => [
  {
    required: true,
    name: "payorderBank",
    label: t("Bank"),
    hasFeedback: false,
    placeholder: t("Select bank"),
  },
  {
    required: true,
    name: "payorderNumber",
    label: t("Pay Order Number"),
    hasFeedback: true,
    rules: {
      validator: (_, value) => {
        return referenceError || referenceValidity?.isValid === false
          ? Promise.reject(t("Pay order number is invalid"))
          : Promise.resolve()
      },
    },
  },
  {
    required: true,
    name: "beneficiaryAccountNumberPayorder",
    label: t("Beneficiary Account Number"),
    hasFeedback: false,
  },
]

export const payorderFormChildren = ({ setFieldsValue, optionList, getFieldValue, debouncedReferenceValidator }) => {
  return [
    {
      type: "select",
      options: optionList?.bank,
      placeholder: t("Select bank"),
      getOptionValue: item => item.key,
      onChange: (value, option) => {
        setFieldsValue({ payorderBank: value, payorderNumber: null })
      },
    },
    {
      type: "text",
      placeholder: t("Enter number"),
      disabled: !!getFieldValue("payorderBank") ? false : true,
      onChange: e => {
        if (e.target.value !== "") {
          debouncedReferenceValidator({
            referenceNumber: e.target.value,
            bankKey: getFieldValue("payorderBank"),
            paymentModeKey: getFieldValue("modeOfPayment")?.key,
          })
        }
      },
    },
    {
      type: "text",
      placeholder: t("Enter account number"),
    },
  ]
}

export const depositSlipFormProps = () => [
  {
    required: true,
    name: "depositBank",
    label: t("Deposited Bank"),
    hasFeedback: false,
  },
  {
    required: true,
    name: "depositSlipNumber",
    label: t("Deposit Slip Number"),
  },
  {
    required: true,
    name: "depositDate",
    label: t("Deposit Date"),
    hasFeedback: false,
  },
  {
    required: true,
    name: "accountNumber",
    label: t("Account Number"),
    hasFeedback: false,
  },
]

export const depositSlipFormChildren = ({ setFieldsValue, optionList }) => {
  return [
    {
      type: "select",
      options: optionList?.bank,
      placeholder: t("Select bank"),
      getOptionValue: item => item.key,
      onChange: (value, option) => {
        setFieldsValue({ depositBank: value })
      },
    },
    {
      type: "text",
      placeholder: t("Enter deposit slip number"),
    },
    {
      type: "date",
      disableFuture: true,
      allowClear: true,
      format: DATE_FORMAT,
    },
    {
      type: "text",
      placeholder: t("Enter account number"),
    },
  ]
}

export const onlinePaymentFormProps = () => [
  {
    required: true,
    name: "bank",
    label: t("From Bank"),
    hasFeedback: false,
  },
  {
    required: true,
    name: "transactionNumber",
    label: t("Transaction Number"),
    hasFeedback: false,
  },
  {
    name: "fromAccountTitle",
    label: t("From Account Title"),
    hasFeedback: false,
  },
  {
    name: "fromAccountNumber",
    label: t("From Account Number"),
    hasFeedback: false,
  },
  {
    name: "beneficiaryAccountTitle",
    label: t("Beneficiary Account Title"),
    hasFeedback: false,
  },

  {
    name: "beneficiaryAccountNumber",
    label: t("Beneficiary Account Number"),
    hasFeedback: false,
  },

  {
    required: true,
    name: "paymentType",
    label: t("Type"),
    hasFeedback: false,
  },
  {
    name: "transactionDate",
    label: t("Transaction Date"),
    hasFeedback: false,
  },
]

export const onlinePaymentFormChildren = ({ setFieldsValue, optionList }) => {
  return [
    {
      type: "select",
      options: optionList?.bank,
      placeholder: t("Select bank"),
      getOptionValue: item => item.key,
      onChange: (value, option) => {
        setFieldsValue({ bank: value })
      },
    },
    {
      type: "text",
      placeholder: t("Enter transaction number"),
    },
    {
      type: "text",
      placeholder: t("Enter account title"),
    },
    {
      type: "text",
      placeholder: t("Enter account number"),
    },
    {
      type: "text",
      placeholder: t("Enter account title"),
    },
    {
      type: "text",
      placeholder: t("Enter account Number"),
    },
    {
      type: "select",
      options: optionList?.paymentMode,
      placeholder: t("Select type"),
      onChange: (value, option) => {
        setFieldsValue({ paymentType: option })
      },
    },
    {
      type: "date",
      disableFuture: true,
      allowClear: true,
      format: DATE_FORMAT,
    },
  ]
}

export const chequeFormProps = ({ referenceError, referenceValidity }) => [
  {
    required: true,
    name: "chequeBank",
    label: t("Bank"),
    hasFeedback: false,
  },
  {
    required: true,
    name: "referenceNumber",
    label: t("Cheque Number"),
    hasFeedback: false,
    rules: {
      validator: (_, value) => {
        return referenceError || referenceValidity?.isValid === false
          ? Promise.reject(t("Cheque number is invalid"))
          : Promise.resolve()
      },
    },
  },
  {
    required: true,
    name: "fromAccountNumber",
    label: t("From Account Number"),
    hasFeedback: false,
  },
  {
    required: true,
    name: "paymentType",
    label: t("Type"),
    hasFeedback: false,
  },
  {
    name: "chequeDate",
    label: t("Cheque Date"),
    hasFeedback: false,
  },
]

export const chequeFormChildren = ({ setFieldsValue, optionList, getFieldValue, debouncedReferenceValidator }) => {
  return [
    {
      type: "select",
      options: optionList?.bank,
      placeholder: t("Select bank"),
      getOptionValue: item => item.key,
      onChange: (value, option) => {
        setFieldsValue({ chequeBank: value, referenceNumber: null })
      },
    },
    {
      type: "text",
      placeholder: t("Enter number"),
      disabled: !!getFieldValue("chequeBank") ? false : true,
      onChange: e => {
        if (e.target.value !== "") {
          debouncedReferenceValidator({
            referenceNumber: e.target.value,
            bankKey: getFieldValue("chequeBank"),
            paymentModeKey: getFieldValue("modeOfPayment")?.key,
          })
        }
      },
    },
    {
      type: "text",
      placeholder: t("Enter account number"),
    },
    {
      type: "select",
      options: optionList?.paymentMode,
      placeholder: t("Select payment type"),
      onChange: (value, option) => {
        setFieldsValue({ paymentType: option })
      },
    },
    {
      type: "date",
      disableFuture: true,
      allowClear: true,
      format: DATE_FORMAT,
    },
  ]
}
