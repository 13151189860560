import { ActionButtons, TitleDescription } from "components/custom"
import {
  Button,
  ComponentState,
  DataTable,
  Group,
  Icon,
  Modal,
  Number,
  Skeleton,
  Text,
  notification,
} from "components/common"
import { Col, List, Row, Tabs } from "antd"
import { IcCoins, IconApartment, IconHome } from "components/svg"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import {
  useAddShortlistedListingsMutation,
  useDeleteShortlistedListingsMutation,
  useLazyGetBuySellOverviewQuery,
  useLazyGetRecommendedListingsQuery,
  useLazyGetShortlistedListingsQuery,
} from "features/leads/api"

import cx from "clsx"
import { getAreaUnit, joinBytItems, localisedValue, convertToSpecificAreaUnit } from "utils"
import styles from "./index.module.less"
import theme from "utils/themeVars"
import { useSelector } from "react-redux"

const BuyRentModal = forwardRef((_props, ref) => {
  const [tab, setTab] = useState("recommended")
  const [leadIDToDelete, setLeadIDToDelete] = useState(null)
  const [selectedProperties, setSelectedProperties] = useState([])
  const [purpose, setPurpose] = useState(null)
  const [leadId, setLeadId] = useState()
  const userInfo = useSelector(state => state?.app?.user?.info)
  const userPlatformKey = userInfo?.AgencyConfiguration?.Platform?.platformKey
  const modalRef = useRef()
  const confirmationModalRef = useRef()

  const [getOverviewDetail, { data: overview, isFetching: isFetchingOverview, error: errorOverview }] =
    useLazyGetBuySellOverviewQuery()
  const [
    getRecommendedList,
    { data: recommended, isFetching: isFetchingRecommended, isLoading: isLoadingRecommended, error: errorRecommended },
  ] = useLazyGetRecommendedListingsQuery()

  const [
    getShortListedList,
    { data: shortlisted, isFetching: isFetchingShortListed, isLoading: isLoadingShortListed, error: errorShortListed },
  ] = useLazyGetShortlistedListingsQuery()

  const [addShortlistedListings] = useAddShortlistedListingsMutation()

  const [
    deleteShortlistedListings,
    { isLoading: isDeletingLead, isError: isErrorDeletingLead, error: errorDeletingLead },
  ] = useDeleteShortlistedListingsMutation()

  const onModalOpen = lead => {
    getOverviewDetail(lead.id)
    getRecommendedList({ leadId: lead.id, params: { page: 0, pageLimit: 10 } })
    getShortListedList(lead.id)
    setLeadId(lead.id)
    setPurpose(lead?.purposeLabel)
    modalRef.current.setVisible(true)
  }

  useImperativeHandle(ref, () => ({ showFor: onModalOpen }), [])

  const handleOnConfirm = () => {
    if (leadIDToDelete) {
      deleteShortlistedListings({ overview, id: leadIDToDelete })
        .unwrap()
        .then(() => {
          notification.success({ message: "Successfully removed shortlisted inventory" })
          closeAndResetConfirmationModal()
        })
    }
  }

  const handleAddToShortlistedLeads = () => {
    let data = { listingIds: selectedProperties, inquiryId: overview?.InternalInquiryLocations?.[0]?.inquiryId }
    addShortlistedListings(data)
      .unwrap()
      .then(() => {
        setSelectedProperties([])
        notification.success({ message: "Inventory shortlisted successfully" })
      })
      .catch(e => {
        notification.error({ message: e?.message })
      })
  }

  const onChangePage = (page, pageSize) => {
    getRecommendedList({ leadId, params: { page, pageLimit: pageSize } })
  }

  const closeAndResetModal = () => {
    modalRef?.current?.setVisible(false)
    setTab("recommended")
    setLeadId(null)
    setSelectedProperties([])
  }

  const closeAndResetConfirmationModal = () => {
    confirmationModalRef.current.setVisible(false)
    setLeadIDToDelete(null)
  }

  const getLabel = (label, count) => (
    <div>
      {label} <span>({count})</span>
    </div>
  )

  const buyRentTabs = useMemo(() => {
    return [
      {
        label: getLabel("Related Secondary Property", recommended?.count),
        key: "recommended",
      },
      {
        label: getLabel("Shortlisted Secondary Property List", shortlisted?.count),
        key: "shortlisted",
      },
    ]
  }, [recommended, shortlisted])

  const columns = useMemo(() => {
    return [
      { title: "ADDRESS", dataIndex: "address", key: "address" },
      { title: "TYPE", dataIndex: "type", key: "type" },
      { title: "PRICE", dataIndex: "price", key: "price" },
      { title: "AREA", dataIndex: "area", key: "area" },
      ...(tab === "shortlisted" ? [{ title: "ACTIONS", dataIndex: "actions", key: "actions" }] : []),
    ]
  }, [tab])

  const getActionButtons = id => {
    return [
      {
        key: "deleteRecord",
        icon: "FiTrash2",
        color: "#E83A3A",
        content: "Delete Record",
        onClick: () => {
          setLeadIDToDelete(id)
          confirmationModalRef?.current?.setVisible(true)
        },
      },
    ]
  }

  const tableData = useMemo(() => {
    const getData = (data, showActions) =>
      data?.rows?.map(e => {
        return {
          key: e?.id,
          address: (
            <TitleDescription
              title={localisedValue({ key: "unitNumber", object: e?.Address })}
              gapSpace={4}
              description={joinBytItems(
                [
                  localisedValue({ key: "locality", object: e.Address }),
                  localisedValue({ key: "city", object: e?.Address }),
                ],
                <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              )}
              key={`address - ${e.id}`}
            />
          ),
          type: localisedValue({ key: "name", object: e?.Type }),
          price: <Number tooltip isCurrency isStringOnly value={e?.price} />,
          area: (
            <Number
              digitAfterFraction={2}
              value={convertToSpecificAreaUnit(e?.landArea, userPlatformKey === "bayut_ksa" ? "sq_m" : "sq_f")}
              unit={getAreaUnit(userPlatformKey)}
            />
          ),
          ...(showActions && { actions: <ActionButtons list={getActionButtons(e?.id)} animate={false} /> }),
        }
      })

    return getData(tab == "recommended" ? recommended : shortlisted, tab != "recommended")
  }, [tab, shortlisted, recommended])

  return (
    <>
      <Modal
        width={840}
        ref={modalRef}
        onCancel={closeAndResetModal}
        loading={isFetchingOverview}
        hideCancelButton={true}
        className={styles.modalBg}
        bodyStyle={{ padding: 24 }}
        footer={null}
        destroyOnClose
      >
        {!!errorOverview ? (
          <ComponentState
            onClick={() => leadId && getOverviewDetail(leadId)}
            btnLoading={isFetchingOverview}
            description={errorOverview?.message}
            status={errorOverview?.status}
          />
        ) : isFetchingOverview ? (
          <RenderSkeleton />
        ) : (
          <>
            <Group template="auto 1fr" gap="24px" className="mbe-32">
              <Icon
                hasBackground
                icon="MdImage"
                color="#35A447"
                size="2.4em"
                iconContainerStyle={{ padding: 19, backgroundColor: "#D3F9DD" }}
              />
              <Group template="45ch" gap="16px">
                <Text color="#000" className="bold" block>
                  {overview?.Type?.children?.[0]?.name}
                </Text>
                <div>
                  <Row className={styles.productInfoMain}>
                    <Col className={styles.productInfo}>
                      <IconHome size={20} className={cx("d-grid", styles.icon)} />
                      <small className="gray-800">Purpose</small>
                      <strong>{joinBytItems([purpose, "Secondary"], " - ")}</strong>
                    </Col>
                    {overview?.InterestLocations?.[0]?.locality?.name && (
                      <Col className={styles.productInfo}>
                        <IconApartment size={20} className={cx("d-grid", styles.icon)} />
                        <small className="gray-800">Location</small>
                        <strong>{overview.InterestLocations[0].locality.name}</strong>
                      </Col>
                    )}
                    {overview?.budget && (
                      <Col className={styles.productInfo}>
                        <IcCoins size={16} className={cx("d-grid", styles.icon)} />
                        <small className="gray-800">Budget</small>
                        <strong>
                          <Number tooltip isCurrency isStringOnly value={overview?.budget} />
                        </strong>
                      </Col>
                    )}
                  </Row>
                </div>
              </Group>
            </Group>
            <Tabs activeKey={tab} items={buyRentTabs} onChange={key => setTab(key)} />
            <Group gap="16px">
              {tab === "recommended" && selectedProperties?.length > 0 && (
                <Button
                  text="Shortlist Property"
                  icon="BsFillPersonPlusFill"
                  size="small"
                  type="light"
                  color={theme["primary-color"]}
                  className="pi-12"
                  style={{ border: "1px solid currentcolor", height: 46 }}
                  onClick={handleAddToShortlistedLeads}
                />
              )}
              <DataTable
                pagination={{
                  totalCount: tab === "recommended" ? recommended?.count : shortlisted?.count,
                  pageOptions: [10, 25, 50],
                }}
                onChangePage={onChangePage}
                loading={isFetchingRecommended || isFetchingShortListed}
                noUrlPush={true}
                error={tab === "recommended" ? errorRecommended : errorShortListed}
                columns={columns}
                data={tableData}
                stickyOffset={-22}
                rowSelection={
                  tab === "recommended" && {
                    type: "checkbox",
                    columnWidth: 70,
                    onChange: selectedRowKeys => setSelectedProperties(selectedRowKeys),
                  }
                }
              />
            </Group>
          </>
        )}
      </Modal>

      <Modal
        onOk={handleOnConfirm}
        ref={confirmationModalRef}
        onCancel={closeAndResetConfirmationModal}
        loading={isDeletingLead}
        onOkProps={{ danger: "danger", loading: isDeletingLead }}
        okText="Confirm"
        width={500}
        title="Confirm Delete Shortlisted Lead"
        footerAlert={isErrorDeletingLead ? errorDeletingLead : null}
        destroyOnClose
      >
        <Text>Are you sure to remove this shortlisted lead?</Text>
      </Modal>
    </>
  )
})

const RenderSkeleton = () => {
  return (
    <>
      <Group template="auto 1fr" gap="24px" className="mbe-32">
        <Skeleton type="image" />
        <Group template="45ch" gap="16px">
          <Skeleton type="text" />
          <Row className={styles.productInfoMain}>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
          </Row>
        </Group>
      </Group>
      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {[1, 2, 3, 4, 5]?.map(e => (
          <List.Item>
            <Skeleton type="text" />
            <Skeleton type="text" />
          </List.Item>
        ))}
      </List>
    </>
  )
}

export default BuyRentModal
