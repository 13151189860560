import { InfoList } from "components/custom"
import { Number } from "../../common"
import { useLazyGetAreaUnitsQuery } from "features/shared/api"
import { useMemo } from "react"

export default function AreaUnit({ value }) {
  const [fetchData, { data: areaUnits, isFetching: isAreaUnitFetching }] = useLazyGetAreaUnitsQuery()

  const getAreaUnitList = useMemo(() => {
    return (
      !!areaUnits?.length &&
      areaUnits.map(e => {
        let val = e.sqfConversion * value
        return [e.displayText, <Number value={val} digitAfterFraction={4} />]
      })
    )
  }, [value, areaUnits])

  return (
    <InfoList
      loading={isAreaUnitFetching}
      list={getAreaUnitList || []}
      onClick={e => {
        fetchData(null, true)
        e?.stopPropagation()
      }}
    />
  )
}
