import { localisedValue } from "utils"
import { t } from "i18next"

const assigneeUserListTransformHelper = response => {
  if (!!response.nonTeamMembers && !!response.teamMembers) {
    const nonTeamMemberList = [
      {
        label: t("Others"),
        options: response.nonTeamMembers.map(it => ({
          ...it,
          label: localisedValue({ object: it, key: "name" }),
          value: it.id.toString(),
          id: it.id,
        })),
      },
    ]
    const teamMemberList = response.teamMembers.map(e => {
      return {
        ...e,
        label: localisedValue({ object: e, key: "team" }),
        options: e.users
          ? e.users?.map(it => {
              return { ...it, label: localisedValue({ object: it, key: "name" }), value: it.id.toString(), id: it.id }
            })
          : [],
      }
    })
    const combinedTeamList = teamMemberList.concat(nonTeamMemberList)
    return combinedTeamList
  }

  return response.map(e => {
    return {
      ...e,
      label: localisedValue({ object: e, key: "team" }),
      options: e.users.map(it => {
        return { ...it, label: localisedValue({ object: it, key: "name" }), value: it.id.toString(), id: it.id }
      }),
    }
  })
}

export default assigneeUserListTransformHelper
