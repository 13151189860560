const availablePaymentDiscountTransformHelper = response => {
  return response?.map(e => {
    return {
      ...e,
      discountId: e.id,
      value: e.payment,
      label: `${e.payment}%`,
    }
  })
}

export default availablePaymentDiscountTransformHelper
