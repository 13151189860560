import { Space } from "antd"
import cx from "clsx"
import { Button, Icon, ModifiedDrawer, Tag, Text, TextInput, Title } from "components/common"
import { configs } from "configs"
import { t } from "i18next"
import { forwardRef, useEffect, useRef, useState } from "react"
import { BsSend } from "react-icons/bs"
import { useSelector } from "react-redux"
import theme from "utils/themeVars"
import { useCreateSessionMutation, useSendChatMessageMutation } from "./api"
import MessageBox from "./components/messageBox"
import styles from "./index.module.less"

const Chatbot = forwardRef((_props, ref) => {
  const [createSession] = useCreateSessionMutation()
  const [sendMessage, { isLoading: isSending }] = useSendChatMessageMutation()
  const user = useSelector(state => state.app.user.info)

  const [messages, setMessages] = useState([])
  const [text, setText] = useState({})
  const [session, setSessionId] = useState()
  const messagesEndRef = useRef(null)
  const inputRef = useRef()

  const errorMessage = t("Due to enormous requests, we were unable to cater your query. Please try again.")

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    inputRef.current?.focus()
  }
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const onDrawerVisible = () => {
    createSession({ tenant: configs.agency, entity_type: "user", entity_id: user?.id })
      .unwrap()
      .then(id => {
        setSessionId(id)
        inputRef.current?.focus()
      })
  }

  const onEnterText = () => {
    setText(undefined)
    setMessages(prev => {
      let temp = [...prev]
      temp.push({ ...text })
      temp.push({ type: "chatbot", message: "", loading: true })
      return temp
    })
    sendMessage({ sessionkey: session, query: text.messages })
      .unwrap()
      .then(response => {
        setMessages(prev => prev?.map(item => (item?.loading ? { ...item, message: response, loading: false } : item)))
      })
      .catch(error => {
        setMessages(prev =>
          prev?.map(item => (item?.loading ? { ...item, message: errorMessage, loading: false } : item))
        )
      })
  }

  return (
    <ModifiedDrawer
      ref={ref}
      onCancel={() => ref?.current?.setVisible(false)}
      onClose={() => ref?.current?.setVisible(false)}
      className={styles.chatDrawer}
      title={
        <Space size={8} align="center">
          <Icon icon="SvgIconAi" size={36} />
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <Space className="w-100">
              <Title level={3} className={cx(styles.headerTitle, "mbe-0")}>
                {t("Odyssey")}
              </Title>
              <Tag
                color={theme["primary-color"]}
                className="pb-2 pi-12"
                style={{ lineHeight: "normal", verticalAlign: 3 }}
              >
                {t("BETA")}
              </Tag>
            </Space>
            <Text className={styles.headerDesc}>
              {t("Odyssey AI might generate incorrect data as a part of its learning process")}
            </Text>
          </div>
        </Space>
      }
      footer={
        <>
          <TextInput
            value={text?.messages}
            placeholder={t("Ask Anything")}
            style={{ borderRadius: 30 }}
            handleChange={e => setText({ messages: e.target.value, type: "user" })}
            disabled={isSending}
            allowClear
            onPressEnter={() => {
              if (text?.messages) onEnterText()
            }}
            ref={inputRef}
            className={styles.messageField}
          />
          <Button
            shape="circle"
            icon={<BsSend size={20} color={isSending ? "#a7a7a7" : "#fff"} />}
            onClick={() => onEnterText()}
            disabled={isSending || !text?.messages}
            className={styles.btnSend}
          />
        </>
      }
      forceRender
      width={540}
      onDrawerVisible={onDrawerVisible}
    >
      {messages?.map((item, index) => (
        <MessageBox key={index} message={item?.message || item?.messages} type={item?.type} loading={item?.loading} />
      ))}
      <div ref={messagesEndRef} />
    </ModifiedDrawer>
  )
})

export default Chatbot
