import { createPrivilegeObject } from "utils/privileges/privilegesMapper"

const clientListingTransformHelper = response => {
  const resp = {
    ...response,
    classificationCounts: {
      all: response.classificationCounts.all,
      inquiry: response.classificationCounts.inquiry,
      prospect: response.classificationCounts.prospect,
      mature: response.classificationCounts.mature,
      pre_closure: response.classificationCounts.pre_closure,
      sold: response.classificationCounts.sold,
      closed_lost: response.classificationCounts.closed_lost,
    },
  }

  resp.data = resp.data.map(item => {
    const client = { ...item }
    client["haveCallLogs"] = item?.PhoneCalls?.in || item?.PhoneCalls?.out
    client["leadIdsWithTasks"] = item.leadsData.filter(e => e.status !== "New").map(e => e.id)
    client["privileges"] = createPrivilegeObject(item?.privileges)
    return client
  })

  return resp
}

export default clientListingTransformHelper
