import moment from "moment/moment"

const statsTransformResponse = response => {
  function convertSecondsToHoursMinutes(seconds) {
    const duration = moment.duration(seconds, "seconds")
    const hours = duration.hours().toString().padStart(2, "0")
    const minutes = duration.minutes().toString().padStart(2, "0")
    const second = duration.seconds().toString().padStart(2, "0")
    return `${hours}:${minutes}:${second}`
  }
  return { ...response, totalTalkTimeInSec: convertSecondsToHoursMinutes(response.calls.totalTalkTimeInSec) }
}
export default statsTransformResponse
