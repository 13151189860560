import { localisedValue } from "utils"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"

const leadRowObject = e => {
  let privileges = createPrivilegeObject(e.privileges)
  return {
    ...e,
    ...e?.InternalInquiry,
    id: e.id,
    class: localisedValue({ object: e.TSRClassification, key: "name" }),
    lastTask: e.LatestTask,
    lastTaskDate: e.LatestTask?.createdAt,
    interest: e.Project?.name,
    description: e.Project?.projectId,
    type: e?.Type?.name,
    purpose: localisedValue({ object: e.Purpose, key: "name" }),
    source: { key: e.source, name: e?.source?.replace("_", " ").toUpperCase() },
    sourceType: e.InternalInquirySource?.[0]?.name,
    clientName: localisedValue({ object: e.Client, key: "name" }),
    nextTask: e.NextStatusWanted === null ? undefined : e.NextStatusWanted?.statusTitleTodo,
    nextTaskDate: e.nextStatusWanted === null ? undefined : e.nextStatusDeadline,
    privileges,
  }
}

const leadsTransformHelper = response => {
  const leadRows = response.data.map(e => leadRowObject(e))
  const { overdue, today, tomorrow, week, all } = response.deadlineCounters
  return { ...response, data: leadRows, deadlineCounters: { overdue, today, tomorrow, week, all } }
}

export { leadRowObject, leadsTransformHelper }
