import { DATE_TIME_FORMAT } from "constants"
import { convertQueryObjToString, deleteKeysWithSubstring } from "utils"
import idleTransformResponse from "./idleTransformResponse"
import { leadsTransformHelper } from "./transformResponse"
import moment from "moment"
import parentApi from "store/parentApi"
import statsTransformResponse from "./statsTransformResponse"
import userPresenceTransformResponse from "./userPresenceTransformResponse"
import { dateTimeFunction } from "utils/date"
import { getEndPointArgs } from "hooks/useQueryUpdate"
import { DATE_FORMAT } from "constants"
import CallTransformer from "./callTransformer"

const tsrApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getLeadsTSR: build.query({
      query: p => {
        const params = { ...p }
        // TODO: Need to remove this need implementation from backend
        //
        const checkUsers = key => {
          if (!!params[key]) {
            let childIdentifierKey = key + "_withoutChildren"
            params[key] = encodeURIComponent(
              `{"teamIds":[],"userIds":[${decodeURIComponent(params[key])}],"includeAllChildren":${!params?.[
                childIdentifierKey
              ]}}`
            )
          }
        }
        const checkDate = key => {
          if (!!params[key]) {
            const dateR = params[key].split(",")
            params[key] = encodeURIComponent(
              `{"from":"${dateTimeFunction({
                date: dateR[0],
                timeZone: true,
                apiRequest: true,
              })}","to":"${dateTimeFunction({
                date: dateR[1],
                timeZone: true,
                apiRequest: true,
              })}"}`
            )
          }
        }
        checkUsers("taskAllocatedTo")
        checkUsers("sharedWith")
        checkUsers("allocatedTo")
        checkUsers("assignedTo")
        checkDate("createdAt")
        checkDate("taskAllocatedDate")
        checkDate("timeAllocation")
        checkDate("lastTaskAdded")
        checkDate("dateShared")
        checkDate("deadline")
        let updatedParams = deleteKeysWithSubstring(params, "_withoutChildren")
        return `/todos/search?teamType=telesales&${convertQueryObjToString(updatedParams)}`
      },
      transformResponse: (response, meta, args) => leadsTransformHelper(response, args),
      providesTags: ["tsrLeads"],
    }),

    addTaskTsr: build.mutation({
      query: ({ lead, leadDetail, encodedFile, ...values }) => {
        const payload = {
          statusId: values?.subLocation
            ? values.subLocation
            : values?.subofSubTask
            ? values?.subofSubTask
            : values?.subTask,
          nextStatusId: values?.nextTask,
          phoneCallId: values?.call,
          status: "successful",
          projectId: values?.project ? +values?.project : +lead?.Project?.projectId,
          tsrClassificationId: values.tsrClassification ? +values.tsrClassification : +values.leadsClassification,
          classificationId: +leadDetail?.inquiry?.InternalInquiryClassification?.id,
          completionDate: dateTimeFunction({
            date: values.completionDate.format(DATE_TIME_FORMAT),
            timeZone: true,
            apiRequest: true,
          }),
          nextStatusDeadline: dateTimeFunction({
            date: values.deadLine.format(DATE_TIME_FORMAT),
            timeZone: true,
            apiRequest: true,
          }),
          ...(encodedFile && {
            attachments: encodedFile
              ? encodedFile.map(e => {
                  return { file: e.file.split(",")[1], name: e.name }
                })
              : null,
          }),
          todoForTSR: true,
          ...(values.timeSlot && {
            calenderEvent: {
              date: dateTimeFunction({ date: values?.dateSlot, timeZone: true, apiRequest: true })?.toString(),
              start: dateTimeFunction({ date: values.timeSlot[0], timeZone: true, apiRequest: true })?.toString(),
              end: dateTimeFunction({ date: values.timeSlot[1], timeZone: true, apiRequest: true })?.toString(),
            },
          }),

          comments: values.comment,
          source: "web_v2",
        }
        return { url: `/internalInquiries/${lead?.inquiryId}/task/v2`, method: "POST", body: payload }
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [{ type: "leads", id: args.lead.Client.id }, "taskLogs", "client-detail", "leads", "tsrLeads"]
          : []
      },
    }),

    updateUserPresence: build.mutation({
      query: ({ userId, time, value }) => {
        const payload = {
          status: value?.id ? "idle" : "active",
          ...(value?.id && { idleReasonId: value?.id }),
          ...(value?.idleReasonValue && { idleReasonValue: value?.idleReasonValue }),
          statusUpdatedAt: moment(), // what to do with this regarding timezone?
        }
        return { url: `/users/${userId}/presenceStatus`, method: "PUT", body: payload }
      },

      invalidatesTags: (result, error, args) => {
        return !error ? ["getUserPresence"] : []
      },
    }),

    getTsrStats: build.query({
      query: obj => `/users/${obj?.id}/activities?${convertQueryObjToString(obj?.extra)}`,
      transformResponse: response => statsTransformResponse(response),
    }),

    getTSRCalls: build.query({
      query: id => `/phoneCalls/callsByTag?clientId=${id}`,
      transformResponse: response => CallTransformer(response),
    }),

    getUserIdleReasons: build.query({
      query: id => `/users/idleReasons`,
      transformResponse: (response, meta, args) => idleTransformResponse(response, args),
    }),

    getUserPresence: build.query({
      query: id => `/users/${id}/presenceStatus`,
      transformResponse: (response, meta, args) => userPresenceTransformResponse(response, args),
      providesTags: ["getUserPresence"],
    }),

    pbxCall: build.mutation({
      query: ({ number }) => {
        const payload = {
          number: number,
        }
        return { url: `/pbxMachines/makePBXCall`, method: "POST", body: payload }
      },
    }),
    markFavoriteUnFavoriteTsr: build.mutation({
      query: ({ tsrIds, favorite }) => {
        const payload = { inquiryId: tsrIds }
        return {
          url: favorite ? `/internalInquiries/markFavorite` : `/internalInquiries/markUnfavorite`,
          method: "PUT",
          body: payload,
        }
      },
      async onQueryStarted({ tsrIds, favorite }, { getState, dispatch, queryFulfilled }) {
        const state = getState()
        const { parentApi, app } = state
        const listingQueryArgs = getEndPointArgs("getLeadsTSR", parentApi)
        const listingPatchResult =
          listingQueryArgs &&
          dispatch(
            tsrApi.util.updateQueryData("getLeadsTSR", listingQueryArgs, draftClients => {
              draftClients.data.forEach(e => {
                if ([tsrIds].includes(e.inquiryId)) {
                  if (favorite) {
                    e.InternalInquiry.isFavoriteOf.push(app.user.info.id)
                  } else {
                    e.InternalInquiry.isFavoriteOf = e.InternalInquiry.isFavoriteOf.filter(
                      userId => userId !== app.user.info.id
                    )
                  }
                }
              })
            })
          )
        try {
          await queryFulfilled
        } catch {
          listingPatchResult?.undo()
        }
      },
    }),

    changeBulkSharing: build.mutation({
      query: data => {
        let duplicateMap = {}
        let uniqueTodoKeys = []
        data?.todoKeys?.forEach(item => {
          if (!duplicateMap[item]) {
            uniqueTodoKeys.push(+item)
            duplicateMap[item] = true
          }
        })

        let payload = {
          changeSharingProcess: data?.reallocatedType,
          inquiryIds: uniqueTodoKeys,
          ...(data?.allocatedTo && {
            userIds: Array.isArray(data?.allocatedTo) ? data?.allocatedTo : [+data?.allocatedTo],
          }),
          changeSharingReason: data?.manualAllocationReason
            ? data?.manualAllocationReason
            : "Change sharing happened from TSR",
        }

        return { url: "/internalInquiries/shareWithSupport", method: "PUT", body: payload }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["tsrLeads"] : []
      },
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetLeadsTSRQuery,
  useAddTaskTsrMutation,
  useLazyGetTsrStatsQuery,
  useGetTsrStatsQuery,
  useGetTSRCallsQuery,
  useLazyGetTSRCallsQuery,
  useGetUserIdleReasonsQuery,
  useUpdateUserPresenceMutation,
  useGetUserPresenceQuery,
  usePbxCallMutation,
  useMarkFavoriteUnFavoriteTsrMutation,
  useChangeBulkSharingMutation,
} = tsrApi
