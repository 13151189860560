import { localisedValue } from "utils"

const regionsTransformer = response => {
  const { regions } = response
  return regions.map(e => {
    return {
      ...e,
      id: e.id,
      label: localisedValue({ object: e, key: "name" }),
      value: e.id.toString(),
    }
  })
}

export default regionsTransformer
