import { t } from "i18next"

export const changeKeys = key => {
  const keyTranslations = {
    name: t("Name"),
    email: t("Email"),
    phone: t("Phone"),
    fax: t("Fax"),
    address: t("Address"),
    gender: t("Gender"),
    occupation: t("Occupation"),
    passport: t("Passport"),
    archived: t("Archived"),
    userId: t("User ID"),
    nationalId: t("National ID"),
    localityId: t("Locality ID"),
    clientTypeId: t("Client Type ID"),
    returning: t("Returning"),
    stageId: t("Stage ID"),
    referralType: t("Referral Type"),
    relationship: t("Relationship"),
    dateOfBirth: t("Date Of Birth"),
    zip: t("Zip"),
    comments: t("Comments"),
    Country: t("Country"),
    City: t("City"),
    ClientBuyingIntent: t("Client Buying Intent"),
    ClientRating: t("Client Rating"),
    ClientCellLogs: t("Client Cell Logs"),
    ClientStage: t("Client Stage"),
    ClientType: t("Client Type"),
  }

  return keyTranslations[key]
}
