import { t } from "i18next"
import { ACTION_TYPES, PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"
import {
  Alert,
  DatePicker,
  DetailBar,
  Group,
  Icon,
  Legend,
  Modal,
  RadioButtons,
  SelectInput,
  Tag,
  Text,
  TextInput,
  TimePicker,
  Title,
  UploadDragger,
  notify,
} from "components/common"
import { DATE_FORMAT, DATE_TIME_FORMAT } from "constants"
import { Form, Row } from "antd"
import {
  useAddTaskMutation,
  useCreateOtpMutation,
  useLazyGetCellNumberQuery,
  useVerifyOtpMutation,
} from "features/tasks/api"
import { useEffect, useMemo, useState } from "react"
import { useLazyGetClassificationQuery, useLazyGetProjectsQuery, useLazyGetTaskTypeQuery } from "features/shared/api"

import VerifyMeeting from "../verifyMeeting/verifyMeeting"
import cx from "clsx"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { getAddTaskInitialValues } from "./addTaskInitialValues"
import getPrivilege from "../../../../utils/privileges/privileges"
import getTaskValidationSchema from "./addTaskValidationSchema"
import moment from "moment"
import { schemaRules } from "features/shared/utils"
import styles from "./index.module.less"
import { useLazyGetCalenderEventsQuery } from "features/calendar/api"
import { useLazyGetLeadDetailsQuery } from "features/leads/api"
import { useSelector } from "react-redux"
import VoiceComment from "../voiceComment/voiceComment"
import { localisedValue } from "utils"

export default function AddTaskForm({ lead, client, formRef, from = {} }) {
  const privileges = useSelector(state => state.app.privileges)
  const userInfo = useSelector(state => state?.app?.user.info)
  const userPlateformKey = userInfo?.AgencyConfiguration?.Platform?.platformKey
  let getCalenderEventsObj = {
    startTime: moment().startOf("day").unix(),
    endTime: moment().endOf("day").unix(),
    userId: !!userInfo?.teamType === "support" ? lead?.User?.id : userInfo?.id,
  }

  const [getTaskTypes, { data: taskTypes, isFetching: isFetchingTaskTypes }] = useLazyGetTaskTypeQuery()
  const [getLeadDetails, { data: leadDetailInfo, isFetching: isFetchingLead }] = useLazyGetLeadDetailsQuery()
  // const [getCells, { data: cellNumberArray }] = useLazyGetCellNumberQuery(lead?.clientId)
  const [getProjects, { data: projects }] = useLazyGetProjectsQuery()
  const [getClassifications, { data: classifications }] = useLazyGetClassificationQuery()
  const [getCalendarEvents, { data: getCalendarEventsList }] = useLazyGetCalenderEventsQuery()
  const [
    createOtp,
    { data: generateOtpData, isLoading: isOtpGenerating, isError: isErrorForGenerateOtp, error: generateOtpError },
  ] = useCreateOtpMutation()
  const [verifyOtp, { data: verifyOtpData, isLoading: isOtpVerify, isError: isErrorForVerify, error: verifyOtpError }] =
    useVerifyOtpMutation()
  const [addTask, { isLoading, isError, error, reset: resetErrorForAddTask }] = useAddTaskMutation()

  const [form] = Form.useForm()
  const rules = schemaRules(getTaskValidationSchema())
  const { setFieldsValue, setFieldValue } = form
  const task = Form.useWatch("task", form)
  const subTask = Form.useWatch("subTask", form)
  const verifyMeeting = Form.useWatch("verifyMeeting", form)
  const project = Form.useWatch("project", form)
  const attachment = Form.useWatch("attachment", form)
  const nextTask = Form.useWatch("nextTask", form)
  const dateSlot = Form.useWatch("dateSlot", form)
  const [showCommentErrors, setShowCommentErrors] = useState("");
  const [busySlotVisible, setBusySlotVisible] = useState(false)
  let recorderControls = null

  useEffect(() => {
    if (leadDetailInfo) {
      setFormValues()
    }
  }, [leadDetailInfo])

  useEffect(() => {
    if (subTask === 4 || subTask === 15) {
      getCalendarEvents(getCalenderEventsObj)
    }
  }, [subTask])

  const onModalVisible = () => {
    getProjects()
    getClassifications()
    getLeadDetails(lead?.id)
    getTaskTypes({params:{
      withNextStatuses: 1,
      leadType: lead?.interestedIn,
      ...((userInfo.AgencyConfiguration.Platform.platformKey !== "mubawab" ||
        userInfo.AgencyConfiguration.Platform.platformKey !== "bproperty") &&
        (userInfo.teamType === "sales" ||
          userInfo.teamType === "secondarySales" ||
          userInfo.teamType === "support") && { teamType: userInfo.teamType }),
    }})
    getProjects()
    getClassifications()
    getLeadDetails(lead?.id)

    // getCells(lead?.clientId)
    leadDetailInfo?.inquiry?.id === lead.id && setFormValues()
  }
  const getController = constroller => {
    recorderControls = constroller
  }

  const setFormValues = () => {
    form.setFieldsValue(getAddTaskInitialValues({ ...leadDetailInfo, Project: lead.Project }))
  }

  const handleSuggestedCommentClick = (element) => {
    form.setFieldsValue({comment : element});
    setShowCommentErrors("");
  };

  const generateOtp = async selectedNumber => {
    const response = await createOtp({ selectedNumber, lead })
    if (response.data) {
      setFieldValue("verifyMeeting", "pending")
      return response
    }
    return response
  }
  const onVerifyOTPCode = async otpNumber => {
    const response = await verifyOtp({ otpNumber, lead })
    if (response.data === false) {
      return response.data
    } else if (response.data) {
      notify.success(`${t("OTP is verified")}`)
      setFieldValue("verifyMeeting", "fullFilled")
      fireEvent({ ...ACTION_TYPES.meeting_verify_button, ...from })
    } else {
      notify.error(verifyOtpError.message)
    }
  }
  const onSubmit = async values => {
    const response = await addTask({
      lead,
      encodedFile: values.attachment,
      verifyMeeting,
      ...values,
    })
      .unwrap()
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.task_tab_submit_task,
          ...from,
          response: e.status,
          client_id: client.id,
          lead_id: lead.id,
          ...eventPayload,
        })
      })
         const eventPayload = {
      client_name: lead.Client.name,
      project_name: values.project,
      task_type: taskTypes?.leadStatuses?.find(t => t.id == values.task)?.name,
      task_sub_type: taskChildren?.find(t => t.id === values.subTask)?.name,
      attachment: !!values.attachment ? "yes" : "no",
      comments: values.comment,
      next_task: taskTypes?.nextStatuses?.find(t => t.id == values.nextTask)?.name,
      lead_id: lead.id,
    }
  
    if (!response.error) {
      formRef?.current?.setVisible(false)
      resetErrorForAddTask()
      fireEvent({
        ...ACTION_TYPES.task_tab_submit_task,
        ...from,
        response: "200",
        client_id: client.id,
        lead_id: lead.id,
        ...eventPayload,
      })
      setBusySlotVisible(false)
    }
  }

  const checkEventList = selectedTime => {
    for (let i = 0; i < getCalendarEventsList?.length; i++) {
      let startTime = moment(getCalendarEventsList[i].startTimeStamp).unix()
      let endTime = moment(getCalendarEventsList[i].endTimeStamp).unix()
      if (startTime <= selectedTime[0].unix() && endTime >= selectedTime[1].unix()) {
        setBusySlotVisible(true)
        break
      }
    }
  }

  const isTaskInQCState = useMemo(
    () => leadDetailInfo?.tasks?.find(key => key.QualityCheckTask?.status === "pending"),
    [leadDetailInfo?.tasks]
  )

  const getClientDetail = useMemo(
    () => [
      { label: t("Client"), value: localisedValue({ object: lead, key: "clientName" }) },
      { label: t("Lead ID"), value: lead?.id },
    ],
    [lead, project]
  )

  const taskChildren = useMemo(
    () =>
      taskTypes?.leadStatuses
        ?.find(e => e.id === task)
        ?.children.map(e => {
          if (e?.id === 15 && !!isTaskInQCState) {
            return {
              ...e,
              disabled: true,
            }
          }
          return e
        }),
    [task, isTaskInQCState]
  )
  const taskSubChildren = useMemo(() => taskChildren?.find(e => e.id === subTask)?.children, [subTask, taskChildren])

  const suggestedComments = useMemo(() => {
    let commentsToShow = leadDetailInfo?.tasks?.filter(e => !!e.description).map(e => e?.description)
    return commentsToShow?.slice(-3)
  }, [leadDetailInfo?.tasks])
  return (
    <div>
      <Modal
        title={t("Add Task")}
        ref={formRef}
        okText={t("Submit")}
        width={780}
        cancelText={t("Cancel")}
        loading={isLoading}
        onOk={form.submit}
        onModalVisible={onModalVisible}
        footerAlert={error}
        destroyOnClose={true}
        scrollToFirstError
        onCancel={() => {
          recorderControls.stopRecording()
          setBusySlotVisible(false)
          resetErrorForAddTask()
          formRef?.current?.setVisible(false)
          setShowCommentErrors("")
        }}
      >
        <Group>
          <div>
            <Title type="secondary" level={4}>
              {t("Client Details")}
            </Title>
            <DetailBar
              detailData={getClientDetail}
              detailHeader={
                <div>
                  <Text type="secondaryLight">{t("Project")}</Text>
                  <Icon icon="GoDotFill" color="#D6D6D6" size="12px" className="pi-4" />
                  <Text type="info">
                    {" "}
                    {localisedValue({ object: project, key: "name" })
                      ? localisedValue({ object: project, key: "name" })
                      : project
                      ? project
                      : localisedValue({ object: lead?.Project, key: "name" })}
                  </Text>
                </div>
              }
            />
          </div>
          <Form
            form={form}
            layout="vertical"
            name="task-form"
            initialValues={getAddTaskInitialValues(lead)}
            onFinish={onSubmit}
            scrollToFirstError
            preserve={false}
          >
            <Group>
              <div>
                {!!isTaskInQCState && <Alert type="error" message="Task is in QC state" />}
                <Legend title={t("Current Task")} />
                <Group template="repeat(2, 1fr)" gap="6px 32px">
                  <Form.Item name="task" label={t("Task")} rules={rules} required>
                    <SelectInput
                      placeholder={t("Select task")}
                      options={taskTypes?.leadStatuses?.filter(item => item?.key !== "pre_sale")}
                      loading={isFetchingTaskTypes}
                      onChange={(value, option) => {
                        setFieldsValue({
                          task: value,
                          subTask: option.subTask[0]?.id,
                          nextTask: (option.subTask[0].nextStatus || option.nextTask)?.id,
                        })
                      }}
                    />
                  </Form.Item>
                  {!!taskChildren?.length && (
                    <Form.Item
                      name="subTask"
                      label={t("Sub-Task")}
                      rules={rules.concat({
                        validator: async (_, value) => {
                          if (value === 15 && !!isTaskInQCState) {
                            return Promise.reject("Cannot add task, in QC state")
                          }
                          return Promise.resolve()
                        },
                      })}
                      required
                    >
                      <SelectInput
                        placeholder={t("Select sub task")}
                        options={taskChildren}
                        onChange={(value, option) => {
                          setFieldsValue({
                            subTask: value,
                            subofSubTask: option.children[0]?.id,
                            nextTask: option.nextStatus?.id,
                          })
                        }}
                      />
                    </Form.Item>
                  )}
                  {!!taskSubChildren?.length && (
                    <Form.Item name="subofSubTask" label={t("Location")} rules={rules}>
                      <SelectInput
                        placeholder={t("Select location")}
                        options={taskSubChildren}
                        onChange={(value, option) => {
                          setFieldsValue({ subofSubTask: value })
                        }}
                      />
                    </Form.Item>
                  )}
                  {userPlateformKey !== "bayut_ksa" && (
                    <Form.Item
                      name="verifyMeeting"
                      label={t("Verify Meeting")}
                      rules={rules}
                      hidden={!(subTask === 5 && verifyMeeting !== "fullFilled")}
                      className="span-all"
                    >
                      <VerifyMeeting
                        clientId={client?.id || lead?.clientId}
                        generateOtp={generateOtp}
                        onSubmitButton={onVerifyOTPCode}
                        isOtpGenerating={isOtpGenerating}
                        isOtpVerify={isOtpVerify}
                        showVerifyOTP={verifyMeeting}
                        generateOtpError={generateOtpError}
                        onClickChangeButton={() => setFieldValue("verifyMeeting", "")}
                      />
                    </Form.Item>
                  )}

                  {!!(subTask === 5 && verifyMeeting === "fullFilled") && "Meeting Verified"}

                  <Form.Item
                    name="completionDate"
                    label={t("Completion Date")}
                    required={true}
                    rules={rules.concat({
                      validator: async (_, value) => {
                        if (value > moment()) {
                          return Promise.reject(t("The completion date is invalid"))
                        }
                        return Promise.resolve()
                      },
                    })}
                  >
                    <DatePicker
                      range={{
                        endDate: moment().add(0, "days"),
                        startDate: moment().subtract(1, "days").startOf("day"),
                      }}
                      onSelect={item => {
                        if (moment(item).isSame(moment(), "day")) {
                          if (item > moment()) {
                            setFieldsValue({ completionDate: moment() })
                          }
                        }
                      }}
                      disableFutureTime={true}
                      format={DATE_TIME_FORMAT}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                  <Form.Item
                    name="attachment"
                    label={t("Attachments")}
                    required={subTask === 44 || subTask === 26 || subTask === 14 ? true : false}
                    rules={rules.concat({
                      validator: async (_, value) => {
                        if ((subTask === 44 || subTask === 26 || subTask === 14) && value.length === 0) {
                          return Promise.reject(t("Attachment is required"))
                        }
                        return Promise.resolve()
                      },
                    })}
                  >
                    <UploadDragger
                      name="attachment"
                      files={attachment}
                      setFiles={cb => {
                        form.setFieldsValue({ attachment: cb(attachment) })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="voiceComment"
                    label={t("Add Voice Comment")}
                    rules={[
                      {
                        validator: (_, _value) => {
                          if (recorderControls?.isRecording) {
                            return Promise.reject("Recording is still in progress, press the save button")
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <VoiceComment
                      getController={getController}
                      setVoice={voice => {
                        setFieldsValue({ voiceComment: voice })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className={cx(!!suggestedComments?.length && styles.commentInput, "span-all")}
                    name="comment"
                    label={t("Comment")}
                    rules={[
                      {
                        validator: (_, value) => {
                         if(!value)
                         {
                          setShowCommentErrors("Comment is Required")
                         }
                         else{
                          setShowCommentErrors("")
                         }
                         return Promise.resolve()
                        },
                      }
                    ]}
                    required
                    extra={
                      !!suggestedComments?.length && (
                        <Group gap="8px" className={cx("p-12")} template="auto 1fr">
                          <Text type="secondaryLight">{t("Suggested Comments")}:</Text>
                          <Row gutter={[8, 8]}>
                            {suggestedComments?.map(element => (
                              <Tag
                                type="light"
                                color="#A7A7A7"
                                onClick={() => handleSuggestedCommentClick(element)}
                                bordered
                                pill
                                maxLength={element.length > 50 ? 29 : undefined}
                                tooltipText={element.length > 50 && element}
                              >
                                <Icon icon="HiPlus" size={12} style={{ marginRight: 4 }} />
                                {element}
                              </Tag>
                            ))}
                          </Row>
                        </Group>
                      )
                    }                   
                  >
                    <TextInput placeholder={t("Enter comment")} maxLength={1000} lineCount={3} />
                  </Form.Item>
                  <div className="ant-form-item-explain-error">
                      {showCommentErrors}
                  </div>              
                </Group>
              </div>

              <div>
                <Legend title={t("Next Task")} hidden={subTask === 39 ? true : false} />
                <Group template="repeat(2, 1fr)" gap="6px 32px">
                  <Form.Item
                    required
                    hidden={subTask === 39 ? true : false}
                    name="nextTask"
                    label={t("Next Task")}
                    rules={rules.concat({
                      validator: async (_, value) => {
                        if (subTask === 39 || value === 0) {
                          return Promise.resolve()
                        } else if (!value) return Promise.reject(t("Next task is required"))
                      },
                    })}
                  >
                    <SelectInput
                      placeholder={t("Select next task")}
                      options={taskTypes?.nextStatuses}
                      getOptionLabel={option => option?.statusTitleTodo}
                      onChange={(value, _option) => {
                        setFieldsValue({ nextTask: value })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="deadLine"
                    label={t("Deadline")}
                    required={true}
                    rules={rules}
                    hidden={
                      getPrivilege(["can_view_bd_calendar", "can_view_tsr_calendar"], privileges) &&
                      (subTask === 15 || nextTask === 5)
                    }
                  >
                    <DatePicker
                      range={{ startDate: moment() }}
                      showTime
                      disablePastTime={true}
                      format={DATE_TIME_FORMAT}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                  <Form.Item name="project" label={t("Project")}>
                    <SelectInput
                      disabled={!!leadDetailInfo?.contracts.length}
                      placeholder={t("Select project")}
                      options={projects}
                      getOptionValue={option => option.projectId}
                      onChange={(value, _option) => {
                        setFieldsValue({ project: _option })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="leadsClassification"
                    label={t("Leads Classification")}
                    required={!(userInfo?.UserDesignation?.name === "TSR")}
                    rules={rules}
                    disabled={!(userInfo?.UserDesignation?.name === "TSR")}
                  >
                    <RadioButtons options={classifications} disabled={userInfo?.UserDesignation?.name === "TSR"} />
                  </Form.Item>

                  <Form.Item
                    name="tsrClassification"
                    label={t("TSR Classification")}
                    required
                    hidden={!(userInfo?.UserDesignation?.name === "TSR")}
                  >
                    <RadioButtons options={classifications} />
                  </Form.Item>

                  {!!(
                    getPrivilege(["can_view_bd_calendar", "can_view_tsr_calendar"], privileges) &&
                    (subTask === 15 || nextTask === 5)
                  ) && (
                    <div>
                      <Form.Item name="dateSlot" label={t("Date Slot")} required={true} rules={rules}>
                        <DatePicker
                          range={{ startDate: moment() }}
                          format={DATE_FORMAT}
                          onChange={date => {
                            form.setFieldValue("timeSlot", [date, date])
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="timeSlot"
                        label={t("Time Slot")}
                        required={true}
                        rules={rules}
                        extra={
                          <Text type="warning" hidden={!busySlotVisible}>
                            {t("User has already scheduled a meeting during this time")}
                          </Text>
                        }
                      >
                        <TimePicker
                          preSelectedDate={dateSlot}
                          onChange={x => {
                            setBusySlotVisible(false)
                            checkEventList(x)
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                </Group>
              </div>
            </Group>
          </Form>
        </Group>
      </Modal>
    </div>
  )
}
