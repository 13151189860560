import { Row } from "antd"
import { ActionButtons, IconTextDate, Separator, TitleDescription } from "components/custom"
import { CLIENT_DETAIL_PATH } from "constants/app-paths"
import ApplyDiscountForm from "features/clients/components/applyForDiscountForm/applyForDiscountForm"
import ClientObservationForm from "features/clients/components/review/clientObservationForm/clientObservationForm"
import {
  useAddClientContactViewTrackingMutation,
  useGetLeadsQuery,
  useMarkFavoriteUnFavoriteMutation,
} from "features/clients/detail/api"
import { CallLogsDrawer, ClientInfo, ProjectModal, TaskOverviewModal } from "features/shared/components"
import ProposalGenerationForm from "features/shared/components/proposalGenerationForm/proposalGenerationForm"
import UserProfileSummary from "features/shared/components/userProfileSummary"
import { classifications } from "features/shared/data"
import { AddTaskForm } from "features/tasks/components"
import { t } from "i18next"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { capitalizeFirstLetter, joinBytItems, localisedValue } from "utils"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES, PAGES, PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"
import getPrivilege from "utils/privileges/privileges"
import { AddReviewLead, AddSalesForm, LeadsFilters, ShareLeadSaleForm, ShareLeadSupportForm } from ".."
import { Button, DataTable, DateFormat, FLagWithText, Icon, Number, Tag, Text } from "../../../../components/common"
import BuyRentModal from "../buyRentModal/buyRentModal"
import CloseLostForm from "../closeLostForm/closeLostForm"
import SellRentModal from "../sellRentModal/sellRentModal"
import ViewSharingDetail from "../viewSharingDetail/viewSharingDetail"
import getActionButtons from "./getActionButtons"
import AiSalesPitchGenerator from "features/aiPitchGenerator"
import ClientProfileForm from "features/tasks/components/clientProfileForm/clientProfileForm"
import AddToSalesPipeline from "features/shared/components/addToSalesPipeline/addToSalesPipeline"

const leadsColumn = (width = 186, isListing, isRecommended, isBProperty, isAIRecommended) => [
  ...(isListing ? [{ title: "", dataIndex: "favorite", width: 40 }] : []),
  { title: t("Lead Id"), dataIndex: "id", key: "id_col", component: "IconText" },
  ...(isListing ? [{ title: t("Client"), dataIndex: "client", key: "client" }] : []),
  { title: t("Last Task"), dataIndex: "lastTask", key: "lastTask" },
  ...(isListing && isBProperty ? [{ title: t("Stage"), dataIndex: "stage" }] : []),
  { title: t("Interest"), dataIndex: "interest" },
  ...(isRecommended
    ? [{ title: t("Recommended"), dataIndex: "recommended" }]
    : isAIRecommended
    ? [{ title: t("Conversion %"), dataIndex: "conversion" }]
    : [{ title: t("Source"), dataIndex: "source" }]),
  ...(isListing ? [{ title: t("Allocated To"), dataIndex: "allocated_to" }] : []),
  { title: t("Actions"), dataIndex: "actions", width: isListing ? 240 : width, className: "text-right" },
]

const LeadsTable = ({
  client: cli,
  params,
  filters = false,
  mainClass,
  children,
  showPagination = true,
  setLeadCount = () => {},
  showAnimation = true,
  actionWidth,
  from = {},
  loadingComponentStyle,
  getSelectedRows,
  selectedRows,
  highlightedId,
  leadsFrom,
  stickyOffset = 0,
  limitResults = 10,
}) => {
  const { t, i18n } = useTranslation()

  const clientId = cli?.id
  const isListing = leadsFrom === "listing" ? true : false
  const shareLeadSaleFormRef = useRef()
  const shareLeadSupportFormRef = useRef()
  const addSalesFormRef = useRef()
  const addReviewLeadFormRef = useRef()
  const addClientObservationFormRef = useRef()
  const addApplyForDiscountFormRef = useRef()
  const taskLogRef = useRef()
  const addTaskRef = useRef()
  const proposalGenerationRef = useRef()
  const projectRef = useRef()
  const closeLostRef = useRef()
  const callDrawerRef = useRef()
  const secondaryBuyerRef = useRef()
  const secondarySellerRef = useRef()
  const aiSaleGenRef = useRef()
  const clientProfileRef = useRef()
  const addToSalesPipeline = useRef()
  const userPrivileges = useSelector(state => state.app.privileges)

  const formRefs = {
    shareLeadSaleFormRef: shareLeadSaleFormRef,
    shareLeadSupportFormRef: shareLeadSupportFormRef,
    addSaleFormRef: addSalesFormRef,
    addReviewLeadFormRef: addReviewLeadFormRef,
    addClientObservationFormRef: addClientObservationFormRef,
    addApplyForDiscountFormRef: addApplyForDiscountFormRef,
    taskLogRef: taskLogRef,
    addTaskRef: addTaskRef,
    proposalGenerationRef: proposalGenerationRef,
    closeLostRef: closeLostRef,
    callDrawerRef: callDrawerRef,
    aiSaleGenRef: aiSaleGenRef,
    clientProfileRef: clientProfileRef,
    addToSalesPipeline: addToSalesPipeline,
  }

  const [lead, setLead] = useState()
  const [selectedInventoryId, setSelectedInventoryId] = useState({
    listingId: null,
    leadId: null,
    clientName: null,
  })
  const [filter, setFilter] = useState({ sortBy: "id", sortOrder: "desc", pageLimit: limitResults })
  const {
    data: leads,
    isFetching,
    error,
    refetch,
  } = useGetLeadsQuery({ ...filter, ...params, ...(clientId && { clientId }) }, { refetchOnMountOrArgChange: true })
  const [markFavoriteUnfavorite, { isError: markFavIsError, error: markFavError }] = useMarkFavoriteUnFavoriteMutation()
  const [addClientContactView] = useAddClientContactViewTrackingMutation()
  const user = useSelector(state => state.app.user?.info)
  const privileges = useSelector(state => state.app.privileges)
  const client = leadsFrom === "listing" ? lead?.Client : cli

  useEffect(() => {
    !isFetching && leads && setLeadCount(leads?.count)
  }, [leads, isFetching])

  const onSort = obj => {
    setFilter(prev => ({ ...prev, sortBy: obj.columnName, sortDirection: obj.sortOrder }))
  }

  const onActionClick = (componentRef, lead) => {
    setLead(lead)
    setSelectedInventoryId({
      listingId: lead?.id,
      leadId: lead?.id,
      clientName: lead?.name,
    })
    componentRef.current.setVisible(true)
  }

  const fireFavoriteEvent = (status, clientIds) => {
    fireEvent({
      ...ACTION_TYPES.mark_lead_as_favorite,
      page: PAGES.lead_listing_page,
      interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
      client_id: clientIds.join(","),
      status,
    })
  }

  const handleFavorite = async (favorite, clientIds) => {
    const resp = await markFavoriteUnfavorite({ favorite, clientIds })
      .unwrap()
      .catch(e => fireFavoriteEvent(e.status, clientIds))
    if (!markFavIsError) {
      fireFavoriteEvent("200", clientIds)
    }
  }

  const getInterestTitle = e =>
    // TODO: Need to  get privilege for secondary lead actions
    e?.interestedIn == "secondary" ? (
      <div className="text-link pointer">{localisedValue({ key: "name", object: e?.Type })}</div>
    ) : getPrivilege(["can_view_client_project_summary"], e?.privileges) ? (
      <div className="text-link pointer">{localisedValue({ key: "name", object: e.Project })}</div>
    ) : (
      localisedValue({ key: "name", object: e.Project })
    )

  const getInterestDesc = e => {
    const items =
      e?.interestedIn === "secondary"
        ? [localisedValue({ key: "name", object: e.Purpose }), "Secondary"]
        : [
            localisedValue({ key: "name", object: e?.Type }),
            localisedValue({ key: "name", object: e.Purpose }),
            t(capitalizeFirstLetter(localisedValue({ key: "interestedIn", object: e }))),
          ]
    return joinBytItems(items, <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />)
  }

  const onClickInterest = e => () => {
    // TODO: Need to get privilege for secondary lead actions
    if (e?.interestedIn == "secondary") {
      if (e?.leadSide === "demand") {
        secondaryBuyerRef.current.showFor(e)
      } else if (e?.leadSide === "supply") {
        secondarySellerRef.current.showFor(e)
      }
    } else if (getPrivilege(["can_view_client_project_summary"], e?.privileges)) {
      projectRef.current.showFor(e?.Project?.projectId)
      fireEvent({
        ...ACTION_TYPES.project_details_clicked,
        project_id: e?.Project?.projectId,
        client_id: e?.Client?.id,
      })
    }
  }

  const tableData = useMemo(
    () =>
      leads?.data?.length
        ? leads?.data.map((e, index) => {
            return {
              key: e.id,
              ...(isListing && {
                favorite: (
                  <Icon
                    icon={
                      e.Client.isFavoriteOf?.length && e.Client?.isFavoriteOf.includes(user?.id)
                        ? "AiFillStar"
                        : "AiOutlineStar"
                    }
                    color="#F5B301"
                    size="22px"
                    style={{ cursor: "pointer" }}
                    tooltipText={
                      e?.Client?.isFavoriteOf?.length && e?.Client?.isFavoriteOf.includes(user?.id)
                        ? t("Click to remove from favorites")
                        : t("Click to add to favorites")
                    }
                    onClick={() => {
                      handleFavorite(!(e.Client.isFavoriteOf?.length && e.Client?.isFavoriteOf.includes(user?.id)), [
                        e.Client.id,
                      ])
                    }}
                  />
                ),
              }),
              id: (
                <IconTextDate
                  message={classifications()[e.classificationId]?.name}
                  iconProps={{ color: classifications()[e.classificationId]?.style.color, hasBackground: true }}
                  icon="MdLocalFireDepartment"
                  title={e.id}
                  date={e.createdAt}
                  customTooltip={t(`Lead Added on`) + ": "}
                  suffixIcon={e.isShared ? <ViewSharingDetail leadId={e.id} /> : null}
                  additionalIcon={
                    e.comments ? (
                      <Icon icon="BiMessageDetail" color="#707070" size="20px" style={{ margin: -4, padding: 3 }} />
                    ) : null
                  }
                  linkInfo={
                    isListing && getPrivilege("can_view_client_details", e?.privileges)
                      ? CLIENT_DETAIL_PATH.replace(":clientId", e?.Client?.id)
                      : false
                  }
                  from={PAGE_SUBSECTIONS.lead_listing_page}
                />
              ),
              ...(isListing && {
                client: (
                  <>
                    <Row>
                      {getPrivilege("can_view_client_details", e?.privileges) ? (
                        <Link
                          to={CLIENT_DETAIL_PATH.replace(":clientId", e.Client?.id)}
                          state={{ hasPath: true, from: PAGE_SUBSECTIONS.lead_listing_page }}
                          className="text-link"
                        >
                          {localisedValue({ key: "name", object: e.Client })}
                        </Link>
                      ) : (
                        <div>{localisedValue({ key: "name", object: e.Client })}</div>
                      )}
                    </Row>
                    <FLagWithText
                      title={localisedValue({ key: "name", object: e.Client?.Country })}
                      countryCode={e.Client.Country?.locCode}
                      text={
                        localisedValue({ key: "name", object: client?.City }) ||
                        localisedValue({ key: "name", object: client?.Country })
                      }
                      infoText={
                        <div className="gray-800 fs14">{`${e.Client.inquiryCount} ${
                          e.Client.inquiryCount > 1 ? t("Leads") : t("Lead")
                        }`}</div>
                      }
                    />
                  </>
                ),
              }),
              lastTask:
                e.lastTask === null ? (
                  <Button
                    className="pi-0"
                    type="link"
                    icon="MdAddTask"
                    text={t("Add Task")}
                    onClick={() => {
                      fireEvent({ ...ACTION_TYPES.lead_add_task_button, ...from, client_id: clientId, lead_id: e.id })
                      onActionClick(addTaskRef, e)
                    }}
                    disabled={!getPrivilege("can_add_task_on_lead", e?.privileges)}
                  />
                ) : (
                  <TitleDescription
                    title={localisedValue({ key: "name", object: e.InternalInquiryStatus })}
                    description={<DateFormat date={e.lastTaskDate} />}
                    key={`lastTask - ${e.id}`}
                  />
                ),
              ...(isListing &&
                user?.AgencyConfiguration?.Platform?.platformKey === "bproperty" && {
                  stage: (
                    <TitleDescription
                      title={
                        <Text level={4}> {localisedValue({ key: "stageName", object: e?.InternalInquiryStage })}</Text>
                      }
                      description={
                        <Text type="secondaryLight">
                          {localisedValue({ key: "substageName", object: e?.InternalInquiryStage })}
                        </Text>
                      }
                    />
                  ),
                }),
              interest: (
                <TitleDescription
                  //  title={getInterestTitle(e)}
                  gapSpace={4}
                  onClick={onClickInterest(e)}
                  description={getInterestDesc(e)}
                  titleClassName="text-link"
                  key={`interest - ${e.id}`}
                />
              ),
              source: getPrivilege("can_view_lead_source_data", userPrivileges) ? (
                <TitleDescription
                  title={
                    <Row>
                      <Tag
                        type="light"
                        children={localisedValue({ key: "referralType", object: e?.Client })?.[0]?.toUpperCase()}
                        style={{ cursor: "pointer" }}
                        tooltipText={
                          localisedValue({ key: "referralType", object: e?.Client })?.[0]?.toUpperCase() +
                          localisedValue({ key: "referralType", object: e?.Client })?.substring(1)
                        }
                      />
                      <div>
                        {localisedValue({ key: "name", object: e?.InternalInquirySource?.[0] })}
                        &nbsp;
                        <ClientInfo client={e} />
                      </div>
                    </Row>
                  }
                  description={localisedValue({ key: "name", object: e.source })}
                />
              ) : (
                "-"
              ),
              recommended: e.recommended,
              ...(isListing && {
                allocated_to: e.User && (
                  <div>
                    <div>
                      {localisedValue({ key: "name", object: e.User })}&nbsp;
                      <UserProfileSummary staffId={e?.User?.id} />
                    </div>
                    <div className="gray-800 fs14">
                      {joinBytItems(
                        [
                          localisedValue({ key: "short", object: e.User?.UserDesignation }),
                          localisedValue({ key: "name", object: e?.User?.City }),
                        ],
                        <Separator />
                      )}
                    </div>
                  </div>
                ),
              }),
              conversion: <Number value={e?.Client?.aiClosureProbability} wrapValue={val => `${val}%`} />,
              // nextTask: (
              //   <TitleDescription
              //     title={localisedValue({ key: "statusTitleTodo", object: e.NextStatusWanted })}
              //     description={e?.nextStatusDeadline && <DateFormat date={e.nextStatusDeadline} />}
              //     key={`source - ${e.id}`}
              //   />
              // ),
              actions: (
                <ActionButtons
                  list={getActionButtons({
                    lead: e,
                    isListing,
                    client,
                    formRefs,
                    onActionClick,
                    user,
                    from,
                    showAnimation,
                    userPrivileges,
                    addClientContactView,
                  })}
                  // animate={showAnimation}
                  // beforeAnimate={isListing ? 1 : 4}
                />
              ),
            }
          })
        : [],
    [leads?.data, user, client]
  )

  return (
    <>
      {filters && <LeadsFilters filter={filter} onSort={onSort} />}
      <DataTable
        tableLayout="auto"
        pagination={showPagination ? { totalCount: leads?.count, pageOptions: [10, 25, 50, 300] } : {}}
        paginationOnBottom={true}
        paginationOnTop={false}
        loadingText={<></>}
        columns={leadsColumn(
          actionWidth,
          isListing,
          false,
          user?.AgencyConfiguration?.Platform?.platformKey === "bproperty",
          params?.activeTab === "ai_recommended"
        )}
        data={tableData || []}
        loading={isFetching}
        error={error}
        onErrorRetry={refetch}
        mainClass={mainClass}
        stickyOffset={stickyOffset}
        rowClassName={(_record, index) =>
          leads?.data?.[index]?.hasActiveSale
            ? "table-active_sale-row-bg"
            : leads?.data?.[index]?.id == highlightedId
            ? "table-highlight-row-bg"
            : " "
        }
        loadingComponentStyle={loadingComponentStyle}
        rowSelection={
          isListing && {
            onChange: rows => {
              const transformData = leads.data.filter(item => {
                return rows.includes(item.id)
              })
              getSelectedRows(transformData)
            },
            columnWidth: 70,
            selectedRowKeys: selectedRows.map(item => item.id),
          }
        }
      />
      {children && children({ leadCount: leads?.count })}
      <ProjectModal ref={projectRef} />
      <BuyRentModal ref={secondaryBuyerRef} />
      <SellRentModal ref={secondarySellerRef} />
      <ShareLeadSaleForm leads={[lead]} client={client} formRef={shareLeadSaleFormRef} from={from} />
      <ShareLeadSupportForm leads={[lead]} client={client} formRef={shareLeadSupportFormRef} from={from} />
      <AddSalesForm lead={lead} client={client} formRef={addSalesFormRef} from={from} />
      <AddReviewLead lead={lead} client={client} formRef={addReviewLeadFormRef} from={from} />
      <ApplyDiscountForm lead={lead} client={client} formRef={addApplyForDiscountFormRef} from={from} />
      <AddTaskForm lead={lead} client={client} formRef={addTaskRef} from={from} />
      <ProposalGenerationForm
        formRef={proposalGenerationRef}
        from={{ name: "lead", listingId: lead?.id, client: client }}
      />
      <TaskOverviewModal
        ref={taskLogRef}
        client={client}
        lead={lead}
        taskTableProps={{ from: from, lead: { inquiryIds: lead?.id?.toString() } }}
        getClientLogs
      />
      <ClientProfileForm
        formRef={clientProfileRef}
        client={client}
        allocatedTo={lead?.User?.name}
        onSuccess={() => clientProfileRef.current?.setVisible(false)}
      />
      <ClientObservationForm
        lead={lead}
        client={client}
        allocatedName={client?.allocationActivity?.name}
        formRef={addClientObservationFormRef}
        from={from}
      />
      <CloseLostForm formRef={closeLostRef} lead={lead} />
      <CallLogsDrawer ref={callDrawerRef} />
      <AiSalesPitchGenerator ref={aiSaleGenRef} />
      <AddToSalesPipeline ref={addToSalesPipeline} lead={lead} />
    </>
  )
}

export default LeadsTable
