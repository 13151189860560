import { useCallback, useEffect, useState } from "react"
import locale from "antd/es/date-picker/locale/ar_EG"
import { TimePicker } from "antd"
import { TIME_FORMAT } from "constants"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { RangePicker } = TimePicker

const TimeRangePicker = props => {
  const { value, onChange, noDefault, ...rest } = props
  const { t } = useTranslation()
  const { locale: appLanguage } = useSelector(state => state.app.configs)

  const [time, setTime] = useState(
    !noDefault ?  [moment().set({ hour: 0, minute: 0, second: 0 }), 
    moment().set({ hour: 0, minute: 0, second: 0 }) ]: undefined
  )

  useEffect(() => {
    setTime(value?.[0] && value?.[1] ? [moment(value[0], TIME_FORMAT), moment(value[1], TIME_FORMAT)] : undefined)
  }, [value])

  const onChangeTime = (time, TimeStrings) => {
    setTime(time)
  }

  const handleChangeTime = useCallback(() => {
    if (!!time) {
      let times = [...time]
      onChange(times)
    } else {
      onChange(time)
    }
  }, [time])

  return (
    <div>
      <RangePicker
        allowClear={false}
        value={time}
        use12Hours
        onChange={onChangeTime}
       onOpenChange={isOpened => {
          !isOpened && handleChangeTime()
        }}
        {...rest}
        locale={appLanguage === "en" ? [] : locale}
      />
    </div>
  )
}
export default TimeRangePicker
