import React from "react"

export default function Legend(props) {
  const { title, extra, ...rest } = props

  return (
    <legend {...rest}>
      {title}
      {extra && <div>{extra}</div>}
    </legend>
  )
}
