import { ACTION_TYPES, PAGES, PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"
import { AddLeadFormModal, LeadsTable } from "features/leads/components"
import { AllocationsTab, CallsTable, EditHistory, SharingTab } from ".."
import { Button, Card, DataTable, Text, notification } from "components/common"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { TasksTable } from "features/tasks/components"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "utils/privileges/privileges"
import theme from "utils/themeVars"
import { useAddLeadMutation } from "features/clients/detail/api"
import { useQueryUpdate } from "hooks"
import { useSelector } from "react-redux"
import LeadReviewTable from "features/leads/components/LeadReviewTable/leadReviewTable"
import { useTranslation } from "react-i18next"

const ClientTabs = ({ client, skeletonLoading }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const leadId = searchParams.get("leadId")
  const userPrivileges = useSelector(state => state.app.privileges)
  const [addLeadModal, setAddLeadModal] = useState(false)
  const [tabKey, setTabKey] = useState(searchParams.get("activeTab") || "leads")
  const [leadCount, setLeadCount] = useState(null)
  const [addLead, { error, isError, isLoading, reset }] = useAddLeadMutation()
  const [tabCount, setTabCount] = useState()
  const queryObj = Object.fromEntries([...searchParams])
  const { activeTab, ...newParams } = queryObj
  const { state } = useLocation()

  const onAddLead = async values => {
    const response = await addLead({ client, ...values })
      .unwrap()
      .catch(e => {
        fireEvent({ ...ACTION_TYPES.add_new_lead_submit, response: e.status, client_id: client.id })
      })
    if (response) {
      setAddLeadModal(false)
      fireEvent({ ...ACTION_TYPES.add_new_lead_submit, response: "200", client_id: client.id })
      notification.success({
        message: t("Lead added successfully"),
      })
    }
  }

  const renderTabTitle = (title, count) =>
    count ? (
      <>
        {title} {!!leadCount && <Text color={theme["gray700"]}>({count})</Text>}
      </>
    ) : (
      title
    )

  const tabItems = useMemo(
    () => [
      {
        tab: renderTabTitle(t("Leads"), leadCount),
        key: "leads",
        actionButton: (
          <Button
            size="small"
            type="primary"
            icon="MdAddCircleOutline"
            text={t("Add New Lead")}
            onClick={() => {
              isError && reset()
              fireEvent({ ...ACTION_TYPES.add_new_lead_click, client_id: client.id })
              setAddLeadModal(true)
            }}
          />
        ),
        show: getPrivilege("can_view_leads_summary", client?.privileges),
      },

      {
        tab: renderTabTitle(t("Tasks"), tabCount?.["tasks"]),
        key: "tasks",
        show: getPrivilege("can_see_task_logs", client?.privileges),
      },
      {
        tab: renderTabTitle(t("Calls"), tabCount?.["calls"]),
        key: "calls",
        show: getPrivilege("can_view_call_logs", client?.privileges),
      },
      {
        tab: renderTabTitle(t("Lead Reviews"), tabCount?.["leadReviews"]),
        key: "leadReviews",
        show: getPrivilege("can_see_task_logs", client?.privileges),
      },
      {
        tab: renderTabTitle(t("Allocations"), tabCount?.["allocations"]),
        key: "allocations",
        show: getPrivilege("can_view_client_allocaiton_log", client?.privileges),
      },
      {
        tab: renderTabTitle(t("Sharing"), tabCount?.["sharing"]),
        key: "sharing",
        show: getPrivilege("can_view_client_allocaiton_log", client?.privileges),
      },
      {
        tab: renderTabTitle(t("Edit History"), tabCount?.["editHistory"]),
        key: "editHistory",
        show: getPrivilege("can_view_client_change_log", client?.privileges),
      },
    ],
    [client, leadCount, tabCount]
  )

  const setCount = key => count => setTabCount(prev => ({ ...prev, [key]: count }))

  const getTabContent = () => ({
    leads: (
      <LeadsTable
        client={client}
        params={newParams}
        filters
        setLeadCount={setLeadCount}
        loadingComponentStyle={!!client ? { height: `${60 * (client?.totalLeads || 3)}px` } : {}}
        highlightedId={leadId}
        getCount={setCount("leads")}
        from={{ page: PAGES.client_detail_page, interacted_from: PAGE_SUBSECTIONS.client_detail_page }}
      />
    ),
    tasks: (
      <TasksTable
        client={client}
        filterClass="pi-24"
        getCount={setCount("tasks")}
        stickyOffset={0}
        from={{ page: PAGES.client_detail_page, interacted_from: PAGE_SUBSECTIONS.client_detail_page }}
      />
    ),
    leadReviews: <LeadReviewTable client={client} getCount={setCount("leadReviews")} />,
    calls: (
      <CallsTable
        params={{ clientId: client?.id, excludeDateRange: true }}
        // getCount={setCount("calls")}
        stickyOffset={0}
      />
    ),
    allocations: <AllocationsTab client={client} getCount={setCount("allocations")} />,
    sharing: <SharingTab client={client} getCount={setCount("sharing")} />,
    editHistory: <EditHistory client={client} getCount={setCount("editHistory")} />,
  })

  const handleTabChange = key => {
    let actionType
    setSearchParams({ activeTab: key }, { replace: true, state: state })
    setTabKey(key)
    switch (key) {
      case "leads":
        actionType = ACTION_TYPES.leads_tab
        break
      case "tasks":
        actionType = ACTION_TYPES.tasks_tab
        break
      case "calls":
        actionType = ACTION_TYPES.calls_tab
        break
      case "allocations":
        actionType = ACTION_TYPES.allocation_tab
        break
      case "sharing":
        actionType = ACTION_TYPES.sharing_tab
        break
      case "editHistory":
        actionType = ACTION_TYPES.edit_history_tab
        break
      default:
    }
    fireEvent({ ...actionType, client_id: client.id })
  }

  useEffect(() => {
    if (client) {
      const prevObj = {
        leads: getPrivilege("can_view_leads_summary", client?.privileges),
        tasks: getPrivilege("can_see_task_logs", client?.privileges),
        calls: getPrivilege("can_view_call_logs", client?.privileges),
        leadReviews: getPrivilege("can_see_task_logs", client?.privileges),
        allocations: getPrivilege("can_view_client_allocaiton_log", client?.privileges),
        sharing: getPrivilege("can_view_client_allocaiton_log", client?.privileges),
        editHistory: getPrivilege("can_view_client_change_log", client?.privileges),
      }
      if (!prevObj[tabKey]) {
        let key
        Object.keys(prevObj).some(e => {
          key = e
          return prevObj[e]
        })
        setTabKey(key)
      }
    }
  }, [client])

  return (
    <Card
      tabList={tabItems.filter(item => !!item.show)}
      activeTabKey={tabKey}
      onTabChange={handleTabChange}
      tabBarExtraContent={tabItems.find(item => item.key === tabKey)?.actionButton}
      bodyStyle={{ padding: "12px 0 0", height: "100%" }}
    >
      {skeletonLoading ? (
        <ClientTabsSkeleton />
      ) : (
        <div
          style={{
            // maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
            height: "100%",
          }}
        >
          {getTabContent()[tabKey]}
          <AddLeadFormModal
            client={client}
            modalVisible={addLeadModal}
            setModalVisible={setAddLeadModal}
            onSubmit={onAddLead}
            loading={isLoading}
            footerAlert={error}
          />
        </div>
      )}
    </Card>
  )
}

const ClientTabsSkeleton = () => {
  return (
    <>
      <DataTable loading data={[]} columns={[]} />
    </>
  )
}

export default ClientTabs
