import { formatCompactedNumber, numberFormat } from "utils"

import { DATE_FORMAT } from "constants"
import moment from "moment"
import store from "store"

const createValueFromResponse = (response, availableFiltersObj, e, queryObject) => {
  const responseItems = response[availableFiltersObj[e].appliedFilter.responseKey]
  let currentFilterItems = []

  responseItems.forEach(item => {
    if (availableFiltersObj[e].appliedFilter?.duplicate) {
      const filterValues = queryObject[availableFiltersObj[e].key].split(",")
      // const itemsList = []
      filterValues.forEach(filterValue => {
        filterValue == item.id && currentFilterItems.push(item)
      })
      // itemsList.length && currentFilterItems.push(itemsList)
    } else {
      currentFilterItems.push(item)
    }
  })
  return currentFilterItems
}

const placeNumberUnit = numberString => {
  const currency = store
    .getState()
    .app?.user?.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode
  return currency + " " + numberString
}

const getUnitKey = (availableFiltersObj, queryObject) => {
  if (queryObject?.areaUnitKey) {
    const unitKey = availableFiltersObj?.areaRange?.unitList?.find(item => item?.value == queryObject.areaUnitKey)
    return unitKey?.label
  }
}
const createFormattedValue = (availableFiltersObj, e, queryObject) => {
  let label = availableFiltersObj[e].label,
    value
  if (availableFiltersObj[e]?.appliedFilter?.type === "date") {
    const itemsList = queryObject?.[e]?.split(",")
    value =
      moment(itemsList[0]).format(DATE_FORMAT) + (itemsList[1] ? ", " + moment(itemsList[1]).format(DATE_FORMAT) : "")
  }
  // TODO: Need to fix this
  if (availableFiltersObj[e]?.key === "priceRange" || availableFiltersObj[e]?.key === "instalmentRange") {
    value = queryObject[e]
      .split(",")
      .map(item => item && placeNumberUnit(formatCompactedNumber(item, 2)))
      .join(" to ")
  }
  if (availableFiltersObj[e]?.key === "areaRange") {
    value = `${queryObject[e]
      .split(",")
      .map(item => item && numberFormat(item))
      .join(" to ")} ${getUnitKey(availableFiltersObj, queryObject)}`
  }
  if (availableFiltersObj[e]?.appliedFilter?.type === "toggle" && queryObject?.[e] === "true") {
    value = availableFiltersObj[e]?.appliedFilter?.text
    label = null
  }
  if (availableFiltersObj[e]?.appliedFilter?.type === "static-list") {
    const itemsList = queryObject?.[e]?.split(",")
    value = itemsList
      .map(it => availableFiltersObj[e]?.list && availableFiltersObj[e]?.list.find(item => item.value == it)?.label)
      .filter(e => !!e)
      .join(", ")
  }
  // TODO:
  if (availableFiltersObj[e]?.appliedFilter?.type === "month" && queryObject.month) {
    value = moment(queryObject.month).format("MMMM YYYY")
  }

  return { label, value }
}

// Function that transforms response to tags

export const filterTagsTransform = (response, { queryObject, filterList }) => {
  const availableFiltersObj = {}
  const tagList = []
  // Converting filter list to key value pair object
  filterList.forEach(e => {
    if (e.keyList) {
      e.keyList.forEach(it => {
        availableFiltersObj[it.value] = it
      })
    } else if (e.key) availableFiltersObj[e.key] = e
  })

  Object.keys(queryObject).forEach(e => {
    if (availableFiltersObj[e]) {
      const { label, value: formattedValue } = createFormattedValue(availableFiltersObj, e, queryObject)
      const key = availableFiltersObj[e].key || availableFiltersObj[e].value
      const responseKey = availableFiltersObj[e].appliedFilter?.responseKey

      const tagValue = formattedValue
        ? formattedValue
        : responseKey && response?.[responseKey]
        ? createValueFromResponse(response, availableFiltersObj, e, queryObject)
        : queryObject[e]

      tagList.push({ searchValue: key, label, tagValue, searchKey: key, filterItem: availableFiltersObj[e] })
    }
  })
  return { data: response, list: tagList }
}

// Function to create url params for applied filter request

export const createQueryParamsForFilterRequest = (queryObject = {}, filterList = []) => {
  const availableFiltersObj = {}
  const queryParams = {}
  filterList.forEach(e => {
    if (e.keyList) {
      e.keyList.forEach(it => {
        availableFiltersObj[it.value] = it
      })
    } else availableFiltersObj[e.key] = e
  })
  Object.keys(queryObject).forEach(e => {
    if (availableFiltersObj[e]?.appliedFilter?.searchKey) {
      if (queryParams[availableFiltersObj[e].appliedFilter.searchKey]) {
        queryParams[availableFiltersObj[e].appliedFilter.searchKey] =
          queryParams[availableFiltersObj[e].appliedFilter.searchKey] + "," + queryObject[e]
      } else {
        queryParams[availableFiltersObj[e].appliedFilter.searchKey] = queryObject[e] // value can be changed from value mapper => {teamsIds:[],userIds:[1,2]}
      }
    }
  })
  return queryParams
}

export const getAppliedFilterListObject = (queryObject = {}, filterList = []) => {
  const availableFiltersObj = {}
  const queryParams = {}
  filterList.forEach(e => {
    if (e.keyList) {
      e.keyList.forEach(it => {
        availableFiltersObj[it.value] = it
      })
    } else availableFiltersObj[e.key] = e
  })

  Object.keys(queryObject).forEach(e => {
    if (availableFiltersObj[e]) {
      if (queryParams[e]) {
        queryParams[e] = queryParams[e] + "," + queryObject[e]
      } else {
        queryParams[e] = queryObject[e]
      }
    }
  })
  return queryParams
}

export const modifyUrlParamsForExistingSearches = params => {
  const obj = { ...params }
  Object.keys(obj).forEach(e => {
    if (obj[e].includes("teamIds")) {
      const o = JSON.parse(decodeURIComponent(obj[e]))
      obj[e] = o.userIds.join(",")
    }
    if (obj[e].includes("from")) {
      const date = JSON.parse(obj[e])
      obj[e] = [date.from, date.to].join(",")
    }
  })
  return obj
}
