import { leadSharePurposeList } from "features/shared/data"
import { t } from "i18next"
const currentSharingsTransformHelper = response => {
  const salePurposeTitles = {}
  const leadId = Object.keys(response)[0]
  const salesData = response?.[leadId]?.sales
  salesData?.forEach(e => {
    salePurposeTitles[e.id] = leadSharePurposeList().find(it => it.value === e.purpose)?.label || e.purpose
  })

  const userList = getSharedUserList(response)
  return { userList, salePurposeTitles, ...response }
}

const getSharedUserList = response => {
  let userList = []
  let duplicateMap = {}

  Object.keys(response)?.forEach(key => {
    userList = userList.concat(response[key]?.support)
  })

  return userList
    ?.map(item => item?.id)
    ?.filter(item => {
      if (duplicateMap[item]) {
        return false
      }
      duplicateMap[item] = true
      return true
    })
}

export default currentSharingsTransformHelper
