import * as Sentry from "@sentry/react"

import { Button } from "components/common"
import { Component } from "react"
import Icon from "../../common/icon/icon"
import { Result } from "antd"
import { t } from "i18next"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  renderError = ({ error: sentryError, componentStack, resetError }) => {
    const { hasError, error } = this.state

    return (
      <Result
        icon={<Icon icon="BiSad" size={120} />}
        title={t("Something went wrong")}
        subTitle={error?.message}
        extra={
          <Button
            type="primary"
            onClick={() => {
              resetError()
              window.location.reload()
            }}
          >
            Reload
          </Button>
        }
      />
    )
  }

  render() {
    return <Sentry.ErrorBoundary fallback={this.renderError}>{this.props.children}</Sentry.ErrorBoundary>
  }
}

export default ErrorBoundary
