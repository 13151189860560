import { Col, InputNumber, Slider, Space } from "antd"
import { t } from "i18next"
import { Number } from ".."
import styles from "./index.module.less"

const RangeSlider = props => {
  const { range, isCurrency, steps, setField, value = [], label } = props
  const marks = {
    [range[0]]: {
      label: value[0] ? (
        <Number digitAfterFraction={1} isStringOnly isCurrency={isCurrency} value={value[0]} />
      ) : undefined,
    },
    [range[1]]: {
      label: value[1] ? (
        <Number digitAfterFraction={1} isStringOnly isCurrency={isCurrency} value={value[1]} />
      ) : undefined,
    },
  }

  const onChange = newValue => {
    setField(newValue)
  }
  return (
    <>
      <label className="d-block">{label}</label>
      <Col>
        <Space style={{ alignItems: "baseline" }} className={styles.inputSpacer}>
          <InputNumber
            min={range[0]}
            max={range[1]}
            style={{ width: "100%" }}
            placeholder={t("Min")}
            value={value[0] && value[0]}
            onChange={e => {
              onChange([e, value[1]])
            }}
          />
          <div style={{ marginInline: "8px" }}>{t("To")}</div>
          <InputNumber
            min={range[0]}
            max={range[1]}
            placeholder={t("Max")}
            style={{ width: "100%" }}
            value={value[1] && value[1]}
            onChange={e => {
              onChange([value[0], e])
            }}
          />
        </Space>
        <Col span={24}>
          <Slider
            className={styles.rangeSlider}
            range
            marks={marks}
            min={range[0]}
            max={range[1]}
            onChange={onChange}
            value={value}
            tooltip={{ formatter: value => <Number isStringOnly isCurrency={isCurrency} value={value} /> }}
          />
        </Col>
      </Col>
    </>
  )
}

export default RangeSlider
