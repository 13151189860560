import { Col, Row, Upload } from "antd"
import { Icon, notify, Text } from "components/common"
import { useTranslation } from "react-i18next"
import { fileToBase64 } from "utils/index"
import Lottie from "react-lottie"
import theme from "../../../utils/themeVars"
import { IconText } from "../../custom"
import Group from "../group"
import animationData from "../../../animation/uploadFileAnimation.json"

const defaultOptions = { loop: true, autoplay: true, animationData: animationData }

const UploadDragger = props => {
  const { t } = useTranslation()
  const { setFiles, name, files, setField, fieldName, maxCount, extraObj, imageType, uploadDescription, uploadTitle, showCustomUploaderView } = props
  return (
    <Upload.Dragger
      maxCount={maxCount}
      accept={imageType ? imageType : "image/png, image/jpeg,.pdf,.xls "}
      name={name}
      beforeUpload={e => {
        const isLt1M = e.size / 1024 / 1024 < 1
        if (!isLt1M) {
          notify.error(t("File size must be within 1MB!"))
        } else {
          fileToBase64(e).then(base64File => {
            if (!!setFiles) {
              if (!!files.find(efile => efile.file === base64File)) {
                notify.error(t("Cannot add duplicate file"))
              } else {
                setFiles(encodedFile => [...encodedFile, { name: e.name, file: base64File }])
              }
            } else if (!!setField) {
              setField({ [fieldName]: { name: e.name, file: base64File, ...extraObj } })
            }
          })
        }
        return false
      }}
      onRemove={e => {
        if (!!setField) {
          setField({ [fieldName]: null })
        } else if (!!setFiles) {
          setFiles(encodedFile => encodedFile.filter(file => file.uid !== e.uid))
        }
      }}
      fileList={files}
    >
      {!!showCustomUploaderView ? <Group template="auto 1fr" gap="24px" className="p-16" style={{ "align-items": "center" }}>
          <Lottie options={defaultOptions} height={56} width={56} />
          <Row className="text-left" style={{ gap: 8 }}>
            <Text color={theme["primary-color"]} block>
              <span className="semiBold">{uploadTitle}</span>
            </Text>
            <IconText
              icon="BsInfo"
              title={uploadDescription}
              iconProps={{
                style: { color: "#707070" },
                hasBackground: true,
                size: "1.5em",
                iconContainerStyle: { marginTop: 4, padding: 0, "--icon-bg-color": "#E6E6E6" },
              }}
              style={{ alignItems: "flex-start", "--typography-color": "#9D9D9D", fontSize: 12, gap: 8 }}
            />
          </Row>
        </Group> :
        <Row span={24} className="pi-12" justify="space-between">
          <Col className="gray-700">
            <Icon icon="HiPlus" size={14} /> {t("Select file")}
          </Col>
          <Col>
            <Icon icon="BsPaperclip" />
          </Col>
        </Row>}
    </Upload.Dragger>
  )
}
export default UploadDragger
