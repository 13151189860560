import { Steps as AntdSteps } from "antd"

const Steps = ({ data, ...props }) => {
  return (
    <AntdSteps {...props}>
      {data.map((item, index) => (
        <AntdSteps.Step
          key={index}
          title={item.title}
          icon={item.icon}
          status={!!item.status ? item.status : ""}
          description={item.description}
        />
      ))}
    </AntdSteps>
  )
}

export default Steps
