import { Modal, Row, Upload } from "antd"
import { Text, notify } from "components/common"

import Group from "../group"
import { IconText } from "components/custom"
import Lottie from "react-lottie"
import animationData from "animation/uploadFileAnimation"
import cx from "clsx"
import { fileToBase64, getFileType, openBase64, isNotImageCheck, isPDF } from "utils"
import styles from "./index.module.less"
import theme from "utils/themeVars"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png, application/pdf"]
const defaultOptions = { loop: true, autoplay: true, animationData: animationData }

export default function ImageUpload(props) {
  const { t } = useTranslation()
  const {
    fileSize = 1,
    minDimensions = [400, 400],
    maxDimensions = [1024, 1024],
    withDimensionLimit = true,
    allowedFiles,
    setFiles = () => {},
    files,
    showOnly,
    limit,
    mode,
    outerStyle,
    showButton = true,
    uploadDescription = t(
      "Must be in JPG, PNG or PDF file format up till 5 MB Upload all the evidence screenshots, chat conversation or emails that help in your claim."
    ),
    uploadTitle = t("Click or drag file to this area to upload"),
    onChange,
    ...rest
  } = props

  const [file, setFile] = useState({ previewTitle: "", previewImage: "", previewOpen: false })

  const handleCancel = () =>
    setFile(prev => {
      return { ...prev, previewOpen: false }
    })

  const isValidUrl = url => {
    try {
      new URL(url)
      return true
    } catch (err) {
      return false
    }
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await fileToBase64(file.originFileObj)
    }

    if (isValidUrl(file?.url || file?.preview)) {
      if (isPDF(file?.url || file?.preview)) {
        window.open(file?.url || file?.preview)
      } else if (isNotImageCheck(file)) {
        openBase64(file?.url || file?.preview)
      }
    }

    setFile(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        previewOpen: true,
      }
    })
  }

  const customUpload = file => {
    setTimeout(() => {
      file.onSuccess("ok")
    }, 0)
  }
  const processFile = (file, resolve) => {
    fileToBase64(file).then(base64File => {
      setFiles({ name: file.name, url: base64File, uid: "0" })
      setFile(prev => {
        return {
          ...prev,
          containFile: true,
        }
      })
    })
    resolve(true)
  }
  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      const isLt5M = file.size / 1024 / 1024 <= fileSize

      if (!isLt5M) {
        notify.error(`${t("File size can not be greater than")}${fileSize}MB`)
        return Upload.LIST_IGNORE
      }
      if (isNotImageCheck(file)) {
        rest?.platformKey === "bayut_ksa"
          ? processFile(file, resolve)
          : notify.error(t("Invalid file format. Please upload an image in PNG, JPEG or JPG format"))
      } else {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = e => {
          var image = new Image()
          image.src = e.target.result
          image.onload = function () {
            const height = this.height
            const width = this.width
            if (!withDimensionLimit) {
              processFile(file, resolve)
            } else if (
              width <= maxDimensions[0] &&
              width >= minDimensions[0] &&
              height <= maxDimensions[1] &&
              height >= minDimensions[1]
            ) {
              processFile(file, resolve)
            } else {
              notify.error(`Image size is not within the range of ${maxDimensions[0]}px and ${minDimensions[0]}px`)
              return Upload.LIST_IGNORE
            }
          }
        }
      }
    })
  }

  const renderUploader = () => (
    <Group template="auto 1fr" gap="24px" className="p-16" style={{ "align-items": "center" }}>
      <Lottie options={defaultOptions} height={56} width={56} />
      <Row className="text-left" style={{ gap: 8 }}>
        <Text color={theme["primary-color"]} block>
          <span className="semiBold">{uploadTitle}</span>
        </Text>
        <IconText
          icon="BsInfo"
          title={uploadDescription}
          iconProps={{
            style: { color: "#707070" },
            hasBackground: true,
            size: "1.5em",
            iconContainerStyle: { marginTop: 4, padding: 0, "--icon-bg-color": "#E6E6E6" },
          }}
          style={{ alignItems: "flex-start", "--typography-color": "#9D9D9D", fontSize: 12, gap: 8 }}
        />
      </Row>
    </Group>
  )
  return (
    <>
      <div className={styles.uploadOuter} style={outerStyle}>
        <Upload
          maxCount={limit || 1}
          listType={rest?.noCard ? "picture-card" : "picture"}
          fileList={files}
          onPreview={handlePreview}
          previewFile={file => fileToBase64(file)}
          showUploadList={{ showRemoveIcon: !showOnly }}
          customRequest={customUpload}
          beforeUpload={beforeUpload}
          className={rest?.noCard && cx(styles.upload)}
          onRemove={() => {
            if (mode !== "multiple") {
              setFiles(null)
              setFile(prev => ({ ...prev, containFile: false }))
            }
          }}
          accept={allowedFiles || allowedFileTypes.join(",")}
          onChange={onChange}
          multiple={mode === "multiple"}
          {...rest}
        >
          {mode == "multiple"
            ? renderUploader()
            : files?.length && (files || file?.containFile)
            ? null
            : renderUploader()}
        </Upload>

        {file.previewImage && !isNotImageCheck(file) && (
          <Modal open={file.previewOpen} title={file.previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={file.previewImage}
            />
          </Modal>
        )}
      </div>
    </>
  )
}
