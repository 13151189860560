import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { inDevelopMode } from "utils/env"
import parentApi, { getHeaders } from "store/parentApi"

import { LOGIN_STORAGE_KEY } from "constants"
import { logout } from "store/appSlice"
import { setStorageItem } from "utils/localStorage"
import { configs } from "configs"
import { removeAppCredentials } from "configs/utils"

const transformErrorResponse = error => {
  console.error("Request Error: ", error)
  return {
    ...error,
    status: error?.originalStatus || error?.status,
    message:
      error?.data?.errors?.[0]?.message ||
      error?.data?.message?.reasons?.join("\n") ||
      error?.data?.message ||
      "Network error",
  }
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: configs.apiBaseURL(),
    ...getHeaders(),
    credentials: inDevelopMode ? undefined : "include",
  }),

  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({ url: "/authentication/login", method: "POST", body }),
      transformErrorResponse,
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            setStorageItem(LOGIN_STORAGE_KEY, "yes")
          }
        } catch (err) {}
      },
    }),
    logout: builder.mutation({
      query: body => ({ url: "/authentication/logout", method: "POST", body }),
      transformErrorResponse,
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data === null) {
            dispatch(logout())
            dispatch(parentApi.util.resetApiState())
          }
        } catch (err) {}
      },
    }),
    googleUrl: builder.mutation({
      query: () => ({ url: "/authentication/googleAuthUrl", method: "POST", body: {} }),
      transformErrorResponse: e => {
        if ((e?.originalStatus || e?.status) == 401) {
          removeAppCredentials(null, true)
        }
        return transformErrorResponse(e)
      },
    }),
    googleLogin: builder.mutation({
      query: body => ({
        url: "/authentication/signInViaGoogle",
        method: "POST",
        body,
        headers: { "content-type": "application/json; charset=utf-8" },
      }),
      transformErrorResponse,
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            setStorageItem(LOGIN_STORAGE_KEY, "yes")
          }
        } catch (err) {}
      },
    }),
    forgotPassword: builder.mutation({
      query: body => ({ url: "/authentication/forgotPassword", method: "POST", body }),
      transformErrorResponse,
    }),
    resetPassword: builder.mutation({
      query: body => ({ url: "/authentication/changeForgotPassword", method: "PUT", body }),
      transformErrorResponse,
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useGoogleUrlMutation,
  useGoogleLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = authApi
