import { DataTable, DatePicker, DateRangePicker } from "components/common"
import { useLazyGetProfileSummaryQuery, useLazyGetFilteredActivityLogQuery } from "features/shared/api"
import { useEffect, useMemo, useCallback, useState } from "react"
import { convertTo12HourFormat, dateTimeFunction } from "utils/date"
import { DATE_FORMAT, DATE_TIME_FORMAT } from "constants"
import styles from "./index.module.less"
import { t } from "i18next"
import moment from "moment/moment"
import { notification } from "antd"

const ActivityLogs = ({ staffId }) => {
  const columns = [
    { title: t("TIME"), dataIndex: "time" },
    { title: t("CALL TASKS"), dataIndex: "callTasks" },
    { title: t("CALLS"), dataIndex: "calls" },
    { title: t("MET"), dataIndex: "meetings" },
  ]

  const [getProfileSummary, { data, isFetching, error }] = useLazyGetProfileSummaryQuery()
  const [date, setDate] = useState(null)

  useEffect(() => {
    getProfileSummary({ staffId, load: "activityLogs" }, true)
  }, [])

  const tableData = useMemo(() => {
    if (data) {
      return (data?.activityLogs).map((values, index) => {
        return {
          key: index,
          time:
            dateTimeFunction({ date: values.from, timeZone: true, format: DATE_TIME_FORMAT }).slice(11, -3) +
            " - " +
            dateTimeFunction({ date: values.to, timeZone: true, format: DATE_TIME_FORMAT }).slice(11, -3),
          callTasks: values.callTasks,
          calls: values.calls,
          meetings: values.meetings,
        }
      })
    }

    return []
  }, [data])

  const handleOnChange = date => {
    // const fromDate = encodeURIComponent(
    //   dateTimeFunction({ date: moment(date)?.startOf("day"), apiRequest: true, timeZone: true })
    // )
    const toDate = encodeURIComponent(
      dateTimeFunction({ date: moment(date)?.endOf("day"), apiRequest: true, timeZone: true })
    )

    console.log(toDate, "+++++++++++++++++++++++++++++++++++")

    getProfileSummary({ staffId, load: "activityLogs", extra: { toDate } }, true)
  }

  return (
    <>
      <DatePicker
        onChange={handleOnChange}
        format={DATE_FORMAT}
        showTime={false}
        defaultValue={date ? moment(decodeURIComponent(date)) : moment()}
        disabledDate={date => {
          return date && moment(date)?.isAfter(moment(), "day")
        }}
        style={{
          maxWidth: 180,
        }}
        allowClear={false}
      />
      <br />
      <br />
      <DataTable
        className={styles.activityTable}
        stickyOffset={-24}
        error={error}
        data={tableData || []}
        noUrlPush={true}
        loading={isFetching}
        columns={columns || []}
      />
    </>
  )
}

export default ActivityLogs
