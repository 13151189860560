import { isBefore, isToday, isTomorrow, startOfToday } from "date-fns"

import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import store from "store"

const getPurposeLabel = e => {
  let purpose = e?.Purpose?.name,
    leadSide = e?.leadSide
  if (purpose == "Sell" && leadSide == "demand") return "Buy"
  if (purpose == "Sell" && leadSide == "supply") return "Sell"
  if (purpose == "Rent" && leadSide == "demand") return "Rent In"
  if (purpose == "Rent" && leadSide == "supply") return "Rent Out"
  return "-"
}

const leadRowObject = (e, user, showRecommended) => {
  let privileges = createPrivilegeObject(e.privileges)
  return {
    ...e,
    id: e.id,
    class: e.TSRClassification?.name,
    lastTask: e.LatestTask,
    lastTaskDate: e.LatestTask?.createdAt,
    interest: e.Project?.name,
    description: e.Project?.projectId,
    type: e?.Type?.name,
    purpose: e.Purpose?.name,
    purposeLabel: e?.interestedIn == "secondary" ? getPurposeLabel(e) : e?.Purpose?.name,
    source: { key: e.source, name: e?.source?.replace("_", " ").toUpperCase() },
    sourceType: e.InternalInquirySource[0]?.name,
    clientName: e.Client?.name,
    nextTask: e.NextStatusWanted === null ? undefined : e.NextStatusWanted?.statusTitleTodo,
    nextTaskDate: e.nextStatusWanted === null ? undefined : e.nextStatusDeadline,
    ...(showRecommended && { recommended: getRecommendedColumn(e, user?.teamType) }),
    hasActiveSale: ["token_payment", "partial_down_payment", "complete_down_payment", "closed_won"].includes(
      e.LatestSalesStatus?.key
    ),
    privileges,
  }
}

const leadsTransformHelper = (response, args) => {
  const user = store.getState().app?.user?.info
  const leadRows = response.data.map(e => leadRowObject(e, user, response.count?.recommended))

  return {
    data: leadRows,
    count: typeof response.count === "number" ? response.count : response.count[args?.activeTab || "all"],
    classificationCounts: {
      all: typeof response.count === "number" ? response.count : response.count.all,
      new: response.newLeadsCount,
      ai_recommended: response.aiLeadsCount,
      ...(response.count?.recommended && { recommended: response.count?.recommended }),
    },
  }
}

const getRecommendedColumn = (lead, userTeamType) => {
  const teamTypeWiseLeadRecommendations = lead?.LeadRecommendationRankings?.find(
    ranking => ranking?.teamType === userTeamType
  )

  const highestLeadRanking =
    !!teamTypeWiseLeadRecommendations &&
    ((isToday(new Date(teamTypeWiseLeadRecommendations?.expiry)) &&
      "client_shifting_policy_rule_shifting_tomorrow" !==
        teamTypeWiseLeadRecommendations?.LeadRecommendationRankingCriteria?.key) ||
      (isToday(
        new Date(teamTypeWiseLeadRecommendations?.expiry) ||
          isTomorrow(new Date(teamTypeWiseLeadRecommendations?.expiry))
      ) &&
        "meeting_pushed_due_today_or_tomorrow" ===
          teamTypeWiseLeadRecommendations?.LeadRecommendationRankingCriteria?.key) ||
      (!isBefore(new Date(teamTypeWiseLeadRecommendations?.expiry), startOfToday()) &&
        ["client_inactive_for_five_days", "client_inactive_for_ten_or_more_days"].includes(
          teamTypeWiseLeadRecommendations?.LeadRecommendationRankingCriteria?.key
        )) ||
      (isTomorrow(new Date(teamTypeWiseLeadRecommendations?.expiry)) &&
        "client_shifting_policy_rule_shifting_tomorrow" ===
          teamTypeWiseLeadRecommendations?.LeadRecommendationRankingCriteria?.key))

  return highestLeadRanking?.LeadRecommendationRankingCriteria
}

export { leadsTransformHelper, leadRowObject }
