import { t } from "i18next"
import moment from "moment"
import { capitalizeFirstLetter } from "utils"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"

const callRecordingTransformHelper = response => {
  const { all, ...params } = response.phoneCallRecordsStatistics
  return {
    ...response,
    phoneCallRecords: response.phoneCallRecords?.map(e => {
      let convertedTime = moment.utc(e?.callTime).local()
      return {
        ...e,
        time: convertedTime,
        date: convertedTime,
        staff: { id: e.userId, name: e?.userName },
        client: { id: e?.clientId, name: e?.clientName, country: e?.clientCountry },
        tag: { title: e?.tagShort, color: e?.tagStyle?.bgColor, tooltipTitle: e?.tag },
        recording: e?.url,
        ...(e?.AudioInspection?.phone_call_id && { id: e?.AudioInspection?.phone_call_id }),
        totalScore: e.AudioInspection?.analysis?.assessment?.total_score,
        assessmentStatus: capitalizeFirstLetter(e.AudioInspection?.analysis?.metric?.status) ?? null,
        privileges: createPrivilegeObject(e?.privileges),
      }
    }),
    phoneCallRecordsStatistics: { all, in: params.in, out: params.out },
    callTabNames: { out: t("Outgoing"), in: t("Incoming"), all: t("All") },
  }
}

export default callRecordingTransformHelper
