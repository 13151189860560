import { Col, Form } from "antd"
import { useGetCountriesQuery, useLazyGetCitiesQuery, useLazyGetLocalityQuery } from "features/shared/api"
import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { getCountryList } from "utils"
import SelectInput from "../../common/select/select"

export default function Location({ value, level = 0, onChange, valueList, className, colSpan = 6 }) {
  const { t } = useTranslation()

  const { data: countries } = useGetCountriesQuery()

  const [getCities, { data: cities, isFetching: isFetchingCities }] = useLazyGetCitiesQuery()
  const [getLocations, { data: locations, isFetching: isFetchingLocations }] = useLazyGetLocalityQuery()
  const [country, setCountry] = useState(null)
  const [city, setCity] = useState(null)
  const [location, setLocation] = useState(null)

  useEffect(() => {
    if (valueList) {
      setCountry(valueList[0])
      valueList[0] && valueList[0] !== country && country !== "international" && getCities(valueList[0])
      setCity(valueList[1])
      valueList[1] && valueList[1] !== city && getLocations(valueList[1])
      valueList[2] && valueList[2] !== location && setLocation(valueList[2])
    }
  }, [valueList])

  return (
    <>
      <Col span={colSpan} key={"loc-1"}>
        <Form.Item key={"ct"}>
          <SelectInput
            className={className}
            label={t("Country")}
            placeholder={t("Please select a Country")}
            value={valueList ? valueList[0] : value ? value : country}
            options={getCountryList(countries, true)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            onChange={(value, option) => {
              setCountry(value)
              setLocation(null)
              setCity(null)
              onChange(0, option)
              level > 0 && value !== "international" && getCities(value)
            }}
          />
        </Form.Item>
      </Col>

      {country && country !== "international" && 0 < level && level <= 2 && (
        <Col span={colSpan} key={"loc-2"}>
          <Form.Item key={"ci"}>
            <SelectInput
              className={className}
              label={t("City")}
              placeholder={t("Please select a City")}
              value={valueList ? valueList[1] : city}
              options={cities}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              inputLoading={isFetchingCities}
              onChange={(value, option) => {
                setCity(value)
                setLocation(null)
                onChange(1, option)
                level > 1 && getLocations(value)
              }}
            />
          </Form.Item>
        </Col>
      )}
      {city && level === 2 && (
        <Col span={colSpan} key={"loc-3"}>
          <Form.Item key={"lo"}>
            <SelectInput
              className={className}
              label={t("Location")}
              placeholder={t("Please select a location")}
              value={valueList ? valueList[2] : location}
              options={locations}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              inputLoading={isFetchingLocations}
              onChange={(value, option) => {
                onChange(2, option)
              }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  )
}
