import { Title } from "components/common"
import { t } from "i18next"
import styles from "../index.module.less"
import Markdown from "react-markdown"
import cx from "clsx"

const SalesPitch = props => {
  const { talkingPoints, salesPitch, language } = props
  return (
    <div className={cx(language === "urdu" && styles.arbicFont, "text-left")}>
      <Title children={<div className={styles.headerTitle}>{t("Previous Call Transcript Summary")}</div>} level={5} />
      <div className={styles.headerTitle}>
        <Markdown>{salesPitch}</Markdown>
      </div>
      <Title children={<div className={styles.headerTitle}>{t("Talking Points")}</div>} level={5} />
      <div className={styles.headerTitle}>
        <ul>
          {talkingPoints?.map(item => (
            <li>{<Markdown>{item}</Markdown>}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SalesPitch
