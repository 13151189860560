import * as yup from "yup"

import { emailValidationYup, numValidation, stringValidationYup } from "helpers/validations"

import { regex } from "constants/regex"
import { t } from "i18next"

const getClientValidationSchema = (editClient = true) =>
  yup.object().shape({
    mobileNumber: yup.array(),
    searchAffiliate: yup.object().required(t("This Field needs to be selected")).nullable(),
    searchId: numValidation(t(""), 15, "naturalNumbers"),
    name: stringValidationYup(t("Name is required"), (regex.nameRegex | regex.arabicRegex)),
    email: emailValidationYup(t("Email is required")),
    gender: stringValidationYup(t("Gender is required")),
    country: yup.object().required(t("Country is required")).nullable(),
    city: yup.object().required(t("City is required")).nullable(),
    locality: yup.object().nullable(),
    allocatedTo: yup.object().required(t("Allocated to is required")).nullable(),
    clientType: yup.string().required(t("Client type is required")),
    clientOccupation: yup.object().nullable(),
    clientRating: yup.string().required(t("Client rating is required")),
    clientBuyingIntent: yup.string().required(t("Client buying intent is required")),
    ...(editClient && {
      dateOfBirth: yup.mixed().nullable(),
      city: yup.object().required(t("City is required")).nullable(),
      locality: yup.object().nullable(),
      cnic: numValidation(),
      passportNumber: yup.mixed().nullable(),
      address: yup.string().nullable(),
      fatherName: stringValidationYup(false, regex.alphabets).nullable(),
      reason: yup.string().required(t("Reason is required")).nullable(),
    }),
  })

export default getClientValidationSchema
