import { Col, Row } from "antd"
import { Button, Dropdown, Icon, Number, Text, notify } from "components/common"
import { Separator } from "components/custom"
import theme from "utils/themeVars"
import { DATE_FORMAT } from "constants"
import { useLazyGetPaymentUrlQuery } from "features/leads/api"
import moment from "moment/moment"
import { joinBytItems } from "utils"
import { dateTimeFunction } from "utils/date"
import { useTranslation } from "react-i18next"

const statusColorMap = {
  pending: "warning",
  in_progress: "warning",
  completed: "success",
  rejected: "danger",
}

export default function InvoiceDropDown(props) {
  const { t } = useTranslation()
  const { paymentList } = props
  const [getPaymentUrl, { data, isFetching }] = useLazyGetPaymentUrlQuery()
  const items = paymentList?.payments.map((item, index) => {
    const canDownload = item?.status?.key !== "rejected" && item?.approved
    return {
      key: index,
      contractId: item?.id,
      canDownload: canDownload,
      label: (
        <>
          <Row>
            {joinBytItems(
              [
                <Number isStringOnly={true} isCurrency={true} value={item?.amount} />,
                item?.referenceNumber && <Text strong>{`Ref# ${item?.referenceNumber}`}</Text>,
              ],
              <Separator />
            )}
          </Row>
          <Row justify="space-between">
            <Col>
              <div className="gray-800 fs14">
                {joinBytItems(
                  [
                    item?.mode?.name,
                    dateTimeFunction({ date: item?.date, format: DATE_FORMAT }),
                    <Text type={statusColorMap[item?.status.key]}>{item?.status?.name}</Text>,
                  ],
                  <Separator />
                )}
              </div>
            </Col>
            {canDownload && <Icon icon="MdDownload" color={theme["primary-color"]} />}
          </Row>
        </>
      ),
    }
  })

  return (
    <Dropdown
      menuStyle={{ width: 400 }}
      suffixIcon={null}
      options={items}
      onClickItem={item => {
        item?.e?.domEvent?.stopPropagation()
        if (item.canDownload) {
          getPaymentUrl(item.contractId)
            .unwrap()
            .then(item => {
              window.open(item.link)
            })
            .catch(error => {
              notify.error(`${error.message}`)
            })
        }
      }}
    >
      <Button
        icon={"MdDownload"}
        size="small"
        shape="round"
        type="default"
        style={{ "--btn-color": theme["primary-color"] }}
        onClick={e => {
          e.stopPropagation()
        }}
        text={t("Download Invoice")}
      ></Button>
    </Dropdown>
  )
}
