import * as yup from "yup"

import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"

const getTaskValidationSchema = () =>
  yup.object().shape({
    task: yup.number().required(t("Task is required")).nullable(),
    subTask: yup.number().required(t("Sub task is required")).nullable(),
    subofSubTask: yup.number().required(t("Location is required")).nullable(),
    leadsClassification: yup.string().required(t("Leads classification is required")),
    tsrClassification: yup.string().required(t("TSR classification is required")),
    completionDate: yup.object().required(t("Completion date is required")).nullable(),
    comment: stringValidationYup().required(t("Comment is required")).nullable(),
    nextTask: yup.number().nullable(),
    attachment: yup.array().of(yup.object().shape({ file: yup.mixed() })),
    verifyMeeting: yup
      .string()
      .nullable()
      .test({
        message: t("Verify meeting is required"),
        test: function (value, ctx) {
          if (this.parent.subTask?.id === 5 && value !== ")fulfilled" && value !== "pending") {
            return false
          }
          return true
        },
      }),
    deadLine: yup.object().required(t("Deadline is required")).nullable(),
    dateSlot: yup.object().required(t("Deadline is required")).nullable(),
    timeSlot: yup.array().required(t("Time slot is required")).nullable(),
  })

export default getTaskValidationSchema
