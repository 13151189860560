import parentApi from "store/parentApi"
import { arrayBufferToBase64, convertQueryObjToString, localisedValue } from "utils"
import transformResponse from "./transformResponse"
import getAgencyTransformHelper from "./transformResponse/getAgencyTransformHelper"
import staffListTransformer from "./transformResponse/staffListTransformer"
import { unitTypesWithSubTypesTransformer } from "./transformResponse/unitTypeTransformer"
import flatStaffHierarchyTransformer from "./transformResponse/flatStaffHierarchyTransformer"
import recruitmentDriveTransformer from "./transformResponse/recruitmentDriveTransformer"
import { dateTimeFunction } from "utils/date"

const sharedApis = parentApi.injectEndpoints({
  endpoints: build => ({
    getCountries: build.query({
      query: () => `/countries`,
      transformResponse: response => transformResponse.countries(response),
    }),

    getStates: build.query({
      query: countryId => `/categories?countryId=${countryId}&catLevel=2`,
      transformResponse: response => transformResponse.countries(response),
    }),

    getLocationsByParent: build.query({
      query: ({ parentId, locLevel }) => `/categories?parent=${parentId}&catLevel=${locLevel}`,
      transformResponse: response => transformResponse.countries(response),
    }),

    getCities: build.query({
      query: id => `categories/getCities?countryId=${id}`,
      transformResponse: response => transformResponse.cities(response),
    }),

    getLocality: build.query({
      query: id => `/categories/getArea?id=${id}`,
      transformResponse: response => transformResponse.localities(response),
    }),

    getProjects: build.query({
      query: params => {
        let paramsObj = { ...params }
        let { projectType } = paramsObj
        if (!!projectType) {
          paramsObj["withDepositSlip"] = projectType == "medallion" ? true : undefined
          paramsObj["withoutDepositSlip"] = projectType == "nonMedallion" ? true : undefined
        }
        delete paramsObj["projectType"]
        return `/definitions/v1/projects?sortBy=name&sortOrder=asc&${convertQueryObjToString(paramsObj)}`
      },
      transformResponse: response => transformResponse.projects(response),
    }),

    getProjectDetail: build.query({
      query: params => `/projects/detail/${params.id}`,
    }),

    getProjectBifurcatedUnits: build.query({
      query: params => `/projects/bifurcatedUnits/${params.id}`,
      transformResponse: response => transformResponse.projectBifurcatedUnits(response),
    }),

    getProjectUnits: build.query({
      query: obj => `/listings/getUnits?listingAgainst=project&${convertQueryObjToString({ ...obj })}`,
      transformResponse: response =>
        response.map(e => ({
          ...e,
          key: e?.id,
          label: localisedValue({ object: e, key: "unitNumber" }),
          value: e?.id?.toString(),
        })),
    }),

    getAmenities: build.query({
      query: () => `/amenities`,
    }),

    getRegions: build.query({
      query: () => `/regions`,
      transformResponse: response => transformResponse.regions(response),
    }),

    getPbxMachines: build.query({
      query: () => `pbxMachines`,
    }),

    getPbxValidator: build.query({
      query: params => `/users/validatePBXExtension?${convertQueryObjToString(params)}`,
    }),

    getOccupation: build.query({
      query: () => `/occupations`,
      transformResponse: response => transformResponse.occupations(response),
    }),

    getClientType: build.query({
      query: () => `/clientTypes`,
      transformResponse: response => transformResponse.clientTypes(response),
    }),

    getClientRating: build.query({
      query: () => `/clientRatings`,
      transformResponse: response => transformResponse.clientRatings(response),
    }),

    getClientBuyingIntent: build.query({
      query: () => `/clientBuyingIntents`,
      transformResponse: response => transformResponse.buyingIntents(response),
    }),

    getUsers: build.query({
      query: ({ privileged = true, ...params }) =>
        `/teamMembers${privileged ? "/privilegedUsers" : ""}?${convertQueryObjToString(params)}`,
      transformResponse: (response, meta, arg) => {
        return arg?.module === "staffManagement" && arg?.staffUsers
          ? transformResponse.allStaffMembers(response)
          : transformResponse.staffMembers(response)
      },
    }),

    getUnitsByProject: build.query({
      query: obj =>
        !Object.keys(obj || {}).length
          ? `/listings/getUnits?listingAgainst=lead`
          : obj?.id
          ? `/listings/getUnits?projectIds=${obj?.id}&listingAgainst=project`
          : `/listings/getUnits?listingAgainst=project&${convertQueryObjToString({ ...obj })}`,
      transformResponse: data =>
        data?.map(item => ({
          ...item,
          label: localisedValue({ object: item, key: "unitNumber" }),
          value: item?.id?.toString(),
        })),
    }),

    getStaffHierarchy: build.query({
      query: ({ userId, ...params }) =>
        `/users/reportees/${userId}?hierarchy=true&${convertQueryObjToString({ ...params })}`,
      transformResponse: response => staffListTransformer(response),
    }),

    getFlatStaffHierarchy: build.query({
      query: ({ userId, ...params }) =>
        `/users/reportees/${userId}?hierarchy=false&${convertQueryObjToString({ ...params })}`,
      transformResponse: response => flatStaffHierarchyTransformer(response),
    }),

    getFilterTags: build.query({
      query: params => `/appliedFilters?${convertQueryObjToString(params)}`,
    }),

    getCheckEmail: build.query({
      query: params =>
        `/services/checkMatch/Client/email?value=${params.email}&exceptField=id&exceptValue=${params.id}`,
    }),

    getPaymentModes: build.query({
      query: () => `/paymentModes`,
      transformResponse: data => data?.map(item => ({ ...item, label: item?.name, value: item?.id?.toString() })),
    }),
    getPaymentTypes: build.query({
      query: () => `paymentTypes?active=true`,
      transformResponse: data => data?.map(item => ({ ...item, label: item?.name, value: item?.id?.toString() })),
    }),

    getCheckPhoneNumber: build.query({
      query: params => {
        const { cell, clientId, ...rest } = params
        const payload = {
          ...rest,
          value: cell?.length > 4 ? cell.slice(4) : cell,
          cellNumber: cell.replace("+", ""),
          matchedDetails: true,
          reAllocationCheck: true,
          ...(clientId && { id: clientId }),
        }
        return `services/checkMatch/ClientCell/short?${convertQueryObjToString(payload)}`
      },
    }),

    getCheckNationalId: build.query({
      query: params =>
        `/services/checkMatch/Client/nationalId?value=${params.nationalId}&exceptField=id&exceptValue=${params.id}`,
    }),

    getTypes: build.query({
      query: () => `/types/typesWithChildren`,
    }),

    getSource: build.query({
      query: () => `/internalInquirySources/sourcesWithChildren?internalActive=true`,
    }),

    getClassification: build.query({
      query: () => `internalInquiryClassifications`,
      transformResponse: response => transformResponse.classification(response),
    }),

    getDynamicFilters: build.query({
      query: params => `dynamicFilters/search_client?${convertQueryObjToString(params)}`,
    }),

    getTimeZones: build.query({
      query: () => `/definitions/timezones`,
    }),

    getLanguages: build.query({
      query: () => `/definitions/languages`,
    }),

    getStaffDetails: build.query({
      query: userId => `/users/basicDetails/${userId}`,
    }),

    getS3Presigned: build.query({
      query: ({ path }) => path,
      transformResponse: response => ({ ...response, url: response.signedUrl || response.url }),
    }),

    updateS3: build.mutation({
      query: params => {
        return {
          url: params.url,
          method: "PUT",
          body: params.file,
          headers: { "Content-Type": "application/octet-stream" },
        }
      },
    }),
    changeLanguage: build.mutation({
      query: id => {
        return {
          url: "/users/language",
          method: "PUT",
          body: { languageId: id },
        }
      },
    }),

    getTeamTypes: build.query({
      query: () => `/TeamTypes`,
      transformResponse: (response, _meta, args) => transformResponse.teamTypeTransformer(response, args),
    }),

    getClientBasicDetails: build.query({
      query: clientId => `/clients/basicDetails/${clientId}`,
    }),

    getCheckReferralToClient: build.query({
      query: params => `/affiliates/referralToClient?${convertQueryObjToString(params)}`,
    }),
    getAffiliateLeadsType: build.query({
      query: () => `/affiliateTypes`,
      transformResponse: response => response?.map(item => ({ ...item, value: item.id.toString() })),
    }),

    getQrCodeLogin: build.query({
      query: params => `/authentication/qrCode/${params?.userId}`,
    }),

    getCurrencies: build.query({
      query: () => `/definitions/currencies`,
      transformResponse: response => response.map(e => ({ ...e, label: e.currencyCode, value: e.id.toString() })),
    }),
    getAgencyCurrency: build.query({
      query: () => `/agency/currencies`,
      transformResponse: response =>
        response.map((e, index) => ({ ...e, title: e.currencyCode, value: e?.value, key: index })),
    }),
    getAgency: build.query({
      query: () => `/agency`,
      transformResponse: response => getAgencyTransformHelper(response),
    }),

    editAgency: build.mutation({
      query: agencyInfo => {
        return { url: `/agency`, method: "PUT", body: agencyInfo }
      },
    }),

    getUserDesignation: build.query({
      query: params => `/userDesignations?${convertQueryObjToString(params)}`,
      transformResponse: response => response?.map(item => ({ ...item, value: item.id.toString() })),
    }),

    getTaskType: build.query({
      query: ({ params }) => `/internalInquiryStatuses?${convertQueryObjToString(params)}`,
      transformResponse: (response, _meta, args) => transformResponse.taskTypes(response, args),
    }),

    getCalenderCities: build.query({
      query: params => "/categories/getCities?catLevel=3",
      transformResponse: response =>
        response.map(e => ({ ...e, value: e.id + "", label: localisedValue({ object: e, key: "name" }) })),
    }),
    getUnitNumber: build.query({
      query: obj => {
        return `/listings/getUnits?listingAgainst=project&${convertQueryObjToString({ ...obj })}`
      },
      transformResponse: res => res.map(e => ({ ...e, id: e.id, label: e.unitNumber, value: e.id.toString() })),
    }),
    getInventoryProject: build.query({
      query: obj => `/projects/privilegedProjects?${convertQueryObjToString({ ...obj })}`,
      transformResponse: res => transformResponse.inventoryProjectTransformer(res),
    }),

    getAreaUnits: build.query({
      query: () => `/areaUnits`,
      transformResponse: res => res.map(e => ({ ...e, value: e.shortName, label: e.displayText })),
    }),

    getUnitTypes: build.query({
      query: ({ isFlatHierarchy, ...obj }) => {
        return `/types/v1/typesWithChildren?${convertQueryObjToString({ ...obj })}`
      },
      transformResponse: (res, _, args) => unitTypesWithSubTypesTransformer(res, args),
    }),

    getPurpose: build.query({
      query: () => `/purposes`,
    }),
    getPossessionAndInstalmentInfo: build.query({
      query: ({
        projectId,
        listingId,
        dealPrice,
      }) => `/projects/${projectId}/bookingInfo?listingId=${listingId}&dealPrice=${dealPrice}
      `,
    }),
    getActivityLog: build.query({
      query: params => `/logs?recordId=${params?.id}&record=${params?.module}`,
    }),
    getAttachmentFile: build.query({
      query: id => ({
        url: `/payments/${id}`,
        responseHandler: response => (response.ok ? response.arrayBuffer() : response.json()), //convert file stream to array buffer
        headers: { accept: "application/pdf" },
      }),
      transformResponse: data => arrayBufferToBase64(data), //convert file from array buffer to base64
    }),
    getClientCells: build.query({
      query: params => {
        return `/clients/${params?.clientId}/cells?action=${params.actionType}`
      },
    }),
    getCallNumber: build.query({
      query: params =>
        `/clients/${params?.clientId}/cells?action=${params.actionType}&phoneCallId=${params?.phoneCallId}`,
    }),
    getProfileSummary: build.query({
      query: ({ staffId, load, params }) => `/users/${staffId}/summary?load=${load}&${convertQueryObjToString(params)}`,
    }),
    addReservationQueue: build.mutation({
      query: data => {
        return { url: `/listingReservationQueue`, method: "POST", body: data }
      },
      invalidatesTags: ["inventory-listing"],
    }),
    leadValidationForReservationQueue: build.query({
      query: ({ leadId }) => `/listingReservationQueue/${leadId}/leadValidation`,
    }),
    deleteReservationQueue: build.mutation({
      query: ({ queueId }) => {
        return { url: `/listingReservationQueue/${queueId}`, method: "DELETE" }
      },
      invalidatesTags: ["inventory-listing"],
    }),
    getReservationLogs: build.query({
      query: ({ ...params }) => {
        let obj = { ...params }
        return `/listingReservationLogs?${convertQueryObjToString({ ...obj })}`
      },
    }),
    generateProposal: build.mutation({
      query: ({ payload, inquiryId, listingId, floorPlan, preview }) => {
        let body = {
          projectId: payload?.projects?.projectId,
          listingId: listingId,
          preview: preview,
          paymentDetails: {
            discountPercentage: payload?.discountPercentage,
            bookingPrice: payload?.bookingPrice,
            completeDownPayment: payload?.cdp,
            possessionAmount: payload?.possessionAmount,
          },
          paymentPlan: {
            instalmentDuration: payload?.months,
            instalmentFrequency: payload?.installmentFrequency,
            ...(payload?.startDate && {
              startDate: dateTimeFunction({ date: payload?.startDate, apiRequest: true, timeZone: true }),
            }),
          },
          settings: {
            showClientName: payload?.proposalSettings?.includes("showClientName"),
            showUnitNetArea: payload?.proposalSettings?.includes("showUnitNetArea"),
            showUnitGrossArea: payload?.proposalSettings?.includes("showUnitGrossArea"),
            showPossessionAmount: payload?.proposalSettings?.includes("showPossessionAmount"),
            generateBookingForm: payload?.proposalSettings?.includes("generateBookingForm"),
            showUserBusinessCard: payload?.proposalSettings?.includes("includeBusinessCard"),
          },
          source: "web_v2",
          floorPlan: {
            name: floorPlan?.file?.name,
            file: floorPlan?.file?.thumbUrl?.split(",")[1],
          },
        }
        return {
          url: `/internalInquiries/${inquiryId}/generateProposal`,
          method: "POST",
          body: body,
          responseHandler: response => (response.ok ? response.arrayBuffer() : response.json()),
          headers: { accept: "application/pdf" },
        }
      },
    }),
    getDesignationDiscounts: build.query({
      query: params => `/projects/${params.projectId}/designationDiscounts?listingId=${params.listingId}`,
    }),
    getClientTaskLogs: build.query({
      query: id => `/clients/${id}/taskLog?withInquiries=true`,
      transformResponse: data => data?.leads.map(lead => lead.id),
    }),
    allocateStaff: build.mutation({
      query: ({ projectId, ...values }) => {
        return {
          url: `/projects/${projectId}/updateAllocatedUsers`,
          method: "PUT",
          body: values,
        }
      },
      invalidatesTags: (resp, err) => (resp ? ["staff"] : []),
    }),
    getRecruitmentDrive: build.query({
      query: () => `/userLabels`,
      transformResponse: response => recruitmentDriveTransformer(response),
      providesTags: ["recruitment-drive"],
    }),
    getInternalInquiryStages: build.query({
      query: () => `/InternalInquiryStages`,
      transformResponse: data =>
        data.map(e => {
          return {
            value: e?.id,
            label: localisedValue({ object: e, key: "name" }),
            key: e?.key,
          }
        }),
    }),
    addSalesPipeline: build.mutation({
      query: data => {
        return { url: `/inquiryPipelines`, method: "POST", body: data }
      },
      invalidatesTags: (result, error, args) => (!error ? ["salesPipelineListing"] : []),
    }),
    updateSalesPipeline: build.mutation({
      query: data => {
        return { url: `/inquiryPipelines/${data?.pipelineId}`, method: "PUT", body: data }
      },
      invalidatesTags: (result, error, args) => (!error ? ["salesPipelineListing"] : []),
    }),
    deleteSalesPipeline: build.mutation({
      query: data => {
        return { url: `/inquiryPipelines/${data?.pipelineId}`, method: "DELETE" }
      },
      invalidatesTags: (result, error, args) => (!error ? ["salesPipelineListing"] : []),
    }),
    verifyDiscountStatus: build.query({
      query: data => {
        return { url: `api/dealDiscounts/slug/${data?.slug}?response=${data?.status}` }
      },
    }),
    overrideExisting: false,
  }),
})

export const {
  useGetCountriesQuery,
  useLazyGetCountriesQuery,
  useLazyGetTimeZonesQuery,
  useLazyGetLanguagesQuery,
  useLazyGetActivityLogQuery,
  useGetActivityLogQuery,
  useLazyGetCheckReferralToClientQuery,
  useGetCitiesQuery,
  useLazyGetCitiesQuery,
  useGetLocationsByParentQuery,
  useLazyGetLocationsByParentQuery,
  useGetStatesQuery,
  useLazyGetStatesQuery,
  useLazyGetLocalityQuery,
  useGetAmenitiesQuery,
  useLazyGetCheckEmailQuery,
  useLazyGetCheckPhoneNumberQuery,
  useLazyGetCheckNationalIdQuery,
  useLazyGetPbxValidatorQuery,
  useGetOccupationQuery,
  useLazyGetOccupationQuery,
  useGetClientTypeQuery,
  useLazyGetClientTypeQuery,
  useGetClientRatingQuery,
  useLazyGetClientRatingQuery,
  useGetClientBuyingIntentQuery,
  useLazyGetClientBuyingIntentQuery,
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useGetProjectsQuery,
  useGetPbxMachinesQuery,
  useLazyGetRegionsQuery,
  useGetRegionsQuery,
  useLazyGetProjectsQuery,
  useGetSourceQuery,
  useGetTypesQuery,
  useLazyGetTypesQuery,
  useGetClassificationQuery,
  useLazyGetClassificationQuery,
  useLazyGetSourceQuery,
  useGetDynamicFiltersQuery,
  useGetProjectBifurcatedUnitsQuery,
  useLazyGetProjectBifurcatedUnitsQuery,
  useLazyGetFilterTagsQuery,
  useGetDisputesQuery,
  useLazyGetDisputesQuery,
  useUpdateS3Mutation,
  useLazyGetS3PresignedQuery,
  useLazyGetStaffDetailsQuery,
  useGetTeamTypesQuery,
  useLazyGetTeamTypesQuery,
  useGetTimeZonesQuery,
  useGetLanguagesQuery,
  useLazyGetClientBasicDetailsQuery,
  useLazyGetQrCodeLoginQuery,
  useLazyGetAffiliateLeadsTypeQuery,
  useGetCurrenciesQuery,
  useLazyGetCurrenciesQuery,
  useGetAgencyQuery,
  useLazyGetAgencyQuery,
  useEditAgencyMutation,
  useLazyGetUserDesignationQuery,
  useGetUserDesignationQuery,
  useLazyGetTaskTypeQuery,
  useLazyGetCalenderCitiesQuery,
  useLazyGetUnitNumberQuery,
  useGetUnitNumberQuery,
  useLazyGetInventoryProjectQuery,
  useLazyGetProjectUnitsQuery,
  useLazyGetAreaUnitsQuery,
  useLazyGetUnitTypesQuery,
  useLazyGetPurposeQuery,
  useGetPurposeQuery,
  useGetAreaUnitsQuery,
  useGetAgencyCurrencyQuery,
  useLazyGetProjectDetailQuery,
  useLazyGetAttachmentFileQuery,
  useGetPaymentModesQuery,
  useGetPaymentTypesQuery,
  useGetStaffHierarchyQuery,
  useLazyGetStaffHierarchyQuery,
  useLazyGetFlatStaffHierarchyQuery,
  useLazyGetUnitsByProjectQuery,
  useLazyGetProfileSummaryQuery,
  useLazyGetClientCellsQuery,
  useLazyGetCallNumberQuery,
  useChangeLanguageMutation,
  useLazyGetPossessionAndInstalmentInfoQuery,
  useAddReservationQueueMutation,
  useLazyLeadValidationForReservationQueueQuery,
  useDeleteReservationQueueMutation,
  useLazyGetReservationLogsQuery,
  useGenerateProposalMutation,
  useLazyGetDesignationDiscountsQuery,
  useLazyGetClientTaskLogsQuery,
  useAllocateStaffMutation,
  useGetRecruitmentDriveQuery,
  useGetInternalInquiryStagesQuery,
  useLazyGetInternalInquiryStagesQuery,
  useAddSalesPipelineMutation,
  useUpdateSalesPipelineMutation,
  useDeleteSalesPipelineMutation,
  useVerifyDiscountStatusQuery,
} = sharedApis
