import Icon from "../icon/icon"
import { formatTimeString, getTimeDateString } from "utils/date"

const TimeFormat = ({ date, isTimeOnlyStr, className }) => {
  const formattedDate = isTimeOnlyStr ? formatTimeString(date) : getTimeDateString(date, true, false, true)
  return (
    formattedDate && (
      <div className={className}>
        <Icon icon="BiTimeFive" size="14px" />
        &nbsp; {formattedDate}
      </div>
    )
  )
}

export default TimeFormat
