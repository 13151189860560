import { notification as antdNotification } from "antd"

const changeMaxCount = condition => {
  antdNotification.config({
    maxCount: condition ? 1 : undefined,
  })
}

const notification = {
  info: (obj, singleNotification = true) => {
    changeMaxCount(singleNotification)
    const { message, description } = obj
    antdNotification.info({
      message: message,
      description: description,
    })
  },
  error: (obj, singleNotification = true) => {
    changeMaxCount(singleNotification)
    const { message, description } = obj
    antdNotification.error({
      message: message,
      description: description,
    })
  },

  success: (obj, singleNotification = true) => {
    changeMaxCount(singleNotification)
    const { message, description } = obj
    antdNotification.success({
      message: message,
      description: description,
    })
  },

  warning: (obj, singleNotification = true) => {
    changeMaxCount(singleNotification)
    const { message, description } = obj
    antdNotification.warning({
      message: message,
      description: description,
    })
  },
  custom: obj => {
    antdNotification.destroy(obj?.key)
    antdNotification.open(obj)
  },

  close: key => {
    return antdNotification.close(key)
  },
  destroy: key => {
    antdNotification.destroy(key)
  },
}

export default notification
