import { capitalizeFirstLetter, localisedValue } from "utils"
import { t } from "i18next"

const taskLogsTransformHelper = response => {
  const transformedData = response.data.map((element, index) => {
    return {
      ...element,
      key: index,
      leadId: element?.taskAgainstId,
      taskStatus: {
        name: [
          element?.TaskType.Parent?.name ? localisedValue({ object: element?.TaskType.Parent, key: "name" }) : "",
          element?.TaskType?.name ? localisedValue({ object: element?.TaskType, key: "name" }) : "",
        ],
        date: element?.createdAt,
      },
      interest: {
        projectName: localisedValue({ object: element?.InternalInquiry?.Project, key: "name" }),
        projectDesc: [
          element?.InternalInquiry?.Type?.name
            ? localisedValue({ object: element?.InternalInquiry?.Type, key: "name" })
            : "",
          element?.InternalInquiry?.Purpose?.name
            ? localisedValue({ object: element?.InternalInquiry?.Purpose, key: "name" })
            : "",
          element?.InternalInquiry?.interestedIn
            ? t(capitalizeFirstLetter(localisedValue({ object: element?.InternalInquiry, key: "interestedIn" })))
            : "",
        ],
        projectUrl: element?.InternalInquiry?.Project?.url,
      },
      remark: {
        assignedTo: localisedValue({ object: element?.AssignedTo, key: "name" }),
        tagData: localisedValue({ object: element?.AssignedTo?.TeamMember?.Team?.TeamType, key: "name" }),
        desc: localisedValue({ object: element, key: "description" }),
        attachment: element?.Attachments?.filter(e => e.entityType !== "task_comment_audio"),
      },
      commentVoiceRecording: element?.Attachments?.find(e => e.entityType === "task_comment_audio"),
    }
  })

  const taskAllocatedUsers = response?.taskAllocatedUsers?.map((element, index) => {
    return {
      ...element,
      id: element?.userId,
      label: element?.name,
    }
  })
  return { data: transformedData, count: response.count, taskAllocatedUsers: taskAllocatedUsers }
}

export default taskLogsTransformHelper
