import { Modal, SelectInput, TextInput } from "components/common"
import { IconText } from "components/custom"
import {
  useAddFeedbackMutation,
  useLazyGetMeetingsQuery,
  useLazyGetReviewObservationFieldsQuery,
} from "features/clients/detail/api"

import { Form } from "antd"
import Segmented from "components/common/segmented/segmented"
import { schemaRules } from "features/shared/utils"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES, CONTENT_TYPES } from "utils/gtm/gtmEventsData"
import theme from "utils/themeVars"
import { clientObservationInitialValues } from "./clientObservationInitialValues"
import getClientObservationValidationSchema from "./clientObservationValidationSchema"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"
import { t } from "i18next"
import AttachmentUploader from "features/management/components/attachmentUploader/attachmentUploader"
import styles from "features/management/components/createNewMeeting/index.module.less"
import { useState } from "react"
import { useQueryUpdate } from "hooks"

const ratingsOption = () => [
  { value: 1, label: t("1") },
  { value: 2, label: t("2") },
  { value: 3, label: t("3") },
  { value: 4, label: t("4") },
  { value: 5, label: t("5") },
  { value: 6, label: t("6") },
  { value: 7, label: t("7") },
  { value: 8, label: t("8") },
  { value: 9, label: t("9") },
  { value: 10, label: t("10") },
]

export default function ClientObservationForm({ formRef, lead, client, allocatedName, from = {}, clientLeads }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const attachments = Form.useWatch("attachments", form)
  const selectedLeadId = Form.useWatch("leadId", form)
  const [files, setFiles] = useState([])
  const [updateQueryData] = useQueryUpdate()
  const [addFeedback, { error, isError, isLoading }] = useAddFeedbackMutation()
  const [getReviewsFields, { data: observationFields }] = useLazyGetReviewObservationFieldsQuery()
  const [getMeetings, { data: meeting, isFetching: isFetchingMeeting }] = useLazyGetMeetingsQuery()
  const rules = schemaRules(getClientObservationValidationSchema())

  const onModalVisible = () => {
    getReviewsFields()
    setFiles([])
    lead?.id && getMeetings(lead?.id)
  }

  const onSubmit = values => {
    const response = addFeedback({ values, lead, observationFields, files }).unwrap()
    response
      .then(() => {
        resetModalForm()
        fireEvent({
          ...ACTION_TYPES.lead_review_meeting_submit,
          ...from,
          response: "200",
          client_id: client.id,
          content_type: CONTENT_TYPES.client_observation_meeting,
        })
      })
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.lead_review_meeting_submit,
          ...from,
          response: e.status,
          client_id: client.id,
          content_type: CONTENT_TYPES.client_observation_meeting,
        })
      })
  }

  const resetModalForm = () => {
    formRef?.current?.setVisible(false)
    formRef.resetFields()
    updateQueryData("getClientTaskLogs", () => null)
    updateQueryData("getMeetings", () => null)
  }

  return (
    <Modal
      title={t("Client Observation Meeting")}
      ref={formRef}
      width={670}
      okText={t("Save")}
      cancelText={t("Cancel")}
      onCancel={() => resetModalForm()}
      onOk={form.submit}
      loading={isLoading}
      onModalVisible={onModalVisible}
      footerAlert={error}
      destroyOnClose
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          labelAlign={"left"}
          name="form1"
          colon={false}
          initialValues={clientObservationInitialValues()}
          preserve={false}
          onFinish={onSubmit}
          scrollToFirstError
        >
          {!lead && (
            <Form.Item name={"leadId"} label={t("Lead Id")} rules={rules} required>
              <SelectInput
                placeholder={t("Select Lead Id")}
                options={clientLeads}
                onChange={(_value, option) => {
                  getMeetings(option?.value)
                }}
              />
            </Form.Item>
          )}
          {(lead || selectedLeadId) &&
            (meeting?.length === 0 ? (
              lead?.User?.name ? (
                <div className="mbe-24">{`${t("We could not find any meeting added by")} ${
                  lead?.User?.name ? lead.User?.name : allocatedName
                } ${t("against this lead")}!`}</div>
              ) : (
                <div className="mbe-24">{`${t("We could not find any meeting added against this lead")}!`}</div>
              )
            ) : (
              <Form.Item name="selectMeeting" label={t("Select Meeting")} rules={rules}>
                <SelectInput
                  placeholder={t("Select")}
                  options={meeting}
                  loading={isFetchingMeeting}
                  onChange={(_value, option) => {
                    form.setFieldsValue({ selectMeeting: option })
                  }}
                />
              </Form.Item>
            ))}
          <IconText
            icon="MdInfoOutline"
            iconProps={{ color: theme["gray800"], size: "1em" }}
            title={t("Please note 1 is the lowest and 10 is the highest")}
            textType="secondary"
          />
          {observationFields?.map(e => {
            return (
              <Form.Item
                name={e.value}
                label={localisedValue({ object: e, key: "label" })}
                rules={[
                  { required: true, message: `${localisedValue({ object: e, key: "label" })} ${t("is required")}` },
                ]}
                className="mbe-4"
                required
              >
                <Segmented
                  defaultValue={null}
                  options={ratingsOption()}
                  onChange={(_value, option) => {
                    form.setFieldsValue({ [e.name]: option })
                  }}
                />
              </Form.Item>
            )
          })}
          <Form.Item name="attachments" label={t("Attachments")} className={styles.uploadImages}>
            <AttachmentUploader
              maxCount={2}
              hideUpload={false}
              attachments={attachments}
              allowedFiles={
                "image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              files={files}
              setFiles={setFiles}
              uploadTitle={false}
              uploadClass={styles.uploadID}
              multiple={true}
              isduplicateFileAllowed={false}
              allowedFileSize={2}
              popupPreviewProp
            />
          </Form.Item>
          <Form.Item
            name="feedback"
            label={t("Feedback")}
            rules={rules}
            required
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <TextInput
              placeholder={t("Enter feedback")}
              lineCount={4}
              maxLength={256}
              onChange={e => {
                form.setFieldsValue({ feedback: e.target.value })
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
