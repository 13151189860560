import cx from "clsx"
import { getStageColor } from "../../../utils/stageColors.js"
import styles from "./index.module.less"

const StatusTag = ({ data, children, className, ...rest }) => {
  return (
    data && (
      <div
        className={cx(className, styles.status)}
        style={{
          "--status-bg-color": data?.color || getStageColor(data?.key),
          ...(data?.textColor && { "--text-color": data?.textColor }),
        }}
        {...rest}
      >
        {children ? children : data.title || data.name}
      </div>
    )
  )
}

export default StatusTag
