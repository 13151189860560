import { Radio, Skeleton, Space, Tabs, Typography } from "antd"

import { Icon } from "components/common"
import PropTypes from "prop-types"
import styles from "./radioButtons.module.less"
import { t } from "i18next"
import cx from "clsx"

function RadioButtons(props) {
  const {
    className,
    label,
    labelIcon,
    name,
    skeletonLoading,
    loading,
    value,
    isTabbed,
    isButton,
    btnSize,
    shape,
    onTabChange,
    options,
    errorMsg,
    disabled,
    radioGroupGap,
    activeKey,
    color,
    onChange,
    getValue,
    getLabel,
    darkMode = false,
    ...rest
  } = props

  const renderButtons = () => {
    return (
      <div>
        {label && <div>{label}</div>}
        <Radio.Group
          className={cx({
            [styles.pill]: isButton,
            [styles.pillDarkTheme]: darkMode,
            [className]: true,
          })}
          value={value}
          name={name}
          size={btnSize}
          onChange={onChange}
          {...rest}
        >
          {isTabbed ? (
            <Tabs onTabClick={activeKey => onTabChange(options.find(e => e.tabKey == activeKey))} activeKey={activeKey}>
              {options?.map(item => {
                return (
                  <Tabs.TabPane tab={item.tabTitle} key={getValue(item)} disabled={disabled}>
                    <Space className="pills-list">
                      {item.buttonList.map(e => (
                        <Radio value={getValue(e)} key={getValue(e)} shape="round" disabled={disabled}>
                          {e.icon && <Icon icon={e.icon} color={item.color} />}
                          {e.label}
                        </Radio>
                      ))}
                    </Space>
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          ) : isButton ? (
            options?.map(item => {
              return (
                <Radio.Button
                  value={getValue(item)}
                  key={getValue(item)}
                  disabled={disabled || item.disabled}
                  color={color}
                >
                  {item.icon && <Icon icon={item.icon} color={item.color} />}
                  {t(item.label) || t(getLabel(item))}
                </Radio.Button>
              )
            })
          ) : (
            options?.map(item => {
              return (
                <Radio value={getValue(item)} key={getValue(item)} disabled={disabled || item.disabled}>
                  {item.icon && <Icon icon={item.icon} color={item.color} />}
                  {item.label || getLabel(item)}
                </Radio>
              )
            })
          )}
        </Radio.Group>
        {!!errorMsg && <Typography.Text type="danger">{errorMsg}</Typography.Text>}
      </div>
    )
  }

  return skeletonLoading ? (
    <Skeleton active avatar={!!labelIcon} paragraph={{ rows: 1 }} />
  ) : labelIcon ? (
    <div>
      <Icon icon={labelIcon} />
      {renderButtons()}
    </div>
  ) : (
    renderButtons()
  )
}

RadioButtons.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelIcon: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  buttonList: PropTypes.array,
  isTabbed: PropTypes.bool,
  serverProps: PropTypes.object,
  shape: PropTypes.string,
  btnSize: PropTypes.string,
  onTabChange: PropTypes.func,
  isButton: PropTypes.bool,
  radioGroupGap: PropTypes.string,
}

RadioButtons.defaultProps = {
  loading: false,
  labelIcon: null,
  buttonList: [],
  serverProps: null,
  onTabChange: () => {},
  radioGroupGap: "initial",
  options: [],
  className: "",
  isButton: true,
  getValue: item => item?.id?.toString() || item?.value?.toString(),
  getLabel: item => item.label || item.title || item.name,
}

export default RadioButtons
