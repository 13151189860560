import { Button, Card, Modal, PDFDocumentView, Text, notification } from "components/common"
import { useQueryUpdate } from "hooks"
import { arrayBufferToBase64, downloadPDF } from "utils"
import styles from "./index.module.less"
import { useTranslation } from "react-i18next"

const PreviewProposalModal = ({
  pdfViewRef,
  resetGenerateProposal,
  formRef,
  formState,
  from,
  form,
  generateProposal,
  projects,
  isLoading,
  error,
}) => {
  const [updateQuery] = useQueryUpdate()
  const { t } = useTranslation()

  return (
    <>
      <Modal
        maskClosable={false}
        ref={pdfViewRef}
        title={t("Preview Proposal")}
        closable={true}
        width={950}
        okText="Continue"
        bodyStyle={{ padding: 24 }}
        destroyOnClose
        onCancel={() => {
          pdfViewRef?.current?.setVisible(false)
          resetGenerateProposal()
          formRef?.current?.setVisible(false)
          if (from?.name !== "lead") {
            updateQuery("getLeads", draft => null)
          }
          form.resetFields()
        }}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={() => {
                pdfViewRef?.current?.setVisible(false)
                formRef?.current?.setVisible(true)
              }}
              disabled={null}
              text={t("Edit")}
            />
            <Button
              size="large"
              type={"primary"}
              onClick={async () => {
                await generateProposal({
                  listingId: formState?.listingId,
                  inquiryId: formState?.inquiryId,
                  payload: formState,
                  preview: false,
                  floorPlan: formState?.floorPlan,
                })
                  .unwrap()
                  .then(url => {
                    downloadPDF(arrayBufferToBase64(url), `Proposal - ${formState?.projects?.name}`)
                    pdfViewRef?.current?.setVisible(false)
                    formRef?.current?.setVisible(false)
                    notification.success({ message: t("File has been successfully downloaded") })
                    if (from?.name !== "lead") {
                      updateQuery("getLeads", draft => null)
                    }
                    form.resetFields()
                  })
                  .catch(error => {
                    notification.error({ message: error })
                  })
              }}
              disabled={null}
              text={t("Download")}
              loading={isLoading}
            />
          </div>
        }
      >
        <div style={{ paddingBottom: 5, left: 3, position: "relative" }}>
          <Text type="secondary">
            {t(
              "Please preview the project proposal and make any changes if required. You will not be able to make any changes once it is downloaded"
            )}
          </Text>
        </div>
        <Card>
          <PDFDocumentView error={error} data={formState?.imageData} file={formState?.imageData} pageWidth={850} />
        </Card>
      </Modal>
    </>
  )
}

export default PreviewProposalModal
