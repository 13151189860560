import { List as AntdList } from "antd"
import ComponentState from "../component-state/component-state"

const List = ({ children, error, loading, onErrorRetry, ...rest }) => {
  return !!error ? (
    <ComponentState onClick={onErrorRetry} btnLoading={loading} description={error?.message} status={error.status} />
  ) : (
    <AntdList loading={loading} {...rest}>
      {children}
    </AntdList>
  )
}
export default List
