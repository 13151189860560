import { localisedValue } from "utils"

const projectTransformer = response => {
  return response.map(e => {
    return {
      ...e,
      id: e.projectId,
      label: localisedValue({ object: e, key: "name" }),
      value: e.projectId.toString(),
      tokenWithUnit: e.minTokenUnit !== "amount" ? e.minToken / 100 : e.minToken,
    }
  })
}

export default projectTransformer
