import { Modal, SelectInput, Text, TextInput } from "components/common"
import { Form } from "antd"
import { useAddCloseLostMutation, useLazyGetCloseLostReasonsQuery } from "features/leads/api"
import { useTranslation } from "react-i18next"

const CloseLostForm = ({ formRef, lead }) => {
  const { t } = useTranslation()
  const [getCloseLostReasons, { data: reasons }] = useLazyGetCloseLostReasonsQuery()
  const [addCloseLost, { isLoading, error, reset: resetError }] = useAddCloseLostMutation()
  const [form] = Form.useForm()

  const onSubmit = async values => {
    const response = await addCloseLost({ leadId: lead.id, ...values })
    if (!response.error) {
      formRef.current.setVisible(false)
    }
  }
  const onModalVisible = () => {
    getCloseLostReasons({}, true)
  }
  const onCancel = () => {
    resetError()
    formRef?.current?.setVisible(false)
  }

  return (
    <Modal
      ref={formRef}
      title={t("Mark Lead as Closed(LOST)")}
      error={error}
      destroyOnClose={true}
      footerAlert={error}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      onOk={form.submit}
      loading={isLoading}
      onModalVisible={onModalVisible}
    >
      <Text type="secondaryLight" className="mbe-24">
        {t("Select a reason and mark the lead as Closed(Lost)")}
      </Text>
      <Form
        form={form}
        scrollToFirstError
        preserve={false}
        onFinish={onSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        labelAlign={"left"}
      >
        <Form.Item
          name="reason"
          label={t("Reason")}
          required
          rules={[
            {
              required: true,
              message: t("Reason is required"),
            },
          ]}
        >
          <SelectInput placeholder={t("Select reason")} options={reasons} getPopupContainer={false} />
        </Form.Item>
        <Form.Item name="comment" label={t("Comment")}>
          <TextInput placeholder={t("Enter comment")} maxLength={1000} lineCount={3} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CloseLostForm
