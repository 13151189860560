import { Col, Form, Row } from "antd"
import Button from "../../common/button/button"
import Checkbox from "../../common/checkbox/checkbox"
import DataTable from "../../common/dataTable/dataTable"
import Icon from "../../common/icon/icon"
import SelectInput from "../../common/select/select"
import TextInput from "../../common/textInput/textInput"
import { useMemo } from "react"
import { InputNumber, TreeSelectInput } from "components/common"
import { useTranslation } from "react-i18next"
import styles from "./index.module.less"

const dependentsObj = dependents => {
  let obj = {}
  dependents?.forEach(item => {
    obj = { ...obj, [item]: undefined }
  })
  return obj
}

const FieldsTable = props => {
  const {
    fieldsList = () => {},
    onAdd = () => {},
    onRemoveItem = () => {},
    valuesList = [],
    setCurrentSelectedValues = () => {},
    transformTextInput = null,
    headerSticky = true,
    tableStickyOffset,
    disableActions = false,
    fieldFlex = "20%",
    wrap = false,
    addIcon,
    fieldsRowClass,
    editable = false,
    editingIndex,
    getEditIcons,
  } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [editform] = Form.useForm()

  const columns = useMemo(() => {
    if (!valuesList?.length) return []
    let tableColumns = []
    fieldsList(form).forEach(item => {
      tableColumns.push({ title: item.label, dataIndex: item.name, type: item.type, hide: item?.hide })
    })
    tableColumns.push({ title: t("Action"), dataIndex: "actions", hide: disableActions })
    tableColumns = tableColumns.filter(item => !item?.hide) // allows you to remove the columns you dont want to show

    return tableColumns
  }, [valuesList])

  const getFieldItem = item => {
    const {
      type,
      label,
      placeholder,
      name,
      options,
      rules,
      defaultValue,
      validations,
      componentProps,
      formProps,
      dependencies,
      dependents,
      onChange,
      extra,
      addonAfter,
      ...rest
    } = item
    switch (type) {
      case "select":
        return (
          <Form.Item dependencies={dependencies} name={name} rules={rules} {...formProps}>
            <SelectInput
              className="selectSearch"
              options={options}
              placeholder={label}
              {...componentProps}
              {...rest}
              onChange={(value, option) => {
                componentProps?.mode === "multiple"
                  ? handleMultiValuesSelect(option, name)
                  : form.setFieldsValue({
                      [name]: { label: option?.label, value: option?.value },
                      ...dependentsObj(dependents),
                    })

                componentProps?.onChange && componentProps.onChange(value, form)
              }}
              getPopupContainer={false}
              openIcon="MdKeyboardArrowUp"
            />
          </Form.Item>
        )

      case "input":
        return (
          <Form.Item dependencies={dependencies} name={name} rules={rules} {...formProps}>
            <TextInput placeholder={label} suffixIcon="search" {...componentProps} />
          </Form.Item>
        )

      case "checkbox":
        return (
          <Form.Item dependencies={dependencies} name={name} rules={rules} {...formProps}>
            <Checkbox
              onChange={value => {
                form.setFieldsValue({ [name]: value.target.checked })
              }}
              {...componentProps}
            >
              {label}
            </Checkbox>
          </Form.Item>
        )
      case "treeSelect":
        return (
          <Form.Item dependencies={dependencies} name={name} rules={rules} {...formProps}>
            <TreeSelectInput
              className="selectSearch"
              options={options}
              placeholder={label}
              onChange={value => {
                form.setFieldsValue({ [name]: value })
              }}
              {...componentProps}
            />
          </Form.Item>
        )

      case "number":
        return (
          <>
            <Form.Item dependencies={dependencies} name={name} rules={rules} {...formProps}>
              <InputNumber
                placeholder={label}
                onChange={amount => onChange(amount)}
                addonAfter={addonAfter}
                {...componentProps}
              />
            </Form.Item>
            {extra}
          </>
        )

      default:
        return <></>
    }
  }

  const getSelectValue = obj => obj?.label || obj?.name || obj

  const getTableValue = (fieldItem, obj, index) => {
    switch (fieldItem.type) {
      case "select":
        return Array.isArray(obj[fieldItem.name])
          ? obj[fieldItem.name].map(e => getSelectValue(e))
          : getSelectValue(obj[fieldItem.name])
      case "treeSelect":
        return Array.isArray(obj[fieldItem.name])
          ? obj[fieldItem.name].map(e => getSelectValue(e))
          : getSelectValue(obj[fieldItem.name])
      case "checkbox":
        return obj[fieldItem.name] ? "Yes" : "No"
      case "number":
        return obj[fieldItem.name]
      default:
        const item = { ...fieldItem, name: index }
        return index===editingIndex && fieldItem.editable ? (
          <Form form={editform}>{getFieldItem(item)}</Form>
        ) : transformTextInput ? (
          transformTextInput(obj[fieldItem.name], index)
        ) : (
          obj[fieldItem.name]
        )
    }
  }

  const tableData = useMemo(() => {
    if (!valuesList?.length) return []
    const data = valuesList.map((obj, index) => {
      const row = { key: index }
      fieldsList(form).forEach(it => {
        row[it.name] = getTableValue(it, obj, index) || "-"
      })
      row.actions = !editable ? (
        <Icon color="#E83A3A" icon="FiTrash2" size="18px" onClick={() => onRemoveItem(index)} />
      ) : (
        getEditIcons(index,editform)
      )
      return row
    })
    return data
  }, [valuesList, editable, editingIndex])

  const onSubmit = async values => {
    onAdd(values)
    form.resetFields()
  }

  const handleMultiValuesSelect = (option, key) => {
    form.setFieldsValue({ [key]: option })
  }

  const renderFieldItem = (item, index) => {
    return !item?.fieldHide ? ( // allows you to hide fields based on field props
      <Col flex={fieldFlex} key={index}>
        {getFieldItem(item)}
      </Col>
    ) : (
      <></>
    )
  }

  return (
    <>
      <Form
        form={form}
        // layout="inline"
        onFinish={onSubmit}
        onValuesChange={() => setCurrentSelectedValues(form.getFieldsValue())}
        scrollToFirstError
        preserve={false}
        // style={{ maxWidth: "none" }}
        // wrapperCol={{ span: 24 }}
      >
        <Row gutter={16}>
          <Col span={21}>
            <Row gutter={16} wrap={wrap} className={fieldsRowClass}>
              {fieldsList(form)?.map((item, index) => renderFieldItem(item, index))}
            </Row>
          </Col>
          <Col span={3}>
            <Button
              icon="HiPlus"
              type="primary-light"
              text={!addIcon && t("Add")}
              onClick={form.submit}
              block
              style={{ height: 48 }}
            />
          </Col>
        </Row>
      </Form>
      {valuesList?.length > 0 && (
        <DataTable
          className={styles.uniTargetTable}
          data={tableData}
          columns={columns}
          headerSticky={headerSticky}
          stickyOffset={tableStickyOffset}
        />
      )}
    </>
  )
}
export default FieldsTable

/**
 * Usage *
 * 
 * 
 *   
  const [values, setValues] = useState([])
  const fieldList = useMemo(() => [
    {
      label: "Feature",
      placeholder: "Select Feature",
      name: "feature",
      type: "select",
      options: [
        { label: "Corner", value: 1 },
        { label: "Park Facing", value: 2 },
        { label: "Roadside", value: 3 },
      ],
      rules: [{ message: "Please select feature", required: true }],
      componentProps: { allowClear: true },
    },
    {
      label: "Amount",
      placeholder: "Enter Amount",
      name: "amount",
      type: "checkbox",
    },
    {
      label: "Type",
      placeholder: "Select Type",
      name: "type",
      type: "select",
      options: [
        { label: "Amount", value: "amount" },
        { label: "Percentage", value: "percentage" },
      ],
      rules: [{ message: "Please select amount type", required: true }],
    },
    {
      label: "Check",
      name: "check",
      type: "input",
      rules: [{ message: "Please add amount", required: true }],
      componentProps: { type: "number" },
    },
  ])

  return (
    <FieldsTable
      fieldsList={fieldList}
      onAdd={item => setValues(prev => [...prev, item])}
      onRemoveItem={index => setValues(prev => prev.filter((item, i) => i !== index))}
      valuesList={values}
    />
  )
 * 
 * 
 * 
 */
