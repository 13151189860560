import { Layout, Menu, Radio } from "antd"
import Sider from "antd/lib/layout/Sider"
import cx from "clsx"
import FloatButton from "components/common/floatButton/floatButton"
import { UserMenu } from "components/custom"
import { SvgIconAi } from "components/svg"
import { configs } from "configs"
import { CALENDAR_PATH, NAME_SPACE } from "constants/app-paths"
import { menuItems } from "constants/menuItems/menu-items"
import sidBarMenuItems from "constants/menuItems/sidebar-menu-items"
import AppNotification from "features/appNotification/appNotification"
import Chatbot from "features/odessyChatbot"
import { useChangeLanguageMutation } from "features/shared/api"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useTranslation, withTranslation } from "react-i18next"
import { MdGTranslate } from "react-icons/md"
import sidebarMenuItems from "constants/menuItems/sidebar-menu-items"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { setSelectedMenuItem, setSelectedSideMenuItem } from "store/appSlice"
import getPrivilege from "utils/privileges/privileges"
import theme from "utils/themeVars"
import { Button, Icon, Space } from "../components/common"
import styles from "./index.module.less"

const { Header, Content } = Layout

const AppLayout = ({ children, i18n }) => {
  const userPrivileges = useSelector(state => state.app.privileges)
  const [changeLanguage, { isLoading: loading }] = useChangeLanguageMutation()
  const { locale } = useSelector(state => state.app.configs)
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()

  const menuList = useMemo(() => menuItems(userPrivileges, null, t), [userPrivileges, t])
  const selectedMenuItem = useSelector(state => state.app.selectedMenuItem)
  const selectedSideMenuItem = useSelector(state => state.app.selectedSideMenuItem)
  const chatbotRef = useRef()

  const sidBarItems = useMemo(
    () => sidBarMenuItems[selectedMenuItem?.key] && sidBarMenuItems[selectedMenuItem?.key](userPrivileges, null, t),
    [userPrivileges, selectedMenuItem?.key, t]
  )

  const checkIfPathMatches = useCallback(
    modulePath => {
      const browserPath = window.location.pathname
      let namespace = browserPath.split("/").filter(item => NAME_SPACE.includes(item))
      return !!(browserPath.replace("/" + namespace[0], "") == (modulePath?.split('?')[0]))
    },
    [window.location.pathname]
  )

  useEffect(() => {
    dispatch(
      setSelectedMenuItem(
        menuItems(userPrivileges, null, t).filter(e => {
          return Array.isArray(e.path) ? e.path.some(e => checkIfPathMatches(e)) : checkIfPathMatches(e.path)
        })?.[0]
      )
    )
  }, [userPrivileges, window.location.pathname])

  useEffect(() => {
    sidBarItems
      ? dispatch(setSelectedSideMenuItem(sidBarItems?.filter(e => checkIfPathMatches(e.path))?.[0]))
      : dispatch(setSelectedSideMenuItem())
  }, [sidBarItems, window.location.pathname])

  useEffect(() => {
    const lang = configs.languages?.find(e => e.key == locale)
    changeLanguage(lang.id)
  }, [locale])

  const onClickMenuItem = e => {
    if (e.domEvent.ctrlKey || e.domEvent.metaKey || e.domEvent.button === 2) {
      return
    }
    const item = menuList.find(it => it.key === e.key)
    if (!selectedMenuItem || item?.key !== selectedMenuItem?.key) {
      dispatch(setSelectedMenuItem(item))
      dispatch(setSelectedSideMenuItem())
    }
  }
  const onChangeLanguage = event => {
    const lang = configs.languages?.find(e => e.key == event.target.value)
    changeLanguage(lang.id)
      .unwrap()
      .then(() => {
        window.location.href = window.location.origin + "/" + lang.key + location.pathname
      })
    // dispatch(setAppLocale(lang.key))
    // dispatch(setAppDirection(lang.rtl ? "rtl" : "ltr"))
    // i18n?.changeLanguage(lang.key)
  }

  return (
    <Layout hasSider={selectedSideMenuItem}>
      <Layout>
        <Header className={styles.header}>
          <Icon className={styles.logo} icon="LogoPropforce" width="140" height="100%" />
          <Menu
            style={{ minWidth: 0, flex: "auto" }}
            mode="horizontal"
            onClick={onClickMenuItem}
            selectedKeys={[selectedMenuItem?.key]}
            items={menuList}
          />
          <Space className={styles.navbarIcons} size={20}>
            {configs.languages.length > 1 && (
              <Radio.Group
                className={styles.languageSwitcher}
                buttonStyle="solid"
                value={locale}
                size="small"
                onChange={onChangeLanguage}
                disabled={loading}
              >
                {configs.languages?.map(e => (
                  <Radio.Button value={e.key}>
                    <span>
                      <MdGTranslate size={14} />
                    </span>

                    {e.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
            <AppNotification onClick={() => {}} />
            {getPrivilege("can_view_bd_calendar", userPrivileges) && (
              <Link to={CALENDAR_PATH} onClick={() => dispatch(setSelectedMenuItem())}>
                <Icon icon="BiCalendar" size={22} style={{ color: theme["primary-color"] }} />
              </Link>
            )}
            <UserMenu onClickLink={item => dispatch(setSelectedMenuItem(item))} />
          </Space>
        </Header>
        <div className={cx(styles.sider, !!sidBarItems && styles.hasSider)}>
          {!!sidBarItems && (
            <Sider width={100}>
              <Menu
                selectedKeys={[selectedSideMenuItem?.key]}
                items={sidBarItems}
                onClick={e => {
                  const item = sidBarItems.find(it => it.key === e.key)
                  dispatch(setSelectedSideMenuItem(item))
                }}
                className={styles.sideMenu}
              />

              <Button
                className={styles.btnFeedback}
                icon="IconHelpCenter"
                text={t("Help Center")}
                size="small"
                type="light"
                color={theme["accent-color"]}
                content={t("Access the help center for any guidance or to provide valuable feedback")}
                onClick={() => {
                  window.open("https://helpcenter.propforce.com/hc/en-us", "_blank")
                }}
              />
            </Sider>
          )}
          <Content className={styles.content}>{children}</Content>
          {getPrivilege("can_view_odyssey_bot", userPrivileges) && (
            <>
              <FloatButton
                onClick={() => chatbotRef.current.setVisible(true)}
                icon={<SvgIconAi id="float-btn" size={32} />}
              />
              <Chatbot ref={chatbotRef} />
            </>
          )}
        </div>
      </Layout>
    </Layout>
  )
}

export default withTranslation()(AppLayout)
