import { useEffect, useState } from "react"

import DataTable from "components/common/dataTable/dataTable"
import { TitleDescription } from "components/custom"
import { useGetAllocationLogsQuery } from "features/clients/detail/api"
import { joinBytItems, localisedValue } from "utils"
import { DateFormat, Icon } from "components/common"
import UserProfileSummary from "features/shared/components/userProfileSummary"
import { t } from "i18next"
import CollapsibleText from "components/common/collapsibleText"

const getAllocationsData = data => {
  let object = data?.map(element => {
    return {
      key: element?.key,
      date: <DateFormat date={element?.createdAt} />,
      allocatedBy:
        element?.AllocateBy?.id !== -1 ? (
          <div>
            {element?.allocationBy}&nbsp;
            <UserProfileSummary staffId={element?.AllocateBy?.id} />
          </div>
        ) : (
          t("RULE ENGINE")
        ),
      allocatedFrom:
        element?.AllocatedFrom !== null && element?.AllocatedFrom?.id !== -1 ? (
          <>
            <TitleDescription
              title={
                <div>
                  {localisedValue({ object: element?.AllocationFrom, key: "name" })}&nbsp;
                  <UserProfileSummary staffId={element?.AllocationFrom?.id} />
                </div>
              }
              gapSpace={6}
              description={joinBytItems(
                [element?.AllocationFrom?.designation, element?.AllocationFrom?.city],
                <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              )}
            />
          </>
        ) : (
          element?.AllocationFrom?.name || t("NA")
        ),
      allocatedTo: element?.AllocationTo ? (
        <>
          <TitleDescription
            title={
              <div>
                {element?.AllocationTo?.name}&nbsp;
                <UserProfileSummary staffId={element?.AllocationTo?.id} />
              </div>
            }
            gapSpace={6}
            description={joinBytItems(
              [element?.AllocationTo?.designation, element?.AllocationTo?.city],
              <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
            )}
          />
        </>
      ) : (
        "-"
      ),
      tag:
        element?.allocationTag && element?.shiftingTag && element?.allocationTag !== element?.shiftingTag
          ? `${localisedValue({ object: element, key: "allocationTag" })}, ${localisedValue({
              object: element,
              key: "shiftingTag",
            })}`
          : localisedValue({ object: element, key: "allocationTag" }) ||
            localisedValue({ object: element, key: "shiftingTag" }) ||
            "-",

      comment: !!element?.comment ? (
        <CollapsibleText text={localisedValue({ object: element, key: "comment" })} collapsibleLength={150} />
      ) : (
        "-"
      ),
    }
  })

  return object
}

const columns = () => [
  { title: t("Date"), dataIndex: "date", key: "date" },
  { title: t("By"), dataIndex: "allocatedBy", key: "allocatedBy" },
  {
    title: t("From"),
    dataIndex: "allocatedFrom",
    key: "allocatedFrom",
    className: "tableStartSpace",
  },
  { title: t("To"), dataIndex: "allocatedTo", key: "allocatedTo" },
  { title: t("Tag"), dataIndex: "tag", key: "tag" },
  { title: t("Comment"), dataIndex: "comment", key: "comment" },
]

const AllocationsTab = ({ client, getCount = () => {} }) => {
  const [filter, setFilter] = useState({ pageLimit: 10, page: 0 })
  const { data, isFetching, error, refetch } = useGetAllocationLogsQuery({ clientId: client.id, filter })
  useEffect(() => {
    data?.count && getCount(data?.count)
  }, [data?.count])

  const onChangePage = (page, pageSize) => {
    setFilter(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }

  return (
    <DataTable
      pagination={{ totalCount: data?.count, pageOptions: [10, 25, 50] }}
      paginationOnBottom={true}
      paginationOnTop={false}
      onChangePage={onChangePage}
      data={getAllocationsData(data?.data)}
      columns={columns()}
      loading={isFetching}
      error={error}
      noUrlPush={true}
      onErrorRetry={refetch}
      stickyOffset={0}
    />
  )
}

export default AllocationsTab
