import { Alert, Button, Card, Group, Text, Title } from "components/common"
import { LogoPropforce } from "components/svg"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"

import { VERIFY_DISCOUNT_STATUS_PATH } from "constants/app-paths"
import { useTranslation } from "react-i18next"
import { useVerifyDiscountStatusQuery } from "features/shared/api"
import { localisedValue } from "utils"

export default function DiscountStatusCard() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryObj = Object.fromEntries([...searchParams])
  console.log("queryObj ***", queryObj)
  console.log("searchParams ***", searchParams.get("slug"))
  const { data, isFetching, error } = useVerifyDiscountStatusQuery(
    { slug: searchParams.get("slug"), status: searchParams.get("status") },
    { refetchOnMountOrArgChange: true }
  )
  // const navigate = useNavigate()
  // navigate({ pathname: VERIFY_DISCOUNT_STATUS_PATH })
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        className="text-center"
        style={{ backgroundColor: "#FFFF00", width: "600px", height: "350px", padding: "30px" }}
      >
        {data && <LogoPropforce width={228} height="auto" style={{ marginBottom: "20px" }} />}
        <Title level={2} className="mbe-10" style={{ color: data?.data?.approved ? "#009432" : "#EF2D36" }}>
          {t("Discount ")}
          {data?.data?.approved ? t("Approved") : t("Rejected")}
        </Title>
        <Text
          type="secondary"
          className="text-center mbe-40"
          style={{
            color: "black",
          }}
        >
          {data?.data?.approved
            ? `${t("The discount has been approved for ")} `
            : `${t("The discount has been rejected for ")} `}
          {localisedValue({ key: "projectName", object: data?.data })}
          <br />
          {`${t("The booking price for the requested unit has been updated.")}`}
          <br />
          {"----"}
          <br />
          {t("If this has happened by mistake, please contact Product Admin.")}
        </Text>
      </Card>
    </div>
  )
}
