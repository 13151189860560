import { Card, Group, Text } from "components/common"
import { convertToReadableString } from "utils"

function CallSummary({ callDetail }) {
  return (
    <Group gap="24px">
      <div>{callDetail?.summary?.description}</div>
      <Group template="40% 60%" gap="20px">
        <div>
          <Text type={"secondary"}>Project Discussed</Text>
          <div className="semiBold">{convertToReadableString(callDetail?.summary?.projectDiscussed)}</div>
        </div>
        <div>
          <Text type={"secondary"}>Next Proposed Meeting</Text>
          <div className="semiBold">{convertToReadableString(callDetail?.summary?.nextProposedTask)}</div>
        </div>
      </Group>

      {callDetail?.summary?.meeting && (
        <div>
          <Text type="secondary">Meeting Information</Text>
          <Card variant="fill" style={{ borderColor: "#C7DEFC" }}>
            <Group template="40% 60%" gap="20px">
              <div>
                <Text type="secondary">Probability</Text>
                <div className="semiBold">{convertToReadableString(callDetail?.summary?.meeting?.probability)}</div>
              </div>
              <div>
                <Text type="secondary">Date/Time</Text>
                <div className="semiBold">{`${callDetail?.summary?.meeting?.date} ${callDetail?.summary?.meeting?.time}`}</div>
              </div>
              <div>
                <Text type="secondary">Venue</Text>
                <div className="semiBold">{convertToReadableString(callDetail?.summary?.meeting?.venue || "")}</div>
              </div>
            </Group>
          </Card>
        </div>
      )}
    </Group>
  )
}

export default CallSummary
