const leadStageTransformer = response => {
  return transformData(response)
}

const transformData = data => {
  return data.map(item => {
    return {
      label: item.name,
      key: item.id.toString(),
      ...(item.children && { children: transformData(item.children) }),
      value: item.id.toString(),
    }
  })
}

export default leadStageTransformer
