import { regex } from "constants/regex"
import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"
import * as yup from "yup"

const getDiscountValidationSchema = () =>
  yup.object().shape({
    discountType: yup.string().required(t("Discount Type Is Required")).nullable(),
    paymentCommitment: yup.object().required(t("Payment Commitment Is Required")).nullable(),
    discount: stringValidationYup(t("Discount Value is required"), regex.numbersAndDecimal).nullable(),
    discountForm: yup.object().required(t("Please Choose the Discount Type")).nullable(),
    reason: stringValidationYup(),
  })

export default getDiscountValidationSchema
