import { Modal } from "components/common"
import DataTable from "components/common/dataTable/dataTable"
import { changeKeys } from "features/clients/clientsConstants"
import { t } from "i18next"

const columns = () => [
  {
    title: t("Client Info"),
    dataIndex: "clientInfo",
    key: "clientInfo",
    className: "tableStartSpace",
  },
  {
    title: t("Original"),
    dataIndex: "original",
    key: "original",
    className: "tableStartSpace",
  },
  {
    title: t("Changed"),
    dataIndex: "changed",
    key: "changed",
  },
]

export default function EditHistoryDetail({ formRef, editIndex, data }) {
  const getEditHistoryDetailData = (data, editIndex) => {
    if (data && data[editIndex]) {
      let editObject = data[editIndex]
      let changedFields = Object.keys(editObject?.changes)

      return changedFields.map(changedField => {
        if (changedField == "ClientCellLogs") {
          let originalValue = editObject?.changes[changedField]?.cellsAdded,
            changedValue = editObject?.changes[changedField]?.cellsRemoved

          if (Array.isArray(originalValue)) {
            originalValue = originalValue.join(", ")
          }
          if (Array.isArray(changedValue)) {
            changedValue = changedValue.join(", ")
          }
          return {
            clientInfo: changeKeys(changedField),
            original: originalValue || "-",
            changed: changedValue,
          }
        }

        return {
          clientInfo: changeKeys(changedField),
          original: editObject?.changes[changedField]?.oldValue || "-",
          changed: editObject?.changes[changedField]?.newValue,
        }
      })
    }
  }

  return (
    <Modal
      title={t("Edit History Detail")}
      width={600}
      ref={formRef}
      onCancel={() => {
        formRef?.current?.setVisible(false)
      }}
    >
      <div>
        <DataTable data={getEditHistoryDetailData(data?.data, editIndex)} columns={columns()} stickyOffset={0} />
      </div>
    </Modal>
  )
}
