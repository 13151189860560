import { joinBytItems, localisedValue } from "utils"

const designationTransformer = (data, arg) => {
  return data.map(item => {
    const label = arg?.dontShowCount
      ? localisedValue({ object: item, key: "short" })
      : joinBytItems([localisedValue({ object: item, key: "short" }), `(${item.count})`], "-")
    return {
      ...item,
      value: item.id.toString(),
      label,
    }
  })
}

export default designationTransformer
