import "./CSSMotionList.less"

import { useMemo, useState } from "react"

import Button from "../../common/button/button"
import CSSMotionList from "rc-animate/es/CSSMotionList"
import Dropdown from "../../common/dropdown/dropdown"
import { Row } from "antd"
import classNames from "classnames"
import { getAppLanguage } from "configs/useInitializeApp"

export default function ActionButtons(props) {
  const { list, discountVisible, animate, beforeAnimate, iconTextWrapperClass, opendrop, ...rest } = props
  const language = getAppLanguage()
  const [index, setIndex] = useState({ expand: true, indexes: [...Array(beforeAnimate).keys()] })
  const onCollapse = () => ({ width: 0, margin: "0 -5px 0 0" })
  const getList = list => {
    return list?.map((item, index) => {
      const { Component, componentProps, icon, content, onClick, color, options, title, disabled, key, ...rest } = item
      if (Component) {
        return <Component key={index} {...componentProps} />
      }
      if (!options) {
        return (
          <Button
            type="light"
            key={key}
            icon={icon}
            shape="circle"
            content={content}
            onClick={e => {
              onClick()
              onClickHideAnimation()
              e?.stopPropagation()
            }}
            color={color}
            disabled={disabled}
            tooltipPlacement={list.length - 1 === index ? "right" : "top"}
            {...rest}
          />
        )
      }
      if (options) {
        return (
          <Dropdown
            key={key}
            options={options}
            box_title={title}
            placement={language.rtl ? "bottomLeft" : "bottomRight"}
            suffixIcon={null}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onClickItem={() => onClickHideAnimation()}
            menuClassName={"ddListMain"}
            iconTextWrapperClass={item?.iconTextWrapperClass}
          >
            <Button
              type="light"
              key={key}
              icon={icon}
              shape="circle"
              content={content}
              color={color}
              disabled={disabled}
              tooltipPlacement={list.length - 1 === index ? "right" : "top"}
              {...rest}
            />
          </Dropdown>
        )
      }
    })
  }
  const actionsList = getList(list)

  const beforeExpand = useMemo(() => {
    let newList
    if (beforeAnimate) {
      newList = getList(list).splice(0, beforeAnimate - 1)
      newList.push(
        <div
          onMouseEnter={() => {
            setTimeout(() => {
              setIndex({ expand: false, indexes: [...Array(actionsList.length).keys()] })
            }, 1)
          }}
        >
          <Button type="light" icon="FiMoreHorizontal" shape="circle" disabled />
        </div>
      )
    }
    return newList
  }, [list, beforeAnimate])

  const onClickHideAnimation = () => {
    setTimeout(() => {
      setIndex({ expand: true, indexes: [...Array(beforeAnimate).keys()] })
    }, 1)
  }

  if (animate) {
    return (
      <div onMouseLeave={() => onClickHideAnimation()} className="actionMain">
        <CSSMotionList
          keys={index.indexes}
          motionName="transition"
          onAppearStart={onCollapse}
          onEnterStart={onCollapse}
          onLeaveActive={onCollapse}
          style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
        >
          {({ key, background, className, style }) => {
            return (
              <div
                className={classNames("demo-block", className)}
                style={{ ...style, background, display: "inline-flex", alignItems: "center" }}
              >
                {index.expand ? beforeExpand[key] : actionsList[key]}
              </div>
            )
          }}
        </CSSMotionList>
      </div>
    )
  }
  return (
    <Row align="middle" style={{ gap: 8, ...rest.style }}>
      {actionsList}
    </Row>
  )
}
