import { LoadingOutlined } from "@ant-design/icons"
import React from "react"
import { Spin } from "antd"
import cx from "clsx"
import styles from "./index.module.less"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />

const Spinner = props => {
  const {
    inDisabledLayout,
    type,
    fullPageSpinner = false,
    className,
    text,
    backgroundColor,
    height,
    loading,
    ...rest
  } = props

  return inDisabledLayout ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 4,
        width: "100%",
        height: height || "100%",
        display: "grid",
        placeContent: "center",
        backgroundColor: backgroundColor || "rgba(255,255,255, .8)",
      }}
    >
      <Spin
        {...(type === "plain" && { indicator: antIcon })}
        className={cx(fullPageSpinner && styles.pageSpin, className)}
        tip={text}
        spinning={loading}
        {...rest}
      />
    </div>
  ) : (
    <Spin
      {...(type === "plain" && { indicator: antIcon })}
      className={cx(fullPageSpinner && styles.pageSpin, className)}
      tip={text}
      {...rest}
    />
  )
}

export default Spinner
