import cx from "clsx"
import { Group } from "components/common"
import { convertToReadableString } from "utils"
import styles from "./assessment.module.less"
export const Assessment = ({ data }) => {
  return (
    <div>
      <Group
        template="300px repeat(3, 1fr)"
        gap={0}
        className={cx(styles.assesmentContent, styles.assesmentContentHead, "fs12 semiBold")}
      >
        <div>TITLE</div>
        <div>SCORE</div>
        <div className={styles.alignCenter}> </div>
        <div className={styles.alignEnd}>OUT OF</div>
      </Group>

      {data?.scoring &&
        Object.keys(data?.scoring)?.map(key => {
          if (key != "total_score") {
            return (
              <Group template="300px repeat(3, 1fr)" gap={0} className={styles.assesmentContent}>
                <div>{convertToReadableString(key)}</div>
                <div>{data?.scoring?.[key]?.split("/")?.[0]}</div>
                <div className={styles.alignCenter}>&nbsp;</div>
                <div className={styles.alignEnd}>{data?.scoring?.[key]?.split("/")?.[1]}</div>
              </Group>
            )
          }
        })}
      <Group template="300px repeat(3, 1fr)" gap={0} className={styles.assesmentHeader}>
        <span>Total</span>
        <span>{data?.scoring?.total_score?.split("/")[0]}</span>
        <span className={styles.alignCenter}></span>
        <span className={styles.alignEnd}>{data?.scoring?.total_score?.split("/")[1]}</span>
      </Group>
    </div>
  )
}
