const getLeadInitialValues = () => {
  return {
    project: null,
    classification: "",
    propertyType: "1",
    subType: { id: 8, name: "Flats", value: 8 },
    source: null,
  }
}

export default getLeadInitialValues
