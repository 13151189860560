import { DateFormat, Group, Icon, Space } from "components/common"

import cx from "clsx"
import CardTag from "components/common/cardTag/cardTag"
import Recording from "components/common/recording/recording"
import { TitleDescription } from "components/custom"

import { FLagWithText } from "components/common/flagWithText/flagWithText"
import TimeFormat from "components/common/timeFormat/timeFormat"
import { joinBytItems } from "utils"
import styles from "./callsTab.module.less"

function CallDetailCard({ callDetail }) {
  return (
    <div>
      <Group template="3fr 2fr" gap="20px" className={cx(styles.outlook, "p-16")}>
        <Space direction="vertical" size={2}>
          <TitleDescription
            title={
              <Space size={8}>
                {callDetail?.callType ? (
                  <Icon
                    size="20px"
                    icon={callDetail.callType === "out" ? "IconCallOutgoing" : "IconCallIncoming"}
                    className="vAlignMiddle"
                  />
                ) : null}
                {callDetail?.userName || callDetail?.staff?.name}
                <CardTag
                  title={callDetail?.tagShort}
                  color={callDetail?.tagStyle?.bgColor}
                  tooltipTitle={callDetail?.tag?.tooltipTitle}
                />
              </Space>
            }
            gapSpace={4}
            description={joinBytItems(
              [
                `Via ${callDetail?.callBy}`,
                <DateFormat date={callDetail.date} />,
                <TimeFormat date={callDetail.time} isTimeOnlyStr />,
              ],
              <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
            )}
          />
        </Space>
        <Space size={2}>
          <TitleDescription
            title={
              <FLagWithText
                title={callDetail?.client?.country?.name}
                countryCode={callDetail?.client?.country?.loc_code}
                infoText={<span>{callDetail?.client?.name}</span>}
              />
            }
            gapSpace={4}
            description={"Client Name"}
          />
        </Space>
        <div>
          {(callDetail?.url || callDetail?.recording) && (
            <Recording id="call-detail-card-audio" recordingSrc={callDetail?.url || callDetail?.recording} />
          )}
        </div>
      </Group>
    </div>
  )
}

export default CallDetailCard
