import moment from "moment"
import { localisedValue } from "utils"
export const getAddTaskInitialValues = lead => {
  let preFilledObject = {
    task: null,
    subTask: null,
    subofSubTask: null,
    nextTask: null,
  }

  if (localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusParent" }) === 24) {
    preFilledObject.task = null
    preFilledObject.subTask = null
    preFilledObject.subofSubTask = null
    preFilledObject.nextTask = null
  } else if (localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusGrandParent" }) !== 0) {
    preFilledObject.task = localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusGrandParent" })
    preFilledObject.subTask = localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusParent" })
    preFilledObject.subofSubTask = localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusId" })
    preFilledObject.nextTask = localisedValue({ object: lead?.inquiryStatusDetails, key: "nextStatusId" })
  } else {
    preFilledObject.task = localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusParent" })
    preFilledObject.subTask = localisedValue({ object: lead?.inquiryStatusDetails, key: "taskStatusId" })
    preFilledObject.nextTask = localisedValue({ object: lead?.inquiryStatusDetails, key: "nextStatusId" })
  }

  return {
    task: preFilledObject.task,
    subTask: preFilledObject.subTask,
    subofSubTask: preFilledObject.subofSubTask,
    comment: null,
    nextTask: preFilledObject.nextTask !== 0 ? preFilledObject.nextTask : null,
    project: lead?.Project?.name,
    verifyMeeting: "",
    showOtpfield: null,
    leadsClassification:
      localisedValue({ object: lead?.inquiry?.InternalInquiryClassification, key: "id" })?.toString() || "",
    tsrClassification: localisedValue({ object: lead?.inquiry?.TSRClassification, key: "id" })?.toString() || "",
    completionDate: moment(),
    deadLine: moment(),
    attachment: [],
    dateSlot: moment(),
    timeSlot: [moment(), moment()],
  }
}
