import { List } from "antd"
import cx from "clsx"
import { useLazyGetProfileSummaryQuery } from "features/shared/api"
import { useEffect, useMemo } from "react"
import styles from "../index.module.less"
import { t } from "i18next"
import { useSelector } from "react-redux"

const UserSummary = ({ staffId }) => {
  const [getProfileSummary, { data, isFetching, error }] = useLazyGetProfileSummaryQuery()

  useEffect(() => {
    getProfileSummary({ staffId, load: "summary" }, true)
  }, [])

  const userSummaryData = useMemo(() => {
    if (data) {
      return [
        [t("Days since last won closed"), data?.summary?.daysSinceLastClosedWon || "-"],
        [t("Average Sale Cycle (Days)"), data?.summary?.averageSaleCycle || "-"],
        [t("MP : MD"), `${data?.summary?.meetingsPushed || 0} : ${data?.summary?.meetingsDone || 0}`],
        [t("Average Revenue/Unit (lifetime)"), data?.summary?.arpu || "-"],
        [t("Working Days this Month"), data?.summary?.activeWorkingDays || "-"],
        [t("Overdue"), data?.summary?.overdueCount || "-"],
      ]
    }

    return []
  }, [data])

  return (
    <div style={{ paddingTop: 12 }}>
      <List size="small" loading={isFetching} className={cx(styles.listingMain, "mbe-32")}>
        {userSummaryData?.map((e, index) => (
          <List.Item key={index}>
            <div className="gray-700">{e[0]}</div>
            <div style={{ fontWeight: 700 }}>{e[1].toString()}</div>
          </List.Item>
        ))}
      </List>
    </div>
  )
}

export default UserSummary
