import * as yup from "yup"
import { t } from "i18next"

const getClientProfileValidationSchema = () =>
  yup.object().shape({
    gender: yup.string().required(t("Gender is required")).nullable(),
    maritalStatus: yup.string().nullable(),
    children: yup.string().nullable(),
    nationality: yup.object().nullable(),
    clientOccupation: yup.string().required(t("Client Occupation is required")).nullable(),
    clientExist: yup.string().required(t("Client Existing is Required")).nullable(),
    intent: yup.string().nullable(),
    preferredFinancing: yup.string().nullable(),
    preferredCity: yup.string().nullable(),
    preferredRegion: yup.string().nullable(),
    preferredLocality: yup.string().nullable(),
    clientBudgetRange: yup.array().required(t("Client Budget Range is required")).nullable(),
    clientBuyingIntent: yup.string().required(t("Client Buying Intent is required")).nullable(),
    clientPropertyInterest: yup.string().required(t("Client Property Interest is required")).nullable(),
    clientAreaInterest: yup.string().required(t("Client Area Interest is required")).nullable(),
  })

export default getClientProfileValidationSchema
