import { t } from "i18next"
import React from "react"
import { Row } from "antd"
import { Sort } from "components/custom"

const items = () => [
  { id: "1", columnName: "id", sortOrder: "asc", label: t("Lead ID Ascending") },
  { id: "2", columnName: "id", sortOrder: "desc", label: t("Lead ID Descending") },
  { id: "3", columnName: "latestActivity", sortOrder: "desc", label: t("Last Task Date Latest") },
  { id: "4", columnName: "latestActivity", sortOrder: "asc", label: t("Last Task Date Oldest") },
  { id: "5", columnName: "nextStatusDeadline", sortOrder: "desc", label: t("Next Task Date Latest") },
  { id: "6", columnName: "nextStatusDeadline", sortOrder: "asc", label: t("Next Task Date Oldest") },
]

function LeadsFilters({ filter, onSort }) {
  const currentSort = items()?.filter(
    item => item?.columnName === filter?.sortBy && item?.sortOrder === filter?.sortOrder
  )[0]

  return (
    <Row className="mbe-12 pi-24" justify="end" style={{ gap: 8 }}>
      <Sort
        options={items()}
        sort={currentSort}
        onClickItem={item =>
          (filter?.sortBy !== item?.columnName || filter?.sortDirection !== item?.sortOrder) && onSort(item)
        }
      />
    </Row>
  )
}

export default LeadsFilters
