// import store from "../../store"
// export function fireEvent(eventParams, data = {}) {
//   const { page, interacted_from, event_name, content_type, ...rest } = eventParams
//   const user = store.getState().app?.user?.info

//   const dataLayer = {
//     event: "ga-events",
//     page: page,
//     event_label: event_name,
//     interacted_from,
//     content_type,
//     user_id: user.id,
//     user_email: user.email,
//     user_designation: user.UserDesignation.name,
//     user_team: user.team,
//     user_region: user.City.name,
//     source: "web",
//     ...rest,
//   }
//   console.log("===", window.dataLayer)

//   window && window.dataLayer && window.dataLayer.push(dataLayer)
// }

import ReactGA from "react-ga4"
import store from "store"

export function fireEvent(eventParams) {
  const user = store.getState().app?.user?.info
  const { page, interacted_from, event_name, content_type, client_id, lead_id, ...rest } = eventParams
  const gaData = {
    hitType: "event",
    eventAction: event_name,
    page,
    content_type,
    interacted_from,
    platform_source: "web",
    ...(client_id && { client_id: client_id.toString() }),
    ...(lead_id && { lead_id: lead_id.toString() }),
    user_id: user?.id + "",
    user_email: user?.email,
    user_designation: user?.UserDesignation?.name,
    user_team: user?.team,
    user_region: user?.City?.name,
    ...rest,
  }
  ReactGA.ga("send", gaData)
  console.log("GA Sent: ", gaData)
}

// export function getCurrentPage(pathname) {
//   let page
//   let interacted_from

//   const { path, param } = match(pathname)

//   const pathsToMatch = {
//     CLIENT_LISTING_PATH: { page: PAGES.client_listing_page, url: CLIENT_LISTING_PATH },
//     CLIENT_DETAIL_PATH: { page: PAGES.client_detail_page, param: ":clientId", url: CLIENT_DETAIL_PATH },
//   }

//   Object.keys(pathsToMatch).forEach(key => {
//     const pathParam = pathsToMatch[key].param
//     if(param){
//       pathsToMatch[key].url.replace()
//     }
//   })
// }
