import { InfoList } from "components/custom"
import { Skeleton } from "components/common"
import { useLazyGetClientBasicDetailsQuery } from "../../api"
import { useMemo } from "react"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"

export default function ClientInfoTooltip({ client }) {
  const { t } = useTranslation()
  const [fetchData, { data, isFetching }] = useLazyGetClientBasicDetailsQuery()
  const getClientInfoList = useMemo(() => {
    return [
      [t("Name"), localisedValue({ object: data, key: "name" })],
      [t("Email"), localisedValue({ object: data, key: "email" })],
      [t("City"), localisedValue({ object: data?.City, key: "name" })],
      [t("Locality"), localisedValue({ object: data, key: "locality" }) || "-"],
    ]
  }, [data])

  return (
    <InfoList
      loading={isFetching}
      list={getClientInfoList || []}
      onClick={e => {
        fetchData(client)
        e?.stopPropagation()
      }}
    />
  )
}
