import callRecordingTransformHelper from "./callRecordingTransformHelper"
import { convertQueryObjToString } from "utils"
import parentApi from "store/parentApi"

const callRecordingApi = parentApi.injectEndpoints({
  endpoints: build => ({
    // Get callRecording Details
    getCallRecords: build.query({
      query: ({ page, pageLimit, callType, ...params }) => {
        const param = { ...params }
        if (!!param.staffList) {
          let childIdentifierKey = "staffList_withoutChildren"
          param.staffList = encodeURIComponent(
            `{"teamIds":[],"userIds":[${decodeURIComponent(param.staffList)}],"includeAllChildren":${!params?.[
              childIdentifierKey
            ]}}`
          )
        }
        delete params["staffList_withoutChildren"]
        return `reports/callLogs?isStaffHierarchy=false&${convertQueryObjToString({
          ...param,
          ...(param.toDate && { toDate: encodeURIComponent(param?.toDate) }),
          ...(param.fromDate && { fromDate: encodeURIComponent(param?.fromDate) }),
          ...(callType !== "all" && callType && { callType }),
          pageOffset: page,
          pageSize: pageLimit,
          load: "analysis",
        })}`
      },
      transformResponse: response => callRecordingTransformHelper(response),
    }),

    getCallTags: build.query({
      query: () => `phoneCalls/callTags`,
      transformResponse: response => response.map(e => ({ ...e, value: e.id + "", label: e.title })),
    }),
  }),
})
export const { useLazyGetCallRecordsQuery, useGetCallRecordsQuery, useLazyGetCallTagsQuery } = callRecordingApi
