import { t } from "i18next"

export const classifications = () => ({
  1: { id: 1, name: t("Very hot"), order: 10, key: "very_hot", style: { color: "#B20E1A" } },
  2: { id: 2, name: t("Hot"), order: 20, key: "hot", style: { color: "#FF5F00" } },
  3: { id: 3, name: t("Moderate"), order: 30, key: "moderate", style: { color: "#E6B32C" } },
  4: { id: 4, name: t("Cold"), order: 40, key: "cold", style: { color: "#07C6D5" } },
  5: { id: 5, name: t("Very cold"), order: 50, key: "very_cold", style: { color: "#2259AD" } },
})

export const leadSharePurposeList = () => [
  { key: 1, label: t("Payment/Documentation"), value: "payment_documentation" },
  { key: 2, label: t("Site Visit"), value: "site_visit" },
  { key: 3, label: t("Mutual Agreement"), value: "mutual_agreement" },
]

export const bookingFormStatuses = {
  draft: { title: "Draft", color: "#A7A7A7", textColor: "#FFFFFF" },
  completed: { title: "Completed", color: "#5D9C59", textColor: "#FFFFFF" },
  signed: { title: "Signed", color: "#0091BA", textColor: "#FFFFFF" },
  cancelled: { title: "Cancelled", color: "#B46060", textColor: "#FFFFFF" },
}

export const STATIC_PRIVILEGES = {
  bookingForm: { CUADOC: [], CVADOC: [], CCBF: [], CAADOC: [], CSBF: [] },
  morningMeeting: {
    CAMM: [],
    CVD: [],
    CVDSF: [],
    CVDO: [],
    CVDLR: [],
    CVDQLT: [],
    CVDPT: [],
    CVDCALLR: [],
    CVDCLASSR: [],
    CVDPR: [],
    CVSD2: [],
    CANA: [],
    CAL: [],
    CARP: [],
    CVM: [],
    CCM: [],
  },
  staffProfile: { CVS: [], CUPS: [] },
}
