import * as yup from "yup"
import { t } from "i18next"

const getProposalValidationSchema = () =>
  yup.object().shape({
    leadId: yup.string().required(t("Lead Id is required")).nullable(),
    projects: yup.object().required(t("Project Id is required")).nullable(),
    cdp: yup.number().required("CDP is required").nullable(),
    unitNo: yup.object().required(t("Unit Number is required")).nullable(),
    discountPercentage: yup.number().required(t("Discount Percentage is required")),
    bookingPrice: yup.number().required(t("Booking Price is required")).nullable(),
    possessionAmount: yup.number().required(t("Possession Amount is required")).nullable(),
    uploadAttachments: yup.object().required(t("Attachment is required")).nullable(),
    startDate: yup.object().required(t("Start date is required")).nullable(),
    months: yup.number().required(t("Months are required")).nullable(),
    installmentFrequency: yup.string().required(t("Instalment Duration is required")).nullable(),
  })

export default getProposalValidationSchema
