import { Col, Row } from "antd"
import Link from "antd/lib/typography/Link"

export default function FileViewer(props) {
  const { attachments } = props
  const attachmentWithLinks = !!attachments
    ? attachments.map(item => (
        <Col>
          <Link href={item.url}>{item.fileName}</Link>
        </Col>
      ))
    : null

  return attachmentWithLinks
}
