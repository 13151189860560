export const privilegesMapper = {
  //dashboard
  can_view_dashboard: "CVD",
  can_view_lean_dashboard_sales: "CVLDRS",
  can_view_lean_dashboard_affiliates: "CVLDRA",

  //Staff
  can_add_staff: "CAS",
  can_edit_staff: "CES",
  can_view_staff: "CVS",
  can_view_teams: "CVTEAM",
  can_manage_rule_inclusions: "CMRI",
  can_view_weekly_roster: "CVWR",
  can_view_monthly_roster: "CVMR",
  can_edit_weekly_roster_status: "CEWRS",
  can_create_monthly_roster: "CCMR",
  can_edit_monthly_roster_status: "CEMRS",
  can_edit_weekly_roster_start_time_and_working_hours: "CEWRSTWH",

  //clients
  can_view_clients_module: "CVMCLIENT",
  can_search_clients: "CSC",
  can_view_call_logs: "CVCL",
  can_view_client_contact: "CVCC",
  can_send_email: "CSEC",
  can_change_assignee: "CCA",
  can_view_client_details: "CVCD",
  can_add_client: "CACLIENT",
  can_edit_client: "CECLIENT",
  can_view_client_allocaiton_log: "CVCAL",
  can_view_client_change_log: "CVCCL",
  can_view_client_project_summary: "CVCPS",
  can_view_client_staff_summary: "CVCSS",
  can_view_client_rating_summary: "CVCRS",
  can_view_client_type_summary: "CVCTSUM",
  can_add_client: "CACLIENT",
  can_view_deleted_client: "CVDC",
  can_assign_affiliate_to_existing_client: "CAATEC",
  can_assign_affiliate_to_new_client: "CAAAC",
  can_view_merge_clients: "CVMC",
  can_merge_clients: "CMC",
  can_archive_unarchive_clients: "CAUC",
  can_generate_proposal: "CGP",
  can_edit_and_remove_client_cells: "CERCC",

  //Management
  can_view_meeting: "CVM",
  can_create_meeting: "CCM",
  can_edit_meeting: "CEM",
  can_delete_meeting: "CDM",
  can_add_management_action_item: "CAMAI",
  can_edit_management_action_item: "CEMAI",
  can_delete_management_action_item: "CDMAI",
  can_complete_management_action_item: "CCMAI",
  can_view_kpi_management_report: "CVMKPIR",

  //productivity

  can_view_productivity_report: "CVPR",

  //leads
  can_view_leads_module: "CVMLEAD",
  can_view_leads_summary: "CVLS",
  can_search_leads: "CSL",
  can_view_deleted_leads: "CVDL",
  can_view_todo_list_leads: "CVTLL",
  can_add_task_on_lead: "CATOL", //same for add sale
  can_view_bd_calendar: "CVBDC",
  can_view_tsr_calendar: "CVTSRC",
  can_view_lead_source_data: "CVLSD",
  can_share_lead_with_sales: "CSLWS",
  can_share_lead_with_support: "CSLWSP",
  can_share_lead_with_support_manually: "CSLWSPM",
  can_stop_sharing_lead_with_sales: "CSSLWS",
  can_stop_sharing_lead_with_support: "CSSLWSUP",
  can_see_task_logs: "CSTL",
  can_reapply_deal_discount: "CRDD",
  can_add_lead: "CAL",
  can_add_inquiry_feedback: "CAIF",
  can_edit_share_commission: "CESC",
  can_update_qc_status: "CUQS",
  can_manage_lead_allocation_quota: "CMLAQ",
  can_view_tsr_section: "CVTS",
  can_change_sharing_lead_with_support: "CCSLWS",
  can_search_QC_leads: "CSQL",
  can_add_lead_pre_qualification_pool: "CALPQP",
  can_view_lead_pre_qualification_pools: "CVLPQP",
  can_edit_lead_pre_qualifcation_pools: "CULPQP",
  can_view_pipeline: "CVPIP",
  can_add_to_pipeline: "CATP",
  can_edit_pipeline: "CEPL",
  can_delete_From_Pipeline: "CDFP",
  can_add_sale_on_lead: "CASOL",
  can_apply_discount: "CADIS",
  can_mark_lead_a_closed_lost: "CMLACL",

  //staff
  can_view_staff: "CVS",
  can_add_staff: "CAS",
  can_password_reset_email: "CAS",
  can_edit_staff: "CES",
  can_change_line_manager: "CCUT",
  can_suspend_user: "CSS",
  can_block_user: "CIBS",
  can_view_interventions: "CVIS",
  can_add_interventions: "CAIS",
  can_unblock_user: "CUS",
  can_extend_deadline: "CEBD",
  can_activate_suspended_staff: "CASSTAFF",
  can_manually_shift_leads_in_suspend_user: "CMSHLISU",
  can_manually_shift_qc_leads_in_suspend_user: "CMSQLISU",
  can_manually_share_leads_in_suspend_user: "CMSLISU",
  can_manage_roles: "CMR",
  can_view_teams: "CVTEAM",
  can_manage_rule_inclusions: "CMRI",
  can_update_profile_signature_and_cnic: "CUPS",

  //projects
  can_view_projects: "CVP",
  can_view_auto_assignees: "CVAA",
  can_edit_projects: "CEPS",
  can_add_projects: "CAP",
  // "can_view_affiliate_assignees": "AVAA",
  can_view_staff_allocation: "CVSA",
  can_view_project_allocation_log: "CVPAL",
  can_view_region_wise_product_summary: "CVRWPS",
  can_allocate_user_to_project: "CAUTP",
  can_add_project_location: "CAPL",

  //affiliate
  can_search_affiliate: "CSA",
  can_view_todo_list: "CVTODOLA",
  can_add_affiliate: "CAA",
  can_view_potential_affiliates: "CVPA",
  can_view_verified_unallocated_affiliates: "CVUVAL",
  can_share_affiliate_with_support: "CSAWS",
  can_share_affiliate_with_support_manually: "CSAWSM",
  can_view_affiliate_call_logs: "CSCL",
  can_view_affiliate_task_logs: "CVTLAT",
  can_change_affiliate_assignee: "CCAA",
  can_stop_share_affiliate_with_support: "CSSAWS",
  can_view_active_packages: "CVAP",
  can_view_affiliate_commission: "CVAC",
  can_edit_affiliate_commission: "CEAC",
  can_search_affiliate_task: "CSAT",
  can_view_affiliate_detail: "CVAD",
  can_view_affiliate_logs: "CVAAL",
  can_view_affiliate_sharing_logs: "CVASL",
  can_view_affiliate_edit_logs: "CVAEL",
  can_add_task_on_affiliate: "CATASKOA",
  can_edit_affiliate: "CEA",
  can_view_new_affiliate_in_verification: "CVNAIV",
  can_view_pending_affiliate_in_verification: "CVPAIV",
  can_view_ops_rejected_affiliate_in_verification: "CVORAIV",
  can_view_verified_affiliate_in_verification: "CVVAIV",
  can_view_rejected_affiliate_in_verification: "CVRAIV",
  can_ops_verify_potential_affiliate: "COVPA",
  can_perform_affiliate_actions: "CPPAA",
  can_add_task_on_affiliate: "CATASKOA",
  can_add_task_on_potential_affiliate: "CATPA",
  can_edit_potential_affiliate: "CEPA",

  //inventory
  can_view_primary_inventory: "CVI",
  can_view_inventory: "CVI",
  can_add_inventory: "CAI",
  can_import_listing_and_listing_features: "CILALF",
  can_view_secondary_inventory: "CVSCI",
  can_add_inventory_notes: "CAIN",
  can_change_inventory_state: "CCIS",
  can_export_inventory: "CEXI",
  can_edit_inventory: "CEI",
  can_delete_inventory_notes: "CDIN",
  view_reservation_queue_detail: "CVRQD",
  can_access_reservation_queue: "CARQ",
  can_view_unit_detail_page: "CVUDP",
  can_view_unit_logs_and_notes: "CVULN",

  //accounts
  can_view_accounts: "CVADOC",
  can_view_payments: "CVEP",
  can_edit_payments: "CEP",
  can_edit_payments: "CEP",
  can_add_contract: "CAC",
  can_edit_contract: "CEC",
  can_view_incentive: "CVINCNTV",
  can_upload_incentive: "CUINCNTV",
  can_view_penalty_listing: "CVPPL",
  can_process_payment_refund: "CPPR",
  can_approve_payment_in_review: "CAPIRSS",
  can_approve_payment_in_approval: "CAPIASS",
  can_approve_payment_in_verification: "CAPIVRSS",
  can_approve_payment_in_validation: "CAPIVSS",
  can_approve_payment_in_audit: "CAPIAUSS",
  can_approve_payment_in_dispatch: "CAPIDS",
  can_sign_booking_form: "CSBF",
  can_add_document: "CAD",
  can_cancel_booking_form: "CCBF",
  can_add_payment: "CAPAY",
  can_cancel_contract: "CCC",
  can_sign_contract: "CSIGNC",
  can_recalibrate_contract: "CRC",
  can_view_contract_snapshot: "CVCS",
  can_recognise_revenue: "CRR",
  can_remove_recognised_revenue: "CRRR",

  can_view_revenue_recognition_panel: "CVRRP",
  can_access_billing_module: "CABM",

  //contracts
  can_view_contracts_module: "CVC",
  can_view_contract_detail_page: "CVCDP",
  can_view_contract_snapshot: "CVCS",
  can_update_contract_snapshot: "CECS",
  can_view_booking_information: "CVBI",
  can_edit_booking_price: "CECDP",
  can_recognise_revenue: "CRR",
  can_remove_recognised_revenue: "CRRR",
  can_view_recognition_panel: "CVRRP",
  can_cancel_contract: "CCC",
  can_sign_contract: "CSIGNC",
  can_export_contracts: "CEXC",
  can_recalibrate_contract: "CRC",

  //targets
  can_view_revenue_targets: "CVST",
  can_view_project_units_targets: "CVPUT",
  can_view_soft_kpi_targets: "CVSKT",
  can_edit_assign_soft_kpi_targets: "CUSKT",
  can_view_project_unit_targets: "CVPUT",
  can_assign_sales_targets: "CAST",
  can_assign_project_unit_targets: "CAPUT",
  can_export_revenue_targets: "CEXRT",
  can_export_pu_targets: "CEXPUT",

  //reports
  can_view_reports: "CVAR",
  can_view_activity_report: "CVAR",
  can_view_affiliate_activity_report: "CVAAR",
  can_view_sales_kpi_report: "CVSKPIR",
  can_view_support_kpi_report: "CVSUKPIR",
  can_view_affiliate_kpi_report: "CVAKPIR",
  can_view_agency_leads_report: "CVALR",
  can_view_lead_allocation_score_report: "CVLASR",
  can_view_staff_movement_report: "CVSMR",
  can_view_mobile_app_status_report: "CVMASR",
  can_view_call_recording_report: "CVCRR",
  can_view_pl_report: "CVPLR",
  can_view_revenue_performance_report: "CVRPR",
  can_export_revenue_performance_report: "CERPR",
  can_view_ai_analysis_report: "CVAIAR",

  // policy
  can_view_repository_policy_summary: "CVRPS",
  can_add_repository_policy: "CARP",
  can_delete_repository_policy: "CDRP",
  can_view_repository_policy_view_log: "CVRPVL",
  //document
  can_view_accounts_document: "CVADOC",
  can_add_account_document: "CAADOC",
  can_update_accounts_document: "CUADOC",
  can_cancel_booking_form: "CCBF",
  can_sign_booking_form: "CSBF",

  can_view_notifications: "CVN",
  can_view_alerts: "CVA",
  can_view_sale_dispute: "CVSD",
  can_add_or_reply_dispute: "CAORTSD",
  can_resolve_dispute: "CRSD",

  //agency settings
  can_update_agency_settings: "CUAS",

  //Calendar
  can_view_bd_calendar: "CVBDC",
  can_view_event: "CVE",
  can_add_event: "CAE",
  can_edit_event: "CUE",

  // Targets
  can_view_sale_targets: "CVST",
  can_view_project_unit_targets: "CVPUT",
  can_update_soft_kpi_targets: "CUSKT",
  can_view_targets: "CVT_V2",
  can_update_targets: "CUT_V2",
  can_add_targets: "CAT_V2",

  //AI
  can_view_odyssey_bot: "CVOB",
  can_generate_sales_pitch: "CGSP",

  //Invoices
  can_access_billing_module: "CABM",

  //Incentives
  can_view_incentives_module: "CVIM",
  can_view_incentive_configuration_panel: "CVICP",
  can_create_penalty_policy: "CCPP",
  can_edit_penalty_policy: "CEPP",
  can_view_bonus_listing: "CVBPL",
  can_create_bonus_policy: "CCBP",
  can_edit_bonus_policy: "CEBP",
  can_edit_staff_incentive: "CESI",

  //LMS
  can_search_prequalification_lead: "CSPQL",
  can_fetch_or_update_lead: "CUPQL",
  can_view_lead_details: "CVPQLD",
  can_add_prequalification_lead: "CAPQL",
  //Campaign
  can_view_campaign: "CVMM",
}

const obj = {}

Object.keys(privilegesMapper).forEach(e => {
  obj[e] = e
})

export const createPrivilegeObject = privs => {
  const defaultObj = { all: false, self: false, team: false, default: true }
  const privileges = {}
  if (Array.isArray(privs)) {
    privs.forEach(e => {
      privileges[e] = defaultObj
    })
    return privileges
  } else {
    Object.keys(privs).forEach(privilege => {
      const obj = { ...defaultObj }
      privs[privilege].forEach(item => {
        if (obj[item] !== undefined) {
          obj[item] = true
        }
      })
      privileges[privilege] = obj
    })
    return privileges
  }
}
export const PERMISSION_KEYS = obj
