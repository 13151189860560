import { localisedValue } from "utils"

const flatStaffHierarchyTransformer = response => {
  let map = {}

  response?.forEach(item => {
    let teamName = localisedValue({ object: item, key: "teamName" })
    if (map[teamName]) {
      map[teamName].options.push({ label: localisedValue({ object: item, key: "name" }), value: item?.userId, ...item })
    } else {
      map[teamName] = {
        options: [{ label: localisedValue({ object: item, key: "name" }), value: item?.userId, ...item }],
      }
    }
  })

  return Object.keys(map).map(item => {
    return {
      label: item,
      options: map[item]?.options,
    }
  })
}

export default flatStaffHierarchyTransformer
