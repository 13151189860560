const projectBifurcatedUnitsTransformer = response => {
  let getAvailableUnits = 0
  let getTotalUnits = 0
  let apartments = 0
  let shops = 0

  response?.forEach(e => {
    getAvailableUnits += e?.availableUnits
    getTotalUnits += e?.totalUnits
    apartments += e?.unitType !== "offices" && e?.unitType !== "shops" ? e?.availableUnits : 0
    shops += e?.unitType === "shops" ? 1 : 0
  })

  return {
    ...response,
    getAvailableUnits,
    getTotalUnits,
    apartments,
    shops,
  }
}

export default projectBifurcatedUnitsTransformer
