import { Button, Group, Legend, PhoneInput, RadioButtons, SelectInput, TextInput } from "components/common"
import { Col, Form, Row } from "antd"
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react"
import { getCountryList, getStoreCacheData } from "utils"
import { useForm, useWatch } from "antd/lib/form/Form"
import {
  useLazyGetCheckEmailQuery,
  useLazyGetCheckPhoneNumberQuery,
  useLazyGetCitiesQuery,
  useLazyGetClientBuyingIntentQuery,
  useLazyGetClientRatingQuery,
  useLazyGetClientTypeQuery,
  useLazyGetCountriesQuery,
  useLazyGetLocalityQuery,
  useLazyGetOccupationQuery,
  useLazyGetUsersQuery,
} from "features/shared/api"

import AffiliateSection from "./affiliateSection"
import _ from "lodash"
import { getClientInitialValues } from "../clientForm/clientInitialValues"
import getClientValidationSchema from "../clientForm/clientValidationSchema"
import getPrivilege from "utils/privileges/privileges"
import { isValidPhoneNumber } from "react-phone-number-input"
import { schemaRules } from "features/shared/utils"
import store from "store"
import theme from "utils/themeVars"
import { useSelector } from "react-redux"
import { genderList, clientClassOptions } from "constants/list"
import { useTranslation } from "react-i18next"

const AddClientForm = forwardRef(({ preserve = false, onSubmit }, ref) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [getCountries, { data: countries, isFetching: isFetchCountry }] = useLazyGetCountriesQuery()
  const [getCities, { data: cities, isFetching: isFetchingCities }] = useLazyGetCitiesQuery()
  const [getLocalities, { data: localities, isFetching: isFetchingLocality }] = useLazyGetLocalityQuery()
  const [getOccupations, { data: occupations, isFetching: isFetchingOccupations }] = useLazyGetOccupationQuery()
  const [getClientBuyingIntents, { data: clientBuyingIntents }] = useLazyGetClientBuyingIntentQuery()
  const [getClientRatings, { data: clientRatings }] = useLazyGetClientRatingQuery()
  const [getClientTypes, { data: clientTypes }] = useLazyGetClientTypeQuery()
  const [getUsers, { data: usersList, isFetching: isFetchingUsers }] = useLazyGetUsersQuery()
  const [checkPhone, { data: phoneData, isError: phoneError, isFetching: phoneValidationFetching }] =
    useLazyGetCheckPhoneNumberQuery()
  const [checkEmail, { data: emailData, isError: emailError, isFetching: isEmailCheckFetching }] =
    useLazyGetCheckEmailQuery()

  useImperativeHandle(
    ref,
    () => ({
      ...form,
    }),
    []
  )
  const countryWatch = useWatch("country", form)
  const cityWatch = useWatch("city", form)
  const clientClassWatch = useWatch("clientClass", form)
  const userPrivileges = useSelector(state => state.app.privileges)
  let addClientRules = schemaRules(getClientValidationSchema(false))

  const debounceEmailValidator = useCallback(
    _.debounce(value => {
      const response = checkEmail(value).unwrap()
      response.then(e => {
        form.validateFields(["email"])
      })
    }, 500),
    []
  )

  useEffect(() => {
    !countries && getCountries()
    !occupations && getOccupations()
    !clientBuyingIntents && getClientBuyingIntents()
    !clientRatings && getClientRatings()
    !clientTypes && getClientTypes()
    !usersList && getUsers({ teamType: "sales", module: "clients", subModule: "changeLeadAllocation" })
  }, [])

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="client-form"
        initialValues={getClientInitialValues()}
        onFinish={values => {
          onSubmit && onSubmit(values)
        }}
        scrollToFirstError
        preserve={preserve}
      >
        <Legend title={t("Client Information")} />
        <Form.List name="mobileNumber" rules={addClientRules}>
          {(fields, { add, remove }, { errors }) => (
            <>
              <Row gutter={[64, 0]}>
                {fields.map((field, index) => {
                  return (
                    <Col span={12} key={field.key}>
                      <Form.Item
                        required
                        hasFeedback
                        name={field.name}
                        validateTrigger={["onBlur"]}
                        {...field}
                        rules={[
                          {
                            validator: async (_, value) => {
                              const phoneApiData = getStoreCacheData("getCheckPhoneNumber", { cell: value })
                              if (!value) return Promise.reject(t("Phone number is required"))
                              if (
                                !phoneError &&
                                !!phoneApiData &&
                                phoneApiData?.exists &&
                                value == phoneApiData?.data.number
                              )
                                return Promise.reject(t("Phone number already exists"))
                              return isValidPhoneNumber(value)
                                ? Promise.resolve()
                                : Promise.reject(t("Invalid phone number"))
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          label={t("Mobile Number")}
                          onChange={e => {
                            form.setFields([{ name: ["mobileNumber", field.name], errors: [""] }])
                          }}
                          onBlur={e => {
                            const input = e.target.value
                            checkPhone({ cell: input.split(" ").join("") })
                              .unwrap()
                              .then(e => {
                                form.validateFields([["mobileNumber", field.name]])
                              })
                          }}
                          component={
                            index > 0 && (
                              <Button
                                type="link"
                                icon="FiTrash2"
                                className="p-0"
                                onClick={() => remove(field.name)}
                                size="small"
                                style={{ color: theme["error-color"], height: "auto" }}
                              >
                                {t("Remove")}
                              </Button>
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )
                })}
                {fields.length < 5 && (
                  <Col span={12}>
                    <Form.Item label={t("Add Another Number")}>
                      <Button
                        color={theme["gray600"]}
                        bordered
                        block
                        onClick={() => add()}
                        icon="MdAdd"
                        text={t("Add")}
                        style={{ borderStyle: "dashed", height: 48 }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Form.List>

        <Legend title={t("Client Details")} />
        <Form.Item name="clientClass">
          <RadioButtons
            options={clientClassOptions()
              .map(e =>
                !getPrivilege("can_assign_affiliate_to_new_client", userPrivileges) && e.value == "affiliate"
                  ? { ...e, disabled: true }
                  : e
              )}
          />
        </Form.Item>
        <Group className="mbe-24" template="repeat(2, 1fr)" gap="16px 64px">
          {clientClassWatch === "affiliate" && (
            <AffiliateSection rules={addClientRules} form={form} subModuleName="addClient" />
          )}
          <Form.Item name="name" label={t("Name")} required rules={addClientRules}>
            <TextInput placeholder={t("Enter name")} />
          </Form.Item>

          <Form.Item
            name="email"
            label={t("Email")}
            required
            hasFeedback
            rules={addClientRules.concat({
              validator: async (_, value) => {
                if (emailData?.exists) {
                  return Promise.reject(t("Email already exists"))
                } else {
                  return Promise.resolve()
                }
              },
            })}
          >
            <TextInput
              placeholder={t("Enter email")}
              onChange={e => {
                debounceEmailValidator({
                  email: e.target.value,
                })
              }}
            />
          </Form.Item>

          {clientClassWatch !== "affiliate" && (
            <Form.Item name="allocatedTo" label={t("Allocated To")} required rules={addClientRules}>
              <SelectInput
                placeholder="Select"
                options={usersList}
                loading={isFetchingUsers}
                onChange={(_value, option) => {
                  form.setFieldsValue({ allocatedTo: option })
                }}
              />
            </Form.Item>
          )}

          <Form.Item name="gender" label={t("Gender")} required rules={addClientRules}>
            <RadioButtons
              getKey={item => item.id}
              getValue={item => item.slug}
              options={genderList()}
            />
          </Form.Item>

          <Form.Item name="country" label={t("Country")} required rules={addClientRules}>
            <SelectInput
              placeholder={t("Please select a Country")}
              options={getCountryList(countries)}
              loading={isFetchCountry}
              onChange={(_value, option) => {
                form.setFieldsValue({ country: option, city: null })
                getCities(option.id)
              }}
            />
          </Form.Item>

          <Form.Item name="city" label={t("City")} required rules={addClientRules}>
            <SelectInput
              showSearch
              placeholder={t("Please select a City")}
              options={cities}
              disabled={!!countryWatch ? false : true}
              onChange={(value, option) => {
                form.setFieldsValue({ city: option, locality: null })
                getLocalities(option.id)
              }}
            />
          </Form.Item>

          <Form.Item shouldUpdate={true} noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue("city") && localities?.length !== 0 && !isFetchingLocality) {
                return (
                  <Form.Item name="locality" label="Locality" rules={addClientRules}>
                    <SelectInput
                      showSearch
                      placeholder="Select locality"
                      options={localities}
                      disabled={!!cityWatch ? false : true}
                      onChange={(value, option) => {
                        form.setFieldsValue({ locality: option })
                      }}
                    />
                  </Form.Item>
                )
              }
            }}
          </Form.Item>
        </Group>

        <Legend title={t("Client Profiling")} />

        <Group className="mbe-24" template="repeat(2, 1fr)" gap="16px 64px">
          <Form.Item name="clientType" label={t("Client Type")} required rules={addClientRules}>
            <RadioButtons options={clientTypes} />
          </Form.Item>

          <Form.Item
            name="clientOccupation"
            label={t("Client Occupation")}
            rules={addClientRules}
            placeholder={t("Select Occupation")}
          >
            <SelectInput
              placeholder={t("Select occupation")}
              options={occupations}
              loading={isFetchingOccupations}
              getOptionValue={option => option.name}
              onChange={(_value, option) => {
                form.setFieldsValue({ clientOccupation: option })
              }}
            />
          </Form.Item>

          <Form.Item name="clientRating" label={t("Client Rating")} required rules={addClientRules}>
            <RadioButtons options={clientRatings} />
          </Form.Item>

          <Form.Item name="clientBuyingIntent" label={t("Buying Intent")} required rules={addClientRules}>
            <RadioButtons options={clientBuyingIntents} />
          </Form.Item>
        </Group>
      </Form>
    </div>
  )
})

export default AddClientForm
