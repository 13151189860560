import { Form, Radio } from "antd"
import { Button, DetailBar, Group, Modal, notification, Tag, Text, Title } from "components/common"
import { useCallback, useMemo } from "react"
import { IconText } from "components/custom"
import { useLazyGetLeadsQuery } from "features/clients/detail/api"
import { schemaRules } from "features/shared/utils"
import styles from "./index.module.less"
import getReserveUnitValidationSchema from "./reservationQueueFormSchema"
import {
  useAddReservationQueueMutation,
  useDeleteReservationQueueMutation,
  useLazyLeadValidationForReservationQueueQuery,
} from "../../api"
import { useWatch } from "antd/lib/form/Form"
import { localisedValue } from "../../../../utils"
import theme from "utils/themeVars"
import { dateTimeFunction } from "../../../../utils/date"
import { t } from "i18next"
import { DATE_FORMAT } from "constants"
import Checkbox from "../../../../components/common/checkbox/checkbox"
import { useQueryUpdate } from "../../../../hooks"
import InputNumber from "../../../../components/common/inputNumber/inputNumber"

export default function ReservationQueueForm({ formRef, from, listingReservationQueues }) {
  let inventoryInfo = [{
    value: localisedValue({ key: "name", object: from?.inventory?.Project }),
    label: t("Project"),
  },
    { value: localisedValue({ key: "unitNumber", object: from?.inventory }), label: t("Unit Number") },
    { value: from?.inventory?.ListingStatus?.name, label: t("Unit Status") },
  ]
  let deleteInventoryInfo = useMemo(() => [...inventoryInfo,
    {
      value: listingReservationQueues?.InternalInquiry?.Client?.name,
      subText: `${t("Lead Id")}: ${listingReservationQueues?.reservationQueuedAgainstLeadId}`,
      label: t("Queued For"),
    },
    {
      value: listingReservationQueues?.AddedBy?.name,
      subText: `${t("Emp Id")}: ${listingReservationQueues?.AddedBy?.employeeId}`,
      label: t("Queued By"),
    },
    {
      value: listingReservationQueues?.createdAt ? dateTimeFunction({
        date: listingReservationQueues?.createdAt,
        format: DATE_FORMAT,
      }) : "-",
      label: t("Queued On"),
    },
  ], [listingReservationQueues])

  const [getLeads, { data: leads, isFetching: isFetchingLeads, isErrorSearchLeads, errorSearchLead }] =
    useLazyGetLeadsQuery()
  const [addReservationQueue, { isLoading, error, reset: resetReserveQueueForm }] = useAddReservationQueueMutation()
  const [deleteReservationQueue, { isLoading: loading, error: deletionError }] = useDeleteReservationQueueMutation()

  const [leadValidationForReservationQueue, { data, isFetching: isFetching, error: isError }] =
    useLazyLeadValidationForReservationQueueQuery()
  const [updateQueryData] = useQueryUpdate()
  const [form] = Form.useForm()
  const rules = schemaRules(getReserveUnitValidationSchema())
  const { setFieldsValue } = form
  const termCondition = useWatch("reservationQueueTermsAndConditions", form)
  const leadId = Form.useWatch("leadId", form)


  const onModalVisible = useCallback(() => {
    setFieldsValue({
      leadId: listingReservationQueues?.reservationQueuedAgainstLeadId,
      reservationQueueTermsAndConditions: false,
    })
  }, [listingReservationQueues])

  const onSubmit = async values => {
    if (listingReservationQueues) {
      await deleteReservationQueue({
        queueId: listingReservationQueues?.id,
      })
        .unwrap()
        .then((data) => {
          updateQueryData("leadValidationForReservationQueue", draft => null)
          updateQueryData("getLeads", draft => null)
          formRef?.current?.setVisible(false)
          notification.success({ message: "Reservation Queue is deleted" })
        })
        .catch(error => {
          notification.error({ message: error?.message })
        })
    } else {
      await addReservationQueue({
        listingId: from?.listingId,
        reservationQueuedAgainstLeadId: leadId,
      })
        .unwrap()
        .then((data) => {
          updateQueryData("leadValidationForReservationQueue", draft => null)
          updateQueryData("getLeads", draft => null)
          formRef?.current?.setVisible(false)
          notification.success({ message: "Reservation Queue is Added" })
        })
        .catch(error => {
          notification.error({ message: error?.message })
        })
    }
  }

  const onSearch = useCallback(async () => {
    let response = await leadValidationForReservationQueue({ leadId: leadId })
    if (response.error) {
      notification.error({ message: response.error?.message })
    } else {
      return data
    }
    getLeads({ id: leadId })
  }, [leadId])

  return (
    <div>
      <Modal
        maskClosable={false}
        title={listingReservationQueues ? t("Delete Reservation Queue") : t("Add Reservation Queue")}
        ref={formRef}
        okText={listingReservationQueues ? t("Delete") : t("Reserve")}
        width={880}
        cancelText={t("Cancel")}
        loading={isLoading || loading}
        onOk={form.submit}
        footerAlert={error}
        destroyOnClose={true}
        onOkProps={listingReservationQueues ? { danger: "danger" } : {}}
        onModalVisible={onModalVisible}
        onCancel={() => {
          resetReserveQueueForm()
          updateQueryData("leadValidationForReservationQueue", draft => null)
          updateQueryData("getLeads", draft => null)
          formRef?.current?.setVisible(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="reserveQueueForm"
          scrollToFirstError
          preserve={true}
          onFinish={onSubmit}
          disabled={isFetchingLeads}
        >

          <Title type="secondary" level={4}>{t("Inventory Details")}</Title>

          <DetailBar
            detailData={listingReservationQueues ? deleteInventoryInfo : inventoryInfo}
            detailContainerClass={styles.detailbarContainer}
            className="mbe-16"
          />

          {!listingReservationQueues &&
            <>
              <>
                <Group template="315px 1fr" gap="6px 24px" style={{ alignItems: "flex-start" }}>
                  <Form.Item
                    name="leadId"
                    label={t("Lead ID")}
                    required
                    validateStatus={isErrorSearchLeads ? "error" : undefined}
                    rules={rules}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      controls={false}
                      value={leadId}
                      disabled={listingReservationQueues}
                      placeholder={t("Search By Lead ID")}
                      onChange={value => setFieldsValue({ leadId: value })}
                    />
                    <div>
                      {(isErrorSearchLeads || leads?.data?.length === 0) && (
                        <IconText
                          icon="MdInfoOutline"
                          iconProps={{ color: "red", size: "1em" }}
                          className="mbe-12"
                          textType={"danger"}
                          title={
                            isErrorSearchLeads
                              ? errorSearchLead?.message
                              : leads?.data?.length === 0
                              ? t("Leads Not Found")
                              : ""
                          }
                        />


                      )}
                    </div>
                  </Form.Item>
                  {!listingReservationQueues && <div style={{ top: "30px", position: "relative" }}>
                    <Button
                      loading={isFetchingLeads}
                      type="primary"
                      size="large"
                      onClick={onSearch}
                      icon="FiSearch"
                      text={t("Search")}
                      style={{ height: 48 }}
                      disabled={leadId ? false : true}
                    />
                  </div>}
                </Group>
                {!isErrorSearchLeads && !!leadId && !!data?.isValid && <Radio.Group
                  value={data?.clientName}
                  className="w-100"
                  style={{marginBottom: "14px"}}
                >
                  <Group template="repeat(3,1fr)" gap="16px">
                    <Radio className={styles.cardRadio} value={data?.clientName} key={data?.clientName}>
                      {data?.leadId && <Text type="secondaryLight" block>
                        ID: {data?.leadId}
                      </Text>}
                      <Text>{data?.clientName}</Text>
                    </Radio>
                  </Group>
                </Radio.Group>}
              </>


              <Text className={"bold"} >{t("Terms and Conditions")}</Text>

              <ul>
                <li>{t("Ensure you have a Complete down payment for this inventory")}</li>
                <li>{t("Once the current contract expires, you need to add a complete down payment task against the lead for which you have requested a reservation.")}</li>
                <li>{t("You will be notified once the unit is available and failure to add a complete down payment within 24 hours will make the unit open for all.")}</li>
                <li>{t("If you do not add a complete down payment or your added payment gets rejected you will be fined PKR 20,000.")}</li>
              </ul>
              <Form.Item
                name={"reservationQueueTermsAndConditions"}
                label={
                  <Checkbox
                    checked={termCondition}
                    className={"bold"}
                    onClick={e => setFieldsValue({ reservationQueueTermsAndConditions: !termCondition })}
                  >
                    {t("I acknowledge that i have read and agreed to all the terms & conditions listed above.")}
                  </Checkbox>
                }
                rules={rules}>
              </Form.Item>
            </>
          }
        </Form>
      </Modal>

    </div>
  )
}
