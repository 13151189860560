import React from "react"
import { DataTable } from "components/common"
import styles from "../index.module.less"
import { List } from "antd"
import cx from "clsx"
import { t } from "i18next"
import { localisedValue } from "utils"
import { string } from "yup/lib/locale"

const GeneralInformation = ({ general }) => {
  const generalInformationData = [
    [t("Employee ID"), localisedValue({ object: general, key: "employeeId" }) || "-"],
    [t("Staff ID"), String(general?.staffId || "-")],
    [t("Phone Number"), general?.phoneNumber || "-"],
    [t("Email"), localisedValue({ object: general, key: "email" }) || "-"],
    [t("Line Manager"), localisedValue({ object: general, key: "lineManager" }) || "-"],
    [t("LM Phone"), localisedValue({ object: general, key: "lineManagerPhoneNumber" }) || "-"],
    [t("Director"), localisedValue({ object: general, key: "regionalDirector" }) || "-"],
  ]
  return (
    <div style={{ paddingTop: 12 }}>
      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {generalInformationData?.map(e => (
          <List.Item key={e[0]}>
            <div className="gray-700">{e[0]}</div>
            {e[1].startsWith("+") ? (
              <div className="ddPhone" style={{ fontWeight: 700 }}>
                {e[1]}
              </div>
            ) : (
              <div style={{ fontWeight: 700 }}>{e[1]}</div>
            )}
          </List.Item>
        ))}
      </List>
    </div>
  )
}

export default GeneralInformation
