export const NAME_SPACE = ["en", "ar"]

export const LOGIN_PATH = `/login`
export const RESET_PASSWORD_PATH = "/auth/reset-password"

export const CLIENT_LISTING_PATH = "/clients"
export const AFFILIATE_DETAIL_PATH = "/affiliate-detail/:affiliateId"
export const CLIENT_DETAIL_PATH = "/clients/:clientId"
export const OLD_CLIENT_LISTING_PATH = "/clients/search/v2?routeKey=searchClientsV2"
export const OLD_DASHBOARD_PATH = "/dashboard/sales?routeKey=salesDashboard"
export const DASHBOARD_PATH = "/dashboard-new"
export const LEADS_PATH = "/leads/search/v2?routeKey=leadSearchV2"
export const OLD_TARGETS_PATH = "/targets/view_targets?routeKey=targetsLists"
export const PROJECTS_PATH = "/inventory/projects/cpml-projects?routeKey=projectLists"
export const AFFILIATES_PATH = "/affiliates/dashboard?routeKey=affiliatesDashboard"
export const INVENTORY_PATH = "/inventory/search-inventory?routeKey=searchProjectInventory"
export const OLD_PAYMENTS_PATH = "/accounts/billable-projects?routeKey=billableProjects"
export const BOOKING_FORM_LISTING = "/booking-forms"
export const EDIT_BOOKING_FORM = "/booking-forms/:bookingFormId"
export const TARGETS_PATH = "/staff/targets/view_targets?routeKey=targetsLists"
export const REPORTS_PATH = "/reports/sales-funnel-report?routeKey=salesFunnelReport"
export const POLICIES_PATH = "/repository/policies/cpml_all?routeKey=cpml_all&policyTypeId=3"
export const DOCUMENTATION_PATH = "/documentation/search-documentation?routeKey=searchDocumentation"
export const LEADS_SHARED_LISTING_PATH = "/shared-leads"
export const MANAGEMENT_PATH = "/management"
export const MANAGEMENT_MEETINGS_PATH = "/management/:meetingId"
export const SALES_KPI_REPORT_PATH = "/reports/sales-kpi"
export const SALES_KPI_REPORT_DEFAULT_LINK = "/reports/sales-kpi?staffType=aggregated"
export const AFFILIATE_KPI_REPORT_PATH = "/reports/affiliates-kpi"
export const TSR_KPI_REPORT_PATH = "/reports/telesales-kpi"
export const REVENUE_REPORT_PATH = "/reports/revenues"
export const KPI_MANAGEMENT_REPORT_PATH = "/reports/management-kpi"
export const PRODUCTIVITY_REPORT_PATH = "/reports/productivity"
export const AI_CALL_ANALYSIS_REPORT_PATH = "/reports/ai-call-analysis"
export const LEADS_LISTING_PATH = "/leads"
export const TODOS_LISTING_PATH = "/todos"
export const TASKS_LISTING_PATH = "/tasks"
export const SALES_DISPUTE_PATH = "/sales-dispute"
export const ADD_SALE_DISPUTE_PATH = "/sales-dispute/add"
export const SALES_DISPUTE_DETAIL_PATH = "/sales-dispute/:disputeId"
export const INCENTIVES_DETAILS_PATH = "/incentives/:date"
export const ACTION_ITEMS_PATH = "/action-items"
export const STAFF_PATH = "/staff"
export const ADD_STAFF_PATH = "/staff/add"
export const EDIT_STAFF_PATH = "/staff/:staffId"
export const CALL_RECORDING_PATH = "/call-recordings"
export const AGENCY_SETTINGS_PATH = "/settings/agency-settings"
export const TEAMS_PATH = "/teams"
export const ROSTER_PATH = "/roster"
export const STAFF_TARGETS_SETTING_PATH = "/staff-targets/targets-setting"
export const STAFF_TARGETS_PATH = "/staff-targets"
export const CALENDAR_PATH = "/calendar"
export const ROLES_AND_PRIVILEGES_PATH = "/roles"
export const ROLES_AND_PRIVILEGES_ADD_PATH = "/roles/add"
export const ROLES_AND_PRIVILEGES_EDIT_PATH = "/roles/edit/:roleId"
export const RULE_INCLUSIONS_PATH = "/rule-inclusions"
export const PROJECTS_LISTING_PATH = "/projects"
export const PROJECTS_ADD_PATH = "/projects/add"
export const PROJECTS_EDIT_PATH = "/projects/edit/:projectId"
export const PROJECTS_DETAILS_PATH = "/projects/:projectId"
export const TSR_LISTING_PATH = "/telesales"
export const QUALITY_CONTROL_PATH = "/lead-quality-control"
export const SECONDARY_INVENTORY_LISTING_PATH = "/secondary-inventory"
export const INVENTORY_LISTING_PATH = "/inventory"
export const POLICY_PATH = "/policies"
export const PAYMENT_PATH = "/payments"
export const CONTRACT_PATH = "/contracts"
export const REFUNDS_LISTING_PATH = "/refunds"
export const ADD_INVENTORY_PATH = "/inventory/add"
export const EDIT_INVENTORY_PATH = "/inventory/edit/:inventoryId"
export const EDIT_SECONDARY_INVENTORY_PATH = "/secondary-inventory/edit/:inventoryId"
export const LEAD_QUOTA_PATH = "/lead-quota"
export const AFFILIATE_PATH = "/affiliate"
export const TSR_AFFILIATE_PATH = "/tsr-affiliate-listing"
export const AFFILIATE_COMMISSION_PATH = "/commissions"
export const AFFILIATE_VERIFICATION_PATH = "/verification"
export const AFFILIATE_TO_DO_PATH = "/todo-affiliates"
export const AFFILIATE_TASK_PATH = "/task-affiliates"
export const BILLING_PROJECTS_LISTING_PATH = "/billing-projects"
export const BILLING_PROJECTS_DETAILS_PATH = "/billing-projects/:projectId"
export const REVENUE_RECOGNITION_PANEL_PATH = "/revenue-recognition"
export const REVENUE_RECOGNITION_MONTH_DETAIL_PATH = "/revenue-recognition/detail"
export const CREATE_INVOICE_PATH = "/billing-projects/billing-invoice/:invoiceId"
export const INVOICE_LISTING_PATH = "/invoices"
export const AFFILIATE_UNALLOCATED_PATH = "/unallocated"
export const INVOICE_PAYMENTS_LISTING_PATH = "/invoice-payments"
export const LOCATIONS_LISTING_PATH = "/project-locations"
export const SALES_PIPELINE = "/sales-pipeline"
export const INCENTIVES_LISTING_PATH = "/incentives"
export const ADD_PENALTY_PATH = "/penaltyPolicy/add"
export const EDIT_PENALTY_PATH = "/penaltyPolicy/edit/:penaltyId"
export const ADD_BONUS_PATH = "/bonusPolicy/add"
export const EDIT_BONUS_PATH = "/bonusPolicy/edit/:bonusId"
export const BONUS_LISTING_PATH = "/bonusPolicy"
export const PENALTY_LISTING_PATH = "/penaltyPolicy"
export const LMS_LEADS_PATH = "/lms-leads"
export const LEAD_POOLS_PATH = "/leadPools"
export const CAMPAIGN_LISTING_PATH = "/campaigns"
export const ADD_CAMPAIGN_PATH = "/campaign/add"
export const EDIT_CAMPAIGN_PATH = "/campaign/edit/:campaignId"
export const SOCIAL_CONNECTORS_LIST = "/socialConnectors"
export const VERIFY_DISCOUNT_STATUS_PATH = "/verify/discountStatus"
