import { localisedValue } from "utils"

const idleTransformResponse = response => {
  response.push({ key: "active", title: "Active" })
  return response.map(e => ({
    ...e,
    label: localisedValue({ object: e, key: "title" }),
    key: e.key,
  }))
}
export default idleTransformResponse
