import { HoverText, Skeleton, Tag, Text, Title, Tooltip } from "components/common"

import { Row } from "antd"
import cx from "clsx"
import styles from "./infoBlock.module.less"

const StringComponent = ({
  title,
  displayValue,
  columnView,
  labelTag,
  tagColor,
  tooltip,
  dataColor,
  isCapital = true,
}) => {
  return (
    <Row className={cx(styles.infoBlockItem)} justify="space-between" style={{ gap: "4px 16px" }} wrap={!columnView}>
      <div>
        <Text type="secondary">{title}</Text>{" "}
        {labelTag && (
          <Tag type="light" color={tagColor}>
            {labelTag}
          </Tag>
        )}
      </div>
      <Tooltip title={tooltip} trigger="hover">
        <Text className={isCapital ? "text-capitalize" : null} color={dataColor} strong align={columnView && "right"}>
          {displayValue}
        </Text>
      </Tooltip>
    </Row>
  )
}

const MixedComponent = ({ title, data, columnView, customComponent, customComponentObj, type, labelTag, tagColor }) => {
  return (
    <Row className={cx(styles.infoBlockItem)} justify="space-between" style={{ gap: "4px 16px" }} wrap={!columnView}>
      <div>
        <Text type="secondary">{title}</Text>{" "}
        {labelTag && (
          <Tag type="light" color={tagColor}>
            {labelTag}
          </Tag>
        )}
      </div>
      {type ? customComponentObj?.[type](data) : customComponent(data.path || data)}
    </Row>
  )
}
const ArrayComponent = ({ title, data }) => {
  const contentData = []
  if (data.length !== 0) {
    return (
      <Row className={styles.infoBlockItem} id="2" justify="space-between" style={{ gap: "4px 16px" }} wrap={false}>
        <Text className="text-capitalize" type="secondary">
          {title}
        </Text>
        <Row style={{ gap: "4px 8px" }} justify="end">
          {data.length > 2 &&
            data.map((item, index) => {
              if (index <= 1) {
                return <Tag type="light" key={index} color={item.tagcolor} children={item.taglabel} />
              } else {
                contentData.push(item.taglabel)
                if (data.length - 1 === index) {
                  return <HoverText key={index} message={`+ ${contentData.length} more`} data={contentData} />
                }
              }
            })}
          {data.length <= 2 &&
            data.map((item, index) => {
              return <Tag type="light" key={index} color={item.tagcolor} children={item.taglabel} />
            })}
        </Row>
      </Row>
    )
  }
}

const InfoBlock = ({ className, data, title, size, skeletonLoading, customComponent, style, customComponentObj }) => {
  let dataAvail = true
  const changedData = data?.filter(e => {
    return !!e
  })

  dataAvail = !(changedData?.length === 0)
  return skeletonLoading ? (
    <InfoBlockSkeleton />
  ) : (
    <div
      className={cx(className)}
      style={{ "--info-block-font-size": size === "small" ? "14px" : "initial", ...style }}
    >
      {dataAvail && title && <Title level={4}>{title}</Title>}
      {dataAvail &&
        changedData.map((item, index) => {
          if (!item.data || !item.label) {
            return null
          }
          if (item?.isComponent) {
            return (
              <MixedComponent
                key={index}
                title={item?.label}
                data={item?.data}
                columnView={item?.columnView}
                customComponent={customComponent}
                customComponentObj={customComponentObj}
                type={item?.type}
                labelTag={item?.labelTag}
                tagColor={item?.tagColor}
              />
            )
          } else if (!Array.isArray(item.data)) {
            return (
              <StringComponent
                key={index}
                title={item?.label}
                displayValue={item?.data}
                columnView={item?.columnView}
                labelTag={item?.labelTag}
                tagColor={item?.tagColor}
                isCapital={item?.isCapital}
                dataColor={item?.dataColor}
                tooltip={item?.tooltip}
              />
            )
          } else {
            return <ArrayComponent key={index} title={item?.label} data={item?.data} />
          }
        })}
    </div>
  )
}

const InfoBlockSkeleton = () => (
  <div style={{ "--info-block-font-size": "initial" }}>
    <Skeleton width={120} height={26} className="mbe-4" />
    {[1, 2, 3, 4].map(item => (
      <Row className={cx(styles.infoBlockItem)} justify="space-between" key={item + ""}>
        <Skeleton height={22} />
        <Skeleton height={22} />
      </Row>
    ))}
  </div>
)

export default InfoBlock
