import { Dropdown as DropdownAntd, Menu } from "antd"
import { Icon, Text } from ".."

import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useState } from "react"

const Dropdown = props => {
  const {
    content,
    placement,
    title,
    labelProps,
    action,
    children,
    style,
    iconSize,
    className,
    overlayClassName,
    menuClassName,
    menuStyle,
    options,
    getOptionLabel,
    getOptionValue,
    getOptionLink,
    getOptionIcon,
    renderChildren,
    useAsLink,
    onChange,
    placeholder,
    defaultValue,
    suffixIcon,
    prefixIcon,
    box_title,
    linkTo,
    iconProps,
    onClickItem,
    color,
    iconTextWrapperClass,
    size,
    ...rest
  } = props

  const [value, setValue] = useState(defaultValue ? options.filter(item => item?.id === defaultValue)[0]?.name : null)
  const renderChild = () => {
    return children || <span>{placeholder}</span>
  }

  const renderDropdownItem = item => {
    return renderChildren ? (
      renderChildren(item)
    ) : (
      <Menu.Item
        onClick={e => (onClickItem && onClickItem({ ...item, e })) || (item?.onClick && item.onClick())}
        key={getOptionValue(item)}
        disabled={item?.disabled}
      >
        {useAsLink ? (
          <Link target="_blank" rel="noopener noreferrer" to={linkTo || getOptionLink(item)}>
            {getOptionLabel(item)}
          </Link>
        ) : (
          <div className={iconTextWrapperClass}>
            {getOptionIcon ? (
              <Icon icon={getOptionIcon(item)} size="1.6em" />
            ) : (
              item?.icon && (
                <Icon icon={item.icon} color={item.color} size={item.size || "1.6em"} {...item?.iconProps} />
              )
            )}
            {getOptionLabel(item)}
          </div>
        )}
      </Menu.Item>
    )
  }

  return (
    <>
      {title && <Text>{title}</Text>}
      <DropdownAntd
        className={className}
        overlayClassName={overlayClassName}
        placement={placement}
        value={value}
        overlay={
          options && !!options.length ? (
            <Menu className={menuClassName} style={{ ...menuStyle }}>
              {box_title && (
                <Menu.Item key={box_title} disabled>
                  {box_title}
                </Menu.Item>
              )}
              {content || options.map(e => renderDropdownItem(e))}
            </Menu>
          ) : (
            <Menu className={menuClassName} style={{ ...menuStyle }}>
              {renderDropdownItem()}
            </Menu>
          )
        }
        {...rest}
      >
        <div style={{ gap: 4, ...style }}>
          {prefixIcon && <Icon icon={prefixIcon} {...iconProps} />}
          {renderChild()}
          {suffixIcon && <Icon icon={suffixIcon} {...iconProps} />}
        </div>
      </DropdownAntd>
    </>
  )
}

Dropdown.defaultProps = {
  action: ["click"],
  placement: "bottomRight",
  style: {},
  options: [],
  getOptionLabel: e => e?.name || e?.title || e?.label,
  getOptionValue: e => e?.id || e?.value,
  suffixIcon: "MdKeyboardArrowDown",
  onChange: () => {},
}

Dropdown.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  labelProps: PropTypes.object,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionIcon: PropTypes.func,
  renderChildren: PropTypes.func,
  useAsLink: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  suffixIcon: PropTypes.string,
  prefixIcon: PropTypes.string,
  box_title: PropTypes.string,
  linkTo: PropTypes.string,
  iconProps: PropTypes.object,
}

export default Dropdown
