import {
  ACTION_ITEMS_PATH,
  ADD_BONUS_PATH,
  ADD_INVENTORY_PATH,
  ADD_PENALTY_PATH,
  ADD_SALE_DISPUTE_PATH,
  ADD_STAFF_PATH,
  AFFILIATE_COMMISSION_PATH,
  AFFILIATE_DETAIL_PATH,
  AFFILIATE_KPI_REPORT_PATH,
  AFFILIATE_PATH,
  AFFILIATE_TASK_PATH,
  AFFILIATE_TO_DO_PATH,
  AFFILIATE_UNALLOCATED_PATH,
  AFFILIATE_VERIFICATION_PATH,
  AGENCY_SETTINGS_PATH,
  AI_CALL_ANALYSIS_REPORT_PATH,
  BILLING_PROJECTS_DETAILS_PATH,
  BILLING_PROJECTS_LISTING_PATH,
  BOOKING_FORM_LISTING,
  BONUS_LISTING_PATH,
  CALENDAR_PATH,
  CALL_RECORDING_PATH,
  CLIENT_DETAIL_PATH,
  CLIENT_LISTING_PATH,
  CONTRACT_PATH,
  CREATE_INVOICE_PATH,
  DASHBOARD_PATH,
  EDIT_BONUS_PATH,
  EDIT_BOOKING_FORM,
  EDIT_INVENTORY_PATH,
  EDIT_PENALTY_PATH,
  EDIT_SECONDARY_INVENTORY_PATH,
  EDIT_STAFF_PATH,
  INCENTIVES_LISTING_PATH,
  INVENTORY_LISTING_PATH,
  INVOICE_LISTING_PATH,
  INVOICE_PAYMENTS_LISTING_PATH,
  KPI_MANAGEMENT_REPORT_PATH,
  LEADS_LISTING_PATH,
  LEADS_SHARED_LISTING_PATH,
  LEAD_QUOTA_PATH,
  LOCATIONS_LISTING_PATH,
  MANAGEMENT_PATH,
  MANAGEMENT_MEETINGS_PATH,
  PAYMENT_PATH,
  PENALTY_LISTING_PATH,
  POLICY_PATH,
  PRODUCTIVITY_REPORT_PATH,
  PROJECTS_ADD_PATH,
  PROJECTS_EDIT_PATH,
  PROJECTS_LISTING_PATH,
  QUALITY_CONTROL_PATH,
  REFUNDS_LISTING_PATH,
  REVENUE_RECOGNITION_MONTH_DETAIL_PATH,
  REVENUE_RECOGNITION_PANEL_PATH,
  REVENUE_REPORT_PATH,
  ROLES_AND_PRIVILEGES_ADD_PATH,
  ROLES_AND_PRIVILEGES_EDIT_PATH,
  ROLES_AND_PRIVILEGES_PATH,
  ROSTER_PATH,
  RULE_INCLUSIONS_PATH,
  SALES_DISPUTE_DETAIL_PATH,
  SALES_DISPUTE_PATH,
  SALES_KPI_REPORT_PATH,
  SECONDARY_INVENTORY_LISTING_PATH,
  STAFF_PATH,
  STAFF_TARGETS_PATH,
  STAFF_TARGETS_SETTING_PATH,
  TASKS_LISTING_PATH,
  TEAMS_PATH,
  TODOS_LISTING_PATH,
  TSR_AFFILIATE_PATH,
  TSR_KPI_REPORT_PATH,
  TSR_LISTING_PATH,
  LEAD_POOLS_PATH,
  SALES_PIPELINE,
  LMS_LEADS_PATH,
  ADD_CAMPAIGN_PATH,
  EDIT_CAMPAIGN_PATH,
  CAMPAIGN_LISTING_PATH,
  SOCIAL_CONNECTORS_LIST,
  VERIFY_DISCOUNT_STATUS_PATH,
} from "constants/app-paths"

import { lazy } from "react"

const LandingDashboard = lazy(() => import("features/dashboard"))
const ClientsListing = lazy(() => import("features/clients/listings"))
const ClientsDetail = lazy(() => import("features/clients/detail"))
const LeadsListing = lazy(() => import("features/leads/listings"))
const TodosListing = lazy(() => import("features/todos/listings"))
const AgencySettings = lazy(() => import("features/agency/settings"))
const MeetingList = lazy(() => import("features/management/meetings"))
const ActionItemsListings = lazy(() => import("features/management/actionItems"))
const StaffListing = lazy(() => import("features/staff/listings"))
const StaffTeams = lazy(() => import("features/staff/teams"))
const StaffRoster = lazy(() => import("features/staff/roster/index"))
const AddStaff = lazy(() => import("features/staff/add"))
const EditStaff = lazy(() => import("features/staff/edit"))
const SalesKPIReport = lazy(() => import("features/reports/KPISales"))
const AffiliatesKPIReport = lazy(() => import("features/reports/KPIAffiliates"))
const TSRKPIReport = lazy(() => import("features/reports/KPITSR"))
const ProductivityReport = lazy(() => import("features/reports/productivity"))
const KPIManagementReport = lazy(() => import("features/reports/KPIManagement"))
const RevenueReport = lazy(() => import("features/reports/revenueReport"))
const AiCallAnalysis = lazy(() => import("features/reports/AiCallAnalysis"))
const SalesDisputeListing = lazy(() => import("features/disputes/listings"))
const AddSaleDispute = lazy(() => import("features/disputes/add"))
const SalesDisputeDetail = lazy(() => import("features/disputes/detail"))
const Calender = lazy(() => import("features/calendar"))
const CallRecording = lazy(() => import("features/callRecording"))
const RolesAndPrivilegesView = lazy(() => import("features/rolePrivileges"))
const RolesAndPrivilegesForm = lazy(() => import("features/rolePrivileges/components/addRole"))
const RulesExemption = lazy(() => import("features/staff/ruleExemption"))
const QualityControlListing = lazy(() => import("features/qualityControl/listing"))
const TsrListing = lazy(() => import("features/tsr/listings"))
const ClientQuota = lazy(() => import("features/clientsQuota"))
const ProjectListing = lazy(() => import("features/projects/listing"))
const InventoryListing = lazy(() => import("features/inventory/listing"))
const SecondaryInventoryListing = lazy(() => import("features/secondaryInventory/listing"))
const InventoryProgress = lazy(() => import("features/inventory/inventoryProgress/inventoryProgress"))
const Policies = lazy(() => import("features/policies"))
const Incentive = lazy(() => import("features/incentive"))
const BonusPolicy = lazy(() => import("features/incentive/bonus"))
const PenaltyPolicy = lazy(() => import("features/incentive/penalty"))
const BookingForms = lazy(() => import("features/bookingForm/listings"))
const BookingFormDetail = lazy(() => import("features/bookingForm/editBookingForm"))
const StaffTargets = lazy(() => import("features/staff/targets/targets"))
const StaffTargetSettings = lazy(() => import("features/staff/targets/settings"))
const RefundListing = lazy(() => import("features/refunds"))
const ProjectProgress = lazy(() => import("features/projects/components/projectProgress/ProjectProgress"))
const TaskListing = lazy(() => import("features/tasks/listing/index"))
const Payment = lazy(() => import("features/accounts/payments/listing/index"))
const Affiliate = lazy(() => import("features/affiliates/affiliate"))
const AffiliateTodo = lazy(() => import("features/affiliates/todos"))
const AffiliateTasks = lazy(() => import("features/affiliates/tasks"))
const Contract = lazy(() => import("features/accounts/contracts/listing/index"))
const InvoiceListing = lazy(() => import("features/invoiceListing/invoiceListing"))
const BillingProjects = lazy(() => import("features/billings/projects"))
const BillableProjectDetails = lazy(() => import("features/billings/projects/detail"))
const CreateInvoice = lazy(() => import("features/billings/projects/detail/createInvoice"))
const AffiliateCommission = lazy(() => import("features/affiliates/comissions/index"))
const AffiliateDetail = lazy(() => import("features/affiliates/affiliate/affiliateDetail/index"))
const AffiliateTsr = lazy(() => import("features/affiliates/affiliateTsr/index"))
const AffiliateUnallocated = lazy(() => import("features/affiliates/unallocated/index"))
const AffiliateVerification = lazy(() => import("features/affiliates/verification/index"))
const RevenueRecognitionPanel = lazy(() => import("features/accounts/revenueRecognition"))
const RevenueRecognitionMonthDetail = lazy(() => import("features/accounts/revenueRecognition/detail"))
const InvoicePayments = lazy(() => import("features/accounts/invoice-payment/listing"))
const LocationListing = lazy(() => import("features/locations/listing"))
const SalesPipeline = lazy(() => import("features/salesPipeline"))
const PenaltyProgress = lazy(() => import("features/incentive/penalty/penaltyFormProgress/penaltyProgress"))
const BonusProgress = lazy(() => import("features/incentive/bonus/bonusFormProgress/bonusFormProgress"))
const LMS_LEADS = lazy(() => import("features/lms-leads/leads/index"))
const LeadPools = lazy(() => import("features/lms-leads/leadPools/listings"))
const CampaignProgressForm = lazy(() => import("features/marketing/CampaignFormProgress/campaignProgressForm"))
const CampaignListing = lazy(() => import("features/marketing/index"))
const SocialConnectorsList = lazy(() => import("features/marketing/socialConnectors"))
const verifyDiscountStatus = lazy(() => import("components/custom/discountStatusCard/discountStatusCard"))

export const APP_ROUTES = [
  {
    path: DASHBOARD_PATH,
    Component: LandingDashboard,
    name: "dashboard",
    privilege: ["can_view_dashboard", "can_view_lean_dashboard_sales", "can_view_lean_dashboard_affiliates"],
  },
  /** Clients & Leads */
  {
    path: CLIENT_LISTING_PATH,
    Component: ClientsListing,
    name: "client-listing",
    privilege: "can_view_clients_module",
  },
  { path: CLIENT_DETAIL_PATH, Component: ClientsDetail, name: "client-detail", privilege: "can_view_clients_module" },
  { path: LEADS_LISTING_PATH, Component: LeadsListing, name: "leads-listing", privilege: "can_view_leads_module" },
  { path: TODOS_LISTING_PATH, Component: TodosListing, name: "todos-listing", privilege: "can_view_todo_list_leads" },
  { path: TASKS_LISTING_PATH, Component: TaskListing, name: "tasks-listings", privilege: "can_search_leads" },
  {
    path: LEADS_SHARED_LISTING_PATH,
    Component: LeadsListing,
    name: "shared-leads",
    privilege: "can_view_leads_module",
  },
  {
    path: SALES_DISPUTE_PATH,
    Component: SalesDisputeListing,
    name: "sales-dispute-listing",
    privilege: "can_view_sale_dispute",
  },
  {
    path: ADD_SALE_DISPUTE_PATH,
    Component: AddSaleDispute,
    name: "add-sale-dispute",
    privilege: "can_view_sale_dispute",
  },
  {
    path: SALES_DISPUTE_DETAIL_PATH,
    Component: SalesDisputeDetail,
    name: "sales-dispute-detail",
    privilege: "can_view_sale_dispute",
  },
  {
    path: CALL_RECORDING_PATH,
    Component: CallRecording,
    name: "call-recording",
    privilege: "can_view_call_recording_report",
  },
  {
    path: QUALITY_CONTROL_PATH,
    Component: QualityControlListing,
    name: "quality_control",
    privilege: "can_search_QC_leads",
  },
  {
    path: SALES_PIPELINE,
    Component: SalesPipeline,
    name: "sales-pipeline",
    privilege: "can_view_leads_module",
  },
  /** Telesales */
  { path: TSR_LISTING_PATH, Component: TsrListing, name: "tsr-list", privilege: "can_view_tsr_section" },
  { path: TSR_AFFILIATE_PATH, Component: AffiliateTsr, name: "tsr-affiliate", privilege: "can_view_tsr_section" },
  { path: LMS_LEADS_PATH, Component: LMS_LEADS, name: "lms-leads", privilege: "can_view_tsr_section" }, // change in future
  {
    path: LEAD_POOLS_PATH,
    Component: LeadPools,
    name: "lead-pools",
    privilege: "can_view_lead_pre_qualification_pools",
  },
  /** Staff */
  { path: STAFF_PATH, Component: StaffListing, name: "staff-listing", privilege: "can_view_staff" },
  { path: ADD_STAFF_PATH, Component: AddStaff, name: "add-staff-form", privilege: "can_view_staff" },
  { path: EDIT_STAFF_PATH, Component: EditStaff, name: "edit-staff-form", privilege: "can_view_staff" },
  { path: TEAMS_PATH, Component: StaffTeams, name: "staff-teams", privilege: "can_view_teams" },
  {
    path: ROLES_AND_PRIVILEGES_PATH,
    Component: RolesAndPrivilegesView,
    name: "roles-and-privileges-view",
    privilege: "can_manage_roles",
  },
  {
    path: ROLES_AND_PRIVILEGES_ADD_PATH,
    Component: RolesAndPrivilegesForm,
    name: "roles-and-privileges-add",
    privilege: "can_manage_roles",
  },
  {
    path: ROLES_AND_PRIVILEGES_EDIT_PATH,
    Component: RolesAndPrivilegesForm,
    name: "roles-and-privileges-edit",
    privilege: "can_manage_roles",
  },
  {
    path: RULE_INCLUSIONS_PATH,
    Component: RulesExemption,
    name: "rule-inclusion",
    privilege: "can_manage_rule_inclusions",
  },
  { path: ROSTER_PATH, Component: StaffRoster, name: "staff-roster", privilege: "can_view_weekly_roster" },
  { path: STAFF_TARGETS_PATH, Component: StaffTargets, name: "staff-targets", privilege: "can_view_targets" },
  {
    path: STAFF_TARGETS_SETTING_PATH,
    Component: StaffTargetSettings,
    name: "staff-target-setting",
    privilege: "can_update_soft_kpi_targets",
  },

  /** Management Module */
  { path: MANAGEMENT_PATH, Component: MeetingList, name: "meetings-list", privilege: "can_view_meeting" },
  { path: MANAGEMENT_MEETINGS_PATH, Component: MeetingList, name: "meeting", privilege: "can_view_meeting" },
  {
    path: ACTION_ITEMS_PATH,
    Component: ActionItemsListings,
    name: "action-items-listing",
    privilege: "can_view_meeting",
  },
  /** Reports */
  //
  {
    path: SALES_KPI_REPORT_PATH,
    Component: SalesKPIReport,
    name: "sales-kpi-report",
    privilege: "can_view_sales_kpi_report",
  },
  {
    path: AFFILIATE_KPI_REPORT_PATH,
    Component: AffiliatesKPIReport,
    name: "affiliate-kpi-report",
    privilege: "can_view_affiliate_kpi_report",
  },
  {
    path: TSR_KPI_REPORT_PATH,
    Component: TSRKPIReport,
    name: "tsr-kpi-report",
    privilege: "can_view_support_kpi_report",
  },
  {
    path: PRODUCTIVITY_REPORT_PATH,
    Component: ProductivityReport,
    name: "productivity-report",
    privilege: "can_view_productivity_report",
  },
  {
    path: KPI_MANAGEMENT_REPORT_PATH,
    Component: KPIManagementReport,
    name: "management-kpi-report",
    privilege: "can_view_kpi_management_report",
  },
  {
    path: REVENUE_REPORT_PATH,
    Component: RevenueReport,
    name: "revenue-report",
    privilege: "can_view_revenue_performance_report",
  },
  {
    path: AI_CALL_ANALYSIS_REPORT_PATH,
    Component: AiCallAnalysis,
    name: "ai-call-analysis",
    privilege: "can_view_ai_analysis_report",
  },
  /** Menus */
  {
    path: AGENCY_SETTINGS_PATH,
    Component: AgencySettings,
    name: "agency-settings",
    privilege: "can_update_agency_settings",
  },
  { path: CALENDAR_PATH, Component: Calender, name: "calendar", privilege: "can_view_bd_calendar" },
  /** Projects & Inventory */
  { path: PROJECTS_LISTING_PATH, Component: ProjectListing, name: "projects-listing", privilege: "can_view_projects" },
  { path: PROJECTS_ADD_PATH, Component: ProjectProgress, name: "projects-add", privilege: "can_view_projects" },
  { path: PROJECTS_EDIT_PATH, Component: ProjectProgress, name: "projects-edit", privilege: "can_view_projects" },
  {
    path: INVENTORY_LISTING_PATH,
    Component: InventoryListing,
    name: "inventory-listing",
    privilege: "can_view_inventory",
  },
  {
    path: SECONDARY_INVENTORY_LISTING_PATH,
    Component: SecondaryInventoryListing,
    name: "secondary-inventory-listing",
    privilege: "can_view_secondary_inventory",
  },
  { path: ADD_INVENTORY_PATH, Component: InventoryProgress, name: "add-inventory", privilege: "can_view_inventory" },
  { path: EDIT_INVENTORY_PATH, Component: InventoryProgress, name: "edit-inventory", privilege: "can_view_inventory" },
  {
    path: EDIT_SECONDARY_INVENTORY_PATH,
    Component: InventoryProgress,
    name: "edit-inventory",
    privilege: "can_view_secondary_inventory",
  },

  {
    path: LEAD_QUOTA_PATH,
    Component: ClientQuota,
    name: "leads-quota",
    privilege: "can_manage_lead_allocation_quota",
  },
  /** Accounts */
  { path: INCENTIVES_LISTING_PATH, Component: Incentive, name: "incentive", privilege: "can_view_incentive" },
  { path: PAYMENT_PATH, Component: Payment, name: "payment", privilege: "can_view_payments" },
  { path: CONTRACT_PATH, Component: Contract, name: "contract", privilege: "can_view_contracts_module" },
  { path: INVOICE_LISTING_PATH, Component: InvoiceListing, name: "invoice", privilege: "can_access_billing_module" },

  {
    path: BOOKING_FORM_LISTING,
    Component: BookingForms,
    name: "booking-form-listings",
    privilege: "can_view_accounts",
  },
  {
    path: EDIT_BOOKING_FORM,
    Component: BookingFormDetail,
    name: "booking-form-detail",
    privilege: "can_view_accounts",
  },
  {
    path: REFUNDS_LISTING_PATH,
    Component: RefundListing,
    name: "refunds",
    privilege: "can_view_payments",
  },
  {
    path: BILLING_PROJECTS_LISTING_PATH,
    Component: BillingProjects,
    name: "billing-projects",
    privilege: "can_access_billing_module",
  },
  {
    path: BILLING_PROJECTS_DETAILS_PATH,
    Component: BillableProjectDetails,
    name: "billing-projects-detail",
    privilege: "can_access_billing_module",
  },
  {
    path: REVENUE_RECOGNITION_PANEL_PATH,
    Component: RevenueRecognitionPanel,
    name: "revenue-recognition",
    privilege: "can_view_revenue_recognition_panel",
  },
  {
    path: REVENUE_RECOGNITION_MONTH_DETAIL_PATH,
    Component: RevenueRecognitionMonthDetail,
    name: "revenue-recognition-detail",
    privilege: "can_view_revenue_recognition_panel",
  },
  {
    path: CREATE_INVOICE_PATH,
    Component: CreateInvoice,
    name: "create-invoice",
    privilege: "can_access_billing_module",
  },
  {
    path: INVOICE_PAYMENTS_LISTING_PATH,
    Component: InvoicePayments,
    name: "invoice-payments",
    privilege: "can_access_billing_module",
  },
  /** Policies */
  { path: POLICY_PATH, Component: Policies, name: "policies", privilege: "can_view_repository_policy_summary" },
  /** Affiliate */
  {
    path: AFFILIATE_PATH,
    Component: Affiliate,
    name: "affiliate",
    privilege: "can_search_affiliate", //change privilege
  },
  {
    path: AFFILIATE_COMMISSION_PATH,
    Component: AffiliateCommission,
    name: "commission",
    privilege: "can_view_affiliate_commission", //change privilege
  },
  {
    path: AFFILIATE_DETAIL_PATH,
    Component: AffiliateDetail,
    name: "affiliateDetail",
    privilege: "can_view_affiliate_detail", //change privilege
  },
  {
    path: AFFILIATE_UNALLOCATED_PATH,
    Component: AffiliateUnallocated,
    name: "affiliateUnallocated",
    privilege: "can_view_verified_unallocated_affiliates", //change privilege
  },
  {
    path: AFFILIATE_TO_DO_PATH,
    Component: AffiliateTodo,
    name: "affiliate-toDo",
    privilege: "can_view_todo_list", //change privilege
  },
  {
    path: AFFILIATE_TASK_PATH,
    Component: AffiliateTasks,
    name: "affiliate-task",
    privilege: "can_search_affiliate_task", //change privilege
  },
  {
    path: AFFILIATE_VERIFICATION_PATH,
    Component: AffiliateVerification,
    name: "affiliateVerification",
    privilege: "can_view_potential_affiliates", //change privilege
  },
  /** Locations **/
  {
    path: LOCATIONS_LISTING_PATH,
    Component: LocationListing,
    name: "locations-listing",
    privilege: "can_view_projects",
  },
  /** Incentive **/
  {
    path: INCENTIVES_LISTING_PATH,
    Component: Incentive,
    name: "incentives",
    privilege: "can_view_incentive",
  },
  { path: ADD_PENALTY_PATH, Component: PenaltyProgress, name: "add-penalty", privilege: "can_view_inventory" },
  { path: EDIT_PENALTY_PATH, Component: PenaltyProgress, name: "edit-penalty", privilege: "can_view_inventory" },
  { path: ADD_BONUS_PATH, Component: BonusProgress, name: "add-bonus", privilege: "can_view_inventory" },
  { path: EDIT_BONUS_PATH, Component: BonusProgress, name: "edit-bonus", privilege: "can_view_inventory" },
  {
    path: BONUS_LISTING_PATH,
    Component: BonusPolicy,
    name: "bonusPolicy",
    privilege: "can_view_bonus_listing",
  },
  {
    path: PENALTY_LISTING_PATH,
    Component: PenaltyPolicy,
    name: "penaltyPolicy",
    privilege: "can_view_campaign",
  },
  //** Marketing */
  {
    path: CAMPAIGN_LISTING_PATH,
    Component: CampaignListing,
    name: "campaignListing",
    privilege: "can_view_campaign",
  },
  {
    path: ADD_CAMPAIGN_PATH,
    Component: CampaignProgressForm,
    name: "CampaignProgressForm",
    privilege: "can_view_campaign",
  },
  { path: EDIT_CAMPAIGN_PATH, Component: CampaignProgressForm, name: "edit-campaign", privilege: "can_view_campaign" },
  {
    path: SOCIAL_CONNECTORS_LIST,
    Component: SocialConnectorsList,
    name: "social-connectors-list",
    privilege: "can_view_campaign",
  },
  {
    path: VERIFY_DISCOUNT_STATUS_PATH,
    Component: verifyDiscountStatus,
    name: "verifyDiscountStatus",
    // privilege: "can_view_penalty_listing",
  },
]
