import { ACTION_TYPES, CONTENT_TYPES, EVENT_NAMES, PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"

import { CallButton } from "features/clients/components"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "utils/privileges/privileges"
import theme from "utils/themeVars"
import WhatsappCallButton from "features/clients/components/whatsapp-call-button"
import { useTranslation } from "react-i18next"
import { t } from "i18next"
import styles from "./getActionButton.module.less"
import { SvgIconAi } from "components/svg"

export default function getActionButtons({
  lead,
  isListing,
  client,
  formRefs,
  onActionClick,
  user,
  from,
  userPrivileges,
  showAnimation,
  addClientContactView,
}) {
  return [
    ...(isListing
      ? [
          {
            key: "call",
            Component: () => (
              <CallButton
                client={lead?.Client}
                shape="circle"
                leadId={lead?.id}
                from={from}
                disabled={!getPrivilege("can_view_client_contact", lead?.privileges)}
              />
            ),
          },
          {
            key: "whatsapp",
            Component: () => (
              <WhatsappCallButton
                client={lead?.Client}
                leadId={lead?.id}
                from={from}
                disabled={!getPrivilege("can_view_client_contact", lead?.privileges)}
              />
            ),
          },
        ]
      : []),
    {
      key: "add_task",
      icon: "MdAddTask",
      color: theme["primary-color"],
      content: t("Add Actioknln"),
      title: t("Select Action Type"),
      iconTextWrapperClass: styles.actionDropdDown,
      options: [
        {
          key: "2",
          label: t("Add Task"),
          icon: "MdAddTask",
          color: theme["primary-green-1"],
          size: "16px",

          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_actions_click_on_add_task,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead?.id,
              ...from,
            })
            onActionClick(formRefs.addTaskRef, lead)
          },
          disabled: !getPrivilege("can_add_task_on_lead", lead?.privileges),
        },

        {
          key: "3",
          label: t("Generate Proposal"),
          icon: "FaMagic",
          color: theme["primary-green-1"],
          size: "16px",
          onClick: () => {
            onActionClick(formRefs.proposalGenerationRef, lead)
          },
          disabled: !getPrivilege("can_generate_proposal", userPrivileges),
        },
        {
          key: "aiSalesPitch",
          label: t("Generate Sales Pitch"),
          icon: "SvgIconAi",
          iconProps: {
            id: lead?.id,
          },
          color: theme["primary-green-1"],
          size: "16px",
          onClick: () => {
            formRefs.aiSaleGenRef?.current?.setModal({ clientId: lead?.clientId, leadId: lead?.id })
          },
          disabled: !getPrivilege("can_generate_sales_pitch", userPrivileges),
        },

        {
          key: "add_sales",
          icon: "BsCreditCard",
          color: theme["primary-green-1"],
          label: t("Add Sale"),
          size: "16px",
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_actions_click_on_add_sale,
              ...from,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead.id,
            })
            onActionClick(formRefs.addSaleFormRef, lead)
          },
          disabled: !getPrivilege("can_add_sale_on_lead", lead?.privileges),
        },
        {
          key: "apply_discount",
          icon: "SvgIcApplyDiscount",
          color: theme["primary-green-1"],
          label: t("Apply Discounts"),
          size: "16px",
          disabled: !(
            user?.AgencyConfiguration?.Platform?.platformKey === "zameen" &&
            !lead?.verifiedClosedWon &&
            lead?.discountEligibility &&
            getPrivilege("can_apply_discount", lead?.privileges) &&
            !lead?.InternalInquiryStatus?.closeStatus
          ),
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_apply_discount_button,
              ...from,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead.id,
            })
            onActionClick(formRefs.addApplyForDiscountFormRef, lead)
          },
        },
        {
          key: "addToPipeline",
          icon: "BsPersonX",
          color: theme["primary-green-1"],
          label: t("Add to Pipeline"),
          size: "16px",
          onClick: () => {
            onActionClick(formRefs.addToSalesPipeline, lead)
          },
          disabled: !getPrivilege("can_add_to_pipeline", userPrivileges),
        },
        {
          key: "closeLost",
          icon: "BsPersonX",
          color: theme["primary-green-1"],
          label: t("Mark Lead as Closed(LOST)"),
          size: "16px",
          disabled: !getPrivilege("can_mark_lead_a_closed_lost", lead?.privileges),
          onClick: () => {
            onActionClick(formRefs.closeLostRef, lead)
          },
        },
      ],
    },
    {
      key: "logs",
      icon: "IoDocumentTextOutline",
      color: theme["primary-color"],
      content: t("Logs"),
      title: t("View & Add"),
      iconTextWrapperClass: styles.actionDropdDown,
      options: [
        {
          key: "tasks",
          icon: "IconTaskList",
          color: theme["primary-green-1"],
          size: "16px",
          label: t("Task Logs"),
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_task_overview_button,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead?.id,
              ...from,
            })
            onActionClick(formRefs.taskLogRef, lead)
          },
          disabled: !getPrivilege("can_see_task_logs", userPrivileges) || !lead?.LatestTask,
        },
        {
          key: "fillProfile",
          icon: "TbEdit",
          size: "16px",
          color: theme["primary-green-1"],
          label: t("Fill Profile"),
          onClick: () => {
            onActionClick(formRefs.clientProfileRef, lead)
          },
          disabled: !getPrivilege("can_edit_client", lead?.privileges),
        },
        {
          key: "calls",
          icon: "IconCallLogs",
          color: theme["primary-green-1"],
          size: "16px",
          label: t("Call Logs"),
          onClick: () => {
            formRefs.callDrawerRef?.current?.showDrawer(lead?.Client)
            fireEvent({
              ...ACTION_TYPES.listing_call_logs,
              client_id: lead?.Client?.id,
            })
          },
          disabled: !getPrivilege("can_view_call_logs", lead?.privileges),
        },
        {
          key: "2",
          label: t("Client Review"),
          color: theme["primary-green-1"],
          size: "16px",
          icon: "MdOutlineRateReview",

          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_review_button_lead,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead.id,
              ...from,
            })
            onActionClick(formRefs.addReviewLeadFormRef, lead)
          },
          disabled: !(getPrivilege("can_add_inquiry_feedback", lead?.privileges) && lead.User?.id !== user.id),
        },
        {
          key: "3",
          icon: "SvgMenuClients",
          color: theme["primary-green-1"],
          label: t("Client Observation Meeting"),
          size: "16px",
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_review_button_meeting,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead.id,
              ...from,
            })
            onActionClick(formRefs.addClientObservationFormRef, lead)
          },
          disabled: !(getPrivilege("can_add_inquiry_feedback", lead?.privileges) && lead.User?.id !== user.id),
        },
      ],
    },
    {
      icon: "IoMdShareAlt",
      color: theme["error-color"],
      content: t("Share Lead"),
      title: t("Select Share Type"),
      iconTextWrapperClass: styles.actionDropdDown,
      disabled:
        !getPrivilege("can_share_lead_with_sales", lead?.privileges) &&
        !getPrivilege("can_share_lead_with_support", lead?.privileges),
      options: [
        {
          key: "2",
          label: t("Sale"),
          icon: "IoPricetagOutline",
          color: theme["primary-green-1"],
          size: "16px",
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_sharing_button_with_sale,
              ...from,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead?.id,
            })
            onActionClick(formRefs.shareLeadSaleFormRef, lead)
          },
          disabled: !getPrivilege("can_share_lead_with_sales", lead?.privileges),
        },
        {
          key: "3",
          label: t("Support"),
          icon: "BiSupport",
          color: theme["primary-green-1"],
          size: "16px",
          onClick: () => {
            fireEvent({
              ...ACTION_TYPES.lead_sharing_button_with_support,
              ...from,
              client_id: client?.id || lead?.Client?.id,
              lead_id: lead?.id,
            })
            onActionClick(formRefs.shareLeadSupportFormRef, lead)
          },
          disabled: !getPrivilege("can_share_lead_with_support", lead?.privileges),
        },
      ],
    },
  ]
}
