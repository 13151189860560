import { DataTable } from "components/common"
import CollapsibleText from "components/common/collapsibleText"
import { t } from "i18next"
import { useMemo } from "react"
import Markdown from "react-markdown"
import { dateTimeFunction } from "utils/date"
import styles from "./viewPreviousSummaryTable.module.less"

const columns = () => [
  { title: t("Date"), dataIndex: "date" },
  { title: t("Summary"), dataIndex: "summary" },
  { title: t("Talking Points"), dataIndex: "talkingPoints" },
]

const ViewPreviousSummaryTable = props => {
  const { data } = props

  const getTableData = useMemo(
    () =>
      data?.data?.map((item, index) => ({
        key: item?.id,
        date: dateTimeFunction({ date: item?.analysisDate, timeZone: true, format: "MMM D, YYYY" }),
        summary: (
          <CollapsibleText
            className={item?.language === "urdu" ? styles.arabicLang : styles.englishLang}
            text={<Markdown>{item?.analysis?.sales_pitch}</Markdown>}
            collapsibleLength={160}
          />
        ),
        talkingPoints: (
          <ul className={item?.language === "urdu" ? styles.arabicLang : styles.englishLang}>
            {item?.analysis?.talking_points?.map(item => (
              <li>{<CollapsibleText text={<Markdown>{item}</Markdown>} collapsibleLength={100} />}</li>
            ))}
          </ul>
        ),
      })),
    [data]
  )

  return (
    <DataTable
      className={styles.previousSummaryData}
      data={getTableData}
      pagination={false}
      tableLayout="auto"
      columns={columns()}
      stickyOffset={-1}
    />
  )
}

export default ViewPreviousSummaryTable
