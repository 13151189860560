import { useEffect, useState } from "react";

import { Button, Dropdown, SelectInput, TextInput } from "components/common";

function RangeInput(props) {
  const {
    placeholder,
    label,
    labelProps,
    dropdownPlaceholder,
    inputPlaceholders,
    startLabel,
    endLabel,
    mode,
    list,
    defaultValue,
    onConfirm,
    queryStartValue,
    queryEndValue,
    queryUnitValue,
    onValuesConvert,
    defaultUnit,
    onUnitChange = () => {},
    isMobile,
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);
  const [startValue, setStartValue] = useState(queryStartValue || null);
  const [endValue, setEndValue] = useState(queryEndValue || null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [startvalueError, setStartValueError] = useState(null);
  const [endvalueError, setEndValueError] = useState(null);

  useEffect(() => {
    list && setSelectedUnit(defaultUnit);
  }, [list]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const handleClick = () => {
    if (onValuesConvert) {
      let values = [startValue, endValue];
      const [start, end] = onValuesConvert(selectedUnit, values);
      onConfirm(start, end, selectedUnit);
    } else {
      if (!startvalueError && !endvalueError) {
        onConfirm(startValue, endValue);
      }
    }
  };

  const validateValue = (value) => {
    return value <= 1000000000 ? true : false;
  };

  const renderChildren = () => {
    return (
      <>
        <div>
          <div>
            <TextInput
              label={startLabel}
              placeholder={inputPlaceholders}
              value={startValue}
              type="number"
              handleChange={(e) => {
                setStartValue(e.target.value);
                // if (mode === 'unitRange') {
                //   setStartValue(e.target.value);
                // } else {
                //   if (validateValue(e.target.value)) {
                //     setStartValue(e.target.value);
                //     setStartValueError(null);
                //   } else {
                //     setStartValueError('Invalid Price');
                //   }
                // }
              }}
              errorMsg={startvalueError}
            />
            <TextInput
              label={endLabel}
              placeholder={inputPlaceholders}
              value={endValue}
              errorMsg={endvalueError}
              type="number"
              handleChange={(e) => {
                setEndValue(e.target.value);
                // if (mode === 'unitRange') {
                //   setEndValue(e.target.value);
                // } else {
                //   if (validateValue(e.target.value)) {
                //     setEndValue(e.target.value);
                //     setEndValueError(null);
                //   } else {
                //     setEndValueError('Invalid Price');
                //   }
                // }
              }}
            />
            {mode === "unitRange" && (
              <SelectInput
                label="Unit"
                options={!!list && list}
                style={{ width: 140 }}
                suffixIcon="chevron-down"
                defaultValue={queryUnitValue || defaultValue}
                getOptionValue={(e) => e.slug}
                getOptionLabel={(e) => e.title}
                value={selectedUnit}
                onChange={(value, option) => {
                  onUnitChange(option);
                  setSelectedUnit(value);
                }}
              />
            )}
          </div>
          <div style={{ display: "flex", gap: 8, justifySelf: "end" }}>
            <Button onClick={handleClose} text={"Cancel"} />
            <Button
              type="primary"
              onClick={() => {
                handleClick();
                handleClose();
              }}
              text={"Confirm"}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Dropdown
      title={label}
      placeholder={placeholder}
      style={{ cursor: "pointer" }}
      renderChildren={renderChildren}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      labelProps={labelProps}
    />
  );
}

export default RangeInput;
