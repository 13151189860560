import * as yup from "yup"

import { Form, Row } from "antd"
import { Button, Icon, Legend, Modal, PhoneInput, SelectInput, Spinner, Text, notify } from "components/common"
import {
  useAddLeadMutation,
  useChangeAssigneeMutation,
  useGetReasonForAllocationQuery,
  useLazyGetClientDetailQuery,
} from "features/clients/detail/api"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useForm, useWatch } from "antd/lib/form/Form"
import { IconText } from "components/custom"
import { regex } from "constants/regex"
import { AddLeadForm } from "features/leads/components"
import { useLazyGetCheckPhoneNumberQuery } from "features/shared/api"
import { schemaRules } from "features/shared/utils"
import { useQueryUpdate } from "hooks"
import _ from "lodash"
import { isValidPhoneNumber } from "react-phone-number-input"
import { useSelector } from "react-redux"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import theme from "utils/themeVars"
import { AddClientForm } from ".."
import { useTranslation } from "react-i18next"

export default function ClientLeadForm({ isOpen, setIsOpen }) {
  const { t } = useTranslation()
  const [initializeClientForm] = useForm()
  const addClientFormRef = useRef()
  const addLeadFormRef = useRef()
  const mobileNumber = useWatch("mobileNumber", initializeClientForm)
  const user = useSelector(state => state.app.user?.info)
  const privileges = useSelector(state => state.app.privileges)
  const [
    checkPhone,
    {
      data: phoneData,
      isError: phoneError,
      isFetching: phoneValidationFetching,
      isUninitialized: isCheckPhoneUninitialized,
    },
  ] = useLazyGetCheckPhoneNumberQuery()
  const [addLead, { isLoading: isAddLeadLoading, error, reset: resetAddLead }] = useAddLeadMutation()
  const [getClientDetail, { data: client, isFetching: isClientFetching, isUninitialized: isGetClientUninitialized }] =
    useLazyGetClientDetailQuery()
  const [
    changeAssignee,
    {
      data: changeAssigneeData,
      isLoading: isChangeAssigneeLoading,
      reset,
      isUninitialized: isChangeAssigneeUninitialized,
    },
  ] = useChangeAssigneeMutation()

  const { data: reasons, isFetching: fetchingReasons } = useGetReasonForAllocationQuery()

  const [stage, setStage] = useState(0)
  const [reason, setReason] = useState(null)
  const [clientFormValues, setClientFormValues] = useState(null)

  const [update] = useQueryUpdate()

  let rules = schemaRules(
    yup.object().shape({
      mobileNumber: yup
        .string()
        .test("required", t("Phone number is required"), value => {
          return !value ? false : true
        })
        .test("valid", t("Invalid Phone Number"), value => {
          return isValidPhoneNumber(value ? value : "-")
        }),
    })
  )

  const disableButton = useMemo(
    () =>
      phoneValidationFetching ||
      (phoneData?.exists && phoneData?.data?.Client?.User.id !== user.id) ||
      phoneData?.isUserCellNumber ||
      (phoneData?.clientReallocationStatus?.isClientReAllocationPossible &&
        !phoneData?.clientReallocationStatus?.isClientReAllocationPossible) ||
      isAddLeadLoading,
    [phoneData, isAddLeadLoading, phoneValidationFetching]
  )

  useEffect(() => {
    setStage(0)
  }, [])

  useEffect(() => {
    if (stage === 0) {
      const cell = initializeClientForm.getFieldValue("mobileNumber")
      !isCheckPhoneUninitialized && isOpen && checkPhone({ cell })
    }
    if (stage === 1) {
      addClientFormRef.current?.setFieldsValue({
        ...(clientFormValues && clientFormValues),
        allocatedTo: {
          value: user.id,
          label: user.name,
          id: user.id.toString(),
        },
        mobileNumber: [initializeClientForm.getFieldValue("mobileNumber")],
      })
    }
  }, [stage])

  const onReallocate = async () => {
    reset()
    try {
      await changeAssignee({
        clientIds: [phoneData?.data?.Client?.id],
        type: "add-client",
        allocatedTo: { id: user.id, name: user.name },
        reasonId: reason,
        requestReAllocation: true,
        userPhone: mobileNumber,
      }).unwrap()
      await getClientDetail(phoneData?.data?.Client?.id).unwrap()
      setStage(2)
    } catch (e) {
      notify.error(e.message)
    }
  }

  const handleStageChange = (mode = "forward") => {
    if (mode === "forward") {
      if (stage === 0) {
        initializeClientForm.submit()
      }
      if (stage === 1) {
        addClientFormRef.current?.submit()
      }
      if (stage === 2) {
        addLeadFormRef.current?.submit()
      }
    }
    if (mode === "backward") {
      if (stage > 0) {
        setStage(prevStage => (client ? prevStage - 2 : prevStage - 1))
      } else resetForm()
    }
  }

  const resetForm = () => {
    setIsOpen(false)
    setStage(0)
    setClientFormValues(null)
    initializeClientForm.resetFields()
    addClientFormRef.current?.resetFields()
    addLeadFormRef.current?.resetFields()
    !isGetClientUninitialized && update("getClientDetail", draft => null)
    !isCheckPhoneUninitialized && update("getCheckPhoneNumber", draft => null)
    resetAddLead()
  }

  const handleAddLead = async leadValues => {
    let clientAgainstLead = client ? client : clientFormValues
    try {
      await addLead({ client: clientAgainstLead, ...leadValues }).unwrap()
      resetForm()
      fireEvent({
        ...ACTION_TYPES.add_new_client_submit,
        client_type: !!phoneData?.exists ? "existing" : "new",
        realocated: !!changeAssigneeData ? "yes" : "no",
        property_type: leadValues?.propertyType,
        property_sub_type: leadValues?.subType?.name,
        response: "200",
        project_name: leadValues?.project?.name,
      })
    } catch (e) {
      // notify.error(e.message)
      fireEvent({
        ...ACTION_TYPES.add_new_client_submit,
        client_type: !!phoneData?.exists ? "existing" : "new",
        realocated: !!changeAssigneeData ? "yes" : "no",
        property_type: leadValues?.propertyType,
        property_sub_type: leadValues?.subType?.name,
        response: e.status,
        project_name: leadValues?.project?.name,
      })
    }
  }

  const renderAllocationButton = () => {
    if (phoneData?.data) {
      // data?.Client?.User?.id !== user.id && getPrivilege("can_change_assignee", privileges)
      return phoneData?.clientReallocationStatus?.isClientReAllocationPossible ? (
        <>
          <SelectInput
            style={{ minWidth: 200 }}
            placeholder="Select Reason"
            options={reasons}
            loading={fetchingReasons}
            getPopupContainer={false}
            onChange={value => {
              setReason(value)
            }}
          />
          <Button
            text="Reallocate"
            type="light-bordered"
            style={{ height: 46 }}
            onClick={onReallocate}
            loading={isChangeAssigneeLoading || isClientFetching}
            disabled={isChangeAssigneeLoading || isClientFetching || !!!reason}
          />
        </>
      ) : null
    }
  }

  const renderAllocationMsg = () => {
    if (phoneData?.isUserCellNumber) {
      return (
        <IconText
          icon="MdInfoOutline"
          iconProps={{ color: theme["error-color"], size: "1em" }}
          textType="danger"
          title={t("This cell no belongs to a staff user")}
        />
      )
    }
    if (phoneData?.data) {
      const data = phoneData?.clientReallocationStatus?.data
      return data?.userId === user?.id ? (
        <IconText
          icon="MdInfoOutline"
          iconProps={{ color: theme["success-color"], size: "1em" }}
          textType="success"
          title={t("Client is active")}
        />
      ) : phoneData?.clientReallocationStatus?.isClientReAllocationPossible ? (
        <IconText
          icon="MdInfoOutline"
          iconProps={{ color: theme["success-color"], size: "1em" }}
          textType="success"
          title={t("The client exists and can be re-allocated to you. Please click on the Re-Allocate Client button.")}
        />
      ) : (
        <IconText
          icon="MdInfoOutline"
          iconProps={{ color: theme["error-color"], size: "1em" }}
          textType="danger"
          title={`${t("The client is active with")} ${data?.userName} - ${data?.userContactNumber} ${t("of")} ${
            data?.teamName
          } ${t("from")} ${data?.teamAddress}.\n ${t("Please contact him/her to share the client with you")}.`}
        />
      )
    }
  }

  const titleWithStageNavigation = () => {
    return (
      <Row align={"middle"} style={{ gap: 0 }}>
        {stage > 0 && (
          <Button
            icon={<Icon icon={"IoIosArrowBack"} size="22px" color="#9d9d9d" />}
            onClick={() => {
              handleStageChange("backward")
            }}
            style={{ "--btn-bg": "transparent", boxShadow: "none", marginInlineStart: -12 }}
          />
        )}
        <div>{stage === 2 ? t("Add Lead") : t("Add Client")}</div>
      </Row>
    )
  }

  return (
    <Modal
      title={titleWithStageNavigation()}
      open={isOpen}
      width={900}
      onCancel={() => {
        resetForm()
      }}
      onOk={() => {
        handleStageChange("forward")
      }}
      okText={stage === 2 ? t("Add Lead") : t("Next")}
      disableOkButton={stage === 0 ? disableButton : false}
      hideCancelButton={true}
      onCancelProps={{
        onClick: () => {
          handleStageChange("backward")
        },
        cancelText: stage > 0 ? "Previous" : "Cancel",
      }}
      destroyOnClose
      footerAlert={error}
      loading={isAddLeadLoading}
    >
      <Form
        onKeyDown={e => {
          if (disableButton && e.key === "Enter") {
            e.preventDefault()
          }
        }}
        form={initializeClientForm}
        layout="vertical"
        name="client-form-initialize"
        initialValues={{ mobileNumber: "" }}
        onFinish={async () => {
          const clientId = phoneData?.data?.Client?.id
          const nextStage = phoneData?.data?.Client?.User.id === user.id ? 2 : 1
          nextStage === 2 && getClientDetail(clientId)
          setStage(nextStage)
        }}
        scrollToFirstError
        preserve={true}
        hidden={stage !== 0}
      >
        <Legend title={t("Client Details")} />
        <Row style={{ gap: 8 }} align="top">
          <Form.Item
            label={t("Mobile Number")}
            required
            hasFeedback
            validateTrigger={["onBlur"]}
            name="mobileNumber"
            rules={rules}
            extra={!phoneError && !!phoneData && renderAllocationMsg()}
            style={{ flexBasis: "48ch" }}
          >
            <PhoneInput
              onChange={async input => {
                initializeClientForm.setFields([
                  {
                    name: "mobileNumber",
                    errors: [],
                  },
                ])
                if (input) {
                  await checkPhone({ cell: input.split(" ").join(""), reAllocationCheck: true }).unwrap()
                }
              }}
            />
          </Form.Item>

          <Row style={{ gap: 8, marginTop: 30, height: 46 }} align="middle">
            {phoneValidationFetching ? (
              <>
                <Spinner type="plain" />
                <Text type="success">Validating this number</Text>
              </>
            ) : (
              !phoneError && renderAllocationButton()
            )}
          </Row>
        </Row>
      </Form>

      {stage === 1 && (
        <AddClientForm
          ref={addClientFormRef}
          preserve={true}
          onSubmit={values => {
            setClientFormValues(values)
            setStage(2)
          }}
        />
      )}

      {stage === 2 && <AddLeadForm ref={addLeadFormRef} client={client} onSubmit={handleAddLead} preserve={true} />}
    </Modal>
  )
}
