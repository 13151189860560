import { Col, List, Row } from "antd"
import { ComponentState, DataTable, Group, Icon, Modal, Number, Skeleton, Text } from "components/common"
import { IcCoins, IconApartment, IconHome } from "components/svg"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useLazyGetBuySellOverviewQuery, useLazyGetSellerRecommendedListingsQuery } from "features/leads/api"

import { TitleDescription } from "components/custom"
import cx from "clsx"
import { getAreaUnit, joinBytItems, localisedValue, convertToSpecificAreaUnit } from "utils"
import styles from "./index.module.less"
import { useSelector } from "react-redux"

const SellRentModal = forwardRef((_props, ref) => {
  const userInfo = useSelector(state => state?.app?.user?.info)
  const userPlatformKey = userInfo?.AgencyConfiguration?.Platform?.platformKey
  const [purpose, setPurpose] = useState(null)
  const [leadId, setLeadId] = useState()
  const modalRef = useRef()
  const [getOverviewDetail, { data: overview, isFetching: isFetchingOverview, error: errorOverview }] =
    useLazyGetBuySellOverviewQuery()

  const [
    getRecommendedProperties,
    { data: recommendedProperties, isFetching: isFetchingRecommendedProperties, error: errorRecommendedProperties },
  ] = useLazyGetSellerRecommendedListingsQuery()

  const onModalOpen = lead => {
    getOverviewDetail(lead.id)
      .unwrap()
      .then(res => getRecommendedProperties(getRecommendedListingParams(res)))
    setLeadId(lead.id)
    setPurpose(lead?.purposeLabel)
    modalRef.current.setVisible(true)
  }

  useImperativeHandle(ref, () => ({ showFor: onModalOpen }), [])

  const getRecommendedListingParams = (params, pageLimit, page) => {
    return {
      typeId: params?.typeId,
      localityId: params?.Address?.localityId,
      landAreaTo: params?.maxLand,
      landAreaFrom: params?.minLand,
      pageLimit: pageLimit ? pageLimit : 10,
      page: page ? page : 0,
    }
  }

  const columns = [
    { title: "ADDRESS", dataIndex: "address", key: "address" },
    { title: "TYPE", dataIndex: "type", key: "type" },
    { title: "PRICE", dataIndex: "price", key: "price" },
    { title: "AREA", dataIndex: "area", key: "area" },
  ]

  const getDataRows = useMemo(() => {
    return recommendedProperties?.rows?.map((e, i) => {
      return {
        key: e?.id,
        address: (
          <TitleDescription
            title={e?.Address?.unitNumber}
            gapSpace={4}
            description={joinBytItems(
              [
                localisedValue({ key: "locality", object: e.Address }),
                localisedValue({ key: "city", object: e?.Address }),
              ],
              <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
            )}
            key={`address - ${e.id}`}
          />
        ),
        type: localisedValue({ key: "name", object: e?.Type }),
        price: <Number tooltip isCurrency isStringOnly value={e?.price} />,
        area: (
          <Number
            digitAfterFraction={2}
            value={convertToSpecificAreaUnit(e?.landArea, userPlatformKey === "bayut_ksa" ? "sq_m" : "sq_f")}
            unit={getAreaUnit(userPlatformKey)}
          />
        ),
      }
    })
  }, [recommendedProperties])

  const onChangePage = (page, pageSize) => {
    getRecommendedProperties(getRecommendedListingParams(overview, pageSize, page))
  }

  return (
    <Modal
      width={840}
      ref={modalRef}
      onCancel={() => {
        modalRef?.current?.setVisible(false)
        setLeadId(null)
      }}
      loading={isFetchingOverview}
      hideCancelButton={true}
      className={styles.modalBg}
      bodyStyle={{ padding: 24 }}
      footer={null}
      destroyOnClose
      onModalClos
    >
      {!!errorOverview ? (
        <ComponentState
          onClick={() => leadId && getOverviewDetail(leadId)}
          btnLoading={isFetchingOverview}
          description={errorOverview?.message}
          status={errorOverview?.status}
        />
      ) : isFetchingOverview ? (
        <RenderSkeleton />
      ) : (
        <>
          <Group template="auto 1fr" gap="24px" className="mbe-32">
            <Icon
              hasBackground
              icon="MdImage"
              color="#35A447"
              size="2.4em"
              iconContainerStyle={{ padding: 19, backgroundColor: "#D3F9DD" }}
            />
            <Group template="1fr" gap="16px" className="flexYcenter">
              <Text color="#000" className="bold" block>
                {overview?.Type?.children?.[0]?.name}
              </Text>

              <Row className={styles.productInfoMain}>
                <Col className={styles.productInfo}>
                  <IconHome size={20} className={cx("d-grid", styles.icon)} />
                  <small className="gray-800">Purpose</small>
                  <strong>{joinBytItems([purpose, "Secondary"], " - ")}</strong>
                </Col>
                {overview?.InterestLocations?.[0]?.locality?.name && (
                  <Col className={styles.productInfo}>
                    <IconApartment size={20} className={cx("d-grid", styles.icon)} />
                    <small className="gray-800">Location</small>
                    <strong>{overview.InterestLocations[0].locality.name}</strong>
                  </Col>
                )}
                {overview?.saleabilityData?.price && (
                  <Col className={styles.productInfo}>
                    <IcCoins size={16} className={cx("d-grid", styles.icon)} />
                    <small className="gray-800">Asking Price</small>
                    <strong>
                      <Number tooltip isCurrency isStringOnly value={overview?.saleabilityData?.price} />
                    </strong>
                  </Col>
                )}
              </Row>
            </Group>
          </Group>
          <Group gap="16px">
            <DataTable
              pagination={{ totalCount: recommendedProperties?.count, pageOptions: [10, 25, 50] }}
              onChangePage={onChangePage}
              loading={isFetchingRecommendedProperties}
              error={errorRecommendedProperties}
              skeletonLoading={errorRecommendedProperties}
              columns={columns}
              data={getDataRows}
              stickyOffset={-22}
              noUrlPush={true}
            />
          </Group>
        </>
      )}
    </Modal>
  )
})

const RenderSkeleton = () => {
  return (
    <>
      <Group template="auto 1fr" gap="24px" className="mbe-32">
        <Skeleton type="image" />
        <Group template="45ch" gap="16px">
          <Skeleton type="text" />
          <Row className={styles.productInfoMain}>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
          </Row>
        </Group>
      </Group>
      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {[1, 2, 3, 4, 5]?.map(e => (
          <List.Item>
            <Skeleton type="text" />
            <Skeleton type="text" />
          </List.Item>
        ))}
      </List>
    </>
  )
}

export default SellRentModal
