import React from "react"
import { RadioButtons, InputNumber, Number } from "components/common"
import { useSelector } from "react-redux"

const CurrencyPercentageInput = ({ field, onChange, totalPrice, maxAmount, maxPercentage, subtext }) => {
  const currency = useSelector(
    state =>
      state?.app?.user?.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode
  )

  const handleValueChange = value => {
    if (field?.inputType === "%") {
      return ((totalPrice * value) / 100).toFixed(0)
    } else {
      return value.toFixed(0)
    }
  }

  const handlePercentageChange = value => {
    if (field?.inputType === currency) {
      return ((value / totalPrice) * 100).toFixed(6)
    } else {
      return value.toFixed(6)
    }
  }

  return (
    <>
      <InputNumber
        placeholder={"Set Value"}
        value={field?.inputType === "%" ? field?.percentage : field?.value}
        controls={false}
        min={0}
        max={field?.inputType === "%" ? maxPercentage.toFixed(6) : maxAmount}
        style={{ width: "100%" }}
        onChange={val => {
          onChange({
            value: handleValueChange(val),
            percentage: handlePercentageChange(val),
            inputType: field?.inputType,
          })
        }}
        addonAfter={
          <RadioButtons
            options={[
              { key: 1, label: "%", value: "%" },
              { key: 2, label: currency, value: currency },
            ]}
            value={field?.inputType}
            onChange={option => {
              const { value, percentage } = field
              onChange({ value, percentage, inputType: option.target.value })
            }}
            className="flexNowrap"
            btnSize="small"
          />
        }
      />
      {subtext && (
        <div style={{ "--typography-color": "#9D9D9D" }}>
          {field?.inputType === "%" ? (
            <Number isCurrency value={field?.value} />
          ) : (
            <Number value={field?.percentage} digitAfterFraction={6} wrapValue={v => `${v} %`} />
          )}
        </div>
      )}
    </>
  )
}

export default CurrencyPercentageInput
