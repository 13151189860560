import { convertQueryObjToString } from "utils"
import parentApi from "store/parentApi"
import { transformCalendarEvents } from "./calendarTransformer"

const calendarApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getCalenderEvents: build.query({
      query: params => `events/getCalendarEvents?${convertQueryObjToString(params)}`,
      transformResponse: response => transformCalendarEvents(response),
      providesTags: ["calender-events"],
    }),
    getEventDetails: build.query({
      query: id => `/events/salesEvent/${id}`,
    }),

    addEvent: build.mutation({
      query: values => {
        return { url: `/events/salesEvent`, method: "POST", body: values }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["calender-events"] : []
      },
    }),

    updateEvent: build.mutation({
      query: values => {
        return { url: `/events/salesEvent`, method: "PUT", body: values }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["calender-events"] : []
      },
    }),

    addUnavailability: build.mutation({
      query: values => {
        return { url: `/events/`, method: "POST", body: values }
      },
      invalidatesTags: (result, error, args) => {
        return result ? ["calender-events"] : []
      },
    }),
    deleteUnavailabilityItem: build.mutation({
      query: id => {
        return { url: `/events/${id}`, method: "DELETE" }
      },
      invalidatesTags: (result, error, args) => {
        return result ? ["calender-events"] : []
      },
    }),
    deleteSaleEventItem: build.mutation({
      query: id => {
        return { url: `/events/salesEvent/${id}`, method: "DELETE" }
      },
      invalidatesTags: (result, error, args) => {
        return result ? ["calender-events"] : []
      },
    }),
  }),
})

export const {
  useAddEventMutation,
  useAddUnavailabilityMutation,
  useLazyGetCalenderEventsQuery,
  useGetCalenderEventsQuery,
  useLazyGetEventDetailsQuery,
  useDeleteUnavailabilityItemMutation,
  useDeleteSaleEventItemMutation,
  useUpdateEventMutation,
} = calendarApi
