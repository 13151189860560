import areaUnitTransformer from "./areaUnitTransformer"
import bankTransformer from "./bankTransformer"
import { convertQueryObjToString } from "utils"
import leadStageTransformer from "./leadStageTransformer"
import leadsTransfomer from "./leadsTransformer"
import newTaskTransformer from "./newTaskTransformer"
import parentApi from "store/parentApi"
import paymentMethodTransformer from "./paymentMethodTransformer"
import paymentModeTransformer from "./paymentModeTransformer"
import platformTransformer from "./platformTransformer"
import sharedWithTransformer from "./sharedWithTransformer"
import sourcesTransformer from "./sourcesTransformer"
import taskStatusTransformer from "./taskStatusTransformer"
import { dateTimeFunction } from "utils/date"

const clientApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getIndustries: build.query({
      query: () => `/industries`,
      transformResponse: response => response.map(e => ({ ...e, label: e.name, value: e.id.toString() })),
    }),

    getPaymentMethod: build.query({
      query: id => {
        return id ? { url: `/projects/project/paymentModes?projectId=${id}` } : { url: `/paymentModes` }
      },
      transformResponse: response => {
        const transformedData = paymentMethodTransformer(response)
        return transformedData
      },
    }),
    getUnits: build.query({
      query: projectId => `/definitions/getUnitsByProject/${projectId}`,
      transformResponse: response => {
        const transformedData = areaUnitTransformer(response)
        return transformedData
      },
    }),
    getNewTasks: build.query({
      query: () => `/taskTypes?taskTypeFor=lead&sortBy=displayOrder&sortDirection=asc&isActive=1`,
      transformResponse: response => {
        const transformedData = newTaskTransformer(response)
        return transformedData
      },
    }),
    getPaymentMode: build.query({
      query: parentId => `/paymentModes?parentId=${parentId}`,
      transformResponse: response => {
        const transformedData = paymentModeTransformer(response)
        return transformedData
      },
    }),

    getBankName: build.query({
      query: () => `/banks`,
      transformResponse: response => {
        const transformedData = bankTransformer(response)
        return transformedData
      },
    }),

    getLeadDetails: build.query({
      query: leadId => `/internalInquiries/${leadId}/leadDetails`,
      transformResponse: response => {
        const transformedData = leadsTransfomer(response)
        return transformedData
      },
    }),

    getValidateReceiptNumber: build.query({
      query: number => `/payments/validate/receipt?receiptNumber=${number}`,
    }),

    getValidateReferenceNumber: build.query({
      query: params => `payments/validate/referenceNumber?${convertQueryObjToString(params)}`,
    }),

    getLeadStages: build.query({
      query: () => `/InternalInquiryStages`,
      transformResponse: data => leadStageTransformer(data),
    }),

    getBuySellOverview: build.query({
      query: leadID => `/internalInquiries/${leadID}?includeSaleability=true`,
    }),

    getSellerRecommendedListings: build.query({
      query: params => `/listings/recommendedForSeller?${convertQueryObjToString(params)}`,
    }),

    getShortlistedListings: build.query({
      query: leadId => `/listingsShortlisted/${leadId}`,
      providesTags: ["shortlisted-leads"],
    }),

    getRecommendedListings: build.query({
      query: ({ leadId, params }) => `listings/recommendedForBuyer/${leadId}?${convertQueryObjToString(params)}`,
      providesTags: ["recommended-leads"],
    }),

    addShortlistedListings: build.mutation({
      query: obj => ({ url: "/listingsShortlisted", method: "POST", body: obj }),
      invalidatesTags: (result, _error, _args) => (result ? ["shortlisted-leads"] : []),
    }),

    deleteShortlistedListings: build.mutation({
      query: ({ overview, id }) => {
        let body = {
          listingId: id,
          inquiryId: overview?.InternalInquiryLocations?.[0]?.inquiryId,
        }
        return { url: `/listingsShortlisted`, method: "DELETE", body }
      },
      invalidatesTags: (result, _error, _args) => (result ? ["shortlisted-leads"] : []),
    }),

    getSourcesWithChildren: build.query({
      query: () => `/internalInquirySources/sourcesWithChildren`,
      transformResponse: data => {
        return sourcesTransformer(data)
      },
    }),

    getSharedWith: build.query({
      query: params =>
        `/teamMembers/privilegedUsers?${
          params ? convertQueryObjToString(params) : `sharedLeadUsers=true&module=leads&subModule=sharedLeadUsers`
        }`,
      transformResponse: data => {
        return sharedWithTransformer(data)
      },
    }),

    getAllocatedTo: build.query({
      query: params =>
        `/teamMembers/privilegedUsers?module=leads&subModule=searchLeads${
          params?.all ? "" : "&allocatedLeadUsers=true"
        }`,
    }),

    getPlatform: build.query({
      query: () => `/leadSources`,
      transformResponse: data => {
        return platformTransformer(data)
      },
    }),

    // TODO: remove this and user from shared

    getLeadTaskStatus: build.query({
      query: params => `/internalInquiryStatuses${params ? `?${convertQueryObjToString(params)}` : ""}`,
      transformResponse: data => {
        return taskStatusTransformer(data)
      },
    }),

    addSales: build.mutation({
      query: ({ lead, leadSalesInfo, bookingForm, banks, ...values }) => {
        const attachments = values?.modeOfPayment?.PaymentModeAttachmentTypeMappings?.map(e =>
          !!values[e.AttachmentType.key] ? values[e.AttachmentType.key] : null
        ).filter(e => !!e && typeof e === "object")

        let transformedDiscount
        if (values?.discountType === "%") {
          transformedDiscount = Math.round((values?.unitDiscount / 100) * values?.unitPrice)
        } else {
          transformedDiscount = values?.unitDiscount
        }

        let bankId = banks?.find(item => item.key === values?.depositBank)?.id

        let features = values?.featureTable?.features
          ?.filter(item => !item?.fixed)
          ?.map(item => ({
            id: item?.featureId,
            amountType: item?.amountType,
            amount: item?.amount,
          }))

        const payload = {
          projectId: !!values?.project ? values.project.id : leadSalesInfo?.projectId,
          listingId: !!values?.unit ? values.unit.id : leadSalesInfo?.listingId,
          ...(!!transformedDiscount && { discount: transformedDiscount }),
          dealPrice: !!values?.dealPrice ? values.dealPrice : leadSalesInfo?.dealPrice,
          ...(values?.cdpCommitment && { cdpCommitment: values.cdpCommitment }),
          Payment: {
            amount: values?.amount,
            mode: values?.modeOfPayment?.id,
            ...(values?.referenceNumber && { referenceNumber: values.referenceNumber }),
            receiptNumber: values?.receiptNumber,
            //Cheque
            ...(values?.chequeBank && { bankName: values.chequeBank }),
            ...(values?.fromAccountNumber && { fromAccountNumber: values.fromAccountNumber }),
            ...(values?.chequeDate && { chequeDate: values.chequeDate }),
            //deposit slip
            ...(values?.depositBank && { beneficiaryBankId: bankId }),
            ...(values?.depositSlipNumber && { transactionReferenceNumber: values.depositSlipNumber }),
            ...(values?.depositDate && { depositDate: values.depositDate }),
            ...(values?.accountNumber && { beneficiaryAccountNumber: values.accountNumber }),
            ...(values?.depositBank && { bankKey: values.depositBank }),
            ...(values?.paymentType?.key && { modeDisposition: values.paymentType.key }),
            ...(values?.depositBank && { beneficiaryInternationalBankName: null }),

            //check which submitMode
            ...([1, 2, 3].includes(values?.modeOfPayment?.id) &&
              values?.bank_deposit_slip && {
                submitMode: typeof values?.bank_deposit_slip !== "object" ? "RACC" : "self_submit",
              }),
            ...(typeof values?.bank_deposit_slip !== "object" && { regionalAccountantId: values?.bank_deposit_slip }),

            //OnlinePayment
            ...(values?.bank && { bankName: values.bank }),
            ...(values?.transactionNumber && { referenceNumber: values?.transactionNumber }),
            ...(values?.fromAccountTitle && { fromAccountTitle: values?.fromAccountTitle }),
            ...(values?.beneficiaryAccountTitle && { beneficiaryAccountTitle: values?.beneficiaryAccountTitle }),
            ...(values?.beneficiaryAccountNumber && { beneficiaryAccountNumber: values?.beneficiaryAccountNumber }),
            ...(values?.transactionDate && { depositDate: values?.transactionDate }),

            //PayOrder
            ...(values?.payorderBank && { bankName: values.payorderBank }),
            ...(values?.payorderNumber && { referenceNumber: values?.payorderNumber }),
            ...(values?.beneficiaryAccountNumberPayorder && {
              fromAccountNumber: values?.beneficiaryAccountNumberPayorder,
            }),

            //  "internationalBank": "Optional: international bank name"
          },
          attachments: attachments?.map(e => {
            return {
              file: e.url.split(",")[1],
              name: e.name,
              attachmentTypeId: e.attachmentTypeId,
              trackingId: e?.imageData?.id,
            }
          }),
          Client: {
            ...(values?.nationalId && { nationalId: values.nationalId }),
            ...(values?.dateOfBirth && { dateOfBirth: values.dateOfBirth }),
            ...(values?.country && { countryId: values.country.id }),
            ...(values?.city && { cityId: values.city.id }),
          },
          comment: values?.comment,
          ...(!!bookingForm && { addBookingForm: bookingForm }),
          features,
          ...(leadSalesInfo?.contracts?.[0] && { contractListingId: leadSalesInfo?.contracts?.[0]?.contractListingId }),
          nextStatusDeadline: values?.deadLine,
          ...(values?.nextTask !== 0 && { nextStatusId: values?.nextTask }),
          source: "web_v2",
        }

        return { url: `/internalInquiries/${lead?.id}/sale`, method: "POST", body: payload }
      },
      invalidatesTags: (resp, error, args) =>
        !!error ? [] : ["client-detail", "todos", { type: "leads", id: args.lead.Client.id }, "leads"],
    }),

    addReviewLead: build.mutation({
      query: ({ user, lead, ...values }) => {
        const body = {
          inquiryId: lead?.id || values.leadId,
          feedbackType: "lead_review",
          feedbackFields: [
            { typeId: 1, rating: values.callRecording, comment: values.additionalComment },
            { typeId: 2, rating: values.progressOnTask, comment: values.additionalComment2 },
          ],
          feedback: values.feedback,
          suggestedTaskTypeId: values.proposedNextTask.id,
          reviewerUserId: user?.id,
          agencyId: user.Agent.id,
        }
        return { url: "/feedbacks/inquiry", method: "POST", body }
      },
    }),

    getAgencyClients: build.query({
      query: params => `/clients/agencyClients?${convertQueryObjToString(params)}`,
    }),

    getRecentAgencyClients: build.query({
      query: params => `/clients/agencyClients?${convertQueryObjToString(params)}`,
    }),

    getAccountants: build.query({
      query: params => `/users/regionalAccountants`,
    }),
    getDataFromImage: build.mutation({
      query: params => {
        let body = {
          attachmentTypeId: params.attachmentTypeId,
          file: params.file?.split(",")[1],
          name: params.name,
        }
        return { url: `/attachments/imageToText`, method: "POST", body }
      },
    }),
    getPaymentList: build.query({
      query: params => `/payments/searches?${convertQueryObjToString(params)}`,
    }),

    getPaymentUrl: build.query({
      query: contractId => `/payments/${contractId}?receiptLink=true`,
    }),
    getLeadReviews: build.query({
      query: params => `/inquiryFeedbacks?feedbackType=lead_review&${convertQueryObjToString(params)}`,
    }),
    addCloseLost: build.mutation({
      query: ({ leadId, ...values }) => {
        const body = {
          comment: values.comment,
          closureReasonId: values.reason,
          source: "web_v2",
        }
        return { url: `/internalInquiries/${leadId}/markClosedLost`, method: "POST", body }
      },
      invalidatesTags: (result, _error, _args) => (result ? ["leads", "todos"] : []),
    }),
    getCloseLostReasons: build.query({
      query: () => `/internalInquiryClosureReasons`,
      transformResponse: response =>
        response.map(e => {
          return { ...e.response, name: e.reason, id: e?.id.toString() }
        }),
    }),

    getSaleEvent: build.query({
      query: id => `/events/activeSaleEvents/${id}`,
    }),

    getContractExpiryDate: build.query({
      query: ({listingId,params}) => `/listings/tokenExpiry/${listingId}?${convertQueryObjToString(params)}`,
    }),
  }),

  overrideExisting: false,
})

export const {
  useLazyGetPaymentUrlQuery,
  useGetPaymentUrlQuery,
  useLazyGetPaymentListQuery,
  useLazyGetAccountantsQuery,
  useGetAccountantsQuery,
  useGetDataFromImageMutation,
  useGetIndustriesQuery,
  useGetPaymentMethodQuery,
  useLazyGetPaymentMethodQuery,
  useLazyGetPaymentModeQuery,
  useLazyGetUnitsQuery,
  useGetUnitsQuery,
  useAddSalesMutation,
  useAddReviewLeadMutation,
  useGetNewTasksQuery,
  useLazyGetNewTasksQuery,
  useLazyGetLeadDetailsQuery,
  useLazyGetValidateReceiptNumberQuery,
  useLazyGetValidateReferenceNumberQuery,
  useLazyGetBankNameQuery,
  useGetLeadDetailsQuery,
  useLazyGetSourcesWithChildrenQuery,
  useGetSourcesWithChildrenQuery,
  useLazyGetSharedWithQuery,
  useLazyGetAllocatedToQuery,
  useLazyGetPlatformQuery,
  useLazyGetLeadTaskStatusQuery,
  useLazyGetTaskAddedByQuery,
  useLazyGetAgencyClientsQuery,
  useLazyGetRecentAgencyClientsQuery,
  useLazyGetLeadStagesQuery,
  useLazyGetBuySellOverviewQuery,
  useLazyGetShortlistedListingsQuery,
  useLazyGetRecommendedListingsQuery,
  useAddShortlistedListingsMutation,
  useDeleteShortlistedListingsMutation,
  useLazyGetSellerRecommendedListingsQuery,
  useLazyGetLeadReviewsQuery,
  useAddCloseLostMutation,
  useLazyGetCloseLostReasonsQuery,
  useGetSaleEventQuery,
  useLazyGetSaleEventQuery,
  useLazyGetContractExpiryDateQuery,
} = clientApi
