import { Avatar as AntdAvatar } from "antd"
import { Avatar } from "components/common"
import React from "react"
import Tooltip from "../tooltip/tooltip"
import styles from "./index.module.less"
import { localisedValue } from "utils"

function AvatarGroup({
  source,
  maxCount = 3,
  groupSize = "medium",
  placement = "top",
  currentClickedItemKey = null,
  handleClick = () => {},
  ...rest
}) {
  return (
    <AntdAvatar.Group maxCount={maxCount} size={groupSize} {...rest}>
      {source?.map((e, index) => (
        <Tooltip title={localisedValue({ object: e, key: "name" })} placement={placement}>
          <Avatar
            onClick={() => handleClick(e, index)}
            src={e?.logo}
            key={`${e.name}-${index}`}
            colorKey={e.name?.[0]?.toLowerCase()}
            className={currentClickedItemKey === e?.key && styles.active}
          >
            {e?.name?.[0]?.toUpperCase()}
          </Avatar>
        </Tooltip>
      ))}
    </AntdAvatar.Group>
  )
}

export default AvatarGroup
