import { Col, InputNumber, Row, Slider, Space } from "antd"

import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { numberFormat } from "utils"
import { Dropdown, Number, Text } from ".."
import styles from "./index.module.less"
import { useTranslation } from "react-i18next"

const UnitRangeSlider = props => {
  const { t } = useTranslation()
  const { rangeList = [], steps, setField, value = [], label, unitList = [], defaultUnitKey = "" } = props
  const [searchParams, setSearchParams] = useSearchParams({})
  const queryObj = Object.fromEntries([...searchParams])
  const [selectedUnit, setSelectedUnit] = useState(defaultUnitKey)
  const menuStyle = { overflowY: "auto", maxHeight: 240 }
  const [rangeValue, setRangeValue] = useState(value)
  const getRangeValue = useCallback(
    index => {
      const unitType = rangeList.find(item => item.key === selectedUnit.key)
      return unitType?.value?.[index]
    },
    [selectedUnit]
  )

  const marks = {
    [getRangeValue(0)]: {
      label: rangeValue[0] ? <Number unit={t(selectedUnit.key)} isStringOnly value={rangeValue[0]} /> : undefined,
    },
    [getRangeValue(1)]: {
      label: rangeValue[1] ? <Number unit={t(selectedUnit.key)} isStringOnly value={rangeValue[1]} /> : undefined,
    },
  }

  useEffect(() => {
    setRangeValue(value)
  }, [value])

  useEffect(() => {
    const filteredUnitKey = unitList.filter(item => item.value == queryObj?.areaUnitKey)
    if (filteredUnitKey?.length > 0) {
      setSelectedUnit({ key: filteredUnitKey[0].key, value: filteredUnitKey[0].value })
    }
  }, [])

  const onChange = value => {
    setRangeValue(value)
    setField({ range: value, rangeUnit: selectedUnit.value })
  }

  const onSelectUnit = value => {
    setRangeValue([])
    setSelectedUnit({ key: value.key, value: value?.item?.props?.value })
  }

  return (
    <Col>
      <Row justify={"space-between"}>
        <label className="d-block">{label}</label>
        <Dropdown
          menu={{
            items: unitList,
            onSelect: onSelectUnit,
            selectable: true,
            style: menuStyle,
          }}
          style={{ cursor: "pointer" }}
        >
          <Text>{t(selectedUnit.key)}</Text>
        </Dropdown>
      </Row>
      <Space style={{ alignItems: "baseline" }} className={styles.inputSpacer}>
        <InputNumber
          placeholder={t("Min")}
          min={getRangeValue(0)}
          max={getRangeValue(1)}
          style={{ width: "100%" }}
          value={rangeValue?.length > 0 ? `${numberFormat(rangeValue[0])}` : null}
          onChange={e => {
            onChange([e, rangeValue[1]])
          }}
        />
        <div style={{ marginInline: "8px" }}>{t("To")}</div>
        <InputNumber
          placeholder={t("Max")}
          min={getRangeValue(0)}
          max={getRangeValue(1)}
          style={{ width: "100%" }}
          value={rangeValue?.length > 0 ? `${numberFormat(rangeValue[1])}` : null}
          onChange={e => {
            onChange([rangeValue[0], e])
          }}
        />
      </Space>
      <Col span={24}>
        <Slider
          className={styles.rangeSlider}
          range
          marks={marks}
          min={getRangeValue(0)}
          max={getRangeValue(1)}
          onChange={onChange}
          value={rangeValue?.length > 0 ? rangeValue : []}
          tooltip={{ formatter: value => <Number unit={t(selectedUnit.key)} value={value} /> }}
        />
      </Col>
    </Col>
  )
}

export default UnitRangeSlider
