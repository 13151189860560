import store from "store"
const currency = store
  .getState()
  .app?.user?.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode

export const getFormattedPrice = (price, isNegative, prefix = currency) => {
  if (price === 0) {
    return (
      `${prefix} ` +
      `${isNegative ? "-" : ""}` +
      parseInt(Math.ceil(price))
        .toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .replace(/\.0$/, "")
        .replace(/\.00$/, "")
    )
  }
  if (price && !isNaN(price)) {
    return (
      `${prefix} ` +
      `${isNegative ? "-" : ""}` +
      parseInt(Math.ceil(price))
        .toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .replace(/\.0$/, "")
        .replace(/\.00$/, "")
    )
  } else if (price && isNaN(price)) {
    return `${prefix} ${isNegative ? "-" : ""}${price}`
  } else {
    return ""
  }
}
