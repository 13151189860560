import { t } from "i18next"
import * as yup from "yup"

const getReserveUnitValidationSchema = () =>
  yup.object().shape({
    leadId: yup.string().required(t("Lead Id is required")),
    reservationQueueTermsAndConditions: yup.bool().oneOf([true], t("Accept terms & conditions")).required(t("Accept terms & conditions")),
  })

export default getReserveUnitValidationSchema
