import allStaffMembers from "./staffMemberListTransformer"
import buyingIntents from "./buyingIntentTransformer"
import cities from "./citiesTransformer"
import classification from "./classificationTransformHelper"
import clientQuestion from "../../../tasks/api/clientQuestionTransformer"
import clientRatings from "./clientRatingTransfomer"
import clientTypes from "./clientTypeTransformer"
import countries from "./countryTransformer"
import inventoryProjectTransformer from "./inventoryProjectTransformer"
import localities from "./localityTransformer"
import occupations from "./occupationTransformer"
import projectBifurcatedUnits from "./projectBifurcatedUnitsTransformer"
import projects from "./projectTransformer"
import regions from "./regionsTransformer"
import staff from "./hierarchicalStaffTransformer"
import staffMembers from "./assigneeUserListTransformHelpler"
import taskTypes from "./getTaskTypeTransfromer"
import teamTypeTransformer from "./getTeamTypeTransfromer"
import unitNumberTransformer from "./unitNumberTransformer"

export default {
  allStaffMembers,
  staffMembers,
  buyingIntents,
  cities,
  clientRatings,
  clientTypes,
  countries,
  localities,
  occupations,
  projects,
  projectBifurcatedUnits,
  classification,
  clientQuestion,
  regions,
  staff,
  teamTypeTransformer,
  inventoryProjectTransformer,
  unitNumberTransformer,
  taskTypes,
}
