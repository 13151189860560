import { useEffect, useState } from "react"

import { Input } from "antd"
import { DATE_TIME_FORMAT } from "constants"
import { useTranslation } from "react-i18next"
import { dateTimeFunction } from "utils/date"
import DateRangePicker from "../dateRangePicker/dateRangePicker"
import SelectInput from "../select/select"
import TextInput from "../textInput/textInput"
import styles from "./index.module.less"
import PhoneInput from "../phoneInput/phoneInput"
import moment from "moment"

export default function UnitSelect(props) {
  const { t } = useTranslation()
  const {
    label,
    options,
    value,
    placeholder,
    getOptionLabel = option => option.label,
    getOptionValue = option => option.value,
    defaultOption,
    handleChange,
    useInternalState = true,
    componentType,
    disabled,
    loading,
    preventOnChange = true,
    className,
    getValues,
    splitBy = ",",
    selectOptionsList,
    selectMode,
    disableFuture = true,
    maxLength = 100,
  } = props
  const [option, setOption] = useState(options[0] || null)

  useEffect(() => {
    defaultOption && setOption(defaultOption)
  }, [defaultOption, options])

  const disabledDate = current => {
    const maxDate = new Date()
    return current.isAfter(moment(maxDate))
  }

  const getConvertedDateValue = dates => {
    return `${dateTimeFunction({
      date: dates[0].startOf("day").format(DATE_TIME_FORMAT),
      apiRequest: true,
      timeZone: true,
    })},${dateTimeFunction({
      date: dates[1].endOf("day").format(DATE_TIME_FORMAT),
      apiRequest: true,
      timeZone: true,
    })}`
  }

  const getDateValueFromString = value => {
    if (value) {
      return value?.split(splitBy)
    }
    return undefined
  }

  const getSelectedValue = () => {
    let currentSelectType = option
    let selectedOption = value?.[currentSelectType?.key]
    return !!selectedOption ? selectedOption?.split(",") : []
  }

  const getComponent = () => {
    switch (componentType) {
      case "date":
        return (
          <DateRangePicker
            onChange={dates => {
              dates && handleChange(option.value, getConvertedDateValue(dates))
            }}
            disabledDate={disableFuture ? disabledDate : null}
            value={getDateValueFromString(value?.[option?.value])}
            noDefault
            inputReadOnly
            {...option?.componentProps}
          />
        )
      case "select":
        return (
          <SelectInput
            value={getSelectedValue()}
            className="selectSearch togglesearch"
            style={{
              width: "100%",
            }}
            placeholder={placeholder ? placeholder : t("Search") + `${option?.label}`}
            disabled={disabled}
            mode={selectMode}
            options={selectOptionsList}
            loading={loading}
            allowClear
            onChange={(value, item) => {
              handleChange(option.value, value?.join(","))
            }}
          />
        )
      case "phone":
        return (
          <PhoneInput
            placeholder="Select"
            disabled={disabled}
            onChange={phone => {
              getValues({ key: option.value, value: phone })
            }}
            style={{ minWidth: 120 }}
          />
        )
      default:
        return (
          <TextInput
            value={value?.[option?.value]}
            placeholder={placeholder ? placeholder : t("Search By") + ` ${option?.label}`}
            useInternalState={useInternalState}
            disabled={disabled}
            loading={loading}
            maxLength={option?.maxLength || maxLength}
            {...(preventOnChange && {
              ...(useInternalState
                ? {
                    handleBlur(e) {
                      // option.allowInput && option.allowInput(e.target.value) &&
                      handleChange(option.value, e.target.value)
                    },
                  }
                : {
                    handleChange(e) {
                      // option.allowInput && option.allowInput(e.target.value) &&
                      if (getValues) {
                        getValues({ key: option.value, value: e.target.value })
                      } else {
                        handleChange(option.value, e.target.value)
                      }
                    },
                  }),
            })}
            onPressEnter={e => handleChange(option.value, e.target.value, true)}
          />
        )
    }
  }

  return (
    <div className={className}>
      <label style={{ display: "block" }}>{label}</label>
      <Input.Group style={{ display: "flex" }} className={styles.unitSelectFirst} compact>
        {getComponent()}
        <SelectInput
          placeholder=""
          value={option}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          disabled={disabled}
          onChange={(value, item) => {
            setOption(item)
            getValues && getValues(prev => ({ ...prev, key: value }))
          }}
          style={{ minWidth: 120 }}
        />
      </Input.Group>
    </div>
  )
}
