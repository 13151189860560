import Alert from "../alert/alert"
import { Drawer as AntdDrawer } from "antd"
import Icon from "../icon/icon"
import theme from "utils/themeVars"
import { useSelector } from "react-redux"

const Drawer = ({ error, footer, ...rest }) => {
  const { rtl } = useSelector(state => state.app.configs)

  return (
    <AntdDrawer
      placement={rtl ? "left" : "right"}
      closeIcon={<Icon icon="IoMdClose" size={24} color={theme["base-color"]} />}
      footer={
        footer !== null && (
          <>
            {error && (
              <Alert
                className="mbe-16"
                style={{ marginInline: -32, marginBlockStart: -20, textAlign: "start" }}
                banner
                type="error"
                showIcon
                {...error}
              />
            )}
            {footer}
          </>
        )
      }
      {...rest}
    />
  )
}
export default Drawer
