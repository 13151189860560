import { ConfigProvider } from "antd"
import { ErrorBoundary } from "components/custom"
import { getAppLanguage, useInitializeApp } from "configs/useInitializeApp"
import { configs } from "configs"
import { useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import PFRoutes from "routes"
import { inDevelopMode } from "utils/env"
import "./App.less"
import { useEffect } from "react"
import moment from "moment/moment"

const App = () => {
  const { direction } = useSelector(state => state.app.configs)
  const language = getAppLanguage()
  useInitializeApp()

  useEffect(() => {
    if (!language?.key) {
      window.location.replace("/" + configs.languages[0].key)
    }
  }, [])

  useEffect(() => {
    moment.locale(language?.locale)
    const htmlElement = document.getElementsByTagName("html")[0]
    htmlElement.lang = language?.locale
    htmlElement.dir = language.rtl ? "rtl" : "ltr"
    document.getElementById("link-app-fonts").href = language?.font
  }, [language?.locale])

  return (
    <ConfigProvider locale={language?.locale} direction={direction}>
      <BrowserRouter basename={language?.key || configs.languages[0].key}>
        {inDevelopMode ? (
          <PFRoutes />
        ) : (
          <ErrorBoundary>
            <PFRoutes />
          </ErrorBoundary>
        )}
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
