import { convertQueryObjToString } from "utils"
const { default: parentApi } = require("store/parentApi")

const appNotificationApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getNotification: build.query({
      query: ({ userId, ...params }) => `/appNotifications/${userId}?${convertQueryObjToString(params)}`,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        let { userId, pageOffset, pageSize } = queryArgs
        return { userId }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      merge: (currentData, incomingData, option) => {
        const current = currentData
        return {
          ...current,
          ...incomingData,
          data: [...current.data, ...incomingData.data],
          length: incomingData?.data?.length,
        }
      },
      providesTags: ["appNotification"],
    }),
    markAsReadNotification: build.mutation({
      query: ids => {
        return { url: `appNotifications`, method: "PUT", body: ids }
      },
    }),
  }),
})
export const { useLazyGetNotificationQuery, useMarkAsReadNotificationMutation } = appNotificationApi
