import { Button, Card, Tag } from "components/common"
import { Col, Row } from "antd"

import styles from "./tableBulkActionView.module.less"
import theme from "utils/themeVars"
import { useTranslation } from "react-i18next"

const TableBulkActionView = ({
  selectedRows,
  title,
  onCancel,
  renderButtons = () => null,
  extraComponent = () => null,
}) => 
{
  const { t } = useTranslation()
  return (
    !!selectedRows.length && (
      <Card className={styles.selectedRows} bodyStyle={{ padding: "20px 24px" }} bordered={false}>
        <Row gutter={{ xl: 24, xxl: 40 }} align="middle">
          <Col>
            <Tag color="#00A4D1">{selectedRows.length}</Tag>
            {title} {t("Selected")}
            {extraComponent()}
          </Col>
          {renderButtons()}
          <Col className="pi-0" style={{ marginInlineStart: "auto" }}>
            <Button
              text={t("Cancel")}
              type="link"
              iconProps={{ color: "#9D9D9D", size: "18px" }}
              onClick={onCancel}
              style={{ "--btn-color": theme["error-color"], "--btn-bg": "#fff", borderColor: "transparent" }}
            />
          </Col>
        </Row>
      </Card>
    )
  )
}

export default TableBulkActionView
