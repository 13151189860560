import { localisedValue } from "utils"

const hierarchicalStaffTransformer = staffArray => {
  const transformedArray = staffArray.map(item => {
    const { data, children } = item
    return {
      title: localisedValue({ object: data, key: "name" }),
      value: data?.id?.toString(),
      key: data?.id.toString(),
      regionId: data?.regionId,
      ...(children?.length && { children: hierarchicalStaffTransformer(children) }),
    }
  })
  return transformedArray
}

export default hierarchicalStaffTransformer
