import { DataTable, Icon, Text } from "components/common"
import { useLazyGetProfileSummaryQuery } from "features/shared/api"
import { useEffect, useMemo, useState } from "react"
import { convertCamelCaseToReadable } from "utils"
import { t } from "i18next"
import moment from "moment"
import { Number } from "../../../../../components/common"
import { Radio } from "antd"

const Kpi = ({ staffId, kpiType }) => {
  const [getProfileSummary, { data, isFetching, error }] = useLazyGetProfileSummaryQuery()
  const [formattedDates, setFormattedDates] = useState([])

  useEffect(() => {
    getProfileSummary({ staffId, load: "kpi", params: { reportType: kpiType } }, true)
  }, [getProfileSummary, staffId, kpiType])

  useEffect(() => {
    if (data && data.kpiDateRanges) {
      const formattedDates = data.kpiDateRanges.map(range => ({
        dateRange: `(${moment(range.from).format("Do MMM")} - ${moment(range.to).format("Do MMM")})`,
      }))
      setFormattedDates(formattedDates)
    }
  }, [data])

  const kpiColumns = useMemo(() => {
    const thisWeekTitle =
      formattedDates.length > 0 ? (
        <>
          {t("THIS WEEK")}
          <br />
          {formattedDates[0].dateRange}
        </>
      ) : (
        t("THIS WEEK")
      )

    const lastWeekTitle =
      formattedDates.length > 1 ? (
        <>
          {t("LAST WEEK")}
          <br />
          {formattedDates[1].dateRange}
        </>
      ) : (
        t("LAST WEEK")
      )

    return [
      { title: t("KPIs"), dataIndex: "kpiDetail", width: 130 },
      {
        title: t("Wow Comparison"),
        dataIndex: "wowComparison",
        width: 152,
      },
      {
        title: thisWeekTitle,
        dataIndex: "thisWeek",
        width: 180,
        render: (_, record) => <p>{record.thisWeek}</p>,
      },
      {
        title: lastWeekTitle,
        dataIndex: "lastWeek",
        width: 180,
        render: (_, record) => <p>{record.lastWeek}</p>,
      },
      {
        title: t("REGIONAL AVG."),
        dataIndex: "regionalAverage",
      },
    ]
  }, [formattedDates])

  const tableData = useMemo(() => {
    if (data && data.kpi) {
      return Object.entries(data.kpi).map(([kpiDetail, values], index) => {
        let wowComparisonValue = values.wowComparison
        return {
          key: index,
          kpiDetail: t(convertCamelCaseToReadable(kpiDetail)),
          wowComparison: (
            <Text
              color={wowComparisonValue > 0 ? "#009432" : wowComparisonValue < 0 ? "#EF2D36" : "initial"}
              className={`d-flex flexYcenter ${wowComparisonValue === 0 ? "" : "flexYcenter"}`}
            >
              {wowComparisonValue === 0 ? (
                <>{"0"}</>
              ) : (
                <>
                  <Number isStringOnly value={wowComparisonValue} wrapValue={val => `${val}%`} />
                  {wowComparisonValue > 0 ? <Icon icon="FaArrowUp" size={12} /> : <Icon icon="FaArrowDown" size={12} />}
                </>
              )}
            </Text>
          ),
          thisWeek: values.thisWeek,
          lastWeek: values.lastWeek,
          regionalAverage: values.regionalAverage,
        }
      })
    }
    return []
  }, [data])

  return (
    <>
      <DataTable
        stickyOffset={-24}
        error={error}
        data={tableData}
        noUrlPush={true}
        loading={isFetching}
        columns={kpiColumns || []}
      />
    </>
  )
}

export default Kpi
