import { Badge, List, Popover } from "antd"
import { Icon, Title } from "components/common"
import { useEffect, useMemo, useState } from "react"
import { useLazyGetNotificationQuery, useMarkAsReadNotificationMutation } from "./api/appNotificationApi"

import Button from "../../components/common/button/button"
import moment from "moment"
import theme from "utils/themeVars"
import { useQueryUpdate } from "hooks"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const AppNotification = ({ onClick }) => {
  const { t } = useTranslation()
  const userInfo = useSelector(state => state?.app?.user?.info)
  const [getAppNotification, { data, isFetching }] = useLazyGetNotificationQuery()
  const [markRead, { isLoading, isError, error, reset: resetErrorForAddTask }] = useMarkAsReadNotificationMutation()
  const [pageOffset, setPageOffset] = useState(0)
  const [update] = useQueryUpdate()

  useEffect(() => {
    userInfo?.id && getAppNotification({ userId: userInfo?.id, pageOffset: pageOffset, pageSize: 10 })
  }, [userInfo?.id])

  const onClickSingleRead = async item => {
    if (item.read === 0) {
      const response = await markRead({ ids: [item.id] })
      if (response.data) {
        update("getNotification", draft => ({
          ...draft,
          data: draft.data.map(e => (e.id === item.id ? { ...e, read: 1 } : e)),
          unreadCount: draft.unreadCount - 1,
        }))
      }
    }
  }

  const onClickAllRead = async () => {
    let ids = data.data.filter(e => e.read === 0).map(e => e.id)
    if (ids.length) {
      const response = await markRead({ ids })
      if (response.data) {
        update("getNotification", draft => ({
          ...draft,
          data: draft.data.map(e => (ids.find(it => it == e.id) ? { ...e, read: 1 } : e)),
          unreadCount: draft.unreadCount - ids?.length,
        }))
      }
    }
  }

  const onLoadMore = () => {
    getAppNotification({ userId: userInfo?.id, pageOffset: pageOffset + 1, pageSize: 10 })
    setPageOffset(pageOffset + 1)
  }

  const loadMore = useMemo(
    () =>
      data?.length !== 0 ? (
        <div style={{ textAlign: "center", marginTop: 12, height: 32, lineHeight: "32px" }}>
          <Button type="link" onClick={onLoadMore} loading={isFetching} disabled={isFetching}>
            {t("View More")}
          </Button>
        </div>
      ) : null,
    [data?.length, isFetching]
  )

  return (
    <Popover
      arrowPointAtCenter
      placement="bottomRight"
      content={
        <div style={{ overflowY: "auto", maxHeight: 500, width: 500 }}>
          <List
            loadMore={loadMore}
            loading={isFetching}
            itemLayout="horizontal"
            dataSource={data?.data}
            renderItem={(item, index) => (
              <div
                style={item?.read === 0 ? { background: "#dff1e0" } : {}}
                onClick={() => {
                  onClickSingleRead(item)
                }}
              >
                <List.Item className="pointer">
                  <List.Item.Meta
                    avatar={
                      <Icon
                        icon={item.type === "reminder" ? "AiFillLike" : "AiFillNotification"}
                        style={{ fontSize: "20px", color: "#009F2B", alignItems: "center" }}
                      />
                    }
                    title={item?.title}
                    description={
                      <>
                        {item?.content}
                        {!!item?.deepLink && (
                          <div>
                            <a href={item?.deepLink?.webUrl} target="_blank">
                              {item?.deepLink?.text}
                            </a>
                          </div>
                        )}
                      </>
                    }
                  />
                  <div style={{ marginLeft: 8 }}>{moment(item?.createdAt, "YYYYMMDD").fromNow()}</div>
                </List.Item>
              </div>
            )}
          />
        </div>
      }
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Title level={4}>{t("Notifications")}</Title>
          <Button type="link" onClick={onClickAllRead}>
            {t("Mark all as read")}
          </Button>
        </div>
      }
      trigger="click"
      getPopupContainer={node => node.parentNode}
    >
      <Badge count={data?.unreadCount} size="small" overflowCount={99} offset={[4, 0]}>
        <Icon
          className="pointer"
          icon="IoIosNotifications"
          size={22}
          style={{ color: theme["primary-color"] }}
          onClick={onClick}
        />
      </Badge>
    </Popover>
  )
}
export default AppNotification
