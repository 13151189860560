import { Card, Text } from "components/common"
import { InfoBlock } from "components/custom"
import { useMemo } from "react"

export const ClientInformation = ({ data }) => {
  const listData = useMemo(() => {
    return [
      { label: <Text>Name</Text>, data: <div className="semiBold">{data?.name || "None"}</div> },
      { label: <Text>Age</Text>, data: <div className="semiBold">{data?.age || "None"}</div> },
      { label: <Text>Gender</Text>, data: <div className="semiBold">{data?.gender || "None"}</div> },
      {
        label: <Text>Current Location</Text>,
        data: <div className="semiBold">{data?.location?.current || "None"}</div>,
      },
      {
        label: <Text> Preferred Investment Location</Text>,
        data: <div className="semiBold">{data?.location?.preferred_investment_locations || "None"}</div>,
      },
    ]
  }, [data])
  return (
    <Card variant="fill" style={{ borderColor: "#C7DEFC" }}>
      <InfoBlock size="small" data={listData} />
    </Card>
  )
}
