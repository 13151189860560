import { useEffect, useState } from "react"

import DateRangePicker from "../dateRangePicker/dateRangePicker"
import { DATE_TIME_FORMAT } from "constants"
import { dateTimeFunction } from "utils/date"
import moment from "moment"

export default function SimpleDateRangeFilter({
  onSelect,
  value: dateValue,
  valueFormat = DATE_TIME_FORMAT,
  label,
  splitBy = ",",
  ...rest
}) {
  const [value, setValue] = useState()

  const getConvertedDateValue = dates => {
    return `${dateTimeFunction({
      date: dates[0].startOf("day").format(valueFormat),
      apiRequest: true,
      timeZone: true,
    })},${dateTimeFunction({ date: dates[1].endOf("day").format(valueFormat), timeZone: true, apiRequest: true })}`
  }

  const getDateValueFromString = value => {
    if (value) {
      return value?.split(splitBy)
    }
    return undefined
  }

  useEffect(() => {
    setValue(!!dateValue ? dateValue : null)
  }, [dateValue])

  return (
    <>
      <label className="d-block">{label}</label>
      <DateRangePicker
        onChange={dates => {
          dates && onSelect(getConvertedDateValue(dates))
        }}
        // disabledDate={disabledDate}
        value={getDateValueFromString(value)}
        noDefault
        inputReadOnly
        {...rest}
      />
    </>
  )
}
