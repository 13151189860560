import { DatePicker as AntdDatePicker } from "antd"
import moment from "moment"
import { useSelector } from "react-redux"
import locale from "antd/es/date-picker/locale/ar_EG"

const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}
const disabledFutureTime = date => {
  if (date) {
    if (date?.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      return {
        disabledHours: () => range(moment().hour() + 1, 24),
        disabledMinutes: () => range(moment().minute(), 60),
      }
    }
  }
  return {}
}

const disabledPastTime = date => {
  if (date) {
    const currentMoment = moment()
    if (date.isSame(currentMoment, "day")) {
      return {
        disabledHours: () => range(0, currentMoment.hour()),
        ...(date.hour() === currentMoment.hour() && { disabledMinutes: () => range(0, currentMoment.minute()) }),
      }
    }
    return {
      disabledHours: () => range(0, 0),
      disabledMinutes: () => range(0, 0),
    }
  }
  return {}
}

const disabledTimeRange = (date,timerange) => {
  if (date) {
    const currentMoment = moment()
    if (date.isSame(currentMoment, "day")) {
      return {
        disabledHours: () => range(0, currentMoment.hour()),
        ...(date.hour() === currentMoment.hour() && { disabledMinutes: () => range(0, currentMoment.minute()) }),
      }
    }
    if (date?.format("YYYY-MM-DD") === timerange?.endDate.format("YYYY-MM-DD")) {
      return {
        disabledHours: () => range(timerange?.endDate.hour() + 1, 24),
        ...(date.hour() === timerange?.endDate.hour() && {disabledMinutes: () => range(timerange?.endDate.minute(), 60),})
      }
    }
    return {
      disabledHours: () => range(0, 0),
      disabledMinutes: () => range(0, 0),
    }
  }
  return {}
}

function disableDateRanges(range = { startDate: false, endDate: false }) {
  const { startDate, endDate } = range
  return function disabledDate(current) {
    if (moment(endDate).isSame(current, "day")) {
      if (current.hours() >= moment(endDate).hours()) {
        return false
      }
    }
    let startCheck = true
    let endCheck = true
    if (startDate) {
      startCheck = current < moment(startDate)
    }
    if (endDate) {
      endCheck = current > moment(endDate)
    }
    return (startDate && startCheck) || (endDate && endCheck)
  }
}

const disabledDateFuture = date => {
  return date && moment(date)?.isAfter(moment(), "day")
}

const disabledDatePast = date => {
  return date && moment(date)?.isBefore(moment(), "day")
}

const DatePicker = props => {
  const {
    disableFuture,
    disablePast,
    range,
    disableFutureTime,
    disablePastTime,
    disableTimeRange,
    showTime = true,
    onChange,

    ...rest
  } = props
  const { locale: appLanguage } = useSelector(state => state.app.configs)
  return (
    <AntdDatePicker
      disabledDate={
        !!disableFuture
          ? disabledDateFuture
          : !!disablePast
          ? disabledDatePast
          : !!range
          ? disableDateRanges(range)
          : null
      }
      showTime={showTime ? { hideDisabledOptions: true } : false}
      inputReadOnly
      disabledTime={
        !!disableFutureTime ? disabledFutureTime : !!disablePastTime ? date => disabledPastTime(date, rest.value) : !!disableTimeRange ? date => disabledTimeRange(date,range) :null
      }
      onChange={date => (disablePast && date?.isBefore(moment()) ? onChange(moment()) : onChange(date))}
      locale={appLanguage === "en" ? [] : locale}
      {...rest}
    />
  )
}

export default DatePicker
