import * as yup from "yup"
import { t } from "i18next"
const addToSalesPipelineSchemaRules = () =>
  yup.object().shape({
    projectId: yup.string().required(t("Please select a project")).nullable(),
    unit: yup.number().required(t("Please select a unit")).nullable(),
    probability: yup.number().required(t("Please add probability")).nullable(),
    stage: yup.string().required(t("Please select a stage")).nullable(),
    startDate: yup.string().required(t("Please select a startDate")).nullable(),
  })

export default addToSalesPipelineSchemaRules
