import * as yup from "yup"

import { cnicValidation, numValidation, numValidationGreater, stringValidationYup } from "helpers/validations"

import moment from "moment"
import { regex } from "constants/regex"
import { t } from "i18next"

const getSalesValidationSchema = () =>
  yup.object().shape({
    project: yup.object().required(t("Project Name is required")).nullable(),
    unit: yup.object().required(t("Unit is required")).nullable(),
    unitPrice: yup.number(t("Unit Price is required"), regex.numbersAndDecimal),
    unitDiscount: yup.object().required(t("Discount is required")).nullable(),
    dealPrice: yup.number(t("Deal Price is Required"), regex.numbersAndDecimal),
    nationalId: numValidation(t("National ID is required")).nullable(),
    country: yup.object().required(t("Country is required")).nullable(),
    city: yup.object().required(t("City is required")).nullable(),
    amount: numValidation(t("Amount is required")).nullable(),
    receiptNumber: stringValidationYup(null, regex.alphaNumeric()).nullable(),
    modeOfPayment: yup.object().required(t("Mode of payment is required")).nullable(),
    paymentProof: yup.array().of(yup.object().shape({ file: yup.mixed() })),
    comment: stringValidationYup(),
    discountType: yup.string().required(t("Discount type is required")).nullable(),
    referenceNumber: numValidation(t("Reference number is required")).nullable(),
    depositDate: yup.date().required(t("Deposit date is required")).nullable(),
    chequeDate: yup.date().required(t("Cheque date is required")).nullable(),
    bank: yup.string().required(t("Bank is required")).nullable(),
    depositBank: yup.string().required(t("Bank is required")).nullable(),
    chequeBank: yup.string().required(t("Bank is required")).nullable(),
    payorderBank: yup.string().required(t("Bank is required")).nullable(),
    paymentType: yup.object().required(t("Payment type is required")).nullable(),
    cdpCommitment: numValidation(t("CDP commitment is required")),
    dateOfBirth: yup.mixed().test(t("DOB", "Age must be greater than or equal to 18 years old"), value => {
      if (!!!value) {
        return true
      }
      return moment().diff(value, "years") >= 18
    }),

    beneficiaryAccountNumber: stringValidationYup(
      t("Beneficiary account  number is required"),
      regex.alphaNumeric()
    ).nullable(),
    beneficiaryAccountNumberPayorder: stringValidationYup(
      t("Beneficiary account  number is required"),
      regex.alphaNumeric()
    ).nullable(),
    payorderNumber: numValidation(t("Pay Order number is required")).nullable(),
    depositSlipNumber: numValidation(t("Deposit Slip number is required")).nullable(),
    fromAccountTitle: stringValidationYup().nullable(),
    fromAccountNumber: stringValidationYup(null, regex.alphaNumeric()).nullable(),
    beneficiaryAccountTitle: stringValidationYup().nullable(),
    beneficiaryAccountNumber: stringValidationYup(null, regex.alphaNumeric()).nullable(),
    transactionDate: yup.date().nullable(),
    accountNumber: stringValidationYup(null, regex.alphaNumeric()).nullable(),
    transactionNumber: stringValidationYup().nullable(),
    nextTask: yup.number().nullable(),
    deadLine: yup.object().required(t("Deadline is required")).nullable(),
  })

export default getSalesValidationSchema
