import cx from "clsx"
import React from "react"
import styles from "./index.module.less"
import propTypes from "prop-types"

const Group = ({ className, children, gap, template, style, ...rest }) => {
  const groupStyles = (React.CSSProperties = {
    "--gap": gap,
    "--template": template,
  })

  return (
    <div className={cx(className, styles.group)} style={{ ...groupStyles, ...style }} {...rest}>
      {children}
    </div>
  )
}

Group.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  gap: propTypes.string,
  template: propTypes.string,
  style: propTypes.object,
}

export default Group
