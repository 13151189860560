import { localisedValue } from "utils"

const sharedWithTransformer = response => {
  return response.map(e => {
    return {
      ...e,
      label: localisedValue({ object: e, key: "team" }),
      options: e.users.map(it => {
        return { ...it, label: localisedValue({ object: it, key: "name" }), value: it.id.toString(), id: it.id }
      }),
    }
  })
}

export default sharedWithTransformer
