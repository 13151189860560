import { Icon, PopOver } from "components/common"

import { Col, List, Row } from "antd"
import SenderReceiver from "components/common/senderReceiver/senderReceiver"
import { joinBytItems } from "utils"
import { useLazyGetCurrentLeadSharingQuery } from "features/clients/detail/api"
import { t } from "i18next"

const ViewSharingDetail = lead => {
  const [fetchData, { data, isFetching }] = useLazyGetCurrentLeadSharingQuery()

  const getSharingList = () => {
    return [
      ...(data?.[lead.leadId].sales.length > 0
        ? data?.[lead.leadId].sales.map(item => {
            return {
              sharedWith: t("Sale"),
              date: item.dateTime,
              data: [
                [t("Purpose"), data?.salePurposeTitles[item.id]],
                [t("Commission"), item?.commission && `${item.commission}%`],
                [
                  t("By"),
                  joinBytItems(
                    [`${item.sharedBy.name} (${item.sharedBy.UserDesignation?.short})`, item.sharedBy.cell],
                    " | "
                  ),
                ],
                [
                  t("To"),
                  joinBytItems(
                    [
                      `${item.sharedWith.name}  ${
                        item.sharedWith.UserDesignation?.short && `( ${item.sharedWith.UserDesignation?.short})`
                      }  `,
                      item.sharedWith.cell,
                    ],
                    " | "
                  ),
                ],
              ],
            }
          })
        : []),
      ...(data?.[lead.leadId].support.length > 0
        ? data?.[lead.leadId].support.map(item => {
            return {
              sharedWith: t("Support"),
              date: item.dateTime,
              data: [
                [
                  t("By"),
                  joinBytItems(
                    [`${item.sharedBy.name} (${item.sharedBy.UserDesignation?.short})`],
                    <Icon icon="GoDotFill" size=".7em" color="#000000" />
                  ),
                ],
                [
                  t("To"),
                  joinBytItems(
                    [
                      `${item.sharedWith.name}  ${
                        item.sharedWith.UserDesignation?.short ? `( ${item.sharedWith.UserDesignation?.short})` : ""
                      }  `,
                      item.sharedWith.cell,
                    ],
                    <Icon icon="GoDotFill" size=".7em" color="#000000" />
                  ),
                ],
              ],
            }
          })
        : []),
    ]
  }

  return (
    <PopOver
      trigger="click"
      content={getSharingList().map(e => (
        <>
          <SenderReceiver
            sender={t("Lead Shared With")}
            icon="IconDoubleArrowRight"
            receiver={`${e.sharedWith}`}
            date={e.date}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          />
          <List
            loading={isFetching}
            size="small"
            dataSource={e.data}
            renderItem={item => (
              <List.Item>
                <Row justify={"space-between"} className="w-100" gutter={16}>
                  <Col span={4}>
                    <div className="gray-700">{item[0]}</div>
                  </Col>
                  <Col span={20} align="right">
                    {item[1]}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </>
      ))}
      overlayInnerStyle={{ width: 350 }}
    >
      <Icon
        hasBackground
        icon="BsShare"
        color="#707070"
        size="18px"
        style={{ margin: -7, padding: 3 }}
        className="pointer"
        iconContainerStyle={{ translate: "0 3px" }}
        onClick={e => {
          fetchData(lead.leadId)
          e.stopPropagation()
        }}
      />
    </PopOver>
  )
}

export default ViewSharingDetail
