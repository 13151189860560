import { Icon, PopOver, Skeleton } from "components/common"

import { List } from "antd"

const InfoList = ({ list = [], onClick, loading = false, ...rest }) => {
  const renderLoadingState = () => (
    <List
      size="small"
      dataSource={[0, 1, 2, 3]}
      onClick={e => e.stopPropagation()}
      renderItem={item => (
        <List.Item>
          <Skeleton height={30} />
          <Skeleton height={20} width={120} />
        </List.Item>
      )}
      {...rest}
    />
  )
  return (
    <PopOver
      trigger="click"
      content={
        loading ? (
          renderLoadingState()
        ) : (
          <List
            size="small"
            dataSource={list.filter(e => !!e[1])}
            onClick={e => e.stopPropagation()}
            renderItem={item => (
              <List.Item>
                {item[0] === "heading" ? (
                  <strong>{item?.[1]}</strong>
                ) : (
                  <>
                    <div className="gray-700">{item[0]}</div>
                    <div style={{ textAlign: "right" }}>{item[1]}</div>
                  </>
                )}
              </List.Item>
            )}
            {...rest}
          />
        )
      }
      overlayInnerStyle={{ width: 335 }}
    >
      <Icon
        hasBackground
        icon={"BsInfo"}
        color="#707070"
        size="16px"
        style={{ backgroundColor: "#E6E6E6", borderRadius: "1rem", "--icon-color": "#707070", margin: -8 }}
        className="pointer"
        iconContainerStyle={{ translate: "0 0px" }}
        onClick={onClick}
      />
    </PopOver>
  )
}

export default InfoList
