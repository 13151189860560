import { useEffect, useState } from "react"
import PaymentProofImageUpload from "./paymentProofImageUpload"
import { useGetAccountantsQuery, useLazyGetAccountantsQuery } from "features/leads/api"
import { RadioButtons, SelectInput } from "components/common"
import { useTranslation } from "react-i18next"

export default function DepositSlipSelect(props) {
  const { setFiles, paymentProofProps, setRA, ...rest } = props
  const { t } = useTranslation()

  const [option, setOption] = useState("self_submit")

  const options = [
    { label: t("Self Submit"), key: "self_submit", id: 1 },
    { label: t("By RA"), key: "regional_accountant", id: 2 },
  ]

  const [getAccountant, { data: accountantData, isLoading, isFetching }] = useLazyGetAccountantsQuery()

  useEffect(() => {
    if (option === "regional_accountant") {
      getAccountant(null, true)
    }
  }, [option])

  const getComponent = () => {
    return option === "self_submit" ? (
      <PaymentProofImageUpload
        setFiles={setFiles}
        paymentProofProps={paymentProofProps}
        platformKey={rest?.platformKey}
      />
    ) : (
      <SelectInput
        placeholder={t("Choose RA")}
        options={accountantData}
        getOptionLabel={item => item.name}
        getOptionValue={item => item.id}
        onChange={value => {
          setFiles(value)
        }}
        style={{ minWidth: 120 }}
      />
    )
  }

  return (
    <>
      <RadioButtons
        className="mbe-16"
        value={option}
        options={options}
        getValue={item => item.key}
        onChange={value => {
          setFiles(null)
          setOption(value.target.value)
          setRA(value.target.value)
        }}
      />
      {getComponent()}
    </>
  )
}
