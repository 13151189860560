import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { configs } from "configs"

export const odysseyApi = createApi({
  reducerPath: "odysseyApi",
  baseQuery: fetchBaseQuery({ baseUrl: configs.odysseyApiURL }),

  endpoints: builder => ({
    createSession: builder.mutation({
      query: body => ({ url: `/v1/chat_sessions`, method: "POST", body: body }),
    }),
    sendChatMessage: builder.mutation({
      query: body => {
        return { url: `/v1/chat_sessions/${body.sessionkey}/send_message`, method: "POST", body: { query: body.query } }
      },
    }),

    getChatSessions: builder.query({ query: () => `/v1/chat_sessions?offset=0&limit=2` }),

    getChatSessionsMessage: builder.query({ query: sessionkey => `/v1/chat_sessions/${sessionkey}/session_messages` }),
  }),
})

export const {
  useCreateSessionMutation,
  useGetChatSessionsQuery,
  useSendChatMessageMutation,
  useLazyGetChatSessionsQuery,
  useLazyGetChatSessionsMessageQuery,
} = odysseyApi
