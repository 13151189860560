import { Button, Card, Group, Icon, Modal, Tag, TextInput, Alert } from "components/common";
import { Form, Row } from "antd";
import { convertQueryObjToString, mapObjectToQueryString, mapQueryStringToFilterObject } from "utils";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import {
  useDeleteSearchesMutation,
  useGetSavedSearchesQuery,
  useSaveSearchesMutation,
} from "features/clients/listings/api";
import { useLocation, useSearchParams } from "react-router-dom";

import { ACTION_TYPES } from "utils/gtm/gtmEventsData";
import { IconText } from "components/custom";
import { IconCopyToClipBoard } from "components/svg";
import Space from "components/common/space/space";
import cx from "clsx";
import { fireEvent } from "utils/gtm/gtmEventHandler";
import styles from "./index.module.less";
import theme from "utils/themeVars";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SavedSearches = forwardRef(
  (
    {
      className,
      moduleObject,
      applySavedFilter = () => { },
      onSaveSearch = () => { },
      onClickSearch = () => { },
      ignoreKeys = [],
      onClickSaveSearchManage = () => { },
      label,
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.app.user?.info);
    const [searchParams, setSearchParams] = useSearchParams();
    const [alertVisible, setAlertVisible] = useState(false);
    const modalRef = useRef();
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const location = useLocation();
    const {
      data: savedSearches,
      isLoading: savedSearchesLoading,
      isFetching: savedSearchesFetching,
      isError: savedSearchesError,
      error,
    } = useGetSavedSearchesQuery({ ...moduleObject, userId: user?.id }, { skip: !user });
    const [
      saveSearches,
      { data: savedData, isLoading: savingSearch, error: onSaveSearchError, reset: onSaveRestCache },
    ] = useSaveSearchesMutation();

    const filterFromDateKeys = () => {
      let queryObject = mapQueryStringToFilterObject(location.search);
      const filteredObj = Object.keys(queryObject?.queryObj)
        .filter((key) => !ignoreKeys.includes(key))
        .reduce((res, key) => ({ ...res, [key]: queryObject?.queryObj[key] }), {});
      return "?" + convertQueryObjToString(filteredObj);
    };

    const handleSaveSearch = async (values) => {
      try {
        const data = await saveSearches({
          name: encodeURIComponent(values.name.trim()),
          url: encodeURIComponent(ignoreKeys ? filterFromDateKeys() : location.search),
          moduleObject,
          userId: user?.id,
        }).unwrap();
        modalRef.current.setVisible(false);
        form.resetFields();
        onSaveRestCache();
        onSaveSearch({ status: "200" });
      } catch (e) {
        onSaveSearch({ status: e.status });
      }
    };

    const [deleteSavedSearch, { }] = useDeleteSearchesMutation();

    const deleteSearch = (id) => {
      deleteSavedSearch({ id, moduleObject, userId: user?.id });
    };

    const copySearch = (url) => {
      const fullUrl = `${window.location.origin}${window.location.pathname}${decodeURIComponent(url)}`;
      navigator.clipboard.writeText(fullUrl).then(() => {
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 3000);
      });
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const openSaveSearchModal = () => {
      modalRef.current?.setVisible(true);
    };

    const applyFilter = (item) => {
      const url = item.url && decodeURIComponent(item.url).replace(/\+/g, "%20");
      let queryObj = url && mapQueryStringToFilterObject(decodeURIComponent(url)).queryObj;
      if (!!Object.keys(queryObj).length) {
        setSearchParams(queryObj);
        applySavedFilter(queryObj);
      }
    };

    useEffect(() => {
      savedSearches?.count === 0 && handleCancel();
    }, [savedSearches]);

    useImperativeHandle(
      ref,
      () => ({
        openSaveSearchModal,
      }),
      []
    );

    const showSearches = !!savedSearches?.data?.length;

    return (
      <div className={className}>
        {showSearches && (
          <Row style={{ rowGap: 8, columnGap: 8 }}>
            <Space size={12}>
              {label ? (
                <IconText icon="RiBookmarkFill" iconProps={{ color: "#9D9D9D" }} title={label} />
              ) : (
                <Icon icon="RiBookmarkFill" color="#9D9D9D" />
              )}
              <div className={cx(styles.appliedTags)}>
                {savedSearches?.data?.map((item) => {
                  const url = decodeURIComponent(item.url);
                  const isApplied = url === location.search;
                  return (
                    <Tag
                      key={item.url}
                      checked={isApplied}
                      style={{
                        "--tag-color": isApplied ? theme["primary-color"] : "",
                        "--tag-bg": isApplied ? theme["primary-light"] : "#fff",
                      }}
                      onClick={() => {
                        applyFilter(item);
                        onClickSearch(item);
                      }}
                    >
                      <span>{decodeURIComponent(item.name)}</span>
                      {isApplied && (
                        <Button
                          icon={<IconCopyToClipBoard/>}
                          onClick={(e) => {
                            e.preventDefault();
                            copySearch(item.url);
                            e.currentTarget.blur(); 
                          }}
                          style={{ "--btn-bg": "transparent"}}
                        />
                      )}
                    </Tag>
                  );
                })}
              </div>
              <Button
                className="pi-0"
                type="link"
                size="small"
                style={{ "--btn-color": theme["accent-color"] }}
                onClick={() => {
                  showModal();
                  onClickSaveSearchManage();
                }}
              >
                {t("Manage")}
              </Button>
              <Modal title={t("Manage Quick Search")} open={isModalOpen} onCancel={handleCancel}>
                <Group className={cx(styles.appliedTags)} style={{ gap: "16px", display: "grid" }}>
                  {savedSearches?.data?.map((item) => {
                    return (
                      <Card
                        className="pointer"
                        style={{ boxShadow: "none" }}
                        bodyStyle={{ padding: "12px 16px" }}
                        bordered
                        key={item?.url}
                      >
                        <Row justify="space-between" className="fs16">
                          {decodeURIComponent(item.name)}
                          <div>
                            <Button
                              icon={<Icon color="#e83a3a" icon={"FiTrash2"} size="16px" />}
                              onClick={(e) => {
                                e.preventDefault();
                                deleteSearch(item.id);
                              }}
                              style={{ "--btn-bg": "transparent" }}
                            />
                          </div>
                        </Row>
                      </Card>
                    );
                  })}
                </Group>
              </Modal>
            </Space>
          </Row>
        )}
        <Modal
          ref={modalRef}
          onCancel={() => {
            modalRef?.current?.setVisible(false);
            form.resetFields();
            onSaveRestCache();
          }}
          onOk={() => {
            form.submit();
          }}
          okText={t("Save")}
          title={t("Quick Search")}
          loading={savingSearch}
          footerAlert={onSaveSearchError}
          width={500}
        >
          <Form
            form={form}
            initialValues={{ name: "" }}
            onFinish={(values) => {
              handleSaveSearch(values);
            }}
            layout="vertical"
          >
            <Form.Item
              getValueFromEvent={(e) => {
                return e.target.value?.length <= 256 ? e.target.value : getFieldValue("name");
              }}
              label={t("Name")}
              name="name"
              rules={[
                { required: true, message: t("Please input your search name!") },
                {
                  validator: (_, value) => {
                    if (!!savedSearches?.data?.find((item) => item.name === value.trim())) {
                      return Promise.reject(t("A search with this name already exists"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextInput placeholder={t("Enter your search name")} />
            </Form.Item>
          </Form>
        </Modal>
        {alertVisible && (
          <Alert
            message={t("URL copied to clipboard!")}
            type="success"
          />
        )}
      </div>
    );
  }
);

export default SavedSearches;
