import { Alert, Group, Modal, RadioButtons, SelectInput, TextInput } from "components/common"
import { useAddReviewLeadMutation, useLazyGetNewTasksQuery } from "features/leads/api"

import { Form } from "antd"
import { IconText } from "components/custom"
import { getAddReviewInitialValues } from "./addReviewInitialValues"
import getAddReviewSchema from "./addReviewSchema"
import { schemaRules } from "features/shared/utils"
import theme from "utils/themeVars"
import { useSelector } from "react-redux"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES, CONTENT_TYPES } from "utils/gtm/gtmEventsData"
import { ratingArray } from "constants/list"
import { useTranslation } from "react-i18next"

export default function AddReviewLead({ lead, client, formRef, from = {}, clientLeads }) {
  const { t } = useTranslation()
  const user = useSelector(state => state.app.user.info)
  const [form] = Form.useForm()
  const selectedLeadId = Form.useWatch("leadId")
  const [getTasks, { data, isFetching }] = useLazyGetNewTasksQuery()
  const [reviewLead, { isLoading, isError, error }] = useAddReviewLeadMutation()
  const rules = schemaRules(getAddReviewSchema())

  const onModalVisible = () => {
    getTasks()
  }

  const onSubmit = async values => {
    const response = await reviewLead({ user, lead, ...values })
      .unwrap()
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.lead_review_submit,
          ...from,
          response: e.status,
          client_id: client.id,
          lead_id: lead.id || selectedLeadId,
          content_type: CONTENT_TYPES.lead,
        })
      })
    if (response) {
      formRef?.current?.setVisible(false)
      fireEvent({
        ...ACTION_TYPES.lead_review_submit,
        ...from,
        response: "200",
        client_id: client.id,
        lead_id: lead.id || selectedLeadId,
        content_type: CONTENT_TYPES.lead,
      })
    }
  }

  return (
    <Modal
      title={t("Client Review")}
      ref={formRef}
      width={670}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={() => formRef?.current?.setVisible(false)}
      onOk={form.submit}
      loading={isLoading}
      onModalVisible={onModalVisible}
      footerAlert={error}
      destroyOnClose
    >
      <Form
        form={form}
        name="add-review-lead"
        layout="vertical"
        initialValues={getAddReviewInitialValues()}
        onFinish={onSubmit}
        preserve={false}
      >
        <Group gap="16px">
          {!lead && (
            <Form.Item name={"leadId"} label={t("Lead Id")} rules={rules} required>
              <SelectInput
                placeholder={t("Select Lead Id")}
                options={clientLeads}
                onChange={(_value, option) => {
                  form.setFieldsValue({ leadId: option?.value })
                }}
              />
            </Form.Item>
          )}
          <Form.Item name="callRecording" label={t("Call Recording")} rules={rules} required>
            <RadioButtons
              options={ratingArray()}
            />
          </Form.Item>
          <Form.Item name="additionalComment" label={t("Additional Comment")}>
            <TextInput
              placeholder={t("Enter additional comment")}
              maxLength={256}
              lineCount={1}
              style={{ resize: "none" }}
            />
          </Form.Item>
          <Form.Item name="progressOnTask" label={t("Progress On Task")} rules={rules} required>
            <RadioButtons
              options={ratingArray()}
            />
          </Form.Item>
          <Form.Item name="additionalComment2" label={t("Additional Comment")}>
            <TextInput
              placeholder={t("Enter additional comment")}
              maxLength={256}
              lineCount={1}
              style={{ resize: "none" }}
            />
          </Form.Item>
          <Form.Item name={"proposedNextTask"} label={t("Proposed Next Task")} rules={rules} required>
            <SelectInput
              placeholder={t("Select")}
              options={data}
              loading={isFetching}
              onChange={(_value, option) => {
                form.setFieldsValue({ proposedNextTask: option })
              }}
            />
          </Form.Item>
          <Form.Item name="feedback" label={t("Feedback")} rules={rules} required>
            <TextInput
              placeholder={t("Enter feedback")}
              maxLength={500}
              onChange={e => {
                form.setFieldsValue({ feedback: e.target.value })
              }}
              lineCount={4}
              style={{ resize: "none" }}
            />
            <IconText
              icon="MdInfoOutline"
              iconProps={{ color: theme["gray800"], size: "1em" }}
              textType="secondary"
              title={t("Maximum 500 characters")}
            />
          </Form.Item>
        </Group>
      </Form>
    </Modal>
  )
}
