import { Text } from "components/common"
import styles from "./transcription.module.less"

function Transcription({ transcription }) {
  return (
    <div className={styles.transcriptMain}>
      {transcription?.map(e => (
        <div key={e.slice(0, 3)}>
          <Text>{e}</Text>
        </div>
      ))}
    </div>
  )
}

export default Transcription
