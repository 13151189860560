import { localisedValue } from "utils"
const getClientProfileInitialValues = client => {
  if (client) {
    return {
      gender: localisedValue({ object: client, key: "gender" }) || "",
      maritalStatus: localisedValue({ object: client, key: "maritalStatus" }),
      children: !!client?.children && client?.children === 0 ? "false" : "true",
      clientOccupation: localisedValue({ object: client, key: "occupation" }),
      intent: localisedValue({ object: client, key: "intent" }),
      nationality: client?.nationalityId,
      clientExist: !!localisedValue({ object: client?.profilingAttempted, key: "hasExistingProperty" })
        ? !!localisedValue({ object: client, key: "hasExistingProperty" })
          ? "yes"
          : "no"
        : "",
      clientBudgetRange: [0, localisedValue({ object: client, key: "budget" }) || 0],
      clientBuyingIntent: localisedValue({ object: client, key: "buyingIntentId" })?.toString() || "",
      clientPropertyInterest: localisedValue({ object: client, key: "interestedTypeId" })?.toString() || "",
      clientAreaInterest:
        !!localisedValue({ object: client?.profilingAttempted, key: "area" }) &&
        localisedValue({ object: client, key: "interestedMaxArea" }).toString(),

      preferredFinancing: localisedValue({ object: client, key: "preferredFinancing" }),
      preferredCity: client?.preferredCityId?.toString(),
      preferredRegion: localisedValue({ object: client, key: "preferredCityRegion" }),
      preferredLocality: client?.preferredLocalityId?.toString(),
    }
  }
}

export default getClientProfileInitialValues
