import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { inDevelopMode } from "utils/env"

import { AUTH_TOKEN_COOKIE_KEY } from "constants"
import { getCookie } from "utils/cookie"
import { logout } from "./appSlice"
import { removeAppCredentials } from "configs/utils"
import { configs } from "configs"

const authHeader = auth => ({ token: auth ? auth : getCookie(AUTH_TOKEN_COOKIE_KEY) })

const getErrorFromResponse = response => {
  console.error("Request Error: ", response)
  return {
    ...response,
    error: {
      status: response.error?.originalStatus || response.error?.status,
      // TODO:
      message:
        (response.error?.data?.errors?.[0]?.extra?.filename == "IncentivesErrorFile" &&
          response.error?.data?.errors?.[0]) ||
        response.error?.message ||
        response.error?.data?.message ||
        (response.error?.data?.errors && response.error.data.errors.map(e => `${e.message}`).join(", ")) ||
        response.error?.data ||
        "Network error",
    },
  }
}

const tagTypes = [
  "client-detail",
  "leads",
  "taskLogs",
  "sharings",
  "project-info",
  "clients",
  "kpi-reports",
  "meeting-list",
  "meeting-detail",
  "action-items",
  "project-detail",
  "qc-listing",
]

export const getHeaders = () => {
  return {
    prepareHeaders: (headers, { getState }) => {
      let obj = {}
      const cookie = getCookie(AUTH_TOKEN_COOKIE_KEY)
      const stateToken = getState().app.auth.token || cookie
      if (stateToken && typeof stateToken !== "boolean") {
        obj = authHeader(stateToken)
      }
      Object.keys(obj).forEach(e => {
        !!obj[e] && headers.set(e, obj[e])
      })
      return headers
    },
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: configs.apiBaseURL(),
  ...getHeaders(),
  credentials: inDevelopMode ? undefined : "include",
})

const parentApi = createApi({
  reducerPath: "parentApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      let result = await baseQuery(args, api, extraOptions)
      if (result.error) {
        if (result.error.status === 401 || result?.error?.originalStatus === 401) {
          removeAppCredentials(null, true)
          api.dispatch(logout(true))
          api.dispatch(parentApi.util.resetApiState())
        } else {
          return getErrorFromResponse(result)
        }
      }
      return result
    } catch (e) {
      return getErrorFromResponse(e)
    }
  },
  tagTypes,
  endpoints: builder => ({
    getUserDetail: builder.query({
      query: () => `/users/getProfile`,
    }),

    getUserPrivileges: builder.query({
      query: () => `/authentication/selfPrivileges`,
    }),

    getUserAccounts: builder.query({
      query: () => `/userAccounts`,
    }),

    getPendingAgencyAssessments: builder.query({
      query: () => `/userAgencyAssessments/pending`,
    }),

    getPendingAnnouncements: builder.query({
      query: () => `/announcementRecipients`,
    }),

    getAgencySettingsByKeys: builder.query({
      query: () =>
        `/agency/settingByKeys?settingKeys=["role_privileges","payment_attachment_image_processing","client_stages","lead_stages"]`,
    }),

    getAgencyPrimaryCurrency: builder.query({
      query: () => `/agency/currencies`,
      transformResponse: response => response.filter(item => item["key"] === "primary_currency"),
    }),

    signLeadAcknowledgeSlip: builder.mutation({
      query: ({ id }) => ({ url: `/user-signoff/${id}/sign`, method: "PUT", body: {} }),
    }),

    skipLeadAcknowledge: builder.mutation({
      query: id => ({ url: `/user-signoff/${id}/skipCount`, method: "PUT", body: {} }),
    }),

    updatePendingAnnouncements: builder.mutation({
      query: payload => ({ url: `/announcementRecipients`, method: "PUT", body: payload }),
    }),
  }),
})

export default parentApi
