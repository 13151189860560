import { DetailBar, Drawer, Icon, Title } from "components/common"
import { forwardRef, useImperativeHandle, useMemo, useState } from "react"

import cx from "clsx"
import { FLagWithText } from "components/common/flagWithText/flagWithText"
import { CallsTable } from "features/clients/components"
import { t } from "i18next"
import styles from "./index.module.less"
import { TitleDescription } from "components/custom"
import { capitalizeFirstLetter } from "utils"

const CallLogsDrawer = forwardRef((_props, ref) => {
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [showCallsDrawer, setShowCallsDrawer] = useState(false)
  const [params, setParams] = useState({})
  const [drawerType, setDrawerType] = useState("client")
  useImperativeHandle(
    ref,
    () => ({
      showDrawer(data, params, type) {
        //if the drawer is for staff,region,city
        if (params) {
          setParams(params)
          if (params?.toDate || params?.fromDate) {
            setParams({ ...params, excludeDateRange: true })
          }
        }
        if (type) {
          setDrawerType(type)
        }
        //If the drawer is for client
        if (!type || (type !== "staff" && type !== "region" && type !== "city")) {
          setParams({ ...params, clientId: data?.id, excludeDateRange: true })
        }

        setSelectedRecord(data)
        setShowCallsDrawer(true)
      },
    }),
    []
  )

  const getClientDetail = useMemo(() => {
    if (selectedRecord) {
      if (selectedRecord.Country) {
        return [
          { label: t("Client ID"), value: selectedRecord.id },
          {
            label: t("Client Name"),
            value: (
              <FLagWithText
                title={selectedRecord.Country.name}
                countryCode={selectedRecord.Country.locCode || selectedRecord.Country.loc_code}
                infoText={<span>{selectedRecord.name}</span>}
              />
            ),
          },
        ]
      } else {
        return [
          { label: `${capitalizeFirstLetter(drawerType)} ID`, value: selectedRecord.id },
          {
            label: `${capitalizeFirstLetter(drawerType)} Name`,
            value: <TitleDescription title={<span>{selectedRecord.name}</span>} />,
          },
        ]
      }
    } else {
      return []
    }
  }, [selectedRecord, drawerType])

  const onClose = () => {
    setShowCallsDrawer(false)
    setSelectedRecord(null)
  }

  const toggleDrawer = () => {
    setShowCallsDrawer(prev => !prev)
  }

  return (
    <Drawer
      title={
        <>
          <Title level={3} className="mbe-0">
            {t("Call logs")}
          </Title>
          <div className="gray-700 fs12 lhn">
            {t("Revolutionising the way we transcribe conversations with clients")}.
          </div>
        </>
      }
      closable={false}
      placement="right"
      width={1300}
      onClose={onClose}
      open={showCallsDrawer}
      className={styles.filterMain}
      extra={<Icon icon="IoMdClose" onClick={onClose} />}
    >
      <DetailBar className={cx(styles.callLogBar, "mbe-20")} detailData={getClientDetail} separator={false} evenly />
      <CallsTable toggleParentDrawer={toggleDrawer} stickyOffset={-16} params={params} />
    </Drawer>
  )
})

export default CallLogsDrawer
