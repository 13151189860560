import { Form, Input, Layout } from "antd"
import { Alert, Button, Card, Group, Text, Title, notification } from "components/common"
import { Link, useNavigate } from "react-router-dom"
import cx from "clsx"
import { LogoPropforce, SvgResetPassword } from "components/svg"
import { LOGIN_PATH } from "constants/app-paths"
import { useResetPasswordMutation } from "./api"
import styles from "./index.module.less"
import { useTranslation } from "react-i18next"

const ResetPassword = () => {
  const { t } = useTranslation()
  const [resetPassword, { isLoading, isError, error }] = useResetPasswordMutation()
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = async values => {
    const response = await resetPassword({ resetKey: values.secretKey, newPassword: values.password }).unwrap()
    if (response) {
      notification.success({ message: t("Password has been rest successfully!") })
      navigate(LOGIN_PATH + "?from=reset-password")
    }
  }

  const getErrorComp = message => (
    <>
      {message.split("\n").map(e => (
        <li key={e}>{e}</li>
      ))}
    </>
  )

  const renderResetPasswordContent = () => (
    <>
      <div className="text-center">
        <Title level={2} className="mbe-12">
          {t("Reset password")} <SvgResetPassword />
        </Title>
        <Text type="secondary" className="mbe-40">
          {t("Please check your email to add secret key here to reset your password")}
        </Text>

        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical" className="text-left">
          <Form.Item name="secretKey" label={t("Secret Key")}>
            <Input placeholder={t("Secret Key")} autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("New Password")}
            rules={[{ message: t("Enter new password"), required: true }]}
          >
            <Input.Password placeholder={t("Password")} />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={t("Confirm Password")}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: t("Please confirm your password"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t("The new password that you entered do not match")))
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("Password")} />
          </Form.Item>
          {isError && !!error?.message && (
            <Alert message={getErrorComp(error.message)} className="mbe-16" type="error" />
          )}
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
            text={t("Reset Password")}
            className="mbe-16"
            block
          />
          <div className="text-center">
            <Link to={LOGIN_PATH} className="text-link bold">
              {t("Go Back")}
            </Link>
          </div>
        </Form>
      </div>
    </>
  )

  return (
    <>
      <Layout.Content className={styles.authLayout}>
        <Group template="1fr 552px" gap="32px" style={{ minHeight: "100vh" }}>
          <div className="pb-32">
            <div className={styles.logoPosition}>
              <div className="mbe-24" style={{ marginTop: 90 }}>
                <LogoPropforce width={228} height="auto" />
              </div>
              <Title level={1} className={cx(styles.logoHeading, "mbe-12")}>
                {t("Elevate your Sales with Propforce")}
              </Title>
              <Text type="secondary" className={styles.logoSubHeading}>
                {t("Efficiently manage your sales funnel and close leads faster")}
              </Text>
            </div>
          </div>
          <div className="pb-32 d-grid" style={{ alignContent: "center" }}>
            <Card className="pi-40 pb-8">{renderResetPasswordContent()}</Card>
          </div>
        </Group>
      </Layout.Content>
    </>
  )
}

export default ResetPassword
