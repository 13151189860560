import cx from "clsx"
import styles from "./index.module.less";

const getSpaceCharacter = (sp) => {
  switch (sp) {
    case "no-break":
      return "\u00A0";
    case "en":
      return "\u2002";
    case "em":
      return "\u2003";
    case "thin":
      return "\u2009";
    case "hair":
      return "\u200A";
    default:
      return " ";
  }
}

const getSymbol = (sym) => {
  switch (sym) {
    case "bull":
      return <span className={styles.bull}>•</span>;
    case "emdash":
      return "—";
    case "endash":
      return "–";
    default:
      break;
  }
}

const Separator = (props) => {
  const { className, symbol, spaceType, color } = props
  const spaceChar = getSpaceCharacter(spaceType)
  const symbolChar = getSymbol(symbol)

  return (
    <span className={cx(className, styles.separator)} style={{ "--separator-color": color, ...props.style }}>
      {spaceChar}{symbolChar}{spaceChar}
    </span>
  )
}

export default Separator

Separator.defaultProps = {
  symbol: "bull",
  spaceType: "thin"
}
