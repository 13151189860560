import store from "store"

export const transformCalendarEvents = response => {
  const state = store?.getState()
  const user = state?.app?.user?.info
  let calenderItems = []
  response.map(item => {
    let filterKey = ""
    if (item.type == "unavailable") {
      filterKey = "busy"
    }
    if (item.type == "task" && item.addedBy == user?.id) {
      filterKey = "myMeeting"
    }
    if (item.type == "task" && item.addedBy !== user?.id) {
      filterKey = "tsrMeeting"
    }
    if (item.type == "sale") {
      filterKey = "sale"
    }
    if (typeof item.endTimeStamp == "number" && typeof item.startTimeStamp == "number") {
      const transformedEndTimeStamp = new Date(item.endTimeStamp * 1000).toISOString()
      const transformedStartTimeStamp = new Date(item.startTimeStamp * 1000).toISOString()
      calenderItems.push({
        ...item,
        endTimeStamp: transformedEndTimeStamp,
        startTimeStamp: transformedStartTimeStamp,
        filterKey,
      })
    } else calenderItems.push({ ...item, filterKey })
  })
  return calenderItems
}
