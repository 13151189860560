import { Drawer as AntdDrawer } from "antd"
import cx from "clsx"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Alert from "../alert/alert"
import Button from "../button/button"
import styles from "./index.module.less"
import { useSelector } from "react-redux"

const ModifiedDrawer = forwardRef((props, ref) => {
  const [visible, setShow] = useState(false)
  const { rtl } = useSelector(state => state.app.configs)

  useImperativeHandle(
    ref,
    () => ({
      onOk,
      onCancel,
      visible,
      setVisible(value) {
        setShow(value)
      },
    }),
    []
  )

  useEffect(() => {
    visible && onDrawerVisible(visible)
  }, [visible])

  const {
    onClose,
    onCancel,
    className,
    onOk,
    title,
    subTitle = "",
    type,
    color,
    footer,
    width,
    children,
    okText,
    cancelText,
    loading,
    onOkProps,
    onCancelProps,
    hideCancelButton,
    disableOkButton,
    onDrawerVisible = () => {},
    footerAlert,
    destroyOnClose,
    forceRender,
    onSuccess,
    maskClose,
    ...rest
  } = props

  return (
    <AntdDrawer
      maskClosable={maskClose}
      placement={rtl ? "left" : "right"}
      onClose={onClose}
      forceRender={forceRender}
      destroyOnClose={destroyOnClose}
      width={width}
      className={className}
      title={
        title && (
          <>
            {title}
            {subTitle && <span className={cx("gray-800", styles.subTitle)}>{subTitle}</span>}
          </>
        )
      }
      open={visible}
      footer={
        footer || footer === null
          ? footer
          : [
              footerAlert?.message && (
                <Alert
                  className="mbe-16"
                  style={{ marginInline: -32, marginBlockStart: -20 }}
                  banner
                  type="error"
                  showIcon
                  {...footerAlert}
                />
              ),

              !!onOk && (
                <Button
                  size="large"
                  type={type}
                  key="submit"
                  onClick={onOk}
                  loading={loading}
                  disabled={loading || disableOkButton}
                  text={okText}
                  {...onOkProps}
                />
              ),
              !!onCancel && !hideCancelButton && (
                <Button
                  size="large"
                  type="default"
                  key="back"
                  className={styles.cancel}
                  onClick={onCancel}
                  text={cancelText}
                  {...onCancelProps}
                />
              ),
            ]
      }
      {...rest}
    >
      {children}
    </AntdDrawer>
  )
})

ModifiedDrawer.defaultProps = {
  width: 700,
  type: "primary",
  okText: "Confirm",
  cancelText: "Cancel",
}

export default ModifiedDrawer
