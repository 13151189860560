import React, { forwardRef, useEffect, useRef } from "react"

import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import styles from "./index.module.less"
import cx from "clsx"

const Recording = forwardRef(({ recordingSrc, id, className }, ref) => {
  const audioRef = useRef(null)

  useEffect(() => {
    const audioElement = document.getElementById(id)
    const preventFetch = event => {
      event.preventDefault()
    }
    audioElement && audioElement.addEventListener("play", preventFetch)
    return () => {
      audioElement && audioElement.removeEventListener("play", preventFetch)
    }
  }, [])

  const handlePlay = () => {
    const audioElements = document.querySelectorAll("audio")
    audioElements.forEach(audioElement => {
      if (audioRef?.current && audioElement !== audioRef?.current) {
        audioElement.pause()
      } else if (ref?.current && audioElement !== ref?.current) {
        audioElement.pause()
      }
    })
  }

  return (
    <audio
      ref={ref || audioRef}
      className={cx(className, styles.audio)}
      id={id}
      controls
      key={id.toString()}
      onPlay={() => {
        handlePlay()
        fireEvent(ACTION_TYPES.recording_play)
      }}
    >
      <source src={recordingSrc} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  )
})

export default Recording
