import { combineReducers, configureStore } from "@reduxjs/toolkit"

import appReducer from "./appSlice"
import { authApi } from "features/auth/api"
import parentApi from "./parentApi"
import reportsReducer from "./reportsSlice"
import thunk from "redux-thunk"
import { odysseyApi } from "features/odessyChatbot/api"

const reducers = combineReducers({
  [appReducer.name]: appReducer.reducer,
  [parentApi.reducerPath]: parentApi.reducer,
  [reportsReducer.name]: reportsReducer.reducer,
  appState: appReducer,
  [authApi.reducerPath]: authApi.reducer,
  [odysseyApi.reducerPath]: odysseyApi.reducer,
})

export default configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat(authApi.middleware, odysseyApi.middleware, parentApi.middleware, thunk),
})
