import { Modal } from "components/common"
import TaskReviewsTabs from "../taskReviewsTabs/taskReviewsTabs"
import { forwardRef } from "react"
import { t } from "i18next"
import { useLazyGetClientTaskLogsQuery } from "features/shared/api"

const TaskOverviewModal = forwardRef((props, ref) => {
  const { client, lead, getClientLogs, taskTableProps } = props
  const [getClientTaskLogs, { data: clientLeads, loading }] = useLazyGetClientTaskLogsQuery()

  const onModalVisible = () => {
    // added this for leads, todos and tasks. This will get us all the leadsIds for that client.
    if (client) {
      getClientTaskLogs(client?.id)
    }
  }

  return (
    <Modal
      title={t("Task Overview")}
      ref={ref}
      width={1100}
      onCancel={() => ref?.current?.setVisible(false)}
      footer={null}
      destroyOnClose
      onModalVisible={getClientLogs && onModalVisible}
    >
      <TaskReviewsTabs client={client} lead={lead} clientLeads={clientLeads} taskTableProps={taskTableProps} />
    </Modal>
  )
})

export default TaskOverviewModal
