export const PAGES = {
  client_detail_page: "client_detail_page",
  client_listing_page: "client_listing_page",
  lead_listing_page: "lead_listing_page",
  sales_dispute_page: "sales_dispute_page",
  kpi_report_sales: "kpi_report_sales",
  kpi_report_affiliates: "kpi_report_affiliates",
  kpi_report_telesales: "kpi_report_telesales",
  telesales_listing_page: "telesales_listing_page",
  qc_listing_page: "qc_listing_page",
}

export const PAGE_SUBSECTIONS = {
  client_listing_page: "client_listing_page",
  client_detail_page: "client_detail_page",
  lead_listing_page: "lead_listing_page",
  telesales_listing_page: "telesales_listing_page",
  leads_tab: "leads_tab",
  task_tab: "task_tab",
  "tasks_tab/leads_tab": "tasks_tab/leads_tab",
  add_task: "add_task",
  tasks_tab: "tasks_tab",
  calls_tab: "calls_tab",
  sales_dispute: "sales_dispute",
  kpi_report_sales: "kpi_report_sales",
  kpi_report_affiliates: "kpi_report_affiliates",
  kpi_report_telesales: "kpi_report_telesales",
  todos_listings_page: "todos_listings_page",
  telesales_listings_page: "telesales_listings_page",
  qc_listing_page: "qc_listing_page",
}

export const EVENT_NAMES = {
  click: "click",
  filter_applied: "filter_applied",
  client_edit: "client_edit",
  contact_client: "contact_client",
  change_allocation_submit: "change_allocation_submit",
  add_new_lead_submit: "add_new_lead_submit",
  lead_actions: "lead_actions",
  apply_discount_submit: "apply_discount_submit",
  lead_review_submit: "lead_review_submit",
  lead_review_meeting_submit: "lead_review_meeting_submit",
  lead_sharing_sales_submit: "lead_sharing_sales_submit",
  lead_sharing_support_submit: "lead_sharing_support_submit",
  add_new_client_submit: "add_new_client_submit",
  filter: "filter",
  apply_filter: "apply_filter",
  clear_applied: "clear_applied",
  sort_applied: "sort_applied",
  submit_task: "submit_task",
  meeting_verify_submit: "meeting_verify_submit",
  submit_sale: "submit_sale",
  play_call_recording: "play_call_recording",
  favorite: "favorite",
  page_view: "page_view",
  save_search_submit: "save_search_submit",
  save_search_used: "save_search_used",
  view_favorite: "view_favorite",
  share_lead_submit: "share_lead_submit",
  toggle: "toggle",
  add_lead_submit: "add_lead_submit",
  discount_submit: "discount_submit",
  sort: "sort",
  filters: "filter",
  open_dispute_submit: "open_dispute_submit",
  compare: "compare",
  metrics_selection: "metrics_selection",
  filter_reset: "filter_reset",
  review_lead_submit: "review_lead_submit",
  submit_sale_task: "submit_sale_task",
}

export const CONTENT_TYPES = {
  filter_field: "filter_field",
  button_click: "button_click",
  submit: "submit",
  email: "email",
  whatsapp: "whatsapp",
  calls: "calls",
  edit_allocation: "edit_allocation",
  leads_tab: "leads_tab",
  tasks_tab: "tasks_tab",
  calls_tab: "calls_tab",
  allocation_tab: "allocation_tab",
  sharing_tab: "sharing_tab",
  edit_history_tab: "edit_history_tab",
  add_new_lead: "add_new_lead",
  add_new_client: "add_new_client",
  task_overview: "task_overview",
  add_task: "add_task",
  apply_discount: "apply_discount",
  lead_review: "lead_review",
  Lead_review_meeting: "Lead_review_meeting",
  lead_sharing_sale: "lead_sharing_sale",
  lead_sharing_sale_support: "lead_sharing_sale_support",
  lead_id_filter: "lead_id_filter",
  lead_type_filter: "lead_type_filter",
  team_type_filter: "team_type_filter",
  task_type_filter: "task_type_filter",
  client_detail: "client_detail",
  date_ascending: "date_ascending",
  date_descending: "date_descending",
  lead_ID_ascending: "lead_ID_ascending",
  lead_ID_descending: "lead_ID_descending",
  view_task_log: "view_task_log",
  add_sale: "add_sale",
  client_id_ascending: "client_id_ascending",
  client_id_descending: "client_id_descending",
  name_ascending: "name_ascending",
  name_descending: "name_descending",
  city_ascending: "city_ascending",
  city_descending: "city_descending",
  allocated_to_ascending: "allocated_to_ascending",
  allocated_to_descending: "allocated_to_descending",
  no_of_leads_ascending: "no_of_leads_ascending",
  no_of_leads_descending: "no_of_leads_descending",
  expand: "expand",
  by_approval: "by_approval",
  by_designation: "by_designation",
  client_type: "client_type",
  investment_plan: "investment_plan",
  occupation: "occupation",
  client_rating: "client_rating",
  client_id: "client_id",
  client_name: "client_name",
  client_email: "client_email",
  client_cnic: "client_cnic",
  client_cell: "client_cell",
  client_total_leads: "client_total_leads",
  client_country: "client_country",
  client_inactive_duration: "client_inactive_duration",
  client_project: "client_project",
  client_allocation: "client_allocation",
  client_listing_new: "client_listing_new",
  client_detail_new: "client_detail_new",
  lead_added: "lead_added",
  task_added: "task_added",
  allocation_date: "allocation_date",
  last_upload: "last_upload",
  shared_date: "shared_date",
  lead_id: "lead_id",
  client_ascending: "client_ascending",
  client_descending: "client_descending",
  status_ascending: "status_ascending",
  status_descending: "status_descending",
  updated_at_ascending: "updated_at_ascending",
  updated_at_descending: "updated_at_descending",
  project_id: "project_id",
  project_details: "project_details",
  recommended_leads: "recommended_leads",
  lead_detail: "lead_detail",
  appy_for_discount: "appy_for_discount",
  share_lead_sale: "share_lead_sale",
  share_lead_support: "share_lead_support",
  review_lead: "review_lead",
  show_shared_leads: "show_shared_leads",
  date_lead_added: "date_lead_added",
  date_task_added: "date_task_added",
  date_allocation: "date_allocation",
  date_last_updated: "date_last_updated",
  date_lead_shared: "date_lead_shared",
  lead_status: "lead_status",
  interested_projects: "interested_projects",
  city: "city",
  lead_source: "lead_source",
  lead_shared_with: "lead_shared_with",
  task_added_by: "task_added_by",
  platform: "platform",
  more_filter: "more_filter",
  more_filters: "more_filters",
  manage_save_search: "manage_save_search",
  lead_listing: "lead_listing",
  call: "call",
  location: "location",
  sale: "sale",
  support: "support",
  review_lead_meeting: "review_lead_meeting",
  lead: "lead",
  client_observation_meeting: "client_observation_meeting",
  sales_dispute: "sales_dispute",
  raise_dispute: "raise_dispute",
  staff_filter: "staff_filter",
  client_detail_page: "client_detail_page",
  date_filter: "date_filter",
  sales_metrics: "sales_metrics",
  kpi_sales: "kpi_sales",
  region_filter: "region_filter",
  kpi_affiliates: "kpi_affiliates",
  affiliates_metrics: "affiliates_metrics",
  telesales_metrics: "telesales_metrics",
  client_listing_page: "client_listing_page",
  affiliate_lead_type: "affiliate_lead_type",
  lead_type: "lead_type",
  lead_purpose: "lead_purpose",
}

export const ACTION_TYPES = {
  land_on_detail_page: {
    page: PAGES.client_detail_page,
    event_name: EVENT_NAMES.page_view,
    content_type: CONTENT_TYPES.client_detail_new,
  },
  clicking_on_the_filter: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.filter_field,
  },
  filter_applied: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.filter_applied,
  },
  client_edit_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.client_edit,
    content_type: CONTENT_TYPES.button_click,
  },
  cleint_edit_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.client_edit,
    content_type: CONTENT_TYPES.submit,
  },
  email_contact_client: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.contact_client,
    content_type: CONTENT_TYPES.email,
  },
  whatsapp_contact_client: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.contact_client,
    content_type: CONTENT_TYPES.whatsapp,
  },
  call_contact_client: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.contact_client,
    content_type: CONTENT_TYPES.calls,
  },
  allocated_to_edit_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.edit_allocation,
  },
  allocated_to_edit_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.change_allocation_submit,
  },
  leads_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.leads_tab,
  },
  tasks_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.tasks_tab,
  },
  calls_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.calls_tab,
  },
  allocation_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.allocation_tab,
  },
  sharing_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.sharing_tab,
  },
  edit_history_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.edit_history_tab,
  },
  add_new_lead_click: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.add_new_lead,
  },
  add_new_lead_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.add_lead_submit,
  },
  lead_task_overview_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.task_overview,
  },
  lead_add_task_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.add_task,
  },
  lead_apply_discount_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.appy_for_discount,
  },
  lead_apply_discount_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.discount_submit,
  },
  lead_review_button_lead: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.lead_review,
  },
  lead_review_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.review_lead_submit,
    content_type: CONTENT_TYPES.lead_review,
  },
  lead_review_button_meeting: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.review_lead_meeting,
  },
  lead_review_meeting_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_review_meeting_submit,
    content_type: CONTENT_TYPES.review_lead_meeting,
  },
  lead_sharing_button_with_sale: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.share_lead_sale,
  },
  lead_sharing_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.share_lead_submit,
    content_type: CONTENT_TYPES.share_lead_sale,
  },
  lead_sharing_button_with_support: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.share_lead_support,
  },
  lead_sharing_support_submit: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.share_lead_submit,
    content_type: CONTENT_TYPES.share_lead_support,
  },
  filter_by_lead_id_on_task_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.lead_id_filter,
  },
  filter_by_lead_type_on_tasks_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.lead_type_filter,
  },
  filter_by_team_type_on_tasks_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.team_type_filter,
  },
  filter_by_task_type_on_tasks_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.task_type_filter,
  },
  when_filter_is_applied: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.apply_filter,
    content_type: CONTENT_TYPES.client_detail,
  },
  clear_filter_on_tasks_tab: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.clear_applied,
  },
  sort_by: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.sort_applied,
    content_type: CONTENT_TYPES.date_ascending,
  },
  task_tab_add_task_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.add_task,
  },
  task_tab_submit_task: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.submit_task,
  },
  meeting_verify_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.add_task,
    event_name: EVENT_NAMES.meeting_verify_submit,
    content_type: CONTENT_TYPES.add_task,
  },
  view_task_log: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.view_task_log,
  },
  task_tab_add_sale_button: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.task_tab,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.add_sale,
  },
  lead_tab_submit_sale: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.leads_tab,
    event_name: EVENT_NAMES.submit_sale_task,
  },
  recording_play: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.calls_tab,
    event_name: EVENT_NAMES.play_call_recording,
    // content_type: CONTENT_TYPES.date_ascending,
  },
  mark_client_as_favorite: {
    page: PAGES.client_detail_page,
    interacted_from: PAGE_SUBSECTIONS.client_detail_page,
    event_name: EVENT_NAMES.favorite,
  },
  landing_on_the_new_client_page: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.page_view,
    content_type: CONTENT_TYPES.client_listing_page,
  },
  add_new_client_click: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.add_new_client,
  },
  add_new_client_submit: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.add_new_client_submit,
  },

  listing_task_overview: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.task_overview,
  },
  listing_call_logs: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.calls,
  },

  expand_client_row: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.expand,
  },

  save_search_marked: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.save_search_submit,
  },
  save_search_used: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.save_search_used,
  },
  clear_all: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.clear_applied,
  },
  filter_applied_listing: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.filter_applied,
  },

  filter_listing: {
    page: PAGES.client_listing_page,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.filter_field,
  },

  filter_applied_leads_listing: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.filter_applied,
  },

  filter_leads_listing: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.filter_field,
  },

  filter_by_lead_id: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.lead_id,
  },

  mark_lead_as_favorite: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.favorite,
  },

  view_favorite: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.view_favorite,
  },

  sort_by_functionality_used: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.sort_applied,
  },

  project_details_clicked: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.project_details,
  },

  recommended_leads_tab: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.recommended_leads,
  },

  lead_actions_click_on_add_task: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.add_task,
  },

  lead_actions_click_on_add_sale: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.lead_actions,
    content_type: CONTENT_TYPES.add_sale,
  },

  lead_actions_share_lead_support_applied: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.share_lead_submit,
  },

  more_filters_show_shared_leads_toggle: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.toggle,
    content_type: CONTENT_TYPES.show_shared_leads,
  },

  more_filter: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.more_filter,
  },

  save_search_saved: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.save_search_submit,
  },

  save_search_manage: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.manage_save_search,
  },

  landing_on_the_new_lead_page: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.page_view,
    content_type: CONTENT_TYPES.lead_listing,
  },

  propgo_contact: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.contact_client,
    content_type: CONTENT_TYPES.call,
  },

  lead_task_status: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.lead_status,
  },

  lead_source: {
    page: PAGES.lead_listing_page,
    interacted_from: PAGE_SUBSECTIONS.lead_listing_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.lead_source,
  },

  land_on_sales_dispute_page: {
    page: PAGES.sales_dispute_page,
    interacted_from: PAGE_SUBSECTIONS.sales_dispute,
    event_name: EVENT_NAMES.page_view,
    content_type: CONTENT_TYPES.sales_dispute,
  },

  raise_dispute_click: {
    page: PAGES.sales_dispute_page,
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.raise_dispute,
    interacted_from: PAGE_SUBSECTIONS.sales_dispute,
  },

  staff_filter_use: {
    page: PAGES.sales_dispute_page,
    event_name: EVENT_NAMES.filter,
    content_type: CONTENT_TYPES.staff_filter,
    interacted_from: PAGE_SUBSECTIONS.sales_dispute,
  },

  open_dispute: {
    page: PAGES.sales_dispute_page,
    interacted_from: PAGE_SUBSECTIONS.sales_dispute,
    event_name: EVENT_NAMES.open_dispute_submit,
  },

  land_on_the_client_detail: {
    page: PAGES.client_detail_page,
    event_name: EVENT_NAMES.page_view,
    content_type: CONTENT_TYPES.client_detail_page,
  },

  landing_on_the_kpi_sales_report: {
    page: PAGES.kpi_report_sales,
    event_name: EVENT_NAMES.page_view,
  },

  landing_on_the_kpi_affiliates_report: {
    page: PAGES.kpi_report_affiliates,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_affiliates,
    event_name: EVENT_NAMES.page_view,
  },

  landing_on_the_kpi_telesales_report: {
    page: PAGES.kpi_report_telesales,
    event_name: EVENT_NAMES.page_view,
  },

  save_search: {
    event_name: EVENT_NAMES.save_search_submit,
    page: PAGES.kpi_report_sales,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_sales,
  },

  date_filter_applied: {
    event_name: EVENT_NAMES.filter_applied,
    content_type: CONTENT_TYPES.date_filter,
    page: PAGES.kpi_report_sales,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_sales,
  },

  compare_metrics: {
    event_name: EVENT_NAMES.compare,
    content_type: CONTENT_TYPES.sales_metrics,
    page: PAGES.kpi_report_sales,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_sales,
  },

  kpi_metrics_selection: {
    event_name: EVENT_NAMES.metrics_selection,
    content_type: CONTENT_TYPES.kpi_sales,
    page: PAGES.kpi_report_sales,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_sales,
  },

  reset_all: {
    event_name: EVENT_NAMES.filter_reset,
    page: PAGES.kpi_report_sales,
    interacted_from: PAGE_SUBSECTIONS.kpi_report_sales,
  },

  client_id_click: {
    event_name: EVENT_NAMES.click,
    content_type: CONTENT_TYPES.client_detail,
    interacted_from: PAGE_SUBSECTIONS.client_listing_page,
    page: PAGES.client_listing_page,
  },
}
