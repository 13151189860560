import { MdClose } from "react-icons/md"
import { Tag as TagAntd } from "antd"
import Tooltip from "../tooltip/tooltip"
import chroma from "chroma-js"
import cx from "clsx"
import styles from "./index.module.less"

const Tag = props => {
  const {
    closable,
    onClose,
    color,
    checked,
    onChange,
    shape,
    size,
    data,
    hottags,
    animate,
    children,
    bordered,
    pill,
    style,
    onClick,
    tooltipText,
    maxLength,
    className,
    ...rest
  } = props

  const isLightColored = props.type === "light"
  const tagTextColor = e => {
    if (chroma(e)?.luminance() < 0.4) {
      return "#fff"
    }
    return "#000d"
  }

  return (
    <TagAntd
      className={cx(
        styles.tag,
        checked && styles.selected,
        size && styles[`tag-${size}`],
        bordered && styles.bordered,
        pill && styles.pill,
        maxLength && styles.commentTag,
        className
      )}
      closable={closable}
      color={color}
      closeIcon={<MdClose />}
      shape={shape}
      style={{
        "--tag-color": isLightColored ? color : color && tagTextColor(color),
        "--tag-bg": isLightColored && color ? chroma.mix(color, "#fff", 0.85).saturate(0.24) : color,
        maxWidth: !!maxLength ? `${maxLength}ch` : undefined,
        ...style,
      }}
      onClose={onClose}
      {...(onClick && { onClick })}
      {...rest}
    >
      {!!tooltipText ? (
        <Tooltip placement="top" title={<span onClick={e => e.stopPropagation()}>{tooltipText}</span>}>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </TagAntd>
  )
}

export default Tag
