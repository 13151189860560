import { Avatar, Space } from "antd"
import cx from "clsx"
import { Card } from "components/common"
import { SvgIconAi } from "components/svg"
import { useSelector } from "react-redux"
import styles from "./index.module.less"
import TextCard from "./textCard"

const MessageBox = ({ message, loading, list, type }) => {
  let userInfo = useSelector(state => state.app.user.info)
  return (
    <div className={cx(styles.msgOuter, "pb-12")}>
      {type === "chatbot" ? (
        <div className={styles.msgBot}>
          <Space size={16} align="start">
            <Avatar icon={<SvgIconAi size={32} />} />
            <div>
              {loading ? <div className={cx(styles.dotFlashing, styles.dots)}></div> : <TextCard message={message} />}
            </div>
            {list && list?.map(item => <Card title={item?.project_title} />)}
          </Space>
        </div>
      ) : (
        <div className={styles.msgUser}>
          <Space size={16} align="start">
            <div className={styles.msgArea}>{message}</div>
            <Avatar src={userInfo?.Attachments?.[0]?.path}>{userInfo?.name?.[0]} </Avatar>
          </Space>
        </div>
      )}
    </div>
  )
}

export default MessageBox
