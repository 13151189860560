import { Modal, Upload, message } from "antd"
import { Icon, Text, notification, notify } from "components/common"
import { useState } from "react"
import { fileToBase64, isNotImageCheck, isPDF } from "utils"

import { useGetDataFromImageMutation } from "features/leads/api"
import { useTranslation } from "react-i18next"

export default function PaymentProofImageUpload(props) {
  const { t } = useTranslation()
  const { fileSize = 1, setFiles, files, paymentProofProps, allowedFiles, ...rest } = props
  const [file, setFile] = useState({ previewTitle: "", previewImage: "", previewOpen: false })

  const [getDataFromImage, { data, isFetching, isLoading, error }] = useGetDataFromImageMutation()
  const allowedFileTypes =
    rest?.platformKey === "bayut_ksa"
      ? ["image/jpeg", "image/jpg", "image/png", "application/pdf"]
      : ["image/jpeg", "image/jpg", "image/png"]
  const handleCancel = () =>
    setFile(prev => {
      return { ...prev, previewOpen: false }
    })

  const isValidUrl = url => {
    try {
      new URL(url)
      return true
    } catch (err) {
      return false
    }
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await fileToBase64(file.originFileObj)
    }

    if (isValidUrl(file?.url || file?.preview) && isPDF(file?.url || file?.preview)) {
      window.open(file?.url || file?.preview)
    }

    setFile(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        previewOpen: true,
      }
    })
  }
  const customUpload = ({ file, onSuccess, onError }) => {
    if (paymentProofProps.api) {
      fileToBase64(file).then(base64 => {
        getDataFromImage({ attachmentTypeId: paymentProofProps.attachmentTypeId, file: base64, name: file.name })
          .unwrap()
          .then(data => {
            onSuccess("ok")
            setFiles({ ...data, attachmentTypeId: paymentProofProps.attachmentTypeId, url: base64, name: file.name })
          })
          .catch(error => {
            setFiles(null)
            onError({ error })
            notify.error(`${error.message}`)
          })
      })
    } else {
      setTimeout(() => {
        onSuccess("ok")
      }, 0)
    }
  }

  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      const isLt5M = file.size / 1024 / 1024 <= fileSize
      if (!isLt5M) {
        notify.error(`${t("File size can not be greater than")}${fileSize}MB`)
        return Upload.LIST_IGNORE
      }
      if (isNotImageCheck(file) && rest?.platformKey !== "bayut_ksa") {
        notify.error(t("Invalid file format. Please upload an image in PNG, JPEG or JPG format"))
        return
      } else {
        if (!paymentProofProps.api) {
          fileToBase64(file).then(base64File => {
            setFiles({
              name: file.name,
              url: base64File,
              uid: "0",
              attachmentTypeId: paymentProofProps.attachmentTypeId,
            })
          })
        }
        setFile(prev => {
          return {
            ...prev,
            containFile: true,
          }
        })

        resolve(true)
      }
    })
  }

  return (
    <>
      <Upload
        maxCount={1}
        listType={"picture-card"}
        fileList={files}
        onPreview={handlePreview}
        customRequest={customUpload}
        beforeUpload={beforeUpload}
        onRemove={() => {
          setFiles(null)
          setFile(prev => ({ ...prev, containFile: false }))
        }}
        accept={allowedFiles || allowedFileTypes.join(",")}
        {...rest}
      >
        {files || file?.containFile ? null : <Text>{t("Add File")}</Text>}
      </Upload>

      {!isNotImageCheck(file) && (
        <Modal open={file.previewOpen} title={file.previewTitle} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={file.previewImage}
          />
        </Modal>
      )}
    </>
  )
}
