import { Button, Card, Group, IconCard } from "components/common"

import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import ChangeAllocationForm from "../changeAllocationForm/changeAllocationForm"
import { Row } from "antd"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "utils/privileges/privileges"
import { useRef } from "react"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"
const AllocationActivity = ({ data, skeletonLoading }) => {
  const { t } = useTranslation()
  const changeAllocationFormRef = useRef()

  return (
    <>
      <Card bodyStyle={{ padding: 16 }}>
        <Group template="repeat(auto-fit, minmax(min(21ch, 100%), 1fr))" gap="8px">
          <IconCard
            icon="MdPersonOutline"
            skeletonLoading={skeletonLoading}
            title={
              <Row justify="space-between">
                {t("Allocated To")}
                <Button
                  className="p-0"
                  type="link"
                  icon="TbEdit"
                  iconProps={{ size: "1em" }}
                  size="small"
                  onClick={() => {
                    fireEvent({ ...ACTION_TYPES.allocated_to_edit_button, client_id: data.id })
                    changeAllocationFormRef?.current?.setVisible(true)
                  }}
                  style={{
                    fontSize: 12,
                    height: 24,
                    position: "absolute",
                    insetInlineEnd: 4,
                    insetBlockStart: 5,
                  }}
                  disabled={!getPrivilege("can_change_assignee", data?.privileges)}
                />
              </Row>
            }
            description={localisedValue({ object: data?.allocationActivity, key: "name" })}
            accentColor="#2cb270"
          />
          <IconCard
            icon="FiPhoneCall"
            title={t("Qualified Call")}
            description={data?.clientEngagement?.qualifiedCalls}
            accentColor="#2cb270"
            skeletonLoading={skeletonLoading}
          />
          <IconCard
            icon="FiCheckCircle"
            title={t("Verified Call")}
            description={data?.clientEngagement?.verifiedCalls}
            accentColor="#34787e"
            skeletonLoading={skeletonLoading}
          />
          <IconCard
            icon="MdOutlinePeople"
            title={t("Meeting Done")}
            description={data?.clientEngagement?.meetingDone}
            accentColor="#0091ba"
            skeletonLoading={skeletonLoading}
          />
        </Group>
      </Card>
      <ChangeAllocationForm
        formRef={changeAllocationFormRef}
        clientIds={[data?.id]}
        currentSelected={data?.User}
        allocationParams={{ teamType: "sales", module: "clients", subModule: "changeLeadAllocation" }}
        type="client-detail"
        filterRequired={false}
      />
    </>
  )
}

export default AllocationActivity
