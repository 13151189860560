import { ACTION_TYPES, CONTENT_TYPES, EVENT_NAMES } from "utils/gtm/gtmEventsData"
import {
  Alert,
  Button,
  DetailBar,
  Group,
  Legend,
  Modal,
  RadioButtons,
  SelectInput,
  TextInput,
  Title,
  notification,
  notify,
} from "components/common"
import { Form, Row } from "antd"
import {
  useAddShareWithSalesMutation,
  useAddUnShareWithSalesMutation,
  useLazyGetCurrentLeadSharingQuery,
} from "features/clients/detail/api"
import { useEffect, useMemo, useRef, useState } from "react"

import { IconText } from "components/custom"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "utils/privileges/privileges"
import getShareLeadSaleValidationSchema from "./shareLeadSaleValidationchema"
import { leadSharePurposeList } from "features/shared/data"
import { regex } from "constants/regex"
import { schemaRules } from "features/shared/utils"
import theme from "utils/themeVars"
import { useLazyGetUsersQuery } from "features/shared/api"
import { useSelector } from "react-redux"
import { useWatch } from "antd/lib/form/Form"
import { useTranslation } from "react-i18next"

export default function ShareLeadSaleForm({ leads, client, formRef, from = {}, onSuccess = () => {} }) {
  const { t } = useTranslation()
  const privileges = useSelector(state => state.app.privileges)
  const canShareWithCount = getPrivilege("can_edit_share_commission", privileges) ? 2 : 1
  const [form] = Form.useForm()
  const selectedCity = useWatch("city", form)
  const commission = useWatch("commission", form)

  const [editForm] = Form.useForm()
  const formUser = useWatch("formUser", editForm)
  const formPurpose = useWatch("formPurpose", editForm)
  const formCommission = useWatch("formCommission", editForm)

  const [getCurrentLeadSharing, { data: currentSharingData, isFetching: isFetchingCurrentSharing }] =
    useLazyGetCurrentLeadSharingQuery()
  const [
    shareWithSales,
    { isLoading: isLoadingShare, error: errorShare, isError: isErrorShare, reset: resetShareMutation },
  ] = useAddShareWithSalesMutation()
  const [
    unShareWithSales,
    { isLoading: isLoadingUnShare, error: errorUnShare, isError: isErrorUnShare, reset: resetUnShareMutation },
  ] = useAddUnShareWithSalesMutation()
  const [getUsers, { data: usersData }] = useLazyGetUsersQuery()

  const [selectedSharing, setSharing] = useState()
  const stopSharingLeadRef = useRef()
  const [sharedToList, setSharedToList] = useState([])
  const [firstCommission, setFirstCommission] = useState(100)
  const [commissionError, setCommissionError] = useState(null)

  const [shareLeadButtonClicked, setShareLeadButtonClicked] = useState(false)
  const [editButtonClicked, setEditButtonClicked] = useState(false)

  const getLatestSharingState = () => {
    const sharedToData = []
    let newCommission = 100
    let sharedToCommission = 0
    currentSharingData?.[leads[0].id]?.sales?.forEach(element => {
      sharedToData.push(element)
      sharedToCommission += parseInt(element?.commission)
    })
    setFirstCommission(newCommission - sharedToCommission)
    setSharedToList(sharedToData)
    setCommissionError(null)
  }

  const getSumOfAllCommissions = () => {
    let currentCommission = parseInt(firstCommission ? firstCommission : 0)
    if (currentCommission < 0) {
      currentCommission *= -1
    }
    if (!!sharedToList.length) {
      let sharedCommission = 0
      sharedToList.forEach(e => {
        let num = parseInt(e.commission ? e.commission : 0)
        if (num < 0) {
          num *= -1
        }
        sharedCommission += num
      })
      currentCommission += sharedCommission
    }
    return currentCommission + parseInt(commission ? commission : 0)
  }

  useEffect(() => {
    leads?.length === 1 && getLatestSharingState()
  }, [currentSharingData, isFetchingCurrentSharing])

  useEffect(() => {
    const currentSum = getSumOfAllCommissions()
    if (currentSum !== 100) {
      setCommissionError(t("Total commission must be equal to 100"))
    } else {
      setCommissionError(null)
    }
  }, [firstCommission, sharedToList])

  useEffect(() => {
    let commissionInput = parseInt(commission ? commission : 0)
    let sharedToCommission = 0
    if (!!sharedToList.length) {
      sharedToList.forEach(e => {
        sharedToCommission += parseInt(e.commission ? e.commission : 0)
      })
    }
    setFirstCommission(100 - sharedToCommission - commissionInput)
  }, [commission])

  const handlePurposeChange = (index, value) => {
    const updateList = []

    sharedToList.forEach((element, i) => {
      let purpose = i === index ? value?.value : element?.purpose

      updateList.push({
        ...element,
        purpose,
      })
    })

    setSharedToList(updateList)
  }

  const handleCommissionChange = (index, value) => {
    const updateList = []

    let sharedToCommission = 0
    sharedToList.forEach((element, i) => {
      let commission = i === index ? value : element.commission
      updateList.push({
        ...element,
        commission,
      })
      sharedToCommission += commission ? parseInt(commission) : 0
    })
    setFirstCommission(100 - sharedToCommission - parseInt(commission ? commission : 0))
    setSharedToList(updateList)
  }
  const rules = schemaRules(getShareLeadSaleValidationSchema())

  const onModalVisible = () => {
    leads?.length === 1 && getCurrentLeadSharing(leads[0].id)
    getUsers({
      teamType: ["sales", "salesaff", "secondarySales"], //"acquisition"
      leadShare: true,
      designationKeys: ["BDA", "AMBD", "AQE", "BDM", "SM", "SSM", "BDM-I", "BDM-II"],
      privileged: false,
    })
  }

  const onModalClose = () => {
    formRef?.current?.setVisible(false)
    form.resetFields()
    setFirstCommission(100)
    setCommissionError(null)
    setSharedToList([])
    resetShareMutation()
    resetUnShareMutation()
    setShareLeadButtonClicked(false)
    setEditButtonClicked(false)
  }

  const cities = useMemo(() => {
    let citiesList = [{ id: -1, label: t("All"), value: "-1" }]
    usersData &&
      usersData?.forEach(e => {
        e.options.forEach(user => {
          if (!!user.cityId && !citiesList.find(item => item.value == user.cityId)) {
            citiesList.push({ id: user.cityId, label: user.city, value: user.cityId.toString() })
          }
        })
      })
    return citiesList
  }, [usersData])

  const users = useMemo(() => {
    let usersList = []
    const sharedWithUsers = sharedToList.map(e => e.sharedWith.id)
    usersData?.forEach(team => {
      const { label, options } = team
      let filteredUsers = options.filter(user => {
        if (sharedWithUsers.includes(user.id) || (leads?.length && leads[0]?.User?.id == user.id)) return false
        if (!selectedCity || selectedCity.value == "-1" || (selectedCity === "All" && !user.cityId)) return true
        return user.cityId == selectedCity.value
      })
      filteredUsers.length > 0 && usersList.push({ label, options: filteredUsers })
    })
    return usersList
  }, [selectedCity, sharedToList, usersData])

  const onShareLead = async values => {
    if (editButtonClicked && !shareLeadButtonClicked) {
      let sharingInfo = []
      let userIds = []
      !!sharedToList?.length &&
        sharedToList.forEach(e => {
          sharingInfo.push({ userId: e.sharedWith.id, commission: e.commission, purpose: e.purpose })
          userIds.push(parseInt(e.sharedWith.id))
        })

      const response = await shareWithSales({ sharingInfo, userIds, leads })
        .unwrap()
        .catch(e => {
          fireEvent({
            ...ACTION_TYPES.lead_sharing_submit,
            ...from,
            response: e.status,
            client_id: client.id,
            content_type: CONTENT_TYPES.sale,
            lead_id: leads?.[0]?.id,
          })
        })

      if (response) {
        form.resetFields()
        leads?.length === 1 && getCurrentLeadSharing(leads[0].id)
        setShareLeadButtonClicked(false)
        setEditButtonClicked(false)
        fireEvent({
          ...ACTION_TYPES.lead_sharing_submit,
          ...from,
          response: "200",
          client_id: client.id,
          content_type: CONTENT_TYPES.sale,
          event_name: EVENT_NAMES.share_lead_submit,
          lead_id: leads?.[0]?.id,
        })
      }
    } else {
      try {
        let { user, commission, purpose } = values
        let sharingInfo = []
        let userIds = []
        !!sharedToList?.length &&
          sharedToList.forEach(e => {
            sharingInfo.push({ userId: e.sharedWith.id, commission: e.commission, purpose: e.purpose })
            userIds.push(parseInt(e.sharedWith.id))
          })
        sharingInfo.push({ userId: user.value, commission: commission, purpose: purpose })

        userIds.push(parseInt(user.value))
        const response = await shareWithSales({ sharingInfo, userIds, leads }).unwrap()

        if (response) {
          form.resetFields()
          leads?.length === 1 && getCurrentLeadSharing(leads[0].id)
          setShareLeadButtonClicked(false)
          setEditButtonClicked(false)
          fireEvent({
            ...ACTION_TYPES.lead_sharing_submit,
            ...from,
            client_id: client?.id,
            response: "200",
            share_sale_commision: values?.commission + "%",
            share_sale_city_filter: values?.city?.label,
            share_sale_user: values?.user?.name,
            share_sale_purpose: leadSharePurposeMapper[values?.purpose],
            lead_id: leads?.[0]?.id,
          })
          onSuccess(leads, formRef)
          notification.success({ message: t("The lead has been shared successfully") })
        }
      } catch (e) {
        fireEvent({
          ...ACTION_TYPES.lead_sharing_submit,
          ...from,
          client_id: client?.id,
          response: e?.status,
          share_sale_commision: values?.commission + "%",
          share_sale_city_filter: values?.city?.label,
          share_sale_user: values?.user?.name,
          share_sale_purpose: leadSharePurposeMapper[values?.purpose],
          lead_id: leads?.[0]?.id,
        })
        notify.error(e)
      }
    }
  }

  const onStopClick = e => {
    setSharing(e)
    stopSharingLeadRef.current.setVisible(true)
  }

  const onStopSharing = async () => {
    const response = await unShareWithSales({ userId: selectedSharing?.sharedWith?.id, leads: leads }).unwrap()
    if (response) {
      form.resetFields()
      leads?.length === 1 && getCurrentLeadSharing(leads[0].id)
      stopSharingLeadRef?.current?.setVisible(false)
    }
  }

  const leadSharePurposeMapper = useMemo(() => {
    const obj = {}
    leadSharePurposeList().forEach(e => {
      obj[e.value] = e.label
    })
    return obj
  }, [leadSharePurposeList, t])

  return (
    <>
      <Modal
        title={t("Stop Sharing")}
        ref={stopSharingLeadRef}
        width={600}
        okText={t("Stop Sharing")}
        cancelText={t("Cancel")}
        onCancel={() => {
          stopSharingLeadRef?.current?.setVisible(false)
        }}
        onOk={onStopSharing}
        disableOkButton={isLoadingUnShare}
        loading={isLoadingUnShare}
      >
        <div className="pb-24 fs16">
          {t("Are you sure, to stop sharing the lead with")} "{selectedSharing?.sharedWith?.name}"?
        </div>
        {isErrorUnShare && <Alert type="error" message={errorUnShare.message} />}
      </Modal>

      <Modal
        title={t("Share Lead")}
        subTitle={t("Sale")}
        ref={formRef}
        width={900}
        okText={shareLeadButtonClicked ? t("Share Lead") : t("Save Changes")}
        onOkProps={!shareLeadButtonClicked && !editButtonClicked && { style: { display: "none" } }}
        cancelText={t("Cancel")}
        onCancel={onModalClose}
        onOk={form.submit}
        disableOkButton={isLoadingShare || commissionError}
        loading={isLoadingShare}
        onModalVisible={onModalVisible}
        footerAlert={errorShare || errorUnShare}
      >
        {!editButtonClicked && !shareLeadButtonClicked && (
          <>
            {!shareLeadButtonClicked && leads?.length === 1 && (
              <Group gap="12px">
                <Row align="middle" justify="space-between">
                  <Title type="secondary" level={4} className="mbe-0">
                    {t("Currently Shared")}
                  </Title>
                  {!!sharedToList.length && (
                    <Button
                      type="link"
                      className="p-0"
                      icon="TbEdit"
                      text={t("Edit")}
                      onClick={() => setEditButtonClicked(true)}
                      disabled={!getPrivilege("can_edit_share_commission", privileges)}
                    />
                  )}
                </Row>
                <DetailBar
                  detailData={[
                    { label: t("Staff"), value: leads[0]?.User?.name },
                    { label: t("Purpose"), value: "Allocated Lead" },
                    { label: t("Commission"), value: firstCommission + " %" },
                  ]}
                  evenly
                />

                {sharedToList?.map((salesSharing, i) => (
                  <div key={salesSharing?.id}>
                    <DetailBar
                      detailData={[
                        { label: t("Staff"), value: salesSharing?.sharedWith?.name },
                        { label: t("Purpose"), value: leadSharePurposeMapper[salesSharing?.purpose] },
                        { label: t("Commission"), value: salesSharing?.commission + " %" },
                        {
                          component: (
                            <Row justify="end" style={{ maxWidth: 0 }}>
                              <Button
                                className="p-0"
                                size="small"
                                type="link"
                                icon="AiOutlineStop"
                                danger
                                onClick={() => onStopClick(salesSharing)}
                                disabled={!getPrivilege("can_stop_sharing_lead_with_sales", leads[0]?.privileges)}
                              >
                                {t("Stop Sharing")}
                              </Button>
                            </Row>
                          ),
                        },
                      ]}
                      evenly
                    />
                  </div>
                ))}

                <IconText
                  icon="MdInfoOutline"
                  textType="secondary"
                  iconProps={{ size: "1em", color: theme["gray800"] }}
                  title={`${t("Please note lead can be shared with only")} ${
                    sharedToList.length > canShareWithCount ? sharedToList.length : canShareWithCount
                  } ${t("users")}`}
                />
              </Group>
            )}
          </>
        )}

        {(shareLeadButtonClicked || editButtonClicked) && leads?.length === 1 && (
          <Form
            form={form}
            layout="vertical"
            name="editShareLeadForm"
            initialValues={{ formUser: null, formPurpose: null, formCommission: "" }}
            onFinish={onShareLead}
          >
            <Legend title={t("Edit Share Lead")} />
            <Group template="repeat(3, 1fr)" gap="6px 24px" className={"mbe-16"}>
              <Form.Item name={formUser} label={t("User")}>
                <TextInput placeholder={t("Select user")} defaultValue={leads[0]?.User?.name} disabled />
              </Form.Item>
              <Form.Item name={formPurpose} label={t("Purpose")}>
                <SelectInput
                  placeholder={t("Select purpose")}
                  defaultValue={t(`Allocated Lead`)}
                  options={leadSharePurposeList()}
                  onChange={(_value, option) => {
                    form.setFieldsValue({ formPurpose: option })
                  }}
                  disabled
                />
              </Form.Item>

              <Form.Item name={formCommission} label={t("Commission")}>
                <TextInput
                  type="number"
                  onKeyDown={e => {
                    if (e.key === "+" || e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  defaultValue={firstCommission}
                  value={firstCommission}
                  handleChange={e => {
                    let val = e.target.value
                    val ? regex.wholeNumbers.test(val) && setFirstCommission(val) : setFirstCommission(val)
                  }}
                  errorMsg={
                    (firstCommission < 0 && t("Commission should be greater than or equal to 0")) ||
                    (firstCommission > 100 && t("Commission should not exceed 100"))
                  }
                  suffixText="%"
                />
              </Form.Item>
            </Group>
            {sharedToList?.map((salesSharing, i) => (
              <Group template="repeat(3, 1fr)" gap="6px 24px" key={salesSharing?.id}>
                <Form.Item name={formUser} label={`${i === 0 ? t("Second") : t("Third")} ${t("User")}`}>
                  <TextInput placeholder={t("Select user")} defaultValue={salesSharing?.sharedWith?.name} disabled />
                </Form.Item>

                <Form.Item name={formPurpose} label={t("Purpose")}>
                  <SelectInput
                    placeholder={t("Select purpose")}
                    defaultValue={salesSharing?.purpose}
                    options={leadSharePurposeList()}
                    onChange={(_value, option) => {
                      handlePurposeChange(i, option)
                    }}
                  />
                </Form.Item>

                <Form.Item name={formCommission} label={t("Commission")}>
                  <TextInput
                    type="number"
                    onKeyDown={e => {
                      if (e.key === "+" || e.key === "-") {
                        e.preventDefault()
                      }
                    }}
                    defaultValue={salesSharing?.commission}
                    onChange={e => {
                      let val = e.target.value
                      val
                        ? regex.wholeNumbers.test(val) && handleCommissionChange(i, val)
                        : handleCommissionChange(i, val)
                    }}
                    suffixText="%"
                    errorMsg={
                      (sharedToList[i]?.commission < 0 && t("Commission should be greater than or equal to 0")) ||
                      (sharedToList[i]?.commission > 100 && t("Commission should not exceed 100"))
                    }
                  />
                </Form.Item>
              </Group>
            ))}
          </Form>
        )}

        {shareLeadButtonClicked && (
          <Form
            form={form}
            layout="vertical"
            name="shareLeadForm"
            initialValues={{ city: cities[0], user: null, purpose: null, commission: "" }}
            onFinish={onShareLead}
            disabled={sharedToList.length >= canShareWithCount}
          >
            <Legend title={t("Share Lead")} />
            <Group template="repeat(2, 1fr)" gap="6px 24px">
              <Form.Item name="city" label={t("City")} rules={rules} required shouldUpdate>
                <SelectInput
                  placeholder={t("Select a city")}
                  options={cities}
                  onChange={(_value, option) => {
                    form.setFieldsValue({ city: option, user: null })
                  }}
                />
              </Form.Item>
              <Form.Item name="user" label={t("User")} rules={rules} required shouldUpdate>
                <SelectInput
                  placeholder={t("Select user")}
                  options={users}
                  onChange={(_value, option) => {
                    form.setFieldsValue({ user: option })
                  }}
                />
              </Form.Item>
              <Form.Item name="purpose" label={t("Purpose")} rules={rules} required>
                <RadioButtons options={leadSharePurposeList()} />
              </Form.Item>
              <Form.Item
                name="commission"
                label={t("Commission")}
                rules={rules.concat({
                  validator: async (_, value) => {
                    let sum = 0
                    sharedToList.forEach((element, index) => {
                      let num = parseInt(element?.commission)
                      if (num < 0) {
                        num *= -1
                      }
                      sum += num
                    })
                    sum += parseInt(commission)
                    if (sum > 100) {
                      return Promise.reject(t("Total commission should be equal to 100"))
                    } else {
                      return Promise.resolve()
                    }
                  },
                })}
                required
              >
                <TextInput
                  type="number"
                  onKeyDown={e => {
                    if (e.key === "+" || e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  placeholder={t("Enter commission")}
                  suffixText="%"
                />
              </Form.Item>
            </Group>
          </Form>
        )}

        {!shareLeadButtonClicked && !(sharedToList.length >= canShareWithCount) && (
          <Row justify={"center"} style={{ marginTop: 36 }}>
            <Button
              size="large"
              type="link"
              icon="MdAddCircleOutline"
              text={t("Share Lead")}
              disabled={sharedToList.length >= canShareWithCount}
              onClick={() => setShareLeadButtonClicked(true)}
            />
          </Row>
        )}
      </Modal>
    </>
  )
}
