import { DataTable, Drawer, Group, Icon, Tag, Title } from "components/common"
import { forwardRef, useImperativeHandle, useMemo, useState } from "react"

import { Tabs } from "antd"
import { Assessment } from "features/clients/components/callsTab/assesment"
import CallDetailCard from "features/clients/components/callsTab/callDetailCard"
import CallSummary from "features/clients/components/callsTab/callSummary"
import { ClientInformation } from "features/clients/components/callsTab/clientInformation"
import Transcription from "features/clients/components/callsTab/transcription"
import { useGetCallDetailQuery } from "features/clients/detail/api"
import { IoMdArrowBack } from "react-icons/io"
import { convertToReadableString } from "utils"

const ClientTabsSkeleton = () => {
  return <DataTable loading data={[]} columns={[]} />
}

const CallDrawer = forwardRef(({ selectedCall, onBack }, ref) => {
  const [showCallsDrawer, setShowCallsDrawer] = useState(false)
  const [tabKey, setTabKey] = useState("summary")

  const toggleDrawer = () => {
    setShowCallsDrawer(prev => !prev)
  }

  useImperativeHandle(ref, () => ({
    toggleDrawer,
  }))

  const { data: callDetail, isFetching: fetchingCallDetail } = useGetCallDetailQuery(selectedCall?.phoneCallId, {
    skip: !selectedCall,
  })

  const callTabs = [
    {
      label: "Summary",
      key: "summary",
      children: <CallSummary callDetail={{ ...(selectedCall ?? {}), ...(callDetail ?? {}) }} />,
    },
    ...(callDetail?.transcription?.length
      ? [
          {
            label: "Transcription",
            key: "transcription",
            children: <Transcription transcription={callDetail?.transcription} />,
          },
        ]
      : []),
    ...(callDetail?.clientInformation
      ? [
          {
            label: "Client Information",
            key: "clientInfo",
            children: <ClientInformation data={callDetail?.clientInformation} />,
          },
        ]
      : []),
    ...(callDetail?.assessment
      ? [
          {
            label: "Assessment",
            key: "assessment",
            children: <Assessment data={callDetail?.assessment} />,
          },
        ]
      : []),
  ]

  const getTagColor = tag => {
    switch (tag) {
      case "Fake":
        return "#e83a3a"
      case "Meeting Approved":
        return "#00A4D1"
      case "Meeting Rejected":
        return "#e83a3a"
      default:
        break
    }
  }
  const renderCallTags = useMemo(() => {
    return callDetail?.callTags?.map((tag, index) => (
      <Tag className="mbe-8" key={index} color={getTagColor(tag)}>
        {tag}
      </Tag>
    ))
  }, [callDetail])

  return (
    <Drawer
      title={
        <Group template="auto 1fr" className="flexYcenter">
          <IoMdArrowBack
            onClick={() => {
              onBack(toggleDrawer)
            }}
          />
          <div>
            <Title level={3} className="mbe-0">
              Voice Transcription
            </Title>
            <div className="gray-700 fs12 lhn">Revolutionising the way we transcribe conversations with clients.</div>
          </div>
        </Group>
      }
      headerStyle={{ paddingBlock: 20 }}
      closable={false}
      placement="right"
      width={700}
      onClose={toggleDrawer}
      open={showCallsDrawer}
      // className={styles.filterMain}
      extra={<Icon icon="IoMdClose" onClick={toggleDrawer} />}
      destroyOnClose={true}
    >
      {fetchingCallDetail ? (
        <ClientTabsSkeleton />
      ) : (
        <div>
          {renderCallTags}
          <CallDetailCard callDetail={{ ...(selectedCall ?? {}), ...(callDetail ?? {}) }} />
          <Tabs
            activeKey={tabKey}
            items={callTabs}
            onChange={key => {
              setTabKey(key)
            }}
          />
        </div>
      )}
    </Drawer>
  )
})
export default CallDrawer
