import { Form, Input, Layout } from "antd"
import { Alert, Button, Card, Divider, Group, Modal, Text, Title, notification } from "components/common"
import { LogoPropforce, SvgForgotPassword, SvgGoogle } from "components/svg"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { setAppToken, setIsLoggedIn } from "store/appSlice"
import { AUTH_TOKEN_COOKIE_KEY, PROPGO_APP_LINK, PROPPLUS_APP_LINK } from "../../constants"
import { useForgotPasswordMutation, useGoogleLoginMutation, useGoogleUrlMutation, useLoginMutation } from "./api"

import cx from "clsx"
import { LOGIN_PATH } from "constants/app-paths"
import { usePageTitle } from "hooks/usePageTitle"
import Cookies from "js-cookie"
import { AiOutlineInfoCircle } from "react-icons/ai"
import QRCode from "react-qr-code"
import { useDispatch } from "react-redux"
import { downloadFileFromLink } from "utils"
import { inDevelopMode, inProductionMode } from "utils/env"
import styles from "./index.module.less"
import { useTranslation } from "react-i18next"

const SignIn = () => {
  const { t } = useTranslation()

  usePageTitle(t("Login - Propforce"))
  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const [login, { isLoading, isError, error, reset }] = useLoginMutation()
  const [getGoogleUrl, { isLoading: isLoadingGoogleUrl, error: errorGoogleUrl }] = useGoogleUrlMutation()
  const [forgotPassword, { isLoading: isLoadingForget, isError: isErrorForget, error: errorForget }] =
    useForgotPasswordMutation()
  const [googleLogin, { isLoading: isGoogleLoading, isError: isGoogleError, error: errorGoogle, reset: resetGoogle }] =
    useGoogleLoginMutation()

  const [toLogin, setLogin] = useState(!!params.from)

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isLoading || isGoogleLoading) {
      setSearchParams({})
    }
  }, [isLoading, isGoogleLoading])

  useEffect(() => {
    params?.idToken && onClickLoginWithGoogle()
  }, [params?.idToken])

  const onGoogleLogin = async credentialResponse => {
    isError && reset()
    const response = await googleLogin({ credential: credentialResponse?.credential }).unwrap()
    if (response.token) {
      dispatch(setIsLoggedIn(true))
      if (inDevelopMode) {
        Cookies.set(AUTH_TOKEN_COOKIE_KEY, response.token)
        dispatch(setAppToken({ token: response.token }))
      }
    }
  }

  const handleSubmitLogin = async values => {
    isGoogleError && resetGoogle()
    const response = await login(values).unwrap()
    if (response.token) {
      dispatch(setIsLoggedIn(true))
      if (inDevelopMode) {
        Cookies.set(AUTH_TOKEN_COOKIE_KEY, response.token)
        dispatch(setAppToken({ token: response.token }))
      }
    }
  }

  const handleSubmitForgotPassword = async values => {
    const response = await forgotPassword(values).unwrap()
    if (response) {
      navigate(LOGIN_PATH)
      notification.success({
        message: t(
          "An email with password reset instructions has been sent to you. Please follow the steps listed to proceed"
        ),
      })
    }
  }

  const onClickLoginWithGoogle = () => {
    if (params?.idToken) {
      onGoogleLogin({ credential: params?.idToken })
    } else {
      getGoogleUrl().then(e => {
        if (e.data.url) {
          window.open(e.data.url, "_self")
        }
      })
    }
  }

  const getErrorComp = message => (
    <>
      {message.split("\n").map(e => (
        <li key={e}>{e}</li>
      ))}
    </>
  )

  const renderGoogleLogin = () => (
    <div className={cx(styles.googleBtn, "mbe-20")}>
      <Button
        type="primary"
        text={t("Sign in with Google")}
        icon={<SvgGoogle size="28px" />}
        loading={isLoadingGoogleUrl || isGoogleLoading}
        onClick={onClickLoginWithGoogle}
        className={styles.googleLogin}
        size="large"
        block
      />
    </div>
  )

  const [modal1Open, setModal1Open] = useState(false)
  const [modal2Open, setModal2Open] = useState(false)

  const renderLoginWithEmailContent = () => (
    <Form name="login" form={form} onFinish={handleSubmitLogin} layout="vertical">
      <Form.Item
        name="username"
        rules={[
          { message: t("Please enter email"), required: true },
          { message: t("Please enter valid email"), type: "email" },
        ]}
        initialValue=""
        label={t("Email")}
      >
        <Input placeholder={t("e.g. jane.doe@example.com")} />
      </Form.Item>
      <Form.Item
        name="password"
        initialValue=""
        label={
          <>
            {t("Password")}
            <Link to="#forget-password" className={cx(styles.forget, "text-link")}>
              {t("Forgot Password")}
            </Link>
          </>
        }
        rules={[{ message: t("Please enter password"), required: true }]}
        className={styles.labelForget}
      >
        <Input.Password placeholder={t("Enter password")} />
      </Form.Item>
      {isError && !!error?.message && <Alert className="mbe-8" message={getErrorComp(error?.message)} type="error" />}
      <Button
        htmlType="submit"
        type="primary"
        text={t("Sign In")}
        size="large"
        className="mbe-32"
        loading={isLoading}
        disabled={isLoading}
        block
      />
    </Form>
  )

  const renderForgotPasswordContent = () => (
    <>
      <div className="text-center">
        <Title level={2} className="mbe-12">
          {t("Forgot Password")} <SvgForgotPassword />
        </Title>
        <Text type="secondary" className="mbe-40">
          {t("Please enter your email to reset your password")}
        </Text>
        <Form name="login" form={form} onFinish={handleSubmitForgotPassword} layout="vertical" className="text-left">
          <Form.Item
            name="email"
            rules={[
              { message: t("Please enter email"), required: true },
              { message: t("Please enter valid email"), type: "email" },
            ]}
            initialValue=""
            label={t("Email")}
          >
            <Input placeholder={t("Email")} />
          </Form.Item>
          {isErrorForget && errorForget?.message && (
            <Alert
              message={getErrorComp(errorForget?.message)}
              type="error"
              className="mbe-8"
              icon={<AiOutlineInfoCircle />}
              showIcon
            />
          )}
          <Button
            text={t("Reset Password")}
            htmlType="submit"
            type="primary"
            size="large"
            loading={isLoadingForget}
            disabled={isLoadingForget}
            className="mbe-16"
            block
          />
        </Form>
        <Link to={LOGIN_PATH} className="text-link bold">
          {t("Go Back")}
        </Link>
      </div>
    </>
  )

  const renderAppCodes = () => (
    <>
      <Group template="1fr 1fr" gap="8px" className={styles.getAppBtns}>
        <Button
          size="large"
          icon="LogoIcon"
          iconProps={{ size: "24px" }}
          text={t("Get Propforce App")}
          onClick={() => setModal1Open(true)}
          block
        />
        <Button
          size="large"
          icon="IconGo"
          iconProps={{ size: "24px" }}
          text={t("Get PropGo App")}
          onClick={() => setModal2Open(true)}
          block
        />
      </Group>

      <Modal open={modal1Open} onCancel={() => setModal1Open(false)} footer={null} bodyStyle={{ padding: 48 }}>
        <Group template="1fr auto 1fr" style={{ placeContent: "center", alignItems: "center" }}>
          <div>
            <Button
              size="large"
              icon="LogoIcon"
              iconProps={{ size: "24px" }}
              className={cx(styles.getAppBtn, "pi-32 pb-8")}
              text={
                <>
                  <Text type="secondary" className="fs12">
                    {t("Get Propplus On")}
                  </Text>
                  <div>{t("Google Play Store")}</div>
                </>
              }
              onClick={() => downloadFileFromLink({ url: PROPPLUS_APP_LINK, filename: "PropPlus" }, "url")}
            />
          </div>
          <div className={cx(styles.dividerVertical)}>
            <span>{t("Or")}</span>
          </div>
          <div className="text-center">
            <Text type="secondary" className="mbe-24 bold">
              {t("Download by scanning the QR Code")}
            </Text>
            <QRCode value={PROPPLUS_APP_LINK} size={180} />
          </div>
        </Group>
      </Modal>

      <Modal open={modal2Open} onCancel={() => setModal2Open(false)} footer={null} bodyStyle={{ padding: 48 }}>
        <Group template="1fr auto 1fr" style={{ placeContent: "center", alignItems: "center" }}>
          <div>
            <Button
              size="large"
              icon="IconGo"
              iconProps={{ size: "24px" }}
              className={cx(styles.getAppBtn, "pi-32 pb-8")}
              text={
                <>
                  <Text type="secondary" className="fs12">
                    {t("Get Propgo On")}
                  </Text>
                  <div>{t("Google Play Store")}</div>
                </>
              }
              onClick={() => window.open(PROPGO_APP_LINK, "_blank")}
            />
          </div>
          <div className={cx(styles.dividerVertical)}>
            <span>{t("Or")}</span>
          </div>
          <div className="text-center">
            <Text type="secondary" className="mbe-24 bold">
              {t("Download by scanning the QR Code")}
            </Text>
            <QRCode value={PROPGO_APP_LINK} size={180} />
          </div>
        </Group>
      </Modal>
    </>
  )

  return (
    <Layout.Content className={styles.authLayout}>
      <Group template="1fr 552px" gap="32px" style={{ minHeight: "100vh" }}>
        <div className="pb-32">
          <div className={styles.logoPosition}>
            <div className="mbe-24" style={{ marginTop: 90 }}>
              <LogoPropforce width={228} height="auto" />
            </div>
            <Title level={1} className={cx(styles.logoHeading, "mbe-12")}>
              {t("Elevate your Sales with Propforce")}
            </Title>
            <Text type="secondary" className={styles.logoSubHeading}>
              {t("Efficiently manage your sales funnel and close leads faster")}
            </Text>
          </div>
        </div>
        <div className="pb-32 d-grid" style={{ alignContent: "center" }}>
          <Card className="pi-40 pb-8">
            {location.hash === "#forget-password" ? (
              renderForgotPasswordContent()
            ) : (
              <>
                <Title level={2} className="text-center mbe-40">
                  {t("Welcome Back")}
                </Title>
                {renderGoogleLogin()}
                {isGoogleError && !!errorGoogle?.message && (
                  <Alert className="mbe-8" message={getErrorComp(errorGoogle?.message)} type="error" />
                )}
                {!!params?.error && <Alert className="mbe-8" message={decodeURIComponent(params.error)} type="error" />}
                {!inProductionMode && (
                  <>
                    <Divider className={cx(styles.divider, "mbe-20")} orientation="center">
                      OR
                    </Divider>
                    {toLogin ? (
                      renderLoginWithEmailContent()
                    ) : (
                      <Button
                        type="link"
                        className="p-0 mbe-32"
                        text="Login with Email & Password"
                        onClick={() => setLogin(true)}
                        style={{
                          height: "auto",
                        }}
                        block
                      />
                    )}
                  </>
                )}
                {renderAppCodes()}
              </>
            )}
          </Card>
        </div>
      </Group>
    </Layout.Content>
  )
}

export default SignIn
