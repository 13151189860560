import { Space } from "antd"
import { SvgAnalyzerIcon } from "components/svg"
import Tag from "../tag/tag"
import theme from "utils/themeVars"

export const AIBadgeScore = ({ className, totalScore, handleDrawer }) => {
  const getCallScoreColor = score => {
    if (!score) return theme["error-color"]
    let parts = score?.split("/")
    return Number(parts[0]) < 50 ? theme["error-color"] : theme["accent-color"]
  }
  return (
    <Space align="center" className={className} onClick={handleDrawer}>
      <SvgAnalyzerIcon style={{ verticalAlign: "top" }} size="38" />
      <div>
        <Tag size="small" color={getCallScoreColor(totalScore)}>
          {totalScore}
        </Tag>
      </div>
    </Space>
  )
}
