import { localisedValue } from "utils"

const staffListTransformer = response => {
  const data = [response]
  const transformedData = hierarchicalStaffTransformer(data)
  return transformedData
}
const hierarchicalStaffTransformer = staffArray => {
  const transformedArray = staffArray.map(item => {
    const { children, ...remainingItems } = item
    return {
      title: localisedValue({ object: remainingItems, key: "name" }),
      value: remainingItems?.userId?.toString(),
      key: remainingItems?.userId?.toString(),
      regionId: remainingItems?.regionId,
      ...(children?.length && { children: hierarchicalStaffTransformer(children) }),
    }
  })
  return transformedArray
}

export default staffListTransformer
