import { privilegesMapper } from "./privilegesMapper"

const getPrivilege = (privilegeKey, allPrivileges = {}, levelKey = "default") => {
  if (Array.isArray(privilegeKey)) {
    return privilegeKey.some(e => !!allPrivileges?.[privilegesMapper[e]]?.[levelKey])
  }
  return !!allPrivileges?.[privilegesMapper[privilegeKey]]?.[levelKey]
}

export default getPrivilege
