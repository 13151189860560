import { DATE_FORMAT } from "constants"
import moment from "moment"

const CallTransformer = response => {
  return response?.map(item => ({
    value: item?.id,
    label: `${moment(item?.callTime).format(DATE_FORMAT)} | ${moment(item?.callTime).format("hh:mm A")} | ${
      item?.duration
    }`,
    ...item,
  }))
}

export default CallTransformer
