import { TreeSelect } from "antd"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { filterCriteria } from "utils"
import HoverText from "../hoverable-text/hoverable-text"
import Icon from "../icon/icon"
import Spinner from "../spinner/spinner"
import Switch from "../switch/switch"

const { SHOW_PARENT } = TreeSelect
function TreeSelectInput(props) {
  const {
    label,
    placeholder,
    options,
    prefixIcon,
    getOptionLabel,
    showSearch,
    className,
    handleOnChange,
    inputLoading,
    getPopupContainer = true,
    treeNodeFilterProp = "",
    treeCheckable = true,
    toggle,
    defaultToggleValue = true,
    zIndex,
    ...rest
  } = props

  const [isToggle, setIsToggle] = useState(defaultToggleValue)

  useEffect(() => {
    !!toggle && setIsToggle(defaultToggleValue)
  }, [defaultToggleValue])

  const onToggleClick = () => {
    setIsToggle(prev => !prev)
    handleOnChange([])
  }

  const handleChangeTreeSelect = item => {
    if (!!toggle) {
      return handleOnChange(item, !isToggle)
    }
    handleOnChange(item)
  }
  const renderTreeSelect = () => {
    return (
      <>
        <TreeSelect
          showCheckedStrategy={SHOW_PARENT}
          treeData={options || []}
          onChange={handleChangeTreeSelect}
          allowClear
          treeCheckable={treeCheckable}
          className={className}
          showSearch={showSearch}
          treeNodeFilterProp={treeNodeFilterProp}
          maxTagCount="responsive"
          filterTreeNode={(inputValue, option) => filterCriteria(inputValue, option, getOptionLabel)}
          maxTagPlaceholder={items => <HoverText message={`+${items.length}`} data={items.map(getOptionLabel)} />}
          showArrow
          suffixIcon={
            inputLoading ? (
              <Spinner type="plain" />
            ) : (
              <Icon icon="AiFillCaretDown" className="ant-select-suffix" color="#9D9D9D" size={"16px"} />
            )
          }
          placeholder={
            <>
              {prefixIcon && <Icon icon={prefixIcon} />}
              {placeholder && placeholder}
            </>
          }
          dropdownStyle={{ "--select-dropdown-accent-color": rest.accentColor, zIndex: zIndex }}
          dropdownRender={menu =>
            toggle ? (
              <>
                <div className="pi-12">
                  <Switch value={isToggle} size="small" onChange={onToggleClick} label={toggle?.label} />
                </div>
                {menu}
              </>
            ) : (
              menu
            )
          }
          {...(getPopupContainer ? { getPopupContainer: triggerNode => triggerNode.parentElement } : {})}
          {...rest}
        />
      </>
    )
  }
  const renderLabel = () => {
    return label && <label style={{ display: "block" }}>{label}</label>
  }
  return (
    <>
      <div>
        {renderLabel()}
        {renderTreeSelect()}
      </div>
    </>
  )
}

TreeSelectInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  prefixIcon: PropTypes.string,
  showSearch: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

TreeSelectInput.defaultProps = {
  options: [],
  getOptionLabel: item => item.label || item.name || item.title,
  showSearch: true,
}

export default TreeSelectInput
