import {
  Button,
  DatePicker,
  Group,
  Legend,
  Modal,
  PhoneInput,
  RadioButtons,
  SelectInput,
  TextInput,
} from "components/common"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  useLazyGetCheckEmailQuery,
  useLazyGetCheckNationalIdQuery,
  useLazyGetCheckPhoneNumberQuery,
  useLazyGetCitiesQuery,
  useLazyGetClientBuyingIntentQuery,
  useLazyGetClientCellsQuery,
  useLazyGetClientRatingQuery,
  useLazyGetClientTypeQuery,
  useLazyGetCountriesQuery,
  useLazyGetLocalityQuery,
  useLazyGetOccupationQuery,
} from "features/shared/api"

import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import AffiliateSection from "./affiliateSection"
import ClientProfileForm from "features/tasks/components/clientProfileForm/clientProfileForm"
import { DATE_FORMAT } from "constants"
import { Form, notification } from "antd"
import _ from "lodash"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { getClientInitialValues } from "./clientInitialValues"
import getClientValidationSchema from "./clientValidationSchema"
import getPrivilege from "utils/privileges/privileges"
import { getStoreCacheData } from "utils"
import { isValidPhoneNumber } from "react-phone-number-input"
import moment from "moment"
import { schemaRules } from "features/shared/utils"
import store from "store"
import theme from "utils/themeVars"
import { useEditClientMutation } from "features/clients/detail/api"
import { useWatch } from "antd/lib/form/Form"
import { genderList, clientClassOptions } from "constants/list"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export default function ClientForm({ formRef, client }) {
  const { t } = useTranslation()
  const [mobileNumberToggler, setMobileNumberToggler] = useState(false)
  const [getCountries, { data: countries, isFetching: isFetchCountry }] = useLazyGetCountriesQuery()
  const [getOccupations, { data: occupations, isFetching: isFetchingOccupations }] = useLazyGetOccupationQuery()
  const [getClientCellInfo, { data: clientCells, isFetching: isFetchingClientCells }] = useLazyGetClientCellsQuery()
  const [getClientBuyingIntents, { data: clientBuyingIntents }] = useLazyGetClientBuyingIntentQuery()
  const [getClientRatings, { data: clientRatings }] = useLazyGetClientRatingQuery()
  const [getClientTypes, { data: clientTypes }] = useLazyGetClientTypeQuery()
  const [getCities, { data: cities, isError: isCityError, isFetching: isCitiesFetching }] = useLazyGetCitiesQuery()
  const [getLocalities, { data: localities, isError: isLocalityError, isFetching: isLocalityFetching }] =
    useLazyGetLocalityQuery()
  const [editClient, { error, isError, isLoading, reset: resetEditClientError }] = useEditClientMutation()
  const [checkEmail, { data: emailData, isError: emailError, isFetching: isEmailCheckFetching }] =
    useLazyGetCheckEmailQuery()
  const [checkPhone, { data: phoneData, isError: phoneError }] = useLazyGetCheckPhoneNumberQuery()
  const [checkCnic, { data: cnicData, isError: cnicError }] = useLazyGetCheckNationalIdQuery()

  const [form] = Form.useForm()
  const clientProfileRef = useRef()
  let rules = schemaRules(getClientValidationSchema())
  const country = useWatch("country", form)
  const city = useWatch("city", form)
  const clientClassWatch = useWatch("clientClass", form)

  const userPrivileges = useSelector(state => state.app.privileges)

  const canEditAndRemoveCells = getPrivilege("can_edit_and_remove_client_cells", userPrivileges)
  const canViewClientContact = getPrivilege("can_view_client_contact", userPrivileges)

  const debounceEmailValidator = useCallback(
    _.debounce(value => {
      const response = checkEmail(value).unwrap()
      response.then(e => {
        form.validateFields(["email"])
      })
    }, 500),
    []
  )

  const debounceCnicValidator = useCallback(
    _.debounce(value => {
      const response = checkCnic(value).unwrap()
      response.then(e => {
        form.validateFields(["cnic"])
      })
    }, 500),
    []
  )

  const onModalVisible = () => {
    getCountries()
    getOccupations()
    getClientBuyingIntents()
    getClientRatings()
    getClientTypes()
    client?.Country && getCities(client?.Country.id)
    client?.City && getLocalities(client?.City.id)
  }

  const onSubmit = async values => {
    const response = await editClient({ client, mobileNumberViewed: mobileNumberToggler, ...values })
      .unwrap()
      .catch(e => {
        fireEvent({ ...ACTION_TYPES.cleint_edit_submit, response: e.status, client_id: client.id })
      })
    if (response) {
      formRef?.current?.setVisible(false)
      fireEvent({ ...ACTION_TYPES.cleint_edit_submit, response: "200", client_id: client.id })
      setMobileNumberToggler(false)
    }
  }

  useEffect(() => {
    if (client) {
      form.setFieldsValue(getClientInitialValues(client))
    }
  }, [client, form])

  const fetchClientsCells = () => {
    getClientCellInfo({ clientId: client?.id, actionType: "edit_client" })
      .unwrap()
      .then(res => {
        form.setFieldValue("mobileNumber", res?.length ? res?.map(e => e) : ["+92"])
        setMobileNumberToggler(true)
      })
  }

  function checkDuplicates(array) {
    const uniqueSet = new Set(array)
    return uniqueSet.size !== array.length
  }

  return (
    <Modal
      title={t("Edit Client")}
      ref={formRef}
      okText={t("Save")}
      width={900}
      cancelText={t("Cancel")}
      onModalVisible={onModalVisible}
      onCancel={() => {
        formRef?.current?.setVisible(false)
        form.resetFields()
        resetEditClientError()
        setMobileNumberToggler(false)
      }}
      onOk={form.submit}
      loading={isLoading}
      destroyOnClose
      footerAlert={error}
      // disableOkButton={
      //   !form.isFieldsTouched() || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
      // }
    >
      <Form
        form={form}
        layout="vertical"
        name="client-form"
        initialValues={getClientInitialValues(client)}
        onFinish={onSubmit}
        scrollToFirstError
        preserve={false}
      >
        <Legend
          title={t("Client Info")}
          extra={
            // !client.profileFilled && (
            <Button
              className="p-0"
              style={{ "--btn-color": theme["accent-color"], height: "auto" }}
              type="link"
              onClick={() => {
                clientProfileRef?.current?.setVisible(true)
              }}
            >
              {t("Fill client profile")}?
            </Button>
            // )
          }
        />
        {!!mobileNumberToggler ? (
          <Group className="mbe-24" template="repeat(2, 1fr)" gap="16px 64px">
            <Form.List
              name="mobileNumber"
              rules={rules.concat({
                validator: async (_, value) => {
                  if (checkDuplicates(value)) {
                    notification.error({ message: "Duplicate Phone Number Entered" })
                    return Promise.reject(t("Phone number already exists "))
                  } else {
                    return Promise.resolve()
                  }
                },
              })}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Form.Item
                        key={field.key}
                        name={field.name}
                        requiredx
                        hasFeedback
                        validateTrigger={["onBlur"]}
                        {...field}
                        rules={[
                          {
                            validator: async (_, value) => {
                              const phoneApiData = getStoreCacheData("getCheckPhoneNumber", { cell: value })
                              if (!value) return Promise.reject(t("Phone number is required"))
                              if (
                                !phoneError &&
                                !!phoneApiData &&
                                phoneApiData?.exists &&
                                value == phoneApiData?.data.number
                              )
                                return Promise.reject(t("Phone number already exists"))
                              return isValidPhoneNumber(value)
                                ? Promise.resolve()
                                : Promise.reject(t("Invalid phone number"))
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          disabled={!canEditAndRemoveCells ? (clientCells?.[index]?.length > 0 ? true : false) : false}
                          label={t("Mobile Number")}
                          defaultCountry="PK"
                          onChange={e => {
                            form.setFields([{ name: ["mobileNumber", field.name], errors: [""] }])
                          }}
                          onBlur={e => {
                            const input = e.target.value
                            checkPhone({ cell: input.split(" ").join("") })
                              .unwrap()
                              .then(e => {
                                form.validateFields([["mobileNumber", field.name]])
                              })
                          }}
                          component={
                            (!canEditAndRemoveCells && clientCells?.[index]?.length > 0) ||
                            fields?.length == 1 ? null : (
                              <Button
                                type="link"
                                icon="FiTrash2"
                                className="p-0"
                                onClick={() => remove(field.name)}
                                size="small"
                                style={{
                                  color: theme["error-color"],
                                  height: "auto",
                                }}
                              >
                                Remove
                              </Button>
                            )
                          }
                        />
                      </Form.Item>
                    )
                  })}
                  {fields.length < 5 && (
                    <Form.Item label={t("Add Another No.")}>
                      <Button
                        color={theme["gray600"]}
                        bordered
                        block
                        onClick={() => add()}
                        icon="MdAdd"
                        text="Add"
                        style={{ borderStyle: "dashed", height: 48 }}
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Group>
        ) : (
          <>
            <div className="mbe-24">
              <Button
                loading={isFetchingClientCells}
                type={"primary-light"}
                onClick={fetchClientsCells}
                disabled={!canViewClientContact}
              >
                {t("Show client numbers")}
              </Button>
            </div>
          </>
        )}
        <Legend title={t("Client Details")} />
        <Form.Item name="clientClass">
          <RadioButtons options={clientClassOptions()} />
        </Form.Item>
        <Group template="repeat(2, 1fr)" gap="16px 64px" className="mbe-24">
          {clientClassWatch === "affiliate" && (
            <AffiliateSection
              rules={rules}
              form={form}
              client={client}
              disabled={!getPrivilege("can_assign_affiliate_to_existing_client", client?.privileges)}
              subModuleName="editClient"
            />
          )}
          <Form.Item name="name" label={t("Name")} required rules={rules}>
            <TextInput placeholder={t("Enter name")} />
          </Form.Item>

          <Form.Item
            name="email"
            label={t("Email")}
            required
            hasFeedback
            rules={rules.concat({
              validator: async (_, value) => {
                if (emailData?.exists) {
                  return Promise.reject(t("Email already exists"))
                } else {
                  return Promise.resolve()
                }
              },
            })}
          >
            <TextInput
              placeholder={t("Enter email")}
              onChange={e => {
                debounceEmailValidator({
                  id: client?.id,
                  email: e.target.value,
                })
              }}
            />
          </Form.Item>

          <Form.Item
            name="cnic"
            label={t("National ID")}
            hasFeedback
            rules={rules.concat({
              validator: async (_, value) => {
                if (cnicData?.exists) {
                  return Promise.reject(t("National id already exists"))
                } else {
                  return Promise.resolve()
                }
              },
            })}
          >
            <TextInput
              placeholder={t("Enter national id")}
              onChange={e => {
                debounceCnicValidator({
                  id: client?.id,
                  nationalId: e.target.value,
                })
              }}
            />
          </Form.Item>

          <Form.Item name="gender" label={t("Gender")} required rules={rules}>
            <RadioButtons getKey={item => item.id} getValue={item => item.slug} options={genderList()} />
          </Form.Item>

          <Form.Item name="country" label={t("Country")} required rules={rules}>
            <SelectInput
              placeholder={t("Select country")}
              options={countries}
              loading={isFetchCountry}
              onChange={(_value, option) => {
                form.setFieldsValue({ country: option, city: null, locality: null })
                getCities(option.id)
              }}
            />
          </Form.Item>

          <Form.Item name="city" label={t("City")} required rules={rules}>
            <SelectInput
              placeholder={t("Select city")}
              disabled={!country}
              options={cities}
              loading={isCitiesFetching}
              onChange={(_value, option) => {
                form.setFieldsValue({ city: option, locality: null })
                getLocalities(option.id)
              }}
              onFocus={() => {
                if (!cities) {
                  getCities(country.id)
                }
              }}
            />
          </Form.Item>
          <Form.Item shouldUpdate={true} noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue("city") && localities?.length !== 0 && !isLocalityFetching) {
                return (
                  <Form.Item name="locality" label={t("Locality")} rules={rules}>
                    <SelectInput
                      placeholder={t("Select locality")}
                      disabled={!city}
                      options={localities}
                      loading={isLocalityFetching}
                      onChange={(value, option) => {
                        form.setFieldsValue({ locality: option })
                      }}
                    />
                  </Form.Item>
                )
              }
            }}
          </Form.Item>

          <Form.Item
            name="dateOfBirth"
            label={t("Date of Birth")}
            rules={rules.concat({
              validator: async (_, value) => {
                if (moment().diff(value, "years") <= 18) {
                  return Promise.reject(t("Age must be greater than or equal to 18 years old"))
                }
                return Promise.resolve()
              },
            })}
          >
            <DatePicker allowClear showTime={false} format={DATE_FORMAT} />
          </Form.Item>

          <Form.Item name="passportNumber" label={t("Passport Number")} rules={rules}>
            <TextInput placeholder={t("Enter passport number")} />
          </Form.Item>

          <Form.Item name="address" label={t("Address")} rules={rules}>
            <TextInput placeholder={t("Enter address")} />
          </Form.Item>

          <Form.Item name="fatherName" label={t("S/O D/O W/O")} rules={rules}>
            <TextInput placeholder={t("Enter name")} />
          </Form.Item>

          <Form.Item className="span-all" name="reason" label={t("Reason")} rules={rules} required>
            <TextInput placeholder={t("Enter reason")} style={{ resize: "none" }} maxLength={256} lineCount={3} />
          </Form.Item>

          <Form.Item name="clientType" label={t("Client Type")} required rules={rules}>
            <RadioButtons options={clientTypes} />
          </Form.Item>

          <Form.Item name="clientOccupation" label={t("Client Occupation")} rules={rules}>
            <SelectInput
              placeholder={t("Select occupation")}
              options={occupations}
              loading={isFetchingOccupations}
              getOptionValue={option => option.name}
              onChange={(_value, option) => {
                form.setFieldsValue({ clientOccupation: option })
              }}
            />
          </Form.Item>

          <Form.Item name="clientRating" label={t("Client Rating")} required rules={rules}>
            <RadioButtons options={clientRatings} />
          </Form.Item>

          <Form.Item name="clientBuyingIntent" label={t("Buying Intent")} required rules={rules}>
            <RadioButtons options={clientBuyingIntents} />
          </Form.Item>
        </Group>
      </Form>
      <ClientProfileForm
        formRef={clientProfileRef}
        client={client}
        onSuccess={() => clientProfileRef.current?.setVisible(false)}
      />
    </Modal>
  )
}
