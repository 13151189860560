import parentApi from "store/parentApi"
import store from "store"
import { useDispatch } from "react-redux"

export const useQueryUpdate = () => {
  const dispatch = useDispatch()
  const updateQueryData = (endpoint, cb, handleArgs) => {
    let parentState = store.getState().parentApi
    const args = getEndPointArgs(endpoint, parentState, handleArgs)
    dispatch(parentApi.util.updateQueryData(endpoint, args, cb))
  }
  return [updateQueryData]
}

export const getEndPointArgs = (endpoint, parentState, handleArgs, latestSubscription) => {
  const items = Object.keys(parentState.subscriptions).filter(e => e.startsWith(endpoint))
  let itemKey = items.find(e => !!Object.keys(parentState.subscriptions[e]).length)
  if (latestSubscription) itemKey = items[items?.length - 1]
  const args = itemKey?.split("(")?.[1]?.split(")")[0]
  return args ? (handleArgs ? handleArgs(JSON.parse(args)) : JSON.parse(args)) : undefined
}
