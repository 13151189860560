import React from "react"
import OtpInput from "react-otp-input"

const OtpInputField = props => {
  const { index, otp, setOtp, ...rest } = props
  const inputStyle = {
    width: 46,
    height: 46,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
  }
  return (
    <OtpInput
      value={otp}
      onChange={e => {
        setOtp(e)
      }}
      numInputs={index}
      isInputNum={true}
      containerStyle={{ gap: "12px" }}
      inputStyle={inputStyle}
      focusStyle={{ outlineColor: "#009F2B" }}
      // separator={<span>-</span>}
      {...rest}
    />
  )
}
export default OtpInputField
