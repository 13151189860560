const taskStatusTransformer = response => {
  return transformData(response)
}
const transformData = data => {
  return data?.map(item => {
    return {
      label: item.name,
      key: item.id.toString(),
      children: transformData(item.children || item?.Children),
      value: item.id.toString(),
    }
  })
}

export default taskStatusTransformer
