const clientQuestionTransformer =  (response) =>{
    return {
      ...response,
      gender: response[0],
      clientOccupation: response[1],
      clientIndustry: response[1].children[0],
      clientLocality: response[2],
      clientType: response[3],
      existingProperty: response[4],
      clientPropertyType: response[4].children[0],
      propertyLocality: response[4].children[1],
      clientBudget: response[5],
      clientInvestmentPlan: response[6],
      clientInterest: response[7],
      clientAreaSize: response[8],
      nationality: response[9],
      maritalStatus: response[10],
      children: response[11],
      intent: response[12],
      preferredFinancing: response[13],
      preferredCity: response[14],
      preferredRegion: response[15],
      preferredLocality: response[16],
    }

}

export default clientQuestionTransformer