import {
  createQueryParamsForFilterRequest,
  filterTagsTransform,
  getAppliedFilterListObject,
  modifyUrlParamsForExistingSearches,
} from "utils/filters"
import { useEffect, useMemo, useState } from "react"

import { useLazyGetFilterTagsQuery } from "features/shared/api"
import { useSearchParams } from "react-router-dom"

const useFilters = (filterList, ignoreFilterKeys = [], isFetchingData, dependencyArray = [], mapPreviousFilters) => {
  const [filtersObject, setFiltersObject] = useState({})
  const [filterTags, setFilterTags] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = mapPreviousFilters
    ? modifyUrlParamsForExistingSearches(Object.fromEntries([...searchParams]))
    : Object.fromEntries([...searchParams])

  const [getFiltersTags, { data, isFetching, isError }] = useLazyGetFilterTagsQuery()

  const requestParams = useMemo(() => {
    const rp = createQueryParamsForFilterRequest(urlParams, filterList)
    return rp
  }, [JSON.stringify(urlParams), filterList.length])

  const appliedFilterListParams = useMemo(() => {
    const afp = getAppliedFilterListObject(urlParams, filterList)
    return afp
  }, [JSON.stringify(urlParams), filterList.length])

  useEffect(() => {
    isFetchingData && Object.keys(requestParams)?.length && getFiltersTags(requestParams, true)
    // if (!Object.keys(requestParams)?.length) setFiltersObject({})
  }, [isFetchingData])

  useEffect(() => {
    if ((Object.keys(appliedFilterListParams)?.length || filterTags?.list?.length) && !isFetching && !isError) {
      setFilterTags(filterTagsTransform(data, { queryObject: urlParams, filterList }))
      setFiltersObject({ ...urlParams })
    }
  }, [isFetching, isError, isFetchingData])

  useEffect(() => {
    if (filterList) {
      setTagsAndFilters(urlParams, true)
    }
  }, dependencyArray)

  const setTagsAndFilters = (
    queryObj = urlParams,
    isApplied = false,
    appliedParams = Object.fromEntries([...searchParams])
  ) => {
    let newQueryObj = { ...queryObj }
    setFiltersObject(newQueryObj)
    if (isApplied) {
      Object.keys(newQueryObj).forEach(key => !newQueryObj[key] && delete newQueryObj[key])
      Object.keys(appliedParams).forEach(key => {
        if (ignoreFilterKeys.includes(key)) {
          newQueryObj[key] = appliedParams[key]
        }
      })
      setSearchParams(newQueryObj)
    }
  }

  return { filtersObject, setFiltersObject, setTagsAndFilters, filterTags, isLoading: isFetching }
}

export default useFilters
