const paymentMethodTransformer = response => {
  return response.map(e => ({
    ...e,
    key: e.key,
    label: e.name,
    value: e.name,
    id: e.id,
    cheque: cheque,
    onlinePayment: onlinePayment,
    depositSlip: depositSlip,
    PaymentModeAttachmentTypeMappings: e.PaymentModeAttachmentTypeMappings.map(item => {
      return {
        ...item,
        mandatory: mandatoryMapper(e.key, item?.AttachmentType?.key),
        visionRequired: [2, 3, 6].includes(item?.attachmentTypeId),
      }
    }),
  }))
}

const mandatoryMapper = (paymentType, paymentMode) => {
  switch (paymentType) {
    case "cheque":
      return paymentMode !== "receipt"
    case "cash":
      return paymentMode !== "receipt"
    case "online_payment":
      return true
    case "bank_loan":
      return true
    case "bank_order":
      return paymentMode !== "receipt"
  }
}

const cheque = {
  chequeBank: "Bank",
  referenceNumber: "chequeNumber",
  fromAccountNumber: "accountNumber",
  chequeDate: "date",
}

const onlinePayment = {
  bank: "Bank",
  transactionNumber: "serialNumber",
  fromAccountTitle: "fromAccountTitle",
  fromAccountNumber: "fromAccountNumber",
  beneficiaryAccountTitle: "beneficiaryAccountTitle",
  beneficiaryAccountNumber: "accountNumber",
  transactionDate: "date",
}

const depositSlip = {
  depositBank: "Bank",
  depositDate: "date",
  accountNumber: "accountNumber",
  depositSlipNumber: "serialNumber",
}

export default paymentMethodTransformer
