import { PlusOutlined } from "@ant-design/icons"
import { Input, Button, Space, Divider } from "antd"
import { useState, useRef, useEffect, createElement, cloneElement } from "react"
import { SelectInput, notification } from "components/common"

function SelectInputWithCustomAdd({ mutationFunction, loading, options, ...rest }) {
  const [name, setName] = useState("")

  const inputRef = useRef(null)
  const dropdownRef = useRef(null)

  const onNameChange = event => {
    setName(event.target.value)
  }

  useEffect(() => {
    scrollToBottom()
  }, [options])

  const handleAddOption = e => {
    e.preventDefault()
    mutationFunction({ name: name })
      .unwrap()
      .then(() => {})
      .catch(error => notification.error({ message: error.message }))
    setName("")
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }
  const handleEnterPressed = e => {
    if (e.key === "Enter") {
      e.stopPropagation()
      handleAddOption(e)
    }
  }

  const scrollToBottom = () => {
    dropdownRef?.current?.scrollTo(options.length)
  }
  return (
    <SelectInput
      dropdownRender={menu => {
        let menuObj = { ...menu }
        menuObj.ref = dropdownRef
        return (
          <>
            {menuObj}
            <>
              <Divider style={{ marginBlock: "12px" }} />
              <Space className="pi-8 mbe-12">
                <Input
                  style={{ minWidth: "250px" }}
                  placeholder="Please enter option"
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                  required
                  maxLength={35}
                  onKeyDown={handleEnterPressed}
                />
                <Button size="large" type="text" icon={<PlusOutlined />} onClick={handleAddOption} loading={loading}>
                  Add option
                </Button>
              </Space>
            </>
          </>
        )
      }}
      options={options}
      {...rest}
    />
  )
}

export default SelectInputWithCustomAdd
