// import { Popover } from "antd"
import Tooltip from "../tooltip/tooltip"

const HoverText = ({ data, message }) => {
  const content = (
    <div>
      {data.map((item, index) => {
        return <p key={index}>{item}</p>
      })}
    </div>
  )

  return (
    <Tooltip title={content} trigger="hover">
      {message}
    </Tooltip>
  )
}

export default HoverText
