import * as yup from "yup"
import { numValidation, stringValidationYup } from "helpers/validations"
import { t } from "i18next"
const getShareLeadSaleValidationSchema = () =>
  yup.object().shape({
    city: yup.object().required(t("City is required")).nullable(),
    user: yup.object().required(t("User is required")).nullable(),
    purpose: stringValidationYup(t("Purpose is required")).nullable(),
    commission: numValidation(t("Commission must contain numbers only."), 15, "wholeNumbers")
      .test("minValue", t("Commission must be greater than or equal to 0."), num => num >= 0)
      .test("maxValue", t("Commission should not exceed 100."), num => num <= 100),
  })

export default getShareLeadSaleValidationSchema
