import { useEffect, useState } from "react"

import { Button } from "components/common"
import { Row } from "antd"
import cx from "clsx"
import moment from "moment"
import styles from "./index.module.less"

const Timer = props => {
  const { startDateTime } = props
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const startTime = props.startDateTime ? moment(startDateTime) : moment()

  const getTime = () => {
    const time = moment() - startTime
    const h = Math.floor((time / (1000 * 60 * 60)) % 24),
      m = Math.floor((time / 1000 / 60) % 60),
      s = Math.floor((time / 1000) % 60)
    setHours(h < 10 ? "0" + h : h)
    setMinutes(m < 10 ? "0" + m : m)
    setSeconds(s < 10 ? "0" + s : s)
  }

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000)

    return () => clearInterval(interval)
  }, [startDateTime])

  return (
    <Row className={cx(styles.timeView, "gray-600")} align="middle">
      <Button text={hours} type="primary-light" size="small" />
      <span className={styles.colon}>:</span>
      <Button text={minutes} type="primary-light" size="small" />
      <span className={styles.colon}>:</span>
      <Button text={seconds} type="primary-light" size="small" />
    </Row>
  )
}
export default Timer
