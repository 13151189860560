import { Space, Typography } from "antd"

import { Icon } from "components/common"

const { Paragraph } = Typography

const TitleDescription = props => {
  const { title, description, descriptionIcon, color, gapSpace, onClick, titleClassName } = props
  return (
    <>
      <Paragraph className={"mbe-0 " + titleClassName} onClick={onClick}>
        {title}
      </Paragraph>
      {description && (
        <Paragraph className="mbe-0" style={{ color: color ? color : "#9d9d9d" }}>
          <Space align="start" size={gapSpace ? gapSpace : 24} wrap>
            {descriptionIcon && description && (
              <Icon icon={descriptionIcon} size="14px" style={{ translate: "0 1px" }} />
            )}
            {description}
          </Space>
        </Paragraph>
      )}
    </>
  )
}

export default TitleDescription
