import { Avatar, Dropdown } from "antd"
import { Button, Divider, Icon, Spinner, Text, Title, notification } from "components/common"
import { AGENCY_SETTINGS_PATH, EDIT_STAFF_PATH, LOGIN_PATH } from "constants/app-paths"
import { Link, useNavigate } from "react-router-dom"

import { useLogoutMutation } from "features/auth/api"
import ScanQrCode from "features/layout/scanQrCode/scanQrCode"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import getPrivilege from "utils/privileges/privileges"
import theme from "utils/themeVars"
import styles from "./userMenu.module.less"

const UserMenu = ({ onClickLink }) => {
  const { t, i18n } = useTranslation()
  const user = useSelector(state => state.app.user?.info)
  const userPrivileges = useSelector(state => state.app.privileges)
  const navigate = useNavigate()
  const qrCodeModalRef = useRef()

  const [logoutUser, { isLoading }] = useLogoutMutation()

  const handleLogout = () => {
    logoutUser({}).then(e => {
      !e.error && navigate(LOGIN_PATH)
      if (!!e.error) {
        notification.error({ message: "Something went wrong while logging out" })
        window.location.reload()
      }
    })
  }

  const items = [
    {
      label: (
        <Link to={AGENCY_SETTINGS_PATH} onClick={() => onClickLink()}>
          {t("Agency Settings")}
        </Link>
      ),
      key: "1",
      icon: <Icon icon="AiOutlineSetting" />,
      disabled: !getPrivilege("can_update_agency_settings", userPrivileges),
      onClick: () => {},
    },
    {
      label: t("Scan Qr Code"),
      key: "2",
      icon: <Icon icon="AiOutlineScan" />,
      onClick: () => {
        qrCodeModalRef?.current?.setVisible(true)
      },
    },
    {
      label: t("Logout"),
      key: "3",
      icon: isLoading ? <Spinner type="plain" className="p-1" /> : <Icon icon="FiLogOut" />,
      disabled: isLoading,
      onClick: handleLogout,
    },
  ]

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          menu={{ items }}
          dropdownRender={menu => (
            <div className={styles.userMenuOuter}>
              <div className={styles.userMenuHeader}>
                <div className={styles.userAvatar}>
                  <Avatar
                    size={54}
                    icon={<Icon icon={user?.profile_image ? user?.profile_image : "FiUser"} size={30} />}
                  />
                </div>
                <div className={styles.userInfo}>
                  <Title level={5}>{user?.name}</Title>
                  <Text>{user?.email}</Text>
                  <div className={styles.userDesignation}>
                    <Text>{user?.UserDesignation?.name}</Text>
                    <Text>{user?.City?.name}</Text>
                  </div>
                </div>
              </div>
              <Link to={EDIT_STAFF_PATH.replace(":staffId", user?.id)} onClick={() => onClickLink({ key: "staff" })}>
                <Button type="primary" className={styles.btn} shape="round" block>
                  {t("Manage Profile")}
                </Button>
              </Link>
              <Divider style={{ marginBlock: "1rem" }} />
              <div className="fs16">{menu}</div>
            </div>
          )}
          className="d-grid"
        >
          <div>
            <Button
              type="light"
              color={theme["primary-color"]}
              shape="circle"
              style={{ padding: 0, width: 36, height: 36 }}
            >
              {user?.profile_image ? <Avatar size={34} src={user?.profile_image} /> : <Icon size={20} icon="FiUser" />}
            </Button>
          </div>
        </Dropdown>
      </div>
      <ScanQrCode ref={qrCodeModalRef} />
    </>
  )
}

export default UserMenu
