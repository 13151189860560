import { Form, Spin, notification } from "antd"
import { useWatch } from "antd/lib/form/Form"
import clsx from "clsx"
import { Button, Modal, RadioButtons, Space, Spinner, Text, TextInput, Title } from "components/common"
import { t } from "i18next"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { FaMagic } from "react-icons/fa"
import { useGenerateSalesPitchMutation, useLazyGetAiSalesPitchQuery, useLazyGetLanguagePreferenceQuery } from "./api"
import ViewPreviousSummaryTable from "./components/viewPreviousSummaryTable"
import styles from "./index.module.less"
import SalesPitch from "./components/salesPitch"
import cx from "clsx"

const AiSalesPitchGenerator = forwardRef((props, ref) => {
  const modalRef = useRef()
  const [form] = Form.useForm()
  const [clientLeadData, setClientLeadData] = useState()
  const [stage, setStages] = useState(1)
  const language = useWatch("language", form)
  const leadId = useWatch("leadId", form)

  const [getLanguage, { data }] = useLazyGetLanguagePreferenceQuery()
  const [getPreviousSummary, { data: previousSummary, isFetching: fetchPreviousSummary }] =
    useLazyGetAiSalesPitchQuery()

  const [generateSalePitch, { data: newSalePitch, isLoading: generatingSale }] = useGenerateSalesPitchMutation()

  const onModalVisible = () => {
    getLanguage("zameen")
  }

  const onSubmit = () => {
    generateSalePitch({
      leadId: clientLeadData?.leadId || leadId,
      clientId: clientLeadData?.clientId,
      language: language,
    })
      .unwrap()
      .then(() => {
        setStages(2)
      })
      .catch(e => {
        notification.error({ message: e?.message })
      })
  }
  useImperativeHandle(
    ref,
    () => ({
      setModal(value) {
        modalRef?.current?.setVisible(true)
        setClientLeadData({ clientId: value?.clientId, leadId: value?.leadId })
      },
    }),
    []
  )

  return (
    <Modal
      title={
        stage === 1 ? (
          <Text style={{ "--typography-color": "#fff" }} className={styles.headerTitle}>
            {t("Sales Pitch Generation")}
          </Text>
        ) : stage === 0 ? (
          <div className="d-flex flexYcenter" style={{ gap: "10px" }}>
            <Button
              size="small"
              type="muted"
              shape="circle"
              icon="HiArrowLeft"
              onClick={() => {
                setStages(1)
              }}
              iconProps={{ size: "14px" }}
              style={{ backgroundColor: "#626262", color: "#fff" }}
            />
            <Text style={{ "--typography-color": "#fff" }}>{t("View Previous Summary")}</Text>
          </div>
        ) : (
          <Button
            style={{ backgroundColor: "#626262", color: "#fff" }}
            shape="circle"
            icon="HiArrowLeft"
            onClick={() => {
              setStages(1)
            }}
          />
        )
      }
      ref={modalRef}
      className={styles.chatDrawer}
      width={1100}
      onCancel={() => {
        modalRef?.current?.setVisible(false)
        form.resetFields()
        setClientLeadData(null)
        setStages(1)
      }}
      footer={
        stage === 1 ? (
          <Space
            className="w-100"
            style={{
              justifyContent: "space-between",
            }}
          >
            <Space size={700}>
              <Button
                type="link"
                size="small"
                className={cx("pointer text-underline ", styles.loadingBtn)}
                style={{ height: "auto", color: "#0091BA" }}
                onClick={() => {
                  getPreviousSummary(clientLeadData?.clientId)
                    .unwrap()
                    .then(e => {
                      if (!e?.data?.length) {
                        notification.error({
                          message: t("There is no previous summary, please generate a new sales pitch"),
                        })
                      } else {
                        setStages(0)
                      }
                    })
                    .catch(e => {
                      notification.error({ message: e?.message })
                    })
                }}
                disabled={generatingSale}
                loading={fetchPreviousSummary}
              >
                {t("View Previous Summary")}
              </Button>
            </Space>
            <Space size={700}>
              <Button
                style={{ backgroundColor: "#424242", color: "#fff" }}
                icon={<FaMagic />}
                className="pi-24"
                key="submit"
                onClick={() => {
                  form.submit()
                  // setStages(2)
                }}
                loading={generatingSale}
                disabled={fetchPreviousSummary}
                text={t("Generate")}
                // type="primary"
              />
            </Space>
          </Space>
        ) : null
      }
      destroyOnClose
      onModalVisible={onModalVisible}
    >
      {generatingSale ? (
        <Spin
          loading={generatingSale}
          backgroundColor="#424242"
          inDisabledLayout
          tip={t("Please wait, we are generating your sales pitch. It may take up to 20-25 seconds")}
          className={cx(styles.spinnerLoader, "w-100")}
        />
      ) : (
        <Form
          form={form}
          layout={"vertical"}
          wrapperCol={{
            span: 10,
          }}
          onFinish={onSubmit}
        >
          <div className={stage === 1 ? clsx(styles.container, styles.show) : clsx(styles.container, styles.hide)}>
            {stage === 1 && (
              <>
                {!clientLeadData?.leadId && (
                  <Form.Item
                    label={<div className={styles.headerTitle}>{t("Lead ID")}</div>}
                    name={"leadId"}
                    required
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject("Please provide the lead ID ")
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <TextInput
                      placeholder={t("Please add the lead ID")}
                      className={styles.messageField}
                      type={"number"}
                    />
                  </Form.Item>
                )}
                <Title children={<div className={styles.headerTitle}>{t("Language Selection ")}</div>} level={5} />
                <Form.Item name={"language"} initialValue={"english"}>
                  <RadioButtons darkMode={true} options={data} />
                </Form.Item>
              </>
            )}
          </div>
          <div className={stage !== 1 ? clsx(styles.container, styles.show) : clsx(styles.container, styles.hide)}>
            {stage === 0 ? (
              <ViewPreviousSummaryTable data={previousSummary} language={language} />
            ) : stage === 2 ? (
              <SalesPitch
                salesPitch={newSalePitch?.data?.sales_pitch}
                talkingPoints={newSalePitch?.data?.talking_points}
                language={form.getFieldValue("language")}
              />
            ) : (
              <></>
            )}
          </div>
        </Form>
      )}
    </Modal>
  )
})

export default AiSalesPitchGenerator
