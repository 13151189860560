import { localisedValue } from "utils"

export const unitTypesWithSubTypesTransformer = (res, args) => {
  if (args?.isFlatHierarchy) {
    return res?.map(e => {
      return {
        label: localisedValue({ object: e, key: "name" }),
        options: e.children?.map(item => ({ label: localisedValue({ object: item, key: "name" }), value: item.id })),
      }
    })
  } else {
    const transformedArray = res.map(item => {
      const { children } = item
      return {
        title: localisedValue({ object: item, key: "name" }),
        value: item?.id.toString(),
        key: item?.id.toString(),
        ...(children?.length && { children: unitTypesWithSubTypesTransformer(children) }),
      }
    })
    return transformedArray
  }
}
