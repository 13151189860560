import { DASHBOARD_PATH, NAME_SPACE, POLICY_PATH, REPORTS_PATH } from "../app-paths"

import { Badge } from "antd"
import { Text } from "components/common"
import { AGENCY } from "configs/agency"
import sidBarMenuItems from "constants/menuItems/sidebar-menu-items"
import { Link } from "react-router-dom"
import getPrivilege from "../../utils/privileges/privileges"

const getLink = (label, path, internal = false, badgeText) => {
  const externalPathname = `${window.location.origin}/${NAME_SPACE[0]}${path?.split("?")[0]}`
  return internal ? (
    <Link to={path}>
      {label}
      {badgeText && (
        <Badge
          style={{ marginRight: -28 }}
          count={<Text className="shine new">{badgeText}</Text>}
          offset={[-5, -18]}
          size="small"
        />
      )}
    </Link>
  ) : (
    <a href={externalPathname}>{label}</a>
  )
}

const getSubRoutes = (key, privileges) => {
  if (sidBarMenuItems[key]) {
    const obj = { linkTo: "", paths: [], showModule: false }
    sidBarMenuItems[key](privileges, false).forEach((e, i) => {
      if (i === 0) {
        obj.linkTo = e.path
        obj.showModule = true
      }
      obj.paths.push(e.path)
    })
    return obj
  } else return {}
}

export const menuItems = (privileges, all, t = () => "") => {
  const clientsAndLeads = getSubRoutes("clients-leads", privileges)
  const projectInventory = getSubRoutes("project-inventory", privileges)
  const staff = getSubRoutes("staff", privileges)
  const management = getSubRoutes("management", privileges)
  const reports = getSubRoutes("reports", privileges)
  const accounts = getSubRoutes("accounts", privileges)
  const billings = getSubRoutes("billings", privileges)
  const affiliates = getSubRoutes("affiliates", privileges)
  const incentives = getSubRoutes("incentives", privileges)
  const marketing = getSubRoutes("marketing", privileges)
  const tsr = getSubRoutes("TSR", privileges)
  return [
    {
      label: getLink(t("Dashboard"), DASHBOARD_PATH, true),
      key: "landing-dashboard",
      path: DASHBOARD_PATH,
      show: getPrivilege(["can_view_lean_dashboard_sales", "can_view_lean_dashboard_affiliates"], privileges),
    },
    {
      label: getLink(t("Clients Leads"), clientsAndLeads.linkTo, true),
      key: "clients-leads",
      path: clientsAndLeads.paths,
      show: clientsAndLeads.showModule,
    },
    {
      label: getLink(t("Telesales"), tsr.linkTo, true),
      key: "TSR",
      path: tsr.paths,
      show: getPrivilege("can_view_tsr_section", privileges),
    },
    {
      label: getLink(t("Projects & Inventory"), projectInventory.linkTo, true),
      key: "project-inventory",
      path: projectInventory.paths,
      show: projectInventory.showModule,
    },
    {
      label: getLink(t("Staff"), staff.linkTo, true),
      key: "staff",
      path: staff.paths,
      show: staff.showModule,
    },
    {
      label: getLink(t("Management"), management.linkTo, true),
      key: "management",
      path: management.paths,
      show: management.showModule,
    },
    {
      label: getLink(t("Reports"), reports.linkTo, true),
      key: "reports",
      path: reports.paths,
      show: reports.showModule,
    },
    {
      label: getLink(t("Affiliates"), affiliates.linkTo, true),
      key: "affiliates",
      path: affiliates.paths,
      show: affiliates.showModule,
    },
    {
      label: getLink(t("Accounts"), accounts.linkTo, true),
      key: "accounts",
      path: accounts.paths,
      show: accounts.showModule,
    },
    {
      label: getLink(t("Incentives"), incentives.linkTo, true),
      key: "incentives",
      path: incentives.paths,
      show: incentives.showModule,
    },
    {
      label: getLink(t("Billings"), billings.linkTo, true),
      key: "billings",
      path: billings.paths,
      show: billings.showModule,
    },
    {
      label: getLink(t("Policies"), POLICY_PATH, true),
      key: "policy",
      path: [POLICY_PATH],
      show: getPrivilege(["can_view_repository_policy_summary"], privileges),
    },
    {
      label: getLink(t("Marketing"), marketing.linkTo, true),
      key: "marketing",
      path: marketing.paths,
      show: marketing.showModule,
    },
  ].filter(e => (all ? true : e.show))
}
