import { capitalizeFirstLetter } from "utils"

export const callsTransformHelper = response => {
  const { phoneCalls: data, count: count } = response
  const callRows = data.map((e, index) => {
    return {
      key: index,
      id: e.id,
      phoneCallId: e.id,
      staff: {
        name: e?.User?.name,
        email: e?.User?.email,
        city: e?.User?.City?.name,
        country: e?.User?.Country?.name,
        team: e?.User?.TeamMember?.Team?.name,
        position: e?.User?.UserDesignation?.short,
        cell: e?.callerNumber,
        location: `${e?.User?.City?.name}-${e?.User?.Country?.name}`,
      },
      client: {
        name: e?.Client?.name,
        country: e?.Client.Country,
        id: e?.Client?.id,
      },
      dateTime: e?.callTime,
      status: e?.status,
      number: e?.receiverNumber,
      callBy: undefined,
      tag: {
        title: e?.PhoneCallTag?.abbreviation,
        color: e?.PhoneCallTag?.style?.bgColor,
        tooltipTitle: e?.PhoneCallTag?.title,
      },
      tagShort: e?.PhoneCallTag?.abbreviation,
      tagStyle: { bgColor: e?.PhoneCallTag?.style?.bgColor },
      recording: e?.url,
      callType: e?.callType,
      source: e?.source,
      totalScore: e?.assessment?.scoring?.total_score || null,
      assessmentStatus: e?.analysisStatus,
      scoreBreakdown: e?.assessment?.scoring,
      date: e?.date,
      time: e?.time,
    }
  })

  return { data: callRows, count: count }
}
