import {
  AUTH_TOKEN_COOKIE_KEY,
  LOGIN_STORAGE_KEY,
  RETURN_URL_STORAGE_KEY,
  USER_PRIVILEGES_STORAGE_KEY,
  USER_STORAGE_KEY,
  AGENCY_CONTEXT_STORAGE_KEY,
  LAST_FETCH_STORAGE_KEY,
  USER_CONTEXT_STORAGE_KEY,
  USER_PIC_STORAGE_KEY,
  USER_SIGN_OFF_STORAGE_KEY,
  GOOGLE_AUTH_COOKIE_KEY,
  AGENCY_INFO_STORAGE_KEY,
} from "constants"
import { getCookie, removeCookie } from "utils/cookie"
import { getStorageItem, removeStorageItem, setStorageItem } from "utils/localStorage"
import { LOGIN_PATH } from "constants/app-paths"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import { inDevelopMode } from "utils/env"
import { getAppLanguage } from "./useInitializeApp"

export const getAppCredentials = () => {
  const token = getCookie(AUTH_TOKEN_COOKIE_KEY) // HTTP Only
  const privileges = getStorageItem(USER_PRIVILEGES_STORAGE_KEY)
  const isLoggedIn = getStorageItem(LOGIN_STORAGE_KEY)
  const info = getStorageItem(USER_STORAGE_KEY)
  const language = getAppLanguage()

  return {
    auth: { token, isLoggedIn: isLoggedIn === "no" ? false : !!isLoggedIn },
    configs: {
      ...language,
      direction: language.rtl ? "rtl" : "ltr",
      locale: language.key,
      screenSize: { width: window?.innerWidth, height: window?.innerHeight },
    },
    user: { info },
    privileges: privileges ? createPrivilegeObject(privileges) : null,
  }
}

export const removeAppCredentials = (saveUrl, reload) => {
  saveUrl &&
    !inDevelopMode &&
    !window.location.pathname.includes(LOGIN_PATH) &&
    setStorageItem(RETURN_URL_STORAGE_KEY, window.location.href)
  removeCookie(AUTH_TOKEN_COOKIE_KEY)
  removeCookie(GOOGLE_AUTH_COOKIE_KEY)
  removeStorageItem(LOGIN_STORAGE_KEY)
  removeStorageItem(USER_STORAGE_KEY)
  removeStorageItem(USER_PRIVILEGES_STORAGE_KEY)
  removeStorageItem(USER_CONTEXT_STORAGE_KEY)
  removeStorageItem(USER_PIC_STORAGE_KEY)
  removeStorageItem(USER_SIGN_OFF_STORAGE_KEY)
  removeStorageItem(AGENCY_CONTEXT_STORAGE_KEY)
  removeStorageItem(AGENCY_INFO_STORAGE_KEY)
  removeStorageItem(LAST_FETCH_STORAGE_KEY)
  reload && window.location.reload()
}
