export const getStageColor = key => {
  switch (key) {
    case "inquiry":
      return "#8fa2c6"
    case "prospect":
      return "#7c99ab"
    case "mature":
      return "#75c9a2"
    case "pre_closure":
      return "#509f8f"
    case "sold":
      return "#1c6758"
    case "closed_lost":
      return "#a3a3a3"
    default:
      return "#a3a3a3"
  }
}
