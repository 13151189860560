import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"
import * as yup from "yup"
const getLeadValidationSchema = () =>
  yup.object().shape({
    project: yup.object().required(t("Project is required")).nullable(),
    classification: stringValidationYup(t("Classification is required")),
    propertyType: stringValidationYup(t("Property type is required")),
    subType: yup.object().required(t("Sub type is required")).nullable(),
    source: yup.object().required(t("Source is required")).nullable(),
  })

export default getLeadValidationSchema
