import { localisedValue } from "utils"

const getAgencyTransformHelper = response => {
  const { settings } = response
  const obj = {}
  settings?.forEach(e => {
    if (obj[e.key]) {
      obj[e.key].push(e)
    } else {
      obj[e.key] = [e]
    }
  })

  return {
    ...response,
    settings: obj,
    values: {
      companyHeadQuartersAddress: response?.companyAddress,
      companyEmail: response?.companyEmail,
      companyName: localisedValue({ object: response, key: "companyName" }),
      companyUrl: response?.companyWebsite,
      country: response?.countryId,
      timeZone: obj?.timezone?.[0]?.value,
      timeFormat: obj?.time_format?.[0]?.value,
      language: obj?.language?.[0]?.value,
      currencyPrimary: obj?.primary_currency?.[0]?.value,
      companyPhoneNo: obj?.primary_phone_number?.[0]?.value,
      secondaryPhoneNo: obj?.secondary_phone_number?.[0]?.value,
      companyLogo: obj?.agency_logo?.[0]?.value ? [{ thumbUrl: obj?.agency_logo?.[0]?.value }] : [],
      currencySecondary: obj?.secondary_currency?.map(e => e.value),
      secondaryPhoneNumber: obj?.secondary_phone_number
        ?.filter(e => !!e?.value)
        ?.map(e => e?.value)
        ?.splice(1),
    },
  }
}

export default getAgencyTransformHelper
