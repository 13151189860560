import { Form } from "antd"
import { Divider, Group, InputNumber, Modal, SelectInput, Spinner, Text, notification, Button, Space } from "components/common"
import MonthPicker from "components/common/monthPicker/monthPicker"
import { IconTextDate } from "components/custom"
import {
  useAddSalesPipelineMutation,
  useDeleteSalesPipelineMutation,
  useLazyGetInternalInquiryStagesQuery,
  useLazyGetProjectsQuery,
  useLazyGetUnitsByProjectQuery,
  useUpdateSalesPipelineMutation,
} from "features/shared/api"
import { classifications } from "features/shared/data"
import { schemaRules } from "features/shared/utils"
import moment from "moment"
import { forwardRef, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { localisedValue } from "utils"
import { dateTimeFunction } from "utils/date"
import addToSalesPipelineSchemaRules from "./addToSalesPipelineSchemRules"
import getPrivilege from "utils/privileges/privileges"
import { useSelector } from "react-redux"

const getPrimaryProjectId = e => e?.interestedIn === "primary" && e?.Project?.projectId?.toString()

const AddToSalesPipeline = forwardRef(({ lead, isFromUpdate, onAddPipeline = () => {} }, ref) => {
  const { t } = useTranslation()
  const [getProjects, { data: projects, isFetching: isFetchingProjects, isLoading: loadingProjects }] =
    useLazyGetProjectsQuery()
  const [getUnitNumber, { data: unitsProject, isFetching: fetchUnits, isLoading: loadUnits }] =
    useLazyGetUnitsByProjectQuery()
  const [form] = Form.useForm()
  const [getInternalStatus, { data: inquiryStages, isFetching: inquiryStagesFetching, isLoading: loadStages }] =
    useLazyGetInternalInquiryStagesQuery()
  const [addSalesPipeline, { isLoading, error, reset: resetAddError }] = useAddSalesPipelineMutation()
  const [updateSalesPipeline, { isLoading: isUpdating, reset: resetUpdateError }] = useUpdateSalesPipelineMutation()
  const [deleteSalesPipeline, { isLoading: isDeleting, reset: resetDeleteError }] = useDeleteSalesPipelineMutation()
  const rules = schemaRules(addToSalesPipelineSchemaRules())
  const [currentStage, setCurrentStage] = useState("pre_closure")
  const userPrivileges = useSelector(state => state.app.privileges)

  const onModalVisible = () => {
    getProjects(
      { skipPpaExpired: true, financials: true, paymentInfo: true, withSaleableListings: true, status: "on" },
      true
    )
    getInternalStatus(null, true)
    getInitialValues()
  }

  const getInitialValues = useCallback(() => {
    form.setFieldsValue({
      projectId: isFromUpdate ? lead?.projectId?.toString() : getPrimaryProjectId(lead),
      unit: lead?.unitId?.toString() || lead?.listingId?.toString() || null,
      probability: lead?.probability || lead?.percentage || lead?.Client?.aiClosureProbability || 0,
      stage: lead?.leadStageId || lead?.inquiryStageId || +inquiryStages?.[0]?.value,
      startDate: lead?.date ? dateTimeFunction({ date: lead?.date, timeZone: true }) : moment(),
    })
  }, [lead, isFromUpdate, form, inquiryStages])

  const getLeadStageKey = (inquiryStages, leadStageId) => {
    const leadStage = inquiryStages.find(item => item.value === leadStageId)
    return leadStage.key
  }

  useEffect(() => {
    const projectId = isFromUpdate ? lead?.projectId?.toString() : getPrimaryProjectId(lead)
    getUnitNumber({ projectIds: projectId }, true)
    getInitialValues(inquiryStages)
    isFromUpdate && inquiryStages && setCurrentStage(getLeadStageKey(inquiryStages, lead?.inquiryStageId))
  }, [lead, isFromUpdate, inquiryStages])

  const onSubmit = values => {
    const startDate = dateTimeFunction({ date: values?.startDate, timeZone: true, apiRequest: true })
    const payload = {
      projectId: values?.projectId,
      listingId: values?.unit,
      inquiryId: isFromUpdate ? lead?.inquiryId : lead?.id,
      conversionProbability: values?.probability,
      inquiryStageId: values?.stage,
      date: startDate,
    }
    isFromUpdate
      ? updateSalesPipeline({ pipelineId: lead?.id, ...payload })
          .unwrap()
          .then(() => {
            ref?.current?.setVisible(false)
            notification.success({ message: t("Updated To Sales Pipeline") })
            form.resetFields()
            onAddPipeline()
          })
          .catch(error => {
            notification.error({ message: error?.message })
          })
      : addSalesPipeline(payload)
          .unwrap()
          .then(() => {
            ref?.current?.setVisible(false)
            notification.success({ message: t("Added To Sales Pipeline") })
            form.resetFields()
            onAddPipeline()
          })
          .catch(error => {
            notification.error({ message: error?.message })
          })
  }

  const deleteFromPipeline = () => {
    deleteSalesPipeline({ pipelineId: lead?.id })
      .unwrap()
      .then(() => {
        ref?.current?.setVisible(false)
        notification.success({ message: t("Deleted From Sales Pipeline") })
      })
      .catch(error => {
        notification.error({ message: error?.message })
      })
  }

  return (
    <Modal
      width={800}
      title={isFromUpdate ? t("Update Lead In Pipeline") : t("Add Lead To Pipeline")}
      ref={ref}
      onCancel={() => {
        ref?.current?.setVisible(false)
        form.resetFields()
        resetAddError()
        resetUpdateError()
        resetDeleteError()
      }}
      destroyOnClose
      okText="Update"
      cancelText="Cancel"
      onOk={form.submit}
      onModalVisible={onModalVisible}
      loading={isLoading || isUpdating || fetchUnits || isDeleting}
      footer={
        isFromUpdate && getPrivilege("can_delete_From_Pipeline", userPrivileges) ? (
          <Space className="w-100" align="center" style={{ justifyContent: "space-between" }}>
            <Button
              size="large"
              key="delete"
              type="primary"
              danger={true}
              loading={isLoading}
              disabled={currentStage === "pre_closure" || currentStage === "sold"}
              onClick={() => {
                deleteFromPipeline()
              }}
              text={t("Remove")}
            />
            <div>
            <Button
              size="large"
              key="cancel"
              type="default"
              loading={isLoading}
              onClick={() => {
                ref?.current?.setVisible(false)
                form.resetFields()
                resetAddError()
                resetUpdateError()
                resetDeleteError()
              }}
              text={t("Cancel")}
            />
            <Button
              size="large"
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={() => {
                form.submit()
              }}
              text={t("Update")}
            />
            </div>
          </Space>
        ) : (
          false
        )
      }
    >
      {loadingProjects || loadStages || loadUnits ? (
        <Spinner inDisabledLayout />
      ) : (
        <Form form={form} onFinish={onSubmit} layout="vertical" preserve={false}>
          <Text className="bold fs16 mbe-8" block>
            {t("Interested Project / Unit")}
          </Text>
          <Group template="repeat(2, 1fr)" gap="8px">
            <Form.Item name={"projectId"} label={"Project"} required rules={rules}>
              <SelectInput
                placeholder={t("Select Project")}
                options={projects}
                inputLoading={isFetchingProjects}
                onChange={value => {
                  form.setFieldsValue({ unit: null })
                  getUnitNumber({ projectIds: value }, true)
                }}
              />
            </Form.Item>
            <Form.Item name={"unit"} label={"Unit"} required rules={rules}>
              <SelectInput
                placeholder={t("Select Unit")}
                options={unitsProject}
                inputLoading={fetchUnits}
                disabled={fetchUnits}
              />
            </Form.Item>
          </Group>

          <Text className="bold fs16 mbe-8" block>
            {t("Conversion")}
          </Text>
          <Divider className="m-0 mbe-16" />

          <Group template="repeat(2, 1fr)" gap="8px">
            <Group template="repeat(2, 1fr)" gap="4px">
              <Form.Item name={"probability"} label={"Probability ( 0% - 100%)"} required rules={rules}>
                <InputNumber width={100} style={{ width: "100%" }} min={1} max={100} />
              </Form.Item>
              <Form.Item name={"stage"} label={<></>} rules={rules}>
                <SelectInput
                  options={
                    inquiryStages
                      ? inquiryStages.map(item => ({
                          ...item,
                          disabled: item.key === "sold" || item.key === "pre_closure",
                        }))
                      : []
                  }
                  placeholder={t("Select Stage")}
                />
              </Form.Item>
            </Group>
            <Form.Item name="startDate" label={t("Closing Month")} rules={rules} required>
              <MonthPicker
                placeholder={t("Select start date")}
                format="MMM Y"
                allowClear={false}
                showTime={false}
                containerWidth="100%"
                value={form.getFieldValue("startDate")}
                onChange={value => form.setFieldsValue({ startDate: value })}
              />
            </Form.Item>
          </Group>
          <Divider className="m-0 mbe-16" />
          <IconTextDate
            iconProps={{
              color:
                classifications(t)[
                  lead?.classificationId || lead?.inquiryClassificationId || lead?.InternalInquiryClassification?.id
                ]?.style.color,
              hasBackground: true,
            }}
            icon="MdLocalFireDepartment"
            title={isFromUpdate ? lead?.leadId : lead?.id}
          />
          {" - "}
          <Text className="fs16">
            {isFromUpdate
              ? localisedValue({ object: lead, key: "clientName" })
              : localisedValue({ object: lead?.Client, key: "name" })}
          </Text>
          <div>
            <Text className="gray-800 fs14" block>
              {t("Allocated To")}
              {": "}{" "}
              {isFromUpdate
                ? localisedValue({ key: "userName", object: lead })
                : localisedValue({ key: "name", object: lead?.User })}
              {`(${localisedValue({
                key: isFromUpdate ? "userDesignationShort" : "short",
                object: isFromUpdate ? lead : lead?.User?.UserDesignation,
              })})`}
            </Text>
          </div>
          <Divider className="m-0 mbe-16" />
        </Form>
      )}
    </Modal>
  )
})

export default AddToSalesPipeline
