import { Button, Text, notify } from "components/common"
import Recording from "components/common/recording/recording"
import { useState, useRef, useEffect } from "react"
import { fileToBase64 } from "utils"
import styles from "./index.module.less"
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder"
import { Space } from "antd"
import cx from "clsx"
import theme from "utils/themeVars"
import { t } from "i18next"

const VoiceComment = ({ setVoice, getController }) => {
  const [audioPlayer, setAudioPlayer] = useState(null)
  const recorderControls = useAudioRecorder()
  getController(recorderControls)
  const onNotFound = () => {
    notify.error(t(`Allow access to your microphone in order to use voice comment`))
  }
  const onDeleteClick = () => {
    setAudioPlayer(null)
    setVoice()
  }
  useEffect(() => {
    if (recorderControls.recordingBlob) {
      const url = URL.createObjectURL(recorderControls.recordingBlob)
      const audio = document.createElement("audio")
      audio.src = url
      audio.controls = true
      setAudioPlayer(url)
      fileToBase64(recorderControls.recordingBlob).then(file => {
        setVoice(file)
      })
    }
  }, [recorderControls.recordingBlob])

  const stopRecordingAfterOneMinute = () => {
    recorderControls.stopRecording()
  }

  useEffect(() => {
    if (recorderControls.isRecording) {
      const timer = setTimeout(stopRecordingAfterOneMinute, 60000)
      return () => {
        clearTimeout(timer) // Clear the timer if recording stops before one minute
      }
    }
  }, [recorderControls.isRecording])

  return (
    <Space size={8}>
      {!audioPlayer && (
        <div className={cx(styles.audio)}>
          <AudioRecorder showVisualizer={true} onNotAllowedOrFound={onNotFound} recorderControls={recorderControls} />
        </div>
      )}

      {!!audioPlayer && <Recording id={"voice"} recordingSrc={audioPlayer} className={styles.audioPlay} />}
      {!!audioPlayer && (
        <Button
          icon="FiTrash2"
          type="link"
          content={t("Delete")}
          iconProps={{ color: theme["error-color"], size: "14px" }}
          onClick={onDeleteClick}
        />
      )}
    </Space>
  )
}
export default VoiceComment
