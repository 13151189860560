import { createPrivilegeObject } from "utils/privileges/privilegesMapper"

const transformPolicySummary = (response) => {
  return {
    ...response,
    rows: response?.rows?.map(e => {
      return {
        ...e,
        privileges: createPrivilegeObject(e?.privileges)
      }

    })
  }
}
export default transformPolicySummary