import { Avatar as AntdAvatar } from "antd"

const ColorList = ["#f56a00", "#7265e6", "#00a2ae", "#5470E3", "#A254E3"]

const alphabetsToColorCodeHash = {}

"abcdefghikjlmnopqrstuvwxyz".split("").forEach((alphabet, i) => {
  alphabetsToColorCodeHash[alphabet] = ColorList[i % ColorList.length]
})

const Avatar = props => {
  return (
    <AntdAvatar
      {...props}
      style={{
        backgroundColor: props.color ? props.color : props.colorKey ? alphabetsToColorCodeHash[props.colorKey] : "",
        verticalAlign: "middle",
      }}
    >
      {props.children}
    </AntdAvatar>
  )
}

export default Avatar
