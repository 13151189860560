import { Button, Dropdown } from "components/common"

import { useSelector } from "react-redux"
import { useLazyGetCallNumberQuery } from "features/shared/api"
import { useMemo } from "react"
import { t } from "i18next"
const ClientNumberButton = props => {
  const { client, disabled, className, ...buttonProps } = props
  const userInfo = useSelector(state => state.app.user?.info)
  const [getCallNumber, { data: ContacedNumber }] = useLazyGetCallNumberQuery()

  const items = useMemo(() => {
    return ContacedNumber?.map((e, index) => ({ label: e, key: index }))
  }, [ContacedNumber])
  return (
    <Dropdown
      overlayClassName="ddPhone"
      menu={{ items }}
      dropdownRender={menu => (items?.length ? <div className="dropdown-content">{menu}</div> : <></>)}
      placement="bottomLeft"
      suffixIcon=""
      disabled={disabled}
    >
      <Button
        className={className}
        icon={"IconCallOutgoing"}
        iconProps={{ size: "1.6em" }}
        onClick={e => {
          e.stopPropagation()
          client &&
            getCallNumber({ clientId: client?.clientId, phoneCallId: client?.phoneCallId, actionType: "edit_client" })
        }}
        content={t("View Number")}
        color="#0186fe"
        shape="round"
        type="light"
        disabled={!userInfo?.AgencyConfiguration?.Platform?.platformKey === "zameen" || disabled}
        {...buttonProps}
      />
    </Dropdown>
  )
}

export default ClientNumberButton
