import { t } from "i18next"
import { Card, ComponentState, Group, Icon, Modal, Skeleton, Text } from "components/common"
import { Col, List, Row } from "antd"
import { IconApartment, IconHome, IconShop } from "components/svg"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { localisedValue } from "utils"
import DiscountPlans from "./DiscountPlans/DiscountPlans"
import Image from "components/common/image/image"
import ProjectDetail from "./ProjectDetail/ProjectDetail"
import { convertToReadableString } from "utils"
import cx from "clsx"
import styles from "./index.module.less"
import { useLazyGetProjectDetailQuery } from "features/shared/api"

const ProjectModal = forwardRef((_props, ref) => {
  const [tabKey, setTabKey] = useState("project")
  const [projectId, setProjectId] = useState()
  const modalRef = useRef()
  const [
    getProjectDetails,
    {
      data: project,
      isFetching: isFetchingProjectDetail,
      isLoading: isLoadingProjectDetail,
      error: errorProjectDetail,
    },
  ] = useLazyGetProjectDetailQuery()

  useImperativeHandle(
    ref,
    () => ({
      showFor(id) {
        getProjectDetails({ id })
        setProjectId(id)
        modalRef.current.setVisible(true)
      },
    }),
    []
  )

  const tabItems = useMemo(
    () => [
      { tab: t("Project Detail"), key: "project", show: true },
      {
        tab: t("Discount Plans"),
        key: t("discountPlans"),
        show:
          !!localisedValue({ object: project?.DesignationDiscounts, key: "length" }) ||
          !!localisedValue({ object: project?.PaymentDiscounts, key: "length" }),
      },
      { tab: t("Payment Plans"), key: "paymentPlans", show: !!project?.Images?.paymentPlans?.[0]?.src },
      { tab: t("Floor Plans"), key: "floorPlans", show: !!project?.Images?.floorPlans?.[0]?.src },
    ],
    [project]
  )

  const renderImageForTabs = img =>
    img ? (
      <div style={{ minHeight: 360 }}>
        <Image src={img} height={300} rootClassName="p-16" />
      </div>
    ) : (
      <p>{t("No plan images available")}</p>
    )

  const getTabContent = () => ({
    project: <ProjectDetail project={project} />,
    discountPlans: (
      <DiscountPlans
        paymentDiscounts={localisedValue({ object: project, key: "PaymentDiscounts" })}
        designationDiscounts={localisedValue({ object: project, key: "DesignationDiscounts" })}
      />
    ),
    paymentPlans: renderImageForTabs(project?.Images?.paymentPlans?.[0]?.src),
    floorPlans: renderImageForTabs(project?.Images?.floorPlans?.[0]?.src),
  })

  const handleTabChange = key => {
    setTabKey(key)
  }

  return (
    <Modal
      width={640}
      ref={modalRef}
      onCancel={() => {
        modalRef?.current?.setVisible(false)
        setTabKey("project")
        setProjectId(null)
      }}
      loading={isLoadingProjectDetail}
      hideCancelButton={true}
      className={styles.modalBg}
      bodyStyle={{ padding: 24 }}
      footer={null}
      destroyOnClose
      onModalClos
    >
      {!!errorProjectDetail ? (
        <ComponentState
          onClick={() => projectId && getProjectDetails(projectId)}
          btnLoading={isFetchingProjectDetail}
          description={errorProjectDetail?.message}
          status={errorProjectDetail?.status}
        />
      ) : isFetchingProjectDetail ? (
        <ProjectSkeleton />
      ) : (
        <>
          <Group template="auto 1fr" gap="24px" className="mbe-32">
            {project?.Images?.projectPhotos?.[0]?.src ? (
              <Image src={project?.Images?.projectPhotos?.[0]?.src} width={72} height={72} rootClassName={styles.img} />
            ) : (
              <Icon
                hasBackground
                icon="MdImage"
                color="#35A447"
                size="2.4em"
                iconContainerStyle={{ padding: 19, backgroundColor: "#D3F9DD" }}
              />
            )}
            <Group template="1fr" gap="16px" className="flexYcenter">
              <Text color="#000" className="bold" block>
                {localisedValue({ object: project, key: "name" })}
              </Text>
              <Row className={styles.productInfoMain}>
                <Col className={styles.productInfo}>
                  <IconHome size={20} className={cx("d-grid", styles.icon)} />
                  <small className="gray-800">{t("Total Available Unit")}</small>
                  <strong>
                    {localisedValue({ object: project?.listingStats, key: "totalAvailableUnits" })} /{" "}
                    {localisedValue({ object: project?.listingStats, key: "totalUnits" })}
                  </strong>
                </Col>
                {Object.keys(localisedValue({ object: project?.listingStats, key: "countByListingType" }) || {}).map(
                  e => (
                    <Col className={styles.productInfo}>
                      <IconApartment size={20} className={cx("d-grid", styles.icon)} />
                      {/* <IconShop size={20} className={cx("d-grid", styles.icon)} />  */}
                      <small className="gray-800">
                        {
                          t(convertToReadableString(e))
                          ///
                        }
                      </small>
                      <strong>
                        {localisedValue({
                          object: project?.listingStats?.countByListingType[e],
                          key: "availableUnits",
                        })}{" "}
                        /{localisedValue({ object: project?.listingStats?.countByListingType[e], key: "totalUnits" })}
                      </strong>
                    </Col>
                  )
                )}
              </Row>
            </Group>
          </Group>
          <Card
            tabList={tabItems.filter(item => !!item.show)}
            activeTabKey={tabKey}
            onTabChange={handleTabChange}
            tabBarExtraContent={tabItems.find(item => item.key === tabKey).actionButton}
            className={styles.tabsMain}
            style={{ boxShadow: "none", backgroundColor: "transparent" }}
            bodyStyle={{ padding: "12px 0 0" }}
            bordered={false}
          >
            {getTabContent()[tabKey]}
          </Card>
        </>
      )}
    </Modal>
  )
})

const ProjectSkeleton = () => {
  return (
    <>
      <Group template="auto 1fr" gap="24px" className="mbe-32">
        <Skeleton type="image" />
        <Group template="45ch" gap="16px">
          <Skeleton type="text" />
          <Row className={styles.productInfoMain}>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
            <Col className={styles.productInfo}>
              <Skeleton type="text" />
            </Col>
          </Row>
        </Group>
      </Group>
      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {[1, 2, 3, 4, 5]?.map(e => (
          <List.Item>
            <Skeleton type="text" />
            <Skeleton type="text" />
          </List.Item>
        ))}
      </List>
    </>
  )
}

export default ProjectModal
