import { ACTION_TYPES, PAGE_SUBSECTIONS } from "utils/gtm/gtmEventsData"
import { Button, Card, DetailBar, Group, Icon, Number, Skeleton, Tag, Text, Title } from "components/common"
import { IconText, InfoBlock } from "components/custom"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAddClientContactViewTrackingMutation, useMarkFavoriteUnFavoriteMutation } from "features/clients/detail/api"

import WhatsappCallButton from "features/clients/components/whatsapp-call-button"
import { CLIENT_LISTING_PATH } from "constants/app-paths"
import CallButton from "../call-button"
import ClientForm from "../clientForm/clientForm"
import { Row, Space } from "antd"
import ThemeConfig from "configs/theme"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "utils/privileges/privileges"
import moment from "moment"
import { openMailTo } from "utils"
import styles from "./clientDetailInfo.module.less"
import { useRef } from "react"
import { useSelector } from "react-redux"
import { dateTimeFunction } from "utils/date"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"
import { t } from "i18next"
const { variables: theme } = ThemeConfig

const getActiveSales = e => {
  return [
    { label: t("Unit"), data: e.contract?.unitNumber },
    { label: t("Unit Price"), data: <Number isStringOnly={true} isCurrency={true} value={e.contract.unitPrice} /> },
    {
      label: t("Unit Discount"),
      data: (
        <>
          {" "}
          <Number isCurrency={true} isStringOnly={true} value={e.contract.dealDiscount} /> (
          <Number
            isStringOnly={true}
            value={(e.contract.dealDiscount / e.contract.unitPrice) * 100}
            digitAfterFraction={(e.contract.dealDiscount / e.contract.unitPrice) * 100 < 0.01 ? 3 : 2}
          />
          )%
        </>
      ),
    },
    { label: t("Deal Price"), data: <Number isStringOnly={true} isCurrency={true} value={e.contract.dealPrice} /> },
    {
      label: t("Paid Amount"),
      data: <Number isStringOnly={true} isCurrency={true} value={e.contract.paymentProgress.amountReceived} />,
    },
  ]
}

const getDetailData = e => {
  return [
    {
      label: t("Contract ID"),
      value: (
        <Row align="bottom" style={{ gap: "2px 4px" }}>
          <Text>
            {e?.contract?.contractId ? (
              <Link to={`/contracts/?contractId=${e?.contract?.contractId}`}>{e?.contract?.contractId}</Link>
            ) : (
              e?.contract?.contractId
            )}
          </Text>{" "}
          {e.contract.expiryDate && (
            <Text className="small" type="danger">
              ({t("Expiry")} {dateTimeFunction({ date: e.contract.expiryDate, format: "Do MMM" })})
            </Text>
          )}
        </Row>
      ),
    },
    {
      label: t("Due Amount"),
      value: (
        <Number
          isStringOnly={true}
          isCurrency={true}
          value={e.contract.paymentProgress.remainingCompleteDownPaymentAmount}
        />
      ),
    },
  ]
}

const stepsIcon = <Icon size="8px" icon="ImCheckmark" stroke={0.5} />

const getStepsData = e => {
  return [
    { title: t("Token"), icon: stepsIcon },
    { title: t("PDP"), icon: stepsIcon },
    {
      title: t("Booking Form"),
      icon: stepsIcon,
      status:
        e.inquiry?.Documents[0]?.Disposition.key == "signed" ? "finish" : e.current >= 2 ? "wait pending" : "wait",
    },
    { title: t("Closed. W"), icon: stepsIcon },
    { title: t("Verified"), icon: stepsIcon },
  ]
}

function ClientDetailInfo({ skeletonLoading, data }) {
  const { t } = useTranslation()
  const [markFavoriteUnFavorite, { data: favoriteData, isFetching: isFetchingFavorite, error }] =
    useMarkFavoriteUnFavoriteMutation()
  const [addClientContactView] = useAddClientContactViewTrackingMutation()
  const clientFormRef = useRef()
  const navigate = useNavigate()
  const { state } = useLocation()
  const userInfo = useSelector(state => state.app.user?.info)

  const onClickFavorite = async () => {
    const resp = await markFavoriteUnFavorite({
      clientIds: [data?.id],
      favorite: data?.ClientFavorite?.length === 0,
    })
      .unwrap()
      .catch(e => {
        fireEvent({ ...ACTION_TYPES.mark_client_as_favorite, response: e.status, client_id: data?.id })
      })

    if (!error) {
      data?.ClientFavorite?.length === 0 &&
        fireEvent({ ...ACTION_TYPES.mark_client_as_favorite, response: "200", client_id: data?.id })
    }
  }
  return (
    <Card className={styles.clientSidebar}>
      {skeletonLoading ? (
        <ClientDetailInfoSkeleton />
      ) : (
        <>
          <Group template="initial" gap="32px">
            <Group gap="16px" className={styles.clientInfoSticky}>
              <Row align="middle" style={{ gap: "4px 8px", marginBlock: -8 }}>
                <Button
                  type="muted"
                  shape="circle"
                  icon="HiArrowLeft"
                  onClick={() => (state?.hasPath ? navigate(-1) : navigate(CLIENT_LISTING_PATH))}
                />
                <Text type="secondary" style={{ fontSize: "85%" }}>
                  ID:&nbsp;{data?.id}
                </Text>
                <div style={{ flexGrow: 1 }}>
                  {data?.ClientStage?.name && (
                    <Tag size="small" color={data?.ClientStage?.style?.bgColor}>
                      {data?.ClientStage?.name}
                    </Tag>
                  )}
                  {data?.ClientType?.name && (
                    <Tag size="small" color="#919699">
                      {data?.ClientType?.name}
                    </Tag>
                  )}
                </div>
                {getPrivilege("can_edit_client", data?.privileges) && (
                  <Button
                    className="p-0"
                    type="link"
                    icon="TbEdit"
                    iconProps={{ size: "1.1em" }}
                    text={t("Edit")}
                    size="small"
                    style={{ gap: 2 }}
                    onClick={() => {
                      fireEvent({ ...ACTION_TYPES.client_edit_button, client_id: data?.id })
                      // addClientContactView({ clientId: data?.id, action: "edit_client" })
                      clientFormRef.current?.setVisible(true)
                    }}
                  />
                )}
              </Row>
              <Row align="middle" style={{ gap: 4 }}>
                <Title className="mbe-0 text-capitalize">{localisedValue({ object: data, key: "name" })}</Title>
                &nbsp;
                <Button
                  shape="circle"
                  type="text"
                  icon={!!data?.ClientFavorite?.length ? "AiFillStar" : "AiOutlineStar"}
                  iconProps={{ color: "#faad14" }}
                  disabled={isFetchingFavorite}
                  onClick={onClickFavorite}
                />
              </Row>
              <Row style={{ gap: 4 }}>
                <Button
                  icon="HiOutlineMail"
                  text={t("Email")}
                  type="light"
                  color={theme["primary-color"]}
                  shape="round"
                  disabled={!getPrivilege("can_send_email", data?.privileges)}
                  onClick={() => {
                    fireEvent({
                      ...ACTION_TYPES.email_contact_client,
                      user_input: data?.email,
                      client_id: data?.id,
                    })
                    openMailTo(data?.email)
                  }}
                />
                <WhatsappCallButton
                  client={data}
                  from={{ interacted_from: PAGE_SUBSECTIONS.client_detail_page }}
                  disabled={!getPrivilege("can_view_client_contact", data?.privileges)}
                  shape="round"
                  text={t("Whatsapp")}
                />
                <CallButton
                  text={t("Call")}
                  client={data}
                  from={{ interacted_from: PAGE_SUBSECTIONS.client_detail_page }}
                  className={styles.callBtnTEXT}
                  disabled={!getPrivilege("can_view_client_contact", data?.privileges)}
                />
              </Row>
              <Space size={4} direction="vertical" className="fs12">
                <IconText
                  block
                  textType="secondaryLight"
                  icon="BiCalendar"
                  iconProps={{ color: "#9d9d9d" }}
                  title={
                    <>
                      {t("Last Updated")}:
                      <span className="gray-900 text-capitalize">{data?.allocationActivity.lastActivity}</span>
                    </>
                  }
                />
                <IconText
                  block
                  textType="secondaryLight"
                  icon="BiTimeFive"
                  iconProps={{ color: "#9d9d9d" }}
                  title={
                    <>
                      {t("Created At")}:
                      <span className="gray-900 text-capitalize">{data?.allocationActivity.createdAt}</span>
                    </>
                  }
                />
              </Space>
            </Group>

            {!!data?.activeSales?.length && (
              <div>
                <Title level={3}>{t("Active Sales")}</Title>
                <Group gap="24px">
                  {data?.activeSales.map((e, index) => {
                    if (e.inquiry.interestedIn === "primary") {
                      return (
                        <DetailBar
                          key={e.inquiry.id}
                          collapsible
                          detailData={getDetailData(e)}
                          listData={getActiveSales(e)}
                          stepsProps={{ data: getStepsData(e), current: e.current, className: styles.steps }}
                          detailHeader={
                            e.contract.project?.name && (
                              <div>
                                <Text type="info" style={{ fontWeight: 600 }} block>
                                  {e?.contract?.project?.url && e?.contract?.project?.name ? (
                                    <a href={e?.contract?.project?.url} target="_blank" rel="noreferrer">
                                      {localisedValue({ object: e?.contract?.project, key: "name" })}
                                    </a>
                                  ) : (
                                    localisedValue({ object: e?.contract?.project, key: "name" })
                                  )}
                                </Text>
                                <Text type="secondaryLight">
                                  {localisedValue({ object: e.contract.Type, key: "name" })}
                                </Text>
                              </div>
                            )
                          }
                        />
                      )
                    }
                  })}
                </Group>
              </div>
            )}
            <InfoBlock
              key={data?.salesProfile?.title}
              data={data?.salesProfile?.data}
              title={localisedValue({ object: data?.salesProfile, key: "title" })}
              customComponentObj={{
                numberComponent: data => (
                  <>{data ? <Number isStringOnly={true} value={data} isCurrency={true} /> : <>{data}</>}</>
                ),
              }}
            />
            <InfoBlock
              key={data?.demographicProfile?.title}
              data={data?.demographicProfile?.data}
              title={data?.demographicProfile?.title}
            />
            <InfoBlock
              key={data?.affiliateInfo?.title}
              data={data?.affiliateInfo?.data}
              title={data?.affiliateInfo?.title}
            />
            <InfoBlock key={data?.moreInfo?.title} data={data?.moreInfo?.data} title={data?.moreInfo?.title} />
          </Group>
          <ClientForm formRef={clientFormRef} client={data} />
        </>
      )}
    </Card>
  )
}
const ClientDetailInfoSkeleton = () => (
  <Group template="initial" gap="32px">
    <Group gap="16px">
      <Row align="middle" style={{ gap: "4px 8px" }}>
        <Skeleton type="avatar" />
        <Skeleton height={20} />
        <Skeleton height={20} />
      </Row>
      <Row align="middle" style={{ gap: 4 }}>
        <Skeleton height={36} width={250} />
        {/* <Skeleton type="avatar" /> */}
      </Row>
      <Row style={{ gap: 4 }}>
        {[1, 2, 3].map(e => (
          <Skeleton style={{ borderRadius: 40, height: 32 }} />
        ))}
      </Row>
      <Row className="fs14" style={{ flexFlow: "column", gap: 4 }}>
        {[1, 2].map(e => (
          <Row style={{ gap: 6 }}>
            <Skeleton type="avatar" size={20} />
            <Skeleton height={20} width={150} />
          </Row>
        ))}
      </Row>
    </Group>
    <InfoBlock skeletonLoading />
    <InfoBlock skeletonLoading />
  </Group>
)
export default ClientDetailInfo
