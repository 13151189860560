export const getAddReviewInitialValues = () => {
  return {
    callRecording: "",
    additionalComment: "",
    additionalComment2: "",
    progressOnTask: "",
    proposedNextTask: null,
    feedback: "",
  }
}
