import { Image } from "antd"
import { Icon, Space, Text, Title, Tag, Tooltip } from "components/common"
import { TitleDescription } from "components/custom"
import { attachmentFallback } from "constants"
import { localisedValue } from "utils"
import { t } from "i18next"
import theme from "utils/themeVars"

const userProfileHeader = ({ basicInformation }) => {
  const tooltipTitle = dateTime => {
    return `${t("Stage Entered On: ")}`
  }

  return (
    <Space size={28} style={{ padding: "16px" }}>
      <Image
        src={basicInformation?.profilePicture || ""}
        fallback={attachmentFallback}
        height={100}
        width={100}
        style={{ borderRadius: "50%" }}
      />

      <TitleDescription
        gapSpace={4}
        title={
          <Title level={3} className="mbe-4">
            {localisedValue({ object: basicInformation, key: "name" })}{" "}
            <Tag
              color={theme["primary-light"]}
              style={{
                "--tag-color": theme["primary-color"],
                marginInlineStart: 6,
                paddinginline: 6,
                height: "auto",
              }}
              children={basicInformation?.selfSeller && t("Self-Seller")}
            />
          </Title>
        }
        description={
          <>
            <Tooltip title={localisedValue({ object: basicInformation, key: "designation" })}>
              <Text type="secondaryLight">{t("Designation")}: </Text>
              <Text type="secondary" style={{ fontWeight: 700 }}>
                {localisedValue({ object: basicInformation, key: "designationShort" })}
              </Text>
            </Tooltip>

            <Icon icon="GoPrimitiveDot" color="#D6D6D6" size="12px" className="pi-4" />

            <Text type="secondaryLight">{t("Region")}: </Text>
            <Text type="secondary" style={{ fontWeight: 700 }}>
              {localisedValue({ object: basicInformation, key: "regionName" })}
            </Text>

            <Icon icon="GoPrimitiveDot" color="#D6D6D6" size="12px" className="pi-4" />

            <Text type="secondaryLight">{t("City")}: </Text>
            <Text type="secondary" style={{ fontWeight: 700 }}>
              {localisedValue({ object: basicInformation, key: "cityName" })}
            </Text>

            <Icon icon="GoPrimitiveDot" color="#D6D6D6" size="12px" className="pi-4" />

            <Text type="secondaryLight">{t("Tenure")}: </Text>
            <Text type="secondary" style={{ fontWeight: 700 }}>
              {basicInformation?.tenure}
            </Text>

            <Icon icon="GoPrimitiveDot" color="#D6D6D6" size="12px" className="pi-4" />

            <Text type="secondaryLight">{t("Self-Seller")}: </Text>
            <Text type="secondary" style={{ fontWeight: 700 }}>
              {localisedValue({ object: basicInformation, key: "selfSeller" }) ? "Yes" : "No"}
            </Text>
          </>
        }
      />
    </Space>
  )
}

export default userProfileHeader
