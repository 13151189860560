import clientListingTransformHelper from "./clientListingTransformHelper"
import interestTypeTransformer from "./interestTypeTansformer"
import { convertQueryObjToString } from "utils"
import parentApi from "store/parentApi"
import getQueryTransformObject from "features/clients/detail/api/getQueryTransformObject"

const clientApi = parentApi.injectEndpoints({
  endpoints: build => ({
    // Get Clients
    getClients: build.query({
      query: ({ sortOrder, ...params }) => {
        const param = { ...params }
        return `/clients/search?${convertQueryObjToString(
          getQueryTransformObject(param, sortOrder, "client-listings")
        )}`
      },
      transformResponse: response => {
        const transformedData = clientListingTransformHelper(response)
        return transformedData
      },
      providesTags: ["clients"],
    }),

    // Get Saved Searches Clients
    getSavedSearches: build.query({
      query: ({ userId, ...params }) => `/userSearches/${userId}?${convertQueryObjToString(params)}`,
      transformResponse: response => {
        return response
      },
    }),

    archiveClients: build.mutation({
      query: payload => {
        const clientIds = payload?.clients?.map(item => item?.id)
        const archivePayload = {
          byFilters: payload?.isFilterRequired,
          ...(payload?.isFilterRequired && {
            clientFilters: getQueryTransformObject(payload?.queryObj, "desc", "client-listings"),
          }),
          ...(!payload?.isFilterRequired && { clientIds }),
        }
        return { url: `/clients/archive`, method: "PUT", body: archivePayload }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["clients"] : []
      },
    }),

    unarchiveClients: build.mutation({
      query: payload => {
        const clientIds = payload?.clients?.map(item => item?.id)

        const unarchivePayload = {
          changeAssigneeProcess: payload?.reallocatedType,
          byFilters: payload?.isFilterRequired,
          ...(payload?.isFilterRequired && {
            clientFilters: getQueryTransformObject(payload?.queryObj, "desc", "client-listings"),
          }),
          ...(!payload.isFilterRequired && { clientIds }),
          userIds: payload.userIds,
        }

        return { url: `/clients/unarchive`, method: "PUT", body: unarchivePayload }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["clients"] : []
      },
    }),

    // Post Saved Searches Clients
    saveSearches: build.mutation({
      query: ({ name, url, moduleObject }) => {
        const payload = { name, url: encodeURIComponent(url), ...moduleObject }
        return { url: `/userSearches`, method: "POST", body: payload }
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { moduleObject, userId } = params
        const { data } = await queryFulfilled
        dispatch(
          clientApi.util.updateQueryData("getSavedSearches", { ...moduleObject, userId }, draft => {
            draft.data.push(data.data)
            draft.count += 1
          })
        )
      },
    }),

    // Delete Saved Searches Clients
    deleteSearches: build.mutation({
      query: ({ id }) => ({ url: `userSearches/delete/${id}`, method: "PUT" }),
      async onQueryStarted({ id, moduleObject, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          clientApi.util.updateQueryData("getSavedSearches", { ...moduleObject, userId }, draft => {
            draft.data = draft.data.filter(searchObj => searchObj.id !== id)
            draft.count -= 1
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),

    // Merge Clients
    mergeClients: build.mutation({
      query: params => {
        const payload = {
          selectedClientId: params?.selectedClientId,
          clientsToBeModified: params?.clientsToBeModified,
        }
        return {
          url: `/clients/mergeClients`,
          method: "PUT",
          body: payload,
        }
      },
      invalidatesTags: (result, error, args) => {
        return !error ? ["clients"] : []
      },
    }),
    getInterestedTypes: build.query({
      query: () => "/types/typesWithChildren?clientProfiling=true",
      transformResponse: response => {
        return interestTypeTransformer(response)
      },
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetClientsQuery,
  useSaveSearchesMutation,
  useGetSavedSearchesQuery,
  useDeleteSearchesMutation,
  useMergeClientsMutation,
  useArchiveClientsMutation,
  useUnarchiveClientsMutation,
  useLazyGetInterestedTypesQuery,
} = clientApi
