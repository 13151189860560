import { useEffect, useRef, useState } from "react"

import { DateFormat, Icon } from "components/common"
import DataTable from "components/common/dataTable/dataTable"
import { TitleDescription } from "components/custom"
import { useGetEditLogsQuery } from "features/clients/detail/api"
import UserProfileSummary from "features/shared/components/userProfileSummary"
import { t } from "i18next"
import { Link } from "react-router-dom"
import EditHistoryDetail from "./editHistoryDetail"
import { joinBytItems } from "utils"

const columns = () => [
  {
    title: t("Date"),
    dataIndex: "date",
    key: "date",
    className: "tableStartSpace",
  },
  {
    title: t("By"),
    dataIndex: "editedBy",
    key: "editedBy",
    className: "tableStartSpace",
  },
  {
    title: t("Detail"),
    dataIndex: "detail",
    key: "detail",
  },
]

const EditHistory = ({ client, getCount = () => {} }) => {
  const [index, setIndex] = useState()
  const editHistoryDetailRef = useRef()
  const [filter, setFilter] = useState({ pageLimit: 10, page: 0 })
  const { data, isFetching, isError, error, isLoading, refetch } = useGetEditLogsQuery({ clientId: client.id, filter })
  useEffect(() => {
    data?.count && getCount(data?.count)
  }, [data?.count])

  const onChangePage = (page, pageSize) => {
    setFilter(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }

  const getEditHistoryData = data => {
    let object =
      data?.length &&
      data.map((element, index) => {
        return {
          key: element?.key,
          date: <DateFormat date={element?.createdAt} />,
          editedBy: !!element?.LoggedBy ? (
            <TitleDescription
              title={
                <div>
                  {element?.editedBy?.name}&nbsp;
                  <UserProfileSummary staffId={element?.editedBy?.id} />
                </div>
              }
              gapSpace={6}
              description={joinBytItems(
                [element?.editedBy?.designation, element?.editedBy?.city],
                <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              )}
            />
          ) : (
            "-"
          ),
          detail: (
            <Link onClick={() => handleClick(index)} className="text-link">
              {t("Detail")}
            </Link>
          ),
        }
      })

    const dataSource = object

    return dataSource
  }

  const handleClick = clickedIndex => {
    setIndex(clickedIndex)
    editHistoryDetailRef.current?.setVisible(true)
  }

  return (
    <>
      <DataTable
        data={getEditHistoryData(data?.data)}
        columns={columns()}
        pagination={{ totalCount: data?.count, pageOptions: [10, 25, 50] }}
        paginationOnBottom={true}
        paginationOnTop={false}
        loading={isFetching}
        onChangePage={onChangePage}
        error={error}
        onErrorRetry={refetch}
        noUrlPush={true}
        pageSize={filter.pageLimit}
        stickyOffset={0}
      />
      <EditHistoryDetail formRef={editHistoryDetailRef} client={client} editIndex={index} data={data} />
    </>
  )
}

export default EditHistory
