import {
  allocationsTransformHelper,
  callsTransformHelper,
  editHistoryTransformHelper,
  sharingTransformHelper,
  trasncriptionTransformHelper,
} from "./responseTransformHelpers"

import { getEndPointArgs } from "hooks/useQueryUpdate"
import parentApi from "store/parentApi"
import { convertQueryObjToString } from "utils"
import availablePaymentDiscountTransformHelper from "./availablePaymentDiscountTransformHelper"
import currentSharingsTransformHelper from "./currentSharingsTransformHelper"
import detailTransformHelper from "./detailTransformHelper"
import discountTransformHelper from "./discountTransformHelper"
import existingDiscountTransformHelper from "./discountTypeTransformer"
import getQueryTransformObject from "./getQueryTransformObject"
import { leadsTransformHelper } from "./leadsTransfromHelper"
import meetingTransformHelper from "./meetingTransformHelper"
import reviewObservationFieldTransformHelper from "./reviewObservationTransformHelper"
import taskLogsTransformHelper from "./taskLogsTransformHelper"
import { dateTimeFunction } from "utils/date"

const clientDetailApi = parentApi.injectEndpoints({
  endpoints: build => ({
    // Get Client Detail
    getClientDetail: build.query({
      query: clientId => `/clients/${clientId}?profile=true`,
      transformResponse: response => detailTransformHelper(response),
      providesTags: ["client-detail"],
    }),

    // Get Client Leads
    getLeads: build.query({
      query: p => {
        const params = { ...p }
        // TODO: Need to remove this need implementation from backend

        //
        return `internalInquiries/search?leadStatusFilterType=0&${convertQueryObjToString(
          getQueryTransformObject(params)
        )}`
      },
      transformResponse: (response, meta, args) => leadsTransformHelper(response, args),
      providesTags: (resp, error, args) => {
        return args.clientId ? [{ type: "leads", id: args.clientId }] : ["leads"]
      },
    }),

    // Get Client Tasks
    getTaskLogs: build.query({
      query: ({ clientId, filterObject }) => `/clients/${clientId}/tasks${"?" + convertQueryObjToString(filterObject)}`,
      transformResponse: response => taskLogsTransformHelper(response),
      providesTags: ["taskLogs"],
    }),

    // Get Client Calls
    getCallLogs: build.query({
      query: params => `/phoneCalls?${convertQueryObjToString(params)}`,
      transformResponse: response => callsTransformHelper(response),
    }),

    getCallDetail: build.query({
      query: callId => `/phoneCalls/${callId}/transcription`,
      transformResponse: res => trasncriptionTransformHelper(res),
    }),

    // Get Client Allocations
    getAllocationLogs: build.query({
      query: params => `/clients/getAllocationLogs/${params.clientId}?${convertQueryObjToString(params.filter)}`,
      transformResponse: response => allocationsTransformHelper(response),
      providesTags: ["allocationLogs"],
    }),

    // Get Client Sharing
    getSharingLogs: build.query({
      query: params => `/clients/getLeadSharingLog/${params.clientId}?${convertQueryObjToString(params.filter)}`,
      transformResponse: response => sharingTransformHelper(response),
    }),

    // Get Client Edit Log
    getEditLogs: build.query({
      query: params => `/clientLogs/client/${params.clientId}?${convertQueryObjToString(params.filter)}`,
      transformResponse: response => editHistoryTransformHelper(response),
      providesTags: ["editLogs"],
    }),

    getCurrentLeadSharing: build.query({
      query: leadId => `/internalInquiries/sharings?leadIds=${leadId}`,
      transformResponse: response => {
        const transformedData = currentSharingsTransformHelper(response)
        return transformedData
      },
      providesTags: ["sharings"],
    }),

    getClassification: build.query({
      query: () => `internalInquiryClassifications`,
    }),

    editClient: build.mutation({
      query: ({ client, mobileNumberViewed, ...values }) => {
        const newCells =
          mobileNumberViewed &&
          values?.mobileNumber?.map(e => {
            return { number: e, short: e.slice(4) }
          })
        const payload = {
          name: values.name,
          gender: values.gender,
          ...(mobileNumberViewed && { cells: newCells }),
          ...(!!values.fatherName && { relationship: values.fatherName }),
          phone: values.phone,
          ...(!!values.dateOfBirth && {
            dateOfBirth: dateTimeFunction({ date: values.dateOfBirth.toDate(), timeZone: true }),
          }),
          address: values.address,
          countryId: values.country.id,
          cityId: values?.city.id,
          ...(!!values.locality && { localityId: values.locality?.id }),
          ...(!!values.passportNumber && { passport: values.passportNumber }),
          nationalId: values.cnic,
          email: values.email,
          ...(!!values.clientOccupation && { occupation: values.clientOccupation?.name || values.clientOccupation }),
          clientUserType: values.clientType,
          clientRatingId: values.clientRating,
          buyingIntentId: values.clientBuyingIntent,
          comments: values.reason,
          ...(values?.clientClass === "affiliate" && { referralId: values?.searchAffiliate?.affiliateId }),
          referralType: values?.clientClass,
        }

        return { url: `/clients/${client.id}`, method: "PUT", body: payload }
      },
      invalidatesTags: (result, error) => {
        return result ? ["client-detail", "editLogs"] : []
      },
    }),

    addLead: build.mutation({
      query: leadValues => {
        const { client, classification, project, propertyType, source, subType } = leadValues
        const getClientPayload = client => {
          return client?.id
            ? { clientId: client.id }
            : {
                client: {
                  allocatedTo:
                    client?.clientClass == "affiliate" ? client?.searchAffiliate?.user?.id : client.allocatedTo?.id,
                  name: client.name,
                  gender: client.gender,
                  cells: client.mobileNumber.map(number => ({ number, short: number.slice(4) })),
                  ...(!!client.fatherName && { relationship: client.fatherName }),
                  countryId: client.country?.id,
                  cityId: client.city?.id,
                  localityId: client.locality?.id,
                  email: client.email,
                  ...(!!client.clientOccupation && {
                    occupation: client.clientOccupation?.name || client.clientOccupation,
                  }),
                  clientUserType: client.clientType,
                  clientRatingId: client.clientRating,
                  buyingIntentId: client.clientBuyingIntent,
                  referralType: client?.clientClass,
                  ...(client?.clientClass == "affiliate" && { referralId: client?.searchAffiliate?.affiliateId }),
                },
              }
        }
        const payload = {
          ...getClientPayload(client),
          classificationId: classification,
          projectId: project.value,
          objectType: "project",
          propertyType: propertyType.value,
          //          "leadSide": "demand",
          parentTypeId: propertyType.value,
          //          "clientSelected": "",
          //          "purposeId": 1,
          sourcePlatform: "web_v2",
          inquirySourceParentIds: source.value,
          source: "web_v2",
          childTypeId: subType.value,
          interestedIn: "primary",
          ...(client?.id && { userId: client?.User.userId }),
          firmState: "on",
        }
        return {
          url: `/internalInquiries`,
          method: "POST",
          body: payload,
        }
      },
      invalidatesTags: (resp, error) => (resp ? ["leads", "client-detail", "clients"] : []),
    }),

    addShareWithSales: build.mutation({
      query: ({ sharingInfo, userIds, leads }) => {
        const payload = {
          sharingInfo,
          userIds,
          inquiryIds: leads.map(item => item.id),
          unShareOldUsers: false,
        }
        return {
          url: `/internalInquiries/shareWithSales`,
          method: "PUT",
          body: payload,
        }
      },
      invalidatesTags: (_resp, error) => (!error ? ["sharings", "leads"] : []),
    }),

    addUnShareWithSales: build.mutation({
      query: params => {
        const payload = {
          inquiryIds: params.leads.map(item => item.id),
          ...(params?.userId && { fromUserId: params?.userId }),
        }
        return {
          url: `/internalInquiries/unshare`,
          method: "PUT",
          body: payload,
        }
      },
      invalidatesTags: (_resp, error) => (!error ? ["sharings", "leads"] : []),
    }),

    shareLeadWithSupportManual: build.mutation({
      query: values => {
        const payload = {
          inquiryIds: values.leads.map(item => item.id),
          designationId: values?.user?.designationId,
          selectedSupportUser: values?.user?.id,
          unShareOldUsers: values.unShareOldUsers,
        }
        return {
          url: `/internalInquiries/shareWithSupportManual/user/${values?.user?.id}`,
          method: "PUT",
          body: payload,
        }
      },
      invalidatesTags: (_resp, error) => (!error ? ["leads"] : []),
    }),

    shareLeadWithSupportAuto: build.mutation({
      query: values => {
        const payload = {
          inquiryIds: values.leads.map(item => item.id),
          designationId: values?.user?.designationId,
          unShareOldUsers: values.unShareOldUsers,
        }
        return { url: `/internalInquiries/shareWithSupportAuto`, method: "PUT", body: payload }
      },
      invalidatesTags: (_resp, error) => (!error ? ["leads"] : []),
    }),

    unshareLeadFromSupport: build.mutation({
      query: values => {
        const payload = {
          inquiryIds: values.leads.map(item => item.id),
          ...(!!values?.userId && { fromUserId: values?.userId }),
        }
        return {
          url: `/internalInquiries/unshare-support`,
          method: "PUT",
          body: payload,
        }
      },
      invalidatesTags: (_resp, error) => (!error ? ["leads"] : []),
    }),

    markFavoriteUnFavorite: build.mutation({
      query: ({ clientIds, favorite }) => {
        const payload = { clientIds }
        return {
          url: favorite ? `/favoriteClients/favorite` : `/favoriteClients/unfavorite`,
          method: "PUT",
          body: payload,
        }
      },
      async onQueryStarted({ clientIds, favorite }, { getState, dispatch, queryFulfilled }) {
        const state = getState()
        const { parentApi, app } = state
        const listingQueryArgs = getEndPointArgs("getClients", parentApi)
        const detailQueryArgs = getEndPointArgs("getClientDetail", parentApi)
        const leadDetailQueryArgs = getEndPointArgs("getLeads", parentApi)
        const listingPatchResult =
          listingQueryArgs &&
          dispatch(
            clientDetailApi.util.updateQueryData("getClients", listingQueryArgs, draftClients => {
              draftClients.data.forEach(e => {
                if (clientIds.includes(e.id)) {
                  if (favorite) {
                    e.isFavoriteOf.push(app.user.info.id)
                  } else {
                    e.isFavoriteOf = e.isFavoriteOf.filter(userId => userId !== app.user.info.id)
                  }
                }
              })
            })
          )

        const detailPatchResult =
          detailQueryArgs &&
          dispatch(
            clientDetailApi.util.updateQueryData("getClientDetail", detailQueryArgs, clientDraft => {
              if (favorite) {
                clientDraft.ClientFavorite.push(app.user.info.id)
              } else {
                clientDraft.ClientFavorite = clientDraft.ClientFavorite.filter(userId => userId !== app.user.info.id)
              }
            })
          )

        const leadPatchResult =
          leadDetailQueryArgs &&
          dispatch(
            clientDetailApi.util.updateQueryData("getLeads", leadDetailQueryArgs, draftLeads => {
              draftLeads.data.forEach(e => {
                if (clientIds.includes(e.Client.id)) {
                  if (favorite) {
                    e.Client.isFavoriteOf.push(app.user.info.id)
                  } else {
                    e.Client.isFavoriteOf = e.Client.isFavoriteOf.filter(userId => userId !== app.user.info.id)
                  }
                }
              })
            })
          )

        try {
          await queryFulfilled
        } catch {
          listingPatchResult?.undo()
          detailPatchResult?.undo()
          leadPatchResult?.undo()
        }
      },
    }),

    addFeedback: build.mutation({
      query: feedBackValues => {
        const payload = {
          inquiryId: feedBackValues.lead?.id || feedBackValues?.values?.leadId,
          meeting: feedBackValues.values.selectMeeting,
          feedbackType: "cob",
          feedbackFields: feedBackValues.observationFields?.map(e => {
            return { typeId: e.id, rating: feedBackValues.values[e.value] }
          }),
          feedback: feedBackValues.values.feedback,
          attachments: feedBackValues?.files?.map(e => {
            return {
              name: e?.name,
              file: e?.url,
            }
          }),
        }
        return { url: `/feedbacks/inquiry`, method: "POST", body: payload }
      },
    }),

    callViaPropGo: build.mutation({
      query: values => {
        const payload = {
          cellNumber: values?.cellNumber,
          clientId: values?.clientId,
          affiliateId: values?.affiliateId,
          affiliate: values?.affiliate,
        }

        return {
          url: `appNotifications/callViaPropgo`,
          method: "POST",
          body: payload,
        }
      },
    }),

    addApplyForDiscount: build.mutation({
      query: payload => {
        let changeDiscount

        if (!payload.byApproval) {
          if (
            payload.data.discountForm.value === "amount" &&
            payload.data.paymentCommitment.discountType === "percentage"
          ) {
            changeDiscount = ((payload.data.discount / payload.price) * 100).toFixed(6)
          } else if (
            payload.data.discountForm.value === "percentage" &&
            payload.data.paymentCommitment.discountType === "amount"
          ) {
            changeDiscount = (payload.data.discount / 100) * payload.price
          } else {
            changeDiscount = payload.data.discount
          }
        } else {
          if (
            payload.data.discountForm.value === "amount" &&
            payload.discountApprovalData.discountType === "percentage"
          ) {
            changeDiscount = ((payload.data.discount / payload.price) * 100).toFixed(6)
          } else if (
            payload.data.discountForm === "percentage" &&
            payload.discountApprovalData.discountType === "amount"
          ) {
            changeDiscount = (payload.data.discount / 100) * payload.price
          } else {
            changeDiscount = payload.data.discount
          }
        }

        const data = !payload.byApproval
          ? {
              inquiryId: payload.leadData.id,
              discountType: "by_payment",
              paymentDiscountId: payload.data.paymentCommitment.discountId,
              discount: changeDiscount,
              requestedBy: payload.leadData.userId,
              ...(payload.reapply && { reapplyDiscount: true }),
            }
          : {
              inquiryId: payload.leadData.id,
              discountType: "by_approval",
              designationDiscountId: payload.discountApprovalData.discountId,
              comment: payload.data.reason,
              requestedFrom: payload.discountApprovalData.Approver.id,
              discount: changeDiscount,
              requestedBy: payload.discountApprovalData.Approver.userId,
              ...(payload.reapply && { reapplyDiscount: true }),
            }

        return {
          url: `/dealDiscounts`,
          method: "POST",
          body: data,
        }
      },
    }),

    getApplyDiscountListingInfoWithProject: build.query({
      query: id => `/dealDiscounts/${id}/listingInfoWithProject`,
      transformResponse: response => {
        const transformedData = discountTransformHelper(response)
        return transformedData
      },
      providesTags: ["project-info"],
    }),

    getExistingDiscount: build.query({
      query: id => `/dealDiscounts/${id}`,
      transformResponse: response => {
        const transformedData = existingDiscountTransformHelper(response)
        return transformedData
      },
    }),

    getAvailablePaymentDiscount: build.query({
      query: params => `/projects/${params.projectId}/paymentDiscounts?inquiryId=${params.inquiryId}`,
      transformResponse: response => availablePaymentDiscountTransformHelper(response),
    }),

    getDiscountApprovers: build.query({
      query: params =>
        `/users/discountApprovers?userId=${params.userId}&projectId=${params.projectId}&inquiryId=${params.inquiryId}`,
    }),

    changeAssignee: build.mutation({
      query: ({ clientIds, leadIds, type, filterRequired, queryObj, ...values }) => {
        let defaultFilterOptions = { pageLimit: 10, page: 0, sortBy: "id", orderBy: "desc" }
        const submitData = {
          body: {
            ...(values?.allocatedTo && {
              userIds: Array.isArray(values?.allocatedTo)
                ? values?.allocatedTo?.map(item => item.id)
                : [values?.allocatedTo]?.map(item => item?.id),
            }),
            byFilters: filterRequired ? filterRequired : false,
            ...(!filterRequired && { clientIds }),
            changeAssigneeProcess: values?.requestReAllocation
              ? "request_re_allocation"
              : values?.reallocatedType
              ? values?.reallocatedType
              : null,
            comments: `Client allocation has been changed through ${type.replace("-", " ")}.`,
            ...(values?.manualAllocationReason && { manualAllocationReason: values.manualAllocationReason }),
            ...(values?.reasonId && { clientReAllocationReasonId: values.reasonId }),
            ...(values?.userPhone && { cellNumber: values.userPhone }),
            ...(type == "search-lead" &&
              filterRequired && {
                leadFilters: getQueryTransformObject({ ...defaultFilterOptions, ...queryObj }, "desc", type),
              }),
            ...(type == "client-listings" &&
              filterRequired && {
                clientFilters: getQueryTransformObject({ ...defaultFilterOptions, ...queryObj }, "desc", type),
              }),
          },
          ...(!!leadIds?.length && { selfShare: "false" }),
        }
        return { url: "/clients/changeAssignee/v2", method: "PUT", body: submitData.body }
      },
      invalidatesTags: (resp, error, args) =>
        resp
          ? [
              ...args.clientIds.map(id => ({ type: "leads", id })),
              "client-detail",
              "clients",
              "leads",
              "todos",
              "allocationLogs",
              "editLogs",
            ]
          : [],
    }),

    getReviewObservationFields: build.query({
      query: () => `/feedbacks/cob/fields`,
      transformResponse: response => {
        const transformedResponse = reviewObservationFieldTransformHelper(response)
        return transformedResponse
      },
    }),

    getMeetings: build.query({
      query: taskId => `/internalInquiries/${taskId}/meetings`,
      transformResponse: response => {
        const transformedResponse = meetingTransformHelper(response)
        return transformedResponse
      },
    }),

    getReasonForAllocation: build.query({
      query: () => `/clientReAllocationReasons`,
    }),

    addClientContactViewTracking: build.mutation({
      query: payload => ({ url: `/clientContactViewTrackings`, method: "POST", body: payload }),
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetAllocationLogsQuery,
  useGetEditLogsQuery,
  useGetSharingLogsQuery,
  useGetCallLogsQuery,
  useGetCallDetailQuery,
  useGetTaskLogsQuery,
  useGetClientDetailQuery,
  useLazyGetClientDetailQuery,
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
  useGetClassificationQuery,
  useGetClientDetailsQuery,
  useEditClientMutation,
  useAddLeadMutation,
  useAddShareWithSalesMutation,
  useAddUnShareWithSalesMutation,
  useShareLeadWithSupportAutoMutation,
  useShareLeadWithSupportManualMutation,
  useUnshareLeadFromSupportMutation,
  useAddFeedbackMutation,
  useAddApplyForDiscountMutation,
  useLazyGetApplyDiscountListingInfoWithProjectQuery,
  useCallViaPropGoMutation,
  useMarkFavoriteUnFavoriteMutation,
  useChangeAssigneeMutation,
  useGetReviewObservationFieldsQuery,
  useLazyGetReviewObservationFieldsQuery,
  useLazyGetMeetingsQuery,
  useLazyGetCurrentLeadSharingQuery,
  useGetExistingDiscountQuery,
  useLazyGetDiscountApproversQuery,
  useLazyGetAvailablePaymentDiscountQuery,
  useLazyGetExistingDiscountQuery,
  useGetReasonForAllocationQuery,
  useAddClientContactViewTrackingMutation,
} = clientDetailApi
