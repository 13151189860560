import { t } from "i18next"

const transformPolicyType = response => {
  let array = []
  let allObject = {
    id: 0,
    key: "all",
    name: t("All"),
  }
  array = [allObject].concat(response)

  return array
}
export default transformPolicyType
