import { localisedValue } from "utils"
const interestTypeTransformer = response => {
  return response.map(e => ({
    ...e,
    label: localisedValue({ object: e, key: "name" }),
    value: e.id.toString(),
  }))
}

export default interestTypeTransformer
