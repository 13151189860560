import { Alert as AntdAlert } from "antd"
import styles from "./index.module.less"
import cx from "clsx"

const Alert = props => {
  const { className } = props
  return <AntdAlert className={cx(className, styles.alert)} {...props}></AntdAlert>
}

export default Alert
