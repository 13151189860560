import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"
import * as yup from "yup"
const getClientObservationValidationSchema = () =>
  yup.object().shape({
    leadId: yup.string().required(t("Lead Id is required")),
    selectMeeting: yup.object().nullable(),
    feedback: stringValidationYup(t("Feedback is required")),
  })

export default getClientObservationValidationSchema
