import { Row } from "antd"
import Tooltip from "../tooltip/tooltip"

export const FLagWithText = ({ style, title, countryCode, infoText }) => {
  return (
    <Row style={{ gap: "8px", ...style }}>
      <Tooltip title={title}>
        <img
          src={countryCode && `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
          width="20"
          alt=""
        />
      </Tooltip>
      {infoText}
    </Row>
  )
}
