import { DatePicker, Space } from "antd"
import moment from "moment"
import { useSelector } from "react-redux"
import locale from "antd/es/date-picker/locale/ar_EG"

const MonthPicker = ({ value, onChange, disabledDate, containerWidth = "16ch", format, ...rest }) => {
  const { locale: appLanguage } = useSelector(state => state.app.configs)
  const getCurrentMonth = () => {
    const date = new Date()
    date.setDate(1)
    return date
  }
  return (
    <div style={{ width: containerWidth }}>
      <DatePicker
        value={value ? moment(value) : moment(getCurrentMonth())}
        onChange={onChange}
        format={format || "MMMM y"}
        picker="month"
        disabledDate={disabledDate}
        allowClear={false}
        locale={appLanguage === "en" ? [] : locale}
        {...rest}
      />
    </div>
  )
}

export default MonthPicker
