import { localisedValue } from "utils"
const cityTransformer = response => {
  return response.map(e => ({
    ...e,
    key: e.id,
    label: localisedValue({ object: e, key: "name" }),
    value: e.id.toString(),
  }))
}

export default cityTransformer
