import * as yup from "yup"
import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"

const getChangeAllocationValidationSchema = () =>
  yup.object().shape({
    allocatedTo: yup.mixed().required(t("Allocation user is required")).nullable(),
    manualAllocationReason: stringValidationYup(t("Allocation reason is required")),
  })

export default getChangeAllocationValidationSchema
