import { Image, Modal, Skeleton, Text } from "components/common"
import { useLazyGetQrCodeLoginQuery } from "features/shared/api"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const ScanQrCode = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.app.user?.info)

  const [getQrCode, { data, isLoading, isFetching, error, isError }] = useLazyGetQrCodeLoginQuery()

  const modalRef = useRef()
  const [open, setOpen] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      setVisible(value) {
        setOpen(value)
        modalRef?.current?.setVisible(value)
      },
    }),
    []
  )

  const onModalVisible = () => {
    getQrCode({ userId: user?.id })
  }

  return (
    <Modal
      title={t("QR Code Login")}
      ref={modalRef}
      open={open}
      footerAlert={error}
      onModalVisible={onModalVisible}
      hideCancelButton={true}
      onCancel={() => {
        modalRef?.current?.setVisible(false)
        ref?.current?.setVisible(false)
      }}
      destroyOnClose
      onModalClos
    >
      <Text style={{ fontSize: 17 }}>{t("Please scan the QR code with PropGo or Propforce App to login")}</Text>
      <div style={{ textAlign: "center", padding: "40px" }}>
        {isFetching ? (
          <Skeleton type="image" />
        ) : (
          <Image src={data?.authenticationQRCode} preview={false} alt="qr-code-login" />
        )}
      </div>
    </Modal>
  )
})

export default ScanQrCode
