import { DATE_TIME_FORMAT } from "constants"
import transformPolicySummary from "./transformPolicySummary"
import transformPolicyType from "./transformPolicyType"
import { dateTimeFunction } from "utils/date"
import parentApi from "store/parentApi"
import { convertQueryObjToString } from "utils"

const policiesApi = parentApi.injectEndpoints({
  endpoints: build => ({
    getRepositoryPolicySummary: build.query({
      query: param => {
        let params
        if (param?.createdAt) {
          let parseDate = (param?.createdAt).split(",")
          const { createdAt, ...newParams } = param

          params = {
            ...newParams,
            applicableDateStart: encodeURIComponent(
              dateTimeFunction({ date: parseDate[0], timeZone: true, apiRequest: true })
            ),
            applicableDateEnd: encodeURIComponent(
              dateTimeFunction({ date: parseDate[1], timeZone: true, apiRequest: true })
            ),
          }
        } else {
          params = param
        }
        return `/repository/policies?${convertQueryObjToString(params)}`
      },
      transformResponse: response => transformPolicySummary(response),
      providesTags: ["policySummary"],
    }),
    getPolicyType: build.query({
      query: () => `/repository/policyTypes`,
      transformResponse: response => transformPolicyType(response),
    }),
    getProjectList: build.query({
      query: () => `/repository/policyTypes`,
    }),
    getPolicyLogs: build.query({
      query: id => `/repository/policies/log/${id}`,
    }),

    getPolicy: build.query({
      query: id => ({ url: `/repository/policies/${id}`, method: "GET" }),
    }),

    deletePolicy: build.mutation({
      query: id => ({ url: `/repository/policies/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, args) => (!error ? ["policySummary"] : []),
    }),
    getDesignation: build.query({
      query: params => {
        return `/userDesignations/teamTypes?${convertQueryObjToString(params)}`
      },
      transformResponse: response => response?.map(item => ({ ...item, value: item.id.toString() })),
    }),
    addPolicy: build.mutation({
      query: values => {
        const payload = {
          applicableDate: dateTimeFunction({ date: values.dateSlot, timeZone: true, apiRequest: true }),
          attachment: { isTrusted: true },
          cityIds: values?.cities,
          countryIds: values?.countries,
          designationIds: values?.designation.join(),
          file: values.attachment.file.split(",")[1],
          fileName: values?.attachment?.name,
          from: values.from,
          message: values?.description,
          policyTypeId: values?.policyType.join(),
          projectsIds: values.projects,
          staff: values.staff ? { teamIds: [], userIds: values.staff } : null,
          teamTypeIds: values.department.join(),

          title: values?.title,
        }
        return { url: `repository/policies`, method: "POST", body: payload }
      },
    }),
    viewLog: build.mutation({
      query: values => {
        const payload = {
          policyId: values.policyId,
          userId: values.userId,
        }
        return { url: `repository/policies/log`, method: "PUT", body: payload }
      },
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetRepositoryPolicySummaryQuery,
  useDeletePolicyMutation,
  useLazyGetPolicyLogsQuery,
  useLazyGetProjectListQuery,
  useGetPolicyTypeQuery,
  useLazyGetPolicyTypeQuery,
  useLazyGetDesignationQuery,
  useAddPolicyMutation,
  useViewLogMutation,
  useLazyGetPolicyQuery,
} = policiesApi
