import { localisedValue } from "utils"

const buyingIntentTransformer = response => {
  return response.map(e => ({
    ...e,
    key: e.key,
    label: localisedValue({ object: e, key: "displayName" }),
    value: e.id.toString(),
    id: e.id,
  }))
}

export default buyingIntentTransformer
