import { Alert, Col, Row } from "antd"
import { Button, CountDown, Group, Icon, OtpInputField, SelectInput, Text, notification } from "components/common"

import cx from "clsx"
import styles from "./index.module.less"
import { useAddClientContactViewTrackingMutation } from "features/clients/detail/api"
import { useState } from "react"
import { useLazyGetClientCellsQuery } from "features/shared/api"

const VerifyMeeting = ({
  clientId,
  generateOtp,
  onSubmitButton,
  isOtpGenerating,
  showVerifyOTP,
  onClickChangeButton,
  isOtpVerify,
  generateOtpError,
  ...props
}) => {
  const [selectedNumber, setSelectedNumber] = useState()
  const [otpNumber, setOtpNumber] = useState()
  const [resetTimer, setResetTimer] = useState()
  const [isOtpGenerate, setIsOtpGenerate] = useState(true)
  const [mobileNumberToggler, setMobileNumberToggler] = useState(false)
  const [otpResult, setOtpResult] = useState(true)
  const [addClientContactView] = useAddClientContactViewTrackingMutation()
  const [getClientCellInfo, { data: clientCells, isFetching: isFetchingClientCells }] = useLazyGetClientCellsQuery()
  const fetchClientsCells = () => {
    getClientCellInfo({ clientId: clientId, actionType: "edit_client" })
      .unwrap()
      .then(res => {
        setMobileNumberToggler(true)
      })
      .catch(err => {
        notification.error({ message: err.message })
      })
  }
  return (
    <div className={cx(styles.verifyContainer, props.mainClass, "p-24")}>
      <Group hidden={!(showVerifyOTP === "")}>
        <div className="mbe-12 span-all">
          <Text type="secondary">Change Phone Number on which you want to send a verify Meeting OTP</Text>
        </div>
        {!mobileNumberToggler ? (
          <div className="mbe-24">
            <Button loading={isFetchingClientCells} type={"primary-light"} onClick={fetchClientsCells}>
              Show client numbers
            </Button>
          </div>
        ) : (
          <>
            <SelectInput
              placeholder="Select"
              options={clientCells || []}
              getOptionLabel={option => option}
              getOptionValue={option => option}
              onChange={(value, option) => {
                setSelectedNumber(option.value)
                setIsOtpGenerate(true)
              }}
              // onFocus={() => addClientContactView({ clientId: clientId, action: "add_meeting_done_task" })}
            />
            <div>
              <Button
                type="primary"
                text="Send Code"
                loading={isOtpGenerating}
                disabled={!!selectedNumber ? false : true}
                onClick={async () => {
                  setIsOtpGenerate(true)
                  const response = await generateOtp(selectedNumber)
                  if (response.data) {
                    setResetTimer(Date.now() + 60 * 1000)
                  } else if (response.error) setIsOtpGenerate(false)
                }}
                style={{ height: 46 }}
                bordered
              />
            </div>
          </>
        )}
        <Text type="danger" hidden={isOtpGenerate}>
          {generateOtpError?.message}
        </Text>
      </Group>
      {/* <div>
      </div> */}
      <div hidden={!(showVerifyOTP === "pending")}>
        <Row className="mbe-12">
          <Text type="secondary">Enter the OTP sent to {selectedNumber}</Text>
          <Button
            className="pb-0"
            type="link"
            icon="TbEdit"
            text="Change"
            onClick={() => {
              setOtpResult(true)
              onClickChangeButton()
            }}
            style={{ marginInlineEnd: "auto", height: "auto" }}
          />
        </Row>
        <Row style={{ gap: 12, alignItems: "center" }}>
          <Col>
            <OtpInputField otp={otpNumber} setOtp={setOtpNumber} index={4} />
          </Col>
          <Button
            disabled={!!otpNumber ? false : true}
            loading={isOtpVerify}
            onClick={async () => {
              const response = await onSubmitButton(otpNumber)
              if (response === false) {
                setOtpResult(false)
              }
            }}
            text="Submit"
            type="primary"
            style={{ height: 46 }}
            bordered
          />
          <CountDown
            prefix={<Icon icon="GiSandsOfTime" size={20} style={{ marginTop: 4 }} />}
            // className="fs16"
            value={resetTimer}
            onFinish={() => {
              setResetTimer()
            }}
            format="mm:ss"
            // valueStyle={{ verticalAlign: "middle" }}
          />
          <Button
            type="link"
            disabled={!!resetTimer ? true : false}
            onClick={() => {
              generateOtp(selectedNumber)
              setResetTimer(Date.now() + 60 * 1000)
            }}
            text="Resend"
            className="p-8"
          />
        </Row>
        <Text type="danger" hidden={otpResult}>
          {"OTP verification is false"}
        </Text>
      </div>
    </div>
  )
}
export default VerifyMeeting
