import { Pagination, Row, Table } from "antd"
import { useEffect, useState } from "react"

import { ComponentState } from ".."
import { Group } from "components/common"
import PropTypes from "prop-types"
import cx from "clsx"
import styles from "./dataTable.module.less"
import { useSearchParams } from "react-router-dom"
import { useTableData } from "hooks/useTableData"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const DataTable = props => {
  const {
    columns: col,
    data: rows = [],
    pagination,
    showPagination,
    onChangePage,
    noUrlPush,
    paginationOnTop = false,
    paginationOnBottom = false,
    loading,
    skeletonLoading,
    renderTopRight,
    onErrorRetry = () => {},
    error,
    rowSelection,
    expandable,
    Components,
    shouldTransformData,
    tableStyle,
    expandedRowKeys,
    tableLayout,
    headerSticky = true,
    stickyOffset,
    onRow = () => {},
    loadingComponentStyle,
    noTableBg = false,
    size,
    descriptionText,
    loadingText = <></>,
    ...rest
  } = props

  const { t } = useTranslation()
  const { locale: appLanguage } = useSelector(state => state.app.configs)

  const [data, columns] = useTableData(rows, col, Components, shouldTransformData)
  const defaultPageSize = props?.defaultPageSize ? props.defaultPageSize : pagination?.pageOptions?.[0] || 20
  const [page, setPage] = useState(1)
  const [pageSizeSave, setPageSize] = useState(defaultPageSize)
  const [searchParams, setSearchParams] = useSearchParams()
  const queryObj = Object.fromEntries([...searchParams])

  useEffect(() => {
    setPage(queryObj.page ? parseInt(queryObj.page) + 1 : 1)
    setPageSize(queryObj.pageLimit ? queryObj.pageLimit : defaultPageSize)
  }, [queryObj.page, queryObj.pageLimit])

  useEffect(() => {
    if (noUrlPush && pagination?.page && pagination?.page !== page) setPage(pagination?.page)
  }, [pagination?.page])

  const onHandleChange = (page, pageSize) => {
    if (noUrlPush) {
      setPage(page)
      setPageSize(pageSize)
      onChangePage(page - 1, pageSize)
    } else {
      setSearchParams({ ...queryObj, page: parseInt(page) - 1, pageLimit: pageSize })
    }
  }

  const renderPagination = () => {
    return !!pagination?.totalCount ? (
      <Row justify="end" style={{ padding: "10px 16px 16px" }}>
        <Pagination
          onChange={onHandleChange}
          pageSizeOptions={pagination?.pageOptions}
          pageSize={pageSizeSave}
          defaultCurrent={page}
          current={page}
          total={pagination?.totalCount}
          showSizeChanger={!!pagination?.pageOptions}
          locale={appLanguage === "ar" ? { items_per_page: "/ صفحة" } : []}
        />
      </Row>
    ) : null
  }

  const tablePagination = () => {
    if (!pagination && showPagination && data.length && data.length > defaultPageSize) {
      return true
    }
    return false
  }

  return (
    <Group gap="8px" template="minmax(0, auto)" className={props.mainClass}>
      {(renderTopRight || paginationOnTop) && (
        <Row justify={renderTopRight ? "space-between" : "end"} style={{ columnGap: 24 }} align="bottom">
          {renderTopRight && renderTopRight()}
          {paginationOnTop && !error ? renderPagination() : null}
        </Row>
      )}
      <div>
        {!!error ? (
          <ComponentState
            onClick={onErrorRetry}
            btnLoading={loading}
            description={error?.message}
            status={error.status}
          />
        ) : (
          <Table
            className={cx(styles.tableHeader, props.tableClass, noTableBg && styles.noBg)}
            pagination={tablePagination()}
            dataSource={data || []}
            columns={
              (columns &&
                columns.map(item => ({
                  ...item,
                  render: component => (
                    <div style={{ minWidth: (item.title?.length || 0) + 2 + "ch" }}>{component}</div>
                  ),
                }))) ||
              []
            }
            loading={skeletonLoading || loading}
            locale={{
              emptyText: skeletonLoading ? (
                loadingText
              ) : (
                <ComponentState
                  style={loadingComponentStyle}
                  type="empty"
                  title=""
                  image={loading ? <></> : undefined}
                  description={loading ? loadingText : descriptionText ? descriptionText : t("No records found")}
                />
              ),
            }}
            rowSelection={rowSelection}
            expandable={expandable}
            style={{ ...tableStyle }}
            sticky={headerSticky ? { offsetHeader: stickyOffset } : false}
            expandedRowKeys={expandedRowKeys}
            tableLayout={tableLayout}
            onRow={onRow}
            rowClassName={props.rowClassName && props.rowClassName}
            size={size}
            {...rest}
          />
        )}
      </div>

      {paginationOnBottom && !error ? renderPagination() : null}
    </Group>
  )
}

DataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChangePage: PropTypes.func,
  noUrlPush: PropTypes.bool,
  paginationOnTop: PropTypes.bool,
  paginationOnBottom: PropTypes.bool,
  tabs: PropTypes.bool,
  loading: PropTypes.bool,
  skeletonLoading: PropTypes.bool,
}

DataTable.defaultProps = {
  paginationOnTop: false,
  paginationOnBottom: true,
  tabs: true,
  mainClass: "",
  stickyOffset: 64,
}

export default DataTable
