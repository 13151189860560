import { InfoList } from "components/custom"
import { joinBytItems } from "utils"
import { useLazyGetStaffDetailsQuery } from "../../api"
import { useMemo } from "react"
import { localisedValue } from "utils"

import { t } from "i18next"

export default function ViewStaffDetails({ staff }) {
  const [fetchData, { data, isFetching }] = useLazyGetStaffDetailsQuery()

  const getUserInfoList = useMemo(() => {
    const location = joinBytItems(
      [localisedValue({ object: data?.City, key: "name" }), localisedValue({ object: data?.Country, key: "name" })],
      ", "
    )

    return [
      [t("Name"), localisedValue({ object: data, key: "name" }) || staff?.name],
      [t("Designation"), localisedValue({ object: data?.UserDesignation, key: "name" })],
      [t("Email"), data?.email],
      [t("Cell No"), data?.cell],
      [t("Team"), localisedValue({ object: data?.TeamMember?.Team, key: "name" })],
      [t("Location"), location],
      [t("Region"), localisedValue({ object: data?.Region, key: "name" })],
    ]
  }, [data, staff])

  return (
    <InfoList
      loading={isFetching}
      list={getUserInfoList || []}
      onClick={e => {
        staff?.id && fetchData(staff.id)
        e?.stopPropagation()
      }}
    />
  )
}
