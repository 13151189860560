import { DATE_FORMAT } from "constants"
import { joinBytItems } from "../../../../../src/utils"
import moment from "moment"
import { dateTimeFunction } from "utils/date"
const meetingTransformHelper = response => {
  let desc
  return response.map(e => {
    desc = [dateTimeFunction({ date: e.createdAt, format: DATE_FORMAT }), e.name, e.AssignedTo?.name]
    return { key: e.key, label: joinBytItems(desc, " - "), value: e.name, id: e.id }
  })
}

export default meetingTransformHelper
