import { DetailBar, Group, Modal, RadioButtons, SelectInput, Title } from "components/common"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react"
import { useLazyGetProjectsQuery, useLazyGetSourceQuery, useLazyGetTypesQuery } from "features/shared/api"

import { Form } from "antd"
import { classifications } from "features/shared/data"
import getLeadInitialValues from "./leadInitialValues"
import getLeadValidationSchema from "./leadValidationSchema"
import { schemaRules } from "features/shared/utils"

import { useTranslation } from "react-i18next"
const AddLeadForm = forwardRef(({ client, onSubmit, preserve = false }, ref) => {
  const { t } = useTranslation()
  const [getProjects, { data: projectsData, isLoading: projectsLoading }] = useLazyGetProjectsQuery()
  const [getTypes, { data: typesData, isLoading: typesLoading }] = useLazyGetTypesQuery()
  const [getSource, { data: sourceData, isLoading: sourceLoading }] = useLazyGetSourceQuery()
  const [form] = Form.useForm()
  const propertyTypeId = Form.useWatch("propertyType", form)
  const rules = schemaRules(getLeadValidationSchema())

  useImperativeHandle(
    ref,
    () => ({
      ...form,
    }),
    []
  )

  useEffect(() => {
    getProjects()
    getTypes()
    getSource()
  }, [])

  const clientDetail = useMemo(
    () =>
      client?.id && [
        { label: t("Client Name"), value: client?.name },
        { label: t("Client Id"), value: client?.id },
        { label: t("Allocated To"), value: client?.User?.name },
      ],
    [client]
  )

  // const subTypes = useMemo(() => typesData?.find(type => type.id == propertyTypeId)?.children, [propertyTypeId])
  return (
    <Group>
      {clientDetail && (
        <div>
          <Title type="secondary" level={4}>
            {t("Client Details")}
          </Title>
          <DetailBar detailData={clientDetail} />
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        name="add-lead-form"
        initialValues={getLeadInitialValues()}
        onFinish={onSubmit}
        preserve={preserve}
      >
        <Group template="repeat(2, 1fr)" gap="6px 32px">
          <Form.Item name="project" label={t("Project")} rules={rules} required>
            <SelectInput
              placeholder={t("Select project")}
              options={projectsData}
              onChange={(_value, option) => form.setFieldsValue({ project: option })}
              loading={projectsLoading}
              getPopupContainer={false}
            />
          </Form.Item>
          <Form.Item name="classification" label={t("Classification")} rules={rules} required>
            <RadioButtons
              options={Object.keys(classifications()).map(key => classifications()[key])}
              loading={typesLoading}
            />
          </Form.Item>
          <Form.Item name="propertyType" label={t("Property Type")} rules={rules} required>
            <RadioButtons options={typesData} onChange={() => form.setFieldsValue({ subType: null })} />
          </Form.Item>
          <Form.Item name="subType" label={t("Sub Type")} rules={rules} required shouldUpdate={true}>
            <SelectInput
              placeholder={t("Select sub type")}
              options={typesData?.find(type => type.id == propertyTypeId)?.children}
              disabled={!propertyTypeId}
              onChange={(_value, option) => {
                form.setFieldsValue({ subType: option })
              }}
              getPopupContainer={false}
            />
          </Form.Item>
          <Form.Item name="source" label={t("Please select a source")} rules={rules} required>
            <SelectInput
              placeholder={t("Source")}
              options={sourceData}
              onChange={(_value, option) => form.setFieldsValue({ source: option })}
              loading={sourceLoading}
              getPopupContainer={false}
            />
          </Form.Item>
        </Group>
      </Form>
    </Group>
  )
})

const AddLeadFormModal = ({
  modalVisible,
  setModalVisible,
  client,
  onSubmit,
  loading,
  onModalVisible = () => {},
  footerAlert,
}) => {
  const { t } = useTranslation()
  const formRef = useRef()
  return (
    <Modal
      title={t("Add Lead")}
      open={modalVisible}
      width={900}
      okText={t("Okay")}
      cancelText={t("Cancel")}
      onOk={() => formRef.current?.submit()}
      loading={loading}
      onModalVisible={onModalVisible}
      onCancel={() => setModalVisible(false)}
      footerAlert={footerAlert}
      destroyOnClose
    >
      {modalVisible && <AddLeadForm ref={formRef} client={client} onSubmit={onSubmit} />}
    </Modal>
  )
}

export { AddLeadForm, AddLeadFormModal }
