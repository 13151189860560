import { Empty as AntdEmpty } from "antd"
import Text from "../text/text"
import Title from "../title/title"

const Empty = props => {
  const { description, title, image, children, ...rest } = props

  return (
    <AntdEmpty
      image={image}
      description={
        <>
          {title ? <Title level={4}>{title}</Title> : undefined}
          {description ? <Text type="secondaryLight">{description}</Text> : undefined}
        </>
      }
      {...rest}
    >
      {children}
    </AntdEmpty>
  )
}
export default Empty
