import "react-phone-number-input/style.css"
import { default as PhoneInputReact } from "react-phone-number-input"
import { Row } from "antd"
import styles from "./phoneInput.module.less"
import { configs } from "configs"
import { getAppLanguage } from "configs/useInitializeApp"
import { useSelector } from "react-redux"

const PhoneInput = props => {
  const {
    label,
    className = "",
    errorMsg,
    horizontal,
    labelClassName,
    loading,
    errorType,
    errorClassName,
    placeholder,
    id,
    value,
    setValue,
    textRight,
    textAreaLabelClassName,
    containerClassName,
    left,
    right,
    onChange,
    inputContainerClass,
    disabled,
    component,
    ...rest
  } = props

  const { rtl } = useSelector(state => state.app.configs)
  let defaultCountry = configs.countryCode

  const renderLabel = () =>
    label ? (
      <div className="ant-col ant-form-item-label">
        <label className="ant-form-item-required">{label}</label>
      </div>
    ) : null

  const renderInput = () => (
    <PhoneInputReact
      value={value}
      onChange={onChange}
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      international
      disabled={disabled}
      {...rest}
    />
  )

  return (
    <>
      <div className={`${styles.phoneInputOuter} ${rtl && styles.phoneInputRTL} ${className}`} id={id ? id : ""}>
        <Row align="middle" justify="space-between">
          {renderLabel()}
          {!!component && component}
        </Row>
        {renderInput()}
        {!!errorMsg && <p>{errorMsg}</p>}
      </div>
    </>
  )
}
export default PhoneInput
