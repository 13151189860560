import { useEffect, useState } from "react"

import DataTable from "components/common/dataTable/dataTable"
import SenderReceiver from "components/common/senderReceiver/senderReceiver"
import { useGetSharingLogsQuery } from "features/clients/detail/api"
import UserProfileSummary from "features/shared/components/userProfileSummary"
import { t } from "i18next"
import { useParams } from "react-router"
import { localisedValue } from "utils"
import { DateFormat, Text } from "components/common"
import CollapsibleText from "components/common/collapsibleText"

const getSharingData = data => {
  let object =
    data &&
    data.map(element => {
      return {
        key: element?.key,
        date: <DateFormat date={element?.createdAt} />,
        leadId: element?.leadId,
        sharedBy: (
          <div>
            {localisedValue({ key: "sharedBy", object: element })}&nbsp;
            <UserProfileSummary staffId={element?.logAddedByUser?.id} />
          </div>
        ),
        sharedFrom: (
          <SenderReceiver
            receiver={
              element?.sharedFrom?.sender ? (
                <div>
                  {localisedValue({ key: "sender", object: element?.sharedFrom })}&nbsp;
                  <UserProfileSummary staffId={element?.sharedFrom?.senderId} />
                </div>
              ) : (
                t("NA")
              )
            }
          />
        ),
        sharedTo: (
          <SenderReceiver
            receiver={
              element?.sharedTo?.receiver ? (
                <div>
                  {localisedValue({ key: "receiver", object: element?.sharedTo })}&nbsp;
                  <UserProfileSummary staffId={element?.sharedTo?.receiverId} />
                </div>
              ) : (
                t("NA")
              )
            }
          />
        ),
        tag: element?.sharingTag || "-",
        statusType: localisedValue({ key: "statusType", object: element }),
        comment: (
          <CollapsibleText
            text={element?.comment ? localisedValue({ object: element, key: "comment" }) : "-"}
            collapsibleLength={150}
          />
        ),
      }
    })
  return object
}

const columns = () => [
  { title: t("Date"), dataIndex: "date", key: "date" },
  { title: t("Lead ID"), dataIndex: "leadId", key: "leadId", className: "tableStartSpace" },
  { title: t("Type"), dataIndex: "statusType", key: "statusType" },
  { title: t("By"), dataIndex: "sharedBy", key: "sharedBy" },
  { title: t("From"), dataIndex: "sharedFrom", key: "sharedFrom" },
  { title: t("To"), dataIndex: "sharedTo", key: "sharedTo" },
  { title: t("Tag"), dataIndex: "tag", key: "tag" },
  {
    title: t("Comment"),
    dataIndex: "comment",
    key: "comment",
  },
]

const SharingTab = ({ client, getCount = () => {} }) => {
  const { clientId } = useParams()
  const [filter, setFilter] = useState({ pageLimit: 10, page: 0 })
  const { data, isFetching, isError, error, isLoading, refetch } = useGetSharingLogsQuery({
    clientId: client.id,
    filter,
  })
  useEffect(() => {
    data?.count && getCount(data?.count)
  }, [data?.count])

  const onChangePage = (page, pageSize) => {
    setFilter(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }

  return (
    <DataTable
      pagination={{ totalCount: data?.count, pageOptions: [10, 25, 50] }}
      paginationOnBottom={true}
      paginationOnTop={false}
      onChangePage={onChangePage}
      data={getSharingData(data?.data)}
      columns={columns()}
      loading={isFetching}
      error={error}
      onErrorRetry={refetch}
      noUrlPush={true}
      pageSize={filter.pageLimit}
      stickyOffset={0}
    />
  )
}

export default SharingTab
