import { Tabs } from "antd"
import LeadReviewTable from "features/leads/components/LeadReviewTable/leadReviewTable"
import { TasksTable } from "features/tasks/components"
import { t } from "i18next"
import { useState } from "react"

const TaskReviewsTabs = ({ client, lead, clientLeads, taskTableProps }) => {
  const [activeKey, setActiveKey] = useState("tasks")
  const items = [
    {
      key: "tasks",
      label: t("Tasks"),
      children: <TasksTable stickyOffset={-16} client={client} clientLeads={clientLeads} {...taskTableProps} />,
    },
    {
      key: "leadReview",
      label: t("Lead reviews"),
      children: <LeadReviewTable client={client} lead={lead} />,
    },
  ]
  return (
    <Tabs
      activeKey={activeKey}
      items={items}
      onChange={key => {
        setActiveKey(key)
      }}
    />
  )
}
export default TaskReviewsTabs
