const areaUnitTransformer = response => {
  return response.map(e => ({
    ...e,
    id: e.listingId,
    price: e.unitPrice,
    discount: e.maxDiscount,
    name: e.value,
  }))
}

export default areaUnitTransformer
