import { Col, Collapse, Drawer, Form, Row, Skeleton, Space } from "antd"
import {
  Button,
  Divider,
  Group,
  Icon,
  SelectInput,
  SimpleDateFilter,
  SimpleDateRangeFilter,
  Switch,
  Tag,
  TextInput,
  Title,
  TreeSelectInput,
  UnitSelect,
  SimpleTimeRangeFilter,
} from "components/common"
import { forwardRef, useImperativeHandle, useMemo, useState } from "react"
import { FilterTag, LocationFilter, RegionFilter } from ".."

import cx from "clsx"
import RangeSlider from "components/common/rangeSlide/rangeSlider"
import UnitRangeSlider from "components/common/unitRangeSlider/unitRangeSider"
import useFilters from "hooks/useFilters"
import { useTranslation } from "react-i18next"
import { addQueryFilterArray } from "utils"
import { dateTimeFunction } from "utils/date"
import styles from "./index.module.less"
import { t } from "i18next"
import { DATE_TIME_FORMAT } from "constants"

const Filters = forwardRef(
  (
    {
      list: filterList,
      loading,
      ignoreFilterKeys,
      skeletonLoading,
      children,
      onSaveSearch,
      isDrawerView,
      renderTopRight,
      onFilterValueChange,
      onFiltersApply,
      onFilterClear,
      haveDependentRequests,
      haveDependents,
      searchText,
      onClickMoreFilters,
      saveSearchButtonLabel = t("Save Search"),
      isFetching,
      mapPreviousFilters,
      hideFilterTags = false,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [showMoreFilters, setShowMoreFilters] = useState(false)
    const { filterTags, filtersObject, setFiltersObject, setTagsAndFilters } = useFilters(
      filterList,
      ignoreFilterKeys,
      isFetching,
      haveDependentRequests ? [filterList.length] : [filterList],
      mapPreviousFilters
    )

    const AVAILABLE_FILTERS = useMemo(
      () =>
        filterList?.filter(
          item =>
            !item.hidden && (item.dependsOn ? item.dependsOn.every(e => filtersObject?.[e[0]]?.includes(e[1])) : true)
        ),
      [filterList, haveDependents ? filtersObject : null]
    )

    const INITIAL_FILTERS_TO_SHOW = 4
    const SPLIT_BY = ","

    const onSearch = (object, search, appliedParams) => {
      setTagsAndFilters(object, search, appliedParams)
      if (search) {
        !Object.keys(object).length ? onFilterClear() : onFiltersApply(object)
      }
    }

    useImperativeHandle(ref, () => ({ onSearch, setFiltersObject }), [filterList])

    const handleFilterReset = () => {
      onSearch({}, true)
      showMoreFilters && isDrawerView && setShowMoreFilters(false)
    }

    const onTagClose = item => {
      const obj = { ...filtersObject }
      delete obj[item.searchKey]
      const dependents = item.filterItem?.dependents
      dependents?.forEach(key => {
        delete obj[key]
      })
      onFilterValueChange(item, obj[item.searchKey], item.searchKey, filtersObject)
      onSearch(obj, true)
    }

    const onValueSelect = (item, filterObject, searchKey, getValueKey) => {
      const value = getValueKey(item)
      addQueryFilterArray(filterObject, searchKey, value, SPLIT_BY, obj => {
        obj[searchKey] = value
        onSearch(obj)
      })
    }

    const onMultiValueSelect = (items, filterObject, searchKey, getValueKey) => {
      const obj = { ...filterObject }
      const values = !!items.length ? items.map(e => getValueKey(e)) : []
      obj[searchKey] = values.join(SPLIT_BY)
      onSearch(obj)
    }

    const handleChangeTreeSelect = (items, filterObject, searchKey, toggleKey, isToggle) => {
      const obj = { ...filterObject }
      obj[searchKey] = items.join(SPLIT_BY)
      if (!!toggleKey) {
        obj[toggleKey] = isToggle
      }
      onSearch(obj)
    }

    const onTextInputChange = (event, filtersObj, searchKey, applyFilter = false) => {
      const value = event.target.value
      let obj = { ...filtersObj }
      obj[searchKey] = value
      onSearch(obj, applyFilter)
    }

    const onLocationSelect = (level, selectedOption, filterItem) => {
      let obj = { ...filtersObject }
      filterItem.keyList.forEach((item, index) => {
        let key = filterItem.keyList[index].value
        let value = selectedOption?.value || ""

        if (index === level) {
          obj[key] = value
          item?.dependents?.forEach(key => {
            obj[key] = null
          })
          onFilterValueChange(item, selectedOption, key)
        }
      })
      onSearch(obj)
    }

    const onUnitSelect = (searchKey, value, keyList, applyFilter = false, isStacked) => {
      let obj = { ...filtersObject }
      obj[searchKey] = value
      !isStacked &&
        keyList.forEach(key => {
          if (obj.hasOwnProperty(key)) {
            obj[key] = ""
          }
        })
      onSearch(obj, applyFilter)
    }

    const onChangeMoreFilters = () => setShowMoreFilters(showMoreFilters => !showMoreFilters)

    const getFilterType = item => {
      const {
        type,
        label,
        labelProps,
        placeholder,
        key,
        list,
        keyList,
        defaultValue,
        onValuesConvert,
        getValueKey = item => item?.value || item?.id,
        showInSingleTag,
        inputType,
        limit,
        iconColor,
        allowInput,
        level,
        getTagContent,
        nestedOptions,
        content_type,
        isStacked,
        picker = "date",
        appliedFilter,
        mapFilterObject,
        selectOptionsList,
        selectMode,
        maxCharacterLength,
        isCurrency = true,
        defaultValues = [0, 0],
        disableFuture,
        ...rest
      } = item
      switch (type) {
        case "select":
          return (
            <SelectInput
              zIndex={10}
              key={key}
              className="selectSearch"
              label={label}
              value={
                rest.mode === "multiple"
                  ? filtersObject[key]
                    ? filtersObject[key].split(SPLIT_BY)
                    : []
                  : filtersObject[key]
              }
              options={list}
              onChange={(_val, option) => {
                const filterObj = mapFilterObject ? mapFilterObject(filtersObject) : filtersObject
                if (rest.mode === "multiple") {
                  onMultiValueSelect(option, filterObj, key, getValueKey)
                  onFilterValueChange(item, option, key)
                } else {
                  onValueSelect(option, filterObj, key, getValueKey)
                  onFilterValueChange(item, option, key, filtersObject)
                }
              }}
              defaultValue={defaultValue}
              placeholder={placeholder}
              openIcon="MdKeyboardArrowUp"
              labelProps={labelProps}
              allowClear
              {...rest}
            />
          )
        case "treeSelect":
          return (
            <TreeSelectInput
              zIndex={10}
              className={cx("selectSearch", styles.TreeDropdownSelect)}
              label={label}
              value={filtersObject[key] ? filtersObject[key].split(SPLIT_BY) : []}
              options={list}
              handleOnChange={(it, isToggle) => {
                handleChangeTreeSelect(it, filtersObject, key, rest?.toggle?.key, isToggle)
                onFilterValueChange(item, it.join(SPLIT_BY), key)
              }}
              placeholder={placeholder}
              defaultToggleValue={!(filtersObject[rest?.toggle?.key] == "true")}
              {...rest}
            />
          )
        case "unitSelect":
          return (
            <UnitSelect
              maxLength={maxCharacterLength}
              label={label}
              selectOptionsList={selectOptionsList}
              selectMode={selectMode}
              options={keyList}
              defaultOption={keyList[defaultValue]}
              value={filtersObject}
              disableFuture={disableFuture}
              handleChange={(key, value, applyFilter = false) => {
                onUnitSelect(
                  key,
                  value,
                  keyList.map(e => e.value).filter(e => e !== key),
                  applyFilter,
                  item.isStacked
                )
                onFilterValueChange(item, value, key)
              }}
              placeholder={placeholder}
              inputClass="selectSearch"
              componentType={item.componentType}
              splitBy={SPLIT_BY}
            />
          )
        case "location":
          return (
            <LocationFilter
              className={"selectSearch"}
              level={level}
              valueList={keyList.map(e => filtersObject[e.value] || null)}
              onChange={(level, option) => {
                onLocationSelect(level, option, item)
              }}
              colSpan={isDrawerView ? 24 : 6}
            />
          )
        case "region":
          return (
            <RegionFilter
              className={"selectSearch"}
              level={level}
              valueList={keyList.map(e => filtersObject[e.value] || null)}
              onChange={(level, option) => {
                onLocationSelect(level, option, item)
              }}
              colSpan={isDrawerView ? 24 : 6}
              {...rest}
            />
          )
        case "date":
          return (
            <SimpleDateFilter
              label={label}
              picker={picker}
              valueFormat={picker == "month" && "YYYY-MM-01"}
              value={filtersObject[key]}
              onSelect={(_date, dateString) => {
                const obj = {
                  ...filtersObject,
                  [key]: dateString
                    ? dateTimeFunction({ date: dateString, apiRequest: true, format: DATE_TIME_FORMAT })
                    : null,
                }
                onSearch(obj)
                onFilterValueChange(item, dateString, key, filtersObject)
              }}
              onClear={() => {
                delete filtersObject[key]
                onSearch(filtersObject)
                onFilterValueChange(item, null, key, filtersObject)
              }}
              placeholder={placeholder}
              {...rest}
            />
          )
        case "dateRange":
          return (
            <SimpleDateRangeFilter
              label={label}
              value={filtersObject[key]}
              onSelect={date => {
                const obj = { ...filtersObject, [key]: date }
                onSearch(obj)
              }}
              onClear={() => {
                delete filtersObject[key]
                onSearch(filtersObject)
              }}
              {...rest}
            />
          )
        case "input":
          return (
            <TextInput
              value={filtersObject[key] || ""}
              label={label}
              placeholder={placeholder}
              type={inputType}
              limit={limit}
              useInternalState
              handleBlur={e => {
                onTextInputChange(e, filtersObject, key)
                onFilterValueChange(item, e.target.value, key)
              }}
              allowInput={allowInput}
              suffixIcon="search"
              labelProps={labelProps}
              skeletonLoading={skeletonLoading}
              onPressEnter={e => {
                onTextInputChange(e, filtersObject, key, true)
              }}
              {...rest}
            />
          )

        case "rangeSlider":
          return (
            <RangeSlider
              isCurrency={isCurrency}
              label={label}
              range={list}
              value={filtersObject[key]?.split(",") || undefined}
              setField={value => {
                let min = value[0] ?? defaultValues[0]
                let max = value[1] ?? defaultValues[1]
                const obj = {
                  ...filtersObject,
                  [key]: `${min},${max}`,
                }
                onSearch(obj)
              }}
            />
          )
        case "unitRangeSlider":
          return (
            <UnitRangeSlider
              label={label}
              rangeList={list}
              unitList={item?.unitList || []}
              defaultUnitKey={item?.defaultUnitKey}
              value={filtersObject[key]?.split(",") || undefined}
              setField={field => {
                let min = field?.range?.[0] ?? 0
                let max = field?.range?.[1] ?? 0
                const obj = {
                  ...filtersObject,
                  [key]: `${min},${max}`,
                  ["areaUnitKey"]: field?.rangeUnit,
                }
                onSearch(obj)
              }}
            />
          )
        case "toggle":
          return (
            <Switch
              className="selectSearch"
              label={label}
              value={filtersObject[key]}
              size="default"
              onChange={checked => {
                let obj = { ...filtersObject }
                obj[key] = checked
                onFilterValueChange(item, checked, key)
                onSearch(obj)
              }}
              {...rest}
            />
          )
        case "timeRange":
          return (
            <SimpleTimeRangeFilter
              label={label}
              value={filtersObject[key]}
              onSelect={time => {
                const obj = { ...filtersObject, [key]: time }
                onSearch(obj)
              }}
              onClear={() => {
                delete filtersObject[key]
                onSearch(filtersObject)
              }}
              {...rest}
            />
          )
        default:
          return null
      }
    }

    const searchButton = (text = t("Search"), size = "large", btnHeight = 48, icon = "FiSearch") => {
      return (
        <Button
          onClick={() => {
            onSearch(filtersObject, true)
            showMoreFilters && isDrawerView && setShowMoreFilters(false)
          }}
          icon={icon}
          type="primary"
          size={size}
          text={text}
          className="pi-12"
          style={{ height: btnHeight, boxShadow: "none" }}
          loading={loading || isFetching}
        />
      )
    }

    const renderTag = tag => (
      <Tag closable onClose={() => onTagClose(tag)} key={`${tag.searchKey}-${tag.label}`} color="#fff">
        {tag.title}
      </Tag>
    )

    const renderTags = () => {
      const tagList = filterTags?.list
        ? filterTags.list.map(e => ({
            ...e,
            title: <FilterTag label={e.label} value={e.tagValue} className={e.className} />,
          }))
        : []
      return (
        !!filterTags?.list?.length && (
          <div className="mbe-16">
            <Space size={12} align="start">
              <Icon icon="RiFilter2Fill" color="#9D9D9D" size={18} style={{ height: 32 }} />
              <div className={styles.appliedTags}>
                {tagList.map(renderTag)}
                <span>
                  <Button
                    type="link"
                    danger
                    variant="skeleton"
                    onClick={handleFilterReset}
                    disabled={loading}
                    text={t("Clear All")}
                    style={{ height: 32 }}
                  />
                  {onSaveSearch && (
                    <Button type="primary-light" onClick={() => onSaveSearch(filtersObject)} style={{ height: 32 }}>
                      <Icon icon="RiBookmarkFill" size="14px" /> {saveSearchButtonLabel}
                    </Button>
                  )}
                </span>
              </div>
            </Space>
          </div>
        )
      )
    }

    const renderInitialFilters = () => {
      return (
        <Row gutter={8} className={styles.filterMain}>
          {AVAILABLE_FILTERS?.map((item, index) =>
            index <= INITIAL_FILTERS_TO_SHOW - 1 ? (
              <Col span={6} key={item.key}>
                <Form.Item key={item.key}>{getFilterType(item)}</Form.Item>
              </Col>
            ) : null
          )}

          {AVAILABLE_FILTERS.length < INITIAL_FILTERS_TO_SHOW && (
            <Col span={6}>
              <div style={{ marginBlockStart: 23 }}>{searchButton(searchText)}</div>
            </Col>
          )}
        </Row>
      )
    }

    const renderItemWithHeading = (title, child) => (
      <>
        <Title style={{ paddingTop: 12 }} level={5}>
          {title}
        </Title>
        <Divider className="m-0 mbe-16" />
        {child}
      </>
    )

    const renderFilterItem = (item, index) =>
      item.type === "location" || item.type === "region" || item.type === "businessModel" ? (
        getFilterType(item)
      ) : (
        <Col span={isDrawerView ? 24 : 6} key={`${item.key}-${index}`}>
          <Form.Item className={item?.containerClassName} key={item.key}>
            {getFilterType(item)}
          </Form.Item>
        </Col>
      )

    const renderRestFilters = () =>
      AVAILABLE_FILTERS?.map((item, index) =>
        index > INITIAL_FILTERS_TO_SHOW - 1
          ? item.heading
            ? renderItemWithHeading(item.heading, renderFilterItem(item, index))
            : renderFilterItem(item, index)
          : null
      )

    const renderDrawerView = () => {
      return (
        AVAILABLE_FILTERS.length > INITIAL_FILTERS_TO_SHOW && (
          <Row gutter={8}>
            <Drawer
              title={
                <>
                  <Title level={3} className="mbe-0">
                    {t("Filters")}
                  </Title>
                  <span className="gray-700 fs12">{t("Apply filters to organize data accordingly")}</span>
                </>
              }
              closable={false}
              placement="right"
              width={450}
              onClose={onChangeMoreFilters}
              open={showMoreFilters}
              className={styles.filterMain}
              extra={<Icon icon="IoMdClose" onClick={onChangeMoreFilters} className="pointer" />}
              footer={
                <Row justify="end">
                  <Space>
                    <Button className="pi-12" style={{ "--btn-bg": "#9d9d9d" }} onClick={handleFilterReset} bordered>
                      {t("Reset Filters")}
                    </Button>
                    {searchButton(t("Apply Filter"), "default")}
                  </Space>
                </Row>
              }
            >
              {renderRestFilters()}
            </Drawer>
          </Row>
        )
      )
    }

    const renderCollapseView = () => {
      return (
        AVAILABLE_FILTERS.length > INITIAL_FILTERS_TO_SHOW && (
          <Collapse
            ghost
            expandIcon={() => (
              <Row justify="center">
                <Button type="link" variant="skeleton" onClick={onChangeMoreFilters}>
                  <span className="semiBold">{showMoreFilters ? t("Show Less") : t("Show More")}</span>
                  <div className={cx(styles.lotiOuter, showMoreFilters && styles.chevronReverse)}>
                    <div className={styles.chevron}></div>
                    <div className={styles.chevron}></div>
                    <div className={styles.chevron}></div>
                  </div>
                </Button>
              </Row>
            )}
            expandIconPosition="centre"
            collapsible="icon"
            className="expandableOuter mbe-8"
          >
            <Collapse.Panel>
              <Row gutter={8} className={styles.filterMain}>
                {renderRestFilters()}
                <Col span={4} style={{ marginTop: 25 }}>
                  {searchButton(searchText)}
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        )
      )
    }

    const renderFiltersDesktop = () => {
      return (
        <>
          <Group template="1fr auto" gap="8px">
            <div>
              {renderInitialFilters()}
              {isDrawerView ? renderDrawerView() : renderCollapseView()}
            </div>
            <Row
              align="middle"
              justify="space-between"
              wrap={false}
              style={{ marginBlockStart: 23, height: 48, gap: "8px" }}
            >
              {isDrawerView && AVAILABLE_FILTERS.length > INITIAL_FILTERS_TO_SHOW && (
                <Button
                  type="secondary-light"
                  size="small"
                  onClick={() => {
                    onChangeMoreFilters()
                    onClickMoreFilters()
                  }}
                  className="pi-8 pb-4"
                  style={{ height: "auto", fontWeight: 700 }}
                >
                  {t("Show More")}{" "}
                  <Icon
                    icon="MdOutlineDoubleArrow"
                    color="#95c7d5"
                    className="m-0 fs12"
                    style={{ "--flip-icon": "scaleX(-1)" }}
                  />
                </Button>
              )}
              {AVAILABLE_FILTERS.length >= INITIAL_FILTERS_TO_SHOW && searchButton(searchText)}
              {renderTopRight()}
            </Row>
          </Group>
        </>
      )
    }

    return loading && !AVAILABLE_FILTERS?.length ? (
      <>
        <FiltersSkeleton />
        {children({})}
      </>
    ) : children ? (
      children({ renderFiltersDesktop, renderTags })
    ) : (
      <>
        {renderFiltersDesktop()}
        {!hideFilterTags && renderTags()}
      </>
    )
  }
)

const FiltersSkeleton = () => {
  return [1, 2, 3, 4].map(e => (
    <>
      <Skeleton.Button active size="small" style={{ height: 22 }} />
      <Skeleton.Input active style={{ borderRadius: 6, width: "100%" }} />
    </>
  ))
}

Filters.defaultProps = {
  onFilterValueChange: () => {},
  onFiltersApply: () => {},
  onFilterClear: () => {},
  onClickMoreFilters: () => {},
  renderTopRight: () => null,
  isDrawerView: true,
}

export default Filters
