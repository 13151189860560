import { localisedValue } from "utils"

const unitNumberTransformer = response => {
  return response.map(e => {
    return {
      ...e,
      id: e.id,
      label: localisedValue({ object: e, key: "unitNumber" }),
      value: e.id.toString(),
    }
  })
}
export default unitNumberTransformer
