import { Separator } from "components/custom"
import { DataTable, Group, Number, Tag, Text } from "components/common"
import { Form, Row, Space } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useLazyGetLeadReviewsQuery } from "features/leads/api"
import { ratingArray } from "constants/list"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"

const LeadReviewTable = ({ lead, client, getCount = () => {} }) => {
  const { t } = useTranslation()
  const columns = [
    { title: t("Lead"), dataIndex: "lead", width: 100 },
    { title: t("Interest"), dataIndex: "interest" },
    { title: t("Comments"), dataIndex: "comment", width: 600 },
  ]

  const [getLeadReviews, { data: reviews, isFetching, error, refetch }] = useLazyGetLeadReviewsQuery()
  const [queryObject, setQueryObject] = useState({
    inquiryIds: lead?.id,
    clientIds: client?.id,
    page: 0,
    pageLimit: 10,
  })
  useEffect(() => {
    getLeadReviews({ ...queryObject })
  }, [queryObject])
  useEffect(() => {
    reviews?.count && getCount(reviews?.count)
  }, [reviews?.count])

  const onChangePage = (page, pageSize) => {
    setQueryObject(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }
  const ratingObject = useMemo(() => {
    const result = {}
    ratingArray()
      .forEach(e => {
        result[e.id] = e
      })
    return result
  }, [ratingArray])

  const getColumnData = data => {
    return data?.map(e => {
      return (
        <div>
          <Text type="secondaryLight" style={{ paddingInlineEnd: 8 }}>
            {e[0]}
          </Text>
          {e[1] || "-"}
        </div>
      )
    })
  }

  const renderFeature = field => {
    if (field) {
      return (
        <>
          {ratingObject[field.rating]?.label}
          <Separator />
          {localisedValue({ key: "comment", object: field })}
        </>
      )
    } else {
      return "-"
    }
  }

  const getCommentData = (review, index) => {
    return (
      <Group gap="8px" key={index}>
        <Space size={8}>
          {localisedValue({ key: "name", object: review?.Feedback?.reviewer })}
          <Tag type="light" children={localisedValue({ key: "name", object: review?.Feedback?.reviewer?.TeamType })} />
        </Space>
        {getColumnData(
          review?.Feedback?.FeedbackFields.map(field => {
            return [`${localisedValue({ key: "name", object: field?.FeedbackFieldType })}:`, renderFeature(field)]
          })
        )}
        <div>
          <Text type="secondaryLight" style={{ paddingInlineEnd: 8 }}>
            {t("Feedback")}:
          </Text>
          {`${t("Next task should be")} '${localisedValue({ key: "name", object: review.TaskType })}'`}
          <Separator />
          {localisedValue({ key: "feedback", object: review?.Feedback })}
        </div>
      </Group>
    )
  }
  const tableData = reviews?.data?.map((e, index) => {
    return {
      key: index,
      lead: e?.InternalInquiry?.id,
      interest: localisedValue({ key: "name", object: e?.InternalInquiry?.Project }),
      comment: getCommentData(e, index),
    }
  })

  return (
    <DataTable
      pagination={{ totalCount: reviews?.count, pageOptions: [10, 25, 50] }}
      columns={columns}
      data={tableData || []}
      loading={isFetching}
      noUrlPush={true}
      error={error}
      onErrorRetry={refetch}
      stickyOffset={true}
      onChangePage={onChangePage}
    />
  )
}

export default LeadReviewTable
