import { t } from "i18next"
import { Button, Icon, notify, Number, Text } from "components/common"
import { useMemo } from "react"

import { IconText } from "components/custom"
import { List, Space } from "antd"
import cx from "clsx"
import styles from "../index.module.less"
import { localisedValue } from "utils"

const ProjectDetail = ({ project }) => {
  const copyUrlToClipboard = text => {
    navigator.clipboard.writeText(text)
    notify.success(t("Details copied successfully"))
  }

  const projectSummary = useMemo(() => {
    return [
      [
        t("Possession"),
        (!!localisedValue({ object: project, key: "possession" }) &&
          localisedValue({ object: project, key: "possession" }) + " %") ||
          "-",
      ],
      [
        t("Possession Date"),
        (project?.possessionDate && new Date(project?.possessionDate).toLocaleDateString()) || "-",
      ],
      [t("Total Instalment"), project?.instalment || "-"],
      [
        t("Location"),
        (localisedValue({ object: project, key: "projectCity" })
          ? localisedValue({ object: project, key: "projectCity" }) +
            (localisedValue({ object: project, key: "projectState" }) ||
            localisedValue({ object: project?.Address?.Country, key: "name" })
              ? ", "
              : "")
          : "") +
          (localisedValue({ object: project, key: "projectState" })
            ? localisedValue({ object: project, key: "projectState" }) +
              (localisedValue({ object: project?.Address?.Country, key: "name" }) ? ", " : "")
            : "") +
          (localisedValue({ object: project?.Address?.Country, key: "name" })
            ? localisedValue({ object: project?.Address?.Country, key: "name" })
            : ""),
      ],
      [
        t("Min Token"),
        (project?.minToken && <Number value={project?.minToken} defaultValue="" isCurrency={true} />) || "-",
      ],
      [
        t("PDP"),
        (localisedValue({ object: project, key: "partialDp" }) &&
          localisedValue({ object: project, key: "partialDp" }) + " %") ||
          "-",
      ],
      [
        t("CDP"),
        (project?.ProjectFinancial?.completeDownPayment && project?.ProjectFinancial?.completeDownPayment + " %") ||
          "-",
      ],
      [
        t("URL"),
        project?.url && (
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              text={t("Visit")}
              type="link"
              icon={"AiOutlineLink"}
              className={cx(project.url ? styles.agendaLink : styles.agendaNoLink, "p-0")}
              onClick={() => project?.url && window.open(project.url, "_blank")}
              style={{ height: "auto" }}
            />

            <Text type="primary" style={{ cursor: "pointer" }} onClick={() => copyUrlToClipboard(project?.url)}>
              <Icon icon="RiFileCopyLine" />
              {t("Copy")}
            </Text>
          </Space>
        ),
      ],
    ]
  }, [project])

  const developerInfo = useMemo(() => {
    return [
      [t("Name"), localisedValue({ object: project?.ProjectDeveloper, key: "name" }) || "-"],
      [t("Address"), localisedValue({ object: project, key: "projectState" }) || "-"],
    ]
  }, [project])

  return (
    <div style={{ paddingTop: 12 }}>
      <IconText
        title={t("Project Summary")}
        icon={"BsFileEarmarkText"}
        iconProps={{ style: { color: "#9D9D9D" }, hasBackground: true, size: "1em" }}
        gap={8}
        style={{ fontWeight: 600 }}
        className="mbe-12"
      />

      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {projectSummary?.map(e => (
          <List.Item>
            <div className="gray-700">{e[0]}</div>
            <div>{e[1]}</div>
          </List.Item>
        ))}
      </List>

      <IconText
        title={t("Developer Info")}
        icon={"FaHardHat"}
        iconProps={{ style: { color: "#9D9D9D" }, hasBackground: true, size: "1em" }}
        gap={8}
        style={{ fontWeight: 600 }}
        className="mbe-12"
      />

      <List size="small" className={cx(styles.listingMain, "mbe-32")}>
        {developerInfo?.map(e => (
          <List.Item>
            <div className="gray-700">{e[0]}</div>
            <div>{e[1]}</div>
          </List.Item>
        ))}
      </List>

      {!!project?.ProjectDeveloper?.BankAccounts?.length && (
        <>
          <IconText
            title={t("Bank Details")}
            icon={"BsBank"}
            iconProps={{ style: { color: "#9D9D9D" }, hasBackground: true, size: "1em" }}
            gap={8}
            style={{ fontWeight: 600 }}
            className="mbe-12"
          />
          <List size="small" className={cx(styles.listingMain, styles.listingMainSingle, "mbe-32")}>
            {project?.ProjectDeveloper?.BankAccounts?.map((e, i) => {
              return (
                <List.Item>
                  <div className="gray-700" style={{ width: "20%" }}>
                    {t("Account No")}
                  </div>
                  <div>
                    {`${localisedValue({ object: e, key: "bankName" })} - ${localisedValue({
                      object: e,
                      key: "accountHolderName",
                    })},`}
                    <div>{`AC: ${e?.accountNumber} / ${e?.accountIBAN}`}</div>
                  </div>
                  <Text
                    type="primary"
                    style={{ cursor: "pointer", width: "20%" }}
                    onClick={() =>
                      copyUrlToClipboard(
                        `${localisedValue({ object: e, key: "bankName" })} - ${localisedValue({
                          object: e,
                          key: "accountHolderName",
                        })}, AC: ${e?.accountNumber} / ${e?.accountIBAN}`
                      )
                    }
                  >
                    <Icon icon="RiFileCopyLine" />
                    {t("Copy Details")}
                  </Text>
                </List.Item>
              )
            })}
          </List>
        </>
      )}
    </div>
  )
}

export default ProjectDetail
