const clientAnswerTransformer = response => {
  const profilingObj = {
    ...response?.profilingAttempted,
    industryId: true,
    existingTypeId: true,
    existingLocalityId: true,
    isCompleted: true,
  }
  const profileFilled = response?.profilingAttempted ? !Object.keys(profilingObj).some(e => !profilingObj[e]) : false
  return { ...response, profileFilled }
}
export default clientAnswerTransformer
