import { Form } from "antd"
import { DatePicker, Group, SelectInput, TextInput } from "components/common"

const FormItemCreator = props => {
  const { formItemChildrenList = [], formItemPropList = [], rules } = props
  return (
    <Group template={props.template ? props.template : "repeat(1, 1fr)"} gap={props.gap ? props.gap : "24px"}>
      {formItemPropList.map((item, index) => {
        const { required, name, label, hasFeedback } = item
        return (
          <Form.Item
            required={required}
            name={name}
            hasFeedback={hasFeedback}
            label={label}
            rules={item?.rules ? rules?.concat(item.rules) : rules}
          >
            {getFormChildren(formItemChildrenList[index])}
          </Form.Item>
        )
      })}
    </Group>
  )
}

export default FormItemCreator

const getFormChildren = props => {
  switch (props.type) {
    case "select":
      return <SelectInput {...props} />

    case "text":
      return <TextInput {...props} />

    case "date":
      return <DatePicker {...props} />
    default:
      return null
  }
}
