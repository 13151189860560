import { localisedValue } from "utils"

export const allocationsTransformHelper = response => {
  const { data: allocationData, totalCount: count } = response
  const allocationRows = allocationData.map(e => {
    return {
      ...e,
      key: e.id,
      AllocationFrom: {
        id: e?.AllocatedFrom?.id,
        name: localisedValue({ object: e?.AllocatedFrom, key: "name" }),
        designation: localisedValue({ object: e?.AllocatedFrom?.UserDesignation, key: "short" }),
        city: localisedValue({ object: e?.AllocatedFrom?.City, key: "name" }),
      },
      AllocationTo: {
        id: e?.AllocatedTo?.id,
        name: localisedValue({ object: e?.AllocatedTo, key: "name" }),
        designation: localisedValue({ object: e?.AllocatedTo?.UserDesignation, key: "name" }),
        city: localisedValue({ object: e?.AllocatedTo?.City, key: "name" }),
      },
      allocationBy: localisedValue({ object: e?.AllocateBy, key: "name" }),
    }
  })
  return { data: allocationRows, count: count }
}
