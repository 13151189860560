import { deleteKeysWithSubstring } from "utils"

import { dateTimeFunction } from "utils/date"

const getQueryTransformObject = (params, sortOrder, type) => {
  if (type === "client-listings") {
    const checkDate = key => {
      if (!!params[key]) {
        const dateR = params[key].split(",")
        params[key] = encodeURIComponent(
          `{"from":"${dateTimeFunction({ date: dateR[0], timeZone: true, apiRequest: true })}","to":"${dateTimeFunction(
            {
              date: dateR[1],
              timeZone: true,
              apiRequest: true,
            }
          )}"}`
        )
      }
    }
    const checkUsers = key => {
      if (!!params[key]) {
        let childIdentifierKey = key + "_withoutChildren"
        params[key] = `{"userIds":[${params.allocatedTo}],"includeAllChildren":${!params?.[childIdentifierKey]}}`
      }
    }
    checkDate("createdAt")
    checkDate("stageUpdatedAt")
    checkUsers("allocatedTo")
    params = deleteKeysWithSubstring(params, "_withoutChildren")

    return {
      pageLimit: 10,
      page: 0,
      ...(sortOrder && { orderBy: sortOrder }),
      ...params,
    }
  } else {
    const checkPurpose = () => {
      if (params.purpose) {
        const [purposeId, leadSide] = params.purpose.split("-")
        if (purposeId) params["purposeId"] = purposeId
        if (leadSide) params["leadSide"] = leadSide
        delete params["purpose"]
      }
    }
    const checkUsers = key => {
      if (!!params[key]) {
        let childIdentifierKey = key + "_withoutChildren"
        params[key] = encodeURIComponent(
          `{"teamIds":[],"userIds":[${decodeURIComponent(params[key])}],"includeAllChildren":${!params?.[
            childIdentifierKey
          ]}}`
        )
      }
    }
    const checkDate = key => {
      if (!!params[key]) {
        const dateR = params[key].split(",")
        params[key] = encodeURIComponent(
          `{"from":"${dateTimeFunction({ date: dateR[0], timeZone: true, apiRequest: true })}","to":"${dateTimeFunction({
            date: dateR[1],
            timeZone: true, apiRequest: true
          })}"}`
        )
      }
    }
    checkUsers("taskAllocatedTo")
    checkUsers("sharedWith")
    checkUsers("allocatedTo")
    checkDate("createdAt")
    checkDate("taskAllocatedDate")
    checkDate("timeAllocation")
    checkDate("lastTaskAdded")
    checkDate("dateShared")
    checkPurpose()
    params = deleteKeysWithSubstring(params, "_withoutChildren")
    return params
  }
}

export default getQueryTransformObject
