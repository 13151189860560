import { t } from "i18next"
import { ACTION_TYPES, CONTENT_TYPES, EVENT_NAMES } from "utils/gtm/gtmEventsData"
import { Modal, RadioButtons, SelectInput, Text, notification } from "components/common"
import {
  useLazyGetCurrentLeadSharingQuery,
  useShareLeadWithSupportAutoMutation,
  useShareLeadWithSupportManualMutation,
} from "features/clients/detail/api"

import Checkbox from "components/common/checkbox/checkbox"
import { Form } from "antd"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import getPrivilege from "../../../../utils/privileges/privileges"
import getShareLeadSaleValidationSchema from "./shareLeadSupportValidationchema"
import { schemaRules } from "features/shared/utils"
import { useLazyGetUsersQuery } from "features/shared/api"
import { useSelector } from "react-redux"
import { useState } from "react"
import { useWatch } from "antd/lib/form/Form"
import { useTranslation } from "react-i18next"

const sharingTypes = privileges => [
  { value: "auto", label: t("Auto") },
  {
    value: "manual",
    label: t("Manual"),
    disabled: !getPrivilege("can_share_lead_with_support_manually", privileges),
  },
]

export default function ShareLeadSupportForm({ leads, client, formRef, from = {}, onSuccess = () => {} }) {
  const { t } = useTranslation()
  const privileges = useSelector(state => state.app.privileges)
  const [form] = Form.useForm()
  const sharingType = Form.useWatch("sharingType", form)
  const [unShareOldUsers, setunShareOldUsers] = useState(false)
  const [
    shareLeadWithSupportAuto,
    {
      isLoading: fetchingShareLeadSupportAuto,
      error: errorShareLeadSupportAuto,
      isError: isErrorShareLeadSupportAuto,
      reset: autoTriggerReset,
    },
  ] = useShareLeadWithSupportAutoMutation()
  const [
    shareLeadWithSupportManual,
    {
      isLoading: fetchingShareLeadSupportManual,
      error: errorShareLeadSupportManual,
      isError: isErrorShareLeadSupportManual,
      reset: manualTriggerReset,
    },
  ] = useShareLeadWithSupportManualMutation()

  const [getUsers, { data: usersListForSupportSharing, isFetching: fetchingUsersListForSupportSharing }] =
    useLazyGetUsersQuery()
  const [getCurrentUserSharing, { data: currentSharingData, isFetching: isFetchingCurrentSharing }] =
    useLazyGetCurrentLeadSharingQuery()

  const rules = schemaRules(getShareLeadSaleValidationSchema())
  const supportSharingUsers = currentSharingData?.userList
  const selectUserToShareWith = useWatch("user", form)
  const onModalVisible = async () => {
    if (leads.length === 1) {
      const data = await getCurrentUserSharing(leads[0]?.id).unwrap()
      form.setFieldValue("user", data?.[leads[0]?.id]?.support?.[0]?.sharedWith.id.toString())
    }
    if (leads.length > 1) {
      await getCurrentUserSharing(leads?.map(item => item?.id)?.toString()).unwrap()
    }

    getUsers({
      teamType: ["support", "callcenter", "telesales", "afn_i_affiliates_client_servicing"],
      module: "leads",
      subModule: "shareWithSupport",
      allAgencyUsers: true,
      designationKeys: "TSRAFF,TSR,CSE,EXE",
    })
  }

  const onSubmit = async values => {
    manualTriggerReset()
    autoTriggerReset()
    let user
    usersListForSupportSharing.some(e => {
      if (e.users.find(it => it.id == values.user)) {
        user = e.users.find(it => it.id == values.user)
        return true
      }
    })
    const params = { ...values, leads, user, unShareOldUsers }
    if (values?.sharingType === "auto") {
      const response = await shareLeadWithSupportAuto(params)
        .unwrap()
        .catch(e => {
          fireEvent({
            ...ACTION_TYPES.lead_sharing_support_submit,
            ...from,
            response: e.status,
            client_id: client.id,
            share_support_type: "auto",
            lead_id: leads?.[0]?.id,
          })
        })
      if (response) {
        form.resetFields()
        formRef?.current?.setVisible(false)
        notification.success({ message: t("The lead has been shared successfully") })
        setunShareOldUsers(false)
        fireEvent({
          ...ACTION_TYPES.lead_sharing_support_submit,
          ...from,
          response: "200",
          client_id: client.id,
          share_support_type: "auto",
          lead_id: leads?.[0]?.id,
        })
        onSuccess(leads)
      }
    } else {
      const response = await shareLeadWithSupportManual(params).unwrap()
      if (response) {
        form.resetFields()
        setunShareOldUsers(false)
        formRef.current.setVisible(false)
        notification.success({ message: t("The lead has been shared successfully") })
        onSuccess(leads)
        fireEvent({
          ...ACTION_TYPES.lead_sharing_support_submit,
          ...from,
          response: "200",
          client_id: client?.id,
          share_support_type: "manual",
          lead_id: leads?.[0]?.id,
        })
      }
    }
  }

  return (
    <Modal
      title={t("Share Lead")}
      subTitle={t("Support")}
      ref={formRef}
      width={670}
      okText={t("Share Lead")}
      cancelText={t("Cancel")}
      loading={fetchingShareLeadSupportManual || fetchingShareLeadSupportAuto}
      onCancel={() => {
        formRef?.current?.setVisible(false)
        autoTriggerReset()
        manualTriggerReset()
        setunShareOldUsers(false)
      }}
      onOk={form.submit}
      disableOkButton={
        supportSharingUsers?.length &&
        (sharingType === "auto"
          ? !unShareOldUsers
          : (!!supportSharingUsers?.length && !unShareOldUsers) ||
            supportSharingUsers?.some(userId => userId == selectUserToShareWith))
      }
      onModalVisible={onModalVisible}
      destroyOnClose
      footerAlert={errorShareLeadSupportAuto || errorShareLeadSupportManual}
      bodyStyle={{ overflowY: "visible" }}
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          name="shareLeadSupportForm"
          initialValues={{
            sharingType: getPrivilege("can_share_lead_with_support_manually", privileges) ? "manual" : "auto",
            user: null,
          }}
          onFinish={onSubmit}
          preserve={false}
        >
          <Form.Item name="sharingType" label={t("Sharing Type")} rules={rules}>
            <RadioButtons options={sharingTypes(privileges)} />
          </Form.Item>
          {sharingType === "manual" && (
            <Form.Item name="user" label={t("User")} rules={rules} preserve={true}>
              <SelectInput
                placeholder={t("Select user")}
                options={usersListForSupportSharing}
                loading={fetchingUsersListForSupportSharing}
                onChange={value => {
                  form.setFieldsValue({ user: value })
                }}
              />
            </Form.Item>
          )}

          {!!supportSharingUsers?.length && (
            <>
              <div>
                <Checkbox onChange={e => setunShareOldUsers(e.target.checked)}>
                  {t("Un-share with previous user and share again")}.
                </Checkbox>
              </div>
              <div>
                <Text type="danger">{t("The client is already shared with support")}.</Text>
              </div>
            </>
          )}
        </Form>
      </div>
    </Modal>
  )
}
