import { Button, Modal, PDFDocumentView } from "components/common"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

import { LOGIN_PATH } from "constants/app-paths"
import { notification } from "antd"
import parentApi from "store/parentApi"
import { useLazyGetLeadAcknowledgeSlipQuery } from "features/staff/listings/api"
import { useLogoutMutation } from "features/auth/api"
import { useNavigate } from "react-router-dom"
import { localisedValue } from "utils"

const LeadAcknowledgeModal = forwardRef((_props, ref) => {
  const navigate = useNavigate()
  const [userId, setUserId] = useState()
  const [getReport, { data, error, isLoading }] = useLazyGetLeadAcknowledgeSlipQuery()
  const [signReport, { isLoading: isLoadingSign, error: errorSign }] = parentApi.useSignLeadAcknowledgeSlipMutation()
  const [skipCount, { isLoading: isLoadingSkip, error: errorSkip }] = parentApi.useSkipLeadAcknowledgeMutation()
  const [logoutUser, { isLoading: isLoadingLogout }] = useLogoutMutation()

  const [signOffStatus, setSignOffStatus] = useState()
  const [signOff, setSignOff] = useState()

  const modalRef = useRef()
  const confirmModalRef = useRef()

  useImperativeHandle(
    ref,
    () => ({
      showFor(data) {
        const pendingSignOff = data.signOffStatus.pendingSignOffs[0]
        setSignOffStatus(localisedValue({ object: data, key: "signOffStatus" }))
        setSignOff(pendingSignOff)
        setUserId(data.userId)
        getReport({ id: data.userId, month: pendingSignOff.month, year: pendingSignOff.year, useBuffer: true })
        modalRef.current.setVisible(true)
      },
    }),
    []
  )

  const setNextSignOff = () => {
    setSignOff(prev => {
      const pendingSignOffs = signOffStatus?.pendingSignOffs?.filter(e => e.id !== prev.id)
      setSignOffStatus(prev => ({ ...prev, pendingSignOffs }))
      if (!pendingSignOffs?.length) modalRef?.current?.setVisible(false)
      if (pendingSignOffs?.[0]) {
        getReport({ id: userId, month: pendingSignOffs[0]?.month, year: pendingSignOffs[0]?.year, useBuffer: true })
      }
      return pendingSignOffs?.[0]
    })
  }

  const onConfirm = () => {
    signReport({ id: signOff.id }).then(e => {
      if (!e.error) {
        setNextSignOff()
        confirmModalRef?.current?.setVisible(false)
      } else {
        notification.error({ message: e?.error?.message })
      }
    })
  }

  const handleLogout = () => {
    logoutUser({}).then(e => {
      modalRef?.current?.setVisible(false)
      navigate(LOGIN_PATH)
    })
  }
  const onSkip = () => {
    if (signOffStatus?.canSkip) {
      skipCount(signOff.id)
        .unwrap()
        .then(() => setNextSignOff())
        .catch(e => notification.error({ message: e?.message }))
    }
  }

  return (
    <>
      <Modal
        ref={modalRef}
        closable={false}
        okText="Continue"
        bodyStyle={{ padding: 24 }}
        destroyOnClose
        footer={
          !isLoading && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button type="primary-light" loading={isLoadingLogout} size="large" key="logout" onClick={handleLogout}>
                  Logout
                </Button>
              </div>
              <div>
                {signOffStatus?.canSkip && (
                  <Button loading={isLoadingSkip} size="large" key="cancel" onClick={onSkip}>
                    Skip
                  </Button>
                )}
                <Button
                  size="large"
                  key="continue"
                  type="primary"
                  onClick={() => confirmModalRef?.current?.setVisible(true)}
                  text="Continue"
                />
              </div>
            </div>
          )
        }
      >
        <PDFDocumentView
          error={error}
          isLoading={isLoading}
          data={data}
          onRefetch={() => getReport({ id: userId, useBuffer: true, month: signOff?.month, year: signOff?.year })}
        />
      </Modal>
      <Modal
        width={400}
        ref={confirmModalRef}
        onCancel={() => confirmModalRef?.current?.setVisible(false)}
        bodyStyle={{ padding: 24 }}
        onOk={onConfirm}
        okText="Yes"
        destroyOnClose
        title="Confirmation"
        footerAlert={errorSign}
        loading={isLoadingSign}
      >
        Are you sure that you have read the report?
      </Modal>
    </>
  )
})

export default LeadAcknowledgeModal
