import moment from "moment"
import { dateTimeFunction } from "utils/date"
export const getSalesInitialValues = client => {
  if (client) {
    return {
      project: null,
      unit: null,
      unitPrice: 0,
      unitDiscount: null,
      dealPrice: null,
      nationalId: client?.nationalId,
      dateOfBirth: moment(client.dateOfBirth).isValid()
        ? dateTimeFunction({ date: client.dateOfBirth, Default: true })
        : null,
      discountType: { key: 1, label: "%", value: "%" },
      country: null,
      city: null,
      amount: undefined,
      receiptNumber: null,
      modeOfPayment: null,
      paymentProof: [],
      referenceNumber: null,
      depositDate: null,
      paymentType: null,
      bank: null,
      comment: "",
      cdpCommitment: "",
      featureTable: [],
    }
  }
}
