import { t } from "i18next"
import { InfoList } from "components/custom"
import { Skeleton } from "components/common"
import { useLazyGetClientBasicDetailsQuery } from "../../api"
import { useMemo } from "react"
import { localisedValue } from "utils"

export default function ClientInfo({ client }) {
  const [fetchData, { data, isFetching }] = useLazyGetClientBasicDetailsQuery()

  const getClientInfoList = useMemo(() => {
    return [
      [
        t("Source"),
        client?.InternalInquirySource?.map(e => {
          return e.name || ""
        }).join(", "),
      ],
      ...(localisedValue({ object: data, key: "Affiliate" })
        ? [
            ["heading", "Affiliate"],
            ["Name", localisedValue({ object: data?.Affiliate?.AffiliateContact?.Contact, key: "name" })],
            ["Type", localisedValue({ object: data?.Affiliate?.AffiliateContact, key: "affiliateType" })],
            ["MOU-ID", data?.Affiliate?.AffiliateContact?.affiliateMouId],
            ["CRM-ID", data?.Affiliate?.AffiliateContact?.affiliateCrmId],
          ]
        : []),
    ]
  }, [data, client])

  return (
    <InfoList
      list={isFetching ? [[<Skeleton />, <Skeleton />]] : getClientInfoList}
      onClick={e => {
        fetchData(client?.Client?.id)
        e?.stopPropagation()
      }}
    />
  )
}
