import { configs } from "configs"
import { useEffect } from "react"
import ReactGA from "react-ga4"
import OneSignal from "react-onesignal"
import { useSelector } from "react-redux"
import { inDevelopMode } from "utils/env"

export const getAppLanguage = () => {
  const pathDirectories = window.location.pathname.split("/")
  if (pathDirectories.length > 1) {
    const pathLocale = pathDirectories[1]
    return configs.languages.find(e => e.key == pathLocale) || {}
  }
}

export const useInitializeApp = () => {
  const user = useSelector(state => state.app?.user?.info)
  
  useEffect(() => {
    if (!inDevelopMode) {
      OneSignal.init({ appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true }).then(
        rs => {
          OneSignal.showSlidedownPrompt().then(res => {
            console.log("One Signal Initialized", rs, res)
          })
        }
      )
      OneSignal.on("subscriptionChange", function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed)
      })
    }

    !inDevelopMode &&
      ReactGA.gtag("set", "user_properties", {
        user_id: user?.id,
        user_email: user?.email,
        user_designation: user?.UserDesignation?.name,
        user_team: user?.team,
        user_region: user?.City?.name,
      })
  }, [])
}
