import {
  AGENCY_CONTEXT_STORAGE_KEY,
  AGENCY_INFO_STORAGE_KEY,
  LAST_FETCH_STORAGE_KEY,
  RETURN_URL_STORAGE_KEY,
  USER_CONTEXT_STORAGE_KEY,
  USER_PIC_STORAGE_KEY,
  USER_PRIVILEGES_STORAGE_KEY,
  USER_SIGN_OFF_STORAGE_KEY,
  USER_STORAGE_KEY,
} from "../constants"
import { AnnouncementModal, LeadAcknowledgeModal } from "features/shared/components"
import { Button, ComponentState, Spinner, notification } from "components/common"
import {
  BOOKING_FORM_LISTING,
  EDIT_STAFF_PATH,
  LOGIN_PATH,
  MANAGEMENT_PATH,
  NAME_SPACE,
  RESET_PASSWORD_PATH,
  STAFF_PATH,
  VERIFY_DISCOUNT_STATUS_PATH,
} from "constants/app-paths"
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router"
import { Suspense, useEffect, useMemo, useRef } from "react"
import { getStorageItem, removeStorageItem, setStorageItem } from "utils/localStorage"
import { logout, setAppUser, setPrivileges } from "store/appSlice"
import { useDispatch, useSelector } from "react-redux"

import { APP_ROUTES } from "constants/app-routes"
import AppLayout from "layout"
import { IdleDetector } from "hooks/useIdleDetection"
import { Link } from "react-router-dom"
import ResetPassword from "features/auth/reset-password"
import { Result } from "antd"
import { STATIC_PRIVILEGES } from "features/shared/data"
import SignIn from "features/auth/sign-in"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import getPrivilege from "utils/privileges/privileges"
import moment from "moment"
import parentApi from "store/parentApi"
import theme from "utils/themeVars"
import { legacyToReactPaths } from "constants/legacyPathsMapping"
import DiscountStatusCard from "components/custom/discountStatusCard/discountStatusCard"

const MatchAllRoute = ({ path, renderLogoutButton }) => {
  const pathExists = APP_ROUTES?.find(e => window.location.pathname === `/${NAME_SPACE}${e.path}`)
  return (
    <div className="d-grid" style={{ height: "100vh", placeContent: "center" }}>
      <Result
        status={pathExists ? "500" : "404"}
        title={pathExists ? "Access Denied" : "404"}
        subTitle={
          pathExists ? "You do'not have privilege to access this page" : "Sorry, the page you visited does not exist."
        }
        extra={path ? <Link to={path}>Back to Home</Link> : renderLogoutButton()}
      />
    </div>
  )
}

const PFRoutes = () => {
  const isLoggedIn = useSelector(state => state.app.auth?.isLoggedIn)
  const privileges = useSelector(state => state.app?.privileges)
  const user = useSelector(state => state.app?.user?.info)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getUser, { isFetching: isFetchingUser, isError: isErrorUser, error: errorUser }] =
    parentApi.useLazyGetUserDetailQuery()
  const [getPrivileges, { isFetching: isFetchingPrivileges, isError: isErrorPrivileges, error: errorPrivileges }] =
    parentApi.useLazyGetUserPrivilegesQuery()
  const [getAgencyAssessments] = parentApi.useLazyGetPendingAgencyAssessmentsQuery()
  const [getAnnouncements] = parentApi.useLazyGetPendingAnnouncementsQuery()

  const [userAccounts] = parentApi.useLazyGetUserAccountsQuery()
  const [getAgencyCurrency] = parentApi.useLazyGetAgencyPrimaryCurrencyQuery()
  const [agencySettingsByKeys] = parentApi.useLazyGetAgencySettingsByKeysQuery()

  const announcementRef = useRef()
  const leadAcknowledgeRef = useRef()

  const availableRoutes = useMemo(() => APP_ROUTES.filter(e => getPrivilege(e.privilege, privileges)), [privileges])

  const savePrivileges = (privs, storageOnly) => {
    !storageOnly && dispatch(setPrivileges(createPrivilegeObject(privs)))
    setStorageItem(USER_PRIVILEGES_STORAGE_KEY, privs)
  }

  const redirectExternal = redirectUrl => {
    if (window.location.href != redirectUrl) {
      window.location.replace(redirectUrl)
    }
  }

  // TODO: Need to improve apis
  const fetchInitials = async () => {
    if (isLoggedIn) {
      const lastFetchTime = getStorageItem(LAST_FETCH_STORAGE_KEY)
      // To check if announcement and lead acknowledgment apis fetch time passed 3 hours
      const fetchAnnouncements = lastFetchTime ? moment(lastFetchTime).isBefore(moment().subtract(3, "hours")) : true
      const [userResp, privResp, pendingAgencyAssessments, announcements, userAccountResp] = await Promise.all([
        user ? Promise.resolve() : getUser().unwrap(),
        privileges ? Promise.resolve() : getPrivileges().unwrap(),
        fetchAnnouncements ? getAgencyAssessments().unwrap() : Promise.resolve(),
        fetchAnnouncements ? getAnnouncements().unwrap() : Promise.resolve(),
        fetchAnnouncements ? userAccounts().unwrap() : Promise.resolve(),
      ])
      if (userResp) {
        dispatch(setAppUser({ info: userResp }))
        setStorageItem(USER_STORAGE_KEY, userResp)
        setStorageItem(USER_PIC_STORAGE_KEY, (userResp.Attachments.length && userResp.Attachments[0].path) || "", true)
        removeStorageItem(RETURN_URL_STORAGE_KEY)
      }
      if (userResp?.isBlocked || user?.isBlocked) {
        if (userResp?.bookingFormStatusNotUpdated || user?.bookingFormStatusNotUpdated) {
          navigate(BOOKING_FORM_LISTING)
          savePrivileges(STATIC_PRIVILEGES.bookingForm, true)
          return
        } else if (userResp?.morningMeetingNotScheduled || user?.morningMeetingNotScheduled) {
          redirectExternal(`${window.location.origin}/en${MANAGEMENT_PATH}`)
          savePrivileges(STATIC_PRIVILEGES.morningMeeting)
          return
        } else if (privResp?.CVS || user?.rosterNotSet) {
          redirectExternal(`${window.location.origin}/en${EDIT_STAFF_PATH.replace(":staffId", userResp.id)}`)
          savePrivileges(STATIC_PRIVILEGES.staffProfile)
          return
        } else {
          dispatch(logout())
          notification.error({
            message:
              "You are blocked and do not have privilege to view your profile. Please contact your reporting manager!",
          })
          return
        }
      } else {
        if (privResp) {
          savePrivileges(privResp)
        }
        if (pendingAgencyAssessments?.length || announcements?.length) {
          if (pendingAgencyAssessments?.length) {
            // Redirecting to old propforce
            redirectExternal(`${window.location.origin}/en/training/assessment/${pendingAgencyAssessments[0]}`)
            return
          } else {
            announcements?.length && announcementRef.current.showFor(announcements)
          }
        }
        if (userAccountResp) {
          const keysToStore = {
            [USER_SIGN_OFF_STORAGE_KEY]: "signOffStatus",
            [AGENCY_CONTEXT_STORAGE_KEY]: "agency",
            [USER_CONTEXT_STORAGE_KEY]: "user",
          }
          Object.entries(keysToStore).forEach(([storageKey, userAccountKey]) => {
            setStorageItem(storageKey, userAccountResp?.[userAccountKey])
          })
          if (userAccountResp?.signOffStatus?.pendingSignOffs?.length) {
            leadAcknowledgeRef.current.showFor({ userId: userResp.id, signOffStatus: userAccountResp?.signOffStatus })
          }
        }
        // Setting last time if no assessments available
        if (
          pendingAgencyAssessments?.length === 0 &&
          announcements?.length === 0 &&
          userAccountResp?.signOffStatus?.pendingSignOffs?.length === 0
        ) {
          setStorageItem(LAST_FETCH_STORAGE_KEY, moment().toString(), true)
        }
      }
    } else {
      if (!window.location.href.includes(LOGIN_PATH)) {
        setStorageItem(RETURN_URL_STORAGE_KEY, window.location.href)
      }
      if (!isFetchingUser) {
        if (
          window.location.pathname != `/${NAME_SPACE}${LOGIN_PATH}` &&
          window.location.pathname != `/${NAME_SPACE}${RESET_PASSWORD_PATH}`
        ) {
          navigate(LOGIN_PATH)
        }
      }
    }
  }

  const fetchForOldApp = async () => {
    if (isLoggedIn) {
      const fetchAgency = getStorageItem(AGENCY_INFO_STORAGE_KEY)
      const [agencyCurrency, agencySettingsKey] = await Promise.all([
        fetchAgency ? Promise.resolve() : getAgencyCurrency().unwrap(),
        fetchAgency ? Promise.resolve() : agencySettingsByKeys().unwrap(),
      ])

      if (agencyCurrency && agencySettingsKey) {
        let agencyInfo = {}
        agencyInfo["primaryCurrency"] = agencyCurrency[0]
        agencyInfo["roleBasedPrivilegeSetting"] = agencySettingsKey.filter(
          item => item["key"] === "role_privileges"
        )?.[0]
        agencyInfo["leadStageSetting"] = agencySettingsKey.filter(item => item["key"] === "lead_stages")?.[0]
        agencyInfo["paymentImageProcessing"] = agencySettingsKey.filter(
          item => item["key"] === "payment_attachment_image_processing"
        )?.[0]
        setStorageItem(AGENCY_INFO_STORAGE_KEY, agencyInfo)
      }
    }
  }

  useEffect(() => {
    fetchInitials()
    fetchForOldApp()
  }, [isLoggedIn])

  const onRetry = () => {
    isErrorUser && getUser()
    isErrorPrivileges && getPrivileges()
  }

  const navigateToPath = () => {
    // TODO: this should be handled with privs
    if (
      user?.isBlocked &&
      availableRoutes[0]?.path.includes(STAFF_PATH) &&
      getPrivilege("can_view_staff", privileges)
    ) {
      return EDIT_STAFF_PATH.replace(":staffId", user.id)
    }
    return availableRoutes[0]?.path
  }

  const renderLogoutButton = () => (
    <Button
      text="Logout"
      onClick={() => dispatch(logout())}
      className="pi-0"
      type="link"
      size="small"
      style={{ "--btn-color": theme["primary-color"] }}
    />
  )

  const renderDefault = () => {
    if ((isErrorUser && errorUser) || (isErrorPrivileges && errorPrivileges)) {
      return (
        <div className="d-grid" style={{ height: "100vh", placeContent: "center" }}>
          <ComponentState
            status={500}
            title="Error"
            subTitle={errorUser?.message || errorPrivileges?.message}
            btnLoading={isFetchingUser || isFetchingPrivileges}
            onClick={onRetry}
            buttons={[renderLogoutButton()]}
          />
        </div>
      )
    } else if (isFetchingUser || isFetchingPrivileges || !privileges) {
      return <Spinner size="large" fullPageSpinner />
    }
    return <Navigate to={!!isLoggedIn ? navigateToPath() || APP_ROUTES[0].path : LOGIN_PATH} />
  }
  const checkIfLegacyPath = () => {
    const currentPath = window.location.pathname
    const mapping = legacyToReactPaths.find(route => {
      if (route.hasParams) {
        const paramPattern = new RegExp(route.legacyPath.replace(/:[^\s/]+/g, "[^\\s/]+"))
        return paramPattern.test(currentPath)
      }
      return route.legacyPath === currentPath
    })

    if (!mapping) {
      return false
    }

    let newPath = mapping.reactPath

    if (mapping.hasParams) {
      const paramNames = mapping.legacyPath.match(/:([^\s/]+)/g).map(param => param.slice(1))
      const paramValues = currentPath.match(new RegExp(mapping.legacyPath.replace(/:[^\s/]+/g, "([^\\s/]+)"))).slice(1)

      if (mapping.mapParamsToQuery) {
        const queryName = mapping.mapParamsToQuery.queryName
        const queryParamValue = paramValues[0]
        newPath += `?${queryName}=${queryParamValue}`
      } else {
        paramNames.forEach((name, index) => {
          newPath = newPath.replace(`:${name}`, paramValues[index])
        })
      }
    }
    return newPath
  }

  return (
    <>
      {checkIfLegacyPath() ? (
        <>{window.location.replace(checkIfLegacyPath())}</>
      ) : (
        <>
          <AnnouncementModal ref={announcementRef} />
          <LeadAcknowledgeModal ref={leadAcknowledgeRef} />
          {getPrivilege("can_view_tsr_section", privileges) && <IdleDetector />}
          <Routes>
            <Route path="/" element={renderDefault()} />
            <Route path={LOGIN_PATH} element={!!isLoggedIn ? <Navigate to="/" /> : <SignIn />} name="sign-in" />
            <Route
              path={RESET_PASSWORD_PATH}
              element={!!isLoggedIn ? <Navigate to="/" /> : <ResetPassword />}
              name="reset-password"
            />
            <Route path={VERIFY_DISCOUNT_STATUS_PATH} element={<DiscountStatusCard />} name="verify-discount-status" />
            <Route
              path="/"
              element={
                !!isLoggedIn ? (
                  <AppLayout>
                    <Suspense fallback={<Spinner size="large" fullPageSpinner />}>
                      <Outlet />
                    </Suspense>
                  </AppLayout>
                ) : (
                  <Navigate to={LOGIN_PATH} />
                )
              }
            >
              {availableRoutes.map(({ Component, path, name, privilege }) =>
                getPrivilege(privilege, privileges) ? (
                  <Route path={path} element={<Component />} name={name} key={name} />
                ) : null
              )}
            </Route>
            {!!privileges && (
              <Route
                path="*"
                element={<MatchAllRoute path={navigateToPath()} renderLogoutButton={renderLogoutButton} />}
              />
            )}
          </Routes>
        </>
      )}
    </>
  )
}

export default PFRoutes
