import { Button, DataTable, DateFormat, Group, Icon, Tag, Text } from "components/common"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Row } from "antd"
import cx from "clsx"
import Recording from "components/common/recording/recording"
import { TitleDescription } from "components/custom"
import { ProjectModal } from "features/shared/components"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import getPrivilege from "utils/privileges/privileges"
import { TaskFilters } from ".."
import { convertToReadableString, joinBytItems, localisedValue } from "utils"
import styles from "./index.module.less"
import theme from "utils/themeVars"
import { useGetTaskLogsQuery } from "features/clients/detail/api"
import { t } from "i18next"
const getColumns = tsrTable =>
  tsrTable
    ? [
        { title: t("Lead Info"), dataIndex: "leadInfo", key: "leadInfo", className: "tableStartSpace" },
        { title: t("Last Task"), dataIndex: "taskStatus", key: "taskStatus", width: 375 },
        { title: t("Remarks"), dataIndex: "remarks", key: "remarks", width: 400 },
      ]
    : [
        { title: t("Lead Id"), dataIndex: "leadId", key: "leadId", width: 100, className: "tableStartSpace" },
        { title: t("Task Status"), dataIndex: "taskStatus", key: "taskStatus", width: 375 },
        { title: t("Interest"), dataIndex: "interest", key: "interest", width: 225 },
        { title: t("Remarks"), dataIndex: "remarks", key: "remarks", width: 400 },
      ]

const TasksTable = props => {
  const {
    client,
    fontSize,
    stickyOffset,
    lead,
    filterClass,
    from = {},
    getCount = () => {},
    tsrTable,
    leadPrivileges,
    tableStyle,
    tsrToggle,
    clientLeads,
  } = props

  const defaultFilters = { sortBy: "id", sortOrder: "desc", pageLimit: 10, page: 0, clientTaskAllocatedUsers: true }
  const [filterObject, setFilterObject] = useState({ ...defaultFilters, ...lead })
  const [viewDescriptions, setViewDescriptions] = useState([])
  const { data, isFetching, error, refetch } = useGetTaskLogsQuery({
    clientId: client?.id,
    filterObject,
  })
  const projectRef = useRef()

  useEffect(() => {
    data?.count && getCount(data?.count)
  }, [data?.count])

  useEffect(() => {
    setFilterObject({ ...defaultFilters, ...lead })
  }, [lead])

  const onChangePage = (page, pageSize) => {
    setFilterObject(prev => ({ ...prev, pageLimit: pageSize, page: page }))
  }

  const handleSearch = (objFilter, filterLabels) => {
    setFilterObject({ ...objFilter })
    fireEvent({ ...ACTION_TYPES.when_filter_is_applied, ...from, ...filterLabels })
  }

  const onSort = obj => {
    setFilterObject(prev => ({ ...prev, sortBy: obj.columnName, sortOrder: obj.sortOrder }))
  }

  const onClickCommentsMore = e => () =>
    setViewDescriptions(prevState => (prevState.find(it => it == e.id) ? [] : [e.id]))

  const renderShowMore = useCallback(
    e => {
      return (
        !viewDescriptions.find(e => e === client.id) && (
          <Button
            type="link"
            size="small"
            className="pointer text-underline p-0"
            style={{ height: "auto" }}
            onClick={onClickCommentsMore(e)}
            text={t("more")}
          />
        )
      )
    },
    [viewDescriptions, client]
  )

  const getStatusColors = status => {
    if (status === "pending" || status === "undecided") {
      return { color: "#D9D9D9" }
    }
    if (status === "approved") {
      return { color: "#2CB270", textColor: "#FFFFFF" }
    }
    if (status === "rejected") {
      return { color: "#F39797", textColor: "#FFFFFF" }
    }
  }

  const taskTableData = useMemo(() => {
    const object =
      data?.data &&
      data.data.map((e, index) => {
        return {
          key: e.id,
          leadInfo: (
            <>
              <div>{e.leadId}</div>
              <TitleDescription
                title={
                  getPrivilege(["can_view_client_project_summary"], leadPrivileges) ? (
                    <a rel="noreferrer"> {localisedValue({ key: "projectName", object: e?.interest })}</a>
                  ) : (
                    localisedValue({ key: "projectName", object: e?.interest })
                  )
                }
                onClick={e1 => {
                  e1.stopPropagation()
                  if (getPrivilege(["can_view_client_project_summary"], leadPrivileges)) {
                    projectRef.current.showFor(e.InternalInquiry?.Project.projectId)
                  }
                }}
                gapSpace={4}
                description={joinBytItems(
                  localisedValue({ object: e.interest, key: "projectDesc" }).filter(x => x.trim()),
                  <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
                )}
              />
            </>
          ),
          leadId: e.leadId,
          taskStatus: (
            <TitleDescription
              color={e.taskStatus.name === "SalesClosed(Won)" ? "" : undefined}
              title={
                <>
                  {localisedValue({ key: "name", object: e.taskStatus })
                    ?.filter(x => x.trim())
                    .join("-") || ""}{" "}
                  {e?.QualityCheckTask && (
                    <Tag {...getStatusColors(e?.QualityCheckTask?.status)}>{` ${t("QC")}: ${convertToReadableString(
                      localisedValue({
                        object: e?.QualityCheckTask,
                        key: "status",
                      })
                    )}`}</Tag>
                  )}
                </>
              }
              description={<DateFormat date={e.taskStatus.date} />}
            />
          ),
          interest: (
            <TitleDescription
              title={<a rel="noreferrer">{localisedValue({ key: "projectName", object: e?.interest })}</a>}
              onClick={() =>
                e.InternalInquiry?.Project?.projectId &&
                projectRef.current.showFor(e.InternalInquiry?.Project.projectId)
              }
              gapSpace={4}
              description={joinBytItems(
                localisedValue({ key: "projectDesc", object: e.interest }).filter(x => x.trim()),
                <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              )}
            />
          ),
          remarks: (
            <Group gap="2px" key={index}>
              <Row style={{ gap: "2px 8px" }} align="middle">
                {localisedValue({ key: "assignedTo", object: e.remark })}
                <Tag
                  type={!(e.remark.tagData === "Telesales") && "light"}
                  color={e.remark.tagData === "Telesales" && "#FFC000"}
                  children={e.remark.tagData}
                />
              </Row>
              {e.remark.desc && (
                <div style={{ wordBreak: "normal" }}>{localisedValue({ key: "desc", object: e.remark })}</div> // break work added to prevent overflow in the case of text with no space
              )}
              {e?.remark?.attachment.length ? (
                <Row align="middle" style={{ gap: "2px 8px" }} className="fs12">
                  {e?.remark?.attachment.map((e, index) => (
                    <a key={index} href={e?.path} target="_blank" rel="noreferrer">
                      <Icon style={{ color: theme["primary-color"] }} key={index} icon="BsFileEarmarkText" size="1em" />{" "}
                      {t("Attachment")}
                      {index + 1}
                    </a>
                  ))}
                </Row>
              ) : null}
              {!!e?.commentVoiceRecording && (
                <Recording id={e?.commentVoiceRecording?.id} recordingSrc={e?.commentVoiceRecording?.path} />
              )}
            </Group>
          ),
        }
      })
    return object || []
  }, [data])

  return (
    <>
      {(client?.leadIdsWithTasks || clientLeads) && (
        <TaskFilters
          client={client}
          filterObject={filterObject}
          leadIds={client?.leadIdsWithTasks || clientLeads}
          onSearch={handleSearch}
          onSort={onSort}
          filterClass={filterClass}
          from={from}
          tsrToggle={tsrTable}
          staffList={data?.taskAllocatedUsers}
        />
      )}
      <DataTable
        tableLayout="auto"
        pagination={{ totalCount: data?.count, pageOptions: [10, 25, 50] }}
        paginationOnBottom={true}
        paginationOnTop={false}
        onChangePage={onChangePage}
        columns={getColumns(tsrTable)}
        data={taskTableData}
        error={error}
        loading={isFetching}
        onErrorRetry={refetch}
        tableStyle={{ ...tableStyle, fontSize }}
        noUrlPush={true}
        stickyOffset={stickyOffset}
        pageSize={filterObject.pageLimit}
        expandedRowKeys={viewDescriptions}
        expandable={{
          expandIcon: () => null,
          showExpandColumn: false,
          expandRowByClick: true,
          expandedRowClassName: () => "expandedTableOuter",
          expandedRowRender: (_record, index) => (
            <div className={cx(styles.taskComment, "fs12")}>
              <Text type="secondary" className="fw-medium" block>
                Comments:
              </Text>
              <Text type="secondaryLight" className="d-block">
                {data?.data?.[index]?.remark?.desc}{" "}
              </Text>
              <Button
                type="link"
                size="small"
                className="pointer text-underline p-0"
                style={{ height: "auto" }}
                onClick={onClickCommentsMore(data?.data?.[index])}
              >
                show less
              </Button>
            </div>
          ),
        }}
      />
      <ProjectModal ref={projectRef} />
    </>
  )
}
export default TasksTable

TasksTable.defaultProps = {
  fontSize: 14,
}
