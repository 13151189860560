const discountTransformHelper = response => {
  return {
    id: response.ListingInfo.id,
    unitNumber: response.ListingInfo.unitNumber,
    publishedPrice: response.ListingInfo.publishedPrice,
    dealDiscount: response.ListingInfo.dealDiscount,
    area: response.ListingInfo.area,
    Project: response.ListingInfo.Project,
    type: response.ListingInfo.type,
    currency: response.ListingInfo.currency,
    contractStatus: response.ListingInfo.contractStatus,
  }
}

export default discountTransformHelper
