import * as yup from "yup"

import { regex } from "constants/regex"
import { stringValidationYup } from "helpers/validations"
import { t } from "i18next"

const getAddReviewValidationSchema = () =>
  yup.object().shape({
    leadId: yup.string().required(t("Lead Id is required")),
    callRecording: stringValidationYup(t("Call recording is required")),
    additionalComment: stringValidationYup(null, regex.alphaNumeric),
    progressOnTask: stringValidationYup(t("Progress on task is required")),
    additionalComment2: stringValidationYup(null, regex.alphaNumeric),
    proposedNextTask: yup.object().required(t("Proposed next task is required")).nullable(),
    feedback: stringValidationYup(t("Feedback is required")),
  })

export default getAddReviewValidationSchema
