import { Button, Dropdown } from "components/common"
import { useAddClientContactViewTrackingMutation } from "features/clients/detail/api"
import { useLazyGetClientCellsQuery } from "features/shared/api"
import { ACTION_TYPES } from "utils/gtm/gtmEventsData"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const WhatsappCallButton = props => {
  const { t } = useTranslation()
  const { client, disabled, leadId, from, ...buttonProps } = props
  const userInfo = useSelector(state => state.app.user?.info)
  const [getClientCellInfo, { data: clientCells }] = useLazyGetClientCellsQuery()
  const [addClientContactView] = useAddClientContactViewTrackingMutation()

  const callOnWhatsapp = (cellNumber, id) => {
    // addClientContactView({ clientId: id, action: "contact_via_whats_app" })
    window.open(`https://web.whatsapp.com/send?phone=${cellNumber}`)
    fireEvent({
      ...ACTION_TYPES.whatsapp_contact_client,
      user_input: cellNumber,
      client_id: client?.id,
      ...(leadId && { lead_id: leadId }),
      ...from,
    })
  }

  const onClick = e => {
    e.domEvent.stopPropagation()
    callOnWhatsapp(items[e.key].label, client?.id)
  }

  const onCopy = e => {
    e.preventDefault()
  }
  const onContextMenu = e => {
    e.preventDefault()
  }

  const items = useMemo(
    () =>
      clientCells?.map((e, index) => {
        return { label: e, key: index }
      }),
    [clientCells]
  )

  return (
    <Dropdown
      menu={{ items, onClick, onCopy, onContextMenu }}
      dropdownRender={menu => (items?.length ? <div className="dropdown-content">{menu}</div> : <></>)}
      placement="bottomLeft"
      suffixIcon=""
      disabled={disabled}
    >
      <Button
        icon={"RiWhatsappFill"}
        iconProps={{ size: "1.4em" }}
        onClick={e => {
          e.stopPropagation()
          getClientCellInfo({ clientId: client?.id, actionType: "tooltip" })
        }}
        content={t("WhatsApp")}
        color="#3eaf4c"
        shape="circle"
        type="light"
        disabled={!userInfo?.AgencyConfiguration?.Platform?.platformKey === "zameen" || disabled}
        {...buttonProps}
      />
    </Dropdown>
  )
}

export default WhatsappCallButton
