import { t } from "i18next"
import { localisedValue } from "utils"

const staffMemberListTransformHelper = response => {
  const nonTeamMemberList =
    (response?.nonTeamMembers?.length && [
      {
        label: t("Others"),
        options: response.nonTeamMembers.map(it => ({
          ...it,
          label: localisedValue({ object: it, key: "name" }),
          value: it.id.toString(),
          id: it.id,
        })),
      },
    ]) ||
    []

  const teamMemberList = response.teamMembers.map(e => {
    return {
      ...e,
      label: localisedValue({ object: e, key: "team" }),
      options: e.users
        ? e.users?.map(it => {
            return { ...it, label: localisedValue({ object: it, key: "name" }), value: it.id.toString(), id: it.id }
          })
        : [],
    }
  })

  const combinedTeamList = teamMemberList.concat(nonTeamMemberList)

  return combinedTeamList
}

export default staffMemberListTransformHelper
