import { localisedValue } from "utils"

export const editHistoryTransformHelper = response => {
  const { data: editHistoryData, totalCount: count } = response
  const editHistoryRows = editHistoryData.map(e => {
    return {
      ...e,
      key: e.id,
      ...(e?.LoggedBy && {
        editedBy: {
          id: e?.LoggedBy?.id,
          name: localisedValue({ object: e?.LoggedBy, key: "name" }),
          designation: localisedValue({ object: e?.LoggedBy?.UserDesignation, key: "short" }),
          city: localisedValue({ object: e?.LoggedBy?.City, key: "name" }),
        },
      }),
    }
  })
  return { data: editHistoryRows, count: count }
}
