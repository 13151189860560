import moment from "moment/moment"
import { dateTimeFunction } from "utils/date"

const userPresenceTransformResponse = response => {
  let timeObjects = []
  let reportees = []

  const calculateDuration = data => {
    let totalActiveDuration = moment.duration(0)
    let totalInactiveDuration = moment.duration(0)
    let active = 0
    let inactive = 0
    let flag = false
    for (let i = 0; i < data.length; i++) {
      let previousValue
      previousValue = data[i - 1] !== null ? data[i - 1] : null

      if (data[i].status === "active") {
        flag = false
        active = data[i].statusUpdatedAt
        if (inactive !== 0) {
          const inactiveDuration = moment.duration(
            dateTimeFunction({ date: data[i].statusUpdatedAt, Default: true }).diff(
              dateTimeFunction({ date: inactive, Default: true })
            )
          )
          totalInactiveDuration.add(inactiveDuration)
        }
      } else if (data[i].status === "idle") {
        flag = true
        inactive = data[i].statusUpdatedAt
        if (active !== 0 && previousValue.status !== "idle") {
          const activeDuration = moment.duration(
            dateTimeFunction({ date: data[i].statusUpdatedAt, Default: true }).diff(
              dateTimeFunction({ date: active, Default: true })
            )
          )
          totalActiveDuration.add(activeDuration)
        }
      }
    }
    if (flag === false) {
      const activeDuration = moment.duration(
        moment().diff(dateTimeFunction({ date: data[data.length - 1].statusUpdatedAt, Default: true }))
      )
      totalActiveDuration.add(activeDuration)
    } else if (flag === true) {
      const inactiveDuration = moment.duration(
        moment().diff(dateTimeFunction({ date: data[data.length - 1].statusUpdatedAt, Default: true }))
      )
      totalInactiveDuration.add(inactiveDuration)
    }

    return { totalActiveDuration, totalInactiveDuration }
  }

  Object.keys(response?.usersTimeTracking).forEach(key => {
    const TimeTracking = calculateDuration(response?.usersTimeTracking[key])
    timeObjects.push({
      id: key,
      totalActiveTime: TimeTracking.totalActiveDuration.asMilliseconds(),
      totalInactiveTime: TimeTracking.totalInactiveDuration.asMilliseconds(),
    })
  })

  reportees = response?.reportees.map(e => {
    return {
      ...e,
      timeTracking: timeObjects.find(key => key.id == e.id),
    }
  })
  return {
    ...response,
    userTime: timeObjects.find(key => key.id == response?.id),
    reportees: reportees,
  }
}
export default userPresenceTransformResponse
