import { ACTION_TYPES, CONTENT_TYPES, EVENT_NAMES } from "utils/gtm/gtmEventsData"
import {
  DetailBar,
  Group,
  Modal,
  RadioButtons,
  SelectInput,
  Spinner,
  Text,
  TextInput,
  Title,
  notification,
} from "components/common"
import { Form, Input } from "antd"
import {
  useAddApplyForDiscountMutation,
  useLazyGetApplyDiscountListingInfoWithProjectQuery,
  useLazyGetAvailablePaymentDiscountQuery,
  useLazyGetDiscountApproversQuery,
  useLazyGetExistingDiscountQuery,
} from "features/clients/detail/api"
import { useEffect, useMemo } from "react"
import { useQueryUpdate } from "hooks"
import ThemeConfig from "configs/theme"
import { fireEvent } from "utils/gtm/gtmEventHandler"
import { getDiscountInitialValues } from "./discountInitialValues"
import getDiscountValidationSchema from "./discountValidationSchema"
import { getFormattedPrice } from "utils/privileges/price"
import getPrivilege from "utils/privileges/privileges"
import moment from "moment"
import { schemaRules } from "features/shared/utils"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useLazyGetLeadDetailsQuery } from "features/leads/api"
import { useSelector } from "react-redux"
import { useWatch } from "antd/lib/form/Form"
import { dateTimeFunction } from "utils/date"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"

const { variables: theme } = ThemeConfig

export default function ApplyDiscountForm({ formRef, lead, client, from = {} }) {
  const { t } = useTranslation()
  const loggedUserInfo = useSelector(state => state.app.user)
  const userPrivileges = useSelector(state => state.app.privileges)
  const canReapply = getPrivilege("can_reapply_deal_discount", userPrivileges)
  const currencyAgency = useSelector(
    state =>
      state?.app?.user?.info?.Agent?.AgencySettings?.find(currency => currency.key === "primary_currency").currencyCode
  )

  const [addApplyForDiscount, { data, error, isError, isLoading, reset }] = useAddApplyForDiscountMutation()
  const [getLeadDetails, { data: leadSalesInfo, isFetching: fetchLead }] = useLazyGetLeadDetailsQuery()
  const [getDealDiscountsProject, { data: applyDiscount, error: discountError, isFetching: fetchListing }] =
    useLazyGetApplyDiscountListingInfoWithProjectQuery()
  const [getExistingDiscount, { data: existingDiscount, error: discountTypeError, isFetching: fetchExistDiscount }] =
    useLazyGetExistingDiscountQuery()
  const [getDiscountApprovers, { data: discountApprover, isFetching: fetchApprover }] =
    useLazyGetDiscountApproversQuery()
  const [getAvailablePaymentDiscount, { data: availableDiscount, isFetching: fetchAvailableDiscount }] =
    useLazyGetAvailablePaymentDiscountQuery()

  const currency = !!applyDiscount?.currency ? applyDiscount?.currency : currencyAgency
  const publishedPrice = applyDiscount?.publishedPrice

  const [form] = Form.useForm()
  const { setFieldsValue } = form
  const discountType = useWatch("discountType", form)
  const paymentCommitment = useWatch("paymentCommitment", form)
  const discountValue = useWatch("discount", form)
  const discountForm = useWatch("discountForm", form)
  const [update] = useQueryUpdate()
  const rules = schemaRules(getDiscountValidationSchema())
  const discountOptions = [
    { key: 1, label: currency, value: "amount" },
    { key: 2, label: t("%"), value: "percentage" },
  ]

  const formStates = useMemo(() => {
    const obj = { pending: false, signed: false, rejected: false }
    obj.signed = existingDiscount?.doesDiscountExist
      ? applyDiscount?.contractStatus === "signed" && existingDiscount?.isApproved === 1
      : false
    obj.rejected = existingDiscount?.doesDiscountExist
      ? applyDiscount?.contractStatus === "draft" && existingDiscount?.isApproved === 0
      : false
    obj.pending = existingDiscount?.doesDiscountExist
      ? applyDiscount?.contractStatus === "draft" && existingDiscount?.isApproved === 1
      : false
    const checkExpiry = existingDiscount?.doesDiscountExist
      ? applyDiscount?.contractStatus === "draft" && existingDiscount?.isApproved === null
      : false
    if (checkExpiry) {
      if (applyDiscount.expiryDuration) {
        obj.rejected = true
      } else {
        obj.pending = true
      }
    }
    return obj
  }, [applyDiscount, existingDiscount])

  const getDiscountOptions = useMemo(
    () => [
      ...(!!availableDiscount?.length ? [{ key: 1, label: t("By Payment"), value: "by_payment" }] : []),
      ...(!!discountApprover?.length
        ? [
            {
              key: 2,
              label: "By Approval",
              value: "by_approval",
              Approver: discountApprover?.[0].Approver,
              discountId: discountApprover?.[0].DesignationDiscounts[0].id,
              discount:
                discountApprover?.[0].DesignationDiscounts[0].discountType === "percentage"
                  ? discountApprover?.[0].DesignationDiscounts[0].discount
                  : (discountApprover?.[0].DesignationDiscounts[0] / publishedPrice) * 100,
              discountType: discountApprover?.[0].DesignationDiscounts[0].discountType,
            },
          ]
        : []),
    ],
    [discountApprover, availableDiscount]
  )

  const getApprovalObject = useMemo(() => {
    return getDiscountOptions.find(e => e.value === "by_approval")
  }, [getDiscountOptions])

  const activeSalesDropdownData = useMemo(
    () => [
      {
        label: t("Unit Price"),
        data: getFormattedPrice(leadSalesInfo?.unitPrice, false, currency),
      },
      {
        label: t("Unit Discount"),
        data: !!discountForm
          ? discountForm?.value === "percentage"
            ? `${getFormattedPrice((discountValue / 100) * publishedPrice, false, currency)}(${discountValue}%)`
            : `${getFormattedPrice(discountValue, false, currency)}(${getFormattedPrice(
                (discountValue / publishedPrice) * 100,
                false,
                ""
              )}%)`
          : getFormattedPrice(leadSalesInfo?.unitDiscount, false, currency),
      },
      {
        label: t("Deal Price"),
        data: getFormattedPrice(leadSalesInfo?.dealPrice, false, currency),
      },
      {
        label: t("Paid Amount"),
        data: getFormattedPrice(leadSalesInfo?.paidAmount, false, currency),
      },
    ],
    [leadSalesInfo, currency, discountForm, discountValue, publishedPrice]
  )

  const discountCommitmentOptions = useMemo(
    () =>
      availableDiscount?.map(e => ({
        ...e,
        discount: e.discountType === "amount" ? (e.discount / publishedPrice) * 100 : e.discount,
      })),
    [availableDiscount, publishedPrice]
  )

  const detailData = [
    { label: leadSalesInfo?.projectName, value: leadSalesInfo?.type },
    {
      label: t("Unit ID"),
      value: leadSalesInfo?.unitId,
    },
    {
      label: t("Contract ID"),
      value: (
        <div>
          {leadSalesInfo?.contractId}
          {!!leadSalesInfo?.contractExpiry ? (
            <Text className="small" type="danger">
              ({t("Expiry")} {dateTimeFunction({ date: leadSalesInfo?.contractExpiry, format: "Do MMM" })} )
            </Text>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      label: t("Due Amount"),
      value: getFormattedPrice(leadSalesInfo?.dueAmount, false, currency),
    },
  ]
  const customDiscountValidator = (paymentCommitment, discountForm, discountType, publishedPrice, value) => {
    if (value === "" || !value) {
      return Promise.resolve()
    }
    if (!!paymentCommitment && discountForm?.value === "percentage") {
      return value > paymentCommitment?.discount
        ? Promise.reject(`${t("The discount cannot be greater than")} ${paymentCommitment?.discount}%`)
        : value < parseFloat(((1 / publishedPrice) * 100).toFixed(6))
        ? Promise.reject(`${t("The discount cannot be lesser than")} ${((1 / publishedPrice) * 100).toFixed(6)}`)
        : Promise.resolve()
    } else if (!!paymentCommitment && discountForm?.value !== "percentage") {
      return value > (paymentCommitment?.discount / 100) * publishedPrice
        ? Promise.reject(
            `${t("The discount cannot be greater than")} ${(paymentCommitment?.discount / 100) * publishedPrice}`
          )
        : value < 1
        ? Promise.reject(`${t("The discount cannot be lesser than")} ${1}`)
        : Promise.resolve()
    } else if (discountForm?.value === "percentage") {
      return value > getApprovalObject.discount
        ? Promise.reject(`${t("The discount cannot be greater than")} ${getApprovalObject.discount}%`)
        : value < parseFloat(((1 / publishedPrice) * 100).toFixed(6))
        ? Promise.reject(`${t("The discount cannot be lesser than")} ${((1 / publishedPrice) * 100).toFixed(6)}`)
        : Promise.resolve()
    } else {
      return value > (getApprovalObject.discount / 100) * publishedPrice
        ? Promise.reject(
            `${t("The discount cannot be greater than")} ${(getApprovalObject.discount / 100) * publishedPrice}`
          )
        : value < 1
        ? Promise.reject(`${t("The discount cannot be lesser than")} ${1}`)
        : Promise.resolve()
    }
  }

  const onModalVisible = () => {
    getDealDiscountsProject(lead?.id ?? skipToken, false)
    getExistingDiscount(lead?.id ?? skipToken, false)
    getLeadDetails(lead?.id ?? skipToken, false)
  }
  const handleSubmit = values => {
    let byApproval = false
    let content_type = CONTENT_TYPES.by_designation
    let discountApprovalData = {
      Approver: discountApprover?.[0]?.Approver,
      discountId: discountApprover?.[0]?.DesignationDiscounts[0]?.id,
      discount:
        discountApprover?.[0]?.DesignationDiscounts?.[0]?.discountType === "percentage"
          ? discountApprover?.[0]?.DesignationDiscounts?.[0]?.discount
          : (discountApprover?.[0]?.DesignationDiscounts?.[0] / publishedPrice) * 100,
      discountType: discountApprover?.[0]?.DesignationDiscounts?.[0]?.discountType,
    }
    if (values.discountType === "by_approval") {
      byApproval = true
      content_type = CONTENT_TYPES.by_approval
    }
    addApplyForDiscount({ byApproval, data: values, leadData: lead, price: publishedPrice, discountApprovalData })
      .unwrap()
      .then(() => {
        if (!isError) {
          form.resetFields()
          formRef?.current?.setVisible(false)
          notification.success({ message: t("Discount is applied successfully") })
          fireEvent({
            ...ACTION_TYPES.lead_apply_discount_submit,
            ...from,
            content_type,
            response: "200",
            client_id: client.id,
            lead_id: lead.id,
            payment_commitment: values?.paymentCommitment?.label,
            discount_by_designation: loggedUserInfo?.info?.UserDesignation?.name,
            discount_amount: getFormattedPrice((parseFloat(values?.discount) / 100) * publishedPrice),
          })
        }
      })
      .catch(e => {
        fireEvent({
          ...ACTION_TYPES.lead_apply_discount_submit,
          ...from,
          content_type,
          response: e.status,
          client_id: client.id,
          lead_id: lead.id,
          payment_commitment: values?.paymentCommitment?.label,
          discount_by_designation: loggedUserInfo?.info?.UserDesignation?.name,
          discount_amount: getFormattedPrice((parseFloat(values?.discount) / 100) * publishedPrice),
        })
      })
  }

  const discountTypeOnChange = option => {
    const existingDiscountType =
      existingDiscount?.discountInfo?.discountType === "percentage" ? discountOptions[1] : discountOptions[0]
    const approvalDiscountType =
      getApprovalObject?.value?.discountType === "percentage" ? discountOptions[1] : discountOptions[0]

    const isByPayment = option === "by_payment" && existingDiscount?.discountType === "by_payment"
    const isByApproval = option === "by_approval" && existingDiscount?.discountType === "by_approval"

    setFieldsValue({
      paymentCommitment: isByPayment
        ? discountCommitmentOptions?.find(e => e.value === existingDiscount?.discountInfo?.payment)
        : null,
      discount: isByPayment || isByApproval ? existingDiscount?.discountInfo?.discount : null,
      discountForm: isByPayment || isByApproval ? existingDiscountType : approvalDiscountType,
    })
  }

  const paymentCommitmentOnChange = option => {
    setFieldsValue({ paymentCommitment: option, discount: null, discountForm: null })
    if (option.discountType === "percentage") {
      setFieldsValue({ discountForm: { label: t("%"), value: "percentage" } })
    } else {
      setFieldsValue({ discountForm: { label: currency, value: "amount" } })
    }
  }
  const discountFormOnChange = option => {
    setFieldsValue({ discountForm: option })
    if (!!paymentCommitment) {
      if (option.label === "%") {
        setFieldsValue({ discount: (discountValue / publishedPrice) * 100 })
      } else {
        setFieldsValue({ discount: (discountValue / 100) * publishedPrice })
      }
    }
    if (discountType === "by_approval") {
      if (option.label === "%") {
        setFieldsValue({ discount: (discountValue / publishedPrice) * 100 })
      } else {
        setFieldsValue({ discount: (discountValue / 100) * publishedPrice })
      }
    }
  }

  useEffect(() => {
    if (!formStates.signed && !!lead && leadSalesInfo) {
      getDiscountApprovers({ projectId: leadSalesInfo?.projectId, inquiryId: lead?.id, userId: loggedUserInfo.info.id })
      getAvailablePaymentDiscount({ projectId: leadSalesInfo?.projectId, inquiryId: lead?.id })
    }

    if (formStates.rejected || formStates.pending || formStates.signed) {
      setFieldsValue({
        discountType: existingDiscount?.discountType,
        discount: existingDiscount?.discountInfo?.discount,
        discountForm:
          existingDiscount.discountInfo.discountType === "percentage" ? discountOptions[1] : discountOptions[0],
        ...(existingDiscount.discountType === "by_payment" && {
          paymentCommitment: discountCommitmentOptions?.find(e => e.value === existingDiscount?.discountInfo?.payment),
        }),
      })
    }
  }, [lead, leadSalesInfo, formStates, discountCommitmentOptions, canReapply, existingDiscount])

  useEffect(() => {
    if (!formStates.signed && !formStates.rejected && !formStates.pending && existingDiscount) {
      setFieldsValue({
        discountType: existingDiscount?.discountType || getDiscountOptions[0]?.value,
        discount: existingDiscount?.discountInfo?.discount,
        discountForm:
          existingDiscount?.discountInfo?.discountType === "percentage" ? discountOptions[1] : discountOptions[0],
        ...(existingDiscount?.discountType === "by_payment" && {
          paymentCommitment: discountCommitmentOptions?.find(e => e.value === existingDiscount.discountInfo.payment),
        }),
        ...(existingDiscount?.discountType === "by_approval" && {
          reason: existingDiscount?.comment,
        }),
      })
    }
  }, [fetchApprover, fetchAvailableDiscount, existingDiscount])

  return (
    <Modal
      title={t("Apply for Discount")}
      ref={formRef}
      width={670}
      okText={
        (formStates.rejected && canReapply) || (formStates.pending && canReapply)
          ? t("Re-Apply for discount")
          : t("Apply for Discount")
      }
      cancelText={t("Cancel")}
      onCancel={() => {
        formRef?.current?.setVisible(false)
        form.resetFields()
        reset()
        update("getExistingDiscount", draft => null)
      }}
      onOk={() => {
        form.submit()
      }}
      disableOkButton={
        (formStates.rejected && !canReapply) || (formStates.pending && !canReapply) || formStates.signed
          ? true
          : false ||
            !(availableDiscount?.length || discountApprover?.length) ||
            fetchLead ||
            fetchExistDiscount ||
            fetchListing ||
            fetchAvailableDiscount ||
            fetchApprover
      }
      loading={isLoading}
      onModalVisible={onModalVisible}
      footerAlert={error}
      destroyOnClose
    >
      {fetchLead || fetchExistDiscount || fetchListing || fetchAvailableDiscount || fetchApprover ? (
        <Spinner></Spinner>
      ) : (
        <>
          {availableDiscount?.length || discountApprover?.length ? (
            <Group>
              {formStates.signed && (
                <Text type="danger">
                  {t("The lead has a discount in signed state, you can not re-apply the discount.")}
                </Text>
              )}
              {formStates.rejected && (
                <Text type="danger">{`${t("The lead has been either rejected or expired")} ${
                  canReapply ? t("you can re-apply the discount") : t("you cannot re-apply for discount.")
                }`}</Text>
              )}
              {formStates.pending && (
                <Text type="danger">{`${t("The lead has a discount in approved state")}${
                  canReapply ? ", " + t("you can re-apply the discount") : ", " + t("you cannot re-apply for discount.")
                }`}</Text>
              )}
              <div>
                <Title type="secondary" level={4}>
                  {t("Contract Details")}
                </Title>
                <DetailBar
                  collapsible
                  collapseHeader={
                    <>
                      <Text>{localisedValue({ object: leadSalesInfo, key: "projectName" })}</Text>
                      <span className="gray-500 pi-8">&bull;</span>
                      <Text color={theme["gray800"]}>{localisedValue({ object: leadSalesInfo, key: "type" })}</Text>
                    </>
                  }
                  detailData={detailData.slice(1)}
                  listData={activeSalesDropdownData}
                />
              </div>
              <Form
                form={form}
                layout="vertical"
                name="form1"
                initialValues={getDiscountInitialValues()}
                onFinish={values => {
                  handleSubmit(values)
                }}
                preserve={false}
              >
                <Form.Item name="discountType" label={t("Discount Type")} rules={rules}>
                  <RadioButtons
                    options={getDiscountOptions}
                    onChange={e => {
                      discountTypeOnChange(e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={true}>
                  {({ getFieldValue }) => {
                    if (getFieldValue("discountType") === "by_payment") {
                      return (
                        <Form.Item name="paymentCommitment" label={t(t("Payment Commitment"))} rules={rules}>
                          <SelectInput
                            placeholder={t("Select commitment")}
                            options={discountCommitmentOptions}
                            onChange={(value, option) => {
                              paymentCommitmentOnChange(option)
                            }}
                          />
                        </Form.Item>
                      )
                    }
                  }}
                </Form.Item>

                <Input.Group compact>
                  <Form.Item
                    name="discount"
                    label={t("Discount")}
                    rules={rules.concat({
                      validator: async (_, value) => {
                        return customDiscountValidator(
                          paymentCommitment,
                          discountForm,
                          discountType,
                          publishedPrice,
                          value
                        )
                      },
                    })}
                    style={{ width: "calc( 100% - 80px)" }}
                  >
                    <TextInput
                      disabled={!discountType || (discountType === "by_payment" && !paymentCommitment)}
                      placeholder={t("Enter Amount")}
                      suffixText={
                        discountForm?.value === "percentage"
                          ? ` ${getFormattedPrice((discountValue / 100) * publishedPrice, false, currency)}`
                          : `${discountValue ? ((discountValue / publishedPrice) * 100).toFixed(6) : 0}%`
                      }
                    />
                  </Form.Item>

                  <Form.Item name="discountForm" rules={rules}>
                    <SelectInput
                      defaultValue=""
                      disabled={!discountType}
                      options={discountOptions}
                      onChange={(value, option) => {
                        discountFormOnChange(option)
                      }}
                      style={{ marginTop: 30, width: 80 }}
                    />
                  </Form.Item>
                </Input.Group>
                <div className="gray-800 fs12 mbe-20" style={{ marginTop: -1 }}>
                  {discountType === "by_approval" || !!paymentCommitment ? (
                    <Text>{`${t("Allowed Discount")} ${((1 / applyDiscount?.publishedPrice) * 100).toFixed(
                      6
                    )}% (${getFormattedPrice(1, false, currency)}) - ${
                      discountType === "by_approval"
                        ? getFormattedPrice(getApprovalObject?.discount, false, "") +
                          `%` +
                          `( ${getFormattedPrice(
                            (getApprovalObject?.discount / 100) * applyDiscount?.publishedPrice,
                            false,
                            currency
                          )})`
                        : getFormattedPrice(paymentCommitment.discount, false, "") +
                          `%` +
                          `( ${getFormattedPrice(
                            (paymentCommitment.discount / 100) * applyDiscount?.publishedPrice,
                            false,
                            currency
                          )} )`
                    }  `}</Text>
                  ) : null}
                </div>
                <Form.Item shouldUpdate={true}>
                  {({ getFieldValue }) => {
                    if (getFieldValue("discountType") === "by_approval") {
                      return (
                        <>
                          <Text>{`To ${localisedValue({ object: getApprovalObject?.Approver, key: "name" })}`}</Text>
                          <Form.Item name="reason" rules={rules}>
                            <TextInput placeholder={t("Please Give Reason")} lineCount={5} style={{ resize: "none" }} />
                          </Form.Item>
                        </>
                      )
                    }
                  }}
                </Form.Item>
              </Form>
            </Group>
          ) : (
            <Text>{t("No Discount is available for this lead")}</Text>
          )}
        </>
      )}
    </Modal>
  )
}
