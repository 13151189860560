import { Group, Icon } from "components/common"
import {
  ACTION_ITEMS_PATH,
  AFFILIATE_COMMISSION_PATH,
  AFFILIATE_KPI_REPORT_PATH,
  AFFILIATE_PATH,
  AFFILIATE_TASK_PATH,
  AFFILIATE_TO_DO_PATH,
  AFFILIATE_UNALLOCATED_PATH,
  AFFILIATE_VERIFICATION_PATH,
  AI_CALL_ANALYSIS_REPORT_PATH,
  BILLING_PROJECTS_LISTING_PATH,
  BONUS_LISTING_PATH,
  BOOKING_FORM_LISTING,
  CALL_RECORDING_PATH,
  CAMPAIGN_LISTING_PATH,
  CLIENT_LISTING_PATH,
  CONTRACT_PATH,
  INCENTIVES_LISTING_PATH,
  INVENTORY_LISTING_PATH,
  INVOICE_LISTING_PATH,
  INVOICE_PAYMENTS_LISTING_PATH,
  KPI_MANAGEMENT_REPORT_PATH,
  LEADS_LISTING_PATH,
  LEADS_SHARED_LISTING_PATH,
  LEAD_QUOTA_PATH,
  LOCATIONS_LISTING_PATH,
  MANAGEMENT_PATH,
  PAYMENT_PATH,
  PENALTY_LISTING_PATH,
  PRODUCTIVITY_REPORT_PATH,
  PROJECTS_LISTING_PATH,
  QUALITY_CONTROL_PATH,
  REFUNDS_LISTING_PATH,
  REVENUE_RECOGNITION_PANEL_PATH,
  REVENUE_REPORT_PATH,
  ROLES_AND_PRIVILEGES_PATH,
  ROSTER_PATH,
  RULE_INCLUSIONS_PATH,
  SALES_DISPUTE_PATH,
  SALES_KPI_REPORT_DEFAULT_LINK,
  SECONDARY_INVENTORY_LISTING_PATH,
  SOCIAL_CONNECTORS_LIST,
  STAFF_PATH,
  STAFF_TARGETS_PATH,
  TASKS_LISTING_PATH,
  TEAMS_PATH,
  TODOS_LISTING_PATH,
  TSR_AFFILIATE_PATH,
  SALES_PIPELINE,
  LMS_LEADS_PATH,
  LEAD_POOLS_PATH,
  TSR_KPI_REPORT_PATH,
  TSR_LISTING_PATH,
} from "constants/app-paths"
import { reportCategories } from "constants/list"
import { Link } from "react-router-dom"
import getPrivilege from "utils/privileges/privileges"

const Label = ({ label, icon }) => (
  <Group gap="6px" className="text-center">
    <Icon icon={icon} size={20} />
    <p>{label}</p>
  </Group>
)

const getLink = (path, label, icon) => (
  <Link to={path}>
    <Label label={label} icon={icon} />
  </Link>
)

const clientsLeads = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(CLIENT_LISTING_PATH, t("Clients"), "SvgMenuClients"),
      key: "clients",
      path: CLIENT_LISTING_PATH,
      show: getPrivilege("can_view_clients_module", privileges),
    },
    {
      label: getLink(LEADS_LISTING_PATH, t("Leads"), "SvgMenuLeads"),
      key: "leads",
      path: LEADS_LISTING_PATH,
      show: getPrivilege("can_view_leads_module", privileges),
    },
    {
      label: getLink(TODOS_LISTING_PATH, t("Todos"), "IconTaskList"),
      key: "todos",
      path: TODOS_LISTING_PATH,
      show: getPrivilege("can_view_todo_list_leads", privileges),
    },

    {
      label: getLink(TASKS_LISTING_PATH, t("Tasks"), "SvgIcTaskLogs"),
      key: "tasks",
      path: TASKS_LISTING_PATH,
      show: getPrivilege("can_search_leads", privileges),
    },
    {
      label: getLink(SALES_PIPELINE, t("Pipeline"), "SvgIcPipeLine"),
      key: "pipeline",
      path: SALES_PIPELINE,
      show: getPrivilege("can_view_pipeline", privileges),
    },
    {
      label: getLink(SALES_DISPUTE_PATH, t("Sales Dispute"), "SvgMenuSaleDispute"),
      key: "sales-dispute",
      path: SALES_DISPUTE_PATH,
      show: getPrivilege("can_view_sale_dispute", privileges),
    },
    {
      label: getLink(CALL_RECORDING_PATH, t("Call Recordings"), "IcCallRecord"),
      key: "call_recording",
      path: CALL_RECORDING_PATH,
      show: getPrivilege("can_view_call_recording_report", privileges),
    },
  ].filter(e => (all ? true : e.show))

const projectInventory = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(PROJECTS_LISTING_PATH, t("Projects"), "IcProjects"),
      key: "projects-new",
      path: PROJECTS_LISTING_PATH,
      show: getPrivilege(["can_view_projects"], privileges),
    },
    {
      label: getLink(INVENTORY_LISTING_PATH, t("Inv. Primary"), "IcInventory"),
      key: "inventory",
      path: INVENTORY_LISTING_PATH,
      show: getPrivilege(["can_view_inventory"], privileges),
    },
    {
      label: getLink(SECONDARY_INVENTORY_LISTING_PATH, t("Inv. Secondary"), "IcInventory"),
      key: "secondary-inventory",
      path: SECONDARY_INVENTORY_LISTING_PATH,
      show: getPrivilege(["can_view_secondary_inventory"], privileges),
    },
    {
      label: getLink(LEAD_QUOTA_PATH, t("Quota Slabs"), "BsPersonFillGear"),
      key: "lead-quota",
      path: LEAD_QUOTA_PATH,
      show: getPrivilege(["can_manage_lead_allocation_quota"], privileges),
    },
    {
      label: getLink(LOCATIONS_LISTING_PATH, t("Locations"), "MdLocationPin"),
      key: "location-new",
      path: LOCATIONS_LISTING_PATH,
      show: getPrivilege(["can_view_projects"], privileges),
    },
  ].filter(e => (all ? true : e.show))

const staff = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(STAFF_PATH, t("Staff"), "SvgMenuClients"),
      key: "staff",
      path: STAFF_PATH,
      show: getPrivilege(["can_view_staff"], privileges),
    },
    {
      label: getLink(TEAMS_PATH, t("Teams"), "IcTeams"),
      key: "teams",
      path: TEAMS_PATH,
      show: getPrivilege(["can_view_teams"], privileges),
    },
    {
      label: getLink(ROLES_AND_PRIVILEGES_PATH, t("Roles"), "BsPersonFillGear"),
      key: "roles",
      path: ROLES_AND_PRIVILEGES_PATH,
      show: getPrivilege(["can_manage_roles"], privileges),
    },
    {
      label: getLink(RULE_INCLUSIONS_PATH, t("Rules"), "IcRules"),
      key: "rule-inclusion",
      path: RULE_INCLUSIONS_PATH,
      show: getPrivilege(["can_manage_rule_inclusions"], privileges),
    },
    {
      label: getLink(ROSTER_PATH, t("Roster"), "TbCalendarTime"),
      key: "roster",
      path: ROSTER_PATH,
      show: getPrivilege(["can_view_weekly_roster"], privileges),
    },
    {
      label: getLink(STAFF_TARGETS_PATH, t("Targets"), "TbTargetArrow"),
      key: "target",
      path: STAFF_TARGETS_PATH,
      show: getPrivilege(["can_view_targets"], privileges),
    },
  ].filter(e => (all ? true : e.show))

const management = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(MANAGEMENT_PATH, t("Meetings"), "SvgMenuClients"),
      key: "meetings",
      path: MANAGEMENT_PATH,
      show: getPrivilege("can_view_meeting", privileges),
    },
    {
      label: getLink(ACTION_ITEMS_PATH, t("Action Items"), "SvgToDo"),
      key: "action-items",
      path: ACTION_ITEMS_PATH,
      show: getPrivilege("can_view_meeting", privileges),
    },
  ].filter(e => (all ? true : e.show))

const reports = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(SALES_KPI_REPORT_DEFAULT_LINK, t(reportCategories.saleskpi.name), "IcKpiSales"),
      key: reportCategories.saleskpi.key,
      path: SALES_KPI_REPORT_DEFAULT_LINK,
      show: getPrivilege("can_view_sales_kpi_report", privileges),
    },
    {
      label: getLink(AFFILIATE_KPI_REPORT_PATH, t(reportCategories.affiliateskpi.name), "IcKpiAffiliates"),
      key: reportCategories.affiliateskpi.key,
      path: AFFILIATE_KPI_REPORT_PATH,
      show: getPrivilege("can_view_affiliate_kpi_report", privileges),
    },
    {
      label: getLink(TSR_KPI_REPORT_PATH, t(reportCategories.telesaleskpi.name), "IcKpiSupport"),
      key: reportCategories.telesaleskpi.key,
      path: TSR_KPI_REPORT_PATH,
      show: getPrivilege("can_view_support_kpi_report", privileges),
    },
    {
      label: getLink(PRODUCTIVITY_REPORT_PATH, t(reportCategories.productivity.name), "IcProductivity"),
      key: reportCategories.productivity.key,
      path: PRODUCTIVITY_REPORT_PATH,
      show: getPrivilege("can_view_productivity_report", privileges),
    },
    {
      label: getLink(KPI_MANAGEMENT_REPORT_PATH, t(reportCategories.managementkpi.name), "IcKpiManagement"),
      key: reportCategories.managementkpi.key,
      path: KPI_MANAGEMENT_REPORT_PATH,
      show: getPrivilege("can_view_kpi_management_report", privileges),
    },
    {
      label: getLink(REVENUE_REPORT_PATH, t(reportCategories.revenues.name), "IcRevenueReports"),
      key: reportCategories.revenues.key,
      path: REVENUE_REPORT_PATH,
      show: getPrivilege("can_view_revenue_performance_report", privileges),
    },
    {
      label: getLink(AI_CALL_ANALYSIS_REPORT_PATH, t(reportCategories.aiCallAnalysis.name), "IcAiCallAnalysisReport"),
      key: reportCategories.aiCallAnalysis.key,
      path: AI_CALL_ANALYSIS_REPORT_PATH,
      show: getPrivilege("can_view_ai_analysis_report", privileges),
    },
  ].filter(e => (all ? true : e.show))

const accounts = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(BOOKING_FORM_LISTING, t("Booking Forms"), "BsBuildings"),
      key: "booking-form",
      path: BOOKING_FORM_LISTING,
      show: getPrivilege(["can_view_accounts"], privileges),
    },
    {
      label: getLink(PAYMENT_PATH, t("Payments"), "FaMoneyBill"),
      key: "payments-new",
      path: PAYMENT_PATH,
      show: getPrivilege(["can_view_payments"], privileges),
    },
    {
      label: getLink(REFUNDS_LISTING_PATH, t("Refunds"), "RiRefund2Line"),
      key: "refunds",
      path: REFUNDS_LISTING_PATH,
      show: getPrivilege(["can_view_payments"], privileges),
    },
    {
      label: getLink(CONTRACT_PATH, t("Contracts"), "GiOpenBook"),
      key: "contracts-new",
      path: CONTRACT_PATH,
      show: getPrivilege(["can_view_contracts_module"], privileges),
    },
    {
      label: getLink(REVENUE_RECOGNITION_PANEL_PATH, t("Rev Recognition"), "SvgIcRevenueRecognition"),
      key: "revenue-recognition",
      path: REVENUE_RECOGNITION_PANEL_PATH,
      show: getPrivilege(["can_view_revenue_recognition_panel"], privileges),
    },
  ].filter(e => (all ? true : e.show))

const billings = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(BILLING_PROJECTS_LISTING_PATH, t("Billable Projects"), "SvgIcProjects"),
      key: "billing-projects",
      path: BILLING_PROJECTS_LISTING_PATH,
      show: getPrivilege(["can_access_billing_module"], privileges),
    },
    {
      label: getLink(INVOICE_LISTING_PATH, t("Invoice"), "AiTwotoneFileText"),
      key: "invoice",
      path: INVOICE_LISTING_PATH,
      show: getPrivilege(["can_access_billing_module"], privileges),
    },
    {
      label: getLink(INVOICE_PAYMENTS_LISTING_PATH, t("Inv Payments"), "SvgIcInvoicePayment"),
      key: "invoice-payments",
      path: INVOICE_PAYMENTS_LISTING_PATH,
      show: getPrivilege(["can_access_billing_module"], privileges),
    },
  ].filter(e => (all ? true : e.show))

const affiliates = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(AFFILIATE_PATH, t("Affiliates"), "BsBuildings"),
      key: "affiliates",
      path: AFFILIATE_PATH,
      show: getPrivilege(["can_search_affiliate"], privileges),
    },
    {
      label: getLink(AFFILIATE_TO_DO_PATH, t("Todos"), "MdOutlineChecklist"),
      key: "affiliate-toDo",
      path: AFFILIATE_TO_DO_PATH,
      show: getPrivilege(["can_view_todo_list"], privileges),
    },
    {
      label: getLink(AFFILIATE_TASK_PATH, t("Tasks"), "SvgIcTaskLogs"),
      key: "affiliate-task",
      path: AFFILIATE_TASK_PATH,
      show: getPrivilege(["can_search_affiliate_task"], privileges),
    },
    {
      label: getLink(AFFILIATE_UNALLOCATED_PATH, t("Unallocated Affiliates"), "IconUnauthorizeAffiliate"),
      key: "affiliateUnallocated",
      path: AFFILIATE_UNALLOCATED_PATH,
      show: getPrivilege(["can_view_verified_unallocated_affiliates"], privileges),
    },
    {
      label: getLink(AFFILIATE_COMMISSION_PATH, t("Commissions"), "MdOutlineChecklist"),
      key: "affiliate_commission",
      path: AFFILIATE_COMMISSION_PATH,
      show: getPrivilege(["can_view_affiliate_commission"], privileges),
    },
    {
      label: getLink(AFFILIATE_VERIFICATION_PATH, t("Affiliate Verification"), "BsBuildings"),
      key: "affiliateVerification",
      path: AFFILIATE_VERIFICATION_PATH,
      show: getPrivilege(["can_view_potential_affiliates"], privileges),
    },
  ].filter(e => (all ? true : e.show))

const TSR = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(LEADS_SHARED_LISTING_PATH, t("Leads"), "SvgMenuLeads"),
      key: "leads-shared",
      path: LEADS_SHARED_LISTING_PATH,
      show: getPrivilege("can_view_leads_module", privileges),
    },
    {
      label: getLink(TSR_LISTING_PATH, t("Todos"), "BsBuildings"),
      key: "tsrLeads",
      path: TSR_LISTING_PATH,
      show: getPrivilege("can_view_tsr_section", privileges),
    },
    {
      label: getLink(TSR_AFFILIATE_PATH, t("TSR-Affiliates"), "BsBuildings"),
      key: "tsr-affiliate",
      path: TSR_AFFILIATE_PATH,
      show: getPrivilege("can_view_tsr_section", privileges),
    },
    {
      label: getLink(QUALITY_CONTROL_PATH, t("Quality Control"), "IcQualityControl"),
      key: "quality_control",
      path: QUALITY_CONTROL_PATH,
      show: getPrivilege("can_search_QC_leads", privileges),
    },
    {
      label: getLink(LMS_LEADS_PATH, t("LMS Leads"), "HiOutlineBuildingOffice2"),
      key: "lmsLead",
      path: LMS_LEADS_PATH,
      show: getPrivilege("can_search_prequalification_lead", privileges),
    },
    {
      label: getLink(LEAD_POOLS_PATH, t("Pool"), "HiOutlineChartPie"),
      key: "leads_poold",
      path: LEAD_POOLS_PATH,
      show: getPrivilege("can_view_lead_pre_qualification_pools", privileges),
    },
  ].filter(e => (all ? true : e.show))

const incentives = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(INCENTIVES_LISTING_PATH, t("Incentives"), "IconTaskList"),
      key: "incentives",
      path: INCENTIVES_LISTING_PATH,
      show: getPrivilege(["can_view_incentive_configuration_panel"], privileges),
    },
    {
      label: getLink(BONUS_LISTING_PATH, t("Bonus"), "SvgIcBonus"),
      key: "bonus",
      path: BONUS_LISTING_PATH,
      show: getPrivilege("can_view_bonus_listing", privileges),
    },
    {
      label: getLink(PENALTY_LISTING_PATH, t("Penalty"), "SvgIcPenalty"),
      key: "penalty",
      path: PENALTY_LISTING_PATH,
      show: getPrivilege("can_view_penalty_listing", privileges),
    },
  ].filter(e => (all ? true : e.show))

const marketing = (privileges, all, t = () => "") =>
  [
    {
      label: getLink(CAMPAIGN_LISTING_PATH, t("Campaigns"), "IconCampaign"),
      key: "campaigns",
      path: CAMPAIGN_LISTING_PATH,
      show: getPrivilege(["can_view_campaign"], privileges),
    },
    {
      label: getLink(SOCIAL_CONNECTORS_LIST, t("Social Connectors"), "IconTaskList"),
      key: "social-connectors",
      path: SOCIAL_CONNECTORS_LIST,
      show: getPrivilege(["can_view_campaign"], privileges),
    },
  ].filter(e => (all ? true : e.show))

export default {
  "clients-leads": clientsLeads,
  "project-inventory": projectInventory,
  incentives,
  staff,
  management,
  reports,
  accounts,
  billings,
  affiliates,
  TSR,
  marketing,
}
