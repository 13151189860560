import { t } from "i18next"
import { ACTION_TYPES, CONTENT_TYPES } from "utils/gtm/gtmEventsData"
import { Button, Icon, SelectInput, Switch, Text, TreeSelectInput } from "components/common"
import { Col, Form, Row, Space } from "antd"
import { useEffect, useMemo, useState } from "react"
import { Sort } from "components/custom"
import cx from "clsx"
import { fireEvent } from "utils/gtm/gtmEventHandler"

import { useGetTaskTypesQuery } from "features/tasks/api"
import { includeChildren } from "utils"

const items = client => [
  {
    id: "1",
    columnName: "id",
    sortOrder: "asc",
    label: t("Lead ID Ascending"),
    fireEvent: from =>
      fireEvent({
        ...ACTION_TYPES.sort_by,
        ...from,
        content_type: CONTENT_TYPES.lead_ID_ascending,
        client_id: client?.id,
      }),
  },
  {
    id: "2",
    columnName: "id",
    sortOrder: "desc",
    label: t("Lead ID Descending"),
    fireEvent: from =>
      fireEvent({
        ...ACTION_TYPES.sort_by,
        ...from,
        content_type: CONTENT_TYPES.lead_ID_descending,
        client_id: client?.id,
      }),
  },
  {
    id: "3",
    columnName: "createdAt",
    sortOrder: "asc",
    label: t("Date Ascending"),
    fireEvent: from =>
      fireEvent({
        ...ACTION_TYPES.sort_by,
        ...from,
        content_type: CONTENT_TYPES.date_ascending,
        client_id: client?.id,
      }),
  },
  {
    id: "4",
    columnName: "createdAt",
    sortOrder: "desc",
    label: t("Date Descending"),
    fireEvent: from =>
      fireEvent({
        ...ACTION_TYPES.sort_by,
        ...from,
        content_type: CONTENT_TYPES.date_descending,
        client_id: client?.id,
      }),
  },
]

export default function TaskFilters({
  filterObject,
  leadIds,
  onSearch,
  onSort,
  filterClass,
  from,
  client,
  tsrToggle,
  staffList,
}) {
  const { data: taskTypes } = useGetTaskTypesQuery()
  const [filters, setFilters] = useState(filterObject && { ...filterObject })
  const [selectedFilterLabels, setSelectedFilterLabels] = useState()

  const currentSort = items(client)?.filter(
    item => item?.columnName === filterObject?.sortBy && item?.sortOrder === filterObject?.sortOrder
  )[0]

  useEffect(() => {
    setFilters(filterObject)
  }, [filterObject])

  const handleClear = (filterKey, enableSearch = false) => {
    let obj = { ...filters }
    if (Array.isArray(filterKey)) {
      let filterKeys
      if (leads.length === 1) {
        filterKeys = filterKey.filter(e => e !== "inquiryIds")
        filterKeys.forEach(key => {
          delete obj[key]
        })
      } else {
        filterKey.forEach(key => {
          delete obj[key]
        })
      }
      obj = { ...obj, sortBy: "id", sortOrder: "desc", pageLimit: 20, page: filters.page }
      enableSearch ? onSearch(obj) : setFilters(obj)
      return
    }
    delete obj[filterKey]
    obj = { ...obj, sortBy: "id", sortOrder: "desc", pageLimit: 20, page: filters.page }
    enableSearch ? onSearch(obj) : setFilters(obj)
  }

  const onChangeToggle = e => {
    const fObj = { ...filters }
    if (e && fObj?.inquiryIds) delete fObj.inquiryIds
    else if (!fObj?.inquiryIds) fObj.inquiryIds = leadIds
    setFilters(fObj)
    onSearch(fObj, selectedFilterLabels)
  }

  const isFilterApplied = useMemo(() => {
    return Object.keys(filterObject).filter(
      e => !["sortBy", "sortOrder", "pageLimit", "page"].find(el => el == e) && !!filterObject[e]
    ).length
  }, [filterObject])

  const leads = useMemo(() => leadIds.map(e => ({ label: e, value: e })), [leadIds])

  const checkIfFiltersExist = useMemo(() => {
    if (filters?.inquiryIds && tsrToggle) {
      return isFilterApplied > 1
    } else {
      return isFilterApplied > 0
    }
  }, [filters])

  return (
    <>
      <Row className={cx("mbe-12", filterClass)} align="middle" gutter={[8, 16]}>
        <Col flex="1" hidden={leads.length === 1}>
          <Form.Item className="mbe-0">
            <SelectInput
              key={"inquiryIds"}
              value={typeof filters?.inquiryIds == "string" ? Number(filters?.inquiryIds) : filters?.inquiryIds}
              placeholder={t("Select lead")}
              mode="multiple"
              showArrow
              options={leads}
              onChange={(value, options) => {
                setFilters({ ...filters, inquiryIds: value })
                setSelectedFilterLabels(prev => ({ ...prev, lead_ids: options.map(e => e?.label).join(", ") }))
                value && fireEvent({ ...ACTION_TYPES.filter_by_lead_id_on_task_tab, ...from, user_input: value })
              }}
              allowClear
              onClear={() => handleClear("inquiryIds")}
            />
          </Form.Item>
        </Col>

        <Col flex="1">
          <Form.Item className="mbe-0">
            <SelectInput
              key={"staff"}
              value={filters.assigneeIds}
              placeholder={t("Select staff")}
              showArrow
              options={staffList}
              mode="multiple"
              onChange={(values, option) => {
                setFilters({ ...filters, assigneeIds: values })
                setSelectedFilterLabels(prev => ({ ...prev, staffIds: option?.map(item => item.label) }))
              }}
              allowClear
              onClear={() => handleClear("assigneeIds")}
            />
          </Form.Item>
        </Col>

        <Col flex="1">
          <Form.Item className="mbe-0">
            <TreeSelectInput
              key={"taskTypeIds"}
              value={filters.taskTypeIds}
              placeholder={t("Select task type")}
              options={taskTypes}
              handleOnChange={(value, option) => {
                const selectedValue = includeChildren(taskTypes, value)
                setFilters({ ...filters, taskTypeIds: selectedValue })
                setSelectedFilterLabels(prev => ({ ...prev, task_type: option?.label }))
                value && fireEvent({ ...ACTION_TYPES.filter_by_task_type_on_tasks_tab, ...from })
              }}
              allowClear
              onClear={() => handleClear("taskTypeIds")}
            />
          </Form.Item>
        </Col>

        <Col flex="60px">
          <Button
            type="primary"
            icon={<Icon icon="FiSearch" />}
            text=""
            onClick={() => onSearch(filters, selectedFilterLabels)}
            style={{ height: 48 }}
            block
          />
        </Col>

        {checkIfFiltersExist && (
          <Col flex="60px">
            <Button
              type="link"
              danger
              text={t("Reset")}
              onClick={() => {
                handleClear(Object.keys(filters), true)
                setSelectedFilterLabels({})
                fireEvent({ ...ACTION_TYPES.clear_filter_on_tasks_tab, ...from })
              }}
            />
          </Col>
        )}
      </Row>
      <Row className={cx("mbe-12", filterClass)} align="middle" gutter={[8, 16]}>
        <Col flex="auto" hidden={!tsrToggle}>
          <Space size={8}>
            <Text type="secondaryLight">{t("Show All Tasks")}</Text>
            <Switch defaultValue={false} key={leadIds.toString()} onChange={onChangeToggle} />
          </Space>
        </Col>
        <Col style={{ marginInlineStart: "auto" }}>
          <Sort
            options={items(client)}
            sort={currentSort}
            onClickItem={item => {
              if (filterObject?.sortBy !== item?.columnName || filterObject?.sortOrder !== item?.sortOrder) {
                onSort(item)
                item.fireEvent(from)
              }
            }}
          />
        </Col>
      </Row>
    </>
  )
}
