import {
  ADD_INVENTORY_PATH,
  AFFILIATE_COMMISSION_PATH,
  AFFILIATE_KPI_REPORT_PATH,
  AFFILIATE_PATH,
  AFFILIATE_TASK_PATH,
  AFFILIATE_TO_DO_PATH,
  AFFILIATE_UNALLOCATED_PATH,
  AFFILIATE_VERIFICATION_PATH,
  BILLING_PROJECTS_DETAILS_PATH,
  BILLING_PROJECTS_LISTING_PATH,
  BOOKING_FORM_LISTING,
  CONTRACT_PATH,
  CREATE_INVOICE_PATH,
  DASHBOARD_PATH,
  EDIT_BOOKING_FORM,
  EDIT_INVENTORY_PATH,
  INCENTIVES_LISTING_PATH,
  INVENTORY_LISTING_PATH,
  INVOICE_LISTING_PATH,
  INVOICE_PAYMENTS_LISTING_PATH,
  KPI_MANAGEMENT_REPORT_PATH,
  LEADS_LISTING_PATH,
  LOCATIONS_LISTING_PATH,
  PAYMENT_PATH,
  PRODUCTIVITY_REPORT_PATH,
  QUALITY_CONTROL_PATH,
  REFUNDS_LISTING_PATH,
  REVENUE_RECOGNITION_MONTH_DETAIL_PATH,
  REVENUE_RECOGNITION_PANEL_PATH,
  REVENUE_REPORT_PATH,
  ROLES_AND_PRIVILEGES_PATH,
  SALES_KPI_REPORT_DEFAULT_LINK,
  SECONDARY_INVENTORY_LISTING_PATH,
  STAFF_PATH,
  STAFF_TARGETS_PATH,
  TODOS_LISTING_PATH,
  TSR_KPI_REPORT_PATH,
} from "./app-paths"

export const legacyToReactPaths = [
  {
    legacyPath: "/en/dashboard",
    reactPath: `/en${DASHBOARD_PATH}`,
  },
  // {
  //   legacyPath: "/en/leads",
  //   reactPath: `/en${LEADS_LISTING_PATH}`,
  // },
  {
    legacyPath: "/en/targets",
    reactPath: `/en${STAFF_TARGETS_PATH}`,
  },
  {
    legacyPath: "/en/affiliates",
    reactPath: `/en${AFFILIATE_PATH}`,
  },
  // {
  //   legacyPath: "/en/inventory",
  //   reactPath: `/en${INVENTORY_LISTING_PATH}`,
  // },
  {
    legacyPath: "/en/accounts",
    reactPath: `/en${INCENTIVES_LISTING_PATH}`,
  },
  // {
  //   legacyPath: "/en/staff/details",
  //   reactPath: `/en${STAFF_PATH}`,
  // },
  {
    legacyPath: "/en/accounts/billable-projects",
    reactPath: `/en${BILLING_PROJECTS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/accounts/billable-invoices",
    reactPath: `/en${INVOICE_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/accounts/billing-payments",
    reactPath: `/en${INVOICE_PAYMENTS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/accounts/revenue-recognition",
    reactPath: `/en${REVENUE_RECOGNITION_PANEL_PATH}`,
  },
  {
    legacyPath: "/en/accounts/search-payments",
    reactPath: `/en${PAYMENT_PATH}`,
  },
  {
    legacyPath: "/en/accounts/search-refunds",
    reactPath: `/en/${REFUNDS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/accounts/search-contracts",
    reactPath: `/en${CONTRACT_PATH}`,
  },
  {
    legacyPath: "/en/accounts/documentation/search-documentation",
    reactPath: `/en${BOOKING_FORM_LISTING}`,
  },
  {
    legacyPath: "/en/leads/search/v2",
    reactPath: `/en${LEADS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/inventory/projects/add-locations",
    reactPath: `/en${LOCATIONS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/inventory/search-inventory",
    reactPath: `/en${INVENTORY_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/inventory/inventory_detail/:id",
    reactPath: `/en${INVENTORY_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/targets/view_targets",
    reactPath: `/en${STAFF_TARGETS_PATH}`,
  },
  {
    legacyPath: "/en/targets/view_project_units",
    reactPath: `/en${STAFF_TARGETS_PATH}`,
  },
  {
    legacyPath: "/en/targets/view_soft_kpi",
    reactPath: `/en${STAFF_TARGETS_PATH}`,
  },
  {
    legacyPath: "/en/dashboard/sales",
    reactPath: `/en${DASHBOARD_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/dashboard",
    reactPath: `/en${DASHBOARD_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/tasks/todo",
    reactPath: `/en${AFFILIATE_TO_DO_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/tasks/all",
    reactPath: `/en${AFFILIATE_TASK_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/commission",
    reactPath: `/en${AFFILIATE_COMMISSION_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/verification",
    reactPath: `/en${AFFILIATE_VERIFICATION_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/unallocated",
    reactPath: `/en${AFFILIATE_UNALLOCATED_PATH}`,
  },
  {
    legacyPath: "/en/affiliates/qc",
    reactPath: `/en${QUALITY_CONTROL_PATH}`,
  },
  {
    legacyPath: "/en/reports/kpi-sales",
    reactPath: `/en${SALES_KPI_REPORT_DEFAULT_LINK}`,
  },
  {
    legacyPath: "/en/reports/kpi-telesales",
    reactPath: `/en${TSR_KPI_REPORT_PATH}`,
  },
  {
    legacyPath: "/en/reports/kpi-management",
    reactPath: `/en${KPI_MANAGEMENT_REPORT_PATH}`,
  },
  {
    legacyPath: "/en/reports/revenue-performance-report",
    reactPath: `/en${REVENUE_REPORT_PATH}`,
  },
  {
    legacyPath: "/en/reports/kpi-affiliate",
    reactPath: `/en${AFFILIATE_KPI_REPORT_PATH}`,
  },
  {
    legacyPath: "/en/accounts/documentation/add",
    reactPath: `/en${BOOKING_FORM_LISTING}`,
  },
  {
    legacyPath: "/en/leads/todo-list",
    reactPath: `/en${TODOS_LISTING_PATH}`,
  },
  // {
  //   legacyPath: "/en/inventory",
  //   reactPath: `/en${INVENTORY_LISTING_PATH}`,
  // },
  {
    legacyPath: "/en/inventory/search-secondary-inventory",
    reactPath: `/en${SECONDARY_INVENTORY_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/reports/user-productivity-report",
    reactPath: `/en${PRODUCTIVITY_REPORT_PATH}`,
  },
  {
    legacyPath: "/en/leads/todo-list/v2",
    reactPath: `/en${TODOS_LISTING_PATH}`,
  },
  {
    legacyPath: "/en/inventory/add_inventory",
    reactPath: `/en${ADD_INVENTORY_PATH}`,
  },
  {
    legacyPath: "/en/staff/roles/view",
    reactPath: `/en${ROLES_AND_PRIVILEGES_PATH}`,
  },
  {
    legacyPath: "/en/reports/sales-funnel-report",
    reactPath: `/en${SALES_KPI_REPORT_DEFAULT_LINK}`,
  },
  {
    legacyPath: "/en/inventory/edit_inventory/:inventoryId",
    hasParams: true,
    reactPath: `/en${EDIT_INVENTORY_PATH}`,
  },
  {
    legacyPath: "/en/accounts/documentation/edit/:bookingFormId",
    hasParams: true,
    reactPath: `/en${EDIT_BOOKING_FORM}`,
  },
  {
    legacyPath: "/en/accounts/billable-projects/:projectId",
    hasParams: true,
    reactPath: `/en${BILLING_PROJECTS_DETAILS_PATH}`,
  },
  {
    legacyPath: "/en/accounts/billable-invoices/:invoiceId",
    hasParams: true,
    reactPath: `/en${CREATE_INVOICE_PATH}`,
  },
  {
    legacyPath: "/en/accounts/revenue-recognition/:id",
    hasParams: true,
    reactPath: `/en${REVENUE_RECOGNITION_MONTH_DETAIL_PATH}`,
  },
  {
    legacyPath: "/en/accounts/edit-contracts/:id",
    hasParams: true,
    mapParamsToQuery: {
      queryName: "contractId",
    },
    reactPath: `/en${CONTRACT_PATH}`,
  },
]
