import { useEffect, useState } from "react"
import { TimePicker } from "antd"
import { TIME_FORMAT} from "constants"
import TimeRangePicker from "../timeRangePicker/timeRangePicker"
import moment from "moment"

const { RangePicker } = TimePicker

export default function SimpleTimeRangeFilter({
  onSelect,
  onClear,
  value,
  valueFormat = TIME_FORMAT,
  label,
  splitBy = ",",
  ...rest
}) {

  const getConvertedTimeValue = times => {
    
    return `${times[0]?.format(valueFormat)},${times[1]?.format(valueFormat)}`
  }

  const getTimeValueFromString = (value) => {
    if (value) {
      const times = value.split(splitBy);
      return [moment(times[0], valueFormat), moment(times[1], valueFormat)];
    }
    return undefined;
  };
  return (
    <>
      <label className="d-block">{label}</label>
      <TimeRangePicker
        onChange={times => {
          times && onSelect(getConvertedTimeValue(times))
        }}
        noDefault
        inputReadOnly
        value={getTimeValueFromString(value)}
        {...rest}
      />
    </>
  )
}
