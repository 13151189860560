import { Button, Card, Group, Icon, Skeleton, Space, Spinner, Text, Title, UnitSelect } from "components/common"
import { Col, Form, Row } from "antd"
import styles from "./index.module.less"
import { useEffect, useState } from "react"
import { useLazyGetLineManagersQuery } from "features/staff/listings/api"
import { useQueryUpdate } from "hooks"
import { useTranslation } from "react-i18next"
import { localisedValue } from "utils"

const SearchStaff = ({ staff, manager, onChangeManager, name, formItemProps }) => {
  const [update] = useQueryUpdate()
  const { t } = useTranslation()
  const [lineManager, setLineManager] = useState({ key: null, value: null })
  const [getLineManagers, { data, isLoading, isFetching, error, isError }] = useLazyGetLineManagersQuery()
  useEffect(() => {
    if (manager && !manager?.name) {
      getLineManagers({ id: manager.id, reportingStaffId: staff?.id }).then(response => {
        response?.data?.[0] && onChangeManager(response?.data?.[0])
      })
    }
  }, [manager?.id])

  const onChangeLineManager = (key, value) => {
    getLineManagers({ [key]: value, ...(staff ? { reportingStaffId: staff?.id } : { isNewUser: true }) })
  }

  const onClickSelectLineManager = selectedItem => {
    onChangeManager(selectedItem)
  }

  const optionsList = [
    { id: 1, label: t("Employee ID"), value: "employeeId" },
    { id: 2, label: t("Staff ID"), value: "id" },
    { id: 3, label: t("STAFF NAME"), value: "userName" },
  ]

  const getItemHelp = isError ? (
    <Text type="danger">{error?.message}</Text>
  ) : data?.length === 0 ? (
    <Text type="danger">{t("No staff user found")}</Text>
  ) : (
    ""
  )

  const ViewLineManagerCard = ({ name, teamType, userDesignation, city }) => {
    return (
      <>
        {isFetching ? (
          <>
            <Skeleton className="mbe-4" height={18} />
            <Space align="middle">
              {[1, 2].map(item => (
                <>
                  <Skeleton style={{ marginTop: 6, height: 16 }} />
                  <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
                </>
              ))}
              <Skeleton style={{ marginTop: 6, height: 16 }} />
            </Space>
          </>
        ) : (
          <>
            <Title level={5}>{name}</Title>
            <Space align="start">
              <span className="fs12 gray-800">{teamType}</span>
              <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              <span className="fs12 gray-800">{userDesignation}</span>
              <Icon icon="GoDotFill" size=".7em" color="#D6D6D6" />
              <span className="fs12 gray-800">{city}</span>
            </Space>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Form.Item
        name={name}
        className="mbe-8"
        help={isError || data?.length === 0 ? getItemHelp : null}
        {...formItemProps}
      >
        <Row>
          <Col style={{ marginRight: 10 }} flex={"auto"}>
            <UnitSelect
              options={optionsList}
              disabled={!!manager}
              onClearInput={!!manager}
              handleChange={(key, value) => {
                onChangeLineManager(key, value)
              }}
              getValues={setLineManager}
              useInternalState={false}
              preventOnChange={true}
              loading={isLoading}
              className="mbe-8"
            />
          </Col>
          <Col flex={"48px"}>
            <Button
              loading={isFetching}
              type="primary"
              icon="BiSearch"
              onClick={() => {
                onChangeLineManager(lineManager?.key, lineManager?.value)
              }}
              style={{ height: 48, width: 48 }}
            />
          </Col>
        </Row>
        <div style={{ maxHeight: 320, overflowY: "auto" }}>
          <Group template="1fr" gap="12px">
            {!!data?.length && !manager && (
              <>
                <Text style={{ marginTop: 8 }}>{t("Select Line Manager")}</Text>
                {data?.map((element, i) => (
                  <Card
                    variant="gray"
                    className={styles.itemCard}
                    bodyStyle={{ padding: "12px 16px", cursor: "pointer" }}
                    onClick={e => onClickSelectLineManager(element)}
                  >
                    <ViewLineManagerCard
                      name={localisedValue({ object: element, key: "name" })}
                      teamType={localisedValue({ object: element?.TeamType, key: "name" })}
                      userDesignation={localisedValue({ object: element?.UserDesignation, key: "short" })}
                      city={localisedValue({ object: element?.City, key: "name" })}
                    />
                  </Card>
                ))}
              </>
            )}
            {!!manager && (
              <>
                <Card variant="gray" className={styles.itemCard} bodyStyle={{ padding: "12px 16px" }}>
                  {!isFetching && (
                    <Button
                      className={styles.removeBtn}
                      icon="FiTrash2"
                      danger
                      style={{
                        "--btn-color": "#e83a3a",
                        "--btn-bg": "#fff",
                        "--btn-hover-bg": "#fff",
                        padding: 10,
                        borderRadius: "2rem",
                        fontSize: 10,
                      }}
                      onClick={() => {
                        update("getLineManagers", draft => null)
                        onChangeManager(null)
                      }}
                    />
                  )}
                  <ViewLineManagerCard
                    name={localisedValue({ object: manager, key: "name" })}
                    teamType={localisedValue({ object: manager?.TeamType, key: "name" })}
                    userDesignation={localisedValue({ object: manager?.UserDesignation, key: "short" })}
                    city={localisedValue({ object: manager?.City, key: "name" })}
                  />
                </Card>
              </>
            )}
          </Group>
        </div>
      </Form.Item>
    </>
  )
}

export default SearchStaff
