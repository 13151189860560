import { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import getPrivilege from "utils/privileges/privileges"
import { setAppUserState } from "store/appSlice"
import { useIdleTimer } from "react-idle-timer"
import { useUpdateUserPresenceMutation } from "features/tsr/apis"

export const IdleDetector = (options = {}) => {
  const {
    onIdle = () => {},
    onLocked = () => {},
    onActive = () => {},
    onAction,
    onTrackingUnavailable,
    disabled,
    timeout = 180000,
  } = options
  const idleDetectorRef = useRef(null)
  const controllerRef = useRef(null)
  const signalRef = useRef()

  const userInfo = useSelector(state => state?.app?.user?.info)
  const userState = useSelector(state => state?.app?.user?.status)
  const privileges = useSelector(state => state?.app?.privileges)

  const stateRef = useRef(userState)
  const dispatch = useDispatch()

  const [updateUserPresence, { isLoading, isError, error, reset: resetErrorForAddTask }] =
    useUpdateUserPresenceMutation()

  const setUserState = useCallback(
    type => {
      stateRef.current = { ...stateRef.current, type }
      dispatch(setAppUserState({ ...userState, type }))
    },
    [userState?.type]
  )

  const onUserIdle = useCallback(() => {
    if (userInfo) {
      console.log("calling idle with ===== ", userState?.type)
      if (userState?.type == "active") {
        updateUserPresence({ userId: userInfo?.id, value: { id: 4 } })
        setUserState("idle")
      }
      onIdle()
    }
  }, [userState?.type, userInfo])

  const onUserLocked = useCallback(() => {
    if (userInfo) {
      if (userState?.type == "active") {
        updateUserPresence({ userId: userInfo?.id, value: { id: 4 } })
        setUserState("idle")
      }
      onLocked()
    }
  }, [userState?.type, userInfo])

  const onUserActive = useCallback(() => {
    if (userInfo) {
      console.log("calling active with ===== ", userState?.type)
      if (userState?.type !== "active") {
        updateUserPresence({ userId: userInfo?.id })
        setUserState("active")
      }
      onActive()
    }
  }, [userState?.type, userInfo])

  /**
   *  Use Idle Detector
   */

  // const requestPermission = async () => {
  //   if (!("IdleDetector" in window)) {
  //     return { granted: false, message: "Your browser do not have device monitoring please update." }
  //   }
  //   const state = await window.IdleDetector.requestPermission()
  //   if (state !== "granted") {
  //     return { granted: false, message: "Please allow device monitoring permission." }
  //   } else {
  //     return { granted: true, message: "Device monitoring permission granted." }
  //   }
  // }

  // const eventHandler = useCallback(async () => {
  //   let permissionState
  //   console.log("User State ===== Event", stateRef.current)
  //   if (!permissionState?.granted) {
  //     permissionState = await requestPermission()
  //     if (!permissionState?.granted) {
  //       alert(permissionState?.message)
  //     }
  //   }
  // }, [stateRef])

  // const startDeviceMonitoring = async () => {
  //   try {
  //     const idleDetector = new window.IdleDetector()
  //     idleDetectorRef.current = idleDetector
  //     const controller = new AbortController()
  //     controllerRef.current = controller
  //     signalRef.current = controller.signal
  //     idleDetector.addEventListener("change", () => {
  //       if (idleDetector.screenState == "locked") {
  //         onUserLocked()
  //       } else if (idleDetector.userState == "idle") {
  //         onUserIdle()
  //       } else if (idleDetector.screenState == "unlocked" && idleDetector.userState == "active") {
  //         onUserActive()
  //       }
  //     })
  //     await idleDetector.start({ threshold: timeout, signalRef })
  //   } catch (e) {
  //     console.log("calling error =====", e)
  //   }
  // }

  // useEffect(() => {
  //   if (privileges) {
  //     document.addEventListener("click", eventHandler)
  //     startDeviceMonitoring()
  //   }
  //   return () => {
  //     document.removeEventListener("click", eventHandler)
  //   }
  // }, [privileges])

  /**
   * Using idle timer
   *  */

  useIdleTimer({
    timeout,
    onAction,
    onActive: onUserActive,
    onIdle: onUserIdle,
    disabled,
    crossTab: true,
    name: "idle-time" + Date.now().toString(),
    startOnMount: getPrivilege("can_view_tsr_section", privileges),
  })

  return null
}
