import { Col, InputNumber, Row, Slider } from "antd"

import { Number } from ".."

const PriceSlider = props => {
  const { range, steps, setField, value = [], disable } = props

  const onChange = newValue => {
    setField({ clientBudgetRange: [0, newValue] })
  }

  return (
    <Col>
      <Col span={12}>
        <Slider
          min={range[0]}
          max={range[1]}
          step={steps}
          onChange={onChange}
          value={value[1]}
          tooltip={{ formatter: value => <Number isStringOnly value={value} /> }}
        />
      </Col>
      <Row span={4}>
        <InputNumber
          min={range[0]}
          max={range[1]}
          disabled={disable}
          style={{ margin: "0 16px", width: 130 }}
          value={value[0]}
          onChange={value => {
            onChange({ id: 1, value: value })
          }}
        />
        <InputNumber
          min={range[0]}
          max={range[1]}
          style={{ margin: "0 16px", width: 130 }}
          value={value[1]}
          onChange={value => {
            onChange(value)
          }}
        />
      </Row>
    </Col>
  )
}

export default PriceSlider
