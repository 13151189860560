import { Number } from "components/common"
import { createPrivilegeObject } from "utils/privileges/privilegesMapper"
import { dateTimeFunction, getTimeDateString, getAgeFromDate } from "utils/date"
import moment from "moment"

import { t } from "i18next"
import { localisedValue } from "utils"
const detailTransformHelper = response => {
  const lastActivity = moment(response.updatedAt).isValid()
    ? dateTimeFunction({ date: response.updatedAt, Default: true }).fromNow()
    : "-"
  const profilingObj = {
    ...response?.profilingAttempted,
    industryId: true,
    existingTypeId: true,
    existingLocalityId: true,
    isCompleted: true,
  }
  const profileFilled = response?.profilingAttempted ? !Object.keys(profilingObj).some(e => !profilingObj[e]) : false

  const transformedData = {
    ...response,
    ClientFavorite: response.FavoriteClients,
    salesProfile: { title: t("Sales Profile"), data: [] },
    demographicProfile: { title: t("Demographic Profile"), data: [] },
    affiliateInfo: { title: t("Affiliate Info"), data: [] },
    moreInfo: { title: t("More Information"), data: [] },
    allocationActivity: {
      name: response.User?.name,
      createdAt: getTimeDateString(response.createdAt, false, true),
      lastActivity,
    },
    clientEngagement: {
      qualifiedCalls: response.stats.qualifiedCalls.count.toString(),
      verifiedCalls: response.stats.verifiedCalls.count.toString(),
      meetingDone: response.stats.meetingDone.count.toString(),
    },
    activeSales:
      response.activeSales &&
      response.activeSales.map(e => ({
        ...e,
        current:
          e.inquiry.verifiedClosedWon !== 0
            ? 5
            : e.contract.paymentProgress?.remainingCompleteDownPaymentAmount === 0
            ? 4
            : e.contract.paymentProgress?.remainingPartialDownPaymentAmount === 0
            ? 2
            : e.contract.paymentProgress?.remainingMinimumTokenAmount === 0
            ? 1
            : null,
      })),
    privileges: {},
    profileFilled,
    leadIdsWithTasks: !!response.leadIdsWithTasks ? response.leadIdsWithTasks.split(",") : [],
  }

  const {
    budget,
    interestedProjects,
    ClientBuyingIntent,
    interestedAreaUnit,
    ClientExistingProperty,
    ClientRating,
    occupation,
    industry,
    gender,
    dateOfBirth,
    address,
    Country,
    nationalId,
    privileges,
    City,
    Affiliate,
  } = response

  transformedData.salesProfile.data.push(
    budget && {
      label: t("Buying Budget"),
      data: budget,
      isComponent: true,
      type: "numberComponent",
      columnView: true,
    },
    interestedProjects && {
      label: t("Interested Project"),
      data: interestedProjects.map(e => ({
        taglabel: `${localisedValue({ object: e, key: "name" })}`,
        tagcolor: "#3a77ff",
      })),
    },
    ClientBuyingIntent && {
      label: t("Investment Plan"),
      data: localisedValue({ object: ClientBuyingIntent, key: "displayName" }),
    },
    interestedAreaUnit && {
      label: t("Interested Area Unit"),
      data: [{ taglabel: t(interestedAreaUnit), tagcolor: "#7858a6" }],
    },
    ClientExistingProperty && {
      label: t("Existing Property"),
      data: [
        {
          taglabel:
            localisedValue({ object: ClientExistingProperty.Locality, key: "name" }) ||
            localisedValue({ object: ClientExistingProperty.Type, key: "name" }),
          tagcolor: "#7858a6",
        },
      ],
    }
  )

  transformedData.demographicProfile.data.push(
    ClientRating && { label: t("Client Rating"), data: localisedValue({ object: ClientRating, key: "name" }) },
    occupation && { label: t("Occupation"), data: t(occupation) },
    industry && { label: t("Industry"), data: t(industry) },
    gender && { label: t("Gender"), data: t(gender) },
    dateOfBirth && { label: t("Age"), data: getAgeFromDate(dateOfBirth) },
    address && { label: t("Address"), data: t(address), columnView: true },
    City && { label: t("City"), data: localisedValue({ object: City, key: "name" }) },
    Country && { label: t("Country"), data: localisedValue({ object: Country, key: "name" }) }
  )

  transformedData.affiliateInfo.data.push(Affiliate && { label: t("MOU ID"), data: Affiliate?.AffiliateMOU?.id })

  transformedData.moreInfo.data.push(
    nationalId && { label: t("CNIC"), data: nationalId },
    dateOfBirth && { label: t("DOB"), data: new Date(dateOfBirth).toLocaleDateString() }
  )

  transformedData.privileges = createPrivilegeObject(privileges)
  return transformedData
}

export default detailTransformHelper
