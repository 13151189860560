import { Button, Card, Group, SelectInput, Text, TextInput, Title } from "components/common"
import { useCallback, useEffect } from "react"

import { Form } from "antd"
import { affiliateOptions } from "constants/list"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useLazyGetCheckReferralToClientQuery } from "features/shared/api"
import { useWatch } from "antd/lib/form/Form"
import { localisedValue } from "utils"
import { useTranslation } from "react-i18next"
import { t } from "i18next"

const renderCard = item => {
  return (
    <Group
      gap="8px"
      template="1fr"
      className="span-all"
      style={{ gridAutoFlow: "row", gridTemplateRows: "auto 1fr" }}
      key={item.key}
    >
      {item.itemTitle && (
        <Text type="secondaryLight" block>
          {localisedValue({ object: item, key: "itemTitle" })}
        </Text>
      )}

      <Card variant="gray" bordered>
        <Group template="repeat(3,1fr)" className="fs12" gap={"12px 16px"}>
          <Title className="span-all" level={5}>
            {t("Affiliate Details")}
          </Title>
          {renderAffiliateCard(item)}
        </Group>
      </Card>
    </Group>
  )
}

const renderAffiliateCard = item => (
  <>
    <div>
      <Text type="secondaryLight">{t("MOU ID")}</Text>
      <p className="mbe-0">{item?.AffiliateMOU?.id}</p>
    </div>
    <div>
      <Text type="secondaryLight">{t("City")}</Text>
      <p className="mbe-0">{item?.Contact?.Address?.city ?? "-"}</p>
    </div>
    <div>
      <Text type="secondaryLight">{t("Name")}</Text>
      <p className="mbe-0">{item?.Contact?.name ?? "-"}</p>
    </div>
  </>
)

const AffiliateSection = props => {
  const { t } = useTranslation()
  const { form, rules, client, disabled, subModuleName } = props
  const searchAffiliate = useWatch("searchAffiliate", form)
  const searchId = useWatch("searchId", form)
  const [checkId, { data, isFetching }] = useLazyGetCheckReferralToClientQuery()
  const searchModule = { moduleName: "clients", subModuleName }

  useEffect(() => {
    if (client?.referralId) {
      const queryObj = { id: client?.referralId, ...searchModule }
      const response = checkId(!!client?.referralId ? queryObj : skipToken).unwrap()
      response.then(e => {
        form.setFieldsValue({
          searchId: e?.data[0]?.AffiliateMOU?.id,
          searchAffiliate: {
            ...form.getFieldValue().searchAffiliate,
            assignee: e?.data[0]?.AffiliateAssignees[0]?.type,
            affiliateId: e?.data[0]?.id,
          },
        })
      })
    }
  }, [client])

  const onSearch = useCallback(
    ({ key, value }) => {
      if (value) {
        const queryObj = { [key]: value, ...searchModule }
        const response = checkId(queryObj).unwrap()
        response.then(e => {
          form.validateFields(["searchId"])
          form.setFieldsValue({
            searchAffiliate: {
              ...form.getFieldValue().searchAffiliate,
              assignee: e?.data[0]?.AffiliateAssignees[0]?.type,
              affiliateId: e?.data[0]?.id,
              user: e?.data[0]?.AffiliateAssignees[0]?.User,
            },
          })
        })
      }
    },
    [searchId]
  )
  return (
    <>
      <Form.Item name="searchAffiliate" label={t("Search Affiliate By")} required rules={rules}>
        <SelectInput
          options={affiliateOptions()}
          onChange={(value, option) => {
            form.setFieldsValue({ searchAffiliate: option })
          }}
          disabled={disabled}
        />
      </Form.Item>
      <Group template={"1fr auto"} gap="8px">
        <Form.Item
          name="searchId"
          label={`${searchAffiliate?.label}`}
          style={{ width: "100%" }}
          required={!client}
          rules={rules.concat({
            validator: async (_, value) => {
              if (data) {
                if (data.count === 0) {
                  return Promise.reject(t("Affiliate not found"))
                } else {
                  if (!data.data?.[0]?.AffiliateAssignees?.find(e => e.type === "bdm" || e.type === "taamaccm")) {
                    return Promise.reject(t("Affiliate assignee must be BDM or TAAMACCM"))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            },
          })}
        >
          <TextInput
            disabled={disabled}
            placeholder={`${t("Search with")} ${searchAffiliate?.label}`}
            onBlur={e => {
              !disabled && onSearch({ key: searchAffiliate?.value, value: e.target.value })
            }}
          />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          onClick={() => {
            onSearch({ key: searchAffiliate?.value, value: searchId })
          }}
          disabled={disabled}
          loading={isFetching}
          icon="BiSearch"
          style={{ marginTop: 30, height: 46 }}
        />
      </Group>
      {data?.count !== 0 &&
        data?.data?.map(item => {
          return renderCard(item)
        })}
    </>
  )
}

export default AffiliateSection
