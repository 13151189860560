const leadsTransfomer = response => {
  if (response.contracts.length === 0) {
    return { ...response, current: undefined, isToken: false, clientId: response.inquiry.Client.id }
  } else {
    return {
      ...response,
      clientName: response.inquiry.Client?.name,
      verifiedWon: response.inquiry.verifiedClosedWon,
      projectName: response.contracts[0].project.name,
      projectId: response.contracts[0].projectId,
      projectUrl: response?.contracts[0]?.project?.url,
      projectBookingForm: response?.contracts[0]?.project?.needsBookingForm,
      type: response.contracts[0].Type.name,
      contractId: response.contracts[0].contractId,
      contractExpiry: response.contracts[0].expiryDate,
      unitPrice: response.contracts[0].unitPrice,
      unitDiscount: response.contracts[0].dealDiscount,
      paidAmount: response.contracts[0].paymentProgress.amountReceived,
      dealPrice: response.contracts[0].dealPrice,
      dueAmount: response.contracts[0].paymentProgress.amountDue,
      unitId: response.contracts[0].Listing.unitNumber,
      listingId: response.contracts[0].listingId,
      doesClientExist: !!response.inquiry.Client,
      doesCnicExist: !!response.inquiry.Client.nationalId,
      doesCountryExist: !!response.inquiry.Client.Country,
      doesCityExist: !!response.inquiry.Client.City,
      countryId: response.inquiry.Client.Country.id,
      remainMinToken:
        response.contracts[0].paymentProgress?.minTokenUnit === "percentage"
          ? (response.contracts[0].dealPrice * response.contracts[0].paymentProgress?.remainingMinimumTokenAmount) / 100
          : response.contracts[0].paymentProgress?.remainingMinimumTokenAmount,
      remainParDowPayment: response.contracts[0].paymentProgress?.remainingPartialDownPaymentAmount,
      remainComDowPayment: response.contracts[0].paymentProgress?.remainingCompleteDownPaymentAmount,
      document: response.documents[0],
      token: response.contracts[0].paymentProgress?.minToken,
      tokenUnit: response.contracts[0].paymentProgress?.minTokenUnit,
      cdp: response.contracts[0].paymentProgress?.completeDownPaymentInPercentage,
      pdp: response.contracts[0].paymentProgress?.partialDownPaymentInPercentage,
      current:
        response.inquiry.verifiedClosedWon !== 0
          ? 5
          : response.inquiry.InternalInquiryStatus.key === "closed_won" &&
            response.contracts[0].paymentProgress?.remainingCompleteDownPaymentAmount === 0
          ? 4
          : response.contracts[0].paymentProgress?.remainingCompleteDownPaymentAmount === 0
          ? 3
          : response.contracts[0].paymentProgress?.remainingPartialDownPaymentAmount === 0
          ? 2
          : response.contracts[0].paymentProgress?.remainingMinimumTokenAmount === 0
          ? 1
          : undefined,
    }
  }
}

export default leadsTransfomer
