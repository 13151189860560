import { localisedValue } from "utils"

const clientTypeTransformer = response => {
  return response.map(e => ({
    ...e,
    key: e.id,
    label: localisedValue({ object: e, key: "name" }),
    value: e.id.toString(),
    id: e.id,
  }))
}

export default clientTypeTransformer
