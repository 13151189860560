import { Result, Row } from "antd"
import Button from "../button/button"
import Empty from "../empty/empty"
import Icon from "../icon/icon"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"

const ComponentState = props => {
  const {
    type,
    children,
    onClick,
    btnLoading,
    btnText,
    status,
    title,
    description,
    btnIcon = "SlReload",
    image,
    buttons = [],
    icon,
    ...rest
  } = props

  const navigate = useNavigate()
  const getIcon = () => {
    switch (type) {
      default:
        return "ApplicationError"
    }
  }

  const statusMapping = {
    error: { title: t("Something went wrong!"), icon: "ApplicationError" },
    warning: { title: t("Warning!"), icon: "ApplicationError" },
    403: {
      title: t("Access Denied"),
      icon: "AccessDeniedError",
      description: t(
        "Your account lacks the necessary access privileges for this panel. Reach out to your Product Admin for more information"
      ),
    }, //Access Denied
    511: {
      title: t("Network Authentication Required"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    }, //Network Authentication Required
    FETCH_ERROR: {
      title: t("Connection Trouble"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    },
    NETWORK_CHANGED: {
      title: t("Connection Trouble"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    },
    CONNECTION_RESET: {
      title: t("Connection Trouble"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    },
    CONNECTION_TIMED_OUT: {
      title: t("Connection Trouble"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    },
    CONNECTION_REFUSED: {
      title: t("Connection Trouble"),
      icon: "NetworkError",
      description: t(
        "Uh-oh! Looks like your internet connection took a break. Please check your network and try again."
      ),
    },
  }

  const getStatus = () => {
    if (["error", "warning", "success", "info", "500", "404"].find(e => e == status)) {
      return status
    } else {
      return "error"
    }
  }

  return type === "empty" ? (
    <Row align="middle" justify="center" className="text-center" style={{ height: "100%" }}>
      <Empty title={title} description={description} image={image} {...rest} />
    </Row>
  ) : (
    <Result
      status={"error"}
      title={statusMapping[status]?.title || ComponentState?.defaultProps()?.title}
      subTitle={statusMapping[status]?.description || ComponentState?.defaultProps()?.description}
      extra={[
        status !== 403 ? (
          <Button
            key="retry"
            type="link"
            size="large"
            onClick={onClick}
            loading={btnLoading}
            text={btnText}
            icon={btnIcon}
          />
        ) : null,
        ...buttons,
      ]}
      icon={<Icon icon={statusMapping[status]?.icon || getIcon() || props.icon} /> || icon}
      {...rest}
    />
  )
}

ComponentState.propTypes = {
  type: PropTypes.string,
  btnLoading: PropTypes.bool,
  children: PropTypes.func,
  onClick: PropTypes.func,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  btnText: PropTypes.string,
  title: PropTypes.string,
}

ComponentState.defaultProps = () => ({
  btnText: t("Retry"),
  title: t("Something went wrong!"),
  description: t("We hit a snag! Something's not right. Give us a moment and then try refreshing."),
  icon: "ApplicationError",
})

export default ComponentState
