import { Carousel, Space } from "antd"
import Meta from "antd/lib/card/Meta"
import cx from "clsx"
import { Card, Icon } from "components/common"
import { HiChevronLeft, HiChevronRight } from "components/icons"
import styles from "./index.module.less"
import { useCallback, useMemo } from "react"
import { convertMarkdownToHtml } from "utils"
import Markdown from "react-markdown"
const TextCard = ({ message }) => {
  const settings = useMemo(
    () => ({
      dots: true,
      speed: 500,
      slidesToShow: 2.5,
      slidesToScroll: 1,
      arrows: true,
      infinite: false,
      nextArrow: <HiChevronRight />,
      prevArrow: <HiChevronLeft />,
    }),
    []
  )

  const ConditionalWrap = useCallback(
    ({ condition, wrap, children }) => (condition ? wrap(children) : children),
    [message]
  )

  const convertedHtml = useCallback(() => <Markdown>{message?.summary}</Markdown>, [message.summary])

  return (
    <Space direction="vertical" size={12}>
      {convertedHtml()}
      <div style={{ width: 400 }} className={message?.cards?.list?.length > 2 ? null : styles.cardGrid}>
        <ConditionalWrap
          condition={message?.cards?.list?.length > 2}
          wrap={children => (
            <Carousel className={styles.chatCarousel} {...settings}>
              {children}
            </Carousel>
          )}
        >
          {message?.cards?.list?.map(item => (
            <div className="pi-4">
              <Card
                className={cx(styles.chatCard, "p-4")}
                cover={<img alt="example" src={`${item?.banner_image}`} onClick={() => window.open(item?.link)} />}
                bodyStyle={{ padding: "10px 6px" }}
                bordered={false}
              >
                <Meta
                  title={item?.title}
                  description={
                    <>
                      <Icon icon="IoMdPin" size={14} />{" "}
                      {item?.footer_info?.heading + " " + (item?.footer_info?.sub_heading ?? "")}
                    </>
                  }
                  className={styles.chatCardContent}
                />
              </Card>
            </div>
          ))}
        </ConditionalWrap>
      </div>
    </Space>
  )
}

export default TextCard
